import React, { useRef } from 'react'

import styled, { useTheme, CSS, css } from 'styled-components'

import { Heading, Paragraph, ResponsiveImage, Button, Spacer, Link } from '@atoms/index'
import { DeviceContainer } from '@client/components/utility'
import { LargePageMediaImage, LargePageMediaImageFloat, MediumBoxShadow, PageMediaImage, PageMediaImageFloat, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'
import { ImageSizeEnum } from '@uctypes/api/globalTypes'

import { PageSectionRef } from './PageSectionGroup'

const MobileLeftContainer = styled.div`
  width: 100%;
`

const Small = css`
  ${PageMediaImage}
  ${PageMediaImageFloat}
`

const Medium = css`
  ${PageMediaImage}
  ${PageMediaImageFloat}
`

const Large = css`
  ${LargePageMediaImage}
  ${LargePageMediaImageFloat}
`

const MobileLeftImageContainer = styled.div<{ $shadow: boolean, $size: ImageSizeEnum }>`
 
  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

   ${(props): CSS => {
    switch (props.$size) {
      case ImageSizeEnum.SMALL:
        return Small
      case ImageSizeEnum.MEDIUM:
        return Medium
      case ImageSizeEnum.LARGE:
        return Large
    }
  }}
  
  ${ResponsivePXValue('margin-left', { mobile: '8px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '-48px', tablet: '-100px' })}

`

const MobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

const MobileButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  ${ResponsivePXValue('margin', { mobile: '32px 0 16px', tablet: '80px 0 40px' })}
`

const DesktopLeftContainer = styled.div`
  display: flex;
  width: 100%;
`

const DesktopLeftImageContainer = styled.div<{ $shadow: boolean, $size: ImageSizeEnum }>`
  flex-shrink: 0;
  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${(props): CSS => {
    switch (props.$size) {
      case ImageSizeEnum.SMALL:
        return Small
      case ImageSizeEnum.MEDIUM:
        return Medium
      case ImageSizeEnum.LARGE:
        return Large
    }
  }}
  
`

const DesktopContentContainer = styled.div`
  ${ResponsivePXValue('padding', { desktop: '42px 84px 0' })}
`

const DesktopButtonsContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('margin', { desktop: '30px 0;' })}
`

const TextContainer = styled.div`

`

export interface PageMediaLeftStandardProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaLeftStandard({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaLeftStandardProps): JSX.Element {
  const theme = useTheme()
  let pageRef
  let idx: number
  let content: string

  const hasButton = !!pageSection.links?.primary || !!pageSection.links?.secondary

  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  return (
    <>
      <DeviceContainer $mobile $tablet>
        <MobileLeftContainer>
          <MobileLeftImageContainer $shadow={pageSection.imageShadow} $size={pageSection.imageSize}>
            <ResponsiveImage image={pageSection.image} objectFit={pageSection.mobileObjectFit} />
          </MobileLeftImageContainer>
          <MobileContentContainer>
            <TextContainer>
              <Heading variant='h4' align='center' fontWeight={600} color={theme.colors.slates.bitter}>
                {pageSection.title}
              </Heading>
              <Spacer universal='16px' />
              <Heading variant='h2' align='center' className='subtitle-text'>
                {pageSection.subTitle}
              </Heading>
              <Spacer universal='16px' />
              <For each='content' index='idx' of={pageSection.contents}>
                <Paragraph variant='p1' align='center' key={idx} >
                  {content}
                </Paragraph>
              </For>
            </TextContainer>
            <If condition={hasButton}>
              <MobileButtonsContainer>
                <If condition={!!pageSection.links?.primary}>
                  <Button title={pageSection.links.primary.title} href={pageSection.links.primary.link} />
                </If>
                <If condition={!!pageSection.links?.secondary}>
                  <Link href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                </If>
              </MobileButtonsContainer>
            </If>
          </MobileContentContainer>
        </MobileLeftContainer>
      </DeviceContainer>
      <DeviceContainer $desktop>
        <DesktopLeftContainer>
          <DesktopLeftImageContainer $shadow={pageSection.imageShadow} $size={pageSection.imageSize}>
            <ResponsiveImage image={pageSection.image} objectFit={pageSection?.objectFit} />
          </DesktopLeftImageContainer>
          <DesktopContentContainer>
            <TextContainer>
              <Heading variant='h4' fontWeight={600} color={theme.colors.slates.bitter}>
                {pageSection.title}
              </Heading>
              <Spacer universal='16px' />
              <Heading variant='h2' className='subtitle-text'>
                {pageSection.subTitle}
              </Heading>
              <Spacer universal='16px' />
              <For each='content' index='idx' of={pageSection.contents}>
                <Paragraph variant='p1' align='left' key={idx} >
                  {content}
                </Paragraph>
              </For>
            </TextContainer>
            <If condition={hasButton}>
              <DesktopButtonsContainer>
                <If condition={!!pageSection.links?.primary}>
                  <Button title={pageSection.links.primary.title} href={pageSection.links.primary.link} />
                </If>
                <If condition={!!pageSection.links?.secondary}>
                  <Link href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                </If>
              </DesktopButtonsContainer>
            </If>
          </DesktopContentContainer>
        </DesktopLeftContainer>
      </DeviceContainer>
    </>
  )
}
