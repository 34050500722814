import React from 'react'

import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { PageBannerContent, PageBannerHero, PageBannerImage, PageBannerAdCampaign, PageBannerDashboard, ResponsivePXValue, PageBannerElegant } from '@client/components'
import { PageBannerFragment, useGetAppQuery } from '@hooks/api'
import { PageBannerVariationEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{ $isNativeApplication: boolean }>`
  display: flex;
  ${(props): CSS => {
    if (props.$isNativeApplication) {
      return `
        overflow: hidden;
        ${ResponsivePXValue('max-height', { mobile: '300px' })}
      `
    }
  }}

`

export interface PageBannerProps {
  pageBanner: PageBannerFragment
  height?: string
  mobileHeight?: string
  className?: string
}

export function PageBanner({ pageBanner, height, mobileHeight, className }: PageBannerProps): JSX.Element {
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  return (
    <Container className={className} $isNativeApplication={isNativeApplication}>
      <Choose>
        <When condition={!!pageBanner}>
          <Choose>
            <When condition={pageBanner.variation === PageBannerVariationEnum.HERO_BANNER}>
              <PageBannerHero pageBanner={pageBanner} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.CONTENT_BANNER}>
              <PageBannerContent pageBanner={pageBanner} height={height} mobileHeight={mobileHeight} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.IMAGE_BANNER}>
              <PageBannerImage pageBanner={pageBanner} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.CAMPAIGN_BANNER}>
              <PageBannerAdCampaign pageBanner={pageBanner} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.DASHBOARD_BANNER}>
              <PageBannerDashboard pageBanner={pageBanner} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.AD_BANNER}>
              <PageBannerAdCampaign pageBanner={pageBanner} />
            </When>
            <When condition={pageBanner.variation === PageBannerVariationEnum.ELEGANT_BANNER}>
              <PageBannerElegant pageBanner={pageBanner} />
            </When>
          </Choose>
        </When>
        <Otherwise>
          Page Banner not found
        </Otherwise>
      </Choose>
    </Container>
  )
}
