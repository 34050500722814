import React, { ChangeEvent } from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { SmallLoader } from '@atoms/index'
import { ResponsivePXValue, LiteBoxShadow } from '@components/Theme'

import { FormContext, FormContextProperties, FormValue, InputProps } from './Form'
import { InputWrapper } from './InputWrapper'

const Container = styled.div`
  position: relative;
 `

const Disabled = css`
  border-color: ${(props): string => props.theme.colors.whites.silver};
  color: ${(props): string => props.theme.colors.whites.silver};
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ::placeholder {
    color: ${(props): string => props.theme.colors.whites.silver};
    opacity: 1;
  }
`

const TextAreaInputElement = styled.textarea<{ $error: boolean, disabled: boolean, $isCompact: boolean }>`
  font-family: 'gordita';
  font-weight: 400;
  border-style: solid;
  resize: none;
  border-color: ${(props): string => props.$error ? props.theme.colors.misc.error : props.theme.colors.whites.desertStorm};

  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('padding', '12px')}
  ${ResponsivePXValue('width', 'CALC(100% - 26px)')}
  ${(props): string => props.$isCompact ? ResponsivePXValue('height', '45px') : ResponsivePXValue('height', '136px')}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('border-width', '1px')}

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props): string => props.$error ? props.theme.colors.misc.error : props.theme.colors.slates.bitter};
    ${LiteBoxShadow}
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.slates.bitter};
    opacity: 1;
  }

  ${(props): CSS => {
    if (props.disabled) {
      return Disabled
    }
  }}

`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
`

interface Values {
  value?: FormValue
  onChange?: (value: FormValue) => void
}

export interface TextAreaInputProps extends InputProps {
  rules?: Rule[]
  placeholder?: string
  isCompact?: boolean
  className?: string
}

export function TextAreaInput(props: TextAreaInputProps): JSX.Element {

  const { placeholder = '', label, rules, showLabel, name, loading, disabled, isCompact = false, className } = props
  const theme = useTheme()

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => {
        const required = !!(
          rules &&
          rules.some(rule => {
            if (rule && typeof rule === 'object' && rule.required) {
              return true
            }
            if (typeof rule === 'function') {
              const ruleEntity = rule(form)
              return ruleEntity && ruleEntity.required
            }
            return false
          })
        )
        const error = meta.errors?.[0]
        const { value, onChange } = control

        const _handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
          const newValue = e.target.value
          onChange(newValue)
        }

        return (
          <InputWrapper
            required={required}
            label={label}
            showLabel={showLabel}
            error={error}
            className = {className}>
            <FormContext.Consumer>
              {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
                <Container>
                  <TextAreaInputElement
                    $isCompact={isCompact}
                    autoComplete='on'
                    disabled={loading || disabled || formLoading || formDisabled}
                    name={name}
                    $error={!!error}
                    value={value ? value + '' : ''}
                    placeholder={placeholder}
                    onChange={_handleChange} />
                  <If condition={loading || formLoading}>
                    <LoadingContainer>
                      <SmallLoader
                        color={theme.colors.greens.aquaDeep} />
                    </LoadingContainer>
                  </If>
                </Container>
              )}
            </FormContext.Consumer>

          </InputWrapper>
        )
      }}
    </Field>
  )

}
