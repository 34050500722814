import React, { useState } from 'react'

import { FieldData } from 'rc-field-form/lib/interface'
import update from 'react-addons-update'
import styled from 'styled-components'

import { Paragraph, Button, Link, Spacer } from '@atoms/index'
import { ModalActionContainer, ResponsiveProperty, ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useAddLocationMutation, useAddUserAddressMutation, useUpdateLocationMutation, useUserDetailsQuery } from '@hooks/api'
import { Form, SelectInput, TextInput, TextAreaInput, useForm, SectionLoading, CheckBox } from '@molecules/index'
import { PlaceIdLookup, PlaceIdOption } from '@organisms/index'
import { BuildingTypeEnum, AddLocationInput, UpdateLocationInput } from '@uctypes/api/globalTypes'

import DraggableMap, { Coordinates, MapDragged } from '../misc/DraggableMap'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  ${ResponsivePXValue('margin', '0 16px')}

  .submit-button {
    ${ResponsivePXValue('margin', '16px 0')}
  }
  .instructions {
    .optional-container {
      justify-content: flex-end;
    }
  }
  .input-label {
    font-weight: 400;
  }
`

const StreetContainer = styled.div`
  display: flex;
  align-items: stretch;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

const OuterMapContainer = styled.div`
  display: flex;
  justify-self: center;
  ${ResponsivePXValue('padding', '20px 0')}
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', { mobile: '236px', tablet: '277px', desktop: '318px' })}
`

const MapContainer = styled.div`
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', '100%')}
`

const ActionContainer = styled.div<{ $stickyBottom: boolean }>`
  ${ModalActionContainer(true)};
`

interface AddressFormData {
  alias: string
  company: string
  buildingTypeEnum: BuildingTypeEnum | null
  buildingName: string | null
  unitNumber: string | null
  instructions: string
  isDefault: string
}

interface LocationFormData {
  locationName: string | null
  streetName: string | null
  streetNumber: string | null
  types: string[] | null
}

export interface AddAddressFunctionProps {
  placeId: string
  placeCoordinates: Coordinates
  buildingTypeEnum: BuildingTypeEnum
  streetName: string
  locationName: string
  streetNumber: string
  alias?: string
  company?: string
  buildingName?: string
  unitNumber?: string
  instructions?: string
  isDefault?: string
}

export interface AddAddressFormProps {
  onSuccess: () => void
  onError: (message: string) => void
}

interface AddAddressFormState {
  loading: boolean
  locationLoading: boolean
  placeId: string | null
  placeCoordinates: Coordinates | null
  locationData: LocationFormData
  buildingTypeEnum: BuildingTypeEnum | null
  hasAlias: boolean
}

const DEFAULT_STATE: AddAddressFormState = {
  loading: false,
  locationLoading: false,
  placeId: null as string | null,
  placeCoordinates: null as Coordinates | null,
  locationData: {
    streetName: null as string | null,
    streetNumber: null as string | null,
    locationName: null as string | null,
    types: null as string[],
  },
  buildingTypeEnum: null as BuildingTypeEnum | null,
  hasAlias: false,
}

export function AddAddressForm({ onSuccess, onError }: AddAddressFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<AddAddressFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addUserAddressMutation] = useAddUserAddressMutation()
  const [addLocationMutation] = useAddLocationMutation()
  const [updateLocationMutation] = useUpdateLocationMutation()
  const [mapForm] = useForm()
  const [form] = useForm()

  const setBuildingTypeEnum = (buildingTypeEnum: BuildingTypeEnum): void => {
    setState((prevState) => update(prevState, {
      buildingTypeEnum: { $set: buildingTypeEnum },
    }))
  }

  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _handleSuccess = async (data: AddressFormData) => {
    setLoading(true)
    try {
      const locationInput: AddLocationInput = {
        buildingTypeEnum: data.buildingTypeEnum,
        buildingName: data.buildingName,
        unitNumber: data.unitNumber,
        placeId: state.placeId,
        coordinates: state.placeCoordinates,
      }

      const addLocationResponse = await addLocationMutation({
        variables: {
          input: locationInput,
        },
      })

      if ((state.locationData.streetName && state.locationData.streetName !== '') || (state.locationData.streetNumber && state.locationData.streetNumber !== '')) {
        const locationUpdate: UpdateLocationInput = {
          streetName: state.locationData.streetName,
          streetNumber: state.locationData.streetNumber,
          locationName: state.locationData.locationName,
        }

        await updateLocationMutation({
          variables: {
            id: addLocationResponse.data.addLocation.id,
            input: locationUpdate,
          },
        })
      }

      const isDefault = data?.isDefault ? (data.isDefault as unknown as string[])[0] === 'true' : false

      await addUserAddressMutation({
        variables: {
          input: {
            alias: data.alias,
            instructions: data.instructions,
            company: data.company,
            location: addLocationResponse.data.addLocation.id,
            user: userDetailsData.currentUser.id,
            isDefault: !!isDefault,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: SiteHelper.getUserRefetchQueries(),
      })
      onSuccess()
    } catch (e) {
      onError(e.message)
    }
    setLoading(false)
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'buildingTypeEnum') {
          setBuildingTypeEnum(field.value)
        }

        if (name ==='alias') {
          setState((prevState) => update(prevState, {
            hasAlias: { $set: true },
          }))
        }

      })
    })
  }

  const _handleLocationChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'streetName' || name === 'streetNumber' || name === 'locationName') {
          setState((prevState) => update(prevState, {
            locationData: {
              [name]: { $set: field.value },
            },
          }))
        }
      })
    })
  }

  const _handlePlaceSelect = async (placeId: string, option: PlaceIdOption): Promise<void> => {

    if (placeId) {
      const addLocationResponse = await addLocationMutation({
        variables: {
          input: {
            placeId,
            types: option.types,
            locationName: option.title.split(',')[0],
          },
        },
      })

      setState((prevState) => ({
        ...prevState,
        placeId,
        placeCoordinates: {
          latitude: addLocationResponse.data.addLocation.center.latitude,
          longitude: addLocationResponse.data.addLocation.center.longitude,
        },
        locationData: {
          streetName: addLocationResponse.data.addLocation.streetName,
          streetNumber: addLocationResponse.data.addLocation.streetNumber,
          locationName: addLocationResponse.data.addLocation.locationName,
          types: addLocationResponse.data.addLocation.types as string[],
        },
      }))
      mapForm.setFieldsValue({
        streetName: addLocationResponse.data.addLocation.streetName,
        streetNumber: addLocationResponse.data.addLocation.streetNumber,
        locationName: addLocationResponse.data.addLocation.locationName,
        // types: addLocationResponse.data.addLocation.types,
      })
    }
  }

  const _handleMarkerDragged = async (mapDragged: MapDragged): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        locationLoading: false,
        locationData: {
          streetName: prevState.locationData.streetName,
          streetNumber: prevState.locationData.streetNumber,
          locationName: prevState.locationData.locationName,
          types: prevState.locationData.types,
        },
        placeCoordinates: {
          latitude: mapDragged.coordinates.latitude,
          longitude: mapDragged.coordinates.longitude,
        },
      }))
    } catch (e) {
      setState((prevState) => ({ ...prevState, locationLoading: false }))
      onError(e.message)
    }
  }

  const streetAddressTypes = ['street_address', 'premise']
  const isStreetNumberRequired = state?.locationData?.types?.some(r => streetAddressTypes.includes(r))
  const loading = userDetailsLoading || state.loading || state.locationLoading

  return (
    <Container>
      <PlaceIdLookup
        onSelect={_handlePlaceSelect} />
      <Form
        form={mapForm}
        disabled={loading || !state.placeId}
        onFieldsChange={_handleLocationChange}>
        <OuterMapContainer>
          <DraggableMap
            onChange={_handleMarkerDragged}
            center={state.placeCoordinates}
            containerElement={<MapContainer />}
            mapElement={<div style={{ height: '100%', width: '100%' }} />} />
          <If condition={state.locationLoading}>
            <SectionLoading />
          </If>
        </OuterMapContainer>
        <StreetContainer>
          <If condition={state.placeCoordinates && !isStreetNumberRequired}>
            <TextInput
              name='locationName'
              placeholder='Place'
              showOptional={false}
              rules={[{ required: false, message: 'Please input a place' }]} />
            <Spacer universal='12px' variant='horizontal' />
          </If>
          <TextInput
            name='streetNumber'
            placeholder='Street Number'
            showOptional={false}
            rules={[{ required: isStreetNumberRequired, message: 'Please input a street number' }]} />
          <Spacer universal='12px' variant='horizontal' />
          <TextInput
            name='streetName'
            placeholder='Street name'
            rules={[{ required: true, message: 'Please input a street name' }]} />
        </StreetContainer>
        <If condition={!!state.placeId} >
          <Spacer universal='24px' />
          <Paragraph variant='p3' color={theme.colors.greys.tundora} align='center'>Drag the marker on the map to your address and edit Street Name and Number if necessary.</Paragraph>
        </If>
      </Form>
      <Form
        form={form}
        onFinish={_handleSuccess}
        onFieldsChange={_handleChange}
        disabled={loading} >
        <TextInput
          name='alias'
          placeholder='Give your address a name'
          rules={[{ required: true, message: 'Please give your address a name' }]} />
        <SelectInput
          name='buildingTypeEnum'
          placeholder='Building Type'
          rules={[{ required: true, message: 'Please select a building type' }]}
          apiEnum='BuildingTypeEnum' />
        <If condition={(state.buildingTypeEnum === BuildingTypeEnum.OFFICE_BUILDING || state.buildingTypeEnum === BuildingTypeEnum.OFFICE_COMPLEX) && !!state.buildingTypeEnum}>
          <TextInput
            name='company'
            label='Company name'
            placeholder='Enter company name' />
        </If>
        <If condition={state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && !!state.buildingTypeEnum}>
          <TextInput
            name='buildingName'
            label={state.buildingTypeEnum === BuildingTypeEnum.SECURITY_COMPLEX ? 'Complex name' : 'Building name'}
            disabled={!(state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null)}
            rules={[{ required: (state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null), message: 'Please input a building name' }]} />
          <TextInput
            name='unitNumber'
            label='Unit Number / Floor'
            disabled={!(state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null)}
            rules={[{ required: (state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null), message: 'Please input a unit number / floor' }]} />
        </If>
        <TextAreaInput
          className='instructions'
          name='instructions'
          placeholder='Special instruction' />
        <CheckBox
          name='isDefault'
          showLabel={false}
          showOptional={false}
          options={[{ title: 'Set as default address', value: 'true' }]} />
        <Spacer universal='24px' />
        <ActionContainer $stickyBottom>
          <Button
            loading={loading}
            disabled={loading || !state.placeId || !state.buildingTypeEnum || !state.hasAlias}
            color='black'
            fullWidth
            title='SAVE ADDRESS'
            onClick={() => form.submit()} />
          <Spacer mobile='8px' desktop='16px' />
          <Link variant='l2' decoration='underline' onClick={onSuccess}> Cancel </Link>
        </ActionContainer>
      </Form>
    </Container>
  )
}
