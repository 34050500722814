import React from 'react'

import styled from 'styled-components'

import { DesktopPageBannerHero, FlexDeviceContainer, MobilePageBannerHero } from '@client/components'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex: 1;
`

export interface PageBannerHeroProps {
  pageBanner: PageBannerFragment
}

export function PageBannerHero({ pageBanner }: PageBannerHeroProps): JSX.Element {

  return (
    <Container>
      <FlexDeviceContainer $mobile $tablet>
        <MobilePageBannerHero pageBanner={pageBanner} />
      </FlexDeviceContainer>
      <FlexDeviceContainer $desktop>
        <DesktopPageBannerHero pageBanner={pageBanner} />
      </FlexDeviceContainer>
    </Container>
  )
}
