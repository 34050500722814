import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftKidsMealDetails')

const CraftKidsMealDetails = loadable(() => import(/* webpackChunkName: "craft-kids-meal-details" */'@pages/CraftKidsMealDetails'), {
  resolveComponent: (components) => components.CraftKidsMealDetails,
})

export function CraftKidsMealDetailsAsync(): JSX.Element {

  return <CraftKidsMealDetails fallback={<div>Loading...</div>} />

}
