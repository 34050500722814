import React from 'react'

import styled from 'styled-components'

import { Loader } from '../atoms'

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export function FullPageLoader(): JSX.Element {

  return (
    <Container>
      <Loader noShadow />
    </Container>
  )

}
