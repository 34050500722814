import React, { useState, useEffect, useRef } from 'react'

import update from 'react-addons-update'
import { ErrorBoundary } from 'react-error-boundary'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ResponsivePXValue } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import useIsomorphicLayoutEffect from '@client/hooks/UseIsomorphicLayoutEffect'

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '40px' })}
  .google-button {
    width: 100%;
  }

`

export interface GoogleIdentityServicesButtonProps {
  disabled: boolean
  action?: 'SIGN UP' | 'LOGIN'
  message: 'use' | 'signin' | 'signup'
  className?: string
  didAuthenticateWithGoogle: (token: string) => void

}

interface GoogleIdentityServicesButtonState {
  disabled: boolean
  loading: boolean
  width: number
  hasInitialized: boolean
}

const DEFAULT_STATE: GoogleIdentityServicesButtonState = {
  disabled: false,
  loading: false,
  width: 0,
  hasInitialized: false,
}

export function GoogleIdentitySocialButton({ message, didAuthenticateWithGoogle }: GoogleIdentityServicesButtonProps): JSX.Element {
  const signInDivRef = useRef(null)
  const config = useConfig()
  const [state, setState] = useState<GoogleIdentityServicesButtonState>({ ...DEFAULT_STATE })
  const { addToast } = useToasts()
  const buttonRef: React.RefObject<HTMLDivElement> = useRef()

  const _handleResponse = (response: { credential: string }): void => {
    try {
      const jwtToken = response.credential
      didAuthenticateWithGoogle(jwtToken)

    } catch (e) {
      _showError()
    }
  }
  // global google object

  useIsomorphicLayoutEffect(() => {
    const buttonWidth = buttonRef?.current?.clientWidth ?? 0
    setState((prevState) => update(prevState, {
      width: { $set: buttonWidth },
    }))
    // TODO: update on toast header
  }, [])

  useEffect(() => {

    if (!state.hasInitialized) {
      try {
        /* global google */

        google.accounts.id.initialize({
          client_id: `${config.getGoogle().clientId}.apps.googleusercontent.com`,
          callback: _handleResponse,
          context: message,

        })
        setState((prevState) => update(prevState, {
          hasInitialized: { $set: true },
        }))

      } catch (e) {

        setState((prevState) => update(prevState, {
          hasInitialized: { $set: false },
        }))
        _showError()
      }

    }

    if (state.hasInitialized && state.width !== 0) {
      try {
        /* global google */

        google.accounts.id.renderButton(signInDivRef.current, {
          theme: 'outline',
          size: 'large',
          type: 'standard',
          width: state.width,
        })
      } catch (error) {
        _showError()

      }
    }

  }, [signInDivRef.current, state.hasInitialized])

  const _showError = (): void => {
    addToast('Google was unable to authenticate you.', {
      appearance: 'error',
      autoDismiss: true,
    })
  }

  return (
    <Container ref={buttonRef}>
      <ErrorBoundary fallback={<div>Google signin service temporarily un-available, please contact customer service for support....</div>}>
        <div id='signinDiv' className='google-button' ref={signInDivRef}></div>
      </ErrorBoundary>
    </Container>
  )
}
