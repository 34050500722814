import React from 'react'

import styled from 'styled-components'

import { Link } from '@client/components'
import { ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`

  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '24px 32px' })}
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: 'CALC(100% - 64px)' })}

  .link {
    flex-shrink: 0;
    padding: 0;
    margin: 0;
    ${ResponsivePXValue('margin', { mobile: '8px', tablet: '8px', desktop: '0 32px 0' })}
  }

  .info {
    flex-grow: 1;
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    color: ${(props): string => props.theme.colors.whites.silver};
    ${ResponsivePXValue('text-align', { mobile: 'center', tablet: 'center', desktop: 'right' })}
    ${ResponsivePXValue('margin', { mobile: '32px 16px', tablet: '32px 16px' })}
  }
`

export function CheckoutFooter(): JSX.Element {

  const currentYear = new Date().getUTCFullYear()

  return (
    <Container>
      <Link className='link' variant='l2' href='https://support.ucook.co.za/hc/en-us'>Need Help?</Link>
      <Link className='link' variant='l2' href='/privacy-policy'>Privacy Policy</Link>
      <Link className='link' variant='l2' href='/referral-policy'>Referral Terms</Link>
      <Link className='link' variant='l2' href='/terms'>Terms of Service</Link>
      <Link className='info'>{`${currentYear} © UCOOK. All rights reserved | Liquor License: WCP/042073`}</Link>
    </Container>
  )
}
