import React from 'react'

import styled from 'styled-components'

import { Button, Paragraph, Link, Spacer } from '@client/components/atoms'
import { Form, TextInput, useForm } from '@client/components/molecules'
import { theme } from '@client/components/Theme'

const Container = styled.div`
  width: 100%;

  .text-element {
    margin: 0px
  }
`

export interface NotifyMeOnProductAvailabilityFormProps {
  onSubmit: (email: string) => void
  onCancel: () => void
}

export function NotifyMeOnProductAvailabilityForm({ onSubmit, onCancel }: NotifyMeOnProductAvailabilityFormProps): JSX.Element {

  const [form] = useForm()

  const _handleSubmit = (data: { email: string }) => {
    onSubmit?.(data.email as string)
  }

  const _handleCancel = () => {
    onCancel?.()
  }

  return (
    <Container>
      <Form form={form} onFinish={_handleSubmit}>
        <Paragraph
          variant='p1'
          color={theme.colors.greys.liteCodGrey}
          align='center'
          className='text-element'>
          {'Enter your email address below and we will keep you up to speed with delivery updates.'}
        </Paragraph>
        <Spacer universal='16px' />
        <TextInput
          variant='email'
          name='email'
          label='Email address'
          showLabel={false}
          placeholder='Enter your email address...'
          rules={[{ required: true, message: 'Please input an email address' }]} />
        <Spacer universal='16px' />
        <Button
          className='submit-email-address-button'
          title='Submit email address'
          onClick={() => form.submit()} />
        <Spacer universal='16px' />
        <Link onClick={_handleCancel}>Cancel</Link>
      </Form>
    </Container>
  )

}
