import React from 'react'

import styled from 'styled-components'

import { Paragraph, Spacer } from '@client/components'
import { ContentSeperator } from '@client/components/atoms'
import { Ellipsis, ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, CartCostItemFragment } from '@hooks/api'

const BreakdownContainer = styled.div`
  ${ResponsivePXValue('padding', '16px')}
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .item-text {
    ${ZeroSpace}
    ${Ellipsis()}
    ${ResponsiveProperty('max-width', { mobile: '70vw', tablet: '70%', desktop: '70%' })}
    font-weight: 500;
    line-height: 1.2;
  }

  .item-value-text {
    ${ZeroSpace}
    line-height: 1.2;
    font-weight: 400;
    ${ResponsivePXValue('font-size', '12px')}
  }

  .total-text {
    ${ZeroSpace}
    font-weight: 700;
    line-height: 1;
  }

  .minimum-items-text {
    ${ZeroSpace}
    font-weight: 500;
    line-height: 1;
    ${ResponsivePXValue('font-size', '10px')}
    color: ${theme.colors.misc.error}
  }
`

const TableRow = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

`

export function CartBreakdown(): JSX.Element {

  const config = useConfig()
  const { data: cartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const cart = cartData?.currentUser?.activeCart
  const addOnItems = (cart?.cartItems || []).filter(cartItem => !cartItem.isFromSubscription)
  const mealKitProduct = (cart?.cartItems || []).filter(cartItem => cartItem.isFromSubscription && cartItem.isDisplayed)

  let mealKitCost = 0

  for (let i = 0; i < mealKitProduct.length; i++) {
    mealKitCost = mealKitProduct[i]?.price + mealKitCost
  }

  const status = cartData?.currentUser?.checkoutStatus
  const hasMealKits = (cart?.cartItems || []).filter(cartItem => cartItem.isFromSubscription).length > 0
  const hasAddOns = (cart?.cartItems || []).filter(cartItem => !cartItem.isFromSubscription).length > 0
  const hasActiveSubscription = status?.hasActiveSubscription

  const getDeliveryFee = (fee: number): string => {
    if (fee === 0) {
      return 'Free'
    } else {
      return `+R${fee.toFixed(2)}`
    }
  }

  let addition: CartCostItemFragment
  let reduction: CartCostItemFragment

  return (
    <BreakdownContainer>
      <If condition={hasMealKits || (!hasAddOns && hasActiveSubscription)}>
        <TableRow>
          <Paragraph variant='p2' className='item-text'>Meal Kit</Paragraph>
          <Paragraph variant='p2' className='item-value-text'>{status?.hasValidSubscription ? `R${mealKitCost.toFixed(2)}` : 'R____'}</Paragraph>
        </TableRow>
        <If condition={!status?.hasValidSubscription}>
          <Spacer universal='16px' />
          <Paragraph variant='p2' className='minimum-items-text'>{'Pick a minimum of two meals to see Meal Kit pricing'}</Paragraph>
        </If>
      </If>
      <If condition={hasAddOns}>
        <Spacer universal='16px' />
        <TableRow>
          <Paragraph variant='p2' className='item-text'>{hasMealKits ? 'Add ons' : 'Subtotal'}</Paragraph>
          <Paragraph variant='p2' className='item-value-text'>{`R${addOnItems.reduce((total, item) => total + item.price, 0).toFixed(2)}`}</Paragraph>
        </TableRow>
      </If>
      <ContentSeperator margin='16px 0 0' />
      <For each='addition' of={cart?.additions || []}>
        <Spacer universal='16px' />
        <TableRow>
          <Choose>
            <When condition={addition.title === 'Delivery Fee'}>
              <Paragraph variant='p2' className='item-text'>{addition.title}</Paragraph>
              <Paragraph variant='p2' className='item-value-text'>{getDeliveryFee(addition.value)}</Paragraph>
            </When>
            <Otherwise>
              <Paragraph variant='p2' className='item-text'>{addition.title}</Paragraph>
              <Paragraph variant='p2' className='item-value-text'>{`+R${addition.value.toFixed(2)}`}</Paragraph>
            </Otherwise>
          </Choose>
        </TableRow>
      </For>
      <For each='reduction' of={cart?.reductions || []}>
        <Spacer universal='16px' />
        <TableRow>
          <Paragraph variant='p2' className='item-text'>{reduction.title}</Paragraph>
          <Paragraph variant='p2' className='item-value-text'>{`-R${reduction.value.toFixed(2)}`}</Paragraph>
        </TableRow>
      </For>
      <Choose>
        <When condition={(cart?.additions || []).length > 0 || (cart?.reductions || []).length > 0} >
          <ContentSeperator margin='16px 0' />
        </When>
        <Otherwise>
          <Spacer universal='16px' />
        </Otherwise>
      </Choose>
      <TableRow>
        <Paragraph variant='p2' className='total-text' transform='uppercase'>{'Total'}</Paragraph>
        <Paragraph variant='p2' className='total-text'>{`R${(cart?.grandTotal ?? 0).toFixed(2)}`}</Paragraph>
      </TableRow>
    </BreakdownContainer>
  )
}
