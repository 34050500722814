import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'misc'
export type HeadingAlignment = 'left' | 'center' | 'right' | 'justify'
export type HeadingTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type HeadingDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const H1 = styled.h1<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  font-family: 'utopia';
  font-weight: 700;

  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '28px', tablet: '28px', desktop: '40px' })}
  ${ResponsivePXValue('line-height', { mobile: '44px', tablet: '44px', desktop: '64px' })}

`

const H2 = styled.h2<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  font-family: 'utopia';

  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 700};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '24px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('line-height', { mobile: '32px', tablet: '32px', desktop: '48px' })}

`

const H3 = styled.h3<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  font-family: 'gordita';

  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 700};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '18px', desktop: '24px' })}
  ${ResponsivePXValue('line-height', { mobile: '28px', tablet: '28px', desktop: '40px' })}

`

const H4 = styled.h4<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  font-family: 'gordita';

  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 700};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '16px', desktop: '20px' })}
  ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '24px', desktop: '32px' })}

`

const H5 = styled.h5<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`
  
  font-family: 'gordita';

  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 700};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '14px', desktop: '16px' })}
  ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '22px', desktop: '24px' })}

`
const H6 = styled.h6<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  font-family: 'gordita';

  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 700};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '20px', desktop: '24px' })}
`
const HMisc = styled.h6<{ $align: string, $decoration: string, $transform: string, $color?: string, $fontWeight?: number }>`

  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.liteCodGrey};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  font-weight: ${(props): number => props.$fontWeight ? props.$fontWeight : 600};

  font-family: 'utopia';
  
  ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '18px', desktop: '20px' })}
`
export interface HeadingProps {
  children: string | React.ReactNode
  variant?: HeadingVariant
  align?: HeadingAlignment
  transform?: HeadingTransforms
  decoration?: HeadingDecorations
  color?: string
  fontWeight?: number
  skeleton?: boolean
  className?: string
}

export function Heading({ children, variant = 'h1', align = 'left', transform = 'none', decoration = 'none', color, fontWeight, className }: HeadingProps): JSX.Element {

  switch (variant) {

    case 'h1':
      return <H1 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H1>
    case 'h2':
      return <H2 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H2>
    case 'h3':
      return <H3 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H3>
    case 'h4':
      return <H4 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H4>
    case 'h5':
      return <H5 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H5>
    case 'h6':
      return <H6 $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</H6>
    case 'misc':
      return <HMisc $align={align} $decoration={decoration} $transform={transform} $color={color} $fontWeight={fontWeight} className={className}>{children}</HMisc>

  }

}
