import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@components/Theme'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}

`
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
`
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;

  ${ResponsivePXValue('padding-left', { mobile: '0', tablet: '0', desktop: '16px' })}
`
const ItemContainer = styled.div`
  display: flex;
  align-items: center;

  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '26px', desktop: '32px' })}
`
const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'gordita';
  font-weight: 700;

  color: ${(props): string => props.theme.colors.slates.bitter};
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};

  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('line-height', '24px')}
  ${ResponsivePXValue('border-radius', '16px')}
  ${ResponsivePXValue('min-width', '48px')}
  ${ResponsivePXValue('width', '48px')}
  ${ResponsivePXValue('height', '48px')}
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('padding-left', { mobile: '8px', tablet: '12px', desktop: '16px' })}
`

const TitleContainer = styled.div`
  div {
     ${ZeroSpace};

     font-weight: 500;

     ${ResponsivePXValue('font-size', '14px')}
     ${ResponsivePXValue('line-height', '24px')}
     ${ResponsivePXValue('margin-bottom', { mobile: '5px', tablet: '6px', desktop: '8px' })}
  }
`

const TextContainer = styled.div`
  div {
    ${ZeroSpace};

    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

export interface NumberedListItem {
  title: string
  index?: number
  description?: string
}

export function NumberedListItem({ title, index, description }: NumberedListItem): JSX.Element {

  const theme = useTheme()

  return (
    <ItemContainer>
      <Index>{index}</Index>
      <ContentContainer>
        <TitleContainer>
          <Paragraph variant='p2' color={theme.colors.greys.darkCodGrey}>
            {title}
          </Paragraph>
        </TitleContainer>
        <TextContainer>
          <Paragraph variant='p3' color={theme.colors.slates.ash}>
            {description}
          </Paragraph>
        </TextContainer>
      </ContentContainer>
    </ItemContainer>
  )

}

export interface NumberedListProps {
  items: NumberedListItem[]
}

export function NumberedList({ items }: NumberedListProps): JSX.Element {

  const isEven = (number: number): boolean => number % 2 === 0
  const meanIndex = Math.round(items.length / 2)

  const renderLeftColumn = (i: number): boolean => {
    return isEven(meanIndex) ? i < meanIndex : i <= meanIndex
  }

  const renderRightColumn = (i: number): boolean => {
    return isEven(meanIndex) ? i >= meanIndex : i > meanIndex
  }

  let item: NumberedListItem
  let i: number

  return (
    <Container>
      <LeftColumn>
        <For each='item' of={items} index='i'>
          <If condition={renderLeftColumn(i)}>
            <NumberedListItem {...item} index={item.index ? item.index : i + 1} key={i} />
          </If>
        </For>
      </LeftColumn>
      <RightColumn>
        <For each='item' of={items} index='i'>
          <If condition={renderRightColumn(i)}>
            <NumberedListItem {...item} index={item.index ? item.index : i + 1} key={i} />
          </If>
        </For>
      </RightColumn>
    </Container>
  )

}
