import React from 'react'

import { PageSectionGroup } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

/**
 * GA Tracking Elements
 *
 * SEO
 * [ ] Link
 * [ ] Description
 * [ ] Tags
 */

const seo = {
  name: 'UCOOK Terms of Use',
  title: 'Terms of Use | UCOOK',
  meta: [
    {
      name: 'description',
      content: 'If you want to learn more about UCOOK\'s policies, head to our terms and conditions page for more information.',
    }, {
      property: 'og:title',
      content: 'UCOOK Terms of Service',
    }, {
      property: 'og:url',
      content: 'https://ucook-co.za/terms',
    }, {
      property: 'og:image',
      content: 'https://ucook.co.za/images/logo.png',
    }, {
      property: 'og:description',
      content: 'If you want to learn more about UCOOK\'s policies, head to our terms and conditions page for more information.',
    },
  ],
}

export function TermsAndConditions(): JSX.Element {

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <PageSectionGroup isLegalDocumentation identifier='TERMS_AND_CONDITIONS' />
    </>
  )

}
