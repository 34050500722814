import React from 'react'

import styled, { CSS, css } from 'styled-components'

import { StaticImage, Tooltip, TooltipContent } from '@client/components'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $size: string }>`
  position: absolute;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('margin-top', { mobile: '10px', tablet: '12px', desktop: '16px' })}
  ${ResponsivePXValue('margin-right', { mobile: '10px', tablet: '12px', desktop: '16px' })}
  ${ResponsivePXValue('padding', { mobile: '4px', tablet: '4px', desktop: '6px' })}
`

const Large = css`
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('width', '24px')}
`

const Small = css`
  ${ResponsivePXValue('height', { mobile: '12px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '12px', tablet: '24px', desktop: '32px' })}
`

const ImageContainer = styled.div<{ $size: string }>`
  display: flex;
  position: relative;

  ${(props): CSS => props.$size === 'large' ? Large : Small}
  
  ${ResponsivePXValue('max-height', { mobile: '16px', tablet: '16px', desktop: '20px' })}
  ${ResponsivePXValue('max-width', { mobile: '16px', tablet: '16px', desktop: '20px' })}
  
  picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export interface DiscoveryVitalityProps {
  className?: string
  iconSize?: 'small' | 'large'
}

export function DiscoveryVitality({ className, iconSize = 'small' }: DiscoveryVitalityProps): JSX.Element {

  const getTooltipContent = (title: string): JSX.Element => <TooltipContent text={title} />

  return (
    <Container className={className} $size={iconSize}>
      <Tooltip
        content={getTooltipContent('HealthyDining')}
        positions={['left']}>
        <ImageContainer $size={iconSize}>
          <StaticImage
            staticId='vitality-indicator-small'
            lazy={false}
            objectFit='contain'
            mobileWidth={768}
            tabletWidth={1024}
            desktopWidth={1600} />
        </ImageContainer>
      </Tooltip>
    </Container>
  )
}

// send through minicard props yayayayay
