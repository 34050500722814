import styled from 'styled-components'

const FlexDeviceContainer = styled.div<{ $mobile?: boolean, $tablet?: boolean, $desktop?: boolean }>`
  position: relative;
  display: ${(props): string => props.$mobile ? 'flex' : 'none'};
  flex: 1;
  ${(props): string => props.theme.tablet} {
    display: ${(props): string => props.$tablet ? 'flex' : 'none'};
  }
  ${(props): string => props.theme.desktop} {
    display: ${(props): string => props.$desktop ? 'flex' : 'none'};
  }
`

const DeviceContainer = styled.div<{ $mobile?: boolean, $tablet?: boolean, $desktop?: boolean }>`
  position: relative;
  display: ${(props): string => props.$mobile ? 'inline' : 'none'};
  ${(props): string => props.theme.tablet} {
    display: ${(props): string => props.$tablet ? 'inline' : 'none'};
  }
  ${(props): string => props.theme.desktop} {
    display: ${(props): string => props.$desktop ? 'inline' : 'none'};
  }
`

export { DeviceContainer, FlexDeviceContainer }
