import React from 'react'

import { Svg } from './Svg'

export interface UserPlusProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function UserPlus({ color, hoverColor, className }: UserPlusProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M21.3334 28V25.3333C21.3334 23.9188 20.7715 22.5623 19.7713 21.5621C18.7711 20.5619 17.4145 20 16 20H6.66671C5.25222 20 3.89567 20.5619 2.89547 21.5621C1.89528 22.5623 1.33337 23.9188 1.33337 25.3333V28" className='stroke' strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.3333 14.6667C14.2789 14.6667 16.6667 12.2789 16.6667 9.33333C16.6667 6.38781 14.2789 4 11.3333 4C8.38781 4 6 6.38781 6 9.33333C6 12.2789 8.38781 14.6667 11.3333 14.6667Z" className='stroke' strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.6666 10.6667V18.6667" className='stroke' stroke="#D7D7CD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30.6666 14.6667H22.6666" className='stroke' stroke="#D7D7CD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100%" height="100%" className='fill' />
        </clipPath>
      </defs>
    </Svg>
  )

}
