import React from 'react'

import styled, { CSS } from 'styled-components'

import { SmallLoader } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const LoaderBox = styled.div<{ size: string }>`
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('height', '32px')}
`

const LoaderContainer = styled.div<{ $height: string }>`
  width: 100%;
  ${(props): CSS => ResponsivePXValue('height', props.$height)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`

export interface SectionLoadingProps {
  height?: string
  loaderSize?: string
}

export function SectionLoading({ height = '80px', loaderSize = '32px' }: SectionLoadingProps): JSX.Element {

  return (
    <LoaderContainer $height={height}>
      <LoaderBox size={loaderSize}>
        <SmallLoader color={theme.colors.oranges.coral} />
      </LoaderBox>
    </LoaderContainer>
  )

}
