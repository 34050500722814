import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator, Spacer } from '@atoms/index'
import { Biography, BiographyProps } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

export interface MoreAboutWineProps {
  aboutWineMaker: BiographyProps
  aboutWine: string[]
  aboutWinery: BiographyProps
}

export function MoreAboutWine({ aboutWineMaker }: MoreAboutWineProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>Have you seen our pairing of the week</Heading>
      <Spacer universal='8px' />
      <Seperator align='center' />
      <Biography {...aboutWineMaker} />
    </Container>
  )
}
