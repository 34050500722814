import React from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { IconEnum, Icon, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $counterStyle: CounterStyle }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  border: 1px solid ${(props): string => props.theme.colors.whites.desertStorm};
  ${(props): CSS => ResponsivePXValue('height', props.$counterStyle.height)}
  ${(props): CSS => ResponsivePXValue('padding', props.$counterStyle.padding)}
  ${ResponsivePXValue('border-radius', '8px')}

  .counter-text {
    ${ResponsivePXValue('line-height', '16px')}
    font-weight: 700;
    padding: 0;
    margin: 0 8px;
    ${(props): CSS => ResponsivePXValue('font-size', props.$counterStyle.fontSize)}
  }
`

const IconContainer = styled.div<{ $disabled: boolean, $counterStyle: CounterStyle }>`

  svg {
    cursor: ${(props): string => props.$disabled ? 'not-allowed' : 'pointer'};
  }

  &:hover {
    opacity: ${(props): string => props.$disabled ? '1' : '0.5'};
  }

  ${(props): CSS => ResponsivePXValue('width', props.$counterStyle.iconSize)}
  ${(props): CSS => ResponsivePXValue('height', props.$counterStyle.iconSize)}
`

interface CounterStyle {
  height: string
  padding: string
  iconSize: string
  fontSize: string
}

export enum CounterVariantEnum {
  STANDARD = 'STANDARD',
  SMALL = 'SMALL'
}

export interface CounterProps {
  amount: number
  onChange: (amount: number) => void
  maximum?: number
  minimum?: number
  loading?: boolean
  disabled?: boolean
  variant?: CounterVariantEnum
  className?: string
}

export function Counter({
  amount, onChange, maximum = Number.MAX_SAFE_INTEGER, minimum = 1, loading = false, disabled = false, variant = CounterVariantEnum.STANDARD, className,
}: CounterProps): JSX.Element {

  const theme = useTheme()
  const addDisabled = disabled || loading || amount >= maximum
  const removeDisabled = disabled || loading || amount <= minimum
  const amountDisabled = disabled || loading
  const amountWarning = amount > maximum

  const _handleIncrease = () => {
    if (!addDisabled) {
      onChange(amount + 1)
    }
  }

  const _handleDecrease = () => {
    if (!removeDisabled) {
      onChange(amount - 1)
    }
  }

  const plusIconColor = addDisabled ? theme.colors.whites.silver : theme.colors.greys.boulder
  const minusIconColor = removeDisabled ? theme.colors.whites.silver : theme.colors.greys.boulder
  const textColor = amountWarning ? theme.colors.misc.error : amountDisabled ? theme.colors.greys.boulder : theme.colors.greys.darkCodGrey

  const smallCounter: CounterStyle = { height: '24px', padding: '0 12px', iconSize: '16px', fontSize: '10px' }
  const standardCounter: CounterStyle = { height: '40px', padding: '0 20px', iconSize: '20px', fontSize: '12px' }
  const counterStyle = variant === CounterVariantEnum.SMALL ? smallCounter : standardCounter

  return (
    <Container $counterStyle={counterStyle} className={className}>
      <IconContainer
        onClick={_handleDecrease}
        $disabled={removeDisabled}
        $counterStyle={counterStyle} >
        <Icon icon={IconEnum.REMOVE} color={minusIconColor} />
      </IconContainer>
      <Paragraph variant='p2' color={textColor} className='counter-text'>{amount}</Paragraph>
      <IconContainer
        onClick={_handleIncrease}
        $disabled={addDisabled}
        $counterStyle={counterStyle} >
        <Icon icon={IconEnum.ADD} color={plusIconColor} />
      </IconContainer>
    </Container>
  )

}
