import React, { useState, useEffect } from 'react'

import { useForm } from 'rc-field-form'
import { FieldData } from 'rc-field-form/lib/interface'
import update from 'react-addons-update'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE, CartPlugin, GlobalModalTypeEnum, ModalsPlugin } from '@api/local'
import { ResponsiveImage, IconEnum } from '@atoms/images'
import { Spacer } from '@atoms/layout'
import { Paragraph, Button, SelectInput, Form } from '@components/index'
import { MiniProductCardContainer, ResponsivePXValue, MiniProductCardImageContainer, ResponsiveProperty, Ellipsis, MiniProductCardContentContainer, ServingSizeEnum } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { UpSellFrozenMealDishFragment, UpSellMarketProductFragment, UpSellWineFragment, useAddDishToUserMenuMutation, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useGetAppQuery, UserCartDocument, useRemoveDishFromUserMenuMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { SiteHelper } from '@lib/SiteHelper'
import { Utilities } from '@lib/Utilities'
import { MealkitModal, ReactivateSubscriptionModal } from '@organisms/modals'
import { FrozenPortionSizeEnum, DeviceTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum, NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  ${MiniProductCardContainer}

  ${ResponsivePXValue('max-width', '414px')}

  overflow: visible !important;
   flex: 1;

  .flex-1 {
    display: flex;
    flex: 1;
  }

  .flex-wrap{
    align-items: center;
    flex-wrap: wrap;
  }

  .serving-size {
     margin-top: 0;
     ${ResponsivePXValue('width', '80px')}

     input {
        font-weight: 500;
        ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '14px', desktop: '14px' })}
        ${ResponsivePXValue('height', '16px')}
        ${ResponsivePXValue('padding-top', '6px')}
        ${ResponsivePXValue('padding-bottom', '4px')}
        ${ResponsivePXValue('padding-left', '32px')}
     }

     .optional-container{
      display: none;
     }
  }

  .loading-container{
     top: 0;
     ${ResponsivePXValue('right', '-8px')}
  }
`

const ImageContainer = styled.div<{ $isCompact: boolean, $isModal: boolean, $isCard?: boolean}>`
  ${MiniProductCardImageContainer}
  ${ResponsivePXValue('max-width', '100px')}

  ${(props): CSS => props.$isCompact ? ResponsivePXValue('display', 'none') : ResponsivePXValue('width', '100px')};
  ${(props): CSS => props.$isModal ? ResponsivePXValue('height', '88px !important') : 'height: 100% !important;'};
  
  img {
    ${(props): CSS => props?.$isCard ? 'border-radius: 4px' : undefined};
  }
`

const LinkContainer = styled.a<{ $isCompact?: boolean, $isCard?: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-top-left-radius: 4px;
  overflow: hidden;

  ${(props): CSS => props.$isCard ? ResponsivePXValue('height', '70%') : ResponsivePXValue('height', { mobile: 'initial', tablet: '100%', desktop: '100%' })};

  ${(props): CSS => props?.$isCard ? undefined : ResponsiveProperty('align-self', { mobile: 'stretch' })};
  ${(props): CSS => props?.$isCompact ? ResponsivePXValue('min-width', '50px') : undefined};
  
  .title {
    ${(props): CSS => props?.$isCompact ? Ellipsis(1) : Ellipsis(2)};
    font-weight: 500;
  }
`

const ContentContainer = styled.div<{ $isCompact?: boolean}>`
  ${MiniProductCardContentContainer}

  ${(props): CSS => props?.$isCompact ? ResponsivePXValue('padding', '6px 8px') : ResponsivePXValue('padding', '12px')};

  flex: 1;
  align-self: stretch;
  justify-content: space-between;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`
const ActionContainer = styled.div<{ $isCompact?: boolean, $isFrozenMealDish?: boolean}>`
  display: flex;

  ${(props): CSS => props.$isCompact ? (props?.$isFrozenMealDish ? ResponsivePXValue('flex-direction', 'column') : ResponsivePXValue('flex-direction', 'row')) : ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })};

  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'center' })}
`
const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

export interface UpSellProductCardProps {
  // meal: FrozenMealDishFragment | MarketProductFragment | WineFragment
  meal: UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment
  containerParentSelector?: string
  isCompact?: boolean
  isModal?: boolean
  isCard?: boolean
  className?: string
  loading?: boolean
}
enum DisplayTypeEnum {
  STANDARD = 'STANDARD',
  PARING = 'PARING',
}

enum MealkitModalEnum {
  NONE = 'NONE',
  SERVING_SIZE = 'SERVING_SIZE',
  RECIPE_CARD = 'RECIPE_CARD',
  ADD_TO_CART = 'ADD_TO_CART',
  COOK_WITHIN = 'COOK_WITHIN',
}

enum MarketProductModalEnum {
  SIGNUP = 'SIGNUP',
  UN_PAUSE = 'UN_PAUSE',
  MARKET = 'MARKET',
  NONE = 'NONE',
}
interface UpSellProductCardState {
  addToCartInterrupted: boolean
  displayingPriceRange: boolean
  price: number | string
  loading: boolean
  displayType:DisplayTypeEnum
  activeMealKitModal: MealkitModalEnum
  activeMarketModal: MarketProductModalEnum
  displayReactivationModal: boolean
  chosenPairing: number
  wineQuantities: number[]
  servingSize: FrozenPortionSizeEnum
  currentCartValues: { [k: string]: number }
  currentAmountInCart: number
  mealKitAmount: number
  portionSize: NumberOfPortionsEnum
}

const DEFAULT_STATE: UpSellProductCardState = {
  addToCartInterrupted: false,
  displayingPriceRange: false,
  price: 0,
  loading: false,
  displayType: DisplayTypeEnum.STANDARD,
  activeMealKitModal: MealkitModalEnum.NONE,
  activeMarketModal: MarketProductModalEnum.NONE,
  displayReactivationModal: false,
  chosenPairing: 0,
  wineQuantities: [],
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  currentCartValues: {},
  currentAmountInCart: null,
  mealKitAmount: null,
  portionSize: NumberOfPortionsEnum.SERVES_2,
}

export function UpSellProductCard({ meal, isCompact = false, isModal = false, isCard = true, className, loading = false }: UpSellProductCardProps): JSX.Element {

  const config = useConfig()
  const [form] = useForm()
  const { addToast } = useToasts()
  const [state, setState] = useState<UpSellProductCardState>({ ...DEFAULT_STATE })

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const [addItemToCart] = useAddItemToCartMutation()
  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const events = useEvents()

  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: NumberOfPortionsEnum.SERVES_2

  const navigate = useNavigate()

  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()

  const productType = meal?.__typename
  const cartItems =userCartData?.currentUser?.activeCart?.cartItems
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const craftMealExcludeCategories = [
    '69e90309-97a2-46b4-8c3e-e447283d9601',
    'cf843967-672c-4bd2-9d46-f593759d5ad3',
    '96ffcec9-e860-4d56-8634-1e70eb9670f3',
  ]

  const isFrozenMealDish = productType === 'FrozenMealDish'
  const isCraftMeal = isFrozenMealDish && meal?.frozenCategories?.some(category => !craftMealExcludeCategories.includes(category.id))
  const isAvailable = (): boolean => {
    let available = true

    if (productType === 'FrozenMealDish') {
      for (let i = 0; i < meal?.products?.length; i++) {
        if (meal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY || meal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE || meal.products[i].availability === ProductAvailabilityEnum.NOT_AVAILABLE) {
          available = false
          break
        }
      }
    } else if (productType === 'MarketProduct') {
      if (meal?.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY ||
      //  meal.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE ||
          meal.availability === ProductAvailabilityEnum.NOT_AVAILABLE) {
        available = false
      }
    } else if (productType === 'Wine') {
      if (meal.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK || meal.stockCount === 0) {
        available = false
      }
    }

    return available
  }
  const getAllSizesAmount = (): number => {
    const cartObject = state.currentCartValues
    let sum = 0
    for (const key in cartObject) {
      if (Object.prototype.hasOwnProperty.call(cartObject, key)) {
        sum += cartObject[key]
      }
    }
    return sum
  }

  const getSpecificAmount = (): number => {

    if (productType === 'FrozenMealDish') {
      const cartObject = state.currentCartValues
      const servingSize = form.getFieldValue('servingSize')
      const mealId = meal?.products?.find(product => product.id === servingSize)?.id
      const number = cartObject[mealId]
      return number

    }

  }

  const mealTitle = meal?.name
  let buttonText

  let pdpLink: string
  let productId = meal.id
  let amount = state.currentCartValues[productId] || 0
  let specificAmount = state.currentCartValues[productId] || 0
  let isOccasionBox = false
  let isWeekendBox = false

  if (productType === 'FrozenMealDish') {
    pdpLink = `/frozen/craft-meal/${meal?.slug}`
    amount = getAllSizesAmount()
    specificAmount = getSpecificAmount() || 0

    const product = meal?.products?.find((product) => { return product.portionSize === state.servingSize })
    productId = product?.id
  } else if (productType === 'MarketProduct') {

    isOccasionBox = meal.isOccasionBox
    isWeekendBox = meal.isWeekendBox

    pdpLink = `/market/${meal?.slug}`

  } else if (productType === 'Wine') {
    pdpLink = `/wine/${meal?.slug}`
  }

  const _handleToggleReactivateSubscriptionModal = (display: boolean): void => {
    const activeModal = display ? MarketProductModalEnum.UN_PAUSE : MarketProductModalEnum.NONE
    setState((prevState) => update(prevState, { activeMarketModal: { $set: activeModal } }))
  }

  const _handleReactivateSubscription = () => {

    _handleToggleReactivateSubscriptionModal(false)
    _handleOnAdd()
    CartPlugin.shared().open()
  }

  const _setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _handleServingSizeSelected = () => {
    setState((prevState) => update(prevState, {
      activeMealKitModal: { $set: MealkitModalEnum.NONE },
    }))
    _handleAddDishToBox()
  }

  const _switchReactivateSubscriptionModal = (display: boolean): void => {
    setState((prevState) => update(prevState, {
      displayReactivationModal: { $set: display },
    }))
  }
  const _onReactivateSubscription = () => {
    _handleAddDishToBox()
    CartPlugin.shared().open()
    _switchReactivateSubscriptionModal(false)
  }
  const _handleAddDishToBox = async (): Promise<void> => {
    _setLoading(true)
    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: meal?.id,
            portionSize: defaultPortions,
          },
        },
      })
      // Analytics

      addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const _handleAddProductToCart = async (): Promise<void> => {

    setState((prevState) => update(prevState, { loading: { $set: true } }))

    const servingSize = form.getFieldValue('servingSize')

    let price
    let itemId
    if (productType === 'FrozenMealDish') {
      price = meal?.products?.find(product => product.id === servingSize)?.price
      itemId = meal?.products?.find(product => product.id === servingSize)?.id
    } else {
      price = meal?.price
      itemId = meal?.id
    }

    try {
      await addItemToCart({
        variables: { productId, quantity: 1 },
      })

      const logData = {
        itemName: meal?.name,
        itemId,
        itemGroupId: meal?.id,
        price,
        itemBrand: 'UCOOK',
        itemCategory: productType,
        itemVariant: 'MobileCard',
        itemListName: productType,
        quantity: 1,
        itemImage: meal?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)

      addToast(`Successfully added (1) ${meal?.name} to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))
  }
  const _handleOnAdd = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, {
      addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() },
    }))
    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if ((isOccasionBox || isWeekendBox) && isAvailable()) {
      _handleAddProductToCart()
    } else if (productType === 'MarketProduct' && userCartData?.currentUser?.isOnDemand) {

      if (userCartData?.currentUser?.checkoutStatus?.hasPausedSubscription) {

        _handleToggleReactivateSubscriptionModal(true)
      } else if (!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
        navigate('/meal-kit/plans')
      }
    } else if (isAvailable()) {
      _handleAddProductToCart()
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }
  const isCardItemInBasket = () => {

    if (productType === 'FrozenMealDish') {
      const product = meal?.products?.find((product) => { return product.portionSize === state?.servingSize })
      const displayedProductId = product?.id

      if (Object.keys(state.currentCartValues).includes(displayedProductId)) {
        setState((prevState) => update(prevState, {
          currentAmountInCart: { $set: state.currentCartValues[displayedProductId] },
        }))
      }
    } else {

      const displayedProductId = meal?.id
      if (Object.keys(state.currentCartValues).includes(displayedProductId)) {
        setState((prevState) => update(prevState, {
          currentAmountInCart: { $set: state.currentCartValues[displayedProductId] },
        }))
      }
    }

  }

  const setCartAmountsObject = () => {

    if (productType === 'FrozenMealDish') {

      const cartObject = state.currentCartValues

      for (const key in cartObject) {
        const isInCart = cartItems.find(item => item.product.id === key)
        if (!isInCart) {
          delete cartObject[key]
        }
      }

      for (let i = 0; i < meal?.products.length; i++) {

        const currentServingSizeId = meal?.products[i].id

        for (let j = 0; j < cartItems?.length; j++) {
          const productId = cartItems[j]?.product?.id
          const quantity = cartItems[j]?.quantity

          if (currentServingSizeId === productId) {
            const currentValuesObject = {
              [productId]: quantity,
            }

            setState((prevState) => update(prevState, {
              currentCartValues: { $merge: currentValuesObject },
            }))
          }
        }
      }
    } else if (productType === 'MarketProduct' || productType === 'Wine') {
      const currentServingSizeId = meal?.id

      for (let j = 0; j < cartItems?.length; j++) {
        const productId = cartItems[j]?.product?.id
        const quantity = cartItems[j]?.quantity

        if (currentServingSizeId === productId) {
          const currentValuesObject = {
            [productId]: quantity,
          }

          setState((prevState) => update(prevState, {
            currentCartValues: { $merge: currentValuesObject },
          }))
        }
      }
    }
  }

  const getMealPriceRange = (meal: UpSellFrozenMealDishFragment): string => {

    const getPortionPrice = (portionSize: FrozenPortionSizeEnum): number => {
      return meal?.products?.find(product => product.portionSize === portionSize)?.price || 0
    }

    const lower = getPortionPrice(FrozenPortionSizeEnum.SERVES_ONE)
    const upper = getPortionPrice(FrozenPortionSizeEnum.SERVES_FOUR)
    return `${lower} - R${upper}`
  }

  const getServingSizeTitle = (product: UpSellFrozenMealDishFragment): string => ServingSizeEnum[product.portionSize].slice(-1) as string

  const getServingSizeOptions = () => {
    if (productType === 'FrozenMealDish') {

      const options = meal?.products?.map((product) => ({
        title: getServingSizeTitle(product),
        disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
        value: product.id,
      })) || []
      return options.sort((a, b) => a.title > b.title ? 1 : -1)
    }

  }

  const getAvailableServing = (): string => {
    const servingSizeOptions = getServingSizeOptions()
    const availableProduct = servingSizeOptions?.find(option => !option.disabled)
    return availableProduct?.value
  }

  const _setPrice = (value: string) => {

    if (productType === 'FrozenMealDish') {
      const selectedMeal = meal?.products?.find(product => product.id === value)
      let price: number | string = selectedMeal?.price || 0
      setState((prevState) => update(prevState, { servingSize: { $set: selectedMeal?.portionSize } }))
      const shouldDisplayPriceRange = price === 0 || !isAvailable() || selectedMeal?.stockCount === 0

      if (shouldDisplayPriceRange) {
        price = getMealPriceRange(meal)
      }

      setState((prevState) => update(prevState, {
        price: { $set: price },
        displayingPriceRange: { $set: shouldDisplayPriceRange },
      }))

    } else if (productType === 'Wine' || productType === 'MarketProduct') {
      setState((prevState) => update(prevState, {
        price: { $set: meal?.price },
      }))

    }

  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSize') {
          _setPrice(field.value)
        }
        if (name === 'numberOfPortions') {
          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
          }))
        }
      })
    })
  }

  const _handleOnRemove = async (): Promise<void> => {

    const product = meal

    try {
      setState((prevState) => update(prevState, { loading: { $set: true } }))

      if (state.currentCartValues[productId] === 1) {
        await removeItemFromCart({
          variables: {
            productId,
          },
        })
        const currentValuesObject = state.currentCartValues
        if (currentValuesObject[productId]) {
          delete currentValuesObject[productId]
        }
        setState((prevState) => update(prevState, { currentCartValues: { $set: currentValuesObject } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId,
            quantity: state.currentCartValues[productId] - 1,
          },
        })
      }

      const logData = {
        itemName: product?.name,
        itemId: productId,
        price: Number(state.price),
        itemBrand: 'UCOOK',
        itemCategory: productType,
        itemVariant: productType,
        itemListName: productType,
        quantity: 1,
        itemImage: product?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    _handleOnAdd()
  }

  const _handlePdpNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(pdpLink)
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  useEffect(() => {
    const availableServing = getAvailableServing()
    form.setFieldsValue({
      servingSize: availableServing,
    })
    _setPrice(availableServing)
  }, [])

  useEffect(() => {
    setCartAmountsObject()
    isCardItemInBasket()
  }, [userCartData, state.servingSize])

  useEffect(() => {

    if (productType === 'Wine' || productType === 'MarketProduct') {
      const price = meal?.price
      setState((prevState) => update(prevState, {
        price: { $set: price },
      }))
    }
  }, [meal])

  if (state.displayingPriceRange) {
    buttonText = 'OUT OF STOCK'
  } else if (productType === 'MarketProduct' && userCartData?.currentUser?.isOnDemand) {
    if (isOccasionBox || isWeekendBox) {
      buttonText = 'ADD TO'
    } else {
      buttonText = 'GET STARTED'
    }
  } else {
    buttonText = isAvailable() ? 'ADD TO' : 'OUT OF STOCK'
  }

  // if (amount > 0) {
  //   console.log('state.currentCartValues => ', state.currentCartValues, ' meal id => ', meal.id, ' amount => ', amount, ' specificAmount => ', specificAmount)
  // }

  return (
    <Container className={className}>
      <MealkitModal activeModal={state.activeMealKitModal} onSubmit={_handleServingSizeSelected} onClose={() => { setState({ ...state, activeMealKitModal: MealkitModalEnum.NONE }) }} />
      <ReactivateSubscriptionModal
        open={state.displayReactivationModal}
        onReactivation={_onReactivateSubscription}
        onClose={() => { _switchReactivateSubscriptionModal(false) }} />
      <Form className='flex-1 flex-wrap' form={form} onFieldsChange={_handleChange}>
        <LinkContainer onClick={_handlePdpNavigation} $isCompact={isCompact} $isCard={isCard}>
          <ImageContainer $isCompact={isCompact} $isModal={isModal} $isCard={isCard}>
            <ResponsiveImage image={meal.coverImage} lazy={false} />
          </ImageContainer>
        </LinkContainer>
        <ContentContainer className='fluid-grow' $isCompact={isCompact}>
          <LinkContainer onClick={_handlePdpNavigation} $isCompact={isCompact}>
            <Paragraph className='title' variant='p2'> {mealTitle} </Paragraph>
          </LinkContainer>
          <Spacer mobile='8px' desktop='12px' />
          <ActionContainer $isCompact={isCompact} $isFrozenMealDish={isFrozenMealDish}>
            <PriceContainer>
              <Paragraph variant='p5' bold> {`R${state.price}`} </Paragraph>
              <Spacer className='flex-1' variant='horizontal' universal='8px' />
              <If condition={isCraftMeal}>
                <SelectInput
                  icon={IconEnum.PERSON}
                  readOnly
                  name='servingSize'
                  className='serving-size'
                  options={[...getServingSizeOptions()]}
                  rules={[{ required: true, message: 'Please select serving size' }]} />
              </If>
            </PriceContainer>
            <Spacer mobile='12px' />
            <Button
              className='mini-add-button'
              compact
              icon={isAvailable() ? IconEnum.CART : undefined}
              title={buttonText}
              color='black'
              size='small'
              fullWidth={isMobile}
              isMiniCardButton
              loading={loading || state.loading}
              disabled={loading || state.displayingPriceRange}
              amount={amount}
              amountServingSizeSpecific={specificAmount}
              onClick={_handleOnCenterClick}
              onLeftIconClick={_handleOnRemove}
              onRightIconClick={_handleOnAdd} />
          </ActionContainer>
        </ContentContainer>
      </Form>
      <ReactivateSubscriptionModal
        open={state.activeMarketModal === MarketProductModalEnum.UN_PAUSE}
        onReactivation={_handleReactivateSubscription}
        onClose={() => { _handleToggleReactivateSubscriptionModal(false) }} />
    </Container>
  )
}
