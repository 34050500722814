import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type Spacing = '4px' | '8px' | '12px' | '16px' | '24px' | '32px' | '40px' | '48px' | '50px' |'56px' | '64px' | '80px'

const Container = styled.div<{ $variant: 'vertical' | 'horizontal', $mobile: Spacing, $tablet: Spacing, $desktop: Spacing }>`
   position:relative;

   display: ${(props): string => props?.$mobile ? 'flex' : 'none'};
   ${(props) => props?.$mobile ? props.$variant === 'vertical' ? ResponsivePXValue('height', props?.$mobile) : ResponsivePXValue('width', props?.$mobile) : null}
  

  ${(props): string => props.theme.tablet} {
    display: ${(props): string => props?.$tablet ? 'flex' : 'none'};
    ${(props) => props?.$tablet ? props.$variant === 'vertical' ? ResponsivePXValue('height', props?.$tablet) : ResponsivePXValue('width', props?.$tablet) : null}
  }

  ${(props): string => props.theme.desktop} {
    display: ${(props): string => props?.$desktop ? 'flex' : 'none'};
    ${(props) => props?.$desktop ? props.$variant === 'vertical' ? ResponsivePXValue('height', props?.$desktop) : ResponsivePXValue('width', props?.$desktop) : null}
    ${(props) => props?.$desktop ? props.$variant === 'vertical' ? null : ResponsivePXValue('min-width', props?.$desktop) : null}
  }

`
export interface SpacerProps {
  variant?: 'vertical' | 'horizontal'
  mobile?: Spacing
  tablet?: Spacing
  desktop?: Spacing
  universal?: Spacing
  className?: string
}

export function Spacer({ variant = 'vertical', mobile, tablet, desktop, universal, className }: SpacerProps): JSX.Element {

  if (universal) {
    mobile = universal
    tablet = universal
    desktop = universal
  }

  if (mobile && !tablet) {
    tablet = mobile
  }

  return (
    <Container $variant={variant} $mobile={mobile} $tablet={tablet} $desktop={desktop} className={className}></Container >
  )
}
