import React from 'react'

import styled from 'styled-components'

import { ContentSeperator, ContentSeperatorTypeEnum, Paragraph, Spacer } from '@atoms/index'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'

export interface BundleTipsProps {
  tips: string[]
}

const Container = styled.div`
  ${ResponsivePXValue('padding', { mobile: '28px', tablet: '30px', desktop: '32px' })}

  .tip-text {
    ${ZeroSpace};
    ${ResponsivePXValue('line-height', '24px')}
  }
`

export function BundleTips({ tips }: BundleTipsProps): JSX.Element {

  let index: number
  let tip: string

  return (
    <Container>
      <For each='tip' of={tips || []} index='index'>
        <Paragraph variant='p2' className='tip-text'>{`Chef tips ${index + 1}`}</Paragraph>
        <Spacer variant='horizontal' universal='8px' />
        <Paragraph variant='p3' className='tip-text'>{tip}</Paragraph>
        <If condition={index < tips.length - 1}>
          <ContentSeperator margin='16px 0' type={ContentSeperatorTypeEnum.THICK} />
        </If>
      </For>
    </Container>
  )

}
