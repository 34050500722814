import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MarketProductStore')

const MarketProductStore = loadable(() => import(/* webpackChunkName: "market-product-store" */'@pages/MarketProductStore'), {
  resolveComponent: (components) => components.MarketProductStore,
})

export function MarketProductStoreAsync(): JSX.Element {

  return <MarketProductStore fallback={<div>Loading...</div>} />

}
