import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/WineStore')

const WineStore = loadable(() => import(/* webpackChunkName: "wine-store" */'@pages/WineStore'), {
  resolveComponent: (components) => components.WineStore,
})

export function WineStoreAsync(): JSX.Element {

  return <WineStore fallback={<div>Loading...</div>} />

}
