import React from 'react'

import { MiniProductGrid } from '@client/components/molecules'
import { FrozenMealDishListFragment, WineListFragment, MealKitDishListFragment, MarketProductListFragment } from '@hooks/api'

type CombinedProduct = FrozenMealDishListFragment | MealKitDishListFragment | WineListFragment | MarketProductListFragment

export function renderMiniProductGrids(products: CombinedProduct[]): JSX.Element[] {

  const batchSize = 4
  const miniProductGrids = []

  for (let i = 0; i < products?.length; i += batchSize) {
    const batch = products.slice(i, i + batchSize)
    miniProductGrids.push(
      <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} isAisle/>,
    )
  }

  return miniProductGrids
}
