import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph, Button, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '100px', desktop: '40px' })}
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: 'CALC(100% - 200px)', desktop: '600px' })}
`

export interface ErrorBlockProps {
  title: string
  description?: string
  actionTitle?: string
  onClick?: () => void
  children?: JSX.Element | JSX.Element[]
  className?: string
}

export function ErrorBlock({
  title,
  description = 'Get in touch with Customer Support on 021 447 4424 or email us at support@ucook.co.za',
  actionTitle = 'Back',
  onClick,
  children = [],
  className,
}: ErrorBlockProps): JSX.Element {

  const _handleClick = (): void => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Container className={className}>
      <Heading variant='h6' align='center'>{title}</Heading>
      <Paragraph variant='p1' align='center'>{description}</Paragraph>
      <If condition={!!onClick}>
        <Spacer universal='24px' />
        <Button
          title={actionTitle}
          color='black'
          onClick={_handleClick} />
      </If>
      {children}
    </Container>
  )
}
