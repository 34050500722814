import React, { useEffect, useRef, useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { Accordion, DeviceContainer, getPageSectionBackground, Heading, PageSectionPadding, ResponsivePXValue, Seperator, Spacer, Tab, TabPane, Tabs, TextListItem } from '@client/components'
import { PageTabFragment, PageTabSectionFragment, PageTabItemFragment } from '@hooks/api'

import { TextList } from '../tables'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string }>`
 display: flex;
 flex-direction: column;
 flex: 1;

 ${PageSectionPadding}

 background: ${(props): string => props.$background};

 .text-list {
    ${ResponsivePXValue('padding', '0 10px')}
 }

`
export interface PageTabSectionProps {
  pageSection: PageTabSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

interface PageTabSectionState {
  activeTab: string
}

const DEFAULT_STATE: PageTabSectionState = {
  activeTab: '',
}

export function PageTabSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageTabSectionProps): JSX.Element {

  const [state, setState] = useState<PageTabSectionState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  const pageTabs = pageSection?.pageTabs || []
  const background = getPageSectionBackground(pageSection?.background)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const getTextListItems = (pageTab: PageTabFragment): TextListItem[] => {
    return pageTab.pageTabItems?.map((pageTabItem: PageTabItemFragment) => ({
      title: pageTabItem.tabItemTitle,
      subTitle: pageTabItem?.description || null,
    }))
  }

  useEffect(() => {
    const activeTab = pageSection?.activeTab || pageSection?.pageTabs[0].header
    setState((prevState) => ({ ...prevState, activeTab }))
  }, [])

  let index: number
  let pageTab: PageTabFragment

  return (
    <Container ref={pageRef} $background={background}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h4' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='8px' />
            <Seperator align='center' />
          </If>
          <DeviceContainer $mobile>
            <Accordion
              activeTab={state.activeTab}
              backgroundColor={theme.colors.whites.pureWhite}
              onTabChange={(activeTab: string): void => setState(prevState => ({ ...prevState, activeTab }))}>
              <For each='pageTab' of={pageTabs} index='index'>
                <Tab tab={pageTab.header} title={pageTab.header} key={index}>
                  <TextList items={getTextListItems(pageTab)} className='text-list' />
                </Tab>
              </For>
            </Accordion>
          </DeviceContainer>
          <DeviceContainer $tablet $desktop>
            <Tabs
              activeTab={state.activeTab}
              backgroundColor={theme.colors.whites.pureWhite}
              onTabChange={(activeTab: string): void => setState(prevState => ({ ...prevState, activeTab }))}>
              <For each='pageTab' of={pageTabs} index='index'>
                <TabPane tab={pageTab.header} title={pageTab.header} key={index}>
                  <TextList items={getTextListItems(pageTab)} />
                </TabPane>
              </For>
            </Tabs>
          </DeviceContainer>
        </When>
        <Otherwise>
          Page Tab Section not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
