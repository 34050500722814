import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'

import { CheckoutPlugin } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Modal } from '@client/components/molecules'
import { useGetModalsQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'
import { LoginForm } from '@organisms/index'

import { CellphoneVerificationForm, EnterNewCellphoneNumberForm } from '../forms'
import { RequestPasswordResetForm } from '../forms/RequestPasswordResetForm'

enum LogInDisplayStepEnum {
  CREDENTIALS = 'CREDENTIAL',
  ENTER_NUMBER = 'ENTER_NUMBER',
  VERIFY_NUMBER = 'VERIFY_NUMBER',
  PASSWORD_RESET = 'PASSWORD_RESET',
}

interface LoginModalState {
  displayStep: LogInDisplayStepEnum
  previousStep: LogInDisplayStepEnum
  error: string
  email: string
}

const DEFAULT_STATE: LoginModalState = {
  displayStep: LogInDisplayStepEnum.CREDENTIALS,
  previousStep: LogInDisplayStepEnum.CREDENTIALS,
  error: '',
  email: '',
}

export function LoginModal(): JSX.Element {

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<LoginModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const _handleClose = (): void => {
    CheckoutPlugin.shared().reset()
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleSuccess = async (): Promise<void> => {
    CheckoutPlugin.shared().didLogIn(client)
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleSignUp = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
  }

  const switchStep = (displayStep: LogInDisplayStepEnum): void => {
    const currentStep = state.displayStep
    setState((prevState) => update(prevState, {
      displayStep: { $set: displayStep },
      previousStep: { $set: currentStep },
    }))
  }

  const _handleLogIn = async (hasCellphoneNumber: boolean, cellphoneIsVerified: boolean): Promise<void> => {
    if (!cellphoneIsVerified) {
      if (!hasCellphoneNumber) {
        switchStep(LogInDisplayStepEnum.ENTER_NUMBER)
      } else {
        switchStep(LogInDisplayStepEnum.VERIFY_NUMBER)
      }
    } else {
      _handleSuccess()
    }
  }

  useEffect(() => {
    if (data?.modals?.logIn) {
      switchStep(LogInDisplayStepEnum.CREDENTIALS)
    }
  }, [data?.modals?.logIn])

  let title = 'Login'
  switch (state.displayStep) {
    case LogInDisplayStepEnum.ENTER_NUMBER:
      title = 'Verify your account'
      break
    case LogInDisplayStepEnum.VERIFY_NUMBER:
      title = 'Verify your account'
      break
    case LogInDisplayStepEnum.PASSWORD_RESET:
      title = 'Forgot password'
      break
  }

  return (
    <Modal
      open={data?.modals?.logIn}
      title={title}
      titleAlign='left'
      fullscreen={false}
      showCloseButton={state.displayStep !== LogInDisplayStepEnum.PASSWORD_RESET}
      onClose={_handleClose}>
      <ModalFormContainer>
        <Choose>
          <When condition={state.displayStep === LogInDisplayStepEnum.ENTER_NUMBER}>
            <EnterNewCellphoneNumberForm
              onNumberChanged={() => switchStep(LogInDisplayStepEnum.VERIFY_NUMBER)} />
          </When>
          <When condition={state.displayStep === LogInDisplayStepEnum.VERIFY_NUMBER}>
            <CellphoneVerificationForm
              onSuccess={_handleSuccess}
              onChangeNumber={() => switchStep(LogInDisplayStepEnum.ENTER_NUMBER)} />
          </When>
          <When condition={state.displayStep === LogInDisplayStepEnum.PASSWORD_RESET}>
            <RequestPasswordResetForm
              onCancel={() => switchStep(LogInDisplayStepEnum.CREDENTIALS)}
              onClose={_handleClose} />
          </When>
          <Otherwise>
            <LoginForm
              onPasswordReset={() => switchStep(LogInDisplayStepEnum.PASSWORD_RESET)}
              onSignUp={_handleSignUp}
              onLoggedIn={_handleLogIn} />
          </Otherwise>
        </Choose>
      </ModalFormContainer>
    </Modal>
  )
}
