import React from 'react'

import styled, { CSS, css } from 'styled-components'

import { MediumBoxShadow, ResponsivePXValue } from '@client/components/Theme'

const ToggleBackground = styled.div<{ $selected: boolean, $disabled: boolean}>`
  position: relative;
  top:0;
  left: 0;
  ${ResponsivePXValue('width', '64px')}
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('border-radius', '40px')}
  background-color: ${(props): string => props.theme.colors.misc.error};
  transition: transform 0.3s ease-in-out;
  ${ResponsivePXValue('box-shadow', 'inset 0px 0px 9px rgba(0, 0, 0, 0.15)')}
  cursor: pointer;
  ${(props): CSS => {
    if (props.$selected && !props.$disabled) {
      return `
        background-color: ${props.theme.colors.greens.greenSmoke};
      `
    }
    if (props.$disabled) {
      return `
        background-color: ${props.theme.colors.greys.silver};
      `
    }
  }}
`

const Selected = css`
  ${ResponsivePXValue('left', '0')}
  transform: translateX(150%);
  transition: transform 0.3s;
`

const ToggleSwitch = styled.div<{$selected: boolean}>`
  position: absolute;
  border-radius:50%;
  z-index: 1;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('top', '4px')}
  ${ResponsivePXValue('left', '4px')}
  transition: transform 0.3s;
  ${MediumBoxShadow}
  ${(props): CSS => {
    if (props.$selected) {
      return Selected
    }
  }}
`

export interface ToggleProps {
  selected: boolean
  disabled?: boolean
  onChange: ()=>void
}

export function Toggle({ selected, disabled = false, onChange }: ToggleProps): JSX.Element {

  const handleClick = () => {
    if (!disabled) {
      onChange()
    }
  }
  return (
    <ToggleBackground $selected={selected} $disabled={disabled} onClick={handleClick}>
      <ToggleSwitch $selected={selected}/>
    </ToggleBackground>
  )

}
