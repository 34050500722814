import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import {
  ContentSeperator, Heading, IconEnum, MobileMenuUserAccount, Icon, Pill, Button, Spacer,
} from '@client/components'
import { Drawer, SectionLoading } from '@client/components/molecules'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { applicationMainNavigation } from '@client/Navigation'
import {
  useUserDetailsQuery, useGetNavigationQuery, useGetAppQuery, NavItemFragment,
  MainNavItemFragment, MainNavMultiItemContainerFragment,
} from '@hooks/api'

import { MobileNavigationBar } from './MobileNavigationBar'

export const MenuNavItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  ${ResponsivePXValue('height', '56px')}
  ${ResponsivePXValue('padding', '0 16px')}

  .nav-item-header {
    line-height: 1;
  }

  .sub-menu-nav-item-header {
    ${ResponsivePXValue('padding', '0 8px')}
    line-height: 1;
  }

  .sub-nav-item-header {
    ${ResponsivePXValue('padding', '0 12px')}
    font-weight: 300;
    line-height: 1;
  }
`

const MenuIconContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('margin', '0 8px')}
`
const ContentContainer = styled.div`
  display: flex;
  flex: 1;

  .pill {
    align-self: center;
   
    ${ResponsivePXValue('max-height', '18px')}
    ${ResponsivePXValue('margin-left', '8px')}
    ${ResponsivePXValue('margin-top', '5px')}
    ${ResponsivePXValue('padding-top', '5px')}

    .title {
      ${ResponsivePXValue('font-size', '10px')}
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('padding', '16px')}

  .mobile-menu-footer-button {
    flex-grow: 1;
  }
`

export function MobileMenu(): JSX.Element {

  const config = useConfig()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _handleNavClicked = (navItem: NavItemFragment): void => {
    const path = NavigationPlugin.shared().selectNav(navItem?.id, false)
    const mainItem = navItem as MainNavItemFragment
    if (mainItem?.isExternalNavigation && config.isBrowser()) {
      window.location.href = path
    } else if (path) {
      _handleClose()
      setTimeout(() => {
        navigate(path)
      }, 300)
    } else if (!isNativeApplication) {
      NavigationPlugin.shared().toggleSubMenu(navItem)
    } else {
      NavigationPlugin.shared().toggleSubMenuApplication(navItem)
    }
  }

  const _handleLogIn = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    _handleClose()
  }

  const _handleSignUp = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    _handleClose()
  }

  const _handleClose = (): void => {
    NavigationPlugin.shared().toggleMainMenu()
  }

  const _handleOpenUserMenu = (): void => {
    NavigationPlugin.shared().toggleUserMenu()
  }

  const _isDealsNavItem = (navItem: MainNavMultiItemContainerFragment): boolean => {
    return navItem.id === 'DEALS' || (navItem).title.toLocaleLowerCase() === 'deals'
  }

  const _moveDealsToTop = (navItems: MainNavMultiItemContainerFragment[]) => {

    const navItemsCopy = [...navItems]

    if (!navItems) {
      return []
    }
    const dealsIndex = navItemsCopy.findIndex(
      (item) => _isDealsNavItem(item),
    )

    if (dealsIndex === -1) {
      throw new Error('Deals nav item not found')
    }

    const dealsNavItem = navItemsCopy.splice(dealsIndex, 1)[0]
    navItemsCopy.unshift(dealsNavItem)

    return navItemsCopy
  }

  let navItem: MainNavMultiItemContainerFragment

  let $header = (
    <MobileNavigationBar
      showLogo={false}
      icon={IconEnum.CLOSE_OUTLINE}
      variant='menu'
      onIconClick={_handleClose} />
  )
  // if isApp, loop through all else loop through all but HELP
  const navigationItemObject = _moveDealsToTop(navigationData?.navigation?.items as unknown as MainNavMultiItemContainerFragment[])
  const navigationItemObjectAddition = applicationMainNavigation
  if (isNativeApplication) {
    $header = (
      <></>
    )
  }

  const isRegisteredUser = userDetailsData?.currentUser?.__typename === 'RegisteredUser'
  const hasPillTitle = (navItem: MainNavMultiItemContainerFragment) => navItem?.pillTitle && navItem?.pillTitle !== ''

  return (
    <>
      <Drawer
        open={navigationData?.navigation?.mobileMenuOpen}
        header={$header} >
        <Choose>
          <When condition={userDetailsLoading}>
            <SectionLoading />
          </When>
          <Otherwise>
            <If condition={!isNativeApplication}>
              <If condition={isRegisteredUser}>
                <MobileMenuUserAccount onClick={_handleOpenUserMenu} />
              </If>
            </If>

            <For each='navItem' of={navigationItemObject || []}>
              <MenuNavItemContainer key={navItem?.id} onClick={() => _handleNavClicked(navItem)}>
                <ContentContainer>
                  <Heading variant='h6' className={`nav-item-header ${_isDealsNavItem(navItem) ? 'deals-nav-item' : ''}`}>{navItem?.title?.toUpperCase()}</Heading>
                  <If condition={hasPillTitle(navItem)}>
                    <Pill
                      className='pill'
                      title={navItem.pillTitle}
                      backgroundColor={theme.colors.yellows.selectiveYellow}
                    />
                  </If>
                </ContentContainer>
                <MenuIconContainer>
                  <Icon icon={IconEnum.CHEVRON_FORWARD_OUTLINE} color={theme.colors.greys.liteCodGrey} />
                </MenuIconContainer>
              </MenuNavItemContainer>
              <ContentSeperator margin='0 16px' />
            </For>

            <If condition={isNativeApplication}>
              <For each='navItem' of={navigationItemObjectAddition || []}>
                <MenuNavItemContainer key={navItem?.id} onClick={() => _handleNavClicked(navItem)}>
                  <ContentContainer>
                    <Heading variant='h6' className='nav-item-header'>{navItem?.title?.toUpperCase()}</Heading>
                    <If condition={hasPillTitle(navItem)}>
                      <Pill
                        className='pill'
                        title={navItem.pillTitle}
                        backgroundColor={theme.colors.yellows.selectiveYellow}
                      />
                    </If>
                  </ContentContainer>
                  <MenuIconContainer>
                    <Icon icon={IconEnum.CHEVRON_FORWARD_OUTLINE} color={theme.colors.greys.liteCodGrey} />
                  </MenuIconContainer>
                </MenuNavItemContainer>
                <ContentSeperator margin='0 16px' />
              </For>
            </If>
            <If condition={userDetailsData?.currentUser?.__typename === 'GuestUser'}>
              <ButtonsContainer>
                <Button
                  title='SIGN UP'
                  onClick={_handleSignUp}
                  fullWidth/>
                <Spacer universal='16px' variant='horizontal' />
                <Button
                  color='orange'
                  title='LOG IN'
                  onClick={_handleLogIn}
                  variant='secondary'
                  fullWidth />
              </ButtonsContainer>
            </If>
          </Otherwise>
        </Choose>
      </Drawer>
    </>
  )

}
