import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { StaticImage, Paragraph, Link, Spacer, Button } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;

  .text-element {
    margin: 0px
  }
`

const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InnerProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const ImageContainer = styled.div`
  overflow: hidden;
  width: 72px;
  height: 72px;
  border-radius: 8px;
`

const HorizontalSpacer = styled.div<{ $width: string }>`
  ${(props): CSS => {
    if (props.$width) {
      return ResponsivePXValue('width', props.$width)
    }
    return ResponsivePXValue('width', '0')
  }}
`

interface AvailableProduct {
  enum: ProductRangeEnum
  staticId: string
  productName: string
  href: string
}

export interface SomeItemsUnavailableFormProps {
  productRange: string
  availableRangeList: AvailableProduct[]
  onProductClicked: () => void
  onNotify: () => void
}

export function SomeItemsUnavailableForm({ productRange, availableRangeList, onProductClicked, onNotify }: SomeItemsUnavailableFormProps): JSX.Element {

  let product: AvailableProduct
  const navigate = useNavigate()

  const _handleOnNotify = () => {
    onNotify?.()
  }

  const _handleProductOnClick = (href: string) => {
    onProductClicked?.()
    navigate(href)
  }

  return (
    <Container>
      <Paragraph variant='p1' color={theme.colors.greys.liteCodGrey} align='center' className='text-element'>{`Oh no! It looks like we are unable to deliver ${productRange} to your area just yet. Here are other UCOOK goodies available to you.`}</Paragraph>
      <Spacer universal='32px' />
      <For each='product' index='index' of={availableRangeList}>
        <ProductContainer>
          <InnerProductContainer>
            <ImageContainer>
              <StaticImage
                staticId={product.staticId}
                objectFit='cover'
                mobileWidth={72}
                mobileHeight={72}
                tabletHeight={72}
                desktopHeight={72}
                tabletWidth={72}
                desktopWidth={72} />
            </ImageContainer>
            <HorizontalSpacer $width='16px' />
            <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} className='text-element'>{product.productName}</Paragraph>
          </InnerProductContainer>
          <HorizontalSpacer $width='16px' />
          <Link onClick={() => _handleProductOnClick(product.href)}>
            Shop now
          </Link>
        </ProductContainer>
        <Spacer universal='16px' />
      </For>
      <Spacer universal='32px' />
      <Button className='notify-me-button' title='Notify me on delivery updates' onClick={_handleOnNotify} />
    </Container>
  )

}
