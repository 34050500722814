import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { css, useTheme } from 'styled-components'

import { Heading, Paragraph, Seperator, Spacer } from '@atoms/index'
import { Form, useForm, FieldData, RadioButton, RadioButtonOption, RadioButtonProps } from '@client/components/molecules'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { NutritionalInfoFragment } from '@hooks/api'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '32px 24px', desktop: '32px' })}

  .radio-choice {
    ${ZeroSpace}
  }

  .spacer {
    display: flex;
    flex-grow: 1;
  }
`

const NutriText = css`
  ${ZeroSpace};

  font-weight: 400;

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const NutritionWrap = styled.div`
  display: flex;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  :nth-of-type(odd) {
   background-color: ${(props): string => props.theme.colors.whites.alabaster};
  }

  ${ResponsivePXValue('padding', { mobile: '10px', tablet: '12px', desktop: '16px' })}

  div {
    ${NutriText};
  }
`

const Value = styled.div<{ $bold?: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${ResponsivePXValue('min-width', { mobile: null, tablet: '180px', desktop: '180px' })}

  div {
    font-weight: ${(props): string => props.$bold ? '700' : '400'}
  }
`

const HeaderContainer = styled.div`
  h5, h6 {
     ${ZeroSpace};

     ${ResponsivePXValue('font-size', '14px')}
     ${ResponsivePXValue('line-height', '24px')}
     ${ResponsivePXValue('margin-bottom', '8px')}
  }
`
const TableHeaderContainer = styled.div`
  h6 {
     ${ZeroSpace};

     ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '12px' })}
     ${ResponsivePXValue('line-height', '24px')}
  }
`
const TextContainer = styled.div`
  div {
   ${NutriText};
  }
`
const NutritionRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${ResponsivePXValue('margin-bottom', { mobile: '32px', tablet: '24px', desktop: '16px' })}
`

export interface NutritionalInfoProps {
  nutrition: NutritionalInfoFragment
  nutritionPerServing?: NutritionalInfoFragment
  allergens?: string
}

interface NutritionRowProps {
  name: string
  value1?: string | number
  value2?: string | number
  bold?: boolean
  unit?: string
}

function NutritionRow({ name, value1, value2, bold, unit }: NutritionRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <If condition={!!value1 || !!value2}>
      <NutritionWrap>
        <If condition={bold}>
          <TableHeaderContainer>
            <Heading variant='h6' color={theme.colors.greys.darkCodGrey}>
              {name}
            </Heading>
          </TableHeaderContainer>
        </If>
        <If condition={!bold}>
          <Paragraph variant='p1'>{name}</Paragraph>
        </If>
        <Spacer className='spacer' />
        <If condition={!!value1}>
          <Value $bold={bold}>
            <Paragraph variant='p2'>{value1}</Paragraph>
            <If condition={!!unit}>
              <Paragraph variant='p2'>{unit}</Paragraph>
            </If>
          </Value>
        </If>
        <If condition={!!value2}>
          <Value $bold={bold}>
            <Paragraph variant='p2'>{value2}</Paragraph>
            <If condition={!!unit}>
              <Paragraph variant='p2'>{unit}</Paragraph>
            </If>
          </Value>
        </If>
      </NutritionWrap>
    </If>
  )
}

interface NutritionalInfoState {
  nutritionType: 'per100g' | 'perServing'
}

const DEFAULT_STATE: NutritionalInfoState = {
  nutritionType: 'per100g',
}

export function NutritionalInfo({ nutrition, nutritionPerServing, allergens }: NutritionalInfoProps): JSX.Element {

  const [state, setState] = useState<NutritionalInfoState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const [form] = useForm()
  const seperatorAlign: 'left' | 'center' | 'right' = 'left'

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'nutritionType') {
          setState((prevState) => update(prevState, {
            nutritionType: { $set: field.value },
          }))
        }
      })
    })
  }

  const options = [{
    title: 'PER SERVING',
    value: 'perServing',
  }, {
    title: 'PER 100G',
    value: 'per100g',
  }]

  const nutritionRadioOptions = () => {
    return options.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
      }
    }) as unknown as RadioButtonOption[]
  }

  const nutritionOptionsRadioProps: RadioButtonProps = {
    className: 'radio-container',
    name: 'nutritionType',
    outline: false,
    options: [...nutritionRadioOptions()],
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  let columnOne: NutritionalInfoFragment
  let columnTwo: NutritionalInfoFragment
  let titleOne: string
  let titleTwo: string

  if (theme.isDesktop()) {
    if (nutritionPerServing) {
      columnOne = nutritionPerServing
      columnTwo = nutrition
      titleOne = 'Per serving'
      titleTwo = 'Per 100g'
    } else {
      columnOne = nutrition
      titleOne = 'Per 100g'
    }
  } else {
    if (state.nutritionType === 'per100g') {
      columnOne = nutrition
      titleOne = 'Per 100g'
    } else {
      columnOne = nutritionPerServing
      titleOne = 'Per serving'
    }
  }

  form.setFieldsValue({
    nutritionType: state.nutritionType,
  })

  return (
    <Container>
      <HeaderContainer>
        <DeviceContainer $mobile $tablet>
          <Heading variant='h5' color={theme.colors.greys.darkCodGrey}> Nutritional Information </Heading>
        </DeviceContainer>
        <DeviceContainer $desktop>
          <Heading variant='h6' color={theme.colors.greys.darkCodGrey}> Nutritional Information </Heading>
        </DeviceContainer>
      </HeaderContainer>
      <Seperator align={seperatorAlign} />
      <If condition={!!nutritionPerServing}>
        <DeviceContainer $mobile $tablet>
          <Form form={form} onFieldsChange={_handleChange}>
            <RadioButton {...nutritionOptionsRadioProps} />
          </Form>
          <Spacer universal='16px' />
        </DeviceContainer>
      </If>
      <NutritionRowContainer>
        <NutritionRow
          name='Typical value'
          value1={titleOne}
          value2={titleTwo}
          bold />
        <NutritionRow
          name='Energy (kJ)'
          value1={columnOne?.energyInKilojoules}
          value2={columnTwo?.energyInKilojoules}
          unit={'kJ'} />
        <NutritionRow
          name='Energy (Kcal)'
          value1={columnOne?.energyInKiloCalories}
          value2={columnTwo?.energyInKiloCalories}
          unit={'Kcal'} />
        <NutritionRow
          name='Protein (g)'
          value1={columnOne?.protein}
          value2={columnTwo?.protein}
          unit={'g'} />
        <NutritionRow
          name='Glycaemic Carbohydrate (g)'
          value1={columnOne?.carbs}
          value2={columnTwo?.carbs}
          unit={'g'} />
        <NutritionRow
          name='of which total sugar (g)'
          value1={columnOne?.sugars}
          value2={columnTwo?.sugars}
          unit={'g'} />
        <NutritionRow
          name='Total Fat(g)'
          value1={columnOne?.fat}
          value2={columnTwo?.fat}
          unit={'g'} />
        <NutritionRow
          name='of which saturated fat (g)'
          value1={columnOne?.saturatedFat}
          value2={columnTwo?.saturatedFat}
          unit={'g'} />
        <NutritionRow
          name='Cholesterol (mg)'
          value1={columnOne?.cholesterol}
          value2={columnTwo?.cholesterol}
          unit={'mg'} />
        <NutritionRow
          name='Dietary Fibre (g)'
          value1={columnOne?.fibre}
          value2={columnTwo?.fibre}
          unit={'g'} />
        <NutritionRow
          name='Total Sodium (mg)'
          value1={columnOne?.salt}
          value2={columnTwo?.salt}
          unit={'mg'} />
      </NutritionRowContainer>
      <If condition={!!allergens}>
        <HeaderContainer>
          <Heading variant='h5' color={theme.colors.greys.darkCodGrey}> Allergens </Heading>
        </HeaderContainer>
        <Seperator align={seperatorAlign} />
        <TextContainer>
          <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey}>
            {allergens}
          </Paragraph>
        </TextContainer>
      </If>
    </Container>
  )
}
