import React from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue } from '@client/components/Theme'
import { FrozenMealDishListFragment, MealKitDishListFragment, WineListFragment, MarketProductListFragment } from '@hooks/api'

import { MiniProductCard } from './MiniProductCard'

type CombinedProduct = FrozenMealDishListFragment | MealKitDishListFragment | WineListFragment | MarketProductListFragment

interface MiniProductInterface {
  meals: CombinedProduct[]
  isAisle?: boolean
  isMixed?: boolean
}
const NormalGridContainer = styled.div<{ $isAisles: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${(props): CSS => props.$isAisles ? ResponsivePXValue('margin', '0 8px 8px 0px') : ResponsivePXValue('margin', '0 8px 8px 8px')};
  ${ResponsivePXValue('grid-gap', '8px')}
  ${ResponsivePXValue('margin', '0 8px 8px 8px')}
`
const ShortGridContainer = styled.div<{ $isAisles: boolean }>`
  display: grid;
  grid-template-columns: repeat(1, .5fr);
  width: 100%;
  ${(props): CSS => props.$isAisles ? ResponsivePXValue('margin', '0 8px 8px 0px') : ResponsivePXValue('margin', '0 8px 8px 8px')};
  ${ResponsivePXValue('grid-gap', '8px')}
  ${ResponsivePXValue('margin', '0 8px 8px 8px')}
`

export function MiniProductGrid({ meals, isAisle, isMixed }: MiniProductInterface): JSX.Element {

  const needsShortContainer = meals.length === 2

  const renderMealCards = (isAisle?: boolean, isMixed?: boolean) => {
    return meals.map((meal: CombinedProduct, index: React.Key) => (
      <MiniProductCard key={index} meal={meal} isAisle={isAisle} isMixed={isMixed} />
    ))
  }

  return (
    <Choose>
      <When condition={needsShortContainer}>
        <ShortGridContainer $isAisles={isAisle}>{renderMealCards(isAisle, isMixed)}</ShortGridContainer>
      </When>
      <Otherwise>
        <NormalGridContainer $isAisles={isAisle}>{renderMealCards(isAisle, isMixed)}</NormalGridContainer>
      </Otherwise>
    </Choose>
  )
}
