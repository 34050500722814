import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Spacer, Paragraph } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'

import { Slider } from '../controls'

const Container = styled.div`
  display: flex;

  .nav-container {
    flex-wrap: wrap;
    ${ResponsiveProperty('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  }

  .nav-link-slider {
    width: calc(100vw - 18px);
  }
`
const NavContainer = styled.div`
  display: flex;
`
const LinkContainer = styled.a<{ $isActive?: boolean, $outline?: boolean }>`
  cursor: pointer;
  text-decoration: none;

  .link-title {
    color: ${(props): string => props.$isActive ? props.theme.colors.oranges.coral : props.theme.colors.greys.liteCodGrey};
  }

  ${(props): CSS => {
    if (props.$outline) {
      return `
        border-radius: 6px;
        border: 1px solid;
        border-color: ${props.theme.colors.slates.celeste};
        ${ResponsivePXValue('padding', '4px 8px')}
        ${ResponsivePXValue('margin-bottom', '16px')}
        ${props.$isActive ? ResponsivePXValue('background-color', props.theme.colors.slates.celeste) : undefined};

        .link-title {
          ${props.$isActive ? ResponsivePXValue('color', props.theme.colors.whites.pureWhite) : undefined};
        }
      `
    }
  }}

  &.new {
    color: ${(props): string => props.$isActive ? props.theme.colors.whites.pureWhite : props.theme.colors.oranges.coral};
    background-color: ${(props): string => props.$isActive ? props.theme.colors.slates.celeste : props.theme.colors.oranges.coral};

    .link-title {
      color: ${(props): string => props.theme.colors.whites.pureWhite};
    }
  }
`

export interface NavLink {
  title: string
  url: string
  isActive?: boolean
}
export interface NavLinksProps {
  navLinks: NavLink[]
  outline?:boolean
  className?:string
  onNavClick?: (navLink: NavLink) => void
}

export function NavLinks({ navLinks, outline = false, className, onNavClick }: NavLinksProps): JSX.Element {

  const navigate = useNavigate()
  let navLink: NavLink
  let index: number

  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>, navLink: NavLink): void => {
    e.preventDefault()
    navigate(navLink.url)
    if (onNavClick) {
      onNavClick(navLink)
    }
  }

  return (
    <Container className={className}>
      <DeviceContainer $mobile $tablet>
        <Slider className='nav-link-slider' slides={navLinks} outline backgroundColor={theme.colors.misc.transparent} onNavClick={onNavClick}/>
      </DeviceContainer>
      <DeviceContainer $desktop className='nav-container'>
        <For each="navLink" of={navLinks} index="index">
          <NavContainer key={index}>
            <LinkContainer className={navLink.title.toLowerCase()} href={navLink.url} $isActive={!!navLink?.isActive} $outline={outline} onClick={(e) => _handleLinkClicked(e, navLink)}>
              <Paragraph bold variant='p3' className='link-title'>{navLink.title} </Paragraph>
            </LinkContainer>
            <If condition={index !== navLinks.length - 1}>
              <Spacer variant='horizontal' desktop='12px' />
            </If>
          </NavContainer>
        </For>
      </DeviceContainer>
    </Container>
  )
}
