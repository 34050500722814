import React, { useEffect, RefObject, useRef } from 'react'

import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

export interface MapDragged {
  coordinates: Coordinates
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export interface MapProps {
  center?: Coordinates
  onChange?: (mapDragged: MapDragged) => void
}

const DEFAULT_CENTER = {
  latitude: -28.8166236,
  longitude: 24.991639,
}

const DEFAULT_ZOOM = 5

const OPTIONS: google.maps.MapOptions = {}

const DraggableMap = withGoogleMap<MapProps>(({ center, onChange }: MapProps) => {

  const mapRef: RefObject<GoogleMap> = useRef()
  const editingPoint: RefObject<Marker> = useRef()

  const _handleEditingPointChanged = () => {
    const coordinates = editingPoint.current?.getPosition()
    onChange({ coordinates: { latitude: coordinates.lat(), longitude: coordinates.lng() } })
  }

  useEffect(() => {
    if (center) {
      const newBounds = new google.maps.LatLngBounds()
      const latLng = new google.maps.LatLng(center.latitude, center.longitude)
      newBounds.extend(latLng)
      const pointB = google.maps.geometry.spherical.computeOffset(latLng, 50, 0)
      newBounds.extend(pointB)
      const pointC = google.maps.geometry.spherical.computeOffset(latLng, 50, 180)
      newBounds.extend(pointC)
      mapRef.current.fitBounds(newBounds)
    } else {
      const latLng = new google.maps.LatLng(DEFAULT_CENTER.latitude, DEFAULT_CENTER.longitude)
      mapRef.current.panTo(latLng)
    }
  }, [center])

  return (
    <GoogleMap
      ref={mapRef}
      options={OPTIONS}
      defaultZoom={DEFAULT_ZOOM}
      zoom={center ? undefined : DEFAULT_ZOOM}
      defaultCenter={{ lat: DEFAULT_CENTER.latitude, lng: DEFAULT_CENTER.longitude }}>
      <If condition={!!center}>
        <Marker position={{ lat: center.latitude, lng: center.longitude }} ref={editingPoint} draggable onDragEnd={_handleEditingPointChanged} />
      </If>
    </GoogleMap>
  )
})

export default DraggableMap
