import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { theme } from '@client/components/Theme'
import { NavLink, Pill, ResponsivePXValue } from '@components/index'

const Container = styled.div<{ $outline: boolean }>`
  display: flex;
  flex: 1;
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: pointer;

  .filter-pill {
    margin-bottom: 0 !important;
    ${ResponsivePXValue('margin-right', '8px')}
  }

  ${(props): CSS => {
    if (props.$outline) {
      return `
      .filter-pill {
        border-radius: 6px;
        border: 1px solid;
        border-color: ${props.theme.colors.slates.celeste};
      }
      `
    }
  }}
`
const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

export interface SliderProps {
  slides: NavLink[]
  backgroundColor?: string
  outline?: boolean
  className?: string
  onNavClick?: (navLink: NavLink) => void
}

export function Slider(props: SliderProps): JSX.Element {
  const navigate = useNavigate()

  const {
    slides,
    backgroundColor = theme.colors.whites.alabaster,
    outline = false,
    className,
    onNavClick,
  } = props

  const _handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>, navLink: NavLink): void => {
    if (onNavClick) {
      onNavClick(navLink)
    }
    e.preventDefault()
    navigate(navLink.url)
  }

  const getPillBackgroundColor = (slide: NavLink): string => outline ? (slide.isActive ? theme.colors.slates.celeste : theme.colors.misc.transparent) : backgroundColor
  const getPillTextColor = (slide: NavLink): string => {
    if (outline) {
      return slide?.isActive ? theme.colors.whites.pureWhite : theme.colors.greys.liteCodGrey
    }
    return slide?.isActive ? theme.colors.oranges.coral : theme.colors.greys.liteCodGrey
  }

  let slide: NavLink
  let index: number

  return (
    <Container className={className} $outline={outline}>
      <For each='slide' of={slides} index='index'>
        <LinkContainer key={index} href={slide.url} onClick={(e) => _handleNavClick(e, slide)}>
          <Pill
            className='filter-pill'
            title={slide.title}
            backgroundColor={getPillBackgroundColor(slide)}
            textColor={getPillTextColor(slide)} />
        </LinkContainer>
      </For>
    </Container>
  )
}
