import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage, Paragraph, Spacer } from '@client/components/atoms'
import { Ellipsis, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { CartItemFragment } from '@hooks/api'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  max-width: 100%;
  ${ResponsivePXValue('padding', '8px 16px')}

  .text {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 140px)', tablet: '260px', desktop: '260px' })}
    ${ZeroSpace}
    ${Ellipsis()}
    line-height: 1.2;
  }

  .error-text {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 140px)', tablet: '260px', desktop: '260px' })}
    ${ZeroSpace}
    ${Ellipsis()}
    line-height: 1.2;
    font-weight: 700;
  }
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', '80px')}
  ${ResponsivePXValue('height', '80px')}
  ${ResponsivePXValue('border-radius', '8px')}
  overflow: hidden;
  background-color: ${theme.colors.whites.desertStorm};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export interface GiftCartItemProps {
  item: CartItemFragment
}

export function GiftCartItem({ item }: GiftCartItemProps): JSX.Element {

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={item?.product?.coverImage} />
      </ImageContainer>
      <Spacer universal='8px' />
      <BodyContainer>
        <Spacer variant='horizontal' universal='4px' />
        <Paragraph variant='p2' className='text'>{item?.product?.name?.split(' - ')[0]}</Paragraph>
        <Spacer variant='horizontal' universal='16px' />
        <Paragraph variant='p3' className='text'>{'Gift'}</Paragraph>
      </BodyContainer>
      <Spacer universal='16px' />
    </Container>
  )
}
