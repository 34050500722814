import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'

import { Paragraph } from '@client/components'
import { ModalLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useActivateSubscription, useUserCartQuery } from '@hooks/api'

interface ActivateSubscriptionState {
  complete: boolean
}

const DEFAULT_STATE: ActivateSubscriptionState = {
  complete: false,
}

export function ActivateSubscription(): JSX.Element {

  const config = useConfig()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [activateSubscription, { loading: activateLoading }] = useActivateSubscription()
  const [state, setState] = useState<ActivateSubscriptionState>({ ...DEFAULT_STATE })

  const activateUserSubscription = async (id: string): Promise<void> => {
    await activateSubscription({
      variables: {
        id,
        reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, { complete: { $set: false } }))
    }
  }, [open])

  useEffect(() => {
    if (userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription === false) {
      activateUserSubscription(userCartData?.currentUser?.activeMenu?.subscription?.id)
    }
  }, [userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription])

  const loading = userCartLoading || activateLoading

  return (
    <Choose>
      <When condition={loading || state.complete}>
        <ModalLoading />
      </When>
      <Otherwise>
        <>
          <Paragraph align='center'>
            Activating your subscription
          </Paragraph>
        </>
      </Otherwise>
    </Choose>
  )
}
