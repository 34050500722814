import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '32px 16px', tablet: '32px 16px', desktop: '40px 136px' })}
  ${ResponsivePXValue('gap', '30px')}
`

const MainContainer = styled.div`
  flex-grow: 1;
`

const SidebarContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '240px', desktop: '280px' })}
`

export interface CheckoutContainerProps {
  children: JSX.Element | JSX.Element[]
  sidebar?: JSX.Element | JSX.Element[]
}

export function CheckoutContainer({ children, sidebar }: CheckoutContainerProps): JSX.Element {

  return (
    <Container>
      <MainContainer>
        {children}
      </MainContainer>
      <If condition={!!sidebar}>
        <SidebarContainer>
          {sidebar}
        </SidebarContainer>
      </If>
    </Container>
  )

}
