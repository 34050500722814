import React from 'react'

import styled from 'styled-components'

import { Link, Paragraph, ResponsivePXValue, ZeroSpace } from '@client/components'

const ParsedContent = styled.div`

  .parsed-link {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: 0;
    display: inline;
    position: relative;
    ${ResponsivePXValue('top', '-1.5px')}
  }

  .parsed-text {
    ${ZeroSpace}
  }

`

export interface RichTextProps {
  children: string
  align?: 'left' | 'right' | 'center'
  className?: string
}

export function RichText({ children, align = 'left', className }: RichTextProps): JSX.Element {

  const getParsedUrl = (textString: string): { title: string, link: string } => { // parse individual url in string
    const url = textString.replace(/[[\]']+/g, '').split(',')
    return {
      title: url[0],
      link: url[1],
    }
  }

  const getTokens = (content: string): { type: 'text' | 'link', title?: string, value: string, hasTrailingSpace: boolean, hasLeadingSpace: boolean }[] => {
    const initialParts = getParsedArrayForUrl(content)
    const response: { type: 'text' | 'link', title?: string, value: string, hasTrailingSpace: boolean, hasLeadingSpace: boolean }[] = []
    for (let i = 0; i < initialParts.length; i++) {
      if (hasCustomUrl(initialParts[i])) {
        const { title, link } = getParsedUrl(initialParts[i])
        response.push({
          type: 'link',
          value: link.trim(),
          title: title.trim(),
          hasLeadingSpace: initialParts[i][0] === ' ',
          hasTrailingSpace: initialParts[i][initialParts[i].length - 1] === ' ',
        })
      } else {
        response.push({
          type: 'text',
          value: initialParts[i].trim(),
          hasLeadingSpace: initialParts[i][0] === ' ',
          hasTrailingSpace: initialParts[i][initialParts[i].length - 1] === ' ',
        })
      }
    }
    return response
  }

  const getParsedArrayForUrl = (textString: string): string[] => {
    return textString.split(';')
  }

  const hasCustomUrl = (textString: string): boolean => {
    return textString.includes('[')
  }

  const tokens = getTokens(children)
  const $content = tokens.map((token, index) => {
    if (token.type === 'text') {
      let response = ''
      if (token.hasLeadingSpace) {
        response += ' '
      }
      response += token.value
      if (token.hasTrailingSpace) {
        response += ' '
      }
      return response
    } else {
      return <Link
        key={index}
        href={token.value}
        className='parsed-link'>{token.title}
      </Link>
    }
  })

  return (
    <ParsedContent className={className}>
      <Paragraph className='parsed-text' variant='p1' align={align}>{$content}</Paragraph>
    </ParsedContent>
  )
}
