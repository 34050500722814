import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Paragraph, SmallLoader } from '@atoms/index'
import { DisabledEffect, ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'

import { FormContext, FormContextProperties, FormValue, InputProps } from './Form'
import { InputWrapper } from './InputWrapper'

const LightSelected = css`
  border-color: ${theme.colors.greys.mineshaft};
  border-width: 2px;
`
const DarkSelected = css`
  border-color: ${theme.colors.greys.mineshaft};
  border-width: 2px;
  opacity: 0.95;
`

const Container = styled.div<{ $direction: 'column' | 'row' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction:  ${(props): string => props.$direction};
  ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between' })}
`

const ItemContainer = styled.div < { $componentTheme: 'light' | 'dark', $outline: boolean, $selected: boolean, $loading: boolean, $disabled: boolean, $isMiniCard: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  border-radius: 4px;
  align-self: stretch;
  border: solid 1px blue;

  opacity: ${(props): number => props.$disabled ? 0.5 : 1};
  cursor: ${(props): string => props.$loading ? 'inherit' : 'pointer'};
  background-color: ${(props): string => props.$componentTheme === 'light' ? props.theme.colors.whites.pureWhite : props.theme.colors.misc.transparent};
  border: 1px solid ${(props): string => props.$outline ? props.$componentTheme === 'light' ? props.theme.colors.whites.pureWhite : props.theme.colors.whites.silver : props.theme.colors.misc.transparent};

  ${(props): CSS => props.$outline ? props.$selected ? ResponsivePXValue('padding', '15px') : ResponsivePXValue('padding', '16px') : null};
  ${(props): CSS => props.$outline ? ResponsivePXValue('margin', '0 16px 16px 0') : ResponsivePXValue('margin', '0 12px 0 0')};
 
  ${ResponsiveProperty('flex-direction', { mobile: 'row', tablet: 'row', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'center', desktop: 'center' })}

  ${(props): CSS => {
    if (props.$outline && props.$selected) {
      switch (props.$componentTheme) {
        case 'light':
          return LightSelected
        case 'dark':
          return DarkSelected
        default:
          return DarkSelected
      }
    }

    if (props.$isMiniCard) {
      return `
        margin: 0px;
        padding; 0px;
      `
    }
  }}

`

const ButtonOuterContainer = styled.div<{ $selected: boolean, $disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-radius: 50%;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  border-width: 1px;

  border-color: ${(props): string => props.theme.colors.whites.silver};
  
  ${ResponsivePXValue('margin', { mobile: '1px', tablet: '2px', desktop: '1px' })}

  ${(props): CSS => {
    if (props.$selected) {
      return `
        border-color: ${props.theme.colors.greys.mineshaft};
        box-shadow: inset 0 0 0 6px  ${props.theme.colors.greys.mineshaft};
      `
    }
    if (props.$disabled) {
      return `
        border-color: ${props.theme.colors.whites.silver};
        ${DisabledEffect};
      `
    }
  }}

`

const ButtonInnerContainer = styled.div<{ $disabled: boolean }>`
  border-radius: 50%;
  width: 80vw;

  ${(props): CSS => {
    if (props.$disabled) {
      return `
        background-color: ${props.theme.colors.whites.silver};
        ${DisabledEffect};
      `
    }
  }}
`
const ItemCountBubble = styled.div<{ $count: number }>`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props): string => props.theme.colors.greens.fruitSalad};
  /* Additional styles for floating effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translate(260%, -65%);
  ${ResponsivePXValue('height', { mobile: '10px', tablet: '10px', desktop: '14px' })}
  ${ResponsivePXValue('width', { mobile: '10px', tablet: '10px', desktop: '14px' })}

  /* Additional style for the text inside the bubble */
  .counter {
    transform: translateY(2px);
  }
`

const ItemLabel = styled.div<{ $color: string, $isMiniCard: boolean }>`
  position: relative;
  font-family: gordita;
  font-weight: 500;

  color: ${(props): string => props.$color};


  ${ResponsivePXValue('top', '2px')}
  ${ResponsivePXValue('font-size', '10px')}


  ${(props): CSS => {
    if (props.$isMiniCard) {
      return `
        ${ResponsivePXValue('margin-left', '4px')}
      `
    } else {
      return `
        ${ResponsivePXValue('margin', { mobile: '0 8px 0 0', tablet: '0 0 0 8px', desktop: '0 0 0 4px' })}
      `

    }
  }}
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
`

interface Values {
  value?: FormValue
  onChange?: (value: FormValue) => void
}

export interface RadioButtonInnerProps extends InputProps {
  variant?: 'vertical' | 'horizontal'
  outline?: boolean
  componentTheme?: 'light' | 'dark'
  options?: RadioButtonOption[]
  className?: string
  control: Values
  meta: Meta
  form: FormInstance
  isMiniCard?: boolean
  rules?: Rule[]
}

function RadioInner(props: RadioButtonInnerProps): JSX.Element {

  const { label, showLabel = true, variant = 'horizontal', componentTheme = 'dark', outline = true, options, loading, disabled, rules, control, meta, form, className, isMiniCard } = props

  const theme = useTheme()

  const availableOptions: RadioButtonOption[] = options ?? []

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )
  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleChange = (option: RadioButtonOption) => {
    if (disabled || loading || option?.disabled) {
      return
    }
    const newValue = option.value
    onChange(newValue)
  }

  let option: RadioButtonOption

  const labelColor = disabled || loading ? theme.colors.whites.silver : theme.colors.greys.tundora
  const direction = variant === 'horizontal' ? 'row' : 'column'

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}
      className={className}
      isMiniCard={isMiniCard}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container $direction={direction} className='container'>
            <For each='option' of={availableOptions}>
              <ItemContainer
                className={`item-container ${option?.className}`}
                $loading={disabled || loading || formLoading || formDisabled}
                key={option.value.toString()}
                $componentTheme={componentTheme}
                $outline={outline}
                $selected={option.value === value}
                $disabled={option?.disabled}
                $isMiniCard={isMiniCard}
                onClick={() => _handleChange(option)}>
                <ButtonOuterContainer
                  $disabled={disabled || loading || formLoading || formDisabled || option?.disabled}
                  $selected={option.value === value}>
                  <If condition={option.value === value}>
                    <ButtonInnerContainer $disabled={disabled || loading || formLoading || formDisabled} />
                  </If>
                </ButtonOuterContainer>
                <ItemLabel className='item-label' $color={labelColor} $isMiniCard={isMiniCard} >{option.element}</ItemLabel>
                <If condition={option.count>0}>
                  <ItemCountBubble $count={option.count}>
                    <Paragraph variant='p3' color='white' className='counter'>{option.count}</Paragraph>
                  </ItemCountBubble>
                </If>
              </ItemContainer>
            </For>
            <If condition={loading || formLoading}>
              <LoadingContainer>
                <SmallLoader
                  color={theme.colors.whites.silver} />
              </LoadingContainer>
            </If>
          </Container>
        )}
      </FormContext.Consumer>
    </InputWrapper>
  )
}

export interface RadioButtonOption {
  element: JSX.Element
  value: string | number
  disabled?: boolean
  className?: string
  count?: number
}

export interface RadioButtonProps extends InputProps {
  rules?: Rule[]
  variant?: 'vertical' | 'horizontal'
  outline?: boolean
  componentTheme?: 'light' | 'dark'
  options?: RadioButtonOption[]
  className?: string
  isMiniCard?: boolean
}

export function RadioButton(props: RadioButtonProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <RadioInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
