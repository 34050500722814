import React from 'react'

import styled, { useTheme } from 'styled-components'

import { IconEnum, Icon, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('width', '244px')}
  ${ResponsivePXValue('height', '130px')}
  ${ResponsivePXValue('padding', '22px')}

`

export interface TextIconProps {
  icon: IconEnum
  text: string
  color?: string
}

export function TextIcon({ icon, text, color }: TextIconProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Icon icon={icon} color={color ?? theme.colors.oranges.coral} />
      <Paragraph variant='p2' align='center'>
        {text}
      </Paragraph>
    </Container>
  )

}
