import Cookies from 'js-cookie'

import { AddonOrderRatingData } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { OrderDirectionEnum, OrderOrderEnum, OrderOriginEnum, OrderStatusEnum } from '@uctypes/api/globalTypes'
import { OrderItemFragment } from '@uctypes/api/OrderItemFragment'

import { useOrdersQuery } from './api'

interface ReturnType {
  addOnsRatingModalOpen: boolean
  addOnsRatingOrder: { [k: string]: AddonOrderRatingData }
}

export const useAddOnsRatingsLogic = (userId: string, isRegistered: boolean): ReturnType => {

  const config = useConfig()

  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const lastMonth = String(Number(month) - 1)
  const lastYear = String(Number(year) - 1)
  let oneMonthAgo = `${year}-${lastMonth}-${day}`

  if (month === '01') {
    oneMonthAgo = `${lastYear}-12-${day}`
  }

  const isAddonsDismissed = Cookies.get('isAddOnsDismissed')

  const { data: ordersAddOnDataAll } = useOrdersQuery({
    context: { key: 'uniqueKeyForParentQuery' },
    skip: (!userId || !!isAddonsDismissed || !isRegistered),
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: {
        users: [userId],
        status: [OrderStatusEnum.COMPLETE],
        origin: [OrderOriginEnum.NODE],
        maxCreatedAt: oneMonthAgo,
      },
      order: {
        field: OrderOrderEnum.DISPATCH_DATE,
        direction: OrderDirectionEnum.DESC,
      },
      skip: 0,
      limit: 4,
    },
  })

  const ordersList = ordersAddOnDataAll?.orders?.list || []

  const shouldIncludeForRating = (orderItem: OrderItemFragment) => {
    const shouldInclude = !orderItem.orderItemRating?.value &&
      orderItem.product?.__typename !== 'MealKit' &&
      orderItem.product?.__typename !== 'MealKitPlan' &&
      !orderItem.product?.name?.includes('Gift') &&
      !orderItem.product?.name?.includes('Fee') &&
      orderItem.product?.__typename !== 'MealKitSubscriptionFee' &&
      orderItem?.product?.name !== 'Fixed Subscription Fee' &&
      isDispatchedMoreThan30DaysAgo(orderItem.order.dispatchDate) &&
      !orderItem.isProductGroupRated

    return shouldInclude
  }

  const calculateDifferenceInDays = (date1: Date, date2: Date): number => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24
    const timeDiff = Math.abs(date1.getTime() - date2.getTime())
    return Math.floor(timeDiff / millisecondsPerDay)
  }

  const isDispatchedMoreThan30DaysAgo = (dispatchDate: string) => {
    const today = new Date()
    const dispatchTime = new Date(dispatchDate)
    const differenceInDays = calculateDifferenceInDays(today, dispatchTime)
    return differenceInDays > 30
  }

  const getAllOnDemandItemsToBeRated = () => {
    const addOnItemsToBeRated:{[key: string]: any } = {}

    for (const order of ordersList || []) {
      let skipOrder = false
      for (const orderItem of order.orderItems) {
        const dispatchTime = new Date(orderItem.order.dispatchDate)
        const today = new Date()
        const differenceInDays = calculateDifferenceInDays(today, dispatchTime)

        if (orderItem.orderItemRating?.value || differenceInDays < 30) {
          // console.log(`Skipping orderItem ${orderItem.productName} in order ${order.orderType}: rating = ${orderItem.orderItemRating?.value}, differenceInDays = ${differenceInDays}`)
          skipOrder = true
          break
        }
      }

      if (!skipOrder) {
        for (const orderItem of order.orderItems) {
          if (shouldIncludeForRating(orderItem as unknown as OrderItemFragment)) {
            const productId = orderItem.product?.id
            const orderId = order.id
            addOnItemsToBeRated[productId + orderId] = { orderId, orderItemData: orderItem }
          }
        }
      }
    }

    return addOnItemsToBeRated
  }

  const onDemandObj = getAllOnDemandItemsToBeRated()

  return ordersList.length > 0 && !isAddonsDismissed && Object.keys(onDemandObj).length > 0
    ? {
      addOnsRatingModalOpen: true,
      addOnsRatingOrder: onDemandObj,
    }
    : {
      addOnsRatingModalOpen: false,
      addOnsRatingOrder: null,
    }

}
