import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, ResponsiveImage, ResponsiveImageData, Paragraph, Spacer } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { ObjectFitEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{ $backgroundColor?: string, $borderRadius?: string }>`
  display: flex;
  align-items: center;
  font-family: 'gordita';

  background-color: ${(props): string => props?.$backgroundColor ? props.$backgroundColor : null};
  border-radius: ${(props): string => props?.$borderRadius ? props.$borderRadius : null};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })} 
  ${ResponsivePXValue('padding', { mobile: '28px', tablet: '30px', desktop: '32px' })}
`
const ImageContainer = styled.div`
  overflow: hidden;

  ${ResponsivePXValue('width', '216px')}
  ${ResponsivePXValue('min-width', '216px')}
  ${ResponsivePXValue('height', '216px')}
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '24px', desktop: '0' })}
  ${ResponsivePXValue('border-radius', { mobile: '24px', tablet: '24px', desktop: '64px' })}
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}

  ${ResponsivePXValue('padding-left', { mobile: '0', tablet: '0', desktop: '32px' })}

 .text {
   ${ResponsivePXValue('text-align', { mobile: 'center', tablet: 'left', desktop: 'left' })}
 }
`
export interface BiographyProps {
  image: ResponsiveImageData
  name: string
  about: string
  backgroundColor?: string
  borderRadius?: string
  objectFit?: ObjectFitEnum
}

export function Biography({ image, name, about, backgroundColor, borderRadius, objectFit }: BiographyProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container $backgroundColor={backgroundColor} $borderRadius={borderRadius}>
      <ImageContainer>
        <ResponsiveImage image={image} objectFit={objectFit} />
      </ImageContainer>
      <ContentContainer>
        <Heading variant='h5' className='header-text' color={theme.colors.greys.darkCodGrey}>
          {name}
        </Heading>
        <Spacer universal='8px' />
        <Paragraph variant='p1' className='body-text' color={theme.colors.greys.darkCodGrey}>
          {about}
        </Paragraph>
      </ContentContainer>
    </Container>
  )

}
