import React, { useCallback, useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Icon, IconEnum, Link, Loader, Paragraph, SmallLoader } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { MarketProductListFragment, useGetAllMarketProductsLazyQuery, useGetAppQuery, useUserDetailsQuery } from '@hooks/api'
import { DeviceTypeEnum, MarketProductOrderEnum, OrderDirectionEnum, ProductRangeEnum, ProductTypeEnum } from '@uctypes/api/globalTypes'
import { useEvents } from '@contexts/GTMProvider'

const Container = styled.div<{ $loading?: string}>`
  display: flex;
  align-items: center;
  justify-self: center;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  
  ${ResponsivePXValue('width', { mobile: '100%' })}

  opacity: ${(props): number => props.$loading === 'true' ? 0.65 : 1};

  div:has(.category-title) {
    cursor: default;
    background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  }

  .category-title {
    display: flex;
    flex: 1;
    font-weight: 500;
    border-bottom: 1px solid ${(props): string => props.theme.colors.oranges.coral};
  }
`
const SearchInput = styled.input<{ $loading?: string, $hasSearchResults: boolean, $showIntro: boolean}>`
  padding: 0 16px;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 4px;
  font-family: 'gordita';
  font-size: 14px;
  box-shadow: 'none';
  border: 1px solid ${(props): string => props.theme.colors.whites.silver};
  background: ${(props): string => props.theme.colors.whites.pureWhite};
  color: ${(props): string => props.theme.colors.greys.mineshaft};
  pointer-events: ${(props): string => props.$loading === 'true' ? 'none' : 'auto'};
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '288px', desktop: '288px' })} 
  ${ResponsivePXValue('padding', '8px 12px')}

  &:focus {
    border-color: ${(props): string => props.theme.colors.greys.mineshaft};
  }

   ${(props): CSS => {
    if (props?.$showIntro) {
      return `
        border-width: 2px;
        border-color: ${props.theme.colors.greens.fruitSalad};
        &:focus {
          border-color: ${props.theme.colors.greens.fruitSalad};
        }
      `
    }
  }}

  ${(props): CSS => {
    if (props?.$hasSearchResults) {
      return `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `
    }
  }}
`

const Results = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 13;
  background: ${(props): string => props.theme.colors.whites.pureWhite};
  color: ${(props): string => props.theme.colors.greys.mineshaft};
  ${ResponsivePXValue('padding', '8px 12px')}
`
const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 8px 0;
`

const PriceContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  .item-name {
    overflow: hidden;
    flex-wrap: wrap;
    ${ResponsivePXValue('max-width', '80%')}
  }

  .market-price{
    font-weight: 600;
    ${ResponsivePXValue('margin-right', '16px')}
  }

  p {
    display: flex;
  }
`
const Highlighter = styled.span`
  display: flex;
  font-weight: 600;

  ${ResponsivePXValue('padding', '0 2px')}
`
const LinkContainer = styled.div`
   display: flex;
  flex: 1;
  justify-content: center;
`
const IconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 14;
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
  ${ResponsivePXValue('right', '14px')}
`
const LoaderContainer = styled.div`
  opacity: 0.5;
  z-index: 10;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props): string => props.theme.colors.whites.pureWhite};
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '28px')}
`

const IntroMessageContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 140%;
  left: 0;
  right: 0;
  z-index: 13;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(19, 172, 106, 0.15) 0%, rgba(19, 172, 106, 0.15) 100%), ${(props): string => props.theme.colors.whites.pureWhite};
  background: linear-gradient(0deg, color(display-p3 0.3098 0.6627 0.4392 / 0.15) 0%, color(display-p3 0.3098 0.6627 0.4392 / 0.15) 100%), color(display-p3 1 1 1);

  border: 2px solid ${(props): string => props.theme.colors.greens.fruitSalad};
  ${ResponsivePXValue('padding', '12px')}

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }
  &:before {
    right: 17px;
    border: 13px solid transparent;
    border-bottom-color: ${(props): string => props.theme.colors.greens.fruitSalad};
  }
  &:after {
    right: 21px;
    border: 9px solid transparent;
    border-bottom-color: #e2f4e9;
  }
`

const NewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${(props): string => props.theme.colors.greens.fruitSalad};
  ${ResponsivePXValue('width', '48px')}
  ${ResponsivePXValue('height', '48px')}
`
const IntroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ResponsivePXValue('padding', '8px 12px')}
`

const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

export type MarketSearchResult = MarketProductListFragment | { id: string, name: string }

export interface MarketSearchProps {
  resetSearch?: boolean
  className?: string
}

interface MarketSearchState {
  skip: number
  limit: number
  searchTerm: string
  categorizedSearchResults?: MarketSearchResult[]
  inFocus?: boolean
  showIntro?: boolean
}

const DEFAULT_STATE: MarketSearchState = {
  skip: 0,
  limit: 20,
  searchTerm: '',
  inFocus: false,
  categorizedSearchResults: [],
  showIntro: true,
}

export function MarketSearch({ resetSearch = false, className }: MarketSearchProps): JSX.Element {

  const [state, setState] = useState<MarketSearchState>({ ...DEFAULT_STATE })

  const config = useConfig()
  const navigate = useNavigate()
  const events = useEvents()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const cityId = userDetailsData?.currentUser?.addresses.find((address) => address.isDefault)?.location?.city?.id
  const viewAllResultItemId = 'view-all-results'

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const marketVariables = {
    filters: {
      cities: cityId ? [cityId] : undefined,
      search: state.searchTerm,
    },
    skip: state.skip,
    limit: state.limit,
    order: [{
      field: MarketProductOrderEnum.DISPLAY_INDEX,
      direction: OrderDirectionEnum.ASC,
    }],
  }

  const [getFilteredProducts, { data: productsData, loading: productsLoading }] = useGetAllMarketProductsLazyQuery({
    variables: marketVariables,
    fetchPolicy: 'no-cache',
  })

  const hasSearchResults = state.categorizedSearchResults?.length > 0 && state.searchTerm !== ''

  const _handleOnClear = () => {
    setState((prevState) => update(prevState, {
      searchTerm: { $set: '' },
      inFocus: { $set: false },
    }))
  }

  const _handleOnSelect = (marketProduct: MarketProductListFragment) => {
    let urlLink = `/market/${marketProduct?.slug}`
    if (marketProduct.id === viewAllResultItemId) {
      urlLink = `/market/search/${state.searchTerm}`
    }
    navigate(urlLink)
  }

  const _formatResult = (item: MarketProductListFragment) => {

    const _highlightText = (text: string) => {
      const parts = text.split(new RegExp(`(${state.searchTerm})`, 'gi'))
      const lowercasedSearchTerm = state.searchTerm.toLowerCase()

      return (
        <>
          {parts.map((part, index) => {
            if (part.toLowerCase() === lowercasedSearchTerm) {
              return <Highlighter key={index}>{part}</Highlighter>
            } else {
              return part
            }
          })}
        </>
      )
    }

    const viewAllItem = item.id === viewAllResultItemId
    const isCategoryTitle = item.id.includes('category-')

    return (
      <>
        <Choose>
          <When condition={isCategoryTitle}>
            <Paragraph variant='p2' className='category-title' color={theme.colors.oranges.coral}>{item.slug}</Paragraph>
          </When>
          <When condition={viewAllItem}>
            <LinkContainer>
              <If condition={productsLoading}>
                <LoaderContainer>
                  <Loader noShadow={true}/>
                </LoaderContainer>
              </If>
              <Link variant='l2' decoration='underline' onClick={() => _handleOnSelect(item)}> View all results </Link>
            </LinkContainer>
          </When>
          <Otherwise>
            <PriceContainer onClick={() => _handleOnSelect(item)}>
              <Paragraph variant='p2' className='item-name'>{_highlightText(item.name)}</Paragraph>
              <Paragraph variant='p2' className='market-price'> R{item.price}</Paragraph>
            </PriceContainer>
            <Paragraph variant='p3'>{_highlightText(item.brand.name)}</Paragraph>
          </Otherwise>
        </Choose>
      </>
    )
  }

  const _getSearchItemsByCategory = (searchResults: MarketProductListFragment[]): (MarketProductListFragment | { id: string, name: string })[] => {

    const searchItems = []
    const seenCategories: Set<string> = new Set()
    const numberOfResults = isMobile ? 4 : 5

    searchResults?.slice(0, numberOfResults)?.forEach((searchResult) => {
      const categoryTitle = searchResult.marketProductCategories[0].title // Assuming single category per product
      const categoryId = `category-${categoryTitle}`

      if (!seenCategories.has(categoryId)) {
        searchItems.push({ id: categoryId, name: state.searchTerm, slug: categoryTitle })
        seenCategories.add(categoryId)
      }

      searchItems.push({ ...searchResult })
    })

    if (searchItems.length > 1) {
      searchItems.push({ id: viewAllResultItemId, name: state.searchTerm })
    }

    return searchItems
  }

  const debounce = <T extends (...args: unknown[]) => unknown>(func: T, delay: number) => {
    let timeoutId: NodeJS.Timeout
    return (...args: unknown[]) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  // Create a debounced search function
  const debouncedSearch = debounce(() => {
    getFilteredProducts({ variables: marketVariables })
  }, 300)

  const resetSearchComponent = () => {
    setState((prevState) => update(prevState, {
      searchTerm: { $set: '' },
      categorizedSearchResults: { $set: [] },
    }))
  }

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setState((prevState) => update(prevState, {
      searchTerm: { $set: value },
    }))
  }

  const _handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleEnter()
      }
    },
    [state.searchTerm, state.categorizedSearchResults.length],
  )

  const handleEnter = () => {
    if (hasSearchResults) {
      resetSearchComponent()
      navigate(`/market/search/${state.searchTerm}`)
    }
    events.hasPerformedSearch(state.searchTerm, ProductRangeEnum.MARKET_PRODUCT)
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement

      // Check if the click is outside the Autocomplete component
      if (!target.closest('.search-container') && !target.closest('.search-results')) {
        resetSearchComponent()
      }
    },
    [],
  )

  const _handleClose = (): void => {

    sessionStorage.setItem('hasSeenSearchIntro', 'true')

    Cookies.set('hasSeenSearchIntro', 'true', { expires: 365 })

    setState(update(state, {
      showIntro: { $set: false },
    }))
  }

  useEffect(() => {
    // Attach click event listener when the component mounts
    document.addEventListener('click', handleClickOutside)

    // Detach click event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    debouncedSearch()
  }, [state.searchTerm.length])

  useEffect(() => {
    if (!productsLoading) {
      let categorizedSearchResults: MarketSearchResult[] = []

      if (productsData?.marketProducts?.list?.length > 0) {
        categorizedSearchResults = _getSearchItemsByCategory(productsData.marketProducts.list as unknown as MarketProductListFragment[])
      }

      setState((prevState) => update(prevState, {
        categorizedSearchResults: { $set: categorizedSearchResults },
      }))
    }
  }, [productsLoading])

  useEffect(() => {
    if (resetSearch) {
      resetSearchComponent()
    }
  }
  , [resetSearch])

  const showIntro = false // Cookies.get('hasSeenSearchIntro') !== 'true' && state.showIntro

  let result: MarketSearchResult

  return (
    <Container className={`search-container ${className}`} $loading={productsLoading.toString()}>
      <SearchInput
        type="text"
        placeholder="Search market"
        value={state.searchTerm}
        onChange={_handleChange}
        onKeyDown={_handleKeyDown}
        onFocus={_handleClose}
        $loading={productsLoading.toString()}
        $hasSearchResults={hasSearchResults}
        $showIntro={showIntro}/>
      <If condition={state.searchTerm !== ''}>
        <Results className='search-results'>
          <Choose>
            <When condition={productsLoading}>
              <LoadingContainer>
                <SmallLoader color={theme.colors.oranges.coral} />
              </LoadingContainer>
            </When>
            <Otherwise>
              <Choose>
                <When condition={hasSearchResults}>
                  <For each='result' of={state.categorizedSearchResults}>
                    <ResultItem key={result.id}>
                      {_formatResult(result as MarketProductListFragment)}
                    </ResultItem>
                  </For>
                </When>
                <Otherwise>
                  <ResultItem>
                    <Paragraph variant='p2' align='center' className='item-name'> No results</Paragraph>
                  </ResultItem>
                </Otherwise>
              </Choose>
            </Otherwise>
          </Choose>
        </Results>
      </If>
      <Choose>
        <When condition={state.searchTerm.length === 0}>
          <IconContainer>
            <Icon icon={IconEnum.SEARCH} color={theme.colors.greys.boulder} />
          </IconContainer>
        </When>
        <Otherwise>
          <IconContainer onClick={_handleOnClear}>
            <Icon icon={IconEnum.CLOSE} color={theme.colors.greys.boulder} />
          </IconContainer>
        </Otherwise>
      </Choose>
      <If condition={showIntro}>
        <IntroMessageContainer>
          <CloseIconContainer onClick={_handleClose}>
            <Icon icon={IconEnum.CLOSE_OUTLINE} color={theme.colors.greens.fruitSalad} />
          </CloseIconContainer>
          <NewContainer>
            <Paragraph variant='p1' color={theme.colors.whites.pureWhite} bold> NEW </Paragraph>
          </NewContainer>
          <IntroTextContainer>
            <Paragraph variant='p1' color={theme.colors.greens.fruitSalad} bold> Try me I’m new! </Paragraph>
            <Paragraph variant='p2' color={theme.colors.greens.fruitSalad}> Try our new search feature </Paragraph>
          </IntroTextContainer>
        </IntroMessageContainer>
      </If>
    </Container>
  )
}
