import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { DisabledEffect, ResponsivePXValue, theme } from '@components/Theme'

export type LinkVariant = 'l1' | 'l2'
export type LinkTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type LinkDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const L1 = styled.a < { $decoration: string, $color: string, $hoverColor: string, $textAlignment: string, $bold: boolean, $transform: string, disabled: boolean }>`

  cursor: pointer;
  font-family: 'gordita';

  font-weight: ${(props): string => props.$bold ? '500' : '400'};
  color: ${(props): string => props.$color};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  text-align: ${(props): string => props.$textAlignment};

  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', '24px')}

  ${(props): CSS => props.disabled ? DisabledEffect : null}

  &:hover {
    color: ${(props): string => props.$hoverColor};
    ${(props): CSS => props.disabled ? DisabledEffect : null}
  }
`

const L2 = styled.a<{ $decoration: string, $color: string, $hoverColor: string, $textAlignment: string, $bold: boolean, $transform: string, disabled: boolean }>`

  cursor: pointer;
  font-family: 'gordita';
  
  font-weight: ${(props): string => props.$bold ? '500' : '400'};
  color: ${(props): string => props.$color};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  text-align: ${(props): string => props.$textAlignment};

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', '20px')}
   ${(props): CSS => props.disabled ? DisabledEffect : null}

  &:hover {
    color: ${(props): string => props.$hoverColor};
     ${(props): CSS => props.disabled ? DisabledEffect : null}
  }
`

export interface LinkProps {
  children: React.ReactNode
  href?: string
  color?: string
  hoverColor?: string
  blank?: boolean
  bold?: boolean
  disabled?: boolean
  variant?: LinkVariant
  transform?: LinkTransforms
  decoration?: LinkDecorations
  textAlignment?: 'left' | 'center' | 'right'
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => void
  test?: boolean
  skeleton?: boolean
  className?: string
}

export function Link(props: LinkProps): JSX.Element {

  const {
    children, href, color = theme.colors.greys.liteCodGrey, hoverColor = theme.colors.oranges.coral, textAlignment = 'left',
    blank = false, disabled = false, variant = 'l1', transform = 'none', decoration = 'none', bold = false, onClick, test = false, className,
  } = props
  const navigate = useNavigate()

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault()
      return onClick(e, href)
    }
    if (test) {
      e.preventDefault()
      return
    }
    if (href && href.indexOf('http') === -1) {
      e.preventDefault()
      navigate(href)
    }
  }

  switch (variant) {

    case 'l1':
      return (
        <L1
          className={className}
          $decoration={decoration}
          $bold={bold}
          $transform={transform}
          href={href}
          $color={color}
          $hoverColor={hoverColor}
          $textAlignment={textAlignment}
          target={blank ? '_blank' : '_self'}
          onClick={_handleClick}
          disabled={disabled}>
          {children}
        </L1>
      )
    case 'l2':
      return (
        <L2
          className={className}
          $decoration={decoration}
          $bold={bold}
          $transform={transform}
          href={href}
          $color={color}
          $hoverColor={hoverColor}
          $textAlignment={textAlignment}
          target={blank ? '_blank' : '_self'}
          onClick={_handleClick}
          disabled={disabled}>
          {children}
        </L2>
      )
  }
}
