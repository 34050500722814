import React from 'react'

import styled, { useTheme } from 'styled-components'

import { IconEnum, Paragraph, Icon, ResponsiveImage, Spacer } from '@atoms/index'
import { BorderBottom, ResponsivePXValue } from '@components/Theme'
import { ProductIconImageFragment } from '@hooks/api'
import { ObjectFitEnum } from '@uctypes/api/globalTypes'

export interface TextListProps {
  items: TextListItem[]
  className?: string
}

export interface TextListItem {
  title: string
  subTitle?: string | string[]
  icon?: IconEnum
  image?: ProductIconImageFragment
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'gordita';

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  .title {
   font-weight: 500;
  }

  .sub-title {
    font-weight: 400;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;

  ${ResponsivePXValue('margin-bottom', { mobile: '5px', tablet: '6px', desktop: '8px' })}
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '14px', tablet: '16px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '14px', tablet: '16px', desktop: '20px' })}
  ${ResponsivePXValue('margin-right', { mobile: '10px', tablet: '12px', desktop: '16px' })}
`
const ListItemContainer = styled.div`
  ${BorderBottom}
  
  ${ResponsivePXValue('padding', { mobile: '16px 0', tablet: '20px 0', desktop: '24px 0' })}
`

export function TextList({ items, className }: TextListProps): JSX.Element {

  const theme = useTheme()

  let item: TextListItem
  let idx: number
  let subTitle: string
  return (
    <Container className={className}>
      <For each='item' index='idx' of={items || []}>
        <ListItemContainer key={idx}>
          <Row>
            <If condition={!!item.icon}>
              <IconContainer>
                <Icon icon={item.icon} color={theme.colors.oranges.coral} />
              </IconContainer>
            </If>
            <If condition={!!item.image}>
              <IconContainer>
                <ResponsiveImage image={item.image} objectFit={ObjectFitEnum.CONTAIN} />
              </IconContainer>
            </If>
            <Paragraph variant='p2' className='title' color={theme.colors.greys.darkCodGrey}>{item.title}</Paragraph>
          </Row>
          <If condition={!!item.subTitle}>
            <Choose>
              <When condition={Array.isArray(item.subTitle)}>
                <For each='subTitle' of={item.subTitle}>
                  <Paragraph variant='p2' className='sub-title' color={theme.colors.greys.darkCodGrey}>{subTitle}</Paragraph>
                  <Spacer universal='4px' />
                </For>
              </When>
              <Otherwise>
                <Paragraph variant='p2' className='sub-title' color={theme.colors.greys.darkCodGrey}>{item.subTitle}</Paragraph>
              </Otherwise>
            </Choose>
          </If>
        </ListItemContainer>
      </For>
    </Container>
  )

}
