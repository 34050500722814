import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/VineyardStore')

const VineyardStore = loadable(() => import(/* webpackChunkName: "vineyard-store" */'@pages/VineyardStore'), {
  resolveComponent: (components) => components.VineyardStore,
})

export function VineyardStoreAsync(): JSX.Element {

  return <VineyardStore fallback={<div>Loading...</div>} />

}
