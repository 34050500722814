import React, { Fragment, useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Paragraph, Spacer, Link } from '@client/components/atoms'
import { AddressModal } from '@client/components/organisms/index'
import { ResponsiveProperty, ResponsivePXValue } from '@client/components/Theme'
import { UserAddressFragment } from '@hooks/api'
import { RadioButtonControl } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  border-top: 2px solid ${(props): string => props.theme.colors.whites.desertStorm};
  ${ResponsivePXValue('margin', { mobile: '0 16px', tablet: '0 24px', desktop: '0 32px' })}
  ${ResponsivePXValue('padding', '16px 0')}
`

const DynamicContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'space-between', desktop: 'space-between' })}
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '16px 0 0', tablet: '0 0 0 32px', desktop: '0 0 0 32px' })}
`

export interface UserAddressListItemProps {
  userAddress: UserAddressFragment
  onMakeDefault: () => void
  onDelete: () => void
  loading: boolean
  expanded?: boolean
}

interface UserAddressListItemState {
  openEdit: boolean
}

const DEFAULT_STATE: UserAddressListItemState = {
  openEdit: false,
}

export function UserAddressListItem({ userAddress, onMakeDefault, onDelete, loading, expanded = false }: UserAddressListItemProps): JSX.Element {

  const [state, setState] = useState<UserAddressListItemState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  const _onEdit = (): void => {
    setState((prevState) => update(prevState, {
      openEdit: { $set: !prevState.openEdit },
    }))
  }
  const _handleEditClose = (): void => {
    setState((prevState) => update(prevState, {
      openEdit: { $set: false },
    }))
  }

  useEffect(() => {
    if (expanded) {
      setState((prevState) => update(prevState, {
        openEdit: { $set: expanded },
      }))
    }
  }, [])

  const fullAddress = userAddress?.location?.locationName ? `${userAddress?.location?.locationName}, ${userAddress?.location.fullAddress}` : userAddress?.location.fullAddress

  return (
    <Container>
      <MainContainer>
        <If condition={loading}>
          <RadioButtonControl
            disabled={true}
            selected={false}
            onClick={onMakeDefault} />
        </If>
        <If condition={!loading}>
          <Choose>
            <When condition={userAddress.isDefault && !userAddress.location.isIncomplete}>
              <RadioButtonControl
                disabled={userAddress.location.isIncomplete}
                selected={userAddress.isDefault && !userAddress.location.isIncomplete} />
            </When>
            <Otherwise>
              <RadioButtonControl
                disabled={userAddress.location.isIncomplete}
                selected={userAddress.isDefault && !userAddress.location.isIncomplete}
                onClick={onMakeDefault} />
            </Otherwise>
          </Choose>
        </If>
        <Spacer universal='16px' variant='horizontal' />
        <DynamicContent>
          <InfoColumn>
            <Paragraph
              variant='p1'
              bold
              color={userAddress.location.isIncomplete ? theme.colors.misc.error : undefined}>
              {userAddress.location.isIncomplete ? `${userAddress.alias} (Incomplete)` : userAddress.alias}
            </Paragraph>
            <Spacer universal='8px' />
            <If condition={!!userAddress.company} >
              <Paragraph
                variant='p1'>
                {userAddress.company}
              </Paragraph>
              <Spacer universal='8px' />
            </If>
            <Paragraph
              variant='p1'>
              {fullAddress}
            </Paragraph>
            <Spacer universal='8px' />
            <If condition={!!userAddress.instructions} >
              <Paragraph
                variant='p3'
                color={theme.colors.slates.bitter}>
                {userAddress.instructions}
              </Paragraph>
              <Spacer universal='8px' />
            </If>
          </InfoColumn>
          <If condition={!state.openEdit}>
            <ActionsRow>
              <If condition={!userAddress.isDefault || userAddress.location.isIncomplete}>
                <Fragment>
                  <Link variant='l2' onClick={onDelete} bold color={theme.colors.slates.ash}> DELETE </Link>
                  <Spacer universal='32px' variant='horizontal' />
                </Fragment>
              </If>
              <Link variant='l2' color={theme.colors.slates.ash} bold onClick={_onEdit}> EDIT </Link>
            </ActionsRow>
          </If>
        </DynamicContent>
      </MainContainer>
      <If condition={state.openEdit}>
        <AddressModal
          userAddress={userAddress}
          onClose={_handleEditClose} />
      </If>
    </Container>
  )
}
