import React, { useEffect, useRef } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Image } from '@atoms/index'
import { defaultNavigation } from '@client/Navigation'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { MainNavContainerFragment, NavItemFragment, SubNavItemFragment } from '@hooks/api'

import { NavigationItem } from './NavigationItem'
import { NavigationList } from './NavigationList'

const Container = styled.div`

  display: none;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${LiteBoxShadow}
  
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  ${ResponsivePXValue('height', '64px')}

`

const LogoContainer = styled.a`
  flex-shrink: 0;
  
  ${ResponsivePXValue('height', 'CALC(100% - 28px)')}
  ${ResponsivePXValue('margin', '0 28px')}
  ${ResponsivePXValue('width', '84px')}
`

const ItemsContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RightItemsContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 3vw;
  ${(props): string => props.theme.ultra} {
    padding-right: ${(props): string => props.theme.uw(3)};
  }
`

const LeftItemsContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('top', '68px')}
`

export interface NavigationBarProps {
  items: NavItemFragment[]
  children: React.ReactNode
  skeleton?: boolean
}

export function NavigationBar({ items, children }: NavigationBarProps): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()
  const subNavRef: React.RefObject<HTMLDivElement> = useRef()

  const _handleLogoClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
    NavigationPlugin.shared().setNavigation(defaultNavigation, false)
  }

  const _handleNavClicked = (item: MainNavContainerFragment): void => {
    let path: string
    if (item.__typename === 'MainNavContainer' && !!item.items.length) {
      path = NavigationPlugin.shared().selectSubNav(item?.id, (item?.items || [])[0].id)
    } else {
      path = NavigationPlugin.shared().selectNav(item?.id, false)
    }

    if (item?.isExternalNavigation && config.isBrowser()) {
      window.location.href = path
    } else {
      navigate(path)
    }

    // if (item?.pillTitle) {
    //   _handleNavHover(item.id)
    // } else if (path) {
    //   navigate(path)
    // }
  }

  const _handleNavHover = (id: string): void => {
    NavigationPlugin.shared().selectNav(id, true)
  }

  const _handleSubNavClicked = (parentId: string, id: string): void => {
    const path = NavigationPlugin.shared().selectSubNav(parentId, id)
    if (path) {
      navigate(path)
    }
  }

  const _handlePageClick = (e: MouseEvent): void => {
    if (subNavRef.current && !subNavRef.current.contains(e.target as Element)) {
      NavigationPlugin.shared().closeOpenSubNav()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', _handlePageClick)
    return () => document.addEventListener('mousedown', _handlePageClick)
  })

  let item: NavItemFragment
  return (
    <Container>
      <LogoContainer href='/' onClick={_handleLogoClicked}>
        <Image src='/logos/large.svg' lazy={false} />
      </LogoContainer>
      <ItemsContainer>
        <LeftItemsContainer>
          <For each='item' of={items}>
            <ItemContainer key={item.id}>
              <NavigationItem
                {...item}
                onHoverChange={() => _handleNavHover(item.id)}
                onClick={() => _handleNavClicked(item as MainNavContainerFragment)} />
              <If condition={item.__typename === 'MainNavContainer' && item.open && !!item.items.length}>
                <SubMenuContainer ref={subNavRef}>
                  <NavigationList
                    items={(item as MainNavContainerFragment).items as SubNavItemFragment[]}
                    onNavigate={(id: string) => _handleSubNavClicked(item.id, id)} />
                </SubMenuContainer>
              </If>
            </ItemContainer>
          </For>
        </LeftItemsContainer>
        <RightItemsContainer>
          {children}
        </RightItemsContainer>
      </ItemsContainer>
    </Container>
  )

}
