import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Paragraph } from '@client/components'
import { Icon, IconSizeEnum, IconEnum, Spacer } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;
  position: absolute;
  top: 8px;
  z-index: 999;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsivePXValue('padding', '4px 8px')}

  .copy-text {
    line-height: normal;
  }
`

export interface CopyToClipboardProps {
  contentToCopy?: string
  className?: string
}

interface CopyToClipboardState {
  contentCopied: boolean
  contentText: string
}

const DEFAULT_STATE: CopyToClipboardState = {
  contentCopied: false,
  contentText: 'Copy code',
}

export function CopyToClipboard({ contentToCopy, className }: CopyToClipboardProps): JSX.Element {

  const [state, setState] = useState<CopyToClipboardState>({ ...DEFAULT_STATE })

  const _copyTextToClipboard = (): void => {

    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy)
        .then(() => {
          setState((prevState) => update(prevState, {
            contentCopied: { $set: true },
            contentText: { $set: 'Copied!' },
          }))
        })
        .catch(() => {
          setState((prevState) => update(prevState, {
            contentCopied: { $set: true },
            contentText: { $set: 'Failed to copy' },
          }))
        })
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = contentToCopy
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      console.log('Text successfully copied to clipboard (fallback)')
    }

    setTimeout(() => {
      setState((prevState) => update(prevState, {
        contentCopied: { $set: false },
        contentText: { $set: 'Copy code' },
      }))
    }, 3000)
  }

  const copyIcon = state.contentCopied ? IconEnum.CHECKMARK_CIRCLE_OUTLINE : IconEnum.COPY_OUTLINE

  return (
    <Container onClick={_copyTextToClipboard} className={className}>
      <Icon
        size={IconSizeEnum.EXTRA_SMALL}
        icon={copyIcon}
        color={theme.colors.greys.boulder} />
      <Spacer universal='8px' variant='horizontal' />
      <Paragraph className='copy-text' variant='p5' color={theme.colors.greys.boulder}>{state.contentText}</Paragraph>
    </Container>
  )
}
