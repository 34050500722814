import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/TermsAndConditions')

const TermsAndConditions = loadable(() => import(/* webpackChunkName: "terms-and-conditions" */'@pages/TermsAndConditions'), {
  resolveComponent: (components) => components.TermsAndConditions,
})

export function TermsAndConditionsAsync(): JSX.Element {

  return <TermsAndConditions fallback={<div>Loading...</div>} />

}
