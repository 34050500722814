import React from 'react'

import OneSignal from 'react-onesignal'
import { useNavigate } from 'react-router'

import { APP_DEFAULT_STATE } from '@api/local'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { ContentSeperator, Heading, IconEnum, MenuNavItemContainer } from '@client/components'
import { Drawer } from '@client/components/molecules'
import { SiteHelper } from '@client/lib/SiteHelper'
import { defaultUserSubNav } from '@client/Navigation'
import { useGetNavigationQuery, SubNavItemFragment, useGetAppQuery, useUserLogoutMutation } from '@hooks/api'

import { MobileNavigationBar } from './MobileNavigationBar'

export function MobileUserAccountMenu(): JSX.Element {

  const navigate = useNavigate()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const [logOut] = useUserLogoutMutation()
  const userNav: SubNavItemFragment[] = [...defaultUserSubNav]
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _handleSubNavClicked = async (subItem: SubNavItemFragment): Promise<void> => {
    NavigationPlugin.shared().resetMenuNav()
    if (subItem?.id === 'LOG_OUT') {

      OneSignal.logout()
      await logOut({
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      navigate('/')
    } else {
      setTimeout(() => {
        navigate(subItem?.href)
      }, 300)
    }
  }

  const _handleClose = (): void => {
    NavigationPlugin.shared().toggleUserMenu()
  }

  let $header = (
    <MobileNavigationBar
      showLogo={false}
      icon={IconEnum.CHEVRON_BACK_OUTLINE}
      variant='menu'
      onIconClick={_handleClose} />
  )
  if (isNativeApplication) {
    $header = (
      <></>
    )
  }
  let subItem: SubNavItemFragment

  return (
    <Drawer
      open={navigationData?.navigation?.userMobileMenuOpen}
      onClose={_handleClose}
      header={$header} >
      <If condition={!isNativeApplication}>
        <MenuNavItemContainer onClick={() => _handleSubNavClicked(userNav.find(subItem => subItem.id === 'ACCOUNT'))}>
          <Heading variant='h6' className='sub-menu-nav-item-header'>YOUR ACCOUNT</Heading>
        </MenuNavItemContainer>
        <ContentSeperator margin='0 16px' />
      </If>
      <For each='subItem' of={userNav || []}>
        <MenuNavItemContainer key={subItem?.id} onClick={() => _handleSubNavClicked(subItem)}>
          <Heading variant='h6' className='sub-menu-nav-item-header'>{subItem.title.toUpperCase()}</Heading>
        </MenuNavItemContainer>
        <ContentSeperator margin='0 16px' />
      </For>
    </Drawer>
  )
}
