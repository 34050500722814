import React from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import {
  Carousel, CarouselCardWidth, CarouselContainerWidth, CarouselFlexContainer, CarouselNavPositionEnum, CarouselSlideMargin,
  Heading, Paragraph, ResponsivePXValue, Seperator, Spacer,
} from '@client/components'
import { PageDataSectionFragment, WineListFragment, useActiveCampaignWinesQuery, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { WineCard } from '../stores/WineCard'

const Container = styled.div`

  display: flex;
  flex-direction: column;
 
  ${CarouselContainerWidth}

  ${ResponsivePXValue('margin', '24px 0')}
  
  .title {
    padding-top: 0;
  }
`

const CarouselContainer = styled.div`
  
  ${CarouselFlexContainer}

  .wine-card {
    ${ResponsivePXValue('max-width', { mobile: '288px', tablet: '288px', desktop: '346px' })}
    ${ResponsivePXValue('margin', { mobile: '0 8px', tablet: '0 14px', desktop: '0 16px' })}
  }
`

const CardContainer = styled.div`
  display: flex;
  align-self: center;
 
  ${CarouselCardWidth}

  .wine-card {
     ${CarouselSlideMargin}
  }
`

export interface PageDataActiveWineCampaignProps {
  pageSection: PageDataSectionFragment
}

export function PageDataActiveWineCampaign({ pageSection }: PageDataActiveWineCampaignProps): JSX.Element {

  const { data } = useActiveCampaignWinesQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const theme = useTheme()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA

  const wines = data?.activeWineCampaign?.campaignWines?.list || []

  const navPosition = isMobile ? CarouselNavPositionEnum.BOTTOM : CarouselNavPositionEnum.CENTER

  let wine: WineListFragment

  return (
    <Container>
      <Choose>
        <When condition={wines?.length > 0}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h4' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='24px' />
            <Seperator align='center' />
          </If>
          <If condition={!!pageSection?.description}>
            <Paragraph align='center' variant='p5' color={theme.colors.slates.bitter}>{pageSection.description}</Paragraph>
            <Spacer universal='24px' />
            <Seperator align='center' />
          </If>
          <Choose>
            <When condition={wines.length === 1}>
              <CardContainer>
                <For each='wine' of={wines}>
                  <WineCard
                    className='wine-card'
                    key={wine.id}
                    wine={wine} />
                </For>
              </CardContainer>
            </When>
            <When condition={isDesktop && wines.length < 4}>
              <CardContainer>
                <For each='wine' of={wines}>
                  <WineCard
                    className='wine-card'
                    key={wine.id}
                    wine={wine} />
                </For>
              </CardContainer>
            </When>
            <Otherwise>
              <CarouselContainer>
                <Carousel
                  displayNavButtons
                  displayPagingInfo={isMobile}
                  navPosition={navPosition}>
                  <For each='wine' of={wines}>
                    <WineCard
                      className='wine-card'
                      key={wine.id}
                      wine={wine} />
                  </For>
                </Carousel>
              </CarouselContainer>
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          <Paragraph variant='p3' align='center'>
            Active Wine Campaign not found
          </Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )
}
