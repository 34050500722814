import React from 'react'

import { PageSectionGroup } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

/**
 * GA Tracking Elements
 * [ ] Banner Clicks/Navigation?
 *
 * SEO
 * [ ] Link
 * [ ] Description
 * [ ] Tags
 */

const seo = {
  name: 'UCOOK Home',
  title: 'UCOOK | Meal Kits & Meal Delivery Service South Africa',
  meta: [{
    name: 'description',
    content: 'SA\'s most popular Meal Kit 📦🥕 We deliver the freshest ethically sourced ingredients in the right quantities so you can make easy weeknight meals at home.',
  }, {
    name: 'keywords',
    content: 'Meal Kits,Meal Prep Delivery,Food Box Delivery',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}

export function PrivacyPolicy(): JSX.Element {

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <PageSectionGroup isLegalDocumentation identifier='PRIVACY_POLICY' />
    </>
  )

}
