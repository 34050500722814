import React from 'react'

import styled, { css, useTheme, CSS } from 'styled-components'

import { Heading, Icon, IconEnum, Paragraph } from '@atoms/index'
import { ContainerBorderRadius, ResponsivePXValue } from '@components/Theme'

const TabContainer = styled.div<{ $open: boolean, $subTitle: boolean, $noBorder: boolean, $borderColor: string }>`

  border-bottom: ${(props): string => props.$open || props.$noBorder ? 'none' : `1px solid ${props.$borderColor}`};
  width: 100%;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }
`

const Inverted = css`background: ${(props): string => props.theme.colors.whites.pureWhite};`

const Open = css`border: none !important;`

const TabHeader = styled.div<{ $open: boolean, $invertTheme: boolean, $blackBackground: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: ${(props): string => props.$open ? (props.$blackBackground ? props.theme.colors.greys.mineshaft : props.theme.colors.oranges.coral) : props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('padding', { mobile: '12px 16px', tablet: '12px 16px', desktop: '24px 32px' })}
  
  ${(props): CSS => {
    if (props.$invertTheme) {
      return Inverted
    }
  }}

  ${(props): CSS => {
    if (!props.$open) {
      return Open
    }
  }}

`
const TitleRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  ${(props): string => props.theme.tablet} {
    flex-direction: row;
  }


  h5 {
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }
  
  h6 {
    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '16px')}
  }
`

const TabIcon = styled.div<{ $open: boolean }>`
  transform: rotate(${(props): number => props.$open ? 180 : 0}deg);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('margin-left', { mobile: '8px', tablet: '12px', desktop: '16px' })}
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '24px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`
const TabContent = styled.div<{ $contentBorderTop?: boolean }>`
  ${(props): CSS => {
    if (props.$contentBorderTop) {
      return `border-top: 1px solid ${props.theme.colors.greys.tundora};`
    }
  }}
  ${ResponsivePXValue('padding', { mobile: '24px 10px', tablet: '24px 10px', desktop: '24px 0' })}
`
interface TabProps {
  title: string
  subTitle?: string
  invertTheme?: boolean
  tab: string
  open?: boolean
  blackBackground?: boolean
  contentBorderTop?: boolean
  className?: string
  noBorder?: boolean
  borderColor?: string
  children: JSX.Element[] | JSX.Element
  onClick?: (tab: string) => void
}

export function Tab(props: TabProps): JSX.Element {

  const { title, subTitle, invertTheme, tab, open, blackBackground = true, contentBorderTop, children, className, noBorder = false, borderColor, onClick } = props
  const theme = useTheme()

  const bColor = borderColor || theme.colors.greens.asparagus
  let color = open ? theme.colors.whites.pureWhite : theme.colors.slates.ironsideGrey
  let subTitleColor = theme.colors.greys.liteCodGrey

  if (invertTheme) {
    color = theme.colors.greys.darkCodGrey
    subTitleColor = theme.colors.whites.silver
  }

  if (noBorder) {
    color = theme.colors.whites.silver
  }

  return (
    <TabContainer id={`id-${tab}`} $noBorder={noBorder} $open={open} $subTitle={!!subTitle} $borderColor={bColor}>
      <TabHeader className={className} $open={open} $blackBackground={blackBackground} $invertTheme={invertTheme} onClick={() => onClick(tab)}>
        <TitleRow>
          <Choose>
            <When condition={noBorder}>
              <Paragraph className='titleText' variant='p2'>
                {title}
              </Paragraph>
            </When>
            <Otherwise>
              <Heading className='titleText' variant='h5' color={color}>{title}</Heading>
              <If condition={!!subTitle}>
                <Heading variant='h6' color={subTitleColor}>{subTitle}</Heading>
              </If>
            </Otherwise>
          </Choose>
        </TitleRow>
        <TabIcon $open={open}>
          <Icon icon={IconEnum.CHEVRON_DOWN_OUTLINE} color={color} />
        </TabIcon>
      </TabHeader>
      <If condition={open}>
        <TabContent className='tab-content' $contentBorderTop={contentBorderTop}>
          {children}
        </TabContent>
      </If>
    </TabContainer>
  )
}

const Container = styled.div<{ $backgroundColor?: string }>`

  ${ContainerBorderRadius}

  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.whites.alabaster};

  width: 100%;
  overflow: auto;
`

export interface AccordionProps {
  allowMulti?: boolean
  activeTab: string
  onTabChange?: (tab: string) => void
  backgroundColor?: string
  className?: string
  children: JSX.Element[] | JSX.Element
}

export function Accordion(props: AccordionProps): JSX.Element {

  const { activeTab, onTabChange, children, className } = props

  let nonNullChild

  if (Array.isArray(children)) {
    nonNullChild = children.filter(child => child !== null)
  }

  const _handleTabClicked = (tab: string) => {

    if (onTabChange) {
      onTabChange(tab)
    }

    if (tab === activeTab) {
      onTabChange('')
    }
  }

  const child = React.Children.map(nonNullChild, (child) => {
    return React.cloneElement(child, { onClick: _handleTabClicked, open: activeTab === child.props.tab, key: child.props.tab })
  })

  return (
    <Container $backgroundColor={props.backgroundColor} className={className}>
      {child}
    </Container>
  )

}
