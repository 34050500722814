import React, { Fragment } from 'react'

import { format } from 'date-fns'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, Paragraph, Rule, Loader, Spacer, Link } from '@atoms/index'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'
import { useUserDetailsQuery, useUserCartQuery, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'

const FlexGrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', 'CALC(50% - 8px)')}
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const BottomBlock = styled.div`
  display: flex;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('margin', '24px 0 0 0')};
  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '16px', desktop: '16px' })}

  .textElement {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')};
  }
`

export function AddressDetails(): JSX.Element {

  const config = useConfig()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const navigate = useNavigate()
  const deliveryAddress = registeredUser?.addresses.find(address => userCartData?.currentUser?.activeMenu?.deliveryAddress?.id === address.id)
  const selectedCreditCard = registeredUser?.creditCards?.find((creditCard) => creditCard?.isDefault)
  const loading = userCartLoading || userDetailsLoading
  return (
    <Fragment>
      <Choose>
        <When condition={loading}>
          <BottomBlock>
            <Loader />
          </BottomBlock>
        </When>
        <Otherwise>
          <DeviceContainer $mobile $tablet>
            <MobileContainer>
              <BottomBlock>
                <HeaderRow>
                  <Paragraph variant='p2' className='textElement'>Delivery Date:</Paragraph>
                  <Spacer variant='horizontal' universal='8px' />
                  <Paragraph variant='p3' className='textElement'>{`${format(new Date(userCartData?.currentUser?.activeMenu?.deliveryDate), 'EEE dd MMM yyyy')}`}</Paragraph>
                </HeaderRow>
                <Spacer universal='8px' />
                <Rule color='slate' />
                <Spacer universal='8px' />
                <Paragraph variant='p2' className='textElement'>Delivery Location</Paragraph>
                <Spacer universal='8px' />
                <Paragraph variant='p1' className='textElement'>{deliveryAddress?.location?.name}</Paragraph>
                <Spacer universal='8px' />
                <Paragraph variant='p1' className='textElement'>{deliveryAddress?.location?.fullAddress}</Paragraph>
                <Spacer universal='8px' />
                <Link
                  color={theme.colors.oranges.coral}
                  bold
                  onClick={() => { navigate('/me/delivery') }}> EDIT DELIVERY DETAILS </Link>
              </BottomBlock>
              <BottomBlock>
                <HeaderRow>
                  <Paragraph className='textElement' variant='p2'>Billing Date:</Paragraph>
                  <Spacer variant='horizontal' universal='8px' />
                  <Paragraph variant='p3' className='textElement'>{`${format(new Date(userCartData?.currentUser?.activeMenu?.billingDate), 'EEE dd MMM yyyy')}`}</Paragraph>
                </HeaderRow>
                <Spacer universal='8px' />
                <Rule color='slate' />
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p2'>Payment Method</Paragraph>
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p1'>{selectedCreditCard?.cardNickname}</Paragraph>
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p1'>**** **** **** {selectedCreditCard?.last4Digits}</Paragraph>
                <Spacer universal='16px' />
                <Link
                  color={theme.colors.oranges.coral}
                  bold
                  onClick={() => { navigate('/me/payment') }}> EDIT PAYMENT DETAILS </Link>
              </BottomBlock>
            </MobileContainer>
          </DeviceContainer>
          <DeviceContainer $desktop>
            <BottomBlock>
              <FlexGrowContainer>
                <HeaderRow>
                  <Heading variant='h6' className='textElement'>Delivery Date: </Heading>
                  <Spacer variant='horizontal' universal='8px' />
                  <Paragraph variant='p2' className='textElement'>{`${format(new Date(userCartData?.currentUser?.activeMenu?.deliveryDate), 'EEE dd MMM yyyy')}`}</Paragraph>
                </HeaderRow>
                <Spacer universal='8px' />
                <Rule color='slate' />
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p2'>Delivery Location</Paragraph>
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p1'>{deliveryAddress?.location?.name}</Paragraph>
                <Spacer variant='horizontal' universal='8px' />
                <Paragraph className='textElement' variant='p1'>{deliveryAddress?.location?.fullAddress}</Paragraph>
                <Spacer universal='16px' />
                <Link
                  color={theme.colors.oranges.coral}
                  bold
                  onClick={() => { navigate('/me/delivery') }}> EDIT DELIVERY DETAILS </Link>
              </FlexGrowContainer>
              <Spacer universal='16px' />
              <FlexGrowContainer>
                <HeaderRow>
                  <Heading variant='h6' className='textElement'>Billing Date:</Heading>
                  <Spacer variant='horizontal' universal='8px' />
                  <Paragraph variant='p2' className='textElement'>{`${format(new Date(userCartData?.currentUser?.activeMenu?.billingDate), 'EEE dd MMM yyyy')}`}</Paragraph>
                </HeaderRow>
                <Spacer universal='8px' />
                <Rule color='slate' />
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p2'>Payment Method</Paragraph>
                <Spacer variant='horizontal' universal='8px' />
                <Paragraph className='textElement' variant='p1'>{selectedCreditCard?.cardNickname}</Paragraph>
                <Spacer universal='8px' />
                <Paragraph className='textElement' variant='p1'>**** **** **** {selectedCreditCard?.last4Digits}</Paragraph>
                <Spacer universal='8px' />
                <Link
                  color={theme.colors.oranges.coral}
                  bold
                  onClick={() => { navigate('/me/payment') }}> EDIT PAYMENT DETAILS </Link>
              </FlexGrowContainer>
            </BottomBlock>
          </DeviceContainer>
        </Otherwise>
      </Choose>
    </Fragment>
  )

}
