import React from 'react'

import { UserEvents } from '@lib/UserEvents'

const EventContext = React.createContext<UserEvents>(null)

export function EventProvider({ events, children }: { events: UserEvents, children: JSX.Element }): JSX.Element {
  return (
    <EventContext.Provider value={events}>
      {children}
    </EventContext.Provider>
  )
}

export const useEvents = (): UserEvents => React.useContext(EventContext)
