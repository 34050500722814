export const getParsedUrl = (textString: string): { title: string, link: string } => { // parse individual url in string
  const url = textString.replace(/[[\]']+/g, '').split(',')
  return {
    title: url[0],
    link: url[1],
  }
}

export const getParsedArrayForUrl = (textString: string): string[] => {
  return textString.split(';')
}

export const hasCustomUrl = (textString: string): boolean => {
  return textString.includes('[')
}

export const getArrayFromNumber = (number: number): number[] => {
  return Array.from(Array(number), (_, i) => i + 1)
}
