import React from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Seperator, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetRelatedFrozenDishesQuery, useGetAppQuery, FrozenMealDishDetailsFragment } from '@hooks/api'
import { CraftMealCard, CraftPizzaCard, SectionLoading } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

export interface CraftMealYouMightAlsoLikeProps {
  dish: FrozenMealDishDetailsFragment
  isPizza?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${ResponsivePXValue('margin-top', { mobile: '18px', tablet: '22px', desktop: '28px' })}

  .craft-meal-card {
    ${ResponsivePXValue('margin', { mobile: '0 0 18px', tablet: '0 0 22px', desktop: ' 0 0 28px' })}
  }
  
`

export function CraftMealYouMightAlsoLike({ dish, isPizza = false }: CraftMealYouMightAlsoLikeProps): JSX.Element {

  const { data, loading } = useGetRelatedFrozenDishesQuery({ variables: { frozenCategories: dish?.frozenCategories?.map((category) => category.id) } })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()

  const relatedFilter = (relatedDish: FrozenMealDishDetailsFragment) => relatedDish.id !== dish.id
  const craftMeals = data?.related?.list.filter(relatedFilter).slice(0, appData.app.deviceType === DeviceTypeEnum.TABLET ? 4 : 4) || []

  let craftMeal: FrozenMealDishDetailsFragment

  return (
    <Choose>
      <When condition={!(craftMeals.length === 0)}>
        <Container id='craftMealYouMightLike'>
          <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>You might also like</Heading>
          <Spacer universal='8px' />
          <Seperator align='center' />
          <CardContainer>
            <For each='craftMeal' of={craftMeals || []}>
              <Choose>
                <When condition={isPizza}>
                  <CraftPizzaCard
                    className='craft-meal-card'
                    key={craftMeal.id}
                    craftPizza={craftMeal} />
                </When>
                <Otherwise>
                  <CraftMealCard
                    className='craft-meal-card'
                    key={craftMeal.id}
                    craftMeal={craftMeal} />
                </Otherwise>
              </Choose>
            </For>
          </CardContainer>
        </Container>
      </When>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <Otherwise>

      </Otherwise>
    </Choose>
  )
}
