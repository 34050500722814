import React, { useRef } from 'react'

import styled, { useTheme, CSS } from 'styled-components'

import { Heading, ResponsiveImage } from '@atoms/index'
import { Button, Link, Paragraph, Spacer } from '@client/components'
import { getObjectFit, MediumBoxShadow, ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'
import { ImageVariantEnum, PageSectionMediaVariationEnum } from '@uctypes/api/globalTypes'

import { getPageSectionButtonColor, PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $isLeftElegant: boolean }>`
  display: flex;
  align-items: center;

  ${(props): CSS => {
    const direction = props.$isLeftElegant ? 'row' : 'row-reverse'
    return ResponsivePXValue('flex-direction', { mobile: 'column', tablet: direction, desktop: direction })
  }}
`

const ImageContainer = styled.div<{ $shadow: boolean, $variant: ImageVariantEnum }>`
  overflow: hidden;
  flex: 1;
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}

  ${ResponsivePXValue('max-height', { mobile: '270px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('height', { mobile: '270px', tablet: '300px', desktop: '424px' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.75;
  ${ResponsivePXValue('align-items', { desktop: 'flex-start', tablet: 'center', mobile: 'center' })}
  ${ResponsivePXValue('padding', { desktop: '0 84px 0' })}
  ${ResponsivePXValue('width', { mobile: '80%', tablet: '65%', desktop: '50%' })}
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}

  .link{
    color: ${(props): string => props.theme.colors.oranges.coral};
  }
`

export interface PageMediaActionProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaAction({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaActionProps): JSX.Element {

  const theme = useTheme()

  const contents = pageSection?.contents || []
  const hasButton = !!pageSection.links?.primary || !!pageSection.links?.secondary
  const isLeftElegant = pageSection?.variation === PageSectionMediaVariationEnum.LEFT_ELEGANT

  const align = theme.isMobile() ? 'center' : 'left'
  const variant = theme.isMobile() ? 'secondary' : 'primary'
  const objectFit = getObjectFit(pageSection)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  let content: string
  let contentIndex: number

  return (
    <Container ref={pageRef} $isLeftElegant={!!isLeftElegant}>
      <ImageContainer $shadow={pageSection.imageShadow} $variant={pageSection.imageVariant}>
        <ResponsiveImage image={pageSection.image} objectFit={objectFit} />
      </ImageContainer>
      <ContentContainer>
        <Spacer mobile='8px' />
        <Heading variant='h1' align={align}>
          {pageSection.title}
        </Heading>
        <Heading variant='h3' align={align} fontWeight={500}>
          {pageSection.subTitle}
        </Heading>
        <Spacer universal='8px' />
        <ListContainer>
          <For each='content' of={contents} index='contentIndex'>
            <Paragraph variant='p1' align={align} key={contentIndex}>
              {content}
            </Paragraph>
          </For>
        </ListContainer>
        <Spacer universal='32px'/>
        <If condition={hasButton}>
          <ButtonsContainer>
            <If condition={!!pageSection.links?.primary}>
              <Button title={pageSection.links.primary.title} variant={variant} color={getPageSectionButtonColor(pageSection.links.primary.color)} href={pageSection.links.primary.link} />
              <Spacer desktop='16px' variant='horizontal' />
            </If>
            <If condition={!!pageSection.links?.secondary}>
              <Spacer mobile='16px' />
              <Link className='link' variant='l2' href={pageSection.links.secondary.link} color={getPageSectionButtonColor(pageSection.links.secondary.color)}>{pageSection.links.secondary.title}</Link>
            </If>
          </ButtonsContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}
