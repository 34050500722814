import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Paragraph, Link, Spacer } from '@atoms/index'
import { Button, theme } from '@client/components'
import { ErrorBlock, Modal, SectionLoading } from '@client/components/molecules'
import { ActiveCampaignWinesDocument, UserCartDocument, useGetModalsQuery, useUpdateUserSubscription, useUserCartQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'
import { WeekDayEnum } from '@uctypes/api/globalTypes'

enum DeliveryDayUnavailableModalStepEnum {
  DELIVERY_DAY_UNAVAILABLE = 'DELIVERY_DAY_UNAVAILABLE',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  KEEP_ME_UPDATED = 'KEEP_ME_UPDATED'
}

const Container = styled.div`
  .form {
    display: grid;
    flex-direction: column;
  }
`

interface DeliveryDayUnavailableModalState {
  displayStep: DeliveryDayUnavailableModalStepEnum
  error: string
}

const DEFAULT_STATE: DeliveryDayUnavailableModalState = {
  displayStep: DeliveryDayUnavailableModalStepEnum.DELIVERY_DAY_UNAVAILABLE,
  error: '',
}

export function DeliveryDayUnavailableModal(): JSX.Element {

  const [state, setState] = useState<DeliveryDayUnavailableModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()

  const { data: userCartData } = useUserCartQuery()
  const [updateSubscription, { loading }] = useUpdateUserSubscription()

  const { addToast } = useToasts()

  useEffect(() => {

    if (data?.modals?.deliveryDayUnavailable) {

      const displayStep = DeliveryDayUnavailableModalStepEnum.DELIVERY_DAY_UNAVAILABLE
      const error = ''

      setState((prevState) => update(prevState, {
        displayStep: { $set: displayStep },
        error: { $set: error },
      }))
    }
  }, [data?.modals?.deliveryDayUnavailable])

  let title = 'Delivery DAY'
  switch (state.displayStep) {
    case DeliveryDayUnavailableModalStepEnum.ERROR:
      title = 'Oops'
      break
  }

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
  }

  const _handleChangeToMonday = async () => {

    setState((prevState) => update(prevState, {
      displayStep: { $set: DeliveryDayUnavailableModalStepEnum.LOADING },
    }))

    try {
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: {
            deliveryOption: WeekDayEnum.MONDAY,
          },
        },
        refetchQueries: [{ query: UserCartDocument }, { query: ActiveCampaignWinesDocument }],
        awaitRefetchQueries: true,
      })
      addToast('Delivery day changed to Monday.', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _handleClose()
  }

  return (
    <Modal
      showCloseButton
      open={data?.modals?.deliveryDayUnavailable}
      title={title}
      onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={loading || state.displayStep === DeliveryDayUnavailableModalStepEnum.LOADING}>
            <ModalFormContainer>
              <SectionLoading />
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === DeliveryDayUnavailableModalStepEnum.ERROR}>
            <ErrorBlock
              title={state.error}
              onClick={_handleClose} />
          </When>
          <When condition={state.displayStep === DeliveryDayUnavailableModalStepEnum.DELIVERY_DAY_UNAVAILABLE}>
            <ModalFormContainer className='form'>
              <Paragraph variant='p1' color={theme.colors.greys.liteCodGrey} align='center' >
                Please update your delivery day.
              </Paragraph>
              <Spacer universal='16px' />
              <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} align='center' >
                We are unable to deliver alcohol with your order on a Sunday. Please update your delivery day to add this product to your order.
              </Paragraph>
              <Spacer universal='32px' />
              <Button color='black' fluid title='CHANGE TO MONDAY DELIVERY' onClick={_handleChangeToMonday} />
              <Spacer universal='16px' />
              <Link decoration='underline' textAlignment='center' onClick={_handleClose}>Cancel</Link>
            </ModalFormContainer>
          </When>
          <Otherwise>
            <ModalFormContainer>
              <Paragraph variant='p1'>User not found</Paragraph>
            </ModalFormContainer>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
