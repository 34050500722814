import React from 'react'

import { Svg } from './Svg'

export interface RandProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Rand({ color, hoverColor, className }: RandProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.9037 54.3926L39.6413 36.653C45.8451 34.392 48.976 29.9861 48.976 23.3772C48.976 18.7974 47.4686 15.261 44.3956 12.7682C41.3807 10.2754 37.5541 9 32.9157 9H15V54.3926H24.3927V38.1023H30.0747L39.4674 54.3926H49.9037ZM24.3927 17.8698H32.162C36.2785 17.8698 39.4674 19.551 39.4674 23.5511C39.4674 27.4933 36.1625 29.4064 32.2199 29.4064H24.3927V17.8698Z" className='fill' />
    </Svg>
  )

}
