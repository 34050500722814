import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/WineAisles')

const WineAisles = loadable(() => import(/* webpackChunkName: "wine-aisles" */'@pages/WineAisles'), {
  resolveComponent: (components) => components.WineAisles,
})

export function WineAislesAsync(): JSX.Element {

  return <WineAisles fallback={<div>Loading...</div>} />

}
