import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import qs from 'qs'
import update from 'react-addons-update'
import { useLocation, useNavigate, useParams } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer, Loader } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useLoadingData } from '@client/hooks/UseLoadingData'
import { useOnScreen } from '@client/hooks/UseOnScreen'
import { useEvents } from '@contexts/GTMProvider'
import { useGetAppQuery, useUserDetailsQuery, useGetAllMarketProductsQuery, ProductAggregationSectionFragment, useGetAllMarketProductCategoriesQuery, useMyShopMarketQuery, MyShopMarketQuery, MarketProductListFragment } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { EducationalBanner, Filter, FilterSectionProps, MiniProductGrid, NavLink, NavLinks, SectionLoading, SelectedFilters, StoreStickyButton } from '@molecules/index'
import { MarketSearch } from '@organisms/index'
import { MarketProductFilters, MarketProductCategoryFilters, MarketProductCategoryStatusEnum, MarketProductCategoryOrderEnum, OrderDirectionEnum, DeviceTypeEnum, MarketProductOrderEnum, ProductRangeEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { MarketProductCard } from '../molecules/stores/MarketProductCard'
import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

const seo = {
  name: 'UCOOK Markets',
  title: 'Buy Market Online in South Africa | UCOOK Market',
  meta: [{
    name: 'description',
    content: 'Our markets are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Market Online,Market Online,Order Market Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}

  .button-container {
    margin: 0;
  }
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;

  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'flex-start' })}
`
const Grid = styled.div <{ $isNativeApplication: boolean, $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column' })}

  .market-filter {
    ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '0 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    &.showing-filter {
       padding: 0 !important;
    }

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .market-product-card {
    margin: 0;
  }
`

const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: 'CALC(100$ - 64px)', desktop: 'CALC(100% - 64px)' })}
 
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', { mobile: '8px', desktop: '24px' })}
  cursor: pointer;
`

const marketEducationBannerMessage = 'Our Market offers you a variety of top-quality local products.Shop whatever you need, from artisan bread, cold pressed olive oil, fresh milk, nut butters to fresh fruit & veg. Add these products to your weekly Meal Kit order and you\'ll have dinner sorted and your pantry and fridge stocked!'
const weekendBoxEducationBannerMessage = 'Elevate your weekends with UCOOK Weekend Boxes! For our meal-kit subscribers, it\'s a breeze - add a Weekend Box to your order before 9 am every Wednesday, for delivery on Friday! Not a UCOOK customer or paused your meal-kit subscription? Simply place your order before 9 pm on Tuesday to experience epic feasts made easy every weekend!'
const giftBoxEducationBannerMessage = 'Spice up gifting with UCOOK Gift Cards! With over 24 recipes to choose from weekly, give the gift of restaurant-quality dishes at home! Gifts Cards can also be used to buy craft meals, pizza, desserts or any goodies on UCOOK Market. For meal-kit subscribers, add a Gift Card to your cart by 9 am every Wednesday for delivery with your meal-kit. If you don’t have a Meal Kit subscription or if your account is paused, you can still add Gift Cards to your Cart and check out instantly – we’ll deliver within 2-4 working days'

interface MarketProductStoreState {
  filters: MarketProductFilters
  skip: number
  limit: number
  displayEducationalBanner: boolean
  fetchMoreLoading: boolean
  hasLoggedImpressions: boolean
  resetSearch: boolean
}

const DEFAULT_STATE: MarketProductStoreState = {
  filters: {},
  skip: 0,
  limit: 20,
  displayEducationalBanner: false,
  fetchMoreLoading: false,
  hasLoggedImpressions: false,
  resetSearch: false,
}

export function MarketProductStore(): JSX.Element {

  const { slug, search } = useParams<{ slug: string, search: string }>()
  const [state, setState] = useState<MarketProductStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const categoryFilters: Mutable<MarketProductCategoryFilters> = {
    status: MarketProductCategoryStatusEnum.ACTIVE,
  }
  const { data: marketCategoriesData, loading: categoriesLoading } = useGetAllMarketProductCategoriesQuery({
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: categoryFilters,
      order: [{
        field: MarketProductCategoryOrderEnum.DISPLAY_INDEX,
        direction: OrderDirectionEnum.DESC,
      }],
    },
  })
  const marketCategories = [...marketCategoriesData?.marketProductCategories?.list || []]?.sort((a, b) => a.displayIndex - b.displayIndex)

  const marketAisleActiveCategory = marketCategories.find(marketCategory => marketCategory.slug === slug)?.id
  const params = qs.parse(location.search.replace('?', ''))
  const filters = params?.filters as { [k: string]: string[] } || {}
  const cityId = userDetailsData?.currentUser?.addresses.find((address) => address.isDefault)?.location?.city?.id
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const marketFilters: Mutable<MarketProductFilters> = {
    cities: cityId ? [cityId] : undefined,
    ...filters,
    marketProductCategories: marketAisleActiveCategory ? [marketAisleActiveCategory] : undefined,
    search: search || undefined,
  }
  const events = useEvents()
  const variables = {
    filters: marketFilters,
    skip: state.skip,
    limit: state.limit,
    order: [{
      field: MarketProductOrderEnum.DISPLAY_INDEX,
      direction: OrderDirectionEnum.ASC,
    }],
  }

  const { data: productsData, loading: productsLoading, fetchMore, refetch: refetchMarketItems } = useGetAllMarketProductsQuery({ variables, skip: !userDetailsData?.currentUser })

  const { data: myShopData, loading: myShopLoading, fetchMore: fetchMoreMyShop, refetch } = useMyShopMarketQuery({
    variables: {
      limit: state.limit,
      skip: state.skip,
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.MARKET_PRODUCT,
      },
    },
    skip: !userDetailsData?.currentUser,
  })
  const myShopMarketList = useLoadingData<Readonly<MyShopMarketQuery['myShopItems']['list']>>({
    data: myShopData?.myShopItems?.list,
    defaultData: [],
    loading: myShopLoading,
  })

  let myShopMarketListReady = true
  if (myShopMarketList.length && myShopMarketList[0]?.__typename !== 'MarketProduct') {
    myShopMarketListReady = false
    refetch()
  }

  const isMyShopList = slug === 'bought-before'
  const loading = productsLoading || userDetailsLoading || myShopLoading || categoriesLoading
  const products = useLoadingData<Readonly<(MarketProductListFragment)[]>>({
    data: productsData?.marketProducts?.list,
    defaultData: [],
    loading: productsLoading,
  })
  const aggregations = useLoadingData<Readonly<ProductAggregationSectionFragment[]>>({
    data: productsData?.marketProducts?.aggregation?.sections,
    defaultData: [],
    loading: productsLoading,
  })
  const renderMiniCardGrid = () => {
    const batchSize = 4
    const miniProductGrids = []
    let marketProductsGridList = products as (MarketProductListFragment)[]
    if (isMyShopList) {
      marketProductsGridList = myShopMarketList as unknown as (MarketProductListFragment)[]
    }

    for (let i = 0; i < marketProductsGridList.length; i += batchSize) {
      const batch = marketProductsGridList.slice(i, i + batchSize)
      miniProductGrids.push(
        <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} />,
      )
    }

    return miniProductGrids
  }
  const marketNavLinks: NavLink[] = marketCategories.map(marketProductCategory => {
    return {
      title: marketProductCategory.title,
      url: `/market/store/${marketProductCategory.slug}`,
      isActive: marketAisleActiveCategory === marketProductCategory.id,
    }
  }) || []

  let boughtBeforeLink: NavLink[] = []

  if (myShopMarketList?.length>0) {
    boughtBeforeLink = [{
      title: 'Bought Before',
      url: '/market/store/bought-before',
      isActive: slug === 'bought-before',
    }]
  }

  const navLinks = [{
    title: 'All',
    url: '/market',
    isActive: false,
  },
  ...boughtBeforeLink,
  ...marketNavLinks,
  ]

  const loadedListLength = productsData?.marketProducts?.list?.length
  const loadedListLengthMyShop = myShopData?.myShopItems?.list?.length

  const performFetchMore = async (): Promise<void> => {

    if (!isMyShopList) {
      fetchMore({
        variables: {
          skip: state.skip,
        },
      })
    } else {
      fetchMoreMyShop({
        variables: {
          skip: state.skip,
        },
      })
    }
  }

  const _handleFiltersChange = (selected: SelectedFilters): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: selected },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },

    }))
    const newQueryString = qs.stringify({ filters: selected })
    navigate(`${location.pathname}?${newQueryString}`)
  }

  const _handleFiltersClear = (): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: {} },
      skip: { $set: 0 },
      hasLoggedImpressions: { $set: false },

    }))
    navigate(location.pathname)
  }

  const _handleFetchMore = (): void => {

    if (!isMyShopList) {
      if (loadedListLength === state.skip + state.limit) {
        setState((prevState) => update(prevState, {
          skip: { $set: prevState.skip + prevState.limit },
          fetchMoreLoading: { $set: false },
        }))
      }
    } else {
      if (loadedListLengthMyShop === state.skip + state.limit) {
        setState((prevState) => update(prevState, {
          skip: { $set: prevState.skip + prevState.limit },
          fetchMoreLoading: { $set: false },
        }))
      }
    }

  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }

  const _handleNavLinkClicked = (): void => {
    setState((prevState) => update(prevState, {
      skip: { $set: 0 },
      loaderNumber: { $set: 0 },
      clearReferences: { $set: true },
      hasLoggedImpressions: { $set: false },
      resetSearch: { $set: true },
    }))
  }

  useEffect(() => {
    performFetchMore()
  }, [state.skip])

  useEffect(() => {
    if (!Cookies.get('displayEducationalBanner')) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
      Cookies.set('displayEducationalBanner', '0')
    }
  }, [])

  useEffect(() => {
    refetchMarketItems()
    refetch()
  }, [userDetailsData?.currentUser?.isOnDemand])

  let totalProductsInSection = productsData?.marketProducts?.count
  if (isMyShopList) {
    totalProductsInSection = myShopData?.myShopItems?.count
  }

  const divRefs = useOnScreen(_handleFetchMore)

  let hasMore = productsData?.marketProducts?.count > state.skip + state.limit
  if (isMyShopList) {
    hasMore = myShopData?.myShopItems?.count > state.skip + state.limit
  }

  const sections: FilterSectionProps[] = aggregations.filter(aggregation => aggregation.filterKey !== 'tags' && aggregation.filterKey !== 'marketProductCategories').map((aggregation) => ({
    id: aggregation.filterKey,
    title: aggregation.title,
    filterKey: aggregation.filterKey,
    items: aggregation.items.map((item) => ({
      id: item.id,
      title: item.name,
      quantity: 0,
    })),
  }))

  let marketProduct: MarketProductListFragment
  const isDoneLoading = state.skip >= totalProductsInSection
  const isWeekendBoxStore = location.pathname.includes('weekend-boxes')
  const isGiftStore = location.pathname.includes('gift-cards')

  let educationBannerTitle = 'Welcome to Market!'
  let educationBannerMessage = marketEducationBannerMessage

  if (isWeekendBoxStore) {
    educationBannerMessage = weekendBoxEducationBannerMessage
    educationBannerTitle = 'Weekend Boxes'
  } else if (isGiftStore) {
    educationBannerMessage = giftBoxEducationBannerMessage
    educationBannerTitle = 'Gift Cards'
  }

  useEffect(() => {
    if (isWeekendBoxStore || isGiftStore) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
    }
  }, [])

  useEffect(() => {
    if (!productsLoading && !!products.length && !state.hasLoggedImpressions) {
      events.hasViewedCatalogue(
        products.map((product, displayIndex) => {

          const logData = {
            itemName: product?.name,
            itemId: product?.id,
            itemGroupId: product?.id,
            price: product?.price,
            itemBrand: product?.brand?.name,
            itemCategory: product?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
            itemVariant: marketProduct?.marketProductCategories[0]?.title,
            itemListName: 'MarketProduct',
            index: displayIndex,
            itemImage: product?.coverImage?.location,
          }
          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

          return snakedData
        }),
        userDetailsData?.currentUser?.id)
    }

    setState((prevState) => update(prevState, {
      hasLoggedImpressions: { $set: true },
    }))
  }, [products])

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={loading && !products.length}>
          <SectionLoading height='100vh' />
        </When>
        <Otherwise>
          <StoreStickyButton className='button-container' isMobile={isMobile} />
          <TopContainer>
            <RowContainer>
              <HeadingContainer>
                <Heading variant='h1'>Market</Heading>
                <Spacer desktop='8px' variant='horizontal' />
                <IconContainer onClick={_handleBannerToggle}>
                  <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
                </IconContainer>
              </HeadingContainer>
              <DeviceContainer $tablet $desktop>
                <Spacer mobile='24px' variant='horizontal' />
                <MarketSearch resetSearch={state.resetSearch} />
              </DeviceContainer>
            </RowContainer>
            <DeviceContainer $mobile>
              <MarketSearch resetSearch={state.resetSearch} />
              <Spacer mobile='12px' />
            </DeviceContainer>
            <If condition={!search}>
              <DeviceContainer $desktop>
                <NavLinks outline onNavClick={_handleNavLinkClicked} navLinks={navLinks} />
              </DeviceContainer>
            </If>
            <If condition={state.displayEducationalBanner}>
              <Spacer universal='16px' />
              <EducationalBanner
                title={educationBannerTitle}
                description={educationBannerMessage}
                onClose={_handleOnClose}
                showCloseButton={!isWeekendBoxStore && !isGiftStore} />
              <Spacer mobile='16px' />
            </If>
            <Spacer desktop='24px' />
          </TopContainer>
          <Grid $isNativeApplication={isNativeApplication} $isLoading={loading}>
            <If condition={!isMyShopList && sections.length > 0}>
              <Filter
                className='market-filter'
                filters={filters as unknown as {[k: string]: string[]}}
                sections={sections}
                onChange={_handleFiltersChange}
                onClear={_handleFiltersClear}
                slides={<NavLinks outline onNavClick={_handleNavLinkClicked} navLinks={navLinks} />}
              />
            </If>

            <If condition={isMyShopList && isMobile}>
              <Filter
                className='market-filter'
                sections={[]}
                onChange={_handleFiltersChange}
                onClear={_handleFiltersClear}
                slides={<NavLinks outline onNavClick={_handleNavLinkClicked} navLinks={navLinks} />}
              />
            </If>
            <Spacer mobile='24px' />
            <DishContainer id='productGrid'>
              <Choose>
                <When condition={!isMyShopList}>
                  <Choose>
                    <When condition={isDesktop}>
                      <For each='marketProduct' of={products}>
                        <MarketProductCard
                          className='market-product-card'
                          key={marketProduct.id}
                          loading={loading}
                          marketProduct={marketProduct} />
                      </For>
                    </When>
                    <Otherwise>
                      {renderMiniCardGrid()}
                    </Otherwise>
                  </Choose>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={isDesktop && myShopMarketListReady}>
                      <For each='marketProduct' of={myShopMarketList}>
                        <MarketProductCard
                          className='market-product-card'
                          key={marketProduct.id}
                          loading={loading}
                          marketProduct={marketProduct} />
                      </For>
                    </When>
                    <When condition={!isDesktop && myShopMarketListReady}>
                      {renderMiniCardGrid()}
                    </When>
                  </Choose>
                </Otherwise>
              </Choose>
              <If condition={!isDoneLoading && hasMore}>
                <MoreContainer ref = {divRefs}>
                  <Loader noShadow={true}></Loader>
                </MoreContainer>
              </If>
            </DishContainer>
          </Grid>
          <Spacer universal='24px' />
        </Otherwise>
      </Choose>
    </Container>
  )
}
