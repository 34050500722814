import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Button, Paragraph, Spacer } from '@client/components/atoms'
import { Form, Modal, SectionLoading, TextAreaInput, useForm } from '@client/components/molecules'
import { ModalContainerStyle, ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useGetModalsQuery, UserCartDocument, UserDetailsDocument, usePauseSubscriptionReason, useGetAppQuery } from '@hooks/api'

const Container = styled.div<{$isNativeApplication:boolean}>`
  ${ModalContainerStyle}

  .input-label{
    font-weight: normal;
  }
`

const PauseOption = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: row;
  border: 1px solid;
  cursor: pointer;

  ${ResponsivePXValue('padding', { mobile: '8px 12px', tablet: '8px 12px', desktop: '12px 16px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '8px', desktop: '12px' })}
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('border-radius', '8px')}

  border-color: ${(props): string => props.$isActive ? props.theme.colors.greys.liteCodGrey : props.theme.colors.slates.bitter};
`

interface PauseSubscriptionReasonFormData {
  pauseReasoning: string
}

export interface PauseSubscriptionReasonModalProps {
  onClose?: () => void
}

interface PauseSubscriptionReasonModalState {
  loading: boolean
  disabled: boolean
  defaultMode: boolean
  selectedReasons: string[]
  otherSelected: boolean
  buttonText: string
}

const DEFAULT_STATE: PauseSubscriptionReasonModalState = {
  loading: false,
  disabled: true,
  defaultMode: true,
  selectedReasons: [],
  otherSelected: false,
  buttonText: 'SELECT AT LEAST 1 ANSWER',
}

export function PauseSubscriptionReasonModal({ onClose }: PauseSubscriptionReasonModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<PauseSubscriptionReasonModalState>({ ...DEFAULT_STATE })
  const [pauseSubscriptionReason] = usePauseSubscriptionReason()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const [form] = useForm()

  const reasons = [
    'I want to make an on demand order',
    'This week’s menu doesn’t appeal to me',
    'I wasn’t happy with my last order',
    'It doesn’t fit into my upcoming schedule',
    'It’s too pricey',
    'It no longer suits my diet or lifestyle',
    'I’m away',
    'Other',
  ]

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION_REASON)
    onClose?.()
  }

  const _handleSuccess = async (data: PauseSubscriptionReasonFormData) => {
    setState({ ...state, loading: true })

    try {

      const sendData = {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
        userId: userCartData?.currentUser?.id,
        reasons: state.selectedReasons,
        reasoning: data.pauseReasoning,
      }

      await pauseSubscriptionReason({
        variables: sendData,
        refetchQueries: [{
          query: UserCartDocument,
        }, {
          query: UserDetailsDocument,
        }],
        awaitRefetchQueries: true,
      })

      form.setFieldsValue({ pauseReasoning: '' })

      _handleClose()

    } catch (e) {
      console.log(e.message)
    }
    setState((prevState) => ({
      ...prevState,
      loading: false,
      otherSelected: false,
      defaultMode: true,
      selectedReasons: [],
    }))
  }

  const handlePauseOptionClick = (reason: string): void => {

    const otherReason = 'Other'
    const isOtherReason = reason === otherReason
    let selectedReasons = state.selectedReasons

    if (isOtherReason) {
      selectedReasons = [otherReason]
    } else {
      selectedReasons = selectedReasons.filter(selectedReason => selectedReason !== otherReason)
      if (isSelectedReason(reason)) {
        selectedReasons = selectedReasons.filter(selectedReason => selectedReason !== reason)
      } else {
        selectedReasons = [reason]
      }
    }

    const buttonText = getButtonText(selectedReasons, isOtherReason)

    setState((prevState) => ({ ...prevState, selectedReasons, otherSelected: isOtherReason, buttonText }))
  }

  const isSelectedReason = (reason: string): boolean => state.selectedReasons.includes(reason)

  const updateInterface = () => {
    setState((prevState) => ({
      ...prevState,
      defaultMode: !prevState.defaultMode,
      buttonText: getButtonText(state.selectedReasons, state.otherSelected, !prevState.defaultMode),
    }))
  }

  const handlePrimaryAction = (): void => {
    if (state.disabled) return

    if (!state.defaultMode || (state.defaultMode && !state.otherSelected)) {
      form.submit()
    } else {
      updateInterface()
    }
  }

  useEffect(() => {
    setState((prevState) => ({ ...prevState, disabled: state.selectedReasons.length < 1 }))
  }, [state.selectedReasons.length])

  const getButtonText = (selectedReasons: string[], otherSelected: boolean, defaultMode?: boolean) => {

    const dMode = defaultMode ?? state.defaultMode
    let buttonText = 'SEND FEEDBACK'

    if (selectedReasons?.length < 1) {
      buttonText = 'SELECT AT LEAST 1 ANSWER'
    } else if (dMode && otherSelected) {
      buttonText = 'TELL US MORE'
    }
    return buttonText
  }

  let reason: string
  let index: number

  return (
    <Modal
      title={'You\'ve paused your subscription'}
      subTitle={'We\'ll miss you! Please let us know why you\'ve chosen to pause your account'}
      open={data?.modals?.pauseSubscriptionReason}
      allowBackgroundClose={false}
      fullscreen={false}
      onClose={_handleClose} >
      <Container $isNativeApplication={isNativeApplication}>
        <Choose>
          <When condition={userCartLoading}>
            <SectionLoading />
          </When>
          <Otherwise>
            <Form
              form={form}
              onFinish={_handleSuccess}
              disabled={state.loading || state.disabled} >
              <Choose>
                <When condition={state.defaultMode}>
                  <For each="reason" index="index" of={reasons}>
                    <PauseOption $isActive={isSelectedReason(reason)} key={index} onClick={() => state.loading ? {} : handlePauseOptionClick(reason)} >
                      <Paragraph
                        variant='p1'
                        color={isSelectedReason(reason) ? theme.colors.greys.liteCodGrey : theme.colors.slates.bitter}>
                        {reason}
                      </Paragraph>
                    </PauseOption>
                  </For>
                </When>
                <Otherwise>
                  <>
                    <Paragraph variant='p1' align='center'>
                      Let us know more about your reason for pausing your subscription.
                    </Paragraph>
                    <TextAreaInput
                      name='pauseReasoning'
                      label='Feedback'
                      placeholder='Let us know here'
                      rules={[{ required: true, message: 'Please input your feedback' }]}
                    />
                  </>
                </Otherwise>
              </Choose>
              <Spacer universal='24px' />
              <Button
                loading={state.loading}
                disabled={state.loading || state.disabled}
                color='black'
                fullWidth
                title={state.buttonText}
                onClick={handlePrimaryAction} />
            </Form>
          </Otherwise>
        </Choose>
      </Container>
    </Modal >
  )
}
