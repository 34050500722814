import React from 'react'

import { useParams } from 'react-router'

import { useOneFrozenMealDishQuery, FrozenMealDishDetailsFragment } from '@hooks/api'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

import { CraftDessertPDP, DetailPageWrapper } from '../organisms'

const seo = (dish?: FrozenMealDishDetailsFragment): SearchEngineOptimizationParams => ({
  name: 'UCOOK Craft Dessert Dish',
  title: `${dish?.name ?? 'Awesome'} Recipe & Ingredients | UCOOK`,
  meta: [{
    name: 'description',
    content: `${dish?.description ?? 'Amazing Recipe'}`,
  }, {
    name: 'keywords',
    content: `${dish?.name ?? 'Awesome'} Recipe,${dish?.name ?? 'Awesome'} Ingredients,${dish?.name ?? 'Awesome'} delivery`,
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
})

export function CraftDessertDetails(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()
  const { data, loading, error } = useOneFrozenMealDishQuery({ variables: { slug } })

  const dish = data?.dish

  return (
    <DetailPageWrapper
      loading={loading && !dish}
      error={error}
      pdp={<CraftDessertPDP dish={dish} loading={loading} />}
      seo={<SearchEngineOptimization seo={seo(dish)} />} />
  )

}
