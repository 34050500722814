import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage, Button, Heading, IconEnum, Paragraph, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@client/components/Theme'
import { UserMenuDishFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsivePXValue('margin-bottom', '12px')}
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${ResponsivePXValue('padding', '0 12px')}
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  .close {
    border-radius: 50%;
    ${ResponsivePXValue('width', '20px')}
    ${ResponsivePXValue('height', '20px')}

    .icon-container {
      ${ResponsivePXValue('width', '16px')}
      ${ResponsivePXValue('height', '16px')}
    }
  }
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  ${ResponsivePXValue('width', { mobile: '56px', tablet: '56px', desktop: '80px' })}
  ${ResponsivePXValue('height', { mobile: '56px', tablet: '56px', desktop: '80px' })}
`
export interface CookWithinDaysDishProps {
  dish: UserMenuDishFragment
  onRemove: (id: string) => void
}

export function CookWithinDaysDish({ dish, onRemove }: CookWithinDaysDishProps): JSX.Element {

  const _handleRemove = (): void => {
    if (onRemove) {
      onRemove(dish.id)
    }
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={dish.dish.coverImage} lazy={false} />
      </ImageContainer>
      <ContentContainer>
        <Heading variant='h6'>{dish.dish.name}</Heading>
        <Spacer universal='4px' />
        <If condition={!!dish.dish.cookWithin} >
          <Paragraph variant='p2' bold={false}>Eat within {dish.dish.cookWithin} days</Paragraph>
          <Spacer universal='4px' />
        </If>
      </ContentContainer>
      <IconContainer>
        <Button
          className='close'
          color='grey'
          icon={IconEnum.CLOSE}
          onClick={_handleRemove} />
      </IconContainer>
    </Container>
  )
}
