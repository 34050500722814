import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Paragraph, Seperator } from '@atoms/index'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'

import { NutritionalInfo, NutritionalInfoProps } from './NutritionalInfo'

export interface IngredientsProps {
  ingredients: string
  nutritionalInfo: NutritionalInfoProps
  allergens?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const IngredientsContainer = styled.div`
   ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '32px' })}
   padding-bottom: 0;
`

const HeaderContainer = styled.div`
  h5, h6 {
     ${ZeroSpace};

     ${ResponsivePXValue('font-size', '14px')}
     ${ResponsivePXValue('line-height', '24px')}
     ${ResponsivePXValue('margin-bottom', '8px')}
  }
`

const TextContainer = styled.div`
  div {
    ${ZeroSpace}
    font-weight: 400;

    ${ResponsivePXValue('font-size', '14px')}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

export function Ingredients({ ingredients, nutritionalInfo, allergens }: IngredientsProps): JSX.Element {

  const theme = useTheme()
  const seperatorAlign: 'left' | 'center' | 'right' = 'left'

  return (
    <Container>
      <IngredientsContainer>
        <HeaderContainer>
          <Heading variant='h5' color={theme.colors.greys.darkCodGrey}> Ingredients </Heading>
        </HeaderContainer>
        <Seperator align={seperatorAlign} />
        <TextContainer>
          <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey}>
            {ingredients}
          </Paragraph>
        </TextContainer>
      </IngredientsContainer>
      <NutritionalInfo {...nutritionalInfo} />
      <If condition={!!allergens}>
        <IngredientsContainer>
          <HeaderContainer>
            <Heading variant='h5' color={theme.colors.greys.darkCodGrey}> Allergens </Heading>
          </HeaderContainer>
          <Seperator align={seperatorAlign} />
          <TextContainer>
            <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey}>
              {allergens}
            </Paragraph>
          </TextContainer>
        </IngredientsContainer>
      </If>
    </Container>
  )

}
