import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Button, scrollToLink } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { getObjectFit, ResponsivePXValue } from '@components/Theme'
import { PageCardFragment } from '@hooks/api'

const Container = styled.div<{ $height?: string, $mobileHeight?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  Overflow: hidden; 

  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw' })}
  ${ResponsivePXValue('height', { mobile: '160px', tablet: '230px', desktop: '230px' }, { mobile: true })}

  .action {
    z-index: 1;
    ${ResponsivePXValue('min-width', { mobile: '50vw', tablet: '30vw' })}
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
`

export interface PageCardStackedProps {
  pageCard: PageCardFragment
  className?: string
}

export function PageCardStacked({ pageCard, className }: PageCardStackedProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const linkUrl = pageCard?.link?.link
  const linkTitle = pageCard?.link?.title

  const objectFit = getObjectFit(pageCard)

  const _handleLinkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    if (e.defaultPrevented) return

    e.preventDefault()

    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container className={className} onClick={_handleLinkClick}>
      <If condition={!!pageCard?.image}>
        <ImageContainer>
          <ResponsiveImage image={pageCard.image} objectFit={objectFit} />
        </ImageContainer>
      </If>
      <If condition={!!linkTitle}>
        <Button className='action' title={pageCard.cardTitle} variant='secondary' color='white' href={linkUrl} />
      </If>
    </Container>
  )
}
