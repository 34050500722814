import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Spacer, Paragraph } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@components/Theme'

const OuterContainer = styled.div`
  border-top: 1px solid ${(props): string => props.theme.colors.slates.celeste};
`

const Container = styled.div`
  display: flex;
  width: 100%;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}

  .title-text {
    ${ZeroSpace};
    font-weight: 500;
    ${ResponsivePXValue('font-size', '14px')}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .value-text {
    ${ZeroSpace};
    font-weight: 400;
    ${ResponsivePXValue('font-size', '14px')}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .spacer {
    display: flex;
    flex-grow: 1;
  }
`

const Row = styled.div`
  ${ResponsiveProperty('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  ${ResponsivePXValue('padding', '16px 0')} 
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props): string => props.theme.colors.slates.celeste};
`

export interface DishInfoTableData {
  col1: DishInfoColData
  col2: DishInfoColData
}
interface DishInfoColData {
  key: string
  value: string
}
export interface DishInfoTableProps {
  data: DishInfoTableData[]
}

export function DishInfoTable({ data }: DishInfoTableProps): JSX.Element {

  const theme = useTheme()

  let record: DishInfoTableData
  let i: number

  return (
    <OuterContainer>
      <For each='record' of={data} index='i'>
        <Container key={i}>
          <Row>
            <Spacer universal='8px' />
            <Paragraph variant='p2' color={theme.colors.greys.darkCodGrey} className='title-text'>{record.col1.key}</Paragraph>
            <Spacer className='spacer' universal='8px' variant='horizontal'/>
            <Paragraph variant='p1' className='value-text'>{record.col1.value}</Paragraph>
            <Spacer universal='16px' variant='horizontal' />
          </Row>
          <Row>
            <Spacer universal='8px' />
            <Paragraph variant='p2' color={theme.colors.greys.darkCodGrey} className='title-text'>{record.col2.key}</Paragraph>
            <Spacer className='spacer' universal='8px' variant='horizontal'/>
            <Paragraph variant='p1' className='value-text'>{record.col2.value}</Paragraph>
            <Spacer universal='8px' variant='horizontal' />
          </Row>
        </Container>
      </For>
    </OuterContainer>
  )
}
