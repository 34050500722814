import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

const RuleElement = styled.div<{ $color?: string }>`

  flex-grow: 1;
  width: 100%;
  background-color: ${(props): string => props.$color};
  max-height: 1px;

  ${ResponsivePXValue('height', '1px')}
`

const RuleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .rule-header {
    ${ResponsivePXValue('margin', '0 10px')}
    flex-shrink: 0;
  }
`
export interface RuleProps {
  color?: 'orange' | 'grey' | 'slate' | 'isabelline' | string
  text?: string
  textVariant?: 'h4' | 't1'
  className?: string
}

export function Rule({ color, text, textVariant = 'h4', className }: RuleProps): JSX.Element {

  let bg = theme.colors.oranges.coral

  if (color === 'grey') {
    bg = theme.colors.greys.liteCodGrey
  } else if (color === 'slate') {
    bg = theme.colors.whites.desertStorm
  } else if (color === 'isabelline') {
    bg = theme.colors.greys.isabelline
  } else if (color === 'orange') {
    bg = theme.colors.greys.isabelline
  } else {
    bg = color
  }

  if (text) {
    return (
      <RuleContainer className={className}>
        <RuleElement className='rule-element' color={bg} />
        <Choose>
          <When condition={textVariant === 't1'}>
            <Paragraph className='rule-header' variant='p1'>{text}</Paragraph>
          </When>
          <Otherwise>
            <Heading className='rule-header' variant='h4'>{text}</Heading>
          </Otherwise>
        </Choose>
        <RuleElement $color={bg} />
      </RuleContainer>
    )
  }

  return <RuleElement $color={bg} className={className} />

}
