import { sentenceCase } from 'change-case'
import memoize from 'fast-memoize'
import { ColorGroups, CSS, css } from 'styled-components'

import { PageMediaSectionFragment, PageGridItemFragment, PageBannerFragment, PageCardFragment, PageDetailFragment } from '@hooks/api'
import { PageSectionBackgroundEnum } from '@uctypes/api/globalTypes'

const cachedStyles: { [k: string]: string } = {}
const VW_PRECISION = 3
const MAX_WIDTH = 1440
const breakpointSize: { [k: string]: number } = {
  ultra: MAX_WIDTH,
  desktop: 1024,
  tablet: 768,
  mobile: 480,
  mobileMax: 767,
}

const calculationSize: { [k: string]: number } = {
  desktop: 1440,
  tablet: 768,
  mobile: 320,
}

const pagePadding: { [k: string]: number } = {
  desktop: 96,
  tablet: 32,
  mobile: 16,
}

interface SizeClasses {
  ultra: string
  desktop: string
  tablet: string
  mobile: string
  mobileMax: string
  ultraImage: number
  desktopImage: number
  tabletImage: number
  mobileImage: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}

export interface FormFactor {
  mobile?: string
  tablet?: string
  desktop?: string
  ultra?: string | number
}

const sizeClasses: SizeClasses = {
  ultra: '',
  desktop: '',
  tablet: '',
  mobile: '',
  mobileMax: '',
  ultraImage: 2000,
  desktopImage: 1600,
  tabletImage: 1024,
  mobileImage: 768,
}

Object.keys(breakpointSize).forEach((key: keyof SizeClasses) => {
  const val: number = breakpointSize[key]

  if (key === 'mobileMax') {
    sizeClasses[key] = `@media (max-width: ${val / 16}em)`
  } else {
    sizeClasses[key] = `@media (min-width: ${val / 16}em)`
  }
})

const isMobile = (): boolean => {
  if (typeof window === 'undefined') {
    return true
  } else {
    if (document.documentElement.clientWidth < breakpointSize.tablet) {
      return true
    }
    return false
  }
}
const isTablet = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (
      document.documentElement.clientWidth >= breakpointSize.tablet &&
      document.documentElement.clientWidth < breakpointSize.desktop
    ) {
      return true
    }
    return false
  }
}
const isDesktop = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (document.documentElement.clientWidth >= breakpointSize.desktop) {
      return true
    }
    return false
  }
}
const isUltra = (): boolean => {
  if (typeof window === 'undefined') {
    return false
  } else {
    if (document.documentElement.clientWidth > breakpointSize.ultra) {
      return true
    }
    return false
  }
}

export const theme = {
  colors: {
    greys: {
      darkCodGrey: '#111111',
      liteCodGrey: '#1E1E1E',
      tundora: '#4B4B4B',
      boulder: '#787878',
      mineshaft: '#292929',
      platinum: '#E8E8E8',
      cultured: '#F7F5F2',
      isabelline: '#F2F0EC',
    },
    slates: {
      ironsideGrey: '#686860',
      bitter: '#8C8C82',
      ash: '#BEBEAF',
      celeste: '#D7D7CD',
    },
    oranges: {
      burntSienna: '#E87543',
      coral: '#FA8246',
      tanHide: '#FA9B69',
      roseBud: '#FAB491',
    },
    yellows: {
      gamboge: '#E59B07',
      selectiveYellow: '#FAB400',
      sunglow: '#FBC333',
      goldenrod: '#FCD266',
    },
    reds: {
      cautionRed: '#F3715F',
      desire: '#E14027',
      raspberry: '#FF5398',
      promo: '#F82A14',
    },
    greens: {
      asparagus: '#87964E',
      chelseaCucumber: '#91A555',
      greenSmoke: '#A7B777',
      pineGlade: '#BDC999',
      fruitSalad: '#4FA970',
    },
    blues: {
      danube: '#6A90C6',
      chetwodeBlue: '#7DA0D7',
      regentStreetBlue: '#97B3DF',
      spindle: '#B1C6E7',
    },
    whites: {
      pureWhite: '#FFFFFF',
      alabaster: '#FAFAFA',
      desertStorm: '#F1F1EE',
      wildSand: '#F5F5F5',
      silver: '#CACACA',
      roseWhite: '#fff9f8',
    },
    misc: {
      error: '#FB6C59',
      facebookBlue: '#3B5998',
      twitterBlue: '#00ACEE',
      whatsAppGreen: '#25D366',
      googleRed: '#DB4437',
      youTubeRed: '#F20C1A',
      instagramPink: '#DF346D',
      vitalityOrange: '#FF5921',
      tiktokGreen: '#02f7ef',
      transparent: 'RGBA(255, 255, 255, 0)',
      modalBackground: 'RGBA(0, 0, 0, 0.5)',
      lightGrey: '#ECECEC',
      lightGreen: 'rgba(79, 169, 112, 0.12)',
      overlay: 'rgba(17, 17, 17, 0.25)',
      opaque: 'rgba(250, 250, 250, 0.20)',
      deals: '#e41f1f',
    },
  },
  MAX_WIDTH,
  uw: (size: number): string => {
    return Math.trunc((MAX_WIDTH / 100) * size) + 'px'
  },
  screenWidth: (): number => {
    if (typeof window === 'undefined') {
      return MAX_WIDTH
    }
    return document.documentElement.clientWidth
  },
  isMobile,
  isTablet,
  isDesktop,
  isUltra,
  getPxWidth: (px: number): number => {
    if (typeof window !== 'undefined' && document.documentElement.clientWidth >= MAX_WIDTH) {
      return px
    }
    if (isMobile()) {
      return Math.round(calculationSize.mobile / document.documentElement.clientWidth * px)
    } else if (isTablet()) {
      return Math.round(calculationSize.tablet / document.documentElement.clientWidth * px)
    } else if (isDesktop()) {
      return Math.round(calculationSize.desktop / document.documentElement.clientWidth * px)
    }
    return px
  },
  getVwWidth: (px: number): string => {
    if (typeof window !== 'undefined') {
      return px + 'px'
    }
    if (isMobile()) {
      return (px / calculationSize.mobile * 100).toFixed(VW_PRECISION) + 'vw'
    } else if (isTablet()) {
      return (px / calculationSize.tablet * 100).toFixed(VW_PRECISION) + 'vw'
    } else if (isDesktop()) {
      return (px / calculationSize.desktop * 100).toFixed(VW_PRECISION) + 'vw'
    }
    return px + 'px'
  },
  ...sizeClasses,
  breakpointSize,
  calculationSize,
  pagePadding,
}

export const getAllColors = (): { [k: string]: string } => {
  const allColors: { [k: string]: string } = {}
  for (let g = 0; g < Object.keys(theme.colors).length; g++) {
    const group = Object.keys(theme.colors)[g] as keyof ColorGroups
    for (let c = 0; c < Object.keys(theme.colors[group]).length; c++) {
      const color = Object.keys(theme.colors[group])[c] as string
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allColors[sentenceCase(color)] = theme.colors[group][color]
    }
  }
  return allColors
}

export const LiteBoxShadow = css`
  box-shadow: 0 0 1.5vw rgba(120, 120, 120, 0.1);

  ${(props): string => props.theme.tablet} {
    box-shadow: 0 0 1vw rgba(120, 120, 120, 0.1);
  }

  ${(props): string => props.theme.desktop} {
    box-shadow: 0 0 0.5vw rgba(120, 120, 120, 0.1);
  }

  ${(props): string => props.theme.ultra} {
    box-shadow: 0 0 ${(props): string => props.theme.uw(0.5)} rgba(120, 120, 120, 0.1);
  }
`

export const MediumBoxShadow = css`
  box-shadow: 0 0.375vw 6px rgba(17, 17, 17, 0.15);

  ${(props): string => props.theme.tablet} {
    box-shadow: 0 0.25vw 4vw rgba(17, 17, 17, 0.15);
  }

  ${(props): string => props.theme.desktop} {
    box-shadow: 0 0.125vw 2vw rgba(17, 17, 17, 0.15);
  }

  ${(props): string => props.theme.ultra} {
    box-shadow: 0 ${(props): string => props.theme.uw(0.125)} ${(props): string => props.theme.uw(2)} rgba(17, 17, 17, 0.15);
  }
`
export const ModalScrollFade = css`

  --mask-image-content:linear-gradient(
      to bottom,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
  );
  --mask-size-content: 100%;

  mask-image: var(--mask-image-content);
  mask-size: var(--mask-size-content);
  mask-repeat: no-repeat, no-repeat;  
`
export const StrongBoxShadow = css`
  box-shadow: 0 0.375vw 1.5px rgba(17, 17, 17, 0.175);

  ${(props): string => props.theme.tablet} {
    box-shadow: 0 0.25vw 1vw rgba(17, 17, 17, 0.175);
  }

  ${(props): string => props.theme.desktop} {
    box-shadow: 0 0.125vw 0.5vw rgba(17, 17, 17, 0.175);
  }

  ${(props): string => props.theme.ultra} {
    box-shadow: 0 ${(props): string => props.theme.uw(0.125)} ${(props): string => props.theme.uw(0.5)} rgba(17, 17, 17, 0.175);
  }
`
export const DarkHover = css`
  background-image: linear-gradient(rgba(0, 0, 0, 0.25) 0 0);
  border-color: transparent;
`

export const SpreadBoxShadow = css`
  box-shadow: 0 0.375vw 15px rgba(17, 17, 17, 0.175);

  ${(props): string => props.theme.tablet} {
    box-shadow: 0 0.25vw 1.2vw rgba(17, 17, 17, 0.175);
  }

  ${(props): string => props.theme.desktop} {
    box-shadow: 0 0.125vw 0.8vw rgba(17, 17, 17, 0.175);
  }

  ${(props): string => props.theme.ultra} {
    box-shadow: 0 ${(props): string => props.theme.uw(0.125)} ${(props): string => props.theme.uw(0.8)} rgba(17, 17, 17, 0.175);
  }
`

const getUltraValue = (value: FormFactor): string | number => {
  const isUltraNumber = typeof value?.ultra === 'number'
  const isDesktopNumber = typeof value?.desktop === 'number'
  if (value?.ultra) {
    return isUltraNumber ? theme.uw(Number(value?.ultra)) : value?.ultra
  }
  if (!value?.ultra && value?.desktop) {
    if (isDesktopNumber) {
      return theme.uw(Number(value?.desktop))
    }
    let safety = 0
    let match = value?.desktop.match(/(-?\d+\.?(?:\d+)?vw)/)
    let newValue = value?.desktop
    while (match && safety < 10) {
      const value = Number(match[1].replace('vw', ''))
      newValue = newValue.replace(match[1].trim(), theme.uw(value))
      match = newValue.match(/(-?\d+\.?(?:\d+)?vw)/)
      safety++
    }
    return newValue
  }
  return 'initial'
}

const _ResponsiveProperty = (prop: string, value: FormFactor): string => `
  ${`${prop}: ${value?.mobile ? value.mobile : 'initial'};`}
      
  ${theme.tablet} {
    ${`${prop}: ${value?.tablet ? value.tablet : 'initial'};`}
  }

  ${theme.desktop} {
    ${`${prop}: ${value?.desktop ? value.desktop : 'initial'};`}
  }

  ${theme.ultra} {
    ${`${prop}: ${getUltraValue(value)};`}
  }
`

export const ResponsiveProperty = memoize(_ResponsiveProperty)

function _ResponsivePXValue(prop: string, value: string | { mobile?: string, tablet?: string, desktop?: string }, skip?: { mobile?: boolean, tablet?: boolean, desktop?: boolean }, specific?: { mobile?: boolean, tablet?: boolean, desktop?: boolean }): string {

  if (typeof value === 'object') {
    let mobileValue = value?.mobile || 'initial'
    let tabletValue = value?.tablet || 'initial'
    let desktopValue = value?.desktop || 'initial'
    const ultraValue = value?.desktop || 'initial'
    let mobileMatch = mobileValue.match(/(-?\d+\.?(?:\d+)?px)/)
    let safety = 0
    while (!skip?.mobile && mobileMatch && safety < 10) {
      const value = Number(mobileMatch[1].replace('px', ''))
      mobileValue = mobileValue.replace(mobileMatch[1].trim(), (value / calculationSize.mobile * 100).toFixed(VW_PRECISION) + 'vw')
      mobileMatch = mobileValue.match(/(-?\d+\.?(?:\d+)?px)/)
      safety++
    }
    let tabletMatch = tabletValue.match(/(-?\d+\.?(?:\d+)?px)/)
    safety = 0
    while (!skip?.tablet && tabletMatch && safety < 10) {
      const value = Number(tabletMatch[1].replace('px', ''))
      tabletValue = tabletValue.replace(tabletMatch[1].trim(), (value / calculationSize.tablet * 100).toFixed(VW_PRECISION) + 'vw')
      tabletMatch = tabletValue.match(/(-?\d+\.?(?:\d+)?px)/)
      safety++
    }
    let desktopMatch = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/)
    safety = 0
    while (!skip?.desktop && desktopMatch && safety < 10) {
      const value = Number(desktopMatch[1].replace('px', ''))
      desktopValue = desktopValue.replace(desktopMatch[1].trim(), (value / calculationSize.desktop * 100).toFixed(VW_PRECISION) + 'vw')
      desktopMatch = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/)
      safety++
    }

    if (specific?.mobile) {
      return `
      ${theme.mobileMax} {
        ${`${prop}: ${mobileValue};`}
      }
    `
    }

    if (specific?.tablet) {
      return `
      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      } 
    `
    }

    if (specific?.desktop) {
      return `
       ${theme.desktop} {
          ${`${prop}: ${desktopValue};`}
       }
     `
    }

    return `
      ${`${prop}: ${mobileValue};`}

      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      }

      ${theme.desktop} {
        ${`${prop}: ${desktopValue};`}
      }

      ${theme.ultra} {
        ${`${prop}: ${ultraValue};`}
      }
    `
  } else {
    let safety = 0
    let ultraValue = value
    let desktopValue = value
    let tabletValue = value
    let mobileValue = value
    let match = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/)
    while (match && safety < 10) {
      const value = Number(match[1].replace('px', ''))
      const vwValue = value / theme.MAX_WIDTH * 100
      mobileValue = mobileValue.replace(match[1].trim(), (vwValue * MAX_WIDTH / calculationSize.mobile).toFixed(VW_PRECISION) + 'vw')
      tabletValue = tabletValue.replace(match[1].trim(), (vwValue * MAX_WIDTH / calculationSize.tablet).toFixed(VW_PRECISION) + 'vw')
      desktopValue = desktopValue.replace(match[1].trim(), vwValue.toFixed(VW_PRECISION) + 'vw')
      ultraValue = ultraValue.replace(match[1].trim(), `${value}px`)
      match = desktopValue.match(/(-?\d+\.?(?:\d+)?px)/)
      safety++
    }

    if (specific?.mobile) {
      return `
      ${theme.mobileMax} {
        ${`${prop}: ${mobileValue};`}
      }
    `
    }

    if (specific?.tablet) {
      return `
      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      } 
    `
    }

    if (specific?.desktop) {
      return `
       ${theme.desktop} {
          ${`${prop}: ${desktopValue};`}
       }
     `
    }

    return `
      ${`${prop}: ${mobileValue};`}

      ${theme.tablet} {
        ${`${prop}: ${tabletValue};`}
      }

      ${theme.desktop} {
        ${`${prop}: ${desktopValue};`}
      }

      ${theme.ultra} {
        ${`${prop}: ${ultraValue};`}
      }
    `
  }
}

export const ResponsivePXValue = memoize(_ResponsivePXValue, {
  cache: {
    create() {
      return {
        has(key) {
          return (key in cachedStyles)
        },
        get(key) {
          // console.log(`RETURNING ${key} FROM CACHE`)
          return cachedStyles[key]
        },
        set(key, value) {
          // console.log(`SETTING ${key} IN CACHE`)
          cachedStyles[key] = value
        },
      }
    },
  },
})

export const getObjectFit = (media: PageMediaSectionFragment | PageGridItemFragment | PageBannerFragment | PageCardFragment | PageDetailFragment): string | null => {
  return media?.objectFit ? (theme.isMobile() ? media?.mobileObjectFit : media?.objectFit) : null
}

export const getPageSectionBackground = (background: string): string => {

  let backgroundColor

  switch (background) {
    case PageSectionBackgroundEnum.WHITE:
      backgroundColor = theme.colors.whites.alabaster
      break
    case PageSectionBackgroundEnum.GREY:
      backgroundColor = theme.colors.whites.desertStorm
      break
    case PageSectionBackgroundEnum.LIGHT_GREY:
      backgroundColor = theme.colors.misc.lightGrey
      break
    case PageSectionBackgroundEnum.TRANSPARENT:
      backgroundColor = theme.colors.misc.transparent
      break
    default:
      break
  }

  return backgroundColor
}

export const Ellipsis = (lineClamp?: number): CSS => {

  if (lineClamp) {
    return css`
    display: -webkit-box;
    -webkit-line-clamp: ${lineClamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `
  }
  return css`
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  `
}

export const HoverEffect = css`
  filter: brightness(90%);
`

export const DisabledEffect = css`
  filter: brightness(50%);
  cursor: default;
  pointer-events: none;
`

export const LoadEffect = css`
  opacity: 0.65;
  filter: brightness(95%);
  cursor: default;
  pointer-events: none;
`

export const IconBorderRadius = css`
   ${ResponsiveProperty('border-radius', { mobile: '1.1vw', tablet: '0.55vw', desktop: '0.27vw' })}
`

export const ContainerBorderRadius = css`
  ${ResponsiveProperty('border-radius', { mobile: '1.66vw', tablet: '1.56vw', desktop: '1.11vw' })}
`

export const ZeroSpace = css`
  margin: 0;
  padding: 0;
`

export const PageWidth = css`
  ${ResponsivePXValue('width', { mobile: `${calculationSize.mobile - (pagePadding.mobile * 2)}px`, tablet: `${calculationSize.tablet - (pagePadding.tablet * 2)}px`, desktop: `${calculationSize.desktop - (pagePadding.desktop * 2)}px` })}
`
export const SidePagePadding = css`
  ${PageWidth}
  ${ResponsivePXValue('padding', { mobile: `0 ${pagePadding.mobile}px`, tablet: `0 ${pagePadding.tablet}px`, desktop: `0 ${pagePadding.desktop}px` })}
`

export const TopPagePadding = css`
  ${ResponsivePXValue('padding-top', { mobile: '20px', tablet: '24px', desktop: '24px' })}
`
export const PageSectionPadding = css`
  ${ResponsivePXValue('padding', { mobile: `32px ${pagePadding.mobile}px`, tablet: `40px ${pagePadding.tablet}px`, desktop: `48px ${pagePadding.desktop}px` })}
`

export const MobileFullWidth = css`
  ${ResponsiveProperty('position', { mobile: 'absolute', tablet: 'absolute', desktop: 'relative' })}
  ${ResponsiveProperty('width', { mobile: '100vw', tablet: '100vw', desktop: '100%' })}
  ${ResponsiveProperty('margin-left', { mobile: '-4vw', tablet: '-8vw' })}
`
export const PageMediaImage = css`
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  ${ResponsivePXValue('width', { mobile: '270px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('max-width', { mobile: '270px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('height', { mobile: '270px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('max-height', { mobile: '270px', tablet: '300px', desktop: '424px' })}
`
export const PageMediaPortraitImage = css`
  position: relative;
  overflow: hidden;
  border-radius: 24px;

  ${ResponsivePXValue('width', { mobile: '272px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('max-width', { mobile: '272px', tablet: '300px', desktop: '424px' })}
  ${ResponsivePXValue('height', { mobile: '348px', tablet: '300px', desktop: '545px' })}
  ${ResponsivePXValue('max-height', { mobile: '348px', tablet: '300px', desktop: '545px' })}
`
export const PageMediaImageFloat = css`
 ${ResponsivePXValue('top', { mobile: '-95px', tablet: '-100px', desktop: '-110px' })}
`
export const LargePageMediaImage = css`
  position: relative;
  overflow: hidden;

  ${ResponsivePXValue('width', { mobile: '100%', tablet: '320px', desktop: '504px' })}
  ${ResponsivePXValue('max-width', { mobile: '90vw', tablet: '320px', desktop: '504px' })}
  ${ResponsivePXValue('height', { mobile: '320px', tablet: '320px', desktop: '504px' })}
  ${ResponsivePXValue('max-height', { mobile: '320px', tablet: '320px', desktop: '504px' })}
`
export const LargePageMediaImageFloat = css`
 ${ResponsivePXValue('top', { mobile: '-95px', tablet: '-100px', desktop: '-110px' })}
`

export const BorderBottom = css`
  border-bottom: 1px solid ${(props): string => props.theme.colors.whites.desertStorm};
  &:last-child {
    border-bottom: none;
  }
`
export const NotFound = css`
  display: flex;
  flex: 1;
  justify-content: center;
  font-family: gordita;
  font-weight: 500;

  color: ${(props): string => props.theme.colors.slates.bitter};
  
  ${ResponsivePXValue('padding', { mobile: '10px', tablet: '15px', desktop: '20px' })}
`

export const ProductCategoriesContainer = css`
  position: relative;
  overflow: hidden;

  .product-categories {
    max-height: 85%;
    overflow-y: auto;
  }
`

export const UserSelectNone = css`
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export const CarouselFlexContainer = css`
  display: flex;
  flex: 1;
  height: fit-content;
  align-self: center;
`

export const CarouselFlexContainerShort = css`
  display: flex;
  flex: 1;
  height: fit-content;
  align-self: left;
`
export const CarouselContainerWidth = css`
  ${PageWidth}
  ${ResponsivePXValue('max-width', '1165px')}
`
export const CarouselMaxWidth = css`
 ${ResponsivePXValue('max-width', { mobile: '100vw', tablet: '90vw', desktop: '1110px' })}
`
export const CarouselSlideMargin = css`
 ${ResponsivePXValue('margin', { mobile: '0 8px', tablet: '0 14px', desktop: '0 16px' })}
`
export const CarouselCardWidth = css`
 ${ResponsivePXValue('min-width', { mobile: 'min(82vw, 288px)', tablet: '288px', desktop: '343px' })}
 ${ResponsivePXValue('max-width', { mobile: '82vw', tablet: '288px', desktop: '343px' })}
`
export const ModalContainerStyle = css`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('width', { desktop: '576px' })}
`

export const AislesLoadingContainer = css`
  width: auto;
  ${ResponsivePXValue('height', { mobile: '300px', tablet: '500px', desktop: 'calc(100vh - 625px)' })}
`

export const ModalActionContainer = (stickyBottom?: boolean): CSS => {

  return css`
    display: flex;
    align-items: center;
    flex-direction: column;

    .full-width {
      width: 100%;
    }

    ${(props): CSS => {
    if (stickyBottom) {
      return `
          position: sticky;
          bottom: 0;
          background: ${props.theme.colors.whites.pureWhite};
          ${ResponsivePXValue('padding-bottom', '16px')}
        `
    }
  }}
  `
}

export const ProductCardContainer = css`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-width: 272px;

  ${ResponsivePXValue('margin', { mobile: '0 4px', tablet: '0 4px', desktop: '0 8px' })}
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 46px)', desktop: '272px' })}

  .fluid-grow {
    flex-grow: 1;
  }
`

export const MiniProductCardContainer = css`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

export const ProductCardImageContainer = css`
  display: flex;
  flex: 1;
  cursor: pointer;

  ${ResponsivePXValue('height', { mobile: '191px' }, { desktop: true })}

  img {
    aspect-ratio: 3 / 2;
  }
`

export const MiniProductCardImageContainer = css`
  display: flex;
  flex: 1;
  cursor: pointer;

  ${ResponsivePXValue('height', '96px')}
  width: 100%;

  img {
    aspect-ratio: 3 / 2;
  }

`
export const ProductCardContentContainer = css`
  display: flex;
  flex-direction: column;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  
  ${ResponsivePXValue('padding', '12px')}
  
  .card-title {
    ${Ellipsis()}
  }
  .card-sub-title {
    ${Ellipsis(2)}
  }
`

export const MiniProductCardContentContainer = css`
  display: flex;
  flex-direction: column;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', '12px')}
  
  .card-title {
    ${Ellipsis()}
  }
  .card-sub-title {
    ${Ellipsis(2)}
  }
`

export const AislesBaseContainer = css`
  display: flex;
  flex-direction: column;

  .aisles-carousel-container {
    ${ResponsivePXValue('margin-left', '-8px')}
  }
  
  .aisles-filtered-data {
    ${ResponsivePXValue('max-width', { mobile: '90vw' })}

    .view-all{
      min-width: fit-content;
      align-items: center;
      align-self: center;
      height: fit-content;
      ${ResponsivePXValue('padding', '4px 0')}
    }
  }

  .nav-button {
    border-radius: 50%;
    background-color: ${(props): string => props.theme.colors.greys.tundora};
  }
`

export const SectionContainer = (backgroundColor?: string): CSS => {
  return css`
    display: flex;
    align-items: center;

    background-color: ${backgroundColor || 'transparent'};
    ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  `
}

export const PaddedSectionContainer = (backgroundColor?: string, customBackground?: string, padded?: boolean): CSS => css`
  position: relative;
  ${TopPagePadding}

  ${(): CSS => {
    if (padded) {
      return SidePagePadding
    }
  }}

  ${ResponsivePXValue('padding-top', { mobile: '28px', tablet: '30px', desktop: '32px' })}
  ${ResponsivePXValue('padding-bottom', { mobile: '28px', tablet: '30px', desktop: '32px' })}
  background-color: ${(props): string => customBackground || (backgroundColor ? (backgroundColor === 'white' ? props.theme.colors.whites.alabaster : props.theme.colors.whites.desertStorm) : props.theme.colors.whites.alabaster)};  
    
    .up-sell-title {
      font-weight: 500;
    }
  `

export const StoreStickyButtonContainer = (isMobile?: boolean, backgroundColor?: string): CSS => {
  return css`
    position: sticky;
    display: flex;
    align-items: center;
    z-index: 13;

    ${ResponsivePXValue('top', { mobile: '56px', desktop: '60px' })}
    ${ResponsivePXValue('padding', { mobile: '12px 0', desktop: '12px 0' })}

    background-color: ${(props): string => backgroundColor || props.theme.colors.greys.cultured};

    ${(): CSS => isMobile ? SectionHorizontalPadding : undefined};
    ${(): CSS => isMobile ? SectionHorizontalNegativeMargin : undefined};
  `
}

export const DashedButtonContainer = (color?: string, hoverColor?: string, backgroundColor?: string): CSS => {
  return css`
    border-style: dashed;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: 500;
    color: ${(props): string => color || props.theme.colors.slates.ash};
    border-color: ${(props) => color || props.theme.colors.slates.ash};

    &:hover {
      color: ${(props) => hoverColor || props.theme.colors.slates.bitter};
      border-color: ${(props) => hoverColor || props.theme.colors.slates.bitter};
    }

    ${(): CSS => backgroundColor ? `background-color: ${backgroundColor}` : undefined};
    
    ${ResponsivePXValue('font-size', '10px')}
    ${ResponsivePXValue('border-width', '2px')}
    ${ResponsivePXValue('height', { mobile: '72px', tablet: '80px', desktop: '88px' })}
  `
}

export const SectionHorizontalPadding = css`
  ${ResponsivePXValue('padding-left', { mobile: `${pagePadding.mobile}px`, tablet: `${pagePadding.tablet}px`, desktop: `${pagePadding.desktop}px` })}
  ${ResponsivePXValue('padding-right', { mobile: `${pagePadding.mobile}px`, tablet: `${pagePadding.tablet}px`, desktop: `${pagePadding.desktop}px` })}
`
export const SectionHorizontalNegativeMargin = css`
  ${ResponsivePXValue('margin-left', { mobile: `-${pagePadding.mobile}px`, tablet: `-${pagePadding.tablet}px`, desktop: `-${pagePadding.desktop}px` })}
  ${ResponsivePXValue('margin-right', { mobile: `-${pagePadding.mobile}px`, tablet: `-${pagePadding.tablet}px`, desktop: `-${pagePadding.desktop}px` })}
`
export const FadeEffect = (width?: string): CSS => {
  return css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient( to left,${(props): string => props.theme.colors.whites.alabaster} 0%,${(props): string => props.theme.colors.whites.alabaster} 10%,transparent 90%,transparent 100% );
  height: 100%;

  ${ResponsivePXValue('width', width || '200px')}
`
}

export enum ServingSizeEnum {
  SERVES_ONE = 'Serves 1',
  SERVES_TWO = 'Serves 2',
  SERVES_FOUR = 'Serves 4',
}

export const OrderItemTitleStyle = css`
  cursor: pointer;
  color: ${theme.colors.greys.liteCodGrey};
  text-align: left;
  text-decoration: none;

  &:hover {
    color: ${theme.colors.greys.boulder};
  }
`
