import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button, IconEnum } from '@client/components/atoms'
import { useConfig } from '@client/contexts/ConfigProvider'

const Container = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex: 1;
  .facebook-button {
    background-color: ${(props): string => props.$disabled ? props.theme.colors.whites.desertStorm : props.theme.colors.whites.pureWhite};
    border-color: ${(props): string => props.$disabled ? props.theme.colors.whites.desertStorm : props.theme.colors.greys.platinum};
    color: ${(props): string => props.$disabled ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.facebookBlue};
    height: 40px;
    font-weight: 405;
    font-size: 12px;
    border-width: 1px;
    .center-container{
      display: inline-block;
      align-items: left;
      justify-content: left;
      
    }
    
  }

  
  .facebook-button:hover {
    opacity: 0.6;
  }
`

export interface FacebookSocialButtonProps {
  disabled: boolean
  action?: 'Sign up' | 'Log in'
  className?: string
  didAuthenticateWithFacebook: (authResponse: fb.AuthResponse, status: fb.LoginStatus) => void
}

interface FacebookSocialButtonState {
  disabled: boolean
  loading: boolean
}

const DEFAULT_STATE: FacebookSocialButtonState = {
  disabled: false,
  loading: false,
}

export function FacebookSocialButton({ disabled, action = 'Sign up', className, didAuthenticateWithFacebook }: FacebookSocialButtonProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<FacebookSocialButtonState>({ ...DEFAULT_STATE })
  const fbButtonText = state.disabled ? `FACEBOOK ${action} DISABLED` : `${action}  with Facebook`
  const { addToast } = useToasts()

  const _handleFacebookResponse = (response: fb.StatusResponse): void => {
    if (response.status === 'connected') {
      didAuthenticateWithFacebook(response.authResponse, response.status)
    } else {
      addToast('Facebook was unable to authenticate you.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (typeof FB !== 'undefined') {
        FB.init({
          appId: config.getFacebook().appId,
          cookie: true,
          xfbml: true,
          version: 'v10.0',
        })
      } else {
        setState((prevState) => update(prevState, {
          disabled: { $set: true },
        }))
      }
    }
    return function cleanup() {
      mounted = false
    }
  }, [])

  const _handleClick = (): void => {
    if (typeof FB !== 'undefined') {
      _setLoading(true)
      FB.login((response) => {
        _handleFacebookResponse(response)
      }, { scope: 'public_profile,email' })
    }
  }

  const _setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const actuallyDisabled = state.disabled || disabled

  return (
    <Container className={className} $disabled={actuallyDisabled}>
      <Button
        className='facebook-button'
        title={fbButtonText}
        disabled={actuallyDisabled || state.loading}
        loading={state.loading}
        onClick={_handleClick}
        color='white'
        rightIcon={IconEnum.LOGO_FACEBOOK}
        fullWidth />
    </Container>
  )
}
