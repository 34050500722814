import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Link, Paragraph } from '@client/components/atoms'
import { SectionLoading } from '@client/components/molecules'
import { DashedButtonContainer, ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, UserMenuDishFragment } from '@hooks/api'

import { MealKitCartItem } from './MealKitCartItem'

const Container = styled.div`
  ${ResponsivePXValue('padding', '8px 0px')}
  max-width: 100%;

  .add-list-button {
    ${ResponsivePXValue('font-size', '10px')}
    border-width: 1px;
    ${ResponsivePXValue('margin', '8px 16px')}
    text-transform: uppercase;
  }

  .link {
    color: ${(props): string => props.theme.colors.slates.bitter};
  }

  .dashed-button {
    ${DashedButtonContainer(theme.colors.oranges.coral, theme.colors.oranges.burntSienna, 'rgba(250, 130, 70, 0.05)')}
    ${ResponsivePXValue('margin', '8px 16px')}
    width: auto;
  }
`

export function CartMealKit(): JSX.Element {

  const config = useConfig()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const menu = cartData?.currentUser?.activeMenu
  const navigate = useNavigate()
  let dishItem: UserMenuDishFragment

  const _generateButtonText = (length: number): string => {
    switch (length) {
      case 0:
        return 'Add dishes to customize this week’s order'
      case 1:
        return 'Add 1 more dish to complete your order'
      default:
        return 'Add another dish'
    }
  }

  const _handleOnAddNewItem = (): void => {
    navigate('/meal-kit')
    CartPlugin.shared().close()
  }

  const _handlePauseSubscription = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAUSE_SUBSCRIPTION)
    CartPlugin.shared().close()
  }
  const mealKitCart = [...menu?.dishes || []].reverse()

  const dishLength = menu.dishes.length

  return (
    <Container>
      <Choose>
        <When condition={cartLoading}>
          <SectionLoading loaderSize='24px' />
        </When>
        <Otherwise>
          <For each='dishItem' of={mealKitCart}>
            <MealKitCartItem key={dishItem.id} dish={dishItem} />
          </For>
          <If condition={menu?.dishes.length < 4}>
            <Button className='dashed-button' color='transparent' title={_generateButtonText(dishLength)} onClick={_handleOnAddNewItem} />
            <If condition={dishLength === 0}>
              <Paragraph
                variant='p2'
                color={theme.colors.slates.bitter}
                align='center'
                bold={false}>
                OR
              </Paragraph>
              <Paragraph
                variant='p2'
                color={theme.colors.slates.bitter}
                align='center'
                bold={false}>
                click <Link onClick={_handlePauseSubscription} variant='l2' className='link' bold>here</Link> to pause your subscription
              </Paragraph>
            </If>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )

}
