import React from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue, StrongBoxShadow } from '@components/Theme'

const Container = styled.div`
  position: relative; 
`

const BadgeContainer = styled.div<{ $offsetY: number, $offsetX: number, $noShadow: boolean }>`

  background-color: ${(props): string => props.theme.colors.oranges.coral};
  color: ${(props): string => props.theme.colors.whites.pureWhite};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: gordita;
  top: 0;
  right: 0;
  transform: translateY(${(props): number => props.$offsetY}%) translateX(${(props): number => props.$offsetX}%);

  ${(props): CSS => !props.$noShadow ? StrongBoxShadow : null};

  ${ResponsivePXValue('border-radius', '4px')}
  ${ResponsivePXValue('padding', '4px')}
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('min-width', '8px')}
  ${ResponsivePXValue('height', '8px')}

`

const TextContainer = styled.span`
  position: relative;
  ${ResponsivePXValue('top', '1px')}
`

export interface BadgeProps {
  children: React.ReactNode
  count: number
  offsetY?: number
  offsetX?: number
  noShadow?: boolean
  skeleton?: boolean
}

export function Badge({ children, count, offsetX = 40, offsetY = -40, noShadow = false }: BadgeProps): JSX.Element {

  return (
    <Container>
      {children}
      <BadgeContainer
        $offsetX={offsetX}
        $offsetY={offsetY}
        $noShadow={noShadow}>
        <TextContainer>
          {count ?? 0}
        </TextContainer>
      </BadgeContainer>
    </Container>
  )

}
