import { FieldPolicy } from '@apollo/client'

import { OrdersQuery } from '@hooks/api'
import { ConfigPlugin } from '@lib/Config'

export class OrderPlugin implements ConfigPlugin {

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    allOrders: {
      read(existing: OrdersQuery['orders'], { args: { skip, limit } }) {
        if (existing) {
          return {
            ...existing,
            list: existing.list.slice(0, skip + limit),
          }
        }
      },
      merge(existing: OrdersQuery['orders'], incoming: OrdersQuery['orders'], { args: { skip = 0 } }) {
        const merged = existing ? existing.list.slice(0) : []
        for (let i = 0; i < incoming.list.length; ++i) {
          merged[skip + i] = incoming.list[i]
        }
        return {
          ...incoming,
          list: merged,
        }
      },
    },
  })

}
