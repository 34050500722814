import React from 'react'

import styled, { CSS } from 'styled-components'

import { StaticImage, Paragraph, Spacer } from '@client/components/atoms'
import { MediumBoxShadow, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'

const Container = styled.div<{ $top: string, $height: string, $padding: string }>`
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${(props): CSS => ResponsivePXValue('top', props.$top)}
  ${(props): CSS => ResponsivePXValue('height', props.$height)}
  ${(props): CSS => ResponsivePXValue('padding', props.$padding)}
  ${ResponsivePXValue('border-bottom-left-radius', '8px')}
  ${ResponsivePXValue('border-top-left-radius', '8px')}
  ${MediumBoxShadow}

  .vitality-text {
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', '10px')}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const IconContainer = styled.div<{ $size: string }>`
  ${(props): CSS => ResponsivePXValue('width', props.$size)}
  ${(props): CSS => ResponsivePXValue('height', props.$size)}
`

export enum StickyHealthyDiningEnum {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface StickyHealthyDiningProps {
  type?: StickyHealthyDiningEnum
}

export function StickyHealthyDining({ type = StickyHealthyDiningEnum.LARGE }: StickyHealthyDiningProps): JSX.Element {

  return (
    <Choose>
      <When condition={type === StickyHealthyDiningEnum.LARGE}>
        <Container $top='24px' $height='32px' $padding='0 10px'>
          <IconContainer $size='20px'>
            <StaticImage
              staticId='vitality-indicator-small'
              lazy={false}
              objectFit='contain'
              mobileWidth={768}
              tabletWidth={1024}
              desktopWidth={1600} />
          </IconContainer>
          <Spacer universal='8px' />
          <Paragraph variant='p3' className='vitality-text' color={theme.colors.misc.vitalityOrange}>{'HealthyDining'}</Paragraph>
        </Container>
      </When>
      <When condition={type === StickyHealthyDiningEnum.MEDIUM}>
        <Container $top='24px' $height='32px' $padding='0 10px'>
          <IconContainer $size='20px'>
            <StaticImage
              staticId='vitality-indicator-small'
              lazy={false}
              objectFit='contain'
              mobileWidth={768}
              tabletWidth={1024}
              desktopWidth={1600} />
          </IconContainer>
        </Container>
      </When>
      <Otherwise>
        <Container $top='8px' $height='24px' $padding='0 8px'>
          <IconContainer $size='16px'>
            <StaticImage
              staticId='vitality-indicator-small'
              lazy={false}
              objectFit='contain'
              mobileWidth={768}
              tabletWidth={1024}
              desktopWidth={1600} />
          </IconContainer>
        </Container>
      </Otherwise>
    </Choose>
  )
}
