import React from 'react'

import styled, { CSS, css } from 'styled-components'

import { ResponsivePXValue } from '@client/components'

const Container = styled.div<{ $margin?: string, $direction?: string, $justify?: string }>`
  display: flex;
  width: 100%;
  ${(props) => (props.$direction) ? `flex-direction: ${props.$direction};` : 'flex-direction: row;'}
  ${(props) => (props.$justify) ? `justify-content: ${props.$justify};` : ''}
  ${(props) => (props.$margin) ? ResponsivePXValue('padding', props.$margin) : ''}
`

export interface TableRowProps {
  direction?: string
  margin?: string
  justify?: string
  children: JSX.Element[] | JSX.Element
}

export function TableRow(props: TableRowProps): JSX.Element {

  const { children, margin, direction, justify } = props

  return (
    <Container $margin={margin} $direction={direction} $justify={justify}>
      {children}
    </Container>
  )

}

export interface TableCellProps {
  className?: string
  align?: string
  grow?: string
  justify?: string
  direction?: string
  padding?: string
  maxWidth?: string
  children: JSX.Element[] | JSX.Element
  noZeroSpace?: boolean
}

const NoZero = css`
  div {
    padding: 0;
    margin: 0;
  }
`

const TableCellContainer = styled.div<{$align?: string, $grow?: string, $justify?: string, $direction?: string, $padding?: string, $maxWidth?: string, $noZeroSpace?: boolean}>`
  display: flex;
  ${(props) => (props.$align) ? `align-items: ${props.$align};` : ''}
  ${(props) => (props.$grow) ? `flex-grow: ${props.$grow};` : ''}
  ${(props) => (props.$justify) ? `justify-content: ${props.$justify};` : ''}
  ${(props) => (props.$direction) ? `flex-direction: ${props.$direction};` : ''}
  ${(props) => (props.$maxWidth) ? `max-width: ${props.$maxWidth};` : ''}
  ${(props) => (props.$padding) ? ResponsivePXValue('padding', props.$padding) : ''}
  ${(props): CSS => !props.$noZeroSpace ? NoZero : null}
  
`

export function TableCell(props: TableCellProps): JSX.Element {

  const { align, grow, justify, direction, maxWidth, padding, children, noZeroSpace = false, className } = props

  return (
    <TableCellContainer className={className} $align={align} $grow={grow} $justify={justify} $maxWidth={maxWidth} $padding={padding} $direction={direction} $noZeroSpace={noZeroSpace}>
      { children }
    </TableCellContainer>
  )
}
