import React from 'react'

import styled from 'styled-components'

import { SmallLoader } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const LoaderBox = styled.div<{ $size: string }>`
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('height', '32px')}
`

const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  position: absolute;
`

export interface LoadingOverlayProps {
  height?: string
  loaderSize?: string
}

export function SectionLoadingOverlay({ loaderSize = '32px' }: LoadingOverlayProps): JSX.Element {

  return (
    <LoaderContainer >
      <LoaderBox $size={loaderSize}>
        <SmallLoader color={theme.colors.oranges.coral} />
      </LoaderBox>
    </LoaderContainer>
  )

}
