import React from 'react'

import { CraftKidsInStoreCarousel } from '@molecules/content/CraftKidsInStoreCarousel'

interface PageDataCraftKidsInStoreCarouselProps {
  title: string
}

export function PageDataCraftKidsInStoreCarousel({ title }: PageDataCraftKidsInStoreCarouselProps): JSX.Element {

  return (
    <>
      <CraftKidsInStoreCarousel title={title} />
    </>
  )

}
