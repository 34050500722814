import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { Drawer, SectionLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useGetCheckoutQuery } from '@hooks/api'
import { CheckoutTypeEnum } from '@uctypes/api/globalTypes'

import { CartBreakdown, CartFooter, CartHeader, CartWallet, CartAddOns, CartMealKit, CartDiscount } from '../cart'

const Container = styled.div`
  height: 100%;
  width: 100%;
  z-index: 8;
`

export enum CartTabs {
  MEAL_KIT = 'meal-kit',
  ADD_ONS = 'add-ons'
}

interface CartDrawerState {
  showTabBar: boolean
  tab: CartTabs
  numberOfAddons: number
}

const DEFAULT_STATE: CartDrawerState = {
  showTabBar: false,
  tab: CartTabs.MEAL_KIT,
  numberOfAddons: null,
}

export function CartDrawer(): JSX.Element {

  const config = useConfig()
  const { addToast } = useToasts()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()

  const [state, setState] = useState<CartDrawerState>({ ...DEFAULT_STATE })
  const menu = cartData?.currentUser?.activeMenu
  const cartStatus = cartData?.currentUser?.checkoutStatus
  const hasMealKits = cartStatus?.hasConfiguredBox
  const hasAddOns = cartStatus?.hasOnDemandProducts
  const numberOfAddons = cartData?.currentUser?.activeCart?.totalNonSubscriptionItems
  const isDrawerOpen = cartData?.currentUser?.activeCart?.open

  // const getTotalWineInCart = () => {
  //   let totalWine = 0

  //   for (const cartItem of cartData?.currentUser?.activeCart?.cartItems || []) {
  //     if (cartItem.product.__typename === 'Wine') {
  //       totalWine += cartItem.quantity
  //     }
  //   }

  //   return totalWine
  // }

  // const numberOfWinesInCart = getTotalWineInCart()

  const _handleClose = (): void => {
    CartPlugin.shared().close()
  }

  const _handleOnTabChange = (tab: CartTabs): void => {
    setState((prevState) => update(prevState, {
      tab: { $set: tab },
    }))
  }

  const $header = <CartHeader
    tab={state.tab}
    deliveryDate={menu?.deliveryDate}
    billingDate={menu?.billingDate}
    showTabBar={state.showTabBar}
    onClose={_handleClose}
    onTabChange={_handleOnTabChange} />

  const $footer = <CartFooter />

  useEffect(() => {
    if (!cartLoading) {
      const showTabBar = hasAddOns ? cartStatus?.hasActiveSubscription || hasMealKits : false
      let addonsHasChanged = false
      if (numberOfAddons !== state.numberOfAddons && state.numberOfAddons) {
        addonsHasChanged = true
      }

      let tab = (checkoutData?.checkout?.type === CheckoutTypeEnum.SUBSCRIPTION || hasMealKits || cartStatus?.hasActiveSubscription) && (!addonsHasChanged || (addonsHasChanged && (!isDrawerOpen || !numberOfAddons))) ? CartTabs.MEAL_KIT : CartTabs.ADD_ONS

      if (tab === CartTabs.ADD_ONS && state.tab === CartTabs.ADD_ONS && hasAddOns) {
        tab = CartTabs.ADD_ONS
      }
      setState((prevState) => update(prevState, {
        tab: { $set: tab },
        showTabBar: { $set: showTabBar },
        numberOfAddons: { $set: numberOfAddons },
      }))

      // if (numberOfWinesInCart === 3) {
      //   addToast('Congratulations, 25% discount applied!', {
      //     appearance: 'success',
      //     autoDismiss: true,
      //   })
      // }
    }

  }, [checkoutData?.checkout?.type, cartData?.currentUser?.activeCart?.totalItems, cartStatus?.hasActiveSubscription])

  return (
    <Drawer
      open={isDrawerOpen}
      allowClose={!cartLoading}
      header={$header}
      footer={$footer}>
      <Container>
        <Choose>
          <When condition={cartLoading}>
            <SectionLoading />
          </When>
          <When condition={state.tab === CartTabs.MEAL_KIT}>
            <CartMealKit />
          </When>
          <Otherwise>
            <CartAddOns />
          </Otherwise>
        </Choose>
        <CartWallet />
        <CartBreakdown />
        <CartDiscount />
      </Container>
    </Drawer>
  )
}
