import React, { ReactElement } from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ResponsivePXValue('width', 'CACL(100% - 14px)')}
  ${ResponsivePXValue('padding', '7px 0')}

  .table-text {
    margin: 0;
    padding: 0;
  }
`

export interface SimpleTableRowProps {
  title: string
  description: string
  className?: string
}

export function SimpleTableRow({ title, description, className }: SimpleTableRowProps): JSX.Element {

  return (
    <Row className={className}>
      <Paragraph className='table-text' variant='p2'>{title}</Paragraph>
      <Paragraph className='table-text'>{description}</Paragraph>
    </Row>
  )

}

const Table = styled.div`
  width: 100%;
`

export interface SimpleTableProps {
  children: ReactElement<SimpleTableRowProps> | Array<ReactElement<SimpleTableRowProps>>
  className?: string
}

export function SimpleTable({ children, className }: SimpleTableProps): JSX.Element {

  return (
    <Table className={className}>
      {children}
    </Table>
  )

}
