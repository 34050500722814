import React from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{className: string}>`
  flex-grow: 1;
  position: relative;
  ${(props): CSS => {

    if (props.className==='no-margin' || props.className ==='mini-serving-size') {
      return `
        margin: 0px;
        padding; 0px;
      `
    } else {
      return `
        ${ResponsivePXValue('margin', '16px 0 0')}
      `
    }
  }}
`

const TopContainer = styled.div<{$isMiniCard: boolean , className: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props): CSS => {

    if (props.$isMiniCard || props.className === 'no-margin') {
      return `
        margin: 0px;
        padding; 0px;
      `
    } else {
      return `
        ${ResponsivePXValue('margin', '0 0 8px')}

      `
    }
  }}
`

const Label = styled.div`
  font-family: Gordita;
  font-weight: 700;
  ${ResponsivePXValue('font-size', '10px')}
`

const Optional = styled.div`
  font-family: Gordita;
  font-weight: 500;
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin', '8px 0')}
  color: ${(props): string => props.theme.colors.slates.bitter};
`

const Error = styled.div`
  font-family: Gordita;
  font-weight: 500;
  color: ${(props): string => props.theme.colors.misc.error};
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin', '8px 4px 0')}
  ${ResponsivePXValue('height', '16px')}
`

export interface InputWrapperProps {
  label?: string
  error?: string
  showLabel?: boolean
  showOptional?: boolean
  required?: boolean
  children: JSX.Element
  className?: string
  isMiniCard?: boolean
}

export function InputWrapper({ label, error = '', showLabel = true, showOptional = true, required = false, children, className, isMiniCard }: InputWrapperProps): JSX.Element {

  return (
    <Container
      className={className} >
      <If condition={showLabel || !required}>
        <TopContainer className='optional-container' $isMiniCard={isMiniCard}>
          <If condition={showLabel && !!label}>
            <Label className='input-label'>
              {label}
            </Label>
          </If>
          <If condition={!required && showOptional}>
            <Optional>
              Optional
            </Optional>
          </If>
        </TopContainer>
      </If>
      {children}
      <If condition={!!error}>
        <Error>
          {error}
        </Error>
      </If>
    </Container>
  )

}
