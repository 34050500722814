import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useLocation } from 'react-router-dom'
import styled, { CSS, css } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Link, Paragraph, Spacer, Rule } from '@atoms/index'
import { DisabledEffect, ResponsiveProperty, theme } from '@client/components/Theme'
import { FilterSlider, Heading, Icon, IconEnum, ResponsivePXValue, lunchCategoryId, lunchPremiumId, lunchSaverId } from '@components/index'
import { useGetAppQuery } from '@hooks/api'
import { FilterSectionProps, FilterSection, FilterPill, FilterItemProps } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const FullScreenFilter = css`
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  z-index: 30;
  height: 100%;
`
const StickyFilter = css<{ $isNativeApplication: boolean }>`
  position: sticky;
  z-index: 12;

  ${ResponsivePXValue('top', { mobile: '54px' })}

  ${(props): string => {
    if (props?.$isNativeApplication) {
      return `${ResponsivePXValue('top', { mobile: '0' })}`

    }
  }}
  .filter-slider{
    padding-bottom: 0;
  }
`
const Container = styled.div < { $showFilter: boolean, $showSlider: boolean, $isFilterApplied: boolean, $isLoading: boolean, $isNativeApplication: boolean }>`
  display: flex;
  flex-direction: column;  
  height: fit-content;
  z-index: 9;
  background: ${(props): string => props.theme.colors.whites.pureWhite};

  ${(props): CSS => props.$showSlider ? StickyFilter : undefined};
  ${(props): CSS => props.$isLoading ? DisabledEffect : undefined};


  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '272px' })}
  ${ResponsivePXValue('padding', { mobile: '16px 0', desktop: '12px 0' })}
  ${ResponsiveProperty('border-radius', { desktop: '4px' })}
  ${ResponsiveProperty('overflow', { desktop: 'hidden' })}
  ${ResponsivePXValue('margin-bottom', { desktop: '24px' })}

  .filter-pill {
    ${ResponsivePXValue('margin-bottom', '12px')}
  }

  .check-box {
   margin: 0;
  }

  .applied-filter-spacer {
    background: ${(props): string => props.theme.colors.whites.wildSand};
  }

  ${(props): CSS => {
    if (props?.$showFilter) {
      return `
        background: ${props.theme.colors.greys.cultured};
        ${FullScreenFilter}
        ${props.$isNativeApplication ? ResponsivePXValue('top', { mobile: '56px' }) : ResponsivePXValue('top', { mobile: '0' })}

        
        .applied-filter-container {
           ${props.$isFilterApplied ? ResponsivePXValue('margin-bottom', { mobile: '6px' }) : null}
           ${props.$isFilterApplied ? ResponsivePXValue('padding-top', { mobile: '12px' }) : ResponsivePXValue('padding-top', { mobile: '44px' })}
           ${ResponsivePXValue('padding-bottom', { mobile: '0' })}
        }
      `
    }
  }}
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  .filter-section {
   background: ${(props): string => props.theme.colors.whites.pureWhite};
  }
`
const AppliedFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;

  background: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', '16px')}
`
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`
const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${ResponsivePXValue('width', '100px')}
  ${ResponsivePXValue('height', '20px')}
`

const FilterHeader = styled.div <{ $isNativeApplication: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9;
  background: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('padding', '12px 16px')}
`
const FilterScrollContainer = styled.div`
  position: relative;
  ${ResponsivePXValue('top', { mobile: '54px' })}
  ${ResponsivePXValue('max-height', { mobile: '85%' })}
  overflow-y: scroll;

`
export type SelectedFilters = { [k: string]: string[] }
export interface FilterProps {
  sections: FilterSectionProps[]
  onClear?: () => void
  onChange?: (selectedFilters: SelectedFilters) => void
  className?: string
  isLoading?: boolean
  filters?: SelectedFilters
  slides?: JSX.Element
  displayShowMore?: boolean
}

interface FilterState {
  selectedFilters: SelectedFilters
  showFilter: boolean
  reset: boolean
  changeReset: boolean
}

const DEFAULT_STATE: FilterState = {
  selectedFilters: {},
  showFilter: false,
  reset: false,
  changeReset: false,
}

export function Filter(props: FilterProps): JSX.Element {

  const {
    filters,
    sections,
    onClear,
    onChange,
    className,
    isLoading = false,
    slides,
    displayShowMore = false,
  } = props

  const isInternal = Boolean(!filters || !onChange || !onClear)
  const [state, setState] = useState<FilterState>({ ...DEFAULT_STATE })
  const location = useLocation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _getSelectedFilterHasLunch = (selectedFilters?: SelectedFilters): boolean => {

    const filtersToVerify = selectedFilters || (isInternal ? state.selectedFilters : filters)

    let hasLunch = false

    for (const [_, filters] of Object.entries(filtersToVerify)) {
      hasLunch = filters.includes(lunchSaverId) || filters.includes(lunchPremiumId)
      if (hasLunch) {
        break
      }
    }
    return hasLunch
  }

  const _getLunchFilteredSelectedValues = (selectedValues: string[], selectedValue: string): string[] => {

    const isLunch = selectedValue === lunchSaverId || selectedValue === lunchPremiumId || selectedValue === lunchCategoryId

    if (isLunch) {
      return selectedValues.filter(value => value !== lunchSaverId && value !== lunchPremiumId && value !== lunchCategoryId && value !== selectedValue)
    }
    return selectedValues.filter(value => value !== selectedValue)
  }

  const _isDirectUrlFilter = (pillTitle: string): boolean => {
    const urlHasUndefinedParams = location.pathname.includes('undefined')
    const urlHasFilter = location.search.includes(pillTitle)
    return urlHasUndefinedParams && urlHasFilter
  }

  const _getTitleFromValue = (sectionId: string, title: string): string => {
    const allItems: FilterItemProps[] = []
    sections?.forEach(section => {
      if (section.filterKey === sectionId) {
        allItems.push(...section.items)
      }
    })
    const item = allItems.find(item => item.id === title)

    const isLunch = title === lunchCategoryId

    if (isLunch) {
      return '*New Lunch'
    }

    return item?.title ? item.title : title
  }

  const _getSelectedFilter = (): JSX.Element[] => {
    const selectedFilters: JSX.Element[] = []
    const items = isInternal ? Object.entries(state.selectedFilters) : Object.entries(filters)

    for (const [sectionId, filters] of items) {

      const hasLunch = filters.includes(lunchSaverId) || filters.includes(lunchPremiumId)

      const updatedFilters = [...filters]

      if (hasLunch) {
        // remove lunch saver and lunch premium as it will have duplicate Lunch pill but it should not clear other filter
        updatedFilters.splice(updatedFilters.indexOf(lunchSaverId), 1)
        updatedFilters.splice(updatedFilters.indexOf(lunchPremiumId), 1)
        updatedFilters.push(lunchCategoryId)
      }

      updatedFilters.forEach((title) => {

        const pillTitle = _getTitleFromValue(sectionId, title)

        if (_isDirectUrlFilter(pillTitle)) return

        selectedFilters.push(
          <FilterPill
            id={title}
            key={title}
            title={pillTitle}
            sectionId={sectionId}
            onRemoveFilter={_removeFilter}
            backgroundColor={theme.colors.slates.bitter}
            closable
            className='filter-pill' />,
        )
      })
    }
    return selectedFilters
  }

  const _isFilterApplied = (selectedFilters: SelectedFilters): boolean => {

    const selectedFilter = _getSelectedFilter()

    if (!selectedFilter || Object.keys(selectedFilter).length === 0) {
      return false
    }

    let isFilterApplied = false

    for (const [_, filters] of Object.entries(selectedFilters)) {
      if (filters.length > 0) {
        isFilterApplied = true
        break
      }
    }
    return isFilterApplied
  }

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isFilterApplied = isInternal ? _isFilterApplied(state.selectedFilters) : _isFilterApplied(filters)

  const _handleClearAll = (): void => {

    const selectedFilter: SelectedFilters = {}

    const items = isInternal ? Object.entries(state.selectedFilters) : Object.entries(filters)
    // eslint-disable-next-line
    for (const [sectionId, _] of items) {
      selectedFilter[sectionId] = []
    }

    if (isInternal) {
      setState(update(state, {
        selectedFilters: { $set: selectedFilter },
        reset: { $set: true },
      }))
    } else {
      setState(update(state, {
        reset: { $set: true },
      }))
    }

    if (onClear) {
      onClear()
    }
  }

  const _getSelectedFilterWithLunchCategoryId = (selectedFilters: SelectedFilters, hasLunch?: boolean): SelectedFilters => {

    const hasLunchCategories = hasLunch || _getSelectedFilterHasLunch(selectedFilters)

    if (hasLunchCategories) {
      const updatedSelectedFilters = { ...selectedFilters }
      for (const [_, filters] of Object.entries(updatedSelectedFilters)) {
        const lunchSaverIndex = filters.indexOf(lunchSaverId)
        const lunchPremiumIndex = filters.indexOf(lunchPremiumId)

        // Remove the element with the higher index first
        if (lunchSaverIndex > lunchPremiumIndex) {
          filters.splice(lunchSaverIndex, 1)
          filters.splice(lunchPremiumIndex, 1)
        } else {
          filters.splice(lunchPremiumIndex, 1)
          filters.splice(lunchSaverIndex, 1)
        }

        filters.push(lunchCategoryId)
      }
      return updatedSelectedFilters
    }
  }

  const _setInitialSelection = (): void => {
    const selectedFilters: SelectedFilters = {}
    sections.forEach(section => {
      const selectedSectionFilter = section.items.filter(item => item.selected).map(item => item.title)
      selectedFilters[section.id] = selectedSectionFilter
    })

    const updatedSelectedFilters = _getSelectedFilterWithLunchCategoryId(selectedFilters)

    if (isInternal) {
      setState(update(state, { selectedFilters: { $set: updatedSelectedFilters } }))
    }
  }

  const _handleChange = (sectionId: string, selectedValues: string[]): void => {

    const hasLunchCategory = selectedValues.includes(lunchCategoryId)
    const hasPremiumOrSaverCategory = selectedValues.includes(lunchSaverId) || selectedValues.includes(lunchPremiumId)

    if (hasLunchCategory) {
      // remove lunchCategoryId from selectedValues and replace it with lunchSaverId and lunchPremiumId
      const lunchIndex = selectedValues.indexOf(lunchCategoryId)
      selectedValues.splice(lunchIndex, 1)
      selectedValues.push(lunchSaverId, lunchPremiumId)
    }

    if (hasLunchCategory && hasPremiumOrSaverCategory) {
      // user has unselected lunch category
      selectedValues = selectedValues.filter(value => value !== lunchSaverId && value !== lunchPremiumId)
    }

    const selectedFilter = isInternal
      ? {
        ...state.selectedFilters,
        [sectionId]: selectedValues,
      }
      : {
        ...filters,
        [sectionId]: selectedValues,
      }

    const changeReset = _isFilterApplied(selectedFilter) // user has manually unselected all options

    if (isInternal) {
      setState(update(state, {
        selectedFilters: { $set: selectedFilter },
        reset: { $set: false },
        changeReset: { $set: !changeReset }, // inverting changeReset as _isFilterApplied will return false after change
      }))
    } else {
      setState(update(state, {
        reset: { $set: false },
        changeReset: { $set: !changeReset }, // inverting changeReset as _isFilterApplied will return false after change
      }))
    }

    if (onChange) {
      onChange(selectedFilter)
    }
  }

  const _handleFilterClick = (): void => {
    setState(update(state, {
      showFilter: { $set: true },
    }))
  }

  const _handleFilterClose = (): void => {
    setState(update(state, {
      showFilter: { $set: false },
    }))
  }

  const _removeFilter = (sectionId: string, selectedValue: string) => {

    const filteredSelectedValue = isInternal
      ? _getLunchFilteredSelectedValues(state.selectedFilters[sectionId], selectedValue)
      : _getLunchFilteredSelectedValues(filters[sectionId], selectedValue)

    const selectedFilter = isInternal
      ? {
        ...state.selectedFilters,
        [sectionId]: filteredSelectedValue,
      }
      : {
        ...filters,
        [sectionId]: filteredSelectedValue,
      }

    if (isInternal) {
      setState(update(state, {
        selectedFilters: { $set: selectedFilter },
      }))
    }

    if (onChange) {
      onChange(selectedFilter)
    }

  }

  useEffect(() => {
    if (sections.length > 0) {
      _setInitialSelection()
    }
  }, [])

  const showSlider = !isDesktop && !state.showFilter
  const containerClassName = state.showFilter ? `${className} showing-filter` : className

  let section: FilterSectionProps

  return (
    <Container className={containerClassName} $showFilter={state.showFilter} $showSlider={showSlider} $isFilterApplied={isFilterApplied} $isLoading={isLoading} $isNativeApplication={isNativeApplication}>
      <If condition={showSlider}>
        <FilterSlider
          className='filter-slider'
          sections={sections}
          isFilterApplied={isFilterApplied}
          selectedFilters={isInternal ? state.selectedFilters : filters}
          reset={state.reset}
          changeReset={state.changeReset}
          onChange={_handleChange}
          onClick={_handleFilterClick}
          slides={slides} />
      </If>
      <If condition={state.showFilter}>
        <FilterHeader $isNativeApplication={isNativeApplication}>
          <Heading variant='h2'> Filters</Heading>
          <Choose>
            <When condition={!isNativeApplication}>
              <IconContainer onClick={_handleFilterClose}>
                <Icon icon={IconEnum.CLOSE_OUTLINE} color={theme.colors.greys.boulder} />
              </IconContainer>
            </When>
            <Otherwise>
              <CloseContainer onClick={_handleFilterClose}>
                <Paragraph bold variant='p3' transform='uppercase' color={theme.colors.greys.mineshaft}>Save and Close</Paragraph>
              </CloseContainer>
            </Otherwise>
          </Choose>
        </FilterHeader>
      </If>
      <If condition={isDesktop || state.showFilter}>
        <FilterScrollContainer>
          <If condition={isFilterApplied}>
            <AppliedFilterContainer className='applied-filter-container'>
              <TextContainer>
                <Paragraph bold variant='p1'> Applied filters </Paragraph>
                <Link variant='l2' decoration='underline' onClick={_handleClearAll}> Clear All </Link>
              </TextContainer>
              <Spacer mobile='4px' desktop='8px' />
              <Rule color='slate' />
              <Spacer universal='12px' />
              {_getSelectedFilter()}
            </AppliedFilterContainer>
            <Spacer mobile='8px' desktop='24px' className='applied-filter-spacer' />
          </If>
          <SectionContainer className='filter-section-container'>
            <For each='section' of={sections} index='index'>
              <FilterSection
                {...section}
                className='filter-section'
                selectedFilters={isInternal ? state.selectedFilters : filters}
                reset={state.reset}
                key={section.id}
                displayShowMore={displayShowMore}
                onChange={_handleChange} />
              <Spacer mobile='4px' />
            </For>
          </SectionContainer>
        </FilterScrollContainer>
      </If>
    </Container>
  )
}
