import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftMealMyShopStore')

const CraftMealMyShopStore = loadable(() => import(/* webpackChunkName: "craft-meal-my-shop-store" */'@pages/CraftMealMyShopStore'), {
  resolveComponent: (components) => components.CraftMealMyShopStore,
})

export function CraftMealMyShopStoreAsync(): JSX.Element {

  return <CraftMealMyShopStore fallback={<div>Loading...</div>} />

}
