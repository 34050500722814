import React, { ReactElement } from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  :nth-of-type(odd) {
   background-color: ${(props): string => props.theme.colors.whites.alabaster};
  }

  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 10px)', tablet: 'CALC(100% - 12px)', desktop: 'CALC(100% - 14px)' })}
  ${ResponsivePXValue('padding', { mobile: '5px 0', tablet: '7px 0', desktop: '8px 0' })}

  .table-text {
    margin: 0;
    padding: 0;
  }
`

export interface AlternatingTableRowProps {
  title: string
  description: string
  className?: string
}

export function AlternatingTableRow({ title, description, className }: AlternatingTableRowProps): JSX.Element {

  return (
    <Row className={className}>
      <Paragraph className='table-text' variant='p2'>{title}</Paragraph>
      <Paragraph className='table-text'>{description}</Paragraph>
    </Row>
  )

}

const Table = styled.div`
  width: 100%;
`

export interface AlternatingTableProps {
  children: ReactElement<AlternatingTableRowProps> | Array<ReactElement<AlternatingTableRowProps>>
  className?: string
}

export function AlternatingTable({ children, className }: AlternatingTableProps): JSX.Element {

  return (
    <Table className={className}>
      {children}
    </Table>
  )

}
