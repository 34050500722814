import React from 'react'

import RCForm from 'rc-field-form'
import { FormProps as RCFormProps } from 'rc-field-form/es/Form'
export * from 'rc-field-form/lib/interface'

export type FormValue = string | number | boolean | null

export interface InputProps {
  value?: FormValue | FormValue[]
  label?: string
  showLabel?: boolean
  showOptional?: boolean
  name?: string
  error?: string
  required?: boolean
  loading?: boolean
  disabled?: boolean
  onChange?: (value?: FormValue | FormValue[]) => void
}

export const useForm = RCForm.useForm

export interface FormContextProperties {
  loading: boolean
  disabled: boolean
}

export const FormContext = React.createContext({ loading: false, disabled: false })

export interface FormProps extends RCFormProps {
  loading?: boolean
  disabled?: boolean
  children: React.ReactNode
}

export function Form({ loading, disabled, children, ...rest }: FormProps): JSX.Element {

  return (
    <RCForm {...rest}>
      <FormContext.Provider value={{ loading, disabled }}>
        {children}
      </FormContext.Provider>
    </RCForm>
  )

}
