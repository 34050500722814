import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { styled } from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Modal } from '@client/components/molecules'
import { useGetModalsQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'

import { CellphoneVerificationForm, SignupForm, EnterNewCellphoneNumberForm } from '../forms'

enum SignUpDisplayStepEnum {
  CREDENTIALS = 'CREDENTIALS',
  ENTER_NUMBER = 'ENTER_NUMBER',
  VERIFY_NUMBER = 'VERIFY_NUMBER',
  ERROR = 'ERROR',
}

interface SignupModalState {
  displayStep: SignUpDisplayStepEnum
  previousStep: SignUpDisplayStepEnum
}

const DEFAULT_STATE: SignupModalState = {
  displayStep: SignUpDisplayStepEnum.CREDENTIALS,
  previousStep: SignUpDisplayStepEnum.CREDENTIALS,
}

const OverlapContainer = styled.div`
  z-index: 100;
`
export function SignupModal(): JSX.Element {

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<SignupModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const switchStep = (displayStep: SignUpDisplayStepEnum): void => {
    const currentStep = state.displayStep
    setState((prevState) => update(prevState, {
      displayStep: { $set: displayStep },
      previousStep: { $set: currentStep },
    }))
  }

  const _handleClose = (): void => {
    CheckoutPlugin.shared().reset()
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.SIGN_UP)
  }

  const _handleSuccess = async (): Promise<void> => {
    CheckoutPlugin.shared().didLogIn(client)
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.SIGN_UP)
  }

  const _handleLogIn = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleSignedUp = (hasCellphoneNumber: boolean, cellphoneIsVerified: boolean): void => {
    if (!cellphoneIsVerified) {
      if (!hasCellphoneNumber) {
        switchStep(SignUpDisplayStepEnum.ENTER_NUMBER)
      } else {
        switchStep(SignUpDisplayStepEnum.VERIFY_NUMBER)
      }
    } else {
      _handleSuccess()
    }
  }

  useEffect(() => {
    if (data?.modals?.signUp) {
      switchStep(SignUpDisplayStepEnum.CREDENTIALS)
    }
  }, [data?.modals?.signUp])

  let title = 'Sign up'
  switch (state.displayStep) {
    case SignUpDisplayStepEnum.ENTER_NUMBER:
      title = 'Verify your account'
      break
    case SignUpDisplayStepEnum.VERIFY_NUMBER:
      title = 'Verify your account'
      break
  }

  const fullScreen = !(state.displayStep === SignUpDisplayStepEnum.ENTER_NUMBER || state.displayStep === SignUpDisplayStepEnum.VERIFY_NUMBER)

  return (
    <OverlapContainer>
      <Modal
        open={data?.modals?.signUp}
        title={title}
        titleAlign='left'
        fullscreen={fullScreen}
        showCloseButton
        onClose={_handleClose}>
        <Choose>
          <When condition={state.displayStep === SignUpDisplayStepEnum.ENTER_NUMBER}>
            <ModalFormContainer>
              <EnterNewCellphoneNumberForm
                onNumberChanged={() => switchStep(SignUpDisplayStepEnum.VERIFY_NUMBER)} />
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === SignUpDisplayStepEnum.VERIFY_NUMBER}>
            <ModalFormContainer>
              <CellphoneVerificationForm
                onSuccess={_handleSuccess}
                onChangeNumber={() => switchStep(SignUpDisplayStepEnum.ENTER_NUMBER)} />
            </ModalFormContainer>
          </When>
          <Otherwise>
            <ModalFormContainer>
              <SignupForm
                onSignedUp={_handleSignedUp}
                onLogIn={_handleLogIn} />
            </ModalFormContainer>
          </Otherwise>
        </Choose>
      </Modal>
    </OverlapContainer>

  )

}
