import React, { useEffect } from 'react'

import { useParams } from 'react-router'
import styled from 'styled-components'

import { Heading, Spacer } from '@atoms/index'
import { WineListFragment, useGetAllWinesLazyQuery, useGetOneVineyardQuery } from '@hooks/api'
import { SectionLoading } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { WineCard } from '../molecules/stores/WineCard'
import { ResponsivePXValue, ResponsiveProperty } from '../Theme'

const seo = {
  name: 'UCOOK Wines',
  title: 'Buy Wine Online in South Africa | UCOOK Wine',
  meta: [{
    name: 'description',
    content: 'Our wines are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Wine Online,Wine Online,Order Wine Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;

  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'flex-start' })}
`
const Grid = styled.div`
  display: flex;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}
  ${ResponsivePXValue('gap', { mobile: '16px', desktop: '16px' })}

  .wine-filter {
    ${ResponsivePXValue('padding', { mobile: '8px 0 8px 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .wine-product-card {
    margin: 0;
  }
`

export function VineyardStore(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()

  const { data: vineyardData, loading: vineyardLoading } = useGetOneVineyardQuery({
    variables: {
      selectorId: slug,
    },
  })

  const vineyard = vineyardData?.vineyard

  const wineQueryVariables = {
    filters: {
      vineyards: [vineyard?.id],
    },
  }

  const [fetchFilteredWines, { data: winesData, loading: winesLoading }] = useGetAllWinesLazyQuery()

  useEffect(() => {
    if (vineyardData?.vineyard) {
      fetchFilteredWines({ variables: wineQueryVariables })
    }
  }, [vineyardData?.vineyard])

  const wines = winesData?.wines?.list || []
  let wine: WineListFragment

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={winesLoading && !wines.length}>
          <SectionLoading height='100vh' />
        </When>
        <Otherwise>
          <TopContainer>
            <RowContainer>
              <HeadingContainer>
                <Heading variant='h1'>{vineyard?.name}</Heading>
                <Spacer desktop='8px' variant='horizontal' />
              </HeadingContainer>
            </RowContainer>
            <Spacer desktop='24px' />
          </TopContainer>
          <Grid>
            <DishContainer id='productGrid'>
              <For each='wine' of={wines}>
                <WineCard
                  className='wine-product-card'
                  key={wine.id}
                  loading={vineyardLoading}
                  wine={wine} />
              </For>
            </DishContainer>
          </Grid>
          <Spacer universal='24px' />
        </Otherwise>
      </Choose>
    </Container>
  )
}
