import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { Heading, IconEnum, Paragraph, Link, Rule, Button } from '@atoms/index'
import { NotFound, PageSectionPadding, ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@client/components/Theme'
import { scrollToSelector } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { MealKitCategoryFragment, useGetAppQuery, useUserDetailsQuery, useUserCartQuery, UserSubscriptionFragment, usePlansPageQuery, UserCartDocument, UserDetailsDocument, useUpdateUserSubscription, useActivateSubscription, useResetUserMenuMutation, RegisteredUserDetailsFragment, UserDetailsFragment, useGetAllMealKitDefaultPricingQuery, useGetAllMealKitSubscriptionFees } from '@hooks/api'
import { Accordion, FieldData, Form, MealKitOnMenuThisWeek, RadioButton, RadioButtonOption, RadioButtonProps, SectionLoading, Tab, useForm } from '@molecules/index'
import { SimpleSection, SimpleSectionProps } from '@molecules/tables/SimpleSection'
import { NumberOfPortionsEnum, DeviceTypeEnum, MealKitServingSizeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled.div`
  flex: 1;
  
  ${ResponsivePXValue('max-width', { mobile: '100vw', tablet: '100vw', desktop: '100%' })}

  .plans-text {
    ${ZeroSpace}

    color: ${(props): string => props.theme.colors.greys.mineshaft};
  }

  .plans-desc{
    text-wrap: balance;
    ${ResponsivePXValue('min-width', { mobile: '70vw', tablet: 'initial', desktop: 'initial' })}
  }

  .price {
     ${ResponsivePXValue('padding-top', { mobile: '4px', tablet: '4px', desktop: '8px' })}
     ${ResponsivePXValue('padding-right', '4px')}
  }

  .free-delivery {
    font-style: italic;

     color: ${(props): string => props.theme.colors.greys.tundora};
  }

   .plan-copyright {
    ${ResponsivePXValue('margin-bottom', { desktop: '85px' })}
  }

`

const ContentContainer = styled.div <{ $direction?: string }>`
  display: flex;

  flex-direction: ${(props): string => props?.$direction ? props.$direction : 'row'};

  ${ResponsiveProperty('justify-content', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('max-width', { mobile: '100vw', tablet: '100vw', desktop: '1136px' })}

  &.header-section-container {
   align-items: center;

   .description {
     ${ZeroSpace}
     ${ResponsivePXValue('max-width', { desktop: '80%' })}
   }
 }
 
 &.simple-section-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;

    ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}

    .simple-section {
      ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
       ${ResponsivePXValue('margin-right', { desktop: '24px' })}
       ${ResponsivePXValue('margin-bottom', { mobile: '16px' })}

      .text-container {
         ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}
        

        .title {
           ${ResponsivePXValue('margin-top', { mobile: '16px' })}
        }
        .description {
           ${ResponsiveProperty('text-align', { mobile: 'center' })}
        }
      }
    }
 }

 &.faq-container {
  width: 100%;
 }

   .people-radio-title {
      padding-bottom: 0;

      ${ResponsivePXValue('text-align', { mobile: 'center' })}
    }
   
    .dishes-radio-title {
      padding-bottom: 0;

      ${ResponsivePXValue('text-align', { mobile: 'center' })}
    }
   
    .category-radio-title {
     ${ResponsivePXValue('padding-top', { tablet: '28PX', desktop: '32px' })};
     ${ResponsivePXValue('padding-bottom', { mobile: '16px', tablet: '16PX', desktop: '16px' })}
    }

  .radio-button-wrapper {
    margin-top: 0;
  }

  .radio-button-container-divider {
    ${ResponsivePXValue('margin-top', { mobile: '24px' })};
    ${ResponsivePXValue('margin-bottom', { mobile: '24px' })};
  }

`

const SectionContainer = styled.div<{ $background: string }>`
 display: flex;
 flex-direction: column;
 align-items: center;

 ${PageSectionPadding}

 ${ResponsivePXValue('padding-top', { mobile: '40px', tablet: '42PX', desktop: '56px' })}
 ${ResponsivePXValue('padding-bottom', { mobile: '40px', tablet: '42PX', desktop: '56px' })}

 background: ${(props): string => props.$background};

  .people-radio {
   flex: 1;
  }
  .dishes-radio {
    flex: 1;

     ${ResponsivePXValue('margin-left', { desktop: '48px' })}
     ${ResponsivePXValue('max-width', { desktop: '500px' })}
  }

  .category-radio {
    .container {
      justify-content: center;
    }
    .item-container {
      ${ResponsiveProperty('flex', { mobile: '1', tablet: '1', desktop: '0.33' })}
      ${ResponsivePXValue('width', { desktop: '368px' })}
      ${ResponsivePXValue('min-width', { desktop: '320px' })}
      ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '8px', desktop: '16px' })}
    }
    .item-label {
       ${ResponsivePXValue('margin', { mobile: '0 12px 0 4px', tablet: '0 0 0 12px', desktop: '0 0 0 24px' })}
    }
  }

  .item-container {
    ${ResponsivePXValue('margin', { mobile: '0 4px 4px 0', tablet: '0 8px 8px 0', desktop: '0 12px 16px 0' })}
  }

  .accordion {
    border-radius: 0;
  }

  .tab {
    background: ${(props): string => props.theme.colors.misc.transparent};

    ${ResponsivePXValue('padding', { mobile: '12px 0', tablet: '12px 0', desktop: '24px 0' })}
  }

  .tab-content {
    ${ZeroSpace}
  }

`
const TabContent = styled.div`
   padding: 0;

   .answer {
     ${ZeroSpace}
   }

   .learn-more {
     display: inline-block;

     ${ResponsivePXValue('margin-top', '16px')}
   }
`

const NotFoundMessage = styled.div`
  ${NotFound}
`

const PlansFooter = styled.div<{ $isNativeApplication: boolean }>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  ${ResponsiveProperty('align-items', { tablet: 'center', desktop: 'center' })}
  ${(props): string => props.$isNativeApplication ? ResponsivePXValue('bottom', '64px') : ResponsivePXValue('bottom', '0px')}
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0px -8px 12px rgba(148, 148, 148, 0.1);

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('height', { desktop: '102px' })}
  ${ResponsivePXValue('padding', { mobile: '12px 16px', tablet: '0 20px', desktop: '0 152px' })}
  
  `
const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const PlanPricing = styled.div`
  display: flex;
  align-self: center;
`
const PlanInfoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  ${ResponsiveProperty('align-items', { tablet: 'center', desktop: 'center' })}
  align-self: stretch;

  ${ResponsivePXValue('padding-right', { desktop: '48px' })};
  ${ResponsivePXValue('margin-bottom', { mobile: '12px' })};
`
const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;

   ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
   ${ResponsiveProperty('align-items', { mobile: 'center', tablet: 'flex-start', desktop: 'flex-start' })}

   .container {
     ${ResponsiveProperty('flex-wrap', { mobile: 'nowrap', tablet: 'nowrap', desktop: 'wrap' })}
   }

   .item-container {
      flex: 1;

      ${ResponsivePXValue('max-height', { mobile: '4px' })}
    }

    .plans-text {
      ${ResponsivePXValue('min-width', { desktop: '60px' })}
    }
`
const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
const RowWrap = styled.div`
  display: flex;
  align-items: center;
  `

const seo = {
  name: 'UCOOK Meal Kit Plans',
  title: 'Meal Plans with UCOOK’s Meal Kits Subscription | UCOOK',
  meta: [{
    name: 'description',
    content: 'Get started with Easy Peasy, Health Nut and Vegetarian meal options, each tailored to suit your lifestyle.',
  }, {
    name: 'keywords',
    content: 'Meal Subscription Box,Subscription Meal Kits,Dinner Subscription Box',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}

interface PageDataMealKitPlansState {
  pageLoading: boolean
  categoryId: string | null
  currentCategoryId: string | null
  numberOfMeals: number
  numberOfPortions: NumberOfPortionsEnum
  selectedPlan: MealKitCategoryFragment
  activeTab: string
}

const DEFAULT_STATE: PageDataMealKitPlansState = {
  pageLoading: false,
  categoryId: 'ed70273c-0531-47fb-8501-84887f6dd90d',
  currentCategoryId: null as string | null,
  numberOfMeals: 2,
  numberOfPortions: NumberOfPortionsEnum.SERVES_2,
  selectedPlan: null,
  activeTab: '',
}

export function MealKitPlans(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const location = useLocation()
  const [state, setState] = useState<PageDataMealKitPlansState>({ ...DEFAULT_STATE })

  const { data, loading } = usePlansPageQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [updateSubscription] = useUpdateUserSubscription()
  const [activateSubscription] = useActivateSubscription()
  const [resetUserMenu] = useResetUserMenuMutation()
  const isNativeApplication = appData.app.isNativeApplication
  const [form] = useForm()
  const plans = data?.categories?.list?.filter(category => category.canSubscribe) || []
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const isGuestUser = userDetailsData?.currentUser?.__typename === 'GuestUser'
  const hasAccount = userCartData?.currentUser?.checkoutStatus?.hasAccount
  const hasActiveSubscription = userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription
  const hasAddress = userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress
  const hasCreditCard = userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod
  const defaultPortions = appData.app.defaultPortions
  const isWindow = config.isBrowser()
  const sessionCat = isWindow ? window.sessionStorage.getItem('categoryID') : ''
  const sessionPeople = isWindow ? window.sessionStorage.getItem('peopleSelected') : ''
  const sessionMeals = isWindow ? window.sessionStorage.getItem('mealsSelected') : ''
  const { addToast } = useToasts()
  const selectedPlan = plans?.find(plan => plan.id === (state.categoryId || state.currentCategoryId))
  const { data: perDishPricingData } = useGetAllMealKitDefaultPricingQuery({
    variables: {
      limit: 40,
    },
  })
  const { data: fixedFee } = useGetAllMealKitSubscriptionFees({
    variables: {
      limit: 1,
    },
  })

  const convertNumberOfMeals = (numberOfMeals: number): string => {
    return isMobile ? `${numberOfMeals}` : `${numberOfMeals} dishes`
  }

  const convertNumberOfPortions = (numberOfPortions: NumberOfPortionsEnum): string => {
    switch (numberOfPortions) {
      case NumberOfPortionsEnum.SERVES_1:
        return isMobile ? '1' : '1 person'
      case NumberOfPortionsEnum.SERVES_2:
        return isMobile ? '2' : '2 people'
      case NumberOfPortionsEnum.SERVES_3:
        return isMobile ? '3' : '3 people'
      case NumberOfPortionsEnum.SERVES_4:
        return isMobile ? '4' : '4 people'
    }
  }

  const trackCheckout = () => {
    if (isWindow && !hasAccount) {
      window.sessionStorage.setItem('categoryID', state.categoryId)
      window.sessionStorage.setItem('peopleSelected', state.numberOfPortions)
      window.sessionStorage.setItem('mealsSelected', String(state.numberOfMeals))
    }
  }

  const _handleChangeSubscription = async (): Promise<void> => {
    try {
      setState(prevState => ({ ...prevState, pageLoading: true }))
      if (!userCartData?.currentUser?.activeMenu) {
        addToast('Could not find active menu', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: {
            category: state.categoryId,
            numberOfMeals: Number(state.numberOfMeals),
            numberOfPortions: state.numberOfPortions,
            updateMenu: true,
          },
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      const shouldActivateSubscription = !userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription && userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddressDetails && userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod
      const shouldResetMenu = !isGuestUser && !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox

      if (shouldActivateSubscription) {
        await activateSubscription({
          variables: {
            id: userCartData?.currentUser?.activeMenu?.subscription?.id,
            reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
          },
          refetchQueries: [{
            query: UserCartDocument,
          }, {
            query: UserDetailsDocument,
          }],
          awaitRefetchQueries: true,
        })

      } else if (shouldResetMenu) {
        await resetUserMenu({
          variables: {
            id: userCartData.currentUser.activeMenu.id,
          },
          refetchQueries: [{
            query: UserCartDocument,
          }],
          awaitRefetchQueries: true,
        })
      }
      trackCheckout()
      CheckoutPlugin.shared().checkout(client)
      setState(prevState => ({ ...prevState, pageLoading: false }))
    } catch (e) {
      setState(prevState => ({ ...prevState, pageLoading: false }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const getPresetPrice = (numberOfMeals:number, portions:NumberOfPortionsEnum, categoryId: string) => {

    const selectedNumberOfMeals = numberOfMeals
    const perDishPricingDataArray = perDishPricingData?.allDefaultMealKitPricing?.list

    let mealKitSelectedSize: MealKitServingSizeEnum
    if (portions === NumberOfPortionsEnum.SERVES_1) {
      mealKitSelectedSize = MealKitServingSizeEnum.SERVES_1
    } else if (portions === NumberOfPortionsEnum.SERVES_2) {
      mealKitSelectedSize = MealKitServingSizeEnum.SERVES_2
    } else if (portions === NumberOfPortionsEnum.SERVES_3) {
      mealKitSelectedSize = MealKitServingSizeEnum.SERVES_3
    } else if (portions === NumberOfPortionsEnum.SERVES_4) {
      mealKitSelectedSize = MealKitServingSizeEnum.SERVES_4
    }

    const matchingPrice = perDishPricingDataArray?.find((item: { servingSize: MealKitServingSizeEnum, category: { id: string } }) => {
      return item.servingSize === mealKitSelectedSize && item.category.id === categoryId
    })

    const subscriptionFee = fixedFee?.subscriptionFee?.list[0]?.price
    const priceBeforeFee = matchingPrice?.price * selectedNumberOfMeals
    const totalPrice = priceBeforeFee + subscriptionFee

    return 'R'+String(totalPrice)
  }

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const theme = useTheme()

  const peopleRadioProps: RadioButtonProps = {
    name: 'peopleRadio',
    options: [{
      value: NumberOfPortionsEnum.SERVES_1,
      element: <Paragraph variant='p3' className='plans-text'>{convertNumberOfPortions(NumberOfPortionsEnum.SERVES_1)}</Paragraph>,
    }, {
      value: NumberOfPortionsEnum.SERVES_2,
      element: <Paragraph variant='p3' className='plans-text'>{convertNumberOfPortions(NumberOfPortionsEnum.SERVES_2)}</Paragraph>,
    }, {
      value: NumberOfPortionsEnum.SERVES_3,
      element: <Paragraph variant='p3' className='plans-text'>{convertNumberOfPortions(NumberOfPortionsEnum.SERVES_3)}</Paragraph>,
    }, {
      value: NumberOfPortionsEnum.SERVES_4,
      element: <Paragraph variant='p3' className='plans-text'>{convertNumberOfPortions(NumberOfPortionsEnum.SERVES_4)}</Paragraph>,
    },
    ],
    variant: 'horizontal',
    error: '',
    rules: [{ required: true, message: 'Please select a value' }],
  }

  const dishesRadioProps: RadioButtonProps = {
    name: 'dishesRadio',
    options: [{
      value: 2,
      element: <Paragraph variant='p3' className='plans-text'>{`${2} dishes`}</Paragraph>,
    }, {
      value: 3,
      element: <Paragraph variant='p3' className='plans-text'>{`${3} dishes`}</Paragraph>,
    }, {
      value: 4,
      element: <Paragraph variant='p3' className='plans-text'>{`${4} dishes`}</Paragraph>,
    },
    ],
    variant: 'horizontal',
    error: '',
    rules: [{ required: true, message: 'Please select a value' }],
  }

  const getCategoryOptions = (): RadioButtonOption[] => {
    return plans.map(plan => {
      return {
        value: plan.id,
        element: (
          <>
            <Heading variant='h6'>{plan.title}</Heading>
            <Paragraph variant='p3' className='plans-text plans-desc'>{plan.description}</Paragraph>
            <RowWrap>
              <Heading variant='h2' className='plans-text price'> {getPresetPrice(state.numberOfMeals, state.numberOfPortions, plan.id)}</Heading>
              <Paragraph variant='p4' className='plans-text'>/&nbsp;week</Paragraph>
            </RowWrap >
          </>
        ),
      }
    })
  }

  const tabChange = (activeTab: string): void => {

    setState((prevState) => update(prevState, {
      activeTab: { $set: activeTab },
    }))

  }

  const categoryRadioProps: RadioButtonProps = {
    name: 'categoryRadio',
    options: getCategoryOptions(),
    variant: 'horizontal',
    error: '',
    componentTheme: 'light',
    rules: [{ required: true, message: 'Please select a value' }],
    className: 'category-radio',
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'peopleRadio') {
          setState((prevState) => update(prevState, {
            numberOfPortions: { $set: field.value },
          }))
        }
        if (name === 'dishesRadio') {
          setState((prevState) => update(prevState, {
            numberOfMeals: { $set: field.value },
          }))
        }
        if (name === 'categoryRadio') {
          setState((prevState) => update(prevState, {
            categoryId: { $set: field.value },
          }))
        }
      })
    })
  }

  const simpleSectionProps: SimpleSectionProps[] = [
    {
      title: 'Save time & money',
      description: 'No food waste, no trips to the grocery store and no more impulse buying.',
      icon: IconEnum.WASTE,
      className: 'simple-section',
    }, {
      title: 'Enjoy flexible orders',
      description: 'Swap out your default dishes, add in extras and pause whenever you like.',
      icon: IconEnum.RAND,
      className: 'simple-section',
    }, {
      title: 'Help us support local',
      description: 'Discover family suppliers and help grow their business with each order.',
      icon: IconEnum.COMMUNITY,
      className: 'simple-section',
    },

  ]

  const faqs = [
    {
      id: '111',
      question: 'How does Meal Kit work?',
      answer: 'UCOOK is a subscription-based meal-kit service. We want to put the fun and ease back into your weeknight cooking. Our expert food team collaborates with top SA chefs and food talent to produce 24 inspired recipes each week. You simply choose which dishes you’d like to cook, and we deliver recipe cards and fresh, local, and ethically sourced ingredients – in the perfect portions, eliminating food waste – straight to your door every week.',
      link: 'https://support.ucook.co.za/hc/en-us/categories/360000135277-Meal-Kit',
    },
    {
      id: '222',
      question: 'Why is Meal Kit a subscription service?',
      answer: 'Our subscription-based Meal Kit service makes it even more convenient for you to receive a box each week without having to re-enter any information. Remember that our subscription service is flexible, which means that you can pause your account at any time, and adjust how many meals you would like to receive in any given week (2, 3, or 4).',
      link: 'https://support.ucook.co.za/hc/en-us/categories/360000135277-Meal-Kit',
    },
    {
      id: '333',
      question: 'What if I want to skip a week?',
      answer: 'You can pause your Meal Kit subscription by heading to your profile and clicking on the Subscription Tab. You can toggle your subscription to Active or Paused here.',
      link: 'https://support.ucook.co.za/hc/en-us/categories/360000135277-Meal-Kit',
    },
    {
      id: '444',
      question: 'When is my Meal Kit subscription charged?',
      answer: 'Orders close at 9am on Wednesday. Once orders close, your account will be debited, if it is active. You’ll receive an order invoice via email, outlining the meals that will be delivered the following week.',
      link: 'https://support.ucook.co.za/hc/en-us/categories/360000135277-Meal-Kit',
    },
    {
      id: '555',
      question: 'Does my Meal Kit subscription auto-renew?',
      answer: 'Yes – your Meal Kit subscription will auto-renew on a weekly basis if you keep your account active. If you pause your account, your subscription will not auto-renew, unless you manually activate it again. Therefore you can plan ahead and skip any week as long as your account is paused before Wednesday at 9am.',
      link: 'https://support.ucook.co.za/hc/en-us/categories/360000135277-Meal-Kit',
    },
  ]

  useEffect(() => {
    if (subscription) {
      if (sessionCat !== null || sessionPeople !== null || sessionMeals !== null) {
        const category = sessionCat
        const numberOfPeople = sessionPeople
        const numberOfDishes = sessionMeals
        const selectedPlan = data?.categories?.list?.find((cat) => cat.id === category)
        setState((prevState) => update(prevState, {
          categoryId: { $set: category },
          numberOfPortions: { $set: numberOfPeople },
          numberOfMeals: { $set: numberOfDishes },
          selectedPlan: { $set: selectedPlan },
        }))

        sessionStorage.removeItem('categoryID')
        sessionStorage.removeItem('peopleSelected')
        sessionStorage.removeItem('mealsSelected')
      } else {
        const category = subscription.category.id
        const numberOfPeople = subscription.numberOfPortions
        const numberOfDishes = subscription.numberOfMeals
        const selectedPlan = data?.categories?.list?.find((cat) => cat.id === category)
        setState((prevState) => update(prevState, {
          categoryId: { $set: category },
          numberOfPortions: { $set: numberOfPeople },
          numberOfMeals: { $set: numberOfDishes },
          selectedPlan: { $set: selectedPlan },
        }))
      }

      form.setFieldsValue({
        peopleRadio: state.numberOfPortions,
        dishesRadio: state.numberOfMeals,
        categoryRadio: state.categoryId,
      })

    } else if (data && userDetailsData) {

      setState((prevState) => update(prevState, {
        numberOfPortions: { $set: state.numberOfPortions },
        numberOfMeals: { $set: state.numberOfMeals },
        categoryId: { $set: state.categoryId },
      }))

      form.setFieldsValue({
        peopleRadio: state.numberOfPortions,
        dishesRadio: state.numberOfMeals,
        categoryRadio: state.categoryId,
      })
    }
  }, [subscription !== undefined, loading, userDetailsLoading])

  useEffect(() => {
    if (userDetailsData) {
      setState((prevState) => update(prevState, {
        currentCategoryId: { $set: userCartData?.currentUser?.activeMenu?.subscription?.category?.id },
        numberOfPortions: { $set: defaultPortions },

      }))
    }
  }, [userDetailsLoading])

  useEffect(() => {
    if (!loading && location?.hash?.length > 0) {
      setTimeout(() => {
        scrollToSelector(location.hash)
      }, 500)
    }
  }, [loading])

  let buttonTitle = 'CONTINUE'

  if (hasActiveSubscription) {
    buttonTitle = 'UPDATE YOUR PLAN'
  }

  if (!hasActiveSubscription && hasAddress && hasCreditCard) {
    buttonTitle = 'ACTIVATE PLAN'
  }

  let simpleSectionProp: SimpleSectionProps
  let faq: { id: string, question: string, answer: string, link: string }

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <Container>
        <Choose>
          <When condition={loading}>
            <SectionLoading />
          </When>
          <When condition={!data}>
            <NotFoundMessage>
              No Data Found
            </NotFoundMessage>
          </When>
          <Otherwise>
            <>
              <SectionContainer $background={theme.colors.greys.isabelline}>
                <ContentContainer className='header-section-container' $direction='column'>
                  <Heading variant='h6' align='center' className='title'>Get everything you need for dinner with no lock-in subscription</Heading>
                  <Paragraph variant='p1' align='center' className='description'>
                    Choose the category that suits your lifestyle, how many people you cook for and the number of dishes you want.
                    Then sit back, as we deliver your pre-portioned ingredients and recipes every week. You can swap dishes, change your plan or pause at any time.
                  </Paragraph>
                </ContentContainer>
              </SectionContainer>

              <SectionContainer $background={theme.colors.greys.cultured}>
                <ContentContainer>
                  <Form form={form} onFieldsChange={_handleChange}>
                    <RadioContainer>
                      <RadioButtonContainer className='people-radio'>
                        <Heading variant='h6' className='people-radio-title'>How many people are you cooking for?</Heading>
                        <RadioButton {...peopleRadioProps} />
                      </RadioButtonContainer>
                      <If condition={isMobile}>
                        <Rule color='isabelline' className='radio-button-container-divider' />
                      </If>
                      <RadioButtonContainer className='dishes-radio'>
                        <Heading variant='h6' className='dishes-radio-title'>How many dishes do you want per week?</Heading>
                        <RadioButton {...dishesRadioProps} />
                      </RadioButtonContainer >
                      <If condition={isMobile}>
                        <Rule color='isabelline' className='radio-button-container-divider' />
                      </If>
                    </RadioContainer >
                    <Heading variant='h6' align='center' className='category-radio-title'>Which category best suits your lifestyle?</Heading>
                    <RadioButton {...categoryRadioProps} className='radio-button-wrapper category-radio' />
                  </Form >
                </ContentContainer >
              </SectionContainer >

              <If condition={!isMobile}>
                <SectionContainer $background={theme.colors.greys.isabelline}>
                  <ContentContainer>
                    <MealKitOnMenuThisWeek categories={plans as MealKitCategoryFragment[]} title='On the menu this week' />
                  </ContentContainer>
                </SectionContainer>
              </If>

              <SectionContainer $background={theme.colors.greys.cultured}>
                <ContentContainer className='simple-section-container'>
                  <For each='simpleSectionProp' of={simpleSectionProps}>
                    <SimpleSection {...simpleSectionProp} key={simpleSectionProp.title} />
                  </For>
                </ContentContainer>
              </SectionContainer>

              <SectionContainer $background={theme.colors.greys.isabelline}>
                <ContentContainer $direction='column' className='faq-container' id='plans-faq'>
                  <Heading variant='h6' align='center'>FAQs</Heading>
                  <Accordion
                    activeTab={state.activeTab}
                    backgroundColor={theme.colors.misc.transparent}
                    onTabChange={tabChange}
                    className='accordion'>
                    <For each='faq' of={faqs}>
                      <Tab
                        tab={faq.id}
                        invertTheme
                        title={faq.question}
                        key={faq.id}
                        borderColor={theme.colors.greys.platinum}
                        className='tab'>
                        <TabContent>
                          <Paragraph variant='p1' className='answer'>
                            {faq.answer}
                          </Paragraph>
                          <Link
                            variant='l2'
                            decoration='underline'
                            href={faq.link}
                            className='learn-more'>
                            Learn More
                          </Link>
                        </TabContent>
                      </Tab>
                    </For>
                  </Accordion>
                </ContentContainer>
              </SectionContainer>

              <PlansFooter $isNativeApplication = {isNativeApplication}>
                <PlanInfoContainer>
                  <PlanInfo>
                    <Paragraph variant='p1' className='plans-text'>{selectedPlan?.title} | {convertNumberOfMeals(state.numberOfMeals)} for {convertNumberOfPortions(state.numberOfPortions)}</Paragraph>
                  </PlanInfo>
                  <PlanPricing>
                    <Heading variant='h5' className='plans-text'>{getPresetPrice(state.numberOfMeals, state.numberOfPortions, state.categoryId)} /{' '}week</Heading>
                  </PlanPricing >
                </PlanInfoContainer >
                <Button
                  color='green'
                  title={buttonTitle}
                  loading={loading || state.pageLoading}
                  disabled={false}
                  fullWidth={isMobile}
                  onClick={_handleChangeSubscription} />
              </PlansFooter >
            </>
          </Otherwise >
        </Choose >
      </Container >
    </>
  )

}
