import React, { useEffect, Fragment } from 'react'

import { useLocation } from 'react-router-dom'

import { useConfig } from '@contexts/ConfigProvider'

interface ScrollTopProps {
  children: JSX.Element | JSX.Element
}

export function ScrollToTop({ children }: ScrollTopProps): JSX.Element {
  const location = useLocation()
  const config = useConfig()
  useEffect(() => {
    if (config.isBrowser()) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return <Fragment>{children}</Fragment>
}
