import React from 'react'

import styled from 'styled-components'

import { Paragraph, ResponsivePXValue } from '@client/components'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    flex-shrink: 0;
    ${ResponsivePXValue('margin', 'auto 20px')}
  }
`

const Seperator = styled.div`
  flex-grow: 1;
  background: ${(props): string => props.theme.colors.whites.silver};
  ${ResponsivePXValue('height', '1px')}
`

export interface TitleSeperatorProps {
  title: string
}

export function TitleSeperator({ title }: TitleSeperatorProps): JSX.Element {

  return (
    <Container>
      <Seperator />
      <Paragraph variant='p3' align='center' className='title'>{title}</Paragraph>
      <Seperator />
    </Container>
  )

}
