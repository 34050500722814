import React from 'react'

import {
  IoArrowBack, IoArrowForward, IoArrowUp, IoArrowDown, IoChevronBackOutline, IoChevronForwardOutline,
  IoChevronUpOutline, IoChevronDownOutline, IoGridOutline, IoGrid, IoRemove, IoAdd, IoCloseOutline, IoClose, IoWineOutline, IoWine,
  IoMenuOutline, IoMenu, IoLinkOutline, IoLink, IoOptionsOutline, IoOptions, IoStarOutline, IoStar, IoCartOutline, IoCart, IoCloseCircleOutline,
  IoCloseCircle, IoEyeOffOutline, IoEyeOff, IoEye, IoWarningOutline, IoWarning, IoHeadsetOutline, IoHeart, IoEyeOutline, IoCardOutline, IoCard,
  IoInformationCircleOutline, IoInformationCircle, IoCheckmarkCircleOutline, IoCheckmarkCircle, IoPersonCircleOutline, IoPersonCircle, IoWalletOutline,
  IoWallet, IoRemoveCircleOutline, IoRemoveCircle, IoHelpCircleOutline, IoHelpCircle, IoSaveOutline, IoSave, IoShapesOutline, IoSearch,
  IoThunderstormOutline, IoTime, IoShieldCheckmark, IoShieldCheckmarkOutline, IoAddCircleOutline, IoAddCircle, IoDownloadOutline, IoDownload,
  IoSadOutline, IoHappyOutline, IoSad, IoHappy, IoReceiptOutline, IoReceipt, IoEllipsisHorizontalCircleOutline, IoEllipsisHorizontalCircle,
  IoAlertCircleOutline, IoAlertCircle, IoShareSocialOutline, IoShareSocial, IoSettingsOutline, IoSettings, IoCalendarClearOutline, IoCalendar,
  IoCalculatorOutline, IoTrashOutline, IoTrash, IoCheckmark, IoRadioButtonOffOutline, IoRadioButtonOn, IoCheckboxOutline, IoCheckbox, IoEllipseOutline,
  IoEllipse, IoLogoFacebook, IoLogoWhatsapp, IoLogoInstagram, IoLogoYoutube, IoLogoTwitter, IoLogoTiktok,
  IoCopyOutline, IoLocationOutline, IoPersonOutline, IoCallOutline, IoPricetagsSharp, IoAlarm, IoAlarmOutline, IoPerson,
} from 'react-icons/io5'
import styled from 'styled-components'

import { useHover } from '@client/hooks/UseHover'
import { Community } from '@client/icons/Community'
import { CreditCard } from '@client/icons/CreditCard'
import { Delivery } from '@client/icons/Delivery'
import { DollarSign } from '@client/icons/DollarSign'
import { Filter } from '@client/icons/Filter'
import { Fork } from '@client/icons/Fork'
import { FreeRange } from '@client/icons/FreeRange'
import { Ice } from '@client/icons/Ice'
import { Local } from '@client/icons/Local'
import { Organic } from '@client/icons/Organic'
import { Package } from '@client/icons/Package'
import { Rand } from '@client/icons/Rand'
import { Recycle } from '@client/icons/Recycle'
import { UserPlus } from '@client/icons/UserPlus'
import { Waste } from '@client/icons/Waste'

const IconContainer = styled.div`
  display: flex;

    font-weight: bold;
`

export enum IconEnum {
  ARROW_BACK = 'ARROW_BACK',
  ARROW_FORWARD = 'ARROW_FORWARD',
  ARROW_UP = 'ARROW_UP',
  ARROW_DOWN = 'ARROW_DOWN',
  CHEVRON_BACK_OUTLINE = 'CHEVRON_BACK_OUTLINE',
  CHEVRON_FORWARD_OUTLINE = 'CHEVRON_FORWARD_OUTLINE',
  CHEVRON_UP_OUTLINE = 'CHEVRON_UP_OUTLINE',
  CHEVRON_DOWN_OUTLINE = 'CHEVRON_DOWN_OUTLINE',
  GRID_OUTLINE = 'GRID_OUTLINE',
  GRID = 'GRID',
  REMOVE = 'REMOVE',
  ADD = 'ADD',
  CLOSE_OUTLINE = 'CLOSE_OUTLINE',
  CLOSE = 'CLOSE',
  WINE_OUTLINE = 'WINE_OUTLINE',
  WINE = 'WINE',
  MENU_OUTLINE = 'MENU_OUTLINE',
  MENU = 'MENU',
  LINK_OUTLINE = 'LINK_OUTLINE',
  LINK = 'LINK',
  OPTIONS_OUTLINE = 'OPTIONS_OUTLINE',
  OPTIONS = 'OPTIONS',
  STAR_OUTLINE = 'STAR_OUTLINE',
  STAR = 'STAR',
  CART_OUTLINE = 'CART_OUTLINE',
  CART = 'CART',
  CLOSE_CIRCLE_OUTLINE = 'CLOSE_CIRCLE_OUTLINE',
  CLOSE_CIRCLE = 'CLOSE_CIRCLE',
  EYE_OFF_OUTLINE = 'EYE_OFF_OUTLINE',
  EYE_OFF = 'EYE_OFF',
  EYE_OUTLINE = 'EYE_OUTLINE',
  EYE = 'EYE',
  WARNING_OUTLINE = 'WARNING_OUTLINE',
  WARNING = 'WARNING',
  HEART_OUTLINE = 'HEART_OUTLINE',
  HEART = 'HEART',
  CARD_OUTLINE = 'CARD_OUTLINE',
  CARD = 'CARD',
  INFORMATION_CIRCLE_OUTLINE = 'INFORMATION_CIRCLE_OUTLINE',
  INFORMATION_CIRCLE = 'INFORMATION_CIRCLE',
  CHECKMARK_CIRCLE_OUTLINE = 'CHECKMARK_CIRCLE_OUTLINE',
  CHECKMARK_CIRCLE = 'CHECKMARK_CIRCLE',
  PERSON_CIRCLE_OUTLINE = 'PERSON_CIRCLE_OUTLINE',
  PERSON_CIRCLE = 'PERSON_CIRCLE',
  PERSON = 'PERSON',
  PERSON_OUTLINE = 'PERSON_OUTLINE',
  WALLET_OUTLINE = 'WALLET_OUTLINE',
  WALLET = 'WALLET',
  REMOVE_CIRCLE_OUTLINE = 'REMOVE_CIRCLE_OUTLINE',
  REMOVE_CIRCLE = 'REMOVE_CIRCLE',
  HELP_CIRCLE_OUTLINE = 'HELP_CIRCLE_OUTLINE',
  HELP_CIRCLE = 'HELP_CIRCLE',
  SEARCH_OUTLINE = 'SEARCH_OUTLINE',
  SEARCH = 'SEARCH',
  SAVE_OUTLINE = 'SAVE_OUTLINE',
  SAVE = 'SAVE',
  TIME_OUTLINE = 'TIME_OUTLINE',
  TIME = 'TIME',
  ALARM_OUTLINE = 'ALARM_OUTLINE',
  ALARM = 'ALARM',
  SHIELD_CHECKMARK_OUTLINE = 'SHIELD_CHECKMARK_OUTLINE',
  SHIELD_CHECKMARK = 'SHIELD_CHECKMARK',
  ADD_CIRCLE_OUTLINE = 'ADD_CIRCLE_OUTLINE',
  ADD_CIRCLE = 'ADD_CIRCLE',
  DOWNLOAD_OUTLINE = 'DOWNLOAD_OUTLINE',
  DOWNLOAD = 'DOWNLOAD',
  SAD_OUTLINE = 'SAD_OUTLINE',
  SAD = 'SAD',
  HAPPY_OUTLINE = 'HAPPY_OUTLINE',
  HAPPY = 'HAPPY',
  RECEIPT_OUTLINE = 'RECEIPT_OUTLINE',
  RECEIPT = 'RECEIPT',
  ELLIPSIS_HORIZONTAL_CIRCLE_OUTLINE = 'ELLIPSIS_HORIZONTAL_CIRCLE_OUTLINE',
  ELLIPSIS_HORIZONTAL_CIRCLE = 'ELLIPSIS_HORIZONTAL_CIRCLE',
  ALERT_CIRCLE_OUTLINE = 'ALERT_CIRCLE_OUTLINE',
  ALERT_CIRCLE = 'ALERT_CIRCLE',
  SHARE_SOCIAL_OUTLINE = 'SHARE_SOCIAL_OUTLINE',
  SHARE_SOCIAL = 'SHARE_SOCIAL',
  SETTINGS_OUTLINE = 'SETTINGS_OUTLINE',
  SETTINGS = 'SETTINGS',
  CALENDAR_CLEAR_OUTLINE = 'CALENDAR_CLEAR_OUTLINE',
  CALENDAR_CLEAR = 'CALENDAR_CLEAR',
  CALENDAR_OUTLINE = 'CALENDAR_OUTLINE',
  CALENDAR = 'CALENDAR',
  TRASH = 'TRASH',
  TRASH_OUTLINE = 'TRASH_OUTLINE',
  CHECKMARK = 'CHECKMARK',
  RADIO_BUTTON_OFF_OUTLINE = 'RADIO_BUTTON_OFF_OUTLINE',
  RADIO_BUTTON_ON = 'RADIO_BUTTON_ON',
  CHECKBOX_OUTLINE = 'CHECKBOX_OUTLINE',
  CHECKBOX = 'CHECKBOX',
  ELLIPSE_OUTLINE = 'ELLIPSE_OUTLINE',
  ELLIPSE = 'ELLIPSE',
  LOGO_FACEBOOK = 'LOGO_FACEBOOK',
  LOGO_INSTAGRAM = 'LOGO_INSTAGRAM',
  LOGO_WHATSAPP = 'LOGO_WHATSAPP',
  LOGO_YOUTUBE = 'LOGO_YOUTUBE',
  LOGO_TWITTER = 'LOGO_TWITTER',
  LOGO_TIKTOK = 'LOGO_TIKTOK',
  LOCATION_OUTLINE = 'LOCATION_OUTLINE',
  COMMUNITY = 'COMMUNITY',
  LOCAL = 'LOCAL',
  ORGANIC = 'ORGANIC',
  RAND = 'RAND',
  RECYCLE = 'RECYCLE',
  DELIVERY = 'DELIVERY',
  FREE_RANGE = 'FREE_RANGE',
  ICE = 'ICE',
  WASTE = 'WASTE',
  PACKAGE = 'PACKAGE',
  CREDIT_CARD = 'CREDIT_CARD',
  USER_PLUS = 'USER_PLUS',
  DOLLAR_SIGN = 'DOLLAR_SIGN',
  FILTER = 'FILTER',
  FORK = 'FORK',
  CALL_OUTLINE = 'CALL_OUTLINE',
  PRICE_TAGS_SHARP = 'PRICE_TAGS_SHARP',
  COPY_OUTLINE = 'COPY_OUTLINE',
  ERROR_502 = 'ERROR_502',
}

export enum IconSizeEnum {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  FULL = 'FULL',
}

export interface IconProps {
  icon: IconEnum
  size?: IconSizeEnum
  color?: string
  hoverColor?: string
  containerClassName?: string
  className?: string
}

export function Icon({ icon, size = IconSizeEnum.FULL, color, hoverColor, className, containerClassName }: IconProps): JSX.Element {

  const [iconRef, isHovered] = useHover()

  let iconSize = '100%'
  switch (size) {
    case IconSizeEnum.EXTRA_SMALL:
      iconSize = '16px'
      break
    case IconSizeEnum.SMALL:
      iconSize = '24px'
      break
    case IconSizeEnum.MEDIUM:
      iconSize = '32px'
      break
    case IconSizeEnum.LARGE:
      iconSize = '40px'
      break
    case IconSizeEnum.EXTRA_LARGE:
      iconSize = '48px'
      break
  }

  const iconColor = hoverColor && isHovered ? hoverColor : color
  const getIcon = () => {
    switch (icon) {
      case IconEnum.ARROW_BACK:
        return <IoArrowBack size={iconSize} color={iconColor} className={className} />
      case IconEnum.ARROW_FORWARD:
        return <IoArrowForward size={iconSize} color={iconColor} className={className} />
      case IconEnum.ARROW_UP:
        return <IoArrowUp size={iconSize} color={iconColor} className={className} />
      case IconEnum.ARROW_DOWN:
        return <IoArrowDown size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHEVRON_BACK_OUTLINE:
        return <IoChevronBackOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHEVRON_FORWARD_OUTLINE:
        return <IoChevronForwardOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHEVRON_UP_OUTLINE:
        return <IoChevronUpOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHEVRON_DOWN_OUTLINE:
        return <IoChevronDownOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.GRID_OUTLINE:
        return <IoGridOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.GRID:
        return <IoGrid size={iconSize} color={iconColor} className={className} />
      case IconEnum.REMOVE:
        return <IoRemove size={iconSize} color={iconColor} className={className} />
      case IconEnum.ADD:
        return <IoAdd size={iconSize} color={iconColor} className={className} />
      case IconEnum.CLOSE_OUTLINE:
        return <IoCloseOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CLOSE:
        return <IoClose size={iconSize} color={iconColor} className={className} />
      case IconEnum.WINE_OUTLINE:
        return <IoWineOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.WINE:
        return <IoWine size={iconSize} color={iconColor} className={className} />
      case IconEnum.MENU_OUTLINE:
        return <IoMenuOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.MENU:
        return <IoMenu size={iconSize} color={iconColor} className={className} />
      case IconEnum.LINK_OUTLINE:
        return <IoLinkOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.LINK:
        return <IoLink size={iconSize} color={iconColor} className={className} />
      case IconEnum.OPTIONS_OUTLINE:
        return <IoOptionsOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.OPTIONS:
        return <IoOptions size={iconSize} color={iconColor} className={className} />
      case IconEnum.STAR_OUTLINE:
        return <IoStarOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.STAR:
        return <IoStar size={iconSize} color={iconColor} className={className} />
      case IconEnum.CART_OUTLINE:
        return <IoCartOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CART:
        return <IoCart size={iconSize} color={iconColor} className={className} />
      case IconEnum.CLOSE_CIRCLE_OUTLINE:
        return <IoCloseCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CLOSE_CIRCLE:
        return <IoCloseCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.EYE_OFF_OUTLINE:
        return <IoEyeOffOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.EYE_OFF:
        return <IoEyeOff size={iconSize} color={iconColor} className={className} />
      case IconEnum.EYE_OUTLINE:
        return <IoEyeOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.EYE:
        return <IoEye size={iconSize} color={iconColor} className={className} />
      case IconEnum.WARNING_OUTLINE:
        return <IoWarningOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.WARNING:
        return <IoWarning size={iconSize} color={iconColor} className={className} />
      case IconEnum.HEART_OUTLINE:
        return <IoHeadsetOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.HEART:
        return <IoHeart size={iconSize} color={iconColor} className={className} />
      case IconEnum.CARD_OUTLINE:
        return <IoCardOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CARD:
        return <IoCard size={iconSize} color={iconColor} className={className} />
      case IconEnum.INFORMATION_CIRCLE_OUTLINE:
        return <IoInformationCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.INFORMATION_CIRCLE:
        return <IoInformationCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHECKMARK_CIRCLE_OUTLINE:
        return <IoCheckmarkCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHECKMARK_CIRCLE:
        return <IoCheckmarkCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.PERSON_CIRCLE_OUTLINE:
        return <IoPersonCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.PERSON_CIRCLE:
        return <IoPersonCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.PERSON_OUTLINE:
        return <IoPersonOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.PERSON:
        return <IoPerson size={iconSize} color={iconColor} className={className} />
      case IconEnum.WALLET_OUTLINE:
        return <IoWalletOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.WALLET:
        return <IoWallet size={iconSize} color={iconColor} className={className} />
      case IconEnum.REMOVE_CIRCLE_OUTLINE:
        return <IoRemoveCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.REMOVE_CIRCLE:
        return <IoRemoveCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.HELP_CIRCLE_OUTLINE:
        return <IoHelpCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.HELP_CIRCLE:
        return <IoHelpCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.SAVE_OUTLINE:
        return <IoSaveOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SAVE:
        return <IoSave size={iconSize} color={iconColor} className={className} />
      case IconEnum.SEARCH_OUTLINE:
        return <IoShapesOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SEARCH:
        return <IoSearch size={iconSize} color={iconColor} className={className} />
      case IconEnum.TIME_OUTLINE:
        return <IoThunderstormOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.TIME:
        return <IoTime size={iconSize} color={iconColor} className={className} />
      case IconEnum.ALARM:
        return <IoAlarm size={iconSize} color={iconColor} className={className} />
      case IconEnum.ALARM_OUTLINE:
        return <IoAlarmOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SHIELD_CHECKMARK_OUTLINE:
        return <IoShieldCheckmarkOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SHIELD_CHECKMARK:
        return <IoShieldCheckmark size={iconSize} color={iconColor} className={className} />
      case IconEnum.ADD_CIRCLE_OUTLINE:
        return <IoAddCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.ADD_CIRCLE:
        return <IoAddCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.DOWNLOAD_OUTLINE:
        return <IoDownloadOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.DOWNLOAD:
        return <IoDownload size={iconSize} color={iconColor} className={className} />
      case IconEnum.SAD_OUTLINE:
        return <IoSadOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SAD:
        return <IoSad size={iconSize} color={iconColor} className={className} />
      case IconEnum.HAPPY_OUTLINE:
        return <IoHappyOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.HAPPY:
        return <IoHappy size={iconSize} color={iconColor} className={className} />
      case IconEnum.RECEIPT_OUTLINE:
        return <IoReceiptOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.RECEIPT:
        return <IoReceipt size={iconSize} color={iconColor} className={className} />
      case IconEnum.ELLIPSIS_HORIZONTAL_CIRCLE_OUTLINE:
        return <IoEllipsisHorizontalCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.ELLIPSIS_HORIZONTAL_CIRCLE:
        return <IoEllipsisHorizontalCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.ALERT_CIRCLE_OUTLINE:
        return <IoAlertCircleOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.ALERT_CIRCLE:
        return <IoAlertCircle size={iconSize} color={iconColor} className={className} />
      case IconEnum.SHARE_SOCIAL_OUTLINE:
        return <IoShareSocialOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SHARE_SOCIAL:
        return <IoShareSocial size={iconSize} color={iconColor} className={className} />
      case IconEnum.SETTINGS_OUTLINE:
        return <IoSettingsOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.SETTINGS:
        return <IoSettings size={iconSize} color={iconColor} className={className} />
      case IconEnum.CALENDAR_CLEAR_OUTLINE:
        return <IoCalendarClearOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CALENDAR_CLEAR:
        return <IoCalendar size={iconSize} color={iconColor} className={className} />
      case IconEnum.CALENDAR_OUTLINE:
        return <IoCalculatorOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CALENDAR:
        return <IoCalendar size={iconSize} color={iconColor} className={className} />
      case IconEnum.TRASH_OUTLINE:
        return <IoTrashOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.TRASH:
        return <IoTrash size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHECKMARK:
        return <IoCheckmark size={iconSize} color={iconColor} className={className} />
      case IconEnum.RADIO_BUTTON_OFF_OUTLINE:
        return <IoRadioButtonOffOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.RADIO_BUTTON_ON:
        return <IoRadioButtonOn size={iconSize} color={iconColor} className={className} />
      case IconEnum.CALL_OUTLINE:
        return <IoCallOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHECKBOX_OUTLINE:
        return <IoCheckboxOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.CHECKBOX:
        return <IoCheckbox size={iconSize} color={iconColor} className={className} />
      case IconEnum.ELLIPSE_OUTLINE:
        return <IoEllipseOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.ELLIPSE:
        return <IoEllipse size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_FACEBOOK:
        return <IoLogoFacebook size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_WHATSAPP:
        return <IoLogoWhatsapp size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_INSTAGRAM:
        return <IoLogoInstagram size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_YOUTUBE:
        return <IoLogoYoutube size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_TWITTER:
        return <IoLogoTwitter size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOGO_TIKTOK:
        return <IoLogoTiktok size={iconSize} color={iconColor} className={className} />
      case IconEnum.LOCATION_OUTLINE:
        return <IoLocationOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.PRICE_TAGS_SHARP:
        return <IoPricetagsSharp size={iconSize} color={iconColor} className={className} />
      case IconEnum.COPY_OUTLINE:
        return <IoCopyOutline size={iconSize} color={iconColor} className={className} />
      case IconEnum.COMMUNITY:
        return <Community color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.LOCAL:
        return <Local color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.ORGANIC:
        return <Organic color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.RAND:
        return <Rand color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.RECYCLE:
        return <Recycle color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.DELIVERY:
        return <Delivery color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.FREE_RANGE:
        return <FreeRange color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.ICE:
        return <Ice color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.WASTE:
        return <Waste color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.PACKAGE:
        return <Package color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.CREDIT_CARD:
        return <CreditCard color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.USER_PLUS:
        return <UserPlus color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.DOLLAR_SIGN:
        return <DollarSign color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.FILTER:
        return <Filter color={color} hoverColor={hoverColor} className={className} />
      case IconEnum.FORK:
        return <Fork color={color} hoverColor={hoverColor} className={className} />
    }
  }

  return (
    <IconContainer ref={iconRef} className={containerClassName}>
      {getIcon()}
    </IconContainer>
  )
}
