import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Heading, Icon, IconEnum, ContentSeperator, Spacer, ContentSeperatorTypeEnum } from '@atoms/index'
import { DeviceContainer, Link } from '@client/components'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'

const SideBarContainer = styled.div`
  ${ResponsiveProperty('position', { mobile: 'relative', tablet: 'sticky', desktop: 'sticky' })}
  ${ResponsivePXValue('top', { mobile: '0px', tablet: '80px', desktop: '80px' })}
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start; 
  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('margin-right', { tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('width', '288px')}
`

interface LinkContainerProps {
  $active: boolean
}

const LinkContainer = styled.div<LinkContainerProps>`
  align-self: flex-start;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  a{
    color: ${(props): string => props.$active ? props.theme.colors.oranges.coral : props.theme.colors.greys.liteCodGrey};
    &:hover {
    color: ${(props): string => props.theme.colors.oranges.burntSienna};
  }
  button{
    align-self: flex-start
  }
  }
`
const ActivePage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '16px')}
  .activeLink{
    ${ZeroSpace}
  }

`
const DropDownIcon = styled.div`
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
`
export interface SidebarLink {
  title: string
  address: string
}

export interface SidebarProps {
  activePageAddress: string
  children?: JSX.Element
  links: SidebarLink[]
}

interface SidebarState {
  open: boolean
}

const DEFAULT_STATE: SidebarState = {
  open: false,
}

export function Sidebar({ activePageAddress, links, children }: SidebarProps): JSX.Element {
  const [state, setState] = useState(DEFAULT_STATE)

  const activeLink = links.find(link => { return link.address === activePageAddress })
  let link: SidebarLink

  return (
    <>
      <DeviceContainer $mobile>
        <SideBarContainer>
          <ActivePage onClick={() => setState((prevState) => (update(prevState, { open: { $set: !prevState.open } })))}>
            <Heading transform='uppercase' variant='h6' className='activeLink'>
              {activeLink?.title}
            </Heading>
            <DropDownIcon>
              <Icon color={theme.colors.greys.liteCodGrey} icon={state.open ? IconEnum.CHEVRON_UP_OUTLINE : IconEnum.CHEVRON_DOWN_OUTLINE} />
            </DropDownIcon>
          </ActivePage>
          <If condition={state.open}>
            <ContentSeperator margin='0' type={ContentSeperatorTypeEnum.THICK} />
            <For each='link' of={links}>
              <LinkContainer $active={link?.address === activePageAddress} key={link?.address} onClick={() => { setState(update(state, { open: { $set: false } })) }}>
                <Link href={link?.address} bold>{link?.title}</Link>
              </LinkContainer>
            </For>
          </If>
        </SideBarContainer>
      </DeviceContainer>
      <DeviceContainer $tablet $desktop>
        <SideBarContainer>
          <If condition={!!children}>
            {children}
            <ContentSeperator margin='0' type={ContentSeperatorTypeEnum.THICK} />
          </If>
          <Spacer variant='horizontal' universal='8px' />
          <For each='link' of={links}>
            <LinkContainer $active={link.address === activePageAddress} key={link.address}>
              <Link href={link.address} bold>{link.title}</Link>
            </LinkContainer>
          </For>
          <Spacer variant='horizontal' universal='8px' />
        </SideBarContainer>
      </DeviceContainer>
    </>
  )

}
