import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Paragraph, Link, Spacer } from '@client/components/atoms'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useAddLocationMutation, useAddUserAddressMutation, useUserDetailsQuery } from '@hooks/api'

import { Coordinates, PlaceIdLookup } from '../misc'

const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
`

export interface QuickAddAddressFormProps {
  onSuccess: () => void
  onError: (message: string) => void
  onLoginClicked: () => void
}

interface QuickAddAddressFormState {
  loading: boolean
  placeId: string | null
  placeCoordinates: Coordinates | null
}

const DEFAULT_STATE: QuickAddAddressFormState = {
  loading: false,
  placeId: null as string | null,
  placeCoordinates: null as Coordinates | null,
}

export function QuickAddAddressForm({ onLoginClicked, onSuccess, onError }: QuickAddAddressFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<QuickAddAddressFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addUserAddressMutation] = useAddUserAddressMutation()
  const [addLocationMutation] = useAddLocationMutation()

  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _handleAddNewAddress = async () => {
    setLoading(true)
    try {
      const addLocationResponse = await addLocationMutation({
        variables: {
          input: {
            placeId: state.placeId,
            coordinates: state.placeCoordinates,
          },
        },
      })
      await addUserAddressMutation({
        variables: {
          input: {
            alias: 'Guest address',
            location: addLocationResponse.data.addLocation.id,
            user: userDetailsData.currentUser.id,
            isDefault: true,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: SiteHelper.getUserRefetchQueries(),
      })

      if (config.isBrowser()) {
        window.sessionStorage.setItem('hasUserEnteredAddressFromModal', 'true')
      }

      onSuccess()
    } catch (e) {
      onError(e.message)
    }
    setLoading(false)
  }

  const _handleLogInClicked = () => {
    onLoginClicked?.()
  }

  const _handlePlaceSelect = async (placeId: string): Promise<void> => {
    if (placeId) {
      const addLocationResponse = await addLocationMutation({
        variables: {
          input: {
            placeId,
          },
        },
      })
      setState((prevState) => update(prevState, {
        placeId: { $set: placeId },
        placeCoordinates: { $set: { latitude: addLocationResponse.data.addLocation.center.latitude, longitude: addLocationResponse.data.addLocation.center.longitude } },
        locationData: { $set: { streetName: addLocationResponse.data.addLocation.streetName, streetNumber: addLocationResponse.data.addLocation.streetNumber } },
      }))
    }
  }

  const loading = userDetailsLoading || state.loading

  return (
    <Container>
      <Paragraph variant='p1' color={theme.colors.greys.liteCodGrey} align='center'>
        Enter your address below so that we can confirm that our couriers can reach you.
      </Paragraph>
      <Spacer universal='24px' />
      <PlaceIdLookup
        onSelect={_handlePlaceSelect} />
      <Spacer universal='16px' />
      <Button
        color='black'
        fluid
        title='SUBMIT ADDRESS'
        disabled={state.placeId == null || state.placeId === '' || loading}
        loading={loading}
        onClick={_handleAddNewAddress} />
      <Spacer universal='16px' />
      <If condition={userDetailsData?.currentUser?.__typename === 'GuestUser'}>
        <Link
          color={theme.colors.oranges.coral}
          textAlignment='center'
          disabled={loading}
          onClick={_handleLogInClicked}>
            ALREADY HAVE AN ACCOUNT? LOGIN
        </Link>
      </If>
    </Container>
  )

}
