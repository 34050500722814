import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@client/components/atoms'
import { LiteBoxShadow, ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${LiteBoxShadow}

  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '12px', desktop: '16px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const HeaderContainer = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.alabaster};

  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '32px 24px', desktop: '32px 32px 24px 32px' })}

  .title {
    margin: 0;
    padding: 0;
  }
  .sub-title {
    margin: 0;
    padding: 0;
    ${ResponsivePXValue('margin-top', { mobile: '4px', tablet: '6px', desktop: '8px' })}
  }
`

const ContentContainer = styled.div`
  width: 100%;
`

export interface ContentBlockProps {
  title: string
  description?: string
  children: JSX.Element | JSX.Element[]
}

export function ContentBlock({ title, description, children }: ContentBlockProps): JSX.Element {

  return (
    <Container>
      <HeaderContainer>
        <Heading className='title' variant='h5' transform='uppercase'>{title}</Heading>
        <If condition={!!description}>
          <Paragraph className='sub-title'>{description}</Paragraph>
        </If>
      </HeaderContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
    </Container>
  )

}
