import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftMealStore')

const CraftMealStore = loadable(() => import(/* webpackChunkName: "craft-meal-store" */'@pages/CraftMealStore'), {
  resolveComponent: (components) => components.CraftMealStore,
})

export function CraftMealStoreAsync(): JSX.Element {

  return <CraftMealStore fallback={<div>Loading...</div>} />

}
