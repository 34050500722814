import React, { Fragment, useState } from 'react'

import { format } from 'date-fns'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { useGetAppQuery, useUserCartQuery, useUserDetailsQuery, UserSubscriptionFragment, UserDetailsFragment, RegisteredUserDetailsFragment } from '@hooks/api'
import { TabPane, Tabs, ContentCard, ContentCardHeaderEnum, SectionLoading } from '@molecules/index'
import { CurrentOrderList, AddressDetails, PastOrderList } from '@organisms/index'
import { UserSubscriptionStatusEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

enum TabEnum {
  CURRENT_ORDERS = 'Current Orders',
  PAST_ORDERS = 'Past Orders'
}

interface TabsState {
  activeTab: string
}

const DEFAULT_STATE: TabsState = {
  activeTab: TabEnum.CURRENT_ORDERS,
}

const TwoBlockWrapper = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
`

const OrderTabs = styled.div`
  .mobileTab{
    ${ResponsivePXValue('font-size', '12px')};
    line-height: 1.7;
    justify-content: center;
  }

  .tab {
    transition: 0.4s ease-in-out;
    ${ZeroSpace}
  }

  .tabButton {
    transition: 0.4s ease-in-out;
    ${ResponsivePXValue('margin', { mobile: '16px', tablet: '24px', desktop: '24px 32px' })}
  }
`
const seo = {
  name: 'User Dashbaord',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Orders(): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const title = 'YOUR ORDERS'
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const activeSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE || subscription?.status === UserSubscriptionStatusEnum.PENDING

  const currentOrdersDescription = activeSubscription ? `This order will be billed on ${format(new Date(userCartData?.currentUser?.activeMenu?.billingDate), 'EEE dd MMMM yyyy')}, for delivery on ${format(new Date(userCartData?.currentUser?.activeMenu?.deliveryDate || new Date()), 'EEE dd MMMM yyyy')}.` : null
  const passedOrdersDescription = 'See what you’ve ordered before, rate your dishes, or download some of your favourite recipes.'

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const description = (state.activeTab === TabEnum.CURRENT_ORDERS) ? currentOrdersDescription : passedOrdersDescription

  const theme = useTheme()
  const loading = userCartLoading || userDetailsLoading

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <TwoBlockWrapper>
        <Choose>
          <When condition={loading}>
            <ContentCard title={title} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
              <SectionLoading />
            </ContentCard>
          </When>
          <Otherwise>
            <ContentCard title={title} description={description} headerType={subscription ? ContentCardHeaderEnum.STANDARD : ContentCardHeaderEnum.TITLE_ONLY}>
              <OrderTabs>
                <Tabs
                  activeTab={state.activeTab}
                  className='tab'
                  buttonsClassName='tabButton'
                  backgroundColor={theme.colors.whites.pureWhite}
                  onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
                  <TabPane tab={TabEnum.CURRENT_ORDERS} title={TabEnum.CURRENT_ORDERS} className={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'mobileTab' : null}>
                    <CurrentOrderList />
                  </TabPane>
                  <TabPane tab={TabEnum.PAST_ORDERS} title={TabEnum.PAST_ORDERS} className={appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'mobileTab' : null}>
                    <PastOrderList />
                  </TabPane>
                </Tabs>
              </OrderTabs>
            </ContentCard>
          </Otherwise>
        </Choose>
        <If condition={state.activeTab === TabEnum.CURRENT_ORDERS && activeSubscription}>
          <AddressDetails />
        </If>
      </TwoBlockWrapper>
    </Fragment>
  )

}
