import React from 'react'

import { Outlet } from 'react-router-dom'

import { ErrorBoundary } from '@organisms/index'

const MainComponent: React.FC = () => {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  )
}

export default MainComponent
