import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Paragraph, ResponsiveImage } from '@atoms/index'
import { DeviceContainer, FlexDeviceContainer, Heading, scrollToLink, Spacer, Link } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { BorderBottom, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { ProductIconImageFragment } from '@hooks/api'
import { ObjectFitEnum } from '@uctypes/api/globalTypes'

export interface SubscriptionCategoryProps {
  title: string
  subTitle?: string
  icon?: ProductIconImageFragment
  learnMoreUrl?: string
  className?: string
}

const Container = styled.div`
  display: flex;
  font-family: 'gordita';
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${BorderBottom}
  
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px', desktop: '16px 24px' })}

  .title {

    display: flex;

    ${ZeroSpace}

    font-weight: 500;

    ${ResponsivePXValue('font-size', '14px')}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .sub-title {
    ${ZeroSpace}

    font-weight: 400;

    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .desktop-image-container {
    align-self: center;
  }
`
const LeftContainer = styled.div`
  width: '33%';
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '33px', tablet: '33px', desktop: '50px' })}
  ${ResponsivePXValue('height', { mobile: '33px', tablet: '33px', desktop: '50px' })}

  ${ResponsivePXValue('margin-right', '8px')}

  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '16px' })}
`
const ContentContainer = styled.div`
 display: flex;
 flex-direction: column;
`
const TitleContainer = styled.div`
 display: flex;
`
const LearnMore = styled.div`
 display: flex;

 .learn-more {

   span {
    font-weight: 500;
   }
   height: auto;
 }
`

export function SubscriptionCategory({ title, subTitle, icon, learnMoreUrl, className }: SubscriptionCategoryProps): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()

  const _handleLearnMore = (): void => {
    if (learnMoreUrl) {
      if (learnMoreUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = learnMoreUrl
      } else if (learnMoreUrl.includes('#')) {
        scrollToLink(learnMoreUrl)
      } else {
        navigate(learnMoreUrl)
      }

      ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.KNOW_YOUR_DISHES)
    }
  }

  return (
    <Container className={className}>
      <DeviceContainer $mobile $tablet>
        <If condition={!!title}>
          <TitleContainer>
            <Heading variant='h6' className='title'>{title}</Heading>
            <Spacer variant='horizontal' universal='16px' />
          </TitleContainer>
        </If>
        <ContentContainer>
          <If condition={!!subTitle}>
            <Paragraph variant='p3' className='sub-title'>{subTitle}</Paragraph>
            <Spacer variant='horizontal' universal='8px' />
          </If>
          <If condition={!!learnMoreUrl}>
            <LearnMore>
              <Link
                className='learn-more'
                bold
                onClick={_handleLearnMore}> Learn more </Link>
            </LearnMore>
          </If>
        </ContentContainer>
      </DeviceContainer>
      <FlexDeviceContainer $desktop>
        <If condition={!!icon}>
          <LeftContainer className='desktop-image-container'>
            <IconContainer className='desktop-image-container'>
              <ResponsiveImage
                lazy={false}
                image={icon}
                objectFit={ObjectFitEnum.FILL}
              />
            </IconContainer>
          </LeftContainer>
          <Spacer variant='horizontal' universal='8px' />
        </If>
        <ContentContainer>
          <If condition={!!title}>
            <Heading variant='h6' className='title'>{title}</Heading>
            <Spacer variant='horizontal' universal='8px' />
          </If>
          <If condition={!!subTitle}>
            <Paragraph variant='p3' className='sub-title'>{subTitle}</Paragraph>
            <Spacer variant='horizontal' universal='4px' />
          </If>
          <If condition={!!learnMoreUrl}>
            <LearnMore>
              <Link
                className='learn-more'
                bold
                onClick={_handleLearnMore}> Learn more </Link>
            </LearnMore>
          </If>
        </ContentContainer>
      </FlexDeviceContainer>
    </Container>
  )
}
