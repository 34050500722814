import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { IconEnum, Button, Link, Spacer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '317px', desktop: '346px' })}
  
`

const DESKTOP_MAX_PAGES = 7
const MOBILE_MAX_PAGES = 3

export interface PaginationProps {
  totalItems: number
  itemsPerPage: number
  page?: number
  prevPageClicked?: (page: number) => void
  nextPageClicked?: (page: number) => void
  pageClicked?: (page: number) => void
}

interface PaginationState {
  currentPage: number
  pagesToShow: number[]
}

export function Pagination({ totalItems, itemsPerPage, page, prevPageClicked, nextPageClicked, pageClicked }: PaginationProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<PaginationState>({ pagesToShow: [], currentPage: page })

  const canMoveNext = (state.currentPage < (Math.ceil(totalItems / itemsPerPage)))

  useEffect(() => {
    _handleSize()
  }, [totalItems, itemsPerPage, page, appData.app.deviceType])

  const getPagesToShow = (currentPage: number): number[] => {
    if (totalItems === undefined) {
      return [1]
    }
    const pagesToShow: number[] = []
    const totalPages = (Math.ceil(totalItems / itemsPerPage))

    if (appData.app.deviceType === DeviceTypeEnum.DESKTOP) {
      if (totalPages <= DESKTOP_MAX_PAGES) {
        for (let i = 1; i <= totalPages; i++) {
          pagesToShow.push(i)
        }
      } else {
        if (currentPage === 1) {
          let p = 1
          while ((pagesToShow.length + 1) <= DESKTOP_MAX_PAGES) {
            pagesToShow.push(p)
            p++
          }
        } else if (currentPage === totalPages) {
          let p: number = (totalPages - (DESKTOP_MAX_PAGES - 1))
          while ((pagesToShow.length + 1) <= DESKTOP_MAX_PAGES) {
            pagesToShow.push(p)
            p++
          }
        } else {
          let p: number = currentPage
          let count = 0

          let leftSideDiff = Math.floor((DESKTOP_MAX_PAGES / 2))
          let rightSideDiff = Math.floor((DESKTOP_MAX_PAGES / 2))
          const pageCalc = (currentPage + rightSideDiff)
          if (pageCalc > totalPages) {
            const leftover = (currentPage + rightSideDiff) - totalPages
            leftSideDiff = leftSideDiff + leftover
            rightSideDiff = rightSideDiff - leftover
          }

          while (count < leftSideDiff && (p > 1)) {
            p--
            count++
          }
          while ((pagesToShow.length + 1) <= DESKTOP_MAX_PAGES) {
            pagesToShow.push(p)
            p++
          }
        }
      }
    } else {
      if (totalPages <= MOBILE_MAX_PAGES) {
        for (let i = 1; i <= totalPages; i++) {
          pagesToShow.push(i)
        }
      } else {
        if (currentPage === 1) {
          let p = 1
          while ((pagesToShow.length + 1) <= MOBILE_MAX_PAGES) {
            pagesToShow.push(p)
            p++
          }
        } else if (currentPage === totalPages) {
          let p: number = (totalPages - (MOBILE_MAX_PAGES - 1))

          while ((pagesToShow.length + 1) <= MOBILE_MAX_PAGES) {
            pagesToShow.push(p)
            p++
          }
        } else {
          pagesToShow.push((currentPage - 1))
          pagesToShow.push((currentPage))
          pagesToShow.push((currentPage + 1))
        }
      }
    }

    return pagesToShow.sort((a, b) => a - b)
  }

  const _handleSize = (): void => {
    const currentPage = state.currentPage
    const pagesToShow = getPagesToShow(currentPage)
    setState({ currentPage, pagesToShow })
  }

  const _prevPageClicked = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {

    if (state.currentPage === 1) {
      return
    }

    let page: number = (state.currentPage - 1)
    if (page < 1) {
      page = 1
    }
    if (prevPageClicked) {
      e?.preventDefault()
      prevPageClicked(page)
    }
    const pagesToShow = getPagesToShow(page)
    setState({ currentPage: page, pagesToShow })
  }

  const _nextPageClicked = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {

    if (!canMoveNext) {
      return
    }

    const totalPages = (Math.ceil(totalItems / itemsPerPage))
    let page: number = (state.currentPage + 1)
    if (page > totalPages) {
      page = totalPages
    }
    if (nextPageClicked) {
      e?.preventDefault()
      nextPageClicked(page)
    }
    const pagesToShow = getPagesToShow(page)
    setState({ currentPage: page, pagesToShow })
  }

  const _pageClicked = (page: number): void => {
    if (pageClicked) {
      pageClicked(page)
    }
    const pagesToShow = getPagesToShow(page)
    setState({ currentPage: page, pagesToShow })
  }

  const activeColor = theme.colors.oranges.coral
  const inactiveColor = theme.colors.greys.boulder

  let item: number

  return (
    <Container>
      <If condition={!!totalItems}>
        <Button
          icon={IconEnum.CHEVRON_BACK_OUTLINE}
          iconColor={state.currentPage === 1 ? inactiveColor : activeColor}
          color='transparent'
          onClick={_prevPageClicked} />
        <For each='item' of={state.pagesToShow}>
          <Spacer universal='8px' variant='horizontal' />
          <Link
            key={`${item}-link`}
            color={item === state.currentPage ? activeColor : inactiveColor}
            onClick={_pageClicked.bind(this, item)}>
            {item}
          </Link>
          <Spacer universal='8px' variant='horizontal' />
        </For>
        <Button
          icon={IconEnum.CHEVRON_FORWARD_OUTLINE}
          iconColor={canMoveNext ? activeColor : inactiveColor}
          color='transparent'
          onClick={_nextPageClicked.bind(this)}/>
      </If>
    </Container>
  )
}
