import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Heading, Paragraph, Spacer, Button, Link } from '@atoms/index'
import { MultipleImageContainer, ProductImageInterface } from '@client/components/atoms/images/MulitpleImageContainer'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useUserCartQuery, useGetAppQuery, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, MarketProductDetailsFragment } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { FloatingUpSell, Form, useForm } from '@molecules/index'
import { DeviceTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { LowStockContainer } from '../misc'
import { ReactivateSubscriptionModal } from '../modals'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .input {
    ${ZeroSpace}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  }
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

  .subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .price-header {
    font-weight: 700;
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '16px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }
`
const TextContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const InputContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

const CounterContainer = styled.div`
  display: flex;
  flex: 1;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'flex-end' })}
  ${ResponsivePXValue('padding', { mobile: '16px 0 0', tablet: '16px 0 0', desktop: '0' })}
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

enum MarketProductModalEnum {
  SIGNUP = 'SIGNUP',
  UN_PAUSE = 'UN_PAUSE',
  NONE = 'NONE',
}

export interface MarketDishDetailsProps {
  product: MarketProductDetailsFragment
}

interface MarketDishDetailsState {
  loading: boolean
  disabled: boolean
  priceDisplay: string
  activeModal: MarketProductModalEnum
  currentAmountInCart: number
  buttonTitle: string
  notAvailable: boolean

}

const DEFAULT_STATE: MarketDishDetailsState = {
  loading: false,
  disabled: false,
  priceDisplay: '',
  activeModal: MarketProductModalEnum.NONE,
  currentAmountInCart: null,
  buttonTitle: 'ADD TO CART',
  notAvailable: false,
}

export const MarketDishDetails = React.memo(({ product }: MarketDishDetailsProps): JSX.Element => {

  const config = useConfig()
  const [state, setState] = useState<MarketDishDetailsState>({ ...DEFAULT_STATE })
  const quantity = 1
  const [addItemToCart] = useAddItemToCartMutation()
  const { data: userCartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const events = useEvents()
  const theme = useTheme()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const isWeekendBox = product?.isWeekendBox
  const allergens: string[] = product?.allergens?.length
    ? product.allergens.map((allergen: { id: string, title: string }) => allergen.title)
    : []
  const formattedAllergens = allergens.length > 0 ? allergens.join(', ') : 'None'
  const finalAllergens = `${formattedAllergens}.`
  const finalWarning = product?.warning
  const productImagesArray = product?.productImages as unknown as ProductImageInterface[]
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const cartItems = userCartData?.currentUser?.activeCart?.cartItems
  const hasActiveSubscription = !!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription

  const _handleAddProductToBox = async (): Promise<void> => {
    try {
      setState((prevState) => update(prevState, { loading: { $set: true } }))

      await addItemToCart({
        variables: { productId: product.id, quantity },
      })

      const logData = {
        itemName: product?.name,
        itemId: product?.id,
        itemGroupId: product?.id,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemCategory: product?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: product?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Market Product',
        quantity,
        itemImage: product?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setState((prevState) => update(prevState, { loading: { $set: false } }))
    }
  }

  const _handleToggleReactivateSubscriptionModal = (display: boolean): void => {
    const activeModal = display ? MarketProductModalEnum.UN_PAUSE : MarketProductModalEnum.NONE
    setState((prevState) => update(prevState, { activeModal: { $set: activeModal } }))
  }

  const _handleReactivateSubscription = () => {
    _handleAddProductToBox()
    CartPlugin.shared().open()
    _handleToggleReactivateSubscriptionModal(false)
  }

  const _handleAddToCart = async () => {
    if (!userCartData?.currentUser?.checkoutStatus?.hasAccount) {
      // Show market modal
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    } else if (product.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
      if (userCartData?.currentUser?.checkoutStatus?.hasPausedSubscription) {
        // SHOW Unpause
        _handleToggleReactivateSubscriptionModal(true)
      } else if (!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
        // Show plans
        navigate('/meal-kit/plans')
      }
    } else {
      _handleAddProductToBox()
    }
  }

  const _handleOnRemove = async (): Promise<void> => {

    setState((prevState) => update(prevState, { loading: { $set: true } }))

    try {
      if (state.currentAmountInCart === 1) {

        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
        })
        setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: state.currentAmountInCart - 1,
          },
        })
      }

      const logData = {
        itemName: product?.name,
        itemId: product?.id,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemCategory: product?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: product?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Market Product',
        quantity,
        itemImage: product?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)
      setState((prevState) => update(prevState, { loading: { $set: false } }))

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (!(state.currentAmountInCart > 0)) {
      _handleAddToCart()
    }
  }

  const setButtonTitle = () => {

    let notAvailable = false
    let buttonTitle = userCartData?.currentUser?.isOnDemand ? 'ADD TO CART' : 'ADD TO ORDER'

    if (product?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      notAvailable = true
      buttonTitle = 'OUT OF STOCK'
    } else if (product?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE || !hasActiveSubscription) {
      buttonTitle = 'GET STARTED'
    } else if (product?.availability !== ProductAvailabilityEnum.AVAILABLE) {
      notAvailable = true
      buttonTitle = 'NOT AVAILABLE'
    }
    setState((prevState) => update(prevState, {
      buttonTitle: { $set: buttonTitle },
      notAvailable: { $set: notAvailable },
    }))
  }

  useEffect(() => {
    if (product) {

      isCardItemInBasket()
      setButtonTitle()

      const price = `R${product?.price}`
      setState((prevState) => ({ ...prevState, priceDisplay: price }))
    }
  }, [product])

  const isCardItemInBasket = () => {

    const quantity = cartItems?.find((item) => item?.product?.id === product?.id)?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))

  }
  const downloadFile = (product: MarketProductDetailsFragment): void => {
    const href = product.boxRecipeCard.location
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = href
    a.download = 'file.pdf'
    a.target = '_blank'
    a.click()
    if (config.isBrowser()) {
      window.URL.revokeObjectURL(a.href)
    }
    document.body.removeChild(a)
  }

  useEffect(() => {
    isCardItemInBasket()
    setButtonTitle()
  }, [userCartData])

  return (
    <>
      <ReactivateSubscriptionModal
        open={state.activeModal === MarketProductModalEnum.UN_PAUSE}
        onReactivation={_handleReactivateSubscription}
        onClose={() => { _handleToggleReactivateSubscriptionModal(false) }} />
      <Form
        form={form}
        disabled={state.loading || cartLoading}
        onFinish={_handleAddToCart}>
        <Container>
          <DishContainer>
            <ContentContainer>
              <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='title-header'>
                {product?.name}
              </Heading>
              <Spacer universal='8px' />
              <Heading variant='h5' color={theme.colors.greys.liteCodGrey} className='sub-title'>
                {product?.brand?.name}
              </Heading>
              <Spacer universal='8px' />
              <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
                {product?.shortDescription}
              </Paragraph>
              <Spacer universal='16px' />
              <LowStockContainer
                displayRule
                product={product}
                flexDirection={isMobile ? 'row' : 'column'}
                alignItems={isMobile ? 'center' : 'flex-start'}>
                <Heading variant='h4' color={theme.colors.greys.darkCodGrey} className='price-header'>
                  {state.priceDisplay}
                </Heading>
              </LowStockContainer>
              <Spacer universal='24px' />
              <InputContainer>
                <CounterContainer>
                  <Button
                    className='add-button'
                    title={state.buttonTitle}
                    color='black'
                    fullWidth
                    loading={state.loading}
                    disabled={state.notAvailable}
                    amount={state.currentAmountInCart}
                    onClick={_handleOnCenterClick}
                    onLeftIconClick={_handleOnRemove}
                    onRightIconClick={_handleAddToCart}
                  />
                </CounterContainer>
              </InputContainer>
              <Spacer universal='16px' />
              <TextContainer>
                <Paragraph variant='p3' bold color={theme.colors.slates.bitter}>Allergens: {finalAllergens}</Paragraph>
                <If condition={!!finalWarning}>
                  <Paragraph variant='p3' bold color={theme.colors.slates.bitter}>Warning: {finalWarning}</Paragraph>
                </If>
              </TextContainer>
              <If condition = {!!product?.boxRecipeCard?.location}>
              <Spacer universal='16px' />
                <Link onClick={() => downloadFile(product)} color={theme.colors.oranges.coral} bold>DOWNLOAD RECIPE</Link>
              </If>
            </ContentContainer>

            <ImageContainer>
              <If condition={product?.upSellText?.length > 0}>
                <FloatingUpSell text={product.upSellText} />
              </If>
              <MultipleImageContainer images={productImagesArray} />
            </ImageContainer>
          </DishContainer>
        </Container >
      </Form>
    </>
  )
})

MarketDishDetails.displayName = 'MarketDishDetails'
