import React from 'react'

import { MealKitOnMenuCarousel } from '@molecules/index'

interface PageDataMealKitOnTheMenuCarouselProps {
  title: string
}

export function PageDataMealKitOnTheMenuCarousel({ title }: PageDataMealKitOnTheMenuCarouselProps): JSX.Element {

  return (
    <>
      <MealKitOnMenuCarousel title={title} />
    </>
  )

}
