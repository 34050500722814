import React, { Fragment, useEffect, useState } from 'react'

import update from 'react-addons-update'

import { useConfig } from '@client/contexts/ConfigProvider'
import { RegisteredUserDetailsFragment, UserDetailsFragment, useUserDetailsQuery } from '@hooks/api'
import { ContentCard, ContentCardHeaderEnum, SectionLoading } from '@molecules/index'
import { ActivateVitalityHealthyDining, VitalityHealthyDining } from '@organisms/index'
import { NotFoundPage } from '@pages/NotFoundPage'
import { PartnershipPlatformEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

enum PartnersStateEnum {
  LOADING = 'LOADING',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}

interface AddLocationModalState {
  displayStep: PartnersStateEnum
}

const DEFAULT_STATE: AddLocationModalState = {
  displayStep: PartnersStateEnum.LOADING,
}

const seo = {
  name: 'User Dashbaord',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Partners(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<AddLocationModalState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const title = 'VITALITY HEALTHY DINING'

  useEffect(() => {
    if (!userDetailsLoading) {
      let state = PartnersStateEnum.INACTIVE
      const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
      if (registeredUser?.userPartnerships && registeredUser?.userPartnerships.length > 0) {
        if (registeredUser.userPartnerships.find(p => p.partnershipPlatform === PartnershipPlatformEnum.DISCOVERY && p.memberVerified)) {
          state = PartnersStateEnum.ACTIVE
        }
      }
      setState((prevState) => update(prevState, {
        displayStep: { $set: state },
      }))
    }
  }, [userDetailsLoading])

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={state.displayStep === PartnersStateEnum.LOADING}>
          <ContentCard title={title} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
            <SectionLoading />
          </ContentCard>
        </When>
        <When condition={state.displayStep === PartnersStateEnum.INACTIVE}>
          <ContentCard title={title} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
            <ActivateVitalityHealthyDining userId={userDetailsData?.currentUser?.id} />
          </ContentCard>
        </When>
        <When condition={state.displayStep === PartnersStateEnum.ERROR}>
          <ContentCard title={'ERROR'} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
            <NotFoundPage />
          </ContentCard>
        </When>
        <Otherwise>
          <ContentCard title={title} headerType={ContentCardHeaderEnum.IMAGE} image='VITALITY-VERIFIED'>
            <VitalityHealthyDining />
          </ContentCard>
        </Otherwise>
      </Choose>
    </Fragment>
  )
}
