import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Heading, Paragraph, scrollToLink, Spacer } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue } from '@components/Theme'
import { PageCardFragment } from '@hooks/api'

const Container = styled.div<{ $isStandardVariation?: boolean }>`

  background-color: ${(props): string => props.$isStandardVariation ? props.theme.colors.misc.transparent : props.theme.colors.misc.transparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  Overflow: hidden; 

  ${ResponsivePXValue('margin', '8px')}
  ${ResponsivePXValue('padding', '24px')}
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${ResponsivePXValue('height', '150px')}
  ${ResponsivePXValue('width', '150px')}

  .icon {
    ${ResponsivePXValue('width', '56px')}
  }
`
const ImageContainer = styled.div<{ $hasLink: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  cursor: ${(props): string => props.$hasLink ? 'pointer' : 'default'};

  ${ResponsivePXValue('width', '256px')}

`

export interface PageTileProps {
  pageTile: PageCardFragment
  isStandardVariation?: boolean
  isImageVariation?: boolean
  hideDescription?: boolean
  className?: string
}

export function PageTile({ pageTile, isStandardVariation, isImageVariation, hideDescription, className }: PageTileProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const linkUrl = pageTile?.link?.link

  const _handleLinkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    if (e.defaultPrevented) return

    e.preventDefault()

    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl) //
      }
    }
  }

  const hasLink = pageTile?.link?.link?.length > 0

  return (
    <Container className={className} $isStandardVariation={isStandardVariation}>
      <If condition={!pageTile?.icon?.isDefault}>
        <IconContainer onClick={_handleLinkClick}>
          <ResponsiveImage className='icon' image={pageTile.icon} objectFit={pageTile?.objectFit} />
        </IconContainer>
        <Spacer universal='24px'/>
      </If>
      <If condition={!pageTile?.image?.isDefault}>
        <ImageContainer onClick={_handleLinkClick} $hasLink={hasLink}>
          <ResponsiveImage className='image' image={pageTile.image} objectFit={pageTile?.objectFit} />
        </ImageContainer>
        <If condition={!isImageVariation}>
          <Spacer universal='24px'/>
        </If>
      </If>
      <If condition={!!pageTile?.cardTitle && !isImageVariation}>
        <Heading variant='h4' align='center'>{pageTile.cardTitle}</Heading>
        <Spacer universal='12px' />
      </If>
      <If condition={!hideDescription && !!pageTile.description}>
        <Paragraph align='center' variant='p1'>
          {pageTile.description}
        </Paragraph>
      </If>
    </Container>
  )
}
