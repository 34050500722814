import React from 'react'

import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { MediumBoxShadow, ResponsivePXValue, SmallLoader } from '@client/components'
import { AddLocationButton } from '@client/components/molecules/navigation/AddLocationButton'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery } from '@hooks/api'

const Bar = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${MediumBoxShadow}

  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'flex', desktop: 'none' })}
  ${ResponsivePXValue('height', { mobile: '64px', tablet: '64px' })}
`

const ButtonLoader = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

export function MobileLocationBar(): JSX.Element {

  const config = useConfig()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const _handleAddLocation = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
  }

  return (
    <Bar>
      <Choose>
        <When condition={userDetailsLoading}>
          <ButtonLoader>
            <SmallLoader color={theme.colors.oranges.coral} />
          </ButtonLoader>
        </When>
        <Otherwise>
          <AddLocationButton
            currentUser={userDetailsData?.currentUser}
            onClick={_handleAddLocation}
          />
        </Otherwise>
      </Choose>
    </Bar>
  )

}
