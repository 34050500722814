import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { Loader } from '@atoms/notifications'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useImpersonateUserMutation } from '@hooks/api'

const Continer = styled.div`
  ${ResponsivePXValue('padding', '160px 16px')}
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export function Impersonate(): JSX.Element {

  const { token } = useParams()
  const [state, setState] = useState<{ error: string, complete: boolean }>({ error: '', complete: false })

  const [impersonate] = useImpersonateUserMutation()

  const performImpersonation = async (): Promise<void> => {
    try {
      impersonate({
        variables: {
          token,
        },
      })
      await setState((prevState) => update(prevState, {
        complete: {
          $set: true,
        },
      }))
      setTimeout(() => {
        window.location.pathname = '/'
      }, 2000)
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: {
          $set: e.message,
        },
      }))
    }
  }

  useEffect(() => {
    performImpersonation()
  }, [token])

  return (
    <Continer>
      <Choose>
        <When condition={!!state.error}>
          <Heading variant='h2'>{state.error}</Heading>
        </When>
        <When condition={state.complete}>
          <Heading variant='h2'>Authenticated!</Heading>
          <Heading variant='h4'>Redirecting</Heading>
        </When>
        <Otherwise>
          <Loader />
        </Otherwise>
      </Choose>
    </Continer>
  )

}
