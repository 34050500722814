import styled from 'styled-components'

import { ResponsivePXValue } from '@client/components'

export const ModalFormContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: 'CALC(100% - 200px)', desktop: '600px' })}
`
export const ModalMessageContainer = styled.div<{ $backgroundColor?: string }>`
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-align: center;

  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.whites.roseWhite};
  border-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.reds.cautionRed};

  ${ResponsivePXValue('padding', '16px 24px')}

  .error {
    color: ${(props): string => props.theme.colors.reds.cautionRed};
  }

`
