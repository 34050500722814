import React, { Fragment, useRef } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Button, calculateFlexBasis, Heading, Spacer } from '@client/components'
import { FormFactor, getPageSectionBackground, PageSectionPadding, ResponsivePXValue, theme } from '@client/components/Theme'
import { PageGridItemFragment, PageGridSectionFragment } from '@hooks/api'
import { PageGridSpacingEnum } from '@uctypes/api/globalTypes'

import { PageGridItem } from './PageGridItem'
import { getPageSectionButtonColor, PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string }>`
 display: flex;
 flex-direction: column;

 ${PageSectionPadding}

 background: ${(props): string => props.$background};
`
const PageGrid = styled.div<{ $gutterWidth?: string }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  justify-content: ${(props): string => props?.$gutterWidth ? 'center' : 'space-around'};

  .page-grid-item {

    ${ResponsivePXValue('margin-bottom', { mobile: '32px', tablet: '36px', desktop: '48px' })}

    ${(props): CSS => {
    if (props.$gutterWidth) {
      return ResponsivePXValue('margin-right', { mobile: '0', desktop: `${props.$gutterWidth}px` })
    }
  }}
  
  }

`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export interface PageGridSectionProps {
  pageSection: PageGridSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageGridSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageGridSectionProps): JSX.Element {

  const navigate = useNavigate()
  const link = pageSection?.link
  const linkColor = getPageSectionButtonColor(link?.color)

  const gutterWidth = pageSection?.gutterWidth || '32'

  const background = getPageSectionBackground(pageSection?.background)

  let pageRef

  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  let flexBasis: FormFactor = null

  if (pageSection.spacingType === PageGridSpacingEnum.SPACE_BETWEEN) {
    flexBasis = calculateFlexBasis(pageSection.columns as unknown as FormFactor)
  }

  const isValidLink = link?.title && link.title.length > 0

  const _handleLinkClick = (): void => {
    if (link?.link?.indexOf('http') !== 0) {
      navigate(link.link)
      if (link.link.includes('#')) {
        // NO:
        // document.getElementById(pageBanner.actionLink.split('#').pop()).scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  let index: number
  let pageGridItem: PageGridItemFragment

  return (
    <Container ref={pageRef} $background={background}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection.title}>
            <Heading variant='h4' align='center' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='24px' />
          </If>
          <If condition={!!pageSection.description}>
            <Heading variant='h2' align='center'>{pageSection.description}</Heading>
            <Spacer universal='16px' />
          </If>
          <Fragment>
            <PageGrid $gutterWidth={gutterWidth}>
              <For each='pageGridItem' of={pageSection.pageGridItems} index='index'>
                <PageGridItem key={index} pageGridItem={pageGridItem} flexBasis={flexBasis} className='page-grid-item' />
              </For>
            </PageGrid>
            <If condition={isValidLink}>
              <ButtonContainer>
                <Button title={link.title} color={linkColor} onClick={_handleLinkClick} href={link?.link} />
              </ButtonContainer>
            </If>
          </Fragment>
        </When>
        <Otherwise>
          Page Grid Section not found
        </Otherwise>
      </Choose>
    </Container>
  )
}
