import styled from 'styled-components'

export const BaseStyle = styled.div`
  position: relative;

  * {
    box-sizing: border-box;
  }
  font-family: "brandon-grotesque",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  a {
    text-decoration: none;
  }
  p {
    margin-bottom: 4vw;
    ${(props): string => props.theme.tablet} {
      margin-bottom: 3vw;
    }
    ${(props): string => props.theme.desktop} {
      margin-bottom: 2vw;
    }
  }
  li {
    margin-bottom: 2vw;
    ${(props): string => props.theme.tablet} {
      margin-bottom: 1vw;
    }
    ${(props): string => props.theme.desktop} {
      margin-bottom: 0.5vw;
    }
  }
  table {
    margin-bottom: 3vw;
    ${(props): string => props.theme.tablet} {
      /* 33% less */
      margin-bottom: 2vw;
    }
    ${(props): string => props.theme.desktop} {
      /* 50% less */
      margin-bottom: 1.5vw;
    }
    ${(props): string => props.theme.ultra} {
      /* 50% less hard */
      margin-bottom: $${(props): string => props.theme.uw(1.5)}px;
    }
  }
  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
`
