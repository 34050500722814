import React from 'react'

import styled from 'styled-components'

import { theme } from '@components/Theme'

const ContentSeperatorElement = styled.div<{ $margin: string, $height: string }>`

  background-color: ${theme.colors.whites.desertStorm};
  height: ${(props): string => props.$height};
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props): string => props.$margin};
`

export enum ContentSeperatorTypeEnum {
  THIN = '1px',
  THICK = '2px'
}

export interface ContentSeperatorProps {
  margin?: string
  type?: ContentSeperatorTypeEnum
}

export function ContentSeperator({ margin = '32px 0', type = ContentSeperatorTypeEnum.THIN }: ContentSeperatorProps): JSX.Element {
  return (
    <ContentSeperatorElement $margin={margin} $height={type}/>
  )
}
