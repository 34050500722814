import React, { useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator, Spacer } from '@atoms/index'
import { TabPane, Tabs, Accordion, Tab, Biography, BiographyProps } from '@molecules/index'
import { DeviceContainer } from '@utility/DeviceContainer'

import { NutritionalInfo, NutritionalInfoProps } from './NutritionalInfo'
import { WhatWeSend, WhatWeSendProps } from './WhatWeSend'
import { WhatYouNeed, WhatYouNeedProps } from './WhatYouNeed'

export interface MoreAboutDishProps {
  chef: BiographyProps
  nutritionalInfo: NutritionalInfoProps
  whatWeSend: WhatWeSendProps
  whatYouNeed: WhatYouNeedProps
}

enum TabEnum {
  CHEF = 'Chef',
  NUTRITIONAL_INFORMATION = 'Nutritional info',
  WHAT_WE_SEND = 'What we send',
  WHAT_YOU_NEED = 'What you need'
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

interface TabsState {
  activeTab: string
}

const DEFAULT_STATE: TabsState = {
  activeTab: TabEnum.CHEF,
}

export function MoreAboutDish({ chef, nutritionalInfo, whatWeSend, whatYouNeed }: MoreAboutDishProps): JSX.Element {

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  return (
    <Container>
      <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>More about this dish</Heading>
      <Spacer universal='8px' />
      <Seperator align='center' />
      <DeviceContainer $mobile>
        <Accordion
          activeTab={state.activeTab}
          backgroundColor={theme.colors.whites.pureWhite}
          onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
          <Tab tab={TabEnum.CHEF} title={TabEnum.CHEF}>
            <Biography {...chef} />
          </Tab>
          <Tab tab={TabEnum.NUTRITIONAL_INFORMATION} title={TabEnum.NUTRITIONAL_INFORMATION}>
            <NutritionalInfo {...nutritionalInfo} />
          </Tab>
          <Tab tab={TabEnum.WHAT_WE_SEND} title={TabEnum.WHAT_WE_SEND}>
            <WhatWeSend {...whatWeSend} />
          </Tab>
          <Tab tab={TabEnum.WHAT_YOU_NEED} title={TabEnum.WHAT_YOU_NEED}>
            <WhatYouNeed {...whatYouNeed} />
          </Tab>
        </Accordion>
      </DeviceContainer>
      <DeviceContainer $tablet $desktop>
        <Tabs
          activeTab={state.activeTab}
          backgroundColor={theme.colors.whites.pureWhite}
          onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
          <TabPane tab={TabEnum.CHEF} title={TabEnum.CHEF}>
            <Biography {...chef} />
          </TabPane>
          <TabPane tab={TabEnum.NUTRITIONAL_INFORMATION} title={TabEnum.NUTRITIONAL_INFORMATION}>
            <NutritionalInfo {...nutritionalInfo} />
          </TabPane>
          <TabPane tab={TabEnum.WHAT_WE_SEND} title={TabEnum.WHAT_WE_SEND}>
            <WhatWeSend {...whatWeSend} />
          </TabPane>
          <TabPane tab={TabEnum.WHAT_YOU_NEED} title={TabEnum.WHAT_YOU_NEED}>
            <WhatYouNeed {...whatYouNeed} />
          </TabPane>
        </Tabs>
      </DeviceContainer>
    </Container>
  )

}
