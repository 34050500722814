import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Paragraph, Spacer } from '@atoms/index'
import { Heading, ResponsivePXValue, StaticImage, Link, ZeroSpace, Button } from '@client/components'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ResponsivePXValue('width', { tablet: '400px', desktop: '496px' })}
  ${ResponsivePXValue('max-width', { tablet: '400px', desktop: '496px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px 32px', desktop: '32px 40px' })}

  .title-element {
    ${ZeroSpace}
   
    ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '14px', desktop: '16px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-element {
    ${ZeroSpace}

    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .deliver-box-button {
    ${ResponsivePXValue('width', { mobile: '85%', tablet: '90%', desktop: '100%' })}
  }

`

const ImageContainer = styled.div`
  overflow: hidden;
  
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '325px', desktop: '496px' })}
  ${ResponsivePXValue('height', { mobile: '291px', tablet: '375px', desktop: '330px' })}
`

export interface ThursdayDeliveryProps {
  onDeliverBoxClick?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  onBackClick?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
}

export function ThursdayDelivery({ onDeliverBoxClick, onBackClick }: ThursdayDeliveryProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const _handleClose = (): void => {
    if (onBackClick) {
      onBackClick()
    }
  }

  const _handleDeliverBox = async () => {
    if (onDeliverBoxClick) {
      onDeliverBoxClick()
    }
  }

  const objectFit = appData.app.deviceType === DeviceTypeEnum.MOBILE ? 'contain' : 'cover'

  const thursdayDeliveryTitle = 'Looking for weekend delivery? You can choose when you’d like to receive your order.'

  const thursdayDeliveryText = 'If you have an active Meal Kit order, you’ll be billed on Wednesday at 9am. Select Sunday or Monday delivery to receive your order three or four days after billing, or select Thursday delivery to receive your order the following Thursday (in time for weekend cooking).'

  return (
    <Container>
      <Heading align='center' variant='h5' className='title-element'>
        {thursdayDeliveryTitle}
      </Heading>
      <Spacer variant='horizontal' universal='24px' />
      <ImageContainer>
        <StaticImage
          staticId='thursday-delivery'
          mobileWidth={768}
          tabletWidth={768}
          desktopWidth={768}
          objectFit={objectFit} />
      </ImageContainer>
      <Spacer variant='horizontal' universal='24px' />
      <Paragraph variant='p3' align='center' className='text-element'>
        {thursdayDeliveryText}
      </Paragraph>
      <Spacer variant='horizontal' universal='24px' />
      <Button
        className='deliver-box-button'
        title='DELIVER MY BOX ON THURSDAYS'
        color='orange'
        fullWidth
        onClick={_handleDeliverBox} />
      <Spacer variant='horizontal' universal='16px' />
      <Link color='slate' onClick={_handleClose}>BACK</Link>
    </Container>
  )
}
