import React from 'react'

import loadable from '@loadable/component'

import { FullPageLoader } from '@client/components'

import(/* webpackPrefetch: true */ '@routes/Me')

const Me = loadable(() => import(/* webpackChunkName: "me" */'@routes/Me'), {
  resolveComponent: (components) => components.Me,
})

export function MeAsync(): JSX.Element {
  return <Me fallback={<FullPageLoader />} />

}
