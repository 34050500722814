import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, IconEnum, Paragraph, Seperator, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { TextIcon } from '@molecules/index'

export interface InfoBlockProps {
  title: string
  subTitle?: string
  text?: string[]
  icons?: { icon: IconEnum, text: string }[]
  lineBreak?: boolean
}

const Container = styled.div`

  background-color: ${(props): string => props.theme.colors.whites.alabaster};

  ${ResponsivePXValue('padding', { mobile: '20px 10px 48px', tablet: '36px 46px 76px', desktop: '34px 216px 72px' })}

`

const IconsContainer = styled.div`

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  ${(props): string => props.theme.tablet} {
    justify-content: space-between;
  }

`

export function InfoBlock({ title, subTitle, text, icons, lineBreak = false }: InfoBlockProps): JSX.Element {

  const theme = useTheme()

  let iconItem: { icon: IconEnum, text: string }
  let txt: string
  let idx: number

  return (
    <Container>
      <If condition={!!title}>
        <Heading variant='misc' align='center' color={theme.colors.slates.bitter}>{title}</Heading>
        <Spacer universal='8px' />
      </If>
      <If condition={!!lineBreak && lineBreak}>
        <Seperator align='center' />
      </If>
      <If condition={!!subTitle}>
        <Heading variant='h2' align='center'>{subTitle}</Heading>
      </If>
      <If condition={!!text}>
        <Paragraph align='center'>
          <For each='txt' index='idx' of={text}>
            <p key={idx}>{txt}</p>
          </For>
        </Paragraph>
      </If>
      <If condition={!!icons}>
        <IconsContainer>
          <For each='iconItem' index='idx' of={icons}>
            <TextIcon key={idx} {...iconItem} />
          </For>
        </IconsContainer>
      </If>
    </Container>
  )

}
