import React, { useRef } from 'react'

import styled, { useTheme, CSS } from 'styled-components'

import { Heading, ResponsiveImage } from '@atoms/index'
import { DeviceContainer, RichText, Spacer } from '@client/components'
import { getObjectFit, MediumBoxShadow, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
`

const ImageContainer = styled.div<{ $shadow: boolean }>`
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}  

  ${ResponsivePXValue('width', { mobile: '272px', desktop: '898px' })}
  ${ResponsivePXValue('max-width', { mobile: '272px', desktop: '898px' })}
  ${ResponsivePXValue('height', { mobile: '348px', desktop: '430px' })}
  ${ResponsivePXValue('max-height', { mobile: '348px', desktop: '430px' })}
  ${ResponsivePXValue('border-radius', '24px')}

`

const ContentContainer = styled.div`
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;

  ${ResponsivePXValue('margin-bottom', { mobile: '32px', desktop: '56px' })}

  .title {
    width: 60%;
  }
  .sub-title {
    width: 70%;
  }
  .description {
    width: 80%;
  }
`
export interface PageMediaCenterStandardProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaCenterStandard({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaCenterStandardProps): JSX.Element {

  const theme = useTheme()

  const objectFit = getObjectFit(pageSection)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  return (
    <Container ref={pageRef}>
      <ContentContainer>
        <If condition={!!pageSection?.title}>
          <Heading variant='h4' align='center' className='title' color={theme.colors.slates.bitter}>
            {pageSection.title}
          </Heading>
          <Spacer universal='24px' />
        </If>
        <If condition={!!pageSection?.subTitle}>
          <Heading variant='h2' align='center' className='sub-title'>
            {pageSection.subTitle}
          </Heading>
          <Spacer universal='24px' />
        </If>
        <If condition={!!pageSection.description}>
          <RichText align='center'>{pageSection.description}</RichText>
        </If>
      </ContentContainer>
      <If condition={!!pageSection?.image}>
        <ImageContainer $shadow={pageSection.imageShadow}>
          <DeviceContainer $mobile $tablet >
            <ResponsiveImage image={pageSection.image} objectFit={objectFit} />
          </DeviceContainer>
          <DeviceContainer $desktop>
            <ResponsiveImage image={pageSection.largeImage} objectFit={objectFit} />
          </DeviceContainer>
        </ImageContainer>
      </If>
    </Container>
  )
}
