import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

import { Pill } from '../../atoms/misc'

export const NavigationItemComponent = styled.a<{ $active: boolean, $emphasized: boolean, $hasPillTitle: boolean }>`

  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 2;
  font-family: 'gordita';
  align-items: center;

  display: ${(props): string => props.$hasPillTitle ? 'flex' : 'initial'};
  color: ${(props): string => props.$active ? props.theme.colors.oranges.coral : props.theme.colors.greys.liteCodGrey};
  font-weight: ${(props): number => props.$emphasized ? 700 : 500};
  &:hover {
    color: ${(props): string => props.$active ? props.theme.colors.oranges.coral : props.theme.colors.slates.celeste};
  }

  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('padding', '14px')}

  .pill {
    ${ResponsivePXValue('margin-left', '8px')}
    ${ResponsivePXValue('padding-top', '5px')}

    .title {
      ${ResponsivePXValue('font-size', '10px')}
    }
  }

  &.DEALS {
    color: ${(props): string => props.$active ? props.theme.colors.oranges.coral : props.theme.colors.misc.deals};
  }
`

export interface NavigationItemProps {
  className?: string
  id?: string
  title: React.ReactNode
  pillTitle?: string
  active?: boolean
  emphasized?: boolean
  href?: string
  onHoverChange?: (active: boolean) => void
  onClick: () => void
}

export function NavigationItem({ id, title, pillTitle = '', active = false, emphasized = false, href = '#', className, onHoverChange, onClick }: NavigationItemProps): JSX.Element {

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    onClick?.()
  }

  const isExternal = (href ?? '#').indexOf('http') !== -1
  const hasPillTitle = pillTitle !== ''

  return (
    <NavigationItemComponent
      className={`${className} ${id}`}
      $active={active}
      $emphasized={emphasized}
      $hasPillTitle={hasPillTitle}
      href={href}
      target={isExternal ? '_blank' : '_self'}
      onMouseEnter={() => onHoverChange?.(true)}
      onMouseLeave={() => onHoverChange?.(false)}
      onClick={_handleClick}>
      {title}
      <If condition={hasPillTitle}>
        <Pill
          className='pill'
          title={pillTitle}
          backgroundColor={theme.colors.yellows.selectiveYellow}
        />
      </If>
    </NavigationItemComponent>
  )
}
