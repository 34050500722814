import styled from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

const DESKTOP_WIDTH = Math.round((theme.calculationSize.desktop - (theme.pagePadding.desktop * 2) - (60)) / 3)

export const StoreCardPlaceholder = styled.div`

  display: flex;
  flex-direction: column;
  align-self: stretch;

  overflow: hidden;

  ${ResponsivePXValue('width', { mobile: '290px', tablet: '708px', desktop: `${DESKTOP_WIDTH}px` })}
  ${ResponsivePXValue('border-radius', '8px')}

`
