import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@client/components'
import { SectionLoading } from '@client/components/molecules'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, CartItemFragment, FrozenMealCartFragment, WineCartFragment, MarketProductCartFragment } from '@hooks/api'
import { FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'

import { AddOnCartItem } from './AddOnCartItem'
import { GiftCartItem } from './GiftCartItem'

const Container = styled.div`
  ${ResponsivePXValue('padding', '8px 0px')}
`

export function CartAddOns(): JSX.Element {

  const config = useConfig()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const isOnDemand = cartData?.currentUser?.isOnDemand

  let cartItem: CartItemFragment

  const getFrozenServing = (cartItem: { frozenPortionSize: FrozenPortionSizeEnum }): string => {
    switch (cartItem?.frozenPortionSize) {
      case FrozenPortionSizeEnum.SERVES_ONE:
        return 'Serves 1'
      case FrozenPortionSizeEnum.SERVES_TWO:
        return 'Serves 2'
      case FrozenPortionSizeEnum.SERVES_FOUR:
        return 'Serves 4'
    }
  }

  const cartItems = [...cartData?.currentUser?.activeCart?.cartItems || []]
    .reverse()
    .filter(cartItem => !cartItem.isFromSubscription)

  return (
    <Container>
      <Choose>
        <When condition={cartLoading}>
          <SectionLoading loaderSize='24px' />
        </When>
        <Otherwise>
          <For each='cartItem' of={cartItems}>
            <Choose>
              <When condition={cartItem.product.__typename === 'FrozenMeal'}>
                <AddOnCartItem
                  key={cartItem.id}
                  subtitle={`By ${(cartItem.product as FrozenMealCartFragment)?.frozenMealChef?.name}, ${getFrozenServing(cartItem.product as unknown as { frozenPortionSize: FrozenPortionSizeEnum })}`}
                  item={cartItem}
                  product={cartItem.product as FrozenMealCartFragment}
                  userIsOnDemand={isOnDemand}
                  showVitalityTag={!!cartItem?.product?.group?.discoveryHealthyDiningItem} />
              </When>
              <When condition={cartItem.product.__typename === 'Wine'}>
                <AddOnCartItem
                  key={cartItem.id}
                  subtitle={(cartItem.product as WineCartFragment)?.vineyard?.name}
                  item={cartItem}
                  product={cartItem.product as WineCartFragment}
                  userIsOnDemand={isOnDemand} />
              </When>
              <When condition={cartItem.product.__typename === 'MarketProduct'}>
                <AddOnCartItem
                  key={cartItem.id}
                  subtitle={(cartItem.product as MarketProductCartFragment)?.brand?.name}
                  item={cartItem}
                  product={cartItem.product as MarketProductCartFragment}
                  userIsOnDemand={isOnDemand} />
              </When>
              <When condition={cartItem.product.__typename === 'Gift'}>
                <GiftCartItem
                  key={cartItem.id}
                  item={cartItem} />
              </When>
              <Otherwise>
                <Paragraph variant='p2'>{'Unknown cart item'}</Paragraph>
              </Otherwise>
            </Choose>
          </For>
        </Otherwise>
      </Choose>
    </Container>
  )

}
