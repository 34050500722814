import React, { Fragment } from 'react'

import styled from 'styled-components'

import { ToastHeader } from '@molecules/index'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  position: sticky;
  top: 0;
`

const Content = styled.div`
  flex: 1;
`

interface WithoutNavigationProps {
  seo?: SearchEngineOptimizationParams
  children: JSX.Element | JSX.Element[]
}

export function WithoutNavigation({ seo, children }: WithoutNavigationProps): JSX.Element {

  return (
    <Fragment>
      <If condition={!!seo}>
        <SearchEngineOptimization seo={seo} />
      </If>
      <Container>
        <Header>
          <ToastHeader />
        </Header>
        <Content>
          {children}
        </Content>
      </Container>
    </Fragment>
  )

}
