import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { ResponsiveImage, Button, Spacer, Heading, Paragraph } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCardContainer, ProductCardImageContainer, ProductCardContentContainer } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useUserDetailsQuery, UserCartDocument, useChangeCartItemQuantityMutation, useAddItemToCartMutation, useRemoveItemFromCartMutation, FrozenMealDishListFragment, FrozenMealListFragment } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { getCraftMealPriceRange } from '@molecules/index'
import { AddToCartModal, AddToCartProductTypeEnum, LowStockContainer } from '@organisms/index'
import { FrozenPortionSizeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'

const Container = styled.div`
  ${ProductCardContainer}

  .flex-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
  .serving-size {
    display: flex;
    flex: 0;
    margin: 0;
  }
`
export interface CraftPizzaCardProps {
  craftPizza: FrozenMealDishListFragment
  className?: string
  loading?: boolean
}

interface CraftPizzaCardState {
  modalOpen: boolean
  addToCartInterrupted: boolean
  loading: boolean
  servingSize: FrozenPortionSizeEnum
}

const DEFAULT_STATE: CraftPizzaCardState = {
  modalOpen: false,
  addToCartInterrupted: false,
  loading: false,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,

}

export function CraftPizzaCard({ craftPizza, className, loading = false }: CraftPizzaCardProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<CraftPizzaCardState>({ ...DEFAULT_STATE })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const pdpLink = `/frozen/craft-pizza/${craftPizza?.slug}`
  const priceRange = getCraftMealPriceRange(craftPizza)
  const price = `R${priceRange.lower}`
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const events = useEvents()

  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const isAvailable = (): boolean => {
    let available = true
    for (let i = 0; i < craftPizza.products.length; i++) {
      if (craftPizza.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    return available
  }

  const _handleOnAdd = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => ({ ...prevState, addToCartInterrupted: noDefaultAddress || !isAvailable() }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (isAvailable()) {
      setLoading(true)
      const product = craftPizza?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })

      try {
        await addItemToCart({
          variables: { productId: product.id, quantity: 1 },
        })

        const logData = {
          itemName: craftPizza?.name,
          itemId: product?.id,
          itemGroupId: craftPizza?.id,
          price: product?.price,
          itemBrand: 'UCOOK',
          itemCategory: craftPizza?.frozenCategories?.map((cat) => cat.id)?.join(', '),
          itemVariant: craftPizza?.frozenCategories?.map((cat) => cat.title)?.join(', '),
          itemListName: 'Craft Meals',
          quantity: 1,
          itemImage: craftPizza?.coverImage?.location,
          itemStockCount: product?.stockCount > 1 ? 1 : 0,
          itemChef: 'UCOOK',
          isMealkit: 'no',
          itemServingSize: 1,
        }

        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
        events.hasAddedToCart(snakedData)

        addToast('Item successfully added to cart', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setLoading(false)

    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }

  const _handleOnRemove = async (): Promise<void> => {

    setLoading(true)
    const product = craftPizza?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })

    try {
      if (product.quantityInCart === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
        })
        setState((prevState) => ({ ...prevState, currentAmountInCart: 0 }))

      } else {

        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: product.quantityInCart - 1,
          },
        })
      }

      const logData = {
        itemName: craftPizza?.name,
        itemId: product?.id,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemCategory: craftPizza?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: craftPizza?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity: 1,
        itemImage: craftPizza?.coverImage?.location,
        itemStockCount: product?.stockCount > 1 ? 1 : 0,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (product.quantityInCart === 0) {
      _handleOnAdd()
    }
  }

  const _handleModalClose = (): void => {
    setState((prevState) => ({ ...prevState, modalOpen: false }))
  }
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  let notAvailable = false
  let buttonTitle = 'ADD TO CART'
  let craftPizzaProduct: FrozenMealListFragment

  for (let i = 0; i < craftPizza?.products.length; i++) {
    if (craftPizza?.products[i].frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE) {
      craftPizzaProduct = craftPizza?.products[i]
    }
  }

  if (craftPizzaProduct?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
    notAvailable = true
    buttonTitle = 'OUT OF STOCK'
  } else if (craftPizzaProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
    buttonTitle = 'GET STARTED'
  } else if (craftPizzaProduct?.availability !== ProductAvailabilityEnum.AVAILABLE) {
    notAvailable = true
    buttonTitle = 'NOT AVAILABLE'
  }

  const product = craftPizza?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })

  return (
    <Container className={className}>
      <AddToCartModal
        open={state.modalOpen}
        onClose={_handleModalClose}
        productType={AddToCartProductTypeEnum.FROZEN}
        product={craftPizza}
        price={price} />
      <If condition={craftPizza?.upSellText?.length > 0}>
        <FloatingUpSell text={craftPizza.upSellText} />
      </If>
      <LinkContainer onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={craftPizza.coverImage} lazy={false} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer className='fluid-grow'>
        <Heading variant='h6' className='card-title'> {craftPizza.name} </Heading>
        <Spacer universal='4px' />
        <Paragraph variant='p2' className='card-sub-title'>{craftPizza.description} </Paragraph>
        <Spacer universal='4px' />
        <LowStockContainer product={product}>
          <Heading variant='h5'> {price} </Heading>
        </LowStockContainer>
        <Spacer universal='16px' className='fluid-grow' />
        <Button
          className='add-button'
          title={buttonTitle}
          color='black'
          fullWidth
          loading={loading || state.loading}
          disabled={loading || notAvailable}
          amount={product?.quantityInCart}
          onClick={_handleOnCenterClick}
          onLeftIconClick={_handleOnRemove}
          onRightIconClick={_handleOnAdd} />
      </ContentContainer>
    </Container>
  )
}
