import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MealKitStore')

const MealKitStore = loadable(() => import(/* webpackChunkName: "meal-kit-store" */'@pages/MealKitStore'), {
  resolveComponent: (components) => components.MealKitStore,
})

export function MealKitStoreAsync(): JSX.Element {

  return <MealKitStore fallback={<div>Loading...</div>} />

}
