import React from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { Heading, Icon, IconEnum } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $showBackground: boolean, $variant: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.$showBackground ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};
  ${ResponsivePXValue('border-radius', '6px')}
  ${ResponsivePXValue('margin-right', '7px')}
  ${(props): CSS => {
    if (props.$variant === CategoryTagVariant.CARD) {
      return ResponsivePXValue('padding', '4px 7px')
    } else {
      return ResponsivePXValue('padding', '8px 7px')
    }
  }}

  div {
    margin: 0;
    padding: 0;
    position: relative;
    ${ResponsivePXValue('top', '1px')}
  }
`

const IconLeft = styled.img`
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
  ${ResponsivePXValue('margin-right', '4px')}
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('margin-left', '12px')}
`

export enum CategoryTagVariant {
  CARD = 'CARD',
  FILTER = 'FILTER'
}
export interface CategoryTagProps {
  image?: string
  title: string
  showBackground?: boolean
  className?: string
  variant?: CategoryTagVariant
  id?: string
  showIcon?: boolean
  onCloseClick?: (id: string) => void
}

export function CategoryTag({ image, title, showBackground = true, className, variant = CategoryTagVariant.CARD, id, showIcon = false, onCloseClick }: CategoryTagProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container $showBackground={showBackground} className={className} $variant={variant}>
      <If condition={showIcon}>
        <IconLeft src={image} />
      </If>
      <Heading variant='h6' color={theme.colors.slates.bitter}>{title}</Heading>
      <If condition={variant === CategoryTagVariant.FILTER}>
        <IconContainer onClick={() => { onCloseClick(id) }}>
          <Icon icon={IconEnum.CLOSE_OUTLINE} color={theme.colors.slates.bitter} />
        </IconContainer>
      </If>
    </Container>
  )

}
