import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/SuppliersStory')

const SuppliersStory = loadable(() => import(/* webpackChunkName: "suppliers-story" */'@pages/SuppliersStory'), {
  resolveComponent: (components) => components.SuppliersStory,
})

export function SuppliersStoryAsync(): JSX.Element {

  return <SuppliersStory fallback={<div>Loading...</div>} />

}
