import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph, ResponsiveImage, Rule, Heading, Button } from '@atoms/index'
import { Ellipsis, MediumBoxShadow, ResponsivePXValue } from '@components/Theme'
import { SupplierFragment } from '@hooks/api'

import { SectionLoading } from '../content'

const Container = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${MediumBoxShadow}

  ${ResponsivePXValue('width', { mobile: '288px', tablet: '317px', desktop: '346px' })}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('margin', { mobile: '24px 0', tablet: '0 24px', desktop: '0 24px' })}

`

const ImageContainer = styled.div`

  ${ResponsivePXValue('width', { mobile: '288px', tablet: '317px', desktop: '346px' })}
  ${ResponsivePXValue('height', { mobile: '192px', tablet: '212px', desktop: '232px' })}

`

const DescriptionContainer = styled.div`

  ${ResponsivePXValue('padding', '0 24px')}

`

const DescriptionText = styled.div`
  div {
    ${Ellipsis(4)}
    ${ResponsivePXValue('height', '90px')}
  }
`

const HeaderContainer = styled.div`
  h5 {
    ${ResponsivePXValue('padding', '24px 0 0')}
  }
`

export interface SupplierCardProps {
  supplier: SupplierFragment
  loading?: boolean
  className?: string
  onShowDetails?: (slug: string) => void
}

export function SupplierCard({ supplier, loading = false, className, onShowDetails }: SupplierCardProps): JSX.Element {

  const theme = useTheme()

  const _handleShowDetails = (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>): void => {
    e?.preventDefault()
    onShowDetails?.(supplier.slug)
  }

  return (
    <Container className={className}>
      <Choose>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <Otherwise>
          <HeaderContainer onClick={_handleShowDetails}>
            <Heading variant='h5' align='center'>{supplier.name}</Heading>
          </HeaderContainer>
          <If condition={!!supplier.subtitle && !!supplier.location} >
            <Paragraph align='center' transform={'capitalize'} color={theme.colors.slates.bitter}>{`${supplier.subtitle} - ${supplier.location} `} </Paragraph>
          </If>
          <If condition={!!supplier.shortDescription || !!supplier.slug}>
            <Rule />
          </If>
          <DescriptionContainer>
            <DescriptionText>
              <Paragraph align='center'>
                {supplier.shortDescription}
              </Paragraph>
            </DescriptionText>
            <If condition={!!supplier.slug}>
              <Paragraph align='center'>
                <Button variant='secondary' color='orange' displayBorder={false} title='READ MORE' fullWidth onClick={_handleShowDetails}/>
              </Paragraph>
            </If>
          </DescriptionContainer>
          <If condition={!!supplier.bannerImage}>
            <ImageContainer onClick={_handleShowDetails}>
              <ResponsiveImage image={supplier.bannerImage} />
            </ImageContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )
}
