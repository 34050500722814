import { gql, ApolloQueryResult, DocumentNode, ApolloClient, NormalizedCacheObject, OperationVariables } from '@apollo/client'

import { parseNumber, CountryCode, ParsedNumber } from 'libphonenumber-js'

import { ActiveCampaignWinesDocument, ActiveMenuDishesDocument, CartFragment, GetAllFrozenMealDishesDocument, GetAllMarketProductsDocument, UserCartDocument, UserCartQuery, UserCheckoutStatusFragment, UserDetailsDocument } from '@hooks/api'

import countryCodes from './countrycodes'
export class SiteHelper {

  static getCheckoutRefetchQueries(): { query: DocumentNode }[] {
    return [{
      query: UserDetailsDocument,
    }, {
      query: UserCartDocument,
    }]
  }

  static getUserRefetchQueries(): { query: DocumentNode, variables?: OperationVariables }[] {
    return [{
      query: UserDetailsDocument,
    }, {
      query: UserCartDocument,
    }, {
      query: GetAllFrozenMealDishesDocument,
    }, {
      query: ActiveCampaignWinesDocument,
    }, {
      query: ActiveMenuDishesDocument,
    }, {
      query: GetAllMarketProductsDocument,
    }]
  }

  static getCountryCode(): string {
    if (typeof window !== 'undefined' && window.sessionStorage.getItem('lookup')) {
      return JSON.parse(window.sessionStorage.getItem('lookup')).countryCode
    }
    return 'ZA'
  }

  static getTelPrefixForCountryCode(countryCode: string): string {
    return countryCodes[countryCode].code
  }

  static getCountryCodeForTelPrefix(telPrefix: string): CountryCode {
    let countryCode = this.getCountryCode()
    Object.keys(countryCodes).forEach((country) => {
      const countryInfo = countryCodes[country]
      if (countryInfo.code === telPrefix) {
        countryCode = country
      }
    })
    return countryCode as CountryCode
  }

  static getCountryCodes(): { [k: string]: { name: string, code: string } } {
    return countryCodes
  }

  static validateEmail(value: string): boolean {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  static validateInt(value: string): boolean {
    return /\d*/.test(value)
  }

  static validateAndSubmitFormOnEnter(event: React.KeyboardEvent<HTMLInputElement>, form: any): void {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      form.validateFields().then(() => {
        form.submit()
      })
    }
  }

  static validatePhone(value: string): boolean {
    try {
      const values = value.split(' ')
      const prefix = this.getCountryCodeForTelPrefix(values.shift())
      const number = values.join(' ')
      if (!number.trim()) {
        return false
      }
      if ((parseNumber(value, prefix) as ParsedNumber)?.phone !== undefined) {
        return true
      }
    } catch (e) {
      return false
    }
  }

  static scrollTo(to: number, duration: number, element: any = false): Promise<void> {
    return new Promise((resolve): void => {
      if (typeof window === 'undefined') {
        return resolve()
      } else {
        const doc = document.documentElement
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        if (top === 0) {
          return resolve()
        }
      }
      let start: number
      if (!element) {
        start = window.scrollY
      } else {
        start = element.scrollTop
      }
      const change = to - start
      const increment = 20
      const animateScroll = (elapsedTime: number): void => {
        elapsedTime += increment
        const position = this.easeInOut(elapsedTime, start, change, duration)
        if (!element) {
          window.scrollTo(0, position)
        } else {
          element.scrollTop = position
        }
        if (elapsedTime < duration) {
          setTimeout(() => {
            animateScroll(elapsedTime)
          }, increment)
        } else {
          resolve()
        }
      }
      animateScroll(0)
    })
  }

  static easeInOut(currentTime: number, start: number, change: number, duration: number): number {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return change / 2 * currentTime * currentTime + start
    }
    currentTime -= 1
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
  }

  static async updateLocalWineStock(): Promise<void> {
    try {
      // TODO
    } catch (e) {
      console.log(e.stack)
    }
  }

  static async updateLocalFrozenMealStock(): Promise<void> {
    try {
      // TODO
    } catch (e) {
      console.log(e.stack)
    }
  }

  static quantityOfItemInCart(id: string, cart: CartFragment): number {
    const cartItem = cart?.cartItems?.find((cartItem) => cartItem.product.id === id)
    return cartItem?.quantity || 0
  }

  static productIsInCart(id: string, cart: CartFragment): boolean {
    if (cart?.cartItems?.findIndex((cartItem) => cartItem.product.id === id) !== -1) {
      return true
    }
    return false
  }

  static clone(data: any): any {
    return JSON.parse(JSON.stringify(data))
  }

  static parseFilters(filters: any): any {
    const response: any = {}
    filters.forEach((filter: any, key: string) => {
      response[key] = {}
      filter.enumValues.forEach((enumValue: any) => {
        response[key][enumValue.name] = enumValue.description
      })
    })
    return response
  }

  static randomString(size: number): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let randomString = ''
    for (let x = 0; x < size; x++) {
      const charIndex = Math.floor(Math.random() * characters.length)
      randomString += characters.substring(charIndex, charIndex + 1)
    }
    return randomString
  }

  static stripGraphQLTypes(input: any, stripIds = true): any {
    const newData = this.clone(input)
    const stripTypes = (data: any): void => {
      if (Array.isArray(data)) {
        data.forEach((item) => stripTypes(item))
      } else if (typeof data === 'object' && data) {
        Object.keys(data).forEach((key) => {
          if (key === '__typename') {
            delete data[key]
          } else if (key === 'id' && stripIds) {
            delete data[key]
          } else {
            stripTypes(data[key])
          }
        })
      }
    }
    stripTypes(newData)
    return newData
  }

  static async validateObjectForMutationInput(client: ApolloClient<NormalizedCacheObject>, object: { [k: string]: any }, inputName: string): Promise<{ [k: string]: any }> {
    const qry = gql`
      query GetDetailedInputParams($name: String!) {
        attributes: getDetailedInputObjectConfig(name: $name)
      }
    `
    const result: ApolloQueryResult<any> = await client.query({
      query: qry,
      variables: { name: inputName },
    })
    const attributes = result.data.attributes as { [k: string]: any }
    return this.parseDataFromObject(attributes, object)
  }

  static parseDataFromObject(object: { [k: string]: any }, data?: { [k: string]: any }): { [k: string]: any } {
    if (!data) {
      return
    }
    const responseObject: { [k: string]: any } = {}
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (object[key]) {
        switch (true) {
          case Array.isArray(object[key].type):
            if (typeof object[key].type[0] === 'object') {
              responseObject[key] = []
              for (let v = 0; v < value.length; v++) {
                responseObject[key].push(this.parseDataFromObject(object[key].type[0], value[v]))
              }
            } else {
              // TODO: recurse and validate for scalar array types
              responseObject[key] = []
              for (let v = 0; v < value.length; v++) {
                responseObject[key].push(value[v])
              }
            }
            break
          case typeof object[key].type === 'object':
            responseObject[key] = this.parseDataFromObject(object[key].type, value)
            break
          case object[key].type === 'Int':
            responseObject[key] = parseInt(value)
            break
          case object[key].type === 'Float':
          case object[key].type === 'Double':
            responseObject[key] = parseFloat(value)
            break
          default:
            responseObject[key] = value
            break
        }
      }
    })
    return responseObject
  }

  static userHasSavedSubscription(userCartData: UserCartQuery): boolean {
    if (!userCartData) {
      return true
    }
    if (!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
      return true
    }
    if (userCartData?.currentUser?.__typename === 'GuestUser') {
      return true
    }
    return userCartData?.currentUser?.checkoutStatus?.subscriptionIsSaved
  }

  // TODO: Remove this function
  static getCheckoutStatus(checkoutStatus: UserCheckoutStatusFragment): UserCheckoutStatusFragment {
    return checkoutStatus || {
      __typename: 'UserCheckoutStatus',
      hasAccount: false,
      hasConfiguredBox: false,
      hasOnDemandProducts: false,
      hasActiveSubscription: false,
      hasPausedSubscription: false,
      hasValidSubscription: false,
      subscriptionIsSaved: false,
      hasAcceptedPriceDifference: false,
      hasAcceptedCookWithInDays: false,
      hasSelectedPlan: false,
      hasSetDeliveryAddress: false,
      hasSetDeliveryAddressDetails: false,
      hasSetPaymentMethod: false,
      hasSelectedDeliveryDay: false,
      hasSelectedPortionSize: false,
    }
  }

  static generateToken(): string {
    return `${this.randomString(6)}-${this.randomString(6)}-${this.randomString(6)}`
  }

  static toTitleCase(input: string): string {
    if (input) {
      return input.replace(
        /\w\S*/g,
        function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        },
      )
    }
  }

}

export function memoize<A extends unknown[], R>(fun: (...args: A) => R): (...args: A) => R {
  console.log('IN MEMO')
  const cache: { [k: string]: R } = {}
  console.log(Object.keys(cache).length)
  return (...args: A) => {
    const stringifiedArgs = JSON.stringify(args)
    if (typeof cache[stringifiedArgs] === 'undefined') {
      cache[stringifiedArgs] = fun(...args)
    }
    return cache[stringifiedArgs]
  }
}
