import { useEffect, useRef, MutableRefObject } from 'react'

interface OptionsInterface {
  readonly root: Element | Document | null
  readonly rootMargin: string
  readonly thresholds: ReadonlyArray<number>
}

export const useOnScreen = (callback: () => void): MutableRefObject<HTMLDivElement | null> => {
  const divRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const options: OptionsInterface = {
      root: null,
      rootMargin: '0px',
      thresholds: [0.1], // Adjust this threshold as needed
    }

    let isIntersecting = false

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (!isIntersecting) {
            isIntersecting = true
            callback()
          }
        } else {
          // console.log('Div is not on the screen!')
        }
      })
    }, options)

    if (divRef.current) {
      observer.observe(divRef.current)
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [callback])

  return divRef
}
