import React from 'react'

import styled, { css, useTheme, CSS } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const SeperatorContainer = styled.div<{ $align: 'left' | 'center' | 'right' }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props): string => {
    switch (props.$align) {
      case 'center':
        return 'center'
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
    }
  }};

  ${ResponsivePXValue('margin-bottom', '30px')}

`
const Small = css`

  ${ResponsivePXValue('width', '58px')}
  ${ResponsivePXValue('height', '3px')}

`

const Medium = css`

  ${ResponsivePXValue('width', '115px')}
  ${ResponsivePXValue('height', '3px')}

`

const Large = css`

  ${ResponsivePXValue('width', '230px')}
  ${ResponsivePXValue('height', '3px')}

`

const SeperatorElement = styled.div<{ $size: 'small' | 'medium' | 'large', $color: string }>`
  background-color: ${(props): string => props.$color};
  ${(props): CSS => props.$size === 'small' ? Small : null};
  ${(props): CSS => props.$size === 'medium' ? Medium : null};
  ${(props): CSS => props.$size === 'large' ? Large : null};
`

export interface SeperatorProps {
  size?: 'small' | 'medium' | 'large'
  color?: 'orange' | 'grey' | 'slate'
  align?: 'left' | 'center' | 'right'
}

export function Seperator({ size = 'small', color = 'orange', align = 'left' }: SeperatorProps): JSX.Element {

  const theme = useTheme()

  let actualColor = theme.colors.oranges.coral

  if (color === 'grey') {
    actualColor = theme.colors.greys.tundora
  } else if (color === 'slate') {
    actualColor = theme.colors.slates.celeste
  }

  return (
    <SeperatorContainer $align={align}>
      <SeperatorElement $size={size} $color={actualColor} />
    </SeperatorContainer>
  )

}
