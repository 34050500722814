import React from 'react'

import styled from 'styled-components'

import { Icon, Paragraph } from '@client/components'
import { Spacer, IconEnum } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@client/components/Theme'

const Container = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.greys.mineshaft};
  ${ResponsivePXValue('padding', '8px 16px')}
  ${ResponsiveProperty('justify-content', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
`

const IconContainer = styled.div`
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('width', '24px')}
`

export interface NotificationProps {
  text: string
  textColor?: string
  backgroundColor?: string
  icon?: IconEnum
  iconColor?: string
  className?: string
  iconClassName?: string
  textClassName?: string
  align?: 'left' | 'right' | 'center'
}

export function Notification({ text, textColor = theme.colors.whites.pureWhite, backgroundColor, icon, iconColor, className, iconClassName, textClassName, align = 'left' }: NotificationProps): JSX.Element {

  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      <If condition={!!icon}>
        <IconContainer>
          <Icon className={iconClassName} icon={icon} color={iconColor || textColor} />
        </IconContainer>
        <Spacer universal='16px' variant='horizontal' />
      </If>
      <Paragraph className={textClassName} variant='p1' color={textColor} align={align}>{text}</Paragraph>
    </Container>
  )
}
