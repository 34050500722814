import React from 'react'

import styled, { CSS } from 'styled-components'

import { theme } from '@client/components/Theme'
import { Icon, IconEnum, ResponsivePXValue } from '@components/index'
import { FilterItemProps, FilterPill, FilterSectionProps } from '@molecules/index'

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .filter-pill {
    margin-bottom: 0 !important;
    ${ResponsivePXValue('margin-right', '8px')}
  }
`
const IconContainer = styled.div<{ $onlySlide: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('padding', '0 4px 2px 18px')}
  z-index: 100;
  background: ${(props): string => props.theme.colors.whites.pureWhite};

   ${(props): CSS => {
    if (props?.$onlySlide) {
      return `
        opacity: 0;
        z-index: -1;
      `
    }
  }}
`

const NotificationBubble = styled.div`
 position: absolute;
 border-radius: 50%;

 background: ${(props): string => props.theme.colors.oranges.burntSienna};

 ${ResponsivePXValue('top', '-4px')}
 ${ResponsivePXValue('right', '-4px')}
 ${ResponsivePXValue('width', '10px')}
 ${ResponsivePXValue('height', '10px')}
`

const FilterPillContainer = styled.div<{ $onlySlide: boolean}>`
  display: flex;
  flex: 1;
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  ${ResponsivePXValue('padding-left', '14px')}
  ${ResponsivePXValue('left', '56px')}

  ${(props): CSS => {
    if (props?.$onlySlide) {
      return `
        ${ResponsivePXValue('left', '0 !important')}
      `
    }
  }}
`

export interface FilterSliderProps {
  id?: string
  sections: FilterSectionProps[]
  selectedFilters?: { [k: string]: string[] }
  onChange?: (SliderName: string, selectedValues: string[]) => void
  onClick?: () => void
  isFilterApplied?: boolean
  reset?: boolean
  changeReset?: boolean
  className?: string
  iconClassName?: string
  slides?: JSX.Element
}

export function FilterSlider(props: FilterSliderProps): JSX.Element {

  const {
    sections,
    selectedFilters,
    isFilterApplied,
    onChange,
    onClick,
    reset,
    changeReset,
    className,
    iconClassName,
    slides,
  } = props

  const _handleChange = (sectionName: string, selectedValue: string) => {

    if (onChange) {

      if (Object.keys(selectedFilters).length === 0) {
        sections.forEach(section => {
          const selectedSectionFilter = section.items.filter(item => item.selected).map(item => item.title)
          selectedFilters[section.title] = selectedSectionFilter
        })
      }

      if (typeof selectedFilters[sectionName] === 'undefined') {
        selectedFilters[sectionName] = []
      }

      let selectedValues: string[] = []
      const isNewValueSelected = selectedFilters[sectionName].includes(selectedValue)

      if (isNewValueSelected) {
        selectedValues = selectedFilters[sectionName].filter(value => value !== selectedValue)
      } else {
        selectedValues = [...selectedFilters[sectionName], selectedValue]
      }

      onChange(sectionName, selectedValues)
    }
  }

  const _handleOnClick = (): void => {
    if (onClick) {
      onClick()
    }
  }

  const _getItemValue = (item: FilterItemProps): string => {
    return item?.id ? item.id : item.title
  }

  const _getBackgroundColor = (section: FilterSectionProps, item: FilterItemProps): string => {
    if (reset) {
      return theme.colors.whites.pureWhite
    } else if (isFilterApplied || changeReset) {
      if (typeof selectedFilters[section.id] === 'undefined') {
        selectedFilters[section.id] = []
      }
      return selectedFilters[section.id]?.includes(_getItemValue(item)) ? theme.colors.slates.bitter : theme.colors.whites.pureWhite
    }
    return item?.selected ? theme.colors.slates.bitter : theme.colors.whites.pureWhite

  }

  const _getTextColor = (section: FilterSectionProps, item: FilterItemProps): string => {
    if (reset) {
      return theme.colors.greys.mineshaft
    } else if (isFilterApplied || changeReset) {

      if (typeof selectedFilters[section.id] === 'undefined') {
        selectedFilters[section.id] = []
      }

      return selectedFilters[section.id]?.includes(_getItemValue(item)) ? theme.colors.whites.pureWhite : theme.colors.greys.mineshaft
    }
    return item?.selected ? theme.colors.whites.pureWhite : theme.colors.greys.mineshaft

  }

  const onlySlide = slides && sections.length === 0

  let section: FilterSectionProps
  let item: FilterItemProps

  return (
    <Container className={className}>
      <Choose>
        <When condition={onlySlide}>
          <IconContainer $onlySlide={onlySlide}></IconContainer>
        </When>
        <Otherwise>
          <IconContainer onClick={_handleOnClick} $onlySlide={onlySlide}>
            <If condition={isFilterApplied}>
              <NotificationBubble></NotificationBubble>
            </If>
            <Icon className={iconClassName} icon={IconEnum.OPTIONS_OUTLINE} color={theme.colors.greys.mineshaft} />
          </IconContainer>
        </Otherwise>
      </Choose>
      <FilterPillContainer className='filter-pill-container' $onlySlide={onlySlide}>
        <Choose>
          <When condition={!!slides}>
            {slides}
          </When>
          <Otherwise>
            <>
              <For each='section' of={sections}>
                <For each='item' of={section.items}>
                  <FilterPill
                    id={_getItemValue(item)}
                    key={_getItemValue(item)}
                    sectionId={section.id}
                    onRemoveFilter={_handleChange}
                    className='filter-pill'
                    title={item.title}
                    disabled={item.disabled}
                    backgroundColor={_getBackgroundColor(section, item)}
                    textColor={_getTextColor(section, item)} />
                </For>
              </For>
            </>
          </Otherwise>
        </Choose>
      </FilterPillContainer>
    </Container>
  )
}
