import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Button, Heading, HeadingAlignment, Link, Paragraph, ParagraphAlignment, Spacer } from '@atoms/index'
import { ResponsivePXValue, ResponsiveProperty } from '@client/components/Theme'
import { DeviceContainer, scrollToLink } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { Modal } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('width', { desktop: '576px' })}
`

const ActionContainer = styled.div<{ $hasSecondaryAction: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

   ${(props): CSS => props.$hasSecondaryAction ? ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' }) : ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'column' })};

  .action {
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '45%' })}
  }

  .no-thanks{
    ${ResponsivePXValue('margin-top', { mobile: '12px', desktop: '16px' })};
  }
`

export interface MarketMessageModalProps {
  open?: boolean
  title: string
  description: string
  primaryActionTitle: string
  primaryActionLink?: string
  onPrimaryActionClick?: () => void
  secondaryActionTitle?: string
  secondaryActionLink?: string
  onSecondaryActionClick?: () => void
  onClose?: () => void
}

export function MarketMessageModal({
  open, title, description,
  primaryActionTitle, primaryActionLink, onPrimaryActionClick,
  secondaryActionLink, secondaryActionTitle, onSecondaryActionClick,
  onClose,
}: MarketMessageModalProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const _handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const _redirectToLink = (url: string): void => {
    if (url && config.isBrowser()) {
      _handleClose()
      if (url.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = url
      } else if (url.includes('#')) {
        scrollToLink(url)
      } else {
        navigate(url)
      }
    }
  }

  const _handlePrimaryActionClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (onPrimaryActionClick) {
      onPrimaryActionClick()
    } else if (primaryActionLink) {
      _redirectToLink(primaryActionLink)
    }
  }

  const _handleSecondaryActionClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (onSecondaryActionClick) {
      onSecondaryActionClick()
    } else if (secondaryActionLink) {
      _redirectToLink(secondaryActionLink)
    }
  }

  const hasSecondaryAction = !!secondaryActionTitle
  const getTitle = (align: HeadingAlignment): JSX.Element => <Heading variant='h2' align={align}> {title} </Heading>
  const getDescription = (align: ParagraphAlignment): JSX.Element => <Paragraph variant='p1' align={align}> {description}</Paragraph>

  return (
    <Modal
      open={open}
      fullscreen={false}
      onClose={_handleClose}
      allowBackgroundClose={false}>
      <Container>
        <DeviceContainer $mobile $tablet>
          {getTitle('left')}
        </DeviceContainer>
        <DeviceContainer $desktop>
          {getTitle('center')}
        </DeviceContainer>
        <Spacer mobile='12px' desktop='16px' />
        <DeviceContainer $mobile $tablet>
          {getDescription('left')}
        </DeviceContainer>
        <DeviceContainer $desktop>
          {getDescription('center')}
        </DeviceContainer>
        <Spacer universal='24px' />
        <ActionContainer $hasSecondaryAction={hasSecondaryAction}>
          <Button
            className='action'
            color='black'
            title={primaryActionTitle}
            onClick={_handlePrimaryActionClick} />
          <If condition={hasSecondaryAction}>
            <DeviceContainer $mobile $tablet>
              <Spacer mobile='16px' />
            </DeviceContainer>
            <DeviceContainer $desktop>
              <Spacer desktop='16px' variant='horizontal' />
            </DeviceContainer>
            <Button
              className='action'
              color='black'
              variant='secondary'
              title={secondaryActionTitle}
              onClick={_handleSecondaryActionClick} />
          </If>
          <Link
            className='no-thanks'
            variant='l2'
            decoration='underline'
            onClick={_handleClose}>
            No thanks
          </Link>
        </ActionContainer>
      </Container>
    </Modal>
  )
}
