import React from 'react'
import styled, { CSS } from 'styled-components'
import { SelectInput, SmallLoader } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'



const MobileContainer = styled.div<{ $height: string }>`
  width: 100%;
  ${(props): CSS => ResponsivePXValue('height', props.$height)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`

export interface SectionLoadingProps {
  height?: string
  loaderSize?: string
}

export function MealKitCardPortionSizeSelector({ height = '20px' }: SectionLoadingProps): JSX.Element {

  return (
    <MobileContainer $height={height}>
      <SelectInput
        name='numberOfPortions'
        apiEnum='NumberOfPortionsEnum'
        className='dropdown' />
    </MobileContainer>
  )

}
