import React from 'react'

import styled from 'styled-components'

import { Paragraph, ResponsivePXValue } from '@client/components'
import { Modal } from '@client/components/molecules'
import { CheckoutStepEnum } from '@uctypes/api/globalTypes'

import { SaveMenu, ActivateSubscription, InvalidSubscription, ReactivateSubscription, CartErrors } from '../checkout/index'
import { VerifyNumber } from '../checkout/modals/VerifyNumber'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('min-width', { mobile: '288px', tablet: '360px', desktop: '496px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '12px 24px', desktop: '32px 40px' })}

  .text {
    ${ResponsivePXValue('margin-bottom', '24px')}
  }
`

export interface CheckoutModalProps {
  open: boolean
  step: CheckoutStepEnum
  onClose: () => void
}

export function CheckoutModal({ open, step, onClose }: CheckoutModalProps): JSX.Element {

  let title = 'PLEASE WAIT'
  switch (step) {
    case CheckoutStepEnum.REACTIVATE:
      title = 'Activate your subscription'
      break
    case CheckoutStepEnum.VALIDATION:
      title = 'ADD MORE DISHES'
      break
    case CheckoutStepEnum.CART_ERRORS:
      title = 'CHECKOUT ERRORS'
      break
    case CheckoutStepEnum.VERIFY_NUMBER:
      title = 'VERIFY YOUR NUMBER'
      break
  }

  const allowClose = step !== CheckoutStepEnum.REACTIVATE

  return (
    <Modal
      open={open}
      showCloseButton={allowClose}
      allowBackgroundClose={allowClose}
      title={title}
      fullscreen={false}
      onClose={onClose}>
      <ModalContainer>
        <Choose>
          <When condition={step === CheckoutStepEnum.SAVE_MENU}>
            <SaveMenu />
          </When>
          <When condition={step === CheckoutStepEnum.REACTIVATE}>
            <ReactivateSubscription onClose={onClose} />
          </When>
          <When condition={step === CheckoutStepEnum.VALIDATION}>
            <InvalidSubscription />
          </When>
          <When condition={step === CheckoutStepEnum.ACTIVATE_SUBSCRIPTION}>
            <ActivateSubscription />
          </When>
          <When condition={step === CheckoutStepEnum.CART_ERRORS}>
            <CartErrors />
          </When>
          <When condition={step === CheckoutStepEnum.VERIFY_NUMBER}>
            <VerifyNumber />
          </When>
          <Otherwise>
            <Paragraph className='text' align='center'>
              Processing
            </Paragraph>
          </Otherwise>
        </Choose>
      </ModalContainer>
    </Modal>
  )

}
