import React from 'react'

import styled from 'styled-components'

import { Spacer, Paragraph, Button, Link, Image } from '@client/components'
import { Modal, TableCell, TableRow } from '@client/components/molecules'
import { ModalActionContainer, ResponsivePXValue, theme } from '@client/components/Theme'
import { GetUnavailableProductsQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'

const ActionContainer = styled.div`
  ${ModalActionContainer()}
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  ${ResponsivePXValue('width', { mobile: '56px', tablet: '56px', desktop: '80px' })}
  ${ResponsivePXValue('height', { mobile: '56px', tablet: '56px', desktop: '80px' })}
`
export interface AddressChangeProductWarningModalProps {
  title: string
  content?: GetUnavailableProductsQuery
  action?: string
  open?: boolean
  onCancel: () => void
  onConfirm: () => void
}

export function AddressChangeProductWarningModal({ title, content, open = false, onCancel, onConfirm }: AddressChangeProductWarningModalProps): JSX.Element {

  const _handleConfirm = (): void => {
    onConfirm?.()
  }

  const _handleCancel = () => {
    onCancel?.()
  }

  const _handleTitle = (name: string):string => {
    const hyphenIndex = name.lastIndexOf('-')

    if (hyphenIndex !== -1) {
      const wordBeforeHyphen = name.substring(0, hyphenIndex).trim()
      return wordBeforeHyphen
    }

    return name

  }

  const _handleServingSize = (name: string):string => {

    const hyphenIndex = name.lastIndexOf('-')

    if (hyphenIndex !== -1) {
      const wordAfterHyphen = name.substring(hyphenIndex + 1).trim()
      const capitalizedWord = wordAfterHyphen.charAt(0).toUpperCase() + wordAfterHyphen.slice(1)
      // return capitalizedWord

      const numberedWord = capitalizedWord.replace(/Serves (one|two|three|four)/g, function (match, word) {
        switch (word) {
          case 'one':
            return 'Serves 1'
          case 'two':
            return 'Serves 2'
          case 'three':
            return 'Serves 3'
          case 'four':
            return 'Serves 4'
          default:
            return match
        }
      })

      return numberedWord
    }

    return name

  }
  const conflictingItems = content?.unavailableProducts || []
  const isNotSubscriptionFee = (productItem: GetUnavailableProductsQuery['unavailableProducts'][0]): boolean => {
    return productItem?.__typename !== 'MealKitSubscriptionFee'
  }
  const conflictingProducts = conflictingItems.filter(isNotSubscriptionFee) || []

  let conflictingItem: GetUnavailableProductsQuery['unavailableProducts'][0]
  return (
    <Modal
      open={open}
      title={title}
      fullscreen={false}>
      <ModalFormContainer>
        <Paragraph variant='p1' align='center'>The following items are not delivered to this area, would you like to continue and remove all items?</Paragraph>
        <Spacer universal='32px' />
        <For each='conflictingItem' of={conflictingProducts} index='dishIndex'>
          <TableRow justify='space-between' margin='12px 0'>
            <TableCell padding='0 16px 0 0'>
              <ImageContainer>
                <Image width={64} height={64} round={true} lazy={false} src={conflictingItem.coverImage?.desktop} />
              </ImageContainer>
            </TableCell>
            <TableCell direction='column' grow='1' justify='center' maxWidth='60%'>
              <Paragraph bold variant='p4'>{_handleTitle(conflictingItem?.name)}</Paragraph>
              <Paragraph variant='p2' color={theme.colors.whites.silver}>{_handleServingSize(conflictingItem?.name)}</Paragraph>
            </TableCell>
          </TableRow>
        </For>
        <ActionContainer>
          <Button color='black' fullWidth title={'Confirm'} onClick={_handleConfirm} />
          <Spacer universal='16px' />
          <Link variant='l2' decoration='underline' onClick={_handleCancel}> Cancel </Link>
        </ActionContainer>
      </ModalFormContainer>
    </Modal>
  )
}
