import React from 'react'

import styled from 'styled-components'

import { LegalText, LegalTextBody } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { ResponsivePXValue } from '../Theme'

const Container = styled.div`
    background-color: ${(props): string => props.theme.colors.whites.alabaster};
    ${ResponsivePXValue('padding', { mobile: '32px', tablet: '40px 345px', desktop: '48px 345px' })};
    display: flex;
    flex-direction: column;
    align-items: center;
`

/**
 * GA Tracking Elements
 *
 * SEO
 * [ ] Link
 * [ ] Description
 * [ ] Tags
 */

const seo = {
  name: 'UCOOK Referral Policy',
  title: 'Referral Policy | UCOOK',
  meta: [{
    name: 'description',
    content: 'As a customer you have a unique code you can share with friends to save on your next box, and give them a discount on their first. Learn more about our referral terms.',
  }],
}

export function ReferralPolicy(): JSX.Element {

  const heading = 'Referral Policy'
  const body: LegalTextBody[] = [{
    paragraphs: ['Welcome to the UCOOK Refer a Friend Programme ("Refer a Friend")! These Refer a Friend terms and conditions ("Refer a Friend Terms & Conditions") govern your participation in UCOOK\'s Refer a Friend Programme. By opting to participate in the Programme, you agree to abide by these Refer a Friend Terms & Conditions. If you don\'t agree to these Refer a Friend Terms & Conditions, you will not be permitted to participate in Refer a Friend Programme.',
      'These Refer a Friend Terms & Conditions operate in conjunction with the UCOOK Terms & Conditions, but only to the extent necessary to govern your participation in the Refer a Friend Programme. If the Refer a Friend Terms & Conditions are inconsistent with the UCOOK Terms & Conditions, these Refer a Friend Terms and Conditions will govern your participation in the Refer a Friend Programme.'],
  }, {
    heading: '1. About the Refer a Friend Programme',
    paragraphs: [`UCOOK may provide you with the opportunity to invite your friends, family members and others (each, an "Invitee") to join UCOOK and order UCOOK Meal Kits. Participation in the Refer a Friend Programme is by invitation only and completely voluntary. If you have received any Refer a Friend Programme invitations, we will notify you via email. 
      UCOOK may provide you with the opportunity to earn referral discounts ("Refer a Friend Discounts") when you successfully refer an eligible invitee to order UCOOK their first Meal Kit through the Refer a Friend Programme, who has successfully participated in the Refer a Friend Programme. The Refer a Friend Discounts can only be used towards future eligible orders for your Meal Kit subscription.`],
  },
  {
    heading: '2. Eligibility',
    paragraphs: ['To be eligible to participate in the Refer a Friend Programme as a referrer (the person that sends out the invitation) or to earn any Refer a Friend Discounts, you must have and maintain an active UCOOK Meal Kit subscription on your account and be in good standing, be over 18 years old, and be a resident of South Africa. UCOOK reserves the right to terminate and disqualify you at any time from participating in the Refer a Friend Programme if you don\'t comply with these Refer a Friend Terms & Conditions.'],
  },
  {
    heading: '3. Refer a Friend Discounts',
    paragraphs: ['If you are eligible to earn Refer a Friend Discounts, we will let you know in the Refer a Friend section of your Account. You may only receive a Refer a Friend Discount if your invitee clicks on your Refer a Friend Programme invitation link, is a new UCOOK Meal Kit customer (who has not previously ordered a UCOOK Meal Kit, completes the registration process, is over the age of 18, resides in South Africa, and participates in a Subscription Meal Kit programme as described in connection with the respective Refer a Friend Discount(s) and is successfully billed for their first Meal Kit order. We will notify you on site if you have a successful referral weekly. The amount of Refer a Friend Discounts that you may receive and the time period in which you may earn Refer a Friend Discounts are subject to limitation.',
      'Refer a Friend Discounts will expire upon the cancellation of your UCOOK account.'],
  },
  {
    heading: '4. Refer a Friend Programme Invitations',
    paragraphs: ['To send a Refer a Friend Programme invitation, visit the relevant page in your Account on UCOOK\'s website and follow the instructions, or click on a relevant Refer a Friend Programme email. You will need to submit personal information about your Invitee, such as name and email address. All information collected, stored, processed, or shared through the Refer a Friend Programme is subject to UCOOK\'s Privacy Policy. By participating in the Refer a Friend Programme, you agree to UCOOK\'s collection, use, storage, and processing of your personal information and acknowledge that you have read and accepted UCOOK\'s Privacy Policy.'],
  },
  {
    heading: '5. Restrictions',
    paragraphs: [`In addition to the restrictions you agreed to in the UCOOK's Terms & Conditions, you agree that the following activities are expressly
                  prohibited and engaging in any of these will be grounds for immediate termination and disqualification from the Refer a Friend Programme,
                  and may lead to the forfeiture of existing Refer a Friend Programme invitations and accrued, but not yet used, Refer a Friend Discounts. Spam,
                  bulk distribution of invitations, distribution to strangers, or any other promotion of your Refer a Friend Programme invitations that would
                  constitute or appear to constitute unsolicited commercial email or spam under any applicable law or regulation. Fraud, attempted fraud, or abuse
                  of the Refer a Friend Programme or Refer a Friend Terms & Conditions. Selling, trading, bartering, or providing anything of value to your Invitee
                  beyond the Refer a Friend Programme invitation, or otherwise using your Refer a Friend Programme Invitations or Refer a Friend Discounts for
                  promotional purposes. Sending more than one Refer a Friend Programme invitation to the same household. Refer a Friend Discounts are not valid
                  for cash or cash equivalent, for purchase of UCOOK ecommerce items, or any kind of subscription fees. No exchange of value for the Refer a Friend
                  Programme invitation or any Refer a Friend Discount (whether cash, product or barter) is allowed, and Refer a Friend Discount cannot be
                transferred to other people or accounts.`],
  },
  {
    heading: '6. Additional Terms',
    paragraphs: ['UCOOK reserves the right at any time to modify or discontinue the Refer a Friend Programme, either temporarily or permanently (or any part thereof). If we modify or discontinue the Refer a Friend Programme, we will let you know via email or by posting a notice on the UCOOK website. UCOOK will not be liable to you in the event of any modification, suspension or discontinuance of the Refer a Friend Programme. UCOOK reserves the right to amend, modify or waive these Refer a Friend Program Terms & Conditions at any time in its sole discretion.'],
  }]

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <Container>
        <LegalText heading={heading} body={body} />
      </Container>
    </>
  )

}
