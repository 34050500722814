import React, { useEffect, useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator } from '@atoms/index'
import { ResponsivePXValue, Spacer } from '@client/components'
import { TabPane, Tabs, Accordion, Tab, TextList, TextListProps, BiographyProps, Biography } from '@molecules/index'
import { DeviceContainer } from '@utility/DeviceContainer'

import { Ingredients, IngredientsProps } from './Ingredients'

export interface MoreAboutKidsMealProps {
  chef: BiographyProps
  ingredients: IngredientsProps
  heatingInstructions: TextListProps
  isDessert?: boolean
}

enum TabEnum {
  ABOUT_THE_DISH = 'No-Nonsense Goodness',
  ABOUT_DESSERT = 'About this Dessert',
  INGREDIENTS = 'Ingredients',
  HEATING_INSTRUCTIONS = 'Heating instructions',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  .radio-container {
    justify-content: center;
    display: flex;

    ${ResponsivePXValue('padding-top', '24px')}
  }

`

interface TabsState {
  activeTab: string
  servingSize: string
}

const DEFAULT_STATE: TabsState = {
  activeTab: TabEnum.ABOUT_THE_DISH, // TODO: Change to about meal when designs are final
  servingSize: '',
}

export function MoreAboutKidsMeal({ chef, ingredients, heatingInstructions, isDessert = false }: MoreAboutKidsMealProps): JSX.Element {

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  useEffect(() => {
    if (isDessert) {
      setState(prevState => ({ ...prevState, activeTab: TabEnum.ABOUT_DESSERT }))
    }
  }, [])

  return (
    <Container>
      <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>More about this meal</Heading>
      <Spacer universal='8px' />
      <Seperator align='center' />
      <DeviceContainer $mobile>
        <Accordion
          activeTab={state.activeTab}
          backgroundColor={theme.colors.whites.pureWhite}
          onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
          <Tab tab={isDessert ? TabEnum.ABOUT_DESSERT : TabEnum.ABOUT_THE_DISH} title={isDessert ? TabEnum.ABOUT_DESSERT : TabEnum.ABOUT_THE_DISH}>
            <Biography {...chef} />
          </Tab>
          <Tab tab={TabEnum.INGREDIENTS} title={TabEnum.INGREDIENTS}>
            <Ingredients {...ingredients} />
          </Tab>
          <If condition={!!heatingInstructions}>
            <Tab tab={TabEnum.HEATING_INSTRUCTIONS} title={TabEnum.HEATING_INSTRUCTIONS}>
              <TextList {...heatingInstructions} />
            </Tab>
          </If>
        </Accordion>
      </DeviceContainer>
      <DeviceContainer $tablet $desktop>
        <Tabs
          activeTab={state.activeTab}
          backgroundColor={theme.colors.whites.pureWhite}
          onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
          <TabPane tab={isDessert ? TabEnum.ABOUT_DESSERT : TabEnum.ABOUT_THE_DISH} title={isDessert ? TabEnum.ABOUT_DESSERT : TabEnum.ABOUT_THE_DISH}>
            <Biography {...chef} />
          </TabPane>
          <TabPane tab={TabEnum.INGREDIENTS} title={TabEnum.INGREDIENTS}>
            <Ingredients {...ingredients} />
          </TabPane>
          <If condition={!!heatingInstructions}>
            <TabPane tab={TabEnum.HEATING_INSTRUCTIONS} title={TabEnum.HEATING_INSTRUCTIONS}>
              <TextList {...heatingInstructions} />
            </TabPane>
          </If>
        </Tabs>
      </DeviceContainer>
    </Container>
  )

}
