import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CarouselContainerWidth, Paragraph, ViralSweep } from '@client/components'
import { PageDataSectionFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  display: flex;
  flex-direction: column;
 
  ${CarouselContainerWidth}
`

export interface PageDataEmbeddedFormProps {
  pageSection: PageDataSectionFragment
}

export function PageDataEmbeddedForm({ pageSection }: PageDataEmbeddedFormProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const formId = pageSection?.embeddedFormId
  const formHeight = isMobile ? pageSection?.embeddedFormHeight?.mobile : pageSection?.embeddedFormHeight?.desktop

  return (
    <Container>
      <Choose>
        <When condition={!!formId}>
          <ViralSweep sweepstakesId={formId} height={`${formHeight}px`} />
        </When>
        <Otherwise>
          <Paragraph variant='p3' align='center'>
            Embedded Form ID Not Found
          </Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )
}
