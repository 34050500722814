import React, { useState, useEffect } from 'react'

import queryString from 'qs'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { useSuppliersCountQuery } from '@hooks/api'
import { Pagination, InfoBlock } from '@molecules/index'
import { SupplierGrid } from '@organisms/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.alabaster};

  ${ResponsivePXValue('padding', '0 0 12px')}
`

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`

interface SuppliersState {
  skip?: number | null
  limit?: number | null
  page?: number
}

const DEFAULT_STATE: SuppliersState = {
  skip: 0,
  limit: 9,
  page: 1,
}

const infoBlockData = {
  title: 'Homegrown goodness',
  subTitle: 'Meet our family of suppliers',
  text: ['Good food comes from good people, and we source only the best, most ethically grown and sourced ingredients for each and every Meal Kit.'],
}

const seo = {
  name: 'UCOOK Suppliers',
  title: 'Meet our Farmers and Suppliers | UCOOK',
  meta: [{
    name: 'description',
    content: 'Our network of small-scale farmers and local purveyors provide you with the most sustainable and high-quality produce on the market. Meet your farmers today.',
  }],
}

export function Suppliers(): JSX.Element {

  const [state, setState] = useState<SuppliersState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const location = useLocation()

  const { data } = useSuppliersCountQuery()

  const totalItems: number = data?.countSuppliers ? data.countSuppliers : 0

  const _handleShowDetails = (slug: string): void => {
    navigate(`/meal-kit/suppliers/${slug}`)
  }

  const _prevPageClicked = (page: number): void => {
    const newSkip = ((page - 1) * state.limit)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: any = {}
    parts.page = page
    const url = queryString.stringify(parts)
    navigate(`${location.pathname}?${url}`)
    setState((prevState) => ({ ...prevState, skip: newSkip, page }))
  }

  const _nextPageClicked = (page: number): void => {
    const newSkip = ((page - 1) * state.limit)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: any = {}
    parts.page = page
    const url = queryString.stringify(parts)
    navigate(`${location.pathname}?${url}`)
    setState((prevState) => ({ ...prevState, skip: newSkip, page }))
  }

  const _pageClicked = (page: number): void => {
    const newSkip = ((page - 1) * state.limit)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: any = {}
    parts.page = page
    const url = queryString.stringify(parts)
    navigate(`${location.pathname}?${url}`)
    setState((prevState) => ({ ...prevState, skip: newSkip, page }))
  }

  useEffect(() => {
    checkUrlParams()
  }, [])

  const checkUrlParams = (): void => {
    const url = location.search
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: any = queryString.parse(url, { ignoreQueryPrefix: true })
    if (parts.page) {
      const newSkip = ((parseInt(parts.page) - 1) * state.limit)
      setState((prevState) => ({ ...prevState, skip: newSkip, page: parts.page }))
    }
  }

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <Container>
        <InfoBlock title={infoBlockData.title} subTitle={infoBlockData.subTitle} text={infoBlockData.text} />
        <SupplierGrid
          skip={state.skip}
          limit={state.limit}
          onShowDetails={_handleShowDetails} />
        <PaginationContainer>
          <Pagination
            totalItems={totalItems}
            itemsPerPage={state.limit}
            page={state.page}
            prevPageClicked={_prevPageClicked}
            nextPageClicked={_nextPageClicked}
            pageClicked={_pageClicked} />
        </PaginationContainer>
      </Container>
    </>
  )
}
