import React from 'react'

import styled from 'styled-components'

import { Heading, ResponsiveImage, Paragraph, Spacer, Link } from '@atoms/index'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { WineListFragment } from '@hooks/api'

import { Counter } from '../controls'

import { CategoryTag } from './CategoryTag'
import { FloatingUpSell } from './FloatingUpSell'

const FrontCard = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.a`
  cursor: pointer;
`

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}

  .title-header {
    ${ResponsivePXValue('padding', '0px 0px 8px')}
  }
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;

  .lite-text-button {
    font-weight: 500;
  }
`

const CategoriesContainer = styled.div`
  display: flex;
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;

  .price-header {
    ${ZeroSpace}
    line-height: 1;
  }

  .maker-text {
    ${ZeroSpace}
    line-height: 24px;
    font-weight: 400;
  }
`

const CounterContainer = styled.div`
  .counter {
    ${ResponsivePXValue('width', { mobile: '72px', tablet: '72px', desktop: '96px' })}
  }
`

export interface WineContentProps {
  wine: WineListFragment
  amount?: number
  onAmountChange?: (amount: number) => void
  onShowDetails?: (slug: string) => void
}

export const WineContent = React.memo(({ wine, amount = 1, onAmountChange, onShowDetails }: WineContentProps): JSX.Element => {

  const _handleShowDetails = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e?.preventDefault()
    onShowDetails?.(wine?.slug)
  }

  const _handleAmountChange = async (amount: number): Promise<void> => {
    onAmountChange?.(amount)
  }

  return (
    <FrontCard>
      <If condition={wine?.upSellText?.length > 0}>
        <FloatingUpSell text={wine.upSellText} />
      </If>
      <ImageContainer onClick={_handleShowDetails} href={`/wine/${wine?.slug}`} className='card-image' >
        <ResponsiveImage image={wine.coverImage} lazy={false} />
      </ImageContainer>
      <ContentContainer>
        <HeaderRow>
          <HeaderColumn>
            <Heading variant='h5' className='price-header'>{`R${wine?.price ?? 250}`}</Heading>
            <Spacer variant='horizontal' universal='12px' />
            <Paragraph variant='p2' className='maker-text'>{(wine?.vineyard?.name ?? 'Brand').toUpperCase()}</Paragraph>
            <Spacer variant='horizontal' universal='16px' />
          </HeaderColumn>
          <CounterContainer>
            <Counter className='counter' minimum={1} amount={amount} onChange={_handleAmountChange} />
          </CounterContainer>
        </HeaderRow>
        <Heading link={`/wine/${wine?.slug}`} variant='h5' className='title-header'>{wine.name}</Heading>
        <Spacer variant='horizontal' universal='16px' />
        <ButtonsContainer>
          <Link
            className='lite-text-button'
            href={`/wine/${wine?.slug}`}
            bold
            onClick={_handleShowDetails}>
              FULL DETAILS
          </Link>
        </ButtonsContainer>
        <Spacer variant='horizontal' universal='24px' />
        <CategoriesContainer>
          <CategoryTag image={wine?.wineCategory?.icon?.fallback} title={wine?.wineCategory?.title} />
        </CategoriesContainer>
      </ContentContainer>
    </FrontCard>
  )

})

WineContent.displayName = 'WineContent'
