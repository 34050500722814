import React from 'react'

import { Svg } from './Svg'

export interface LocalProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Local({ color, hoverColor, className }: LocalProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M35.3526 32.4699C38.4318 34.1115 42.3459 33.6389 44.9452 31.0572C47.4446 28.5699 47.9695 24.8689 46.5349 21.8643C48.7543 18.7055 48.4544 14.328 45.6201 11.5075C43.5656 9.46296 41.0562 8.74664 38.4168 9.34855C37.782 8.21934 36.9972 7.14983 36.0324 6.18976C30.4188 0.603415 20.5762 0.603415 14.9676 6.18976C14.0028 7.14983 13.213 8.21934 12.5832 9.34855C9.94381 8.74664 7.43442 9.46296 5.37992 11.5075C2.54562 14.328 2.24569 18.7055 4.46515 21.8643C3.0305 24.8689 3.55537 28.5699 6.05476 31.0572C8.65412 33.6439 12.5682 34.1115 15.6474 32.4699L15.9073 32.7286C20.5162 37.3051 23.1106 43.5183 23.1106 50H27.9144V49.9801C27.9144 43.5083 30.4988 37.3051 35.0927 32.7286L35.3526 32.4699Z" className='fill' />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100%" height="100%" className='fill' />
        </clipPath>
      </defs>
    </Svg>
  )

}
