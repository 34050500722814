import React from 'react'

import styled from 'styled-components'

import { Spacer, Paragraph, Button, Link } from '@client/components'
import { Modal } from '@client/components/molecules'
import { ModalActionContainer } from '@client/components/Theme'
import { ModalFormContainer } from '@molecules/index'

const ActionContainer = styled.div`
  ${ModalActionContainer()}
`
export interface ConfirmDeleteModalProps {
  title: string
  content?: string
  action?: string
  open?: boolean
  onDelete: () => void
  onClose: () => void
}

export function ConfirmDeleteModal({ title, content = 'You are about to delete', action = '', open = false, onDelete, onClose }: ConfirmDeleteModalProps): JSX.Element {

  const _handleClose = (): void => {
    onClose?.()
  }

  const _handleDeleteClicked = () => {
    onDelete?.()
  }

  return (
    <Modal
      open={open}
      title={title}
      fullscreen={false}
      onClose={_handleClose}>
      <ModalFormContainer>
        <Paragraph variant='p1' align='center'>{`${content}. Do you want to proceed?`}</Paragraph>
        <Spacer universal='32px' />
        <ActionContainer>
          <Button color='black' fullWidth title={`YES, DELETE THIS ${action}`} onClick={_handleDeleteClicked} />
          <Spacer universal='16px' />
          <Link variant='l2' decoration='underline' onClick={_handleClose}> Cancel </Link>
        </ActionContainer>
      </ModalFormContainer>
    </Modal>
  )
}
