import React, { useEffect } from 'react'

import { useAddTrackingDataToCartMutation } from './api'

export interface TrackingData {
  googleSessionId: number
  googleClickId: string
  facebookBrowserId: string
  facebookClickId: string
  instagramClickId: string
  tikTokClickId: string
  admarulaUTMTracker: string
  referrerUrl: string
  source: string
}

export function useTracking(trackingObject: TrackingData): void {

  const [addTrackingDataToCart] = useAddTrackingDataToCartMutation()

  useEffect(() => {

    if (trackingObject) {

      const trackingObjectBeforeJSON = {
        ...(trackingObject.googleSessionId !== null && { googleSessionId: trackingObject.googleSessionId }),
        ...(trackingObject.googleClickId !== null && { googleClickId: trackingObject.googleClickId }),
        ...(trackingObject.facebookBrowserId !== null && { facebookBrowserId: trackingObject.facebookBrowserId }),
        ...(trackingObject.tikTokClickId !== null && { tikTokClickId: trackingObject.tikTokClickId }),
        ...(trackingObject.facebookClickId !== null && { facebookClickId: trackingObject.facebookClickId }),
        ...(trackingObject.instagramClickId !== null && { instagramClickId: trackingObject.instagramClickId }),
        ...(trackingObject.admarulaUTMTracker !== null && { admarulaUTMTracker: trackingObject.admarulaUTMTracker }),
        ...(trackingObject.referrerUrl !== null && { referrerUrl: trackingObject.referrerUrl }),
        ...(trackingObject.source !== null && { source: trackingObject.source }),
      }
      const trackingObjectJSONString = JSON.stringify(trackingObjectBeforeJSON)
      const trackingObjectJSONObject = JSON.parse(trackingObjectJSONString)

      addTrackingDataToCart({
        variables: {
          trackingData: trackingObjectJSONObject,
        },
      })
    }

  }, [trackingObject])
}
