import React from 'react'

import { Svg } from './Svg'

export interface DeliveryProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Delivery({ color, hoverColor, className }: DeliveryProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.8047 11.2152C37.1892 11.1515 35.5338 12.4056 34.9436 14.1005C34.0934 16.5448 33.2331 18.9843 32.3779 21.4238C32.3279 21.5659 32.2478 21.6981 32.1828 21.8353C32.1428 21.8206 32.1028 21.8059 32.0628 21.7863C32.3129 15.9276 32.5579 10.0689 32.813 4.06821C32.0228 4.34253 31.2776 4.49928 30.6424 4.84708C29.097 5.68963 28.4618 7.10042 28.4068 8.77573C28.3318 11.1368 28.2967 13.4979 28.2417 15.8541C28.2317 16.1873 28.2017 16.5253 28.1767 16.8584C28.1317 16.8633 28.0817 16.8681 28.0367 16.8681C27.9867 16.6918 27.9216 16.5204 27.8866 16.344C27.5215 14.4238 27.1614 12.4986 26.8013 10.5784C26.2362 7.57558 25.671 4.57276 25.0959 1.57484C24.9708 0.938026 24.8408 0.889041 24.2206 1.14377C22.3251 1.92754 21.3248 3.77429 21.6999 5.86108C22.1951 8.60917 22.7152 11.3573 23.2253 14.1005C23.3804 14.9332 23.5204 15.7709 23.6855 16.7016C23.3304 16.6673 23.1003 16.6232 22.8702 16.6232C21.93 16.6183 20.9797 16.5546 20.0445 16.6428C18.7491 16.7702 17.5738 17.2943 16.4635 17.9556C14.4979 19.1264 12.5324 20.2922 10.5868 21.4924C9.81661 21.9676 9.82161 22.3741 10.5018 22.9865C11.177 23.589 11.9972 23.8339 12.8925 23.9221C14.4079 24.069 15.7183 23.5008 16.9936 22.7954C17.9839 22.2468 19.0342 21.8696 20.1845 21.8304C22.5151 21.7618 24.3356 22.7758 25.8611 24.4168C27.7466 26.4497 28.0617 29.4917 26.6313 31.8137C25.1859 34.1601 22.13 35.3896 19.4443 34.6597C17.0186 34.0033 15.5032 32.4113 14.813 30.0747C14.3779 28.5904 13.5677 27.4295 12.2123 26.631C11.7622 26.3665 11.312 26.1999 10.8119 26.5134C10.3218 26.822 10.3067 27.2825 10.4018 27.7675C10.9919 30.8535 11.5471 33.9445 12.1923 37.0208C12.6324 39.1223 13.2126 41.1895 13.6877 43.2861C14.5129 46.9355 17.0686 48.988 20.8647 48.9978C24.0056 49.0027 27.1514 48.9978 30.2923 48.9978C30.5924 48.9978 30.8925 48.9978 31.2676 48.9978L40 11.4552C39.5549 11.3622 39.1798 11.2299 38.8047 11.2152Z" className='fill' />
    </Svg>
  )

}
