import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { IconEnum, Button, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PaddedSectionContainer, ResponsivePXValue, SidePagePadding, theme } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { MarketProductDetailsFragment, useUserDetailsQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { MarketDishDetails, MarketDishYouMightAlsoLike, MoreAboutMarketDish } from '@organisms/index'

const BackButtonContainer = styled.div`
 display: flex;
 ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
 ${SidePagePadding}
`
const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
`
const SectionContainer = styled.div<{ $backgroundColor?: string, $customBackground?: string, $padded: boolean }>`
  ${(props): CSS => PaddedSectionContainer(props.$backgroundColor, props.$customBackground, props.$padded)};
`

export interface MarketProductPDPProps {
  dish: MarketProductDetailsFragment
  slug: string
  search: string
  loading: boolean
}

export function MarketProductPDP({ dish, slug, search, loading }: MarketProductPDPProps): JSX.Element {

  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const events = useEvents()
  const navigate = useNavigate()

  const product = dish

  useEffect(() => {

    if (search === undefined && slug === 'search') {
      console.log('search term is undefined')
      navigate('/market')
    }

    if (!loading) {

      const logData = {
        itemName: product?.name,
        itemId: product?.id,
        itemGroupId: dish?.id,
        price: product?.price,
        itemBrand: product?.brand?.name,
        itemCategory: product?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: product?.marketProductCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Market Product',
        itemImage: product?.coverImage?.location,
        itemStockCount: product?.stockCount > 1 ? 1 : 0,
        isMealkit: 'no',
        itemChef: product?.brand?.name,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasViewedProduct('Market Product', snakedData, userDetailsData?.currentUser?.id)
      events.hasSelectedItem('Market Product', snakedData, userDetailsData?.currentUser?.id)
    }
  }, [loading])

  const _handleBackClicked = (): void => {
    navigate(-1)
  }

  const typedProduct = product as MarketProductDetailsFragment

  return (
    <>
      <SectionContainer $padded={false}>
        <BackButtonContainer>
          <Button
            title='BACK'
            variant='secondary'
            displayBorder={false}
            color='grey'
            leftIcon={IconEnum.CHEVRON_BACK_OUTLINE}
            iconColor={theme.colors.oranges.coral}
            loading={loading}
            onClick={_handleBackClicked} />
        </BackButtonContainer>
        <Spacer universal='16px' />
        <MarketDishDetails product={product}/>
        <Spacer universal='16px' />
      </SectionContainer>
      <SectionContainer $backgroundColor='grey' $padded>
        <MoreAboutMarketDish dish={product} />
        <Spacer universal='16px' />
      </SectionContainer>
      <SectionContainer $padded>
        <MarketDishYouMightAlsoLike dish={typedProduct} />
        <GhostButtonContainer>
          <Button variant='secondary' color='grey' title='BACK TO THE MENU' onClick={_handleBackClicked} />
        </GhostButtonContainer>
      </SectionContainer>
    </>
  )

}
