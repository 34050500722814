import React, { useState, useEffect } from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div<{ $color: string, $thin?: boolean }>`
  display: flex;
  width: 100%;

  background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.whites.desertStorm};

  ${(props): string => props.$thin ? ResponsivePXValue('height', '8px') : ResponsivePXValue('height', '16px')};
  ${ResponsivePXValue('border-radius', '4px')}
`

const PercentContainer = styled.div<{ $percentage: number, $color: string, $thin?: boolean }>`
  display: flex;
  align-self: flex-start;

  background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.yellows.goldenrod};

  ${(props): string => props.$thin ? ResponsivePXValue('height', '8px') : ResponsivePXValue('height', '16px')};
  ${ResponsivePXValue('border-radius', '4px')}

  ${(props): CSS => {
    return ResponsivePXValue('width', `${props.$percentage}%`)
  }}
`

export interface PercentBarProps {
  value: number
  total: number
  barColor?: string
  backgroundBarColor?: string
  className?: string
  thin?: boolean
}

interface PercentBarState {
  percent: number
}

const DEFAULT_STATE: PercentBarState = {
  percent: 0,
}

export function PercentBar({ value, total, barColor, backgroundBarColor, className, thin = false }: PercentBarProps): JSX.Element {

  const [state, setState] = useState<PercentBarState>({ ...DEFAULT_STATE })

  useEffect(() => {
    if (value && total) {
      let percentage = 0
      if (total !== 0) {
        percentage = ((value / total) * 100)
      }
      if (percentage > 100) {
        percentage = 100
      }
      setState((prevState) => ({ ...prevState, percent: percentage }))
    }
  }, [value, total])

  return (
    <Container
      $thin={thin}
      className={className}
      $color={backgroundBarColor}>
      <PercentContainer
        $thin={thin}
        $percentage={state.percent}
        $color={barColor} />
    </Container>
  )

}
