import React, { useState } from 'react'

import { Popover, ArrowContainer } from 'react-tiny-popover'
import styled from 'styled-components'

import { useConfig } from '@client/contexts/ConfigProvider'
import { Paragraph } from '@components/index'
import { MediumBoxShadow, theme, ZeroSpace, ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;

  .tooltip-content {
    ${ZeroSpace}

    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '24px')}
  }
`
const TargetContainer = styled.div`
  display: flex;

`
const ContentContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;

  ${MediumBoxShadow}

  background-color: ${theme.colors.whites.pureWhite};
`

export interface TooltipContentProps {
  text: string
}

export function TooltipContent({ text }: TooltipContentProps): JSX.Element {
  return (
    <Container>
      <Paragraph variant='p3' className='tooltip-content'> {text} </Paragraph>
    </Container>
  )
}

interface TooltipState {
  isPopoverOpen: boolean
}

const DEFAULT_STATE: TooltipState = {
  isPopoverOpen: false,
}

export interface TooltipProps {
  children: React.ReactNode
  content: React.ReactNode
  positions?: ('right' | 'left' | 'top' | 'bottom')[]
  onClick?: () => void
}

export function Tooltip({ children, content, positions = ['right', 'left'], onClick }: TooltipProps): JSX.Element {

  const config = useConfig()
  const isBrowser = config?.isBrowser()

  const [state, setState] = useState<TooltipState>({ ...DEFAULT_STATE })

  const setPopoverOpen = (open: boolean) => {
    setState((prevState) => ({ ...prevState, isPopoverOpen: open }))
  }

  const outsideClickHandler = () => {
    setPopoverOpen(false)
  }

  const handleTargetClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (onClick) {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    }
  }

  return (
    <Choose>
      <When condition={isBrowser}>
        <Popover
          isOpen={state.isPopoverOpen}
          positions={positions}
          onClickOutside={outsideClickHandler}
          padding={8}
          containerStyle={{ zIndex: '11' }}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={theme.colors.whites.pureWhite}
              arrowSize={10}
              className='popover-arrow-container'
              arrowClassName='popover-arrow'>
              <ContentContainer>
                {content}
              </ContentContainer>
            </ArrowContainer>
          )}>
          <TargetContainer onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)} onClick={handleTargetClicked}>
            {children}
          </TargetContainer>
        </Popover>
      </When>
      <Otherwise>
        <>
          {children}
        </>
      </Otherwise>
    </Choose >
  )
}
