import React, { useState, useEffect, createRef } from 'react'

import styled from 'styled-components'

import useIsomorphicLayoutEffect from '@client/hooks/UseIsomorphicLayoutEffect'
import { ContainerBorderRadius, ResponsiveProperty, ResponsivePXValue } from '@components/Theme'

const TRANSITION_TIME = 300

enum TransitionStatus {
  IN = 'IN',
  OUT = 'OUT',
}

interface ButtonContainerProps {
  width?: string
  margin?: string
  borderColor?: string
}

const Container = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;

  ${ContainerBorderRadius}

  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.whites.alabaster};
 
  ${ResponsivePXValue('padding', { mobile: '20px', tablet: '24px', desktop: '28px' })}
`

const TabButtonsContainer = styled.div<{ $buttonContainerProps?: ButtonContainerProps }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  align-self: stretch;
  overflow: hidden;

  border: 1px solid ${(props): string => props?.$buttonContainerProps?.borderColor ? props.$buttonContainerProps.borderColor : props.theme.colors.misc.transparent};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('flex-wrap', { mobile: 'wrap', tablet: 'wrap', desktop: 'initial' })}
  ${ResponsivePXValue('border-radius', '8px')}
`

const TabContentContainer = styled.div`
  transition: height 0.3s ease-in-out;
`

const TabPaneComponent = styled.div<{ $opacity: number }>`
  opacity: ${(props): number => props.$opacity};
  transition: opacity 0.3s ease-in-out;
`

const TabButton = styled.div<{ $active: boolean, $blackBackground: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
  font-family: gordita;
  font-weight: 700;
  color: ${(props): string => props.$active ? props.theme.colors.whites.alabaster : props.theme.colors.slates.ironsideGrey};
  background-color: ${(props): string => props.$active ? (props.$blackBackground ? props.theme.colors.greys.mineshaft : props.theme.colors.oranges.coral) : props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('min-height', { mobile: '48px', tablet: '48px', desktop: '40px' })}
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

interface TabPaneProps {
  title: string
  tab: string
  children: JSX.Element | JSX.Element[]
  active?: boolean
  blackBackground?: boolean
  className?: string
  onSelect?: (key: string) => void
}

export function TabPane({ title, tab, active, blackBackground = true, className, onSelect }: TabPaneProps): JSX.Element {

  return (
    <TabButton className={className} key={tab} $active={active} $blackBackground={blackBackground} onClick={(): void => onSelect?.(tab)}>{title}</TabButton>
  )

}

export interface TabsProps {
  children: JSX.Element[] | JSX.Element
  activeTab: string
  buttonContainerProps?: ButtonContainerProps
  backgroundColor?: string
  className?: string
  buttonsClassName?: string
  onTabChange: (tab: string) => void
}

interface TabsState {
  height: number
  activeTab: string
  transitionStatus: TransitionStatus
}

const DEFAULT_STATE: TabsState = {
  height: 0,
  activeTab: '',
  transitionStatus: TransitionStatus.IN,
}

export function Tabs({ children, activeTab, buttonContainerProps, backgroundColor, className, buttonsClassName, onTabChange }: TabsProps): JSX.Element {

  if (Array.isArray(children)) {
    children = children.filter(child => child !== null)
  }

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const ref: React.RefObject<HTMLDivElement> = createRef()
  let resizeTimer: null | ReturnType<typeof setTimeout> = null

  const _handleTabChange = (tab: string): void => {
    onTabChange(tab)
  }

  useEffect(() => {
    if (state.activeTab === '') {
      setState(prevState => ({ ...prevState, activeTab, transitionStatus: TransitionStatus.IN }))
    } else if (activeTab !== state.activeTab) {
      setState(prevState => ({ ...prevState, transitionStatus: TransitionStatus.OUT }))
    }
  }, [activeTab])

  useIsomorphicLayoutEffect(() => {
    if (state.transitionStatus === TransitionStatus.OUT) {
      resizeTimer = setTimeout(() => {
        setState(prevState => ({ ...prevState, activeTab, transitionStatus: TransitionStatus.IN }))
      }, TRANSITION_TIME)
    } else {
      const height = ref?.current?.offsetHeight
      if (height) {
        setState(prevState => ({ ...prevState, height }))
      }
    }
    return (): void => {
      if (resizeTimer) {
        clearTimeout(resizeTimer)
      }
    }

  }, [state.transitionStatus])

  useIsomorphicLayoutEffect(() => {
    const height = ref?.current?.offsetHeight
    if (height) {
      setState(prevState => ({ ...prevState, height }))
    }
  }, [])

  let $contents: JSX.Element = null
  const $tabs: JSX.Element[] = []

  React.Children.forEach(children, (child, index) => {

    if (child.props.tab === state.activeTab) {
      $contents = (
        <TabPaneComponent $opacity={state.transitionStatus === TransitionStatus.OUT ? 0 : 1} ref={ref}>
          {child.props.children}
        </TabPaneComponent>
      )
    }
    $tabs.push(React.cloneElement(child, { onSelect: _handleTabChange, key: index, active: child.props.tab === activeTab }))
  })

  return (
    <Container $backgroundColor={backgroundColor} className={className}>
      <TabButtonsContainer $buttonContainerProps={buttonContainerProps} className={buttonsClassName}>
        {$tabs}
      </TabButtonsContainer>
      <TabContentContainer>
        {$contents}
      </TabContentContainer>
    </Container>
  )

}
