import React, { useState } from 'react'

import { FieldData } from 'rc-field-form/es/interface'
import update from 'react-addons-update'
import styled from 'styled-components'

import { Image, Paragraph } from '@client/components/atoms'
import { Form, TextAreaInput } from '@client/components/molecules'
import { OrderItemTitleStyle, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { MealKitOrderFragment, OrderItemFragment } from '@hooks/api'
import { MealKitDish } from '@uctypes/api/globalTypes'

import { RatingRow } from '../controls'
import { TableCell, TableRow } from '../tables'

const Container = styled.div`
  display: flex;

  .order-item-title{
    ${OrderItemTitleStyle}
  }
`

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '288px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '192px', tablet: '192px', desktop: '88px' })}
  img {
    object-fit: cover;
  }
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const OptionsContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`
const TextAreaContainer = styled.div`
  display: block;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px;

  .rating-modal-input{
    margin: 0;
  }
`
const Option = styled.div<{$selected: boolean}>`
  color: ${(props): string => props.$selected ? '#FFFFFF' : '#292929'};
  background-color: ${(props): string => props.$selected ? '#292929' : '#FFFFFF'};
  display: inline-block;
  border: 2px solid #292929;
  text-align: center;
  vertical-align: middle;
  height: fit-content;
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('margin-right', '8px')}
  ${ResponsivePXValue('margin-bottom', '8px')}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '8px', desktop: '12px' })}
  &:hover{
      cursor: pointer;
  }
  div{
    ${ZeroSpace}
  }
`

const MobileDescription = styled.div`
  .description{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    margin: 0;
  }
`

const MobileRatingContainer = styled.div`
  .mobileRating{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    justify-content: center;

  }
`
interface RatingModalState {
  otherData: string
}

const DEFAULT_STATE: RatingModalState = {
  otherData: '',
}
export interface LatestMealKitOrderListProps {
  orderItem: OrderItemFragment
  rating: number
  selectedOptions: string[]
  newRating: number
  prevInput?: string
  onNavigate: () => void
  onRate: (id: number) => void
  onSelectedOptionClicked:(option: string) => void
  onOtherClicked:(otherInfo: string) => void
}

export function MealKitOrderList({ orderItem, onNavigate, onRate, selectedOptions, onSelectedOptionClicked, newRating, onOtherClicked, rating, prevInput }: LatestMealKitOrderListProps): JSX.Element {

  const [state, setState] = useState<RatingModalState>({ ...DEFAULT_STATE })
  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'otherData') {
          setState((prevState) => update(prevState, {
            otherData: { $set: field.value },
          }))
        }
      })
    })
    const otherInfo = state.otherData
    onOtherClicked(otherInfo)
  }
  const getProductDescription = (product: MealKitOrderFragment): string => {
    return `${(product.group as MealKitDish).mealKitCategories[0].title} | Eat within ${product.cookWithin} days`
  }
  const description = getProductDescription(orderItem?.product as MealKitOrderFragment)
  const shortenedProductName = (orderItem?.product?.name).slice(0, -11)
  const OptionsGood = ['Delicious', 'Fresh ingredients', 'Generous portion', 'Clear instructions', 'Other']
  const OptionsBad = ['Unpalatable', 'Poor ingredients', 'Small portion', 'Unclear instructions', 'Other']

  let option: string
  return (
    <Container>
      <DeviceContainer $desktop>
        <TableRow justify='space-between' margin='12px 0'>
          <TableCell padding='0 16px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image width={64} height={64} round={true} lazy={false} src={orderItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column' grow='1' justify='center' maxWidth='60%'>
            <Paragraph bold variant='p4' className='order-item-title' onClick={onNavigate} >{shortenedProductName}</Paragraph>
            <Paragraph variant='p2' color={theme.colors.whites.silver}>{description}</Paragraph>
          </TableCell>
          <TableCell align='center' justify='flex-end'>
            <RatingRow rating={newRating || rating} onClick={onRate} />
          </TableCell>
        </TableRow>
        <If condition={!!newRating}>
          <TableRow>
            <OptionsContainer>
              <For each='option' of={newRating > 3 ? OptionsGood : OptionsBad}>
                <Option
                  key={option}
                  $selected={selectedOptions?.includes(option)}
                  onClick={() => { onSelectedOptionClicked(option) }}>
                  <Paragraph
                    align='center'
                    cursor='pointer'
                    color={selectedOptions?.includes(option) ? theme.colors.whites.pureWhite : theme.colors.greys.mineshaft }
                    variant='p3'>
                    {option}
                  </Paragraph>
                </Option>
              </For>
            </OptionsContainer>
          </TableRow>
          <If condition={selectedOptions?.includes('Other')}>
            <TextAreaContainer>
              <Form onFieldsChange={_handleChange}>
                <TextAreaInput
                  className='rating-modal-input'
                  isCompact={true}
                  name='otherData'
                  placeholder={prevInput || 'Tell us more'}
                  rules={[{ required: true, message: 'Please input additional info' }]}
                />
              </Form>
            </TextAreaContainer>
          </If>
        </If>
      </DeviceContainer>

      <DeviceContainer $mobile $tablet>
        <TableRow margin='12px 0' justify='center'>
          <ImageContainer onClick={onNavigate}>
            <Image round={true} lazy={false} src={orderItem?.product?.coverImage?.mobile} />
          </ImageContainer>
        </TableRow>
        <TableRow justify='center'>
          <TableCell direction='column'>
            <Paragraph bold align = 'center' variant='p4' className='order-item-title' onClick={onNavigate}>{shortenedProductName}</Paragraph>
            <MobileDescription>
              <Paragraph bold align = 'center' className='description' variant='p3' color='grey'>{description}</Paragraph>
            </MobileDescription>
            <MobileRatingContainer>
              <RatingRow className='mobileRating' onClick={onRate} rating={newRating || rating} />
            </MobileRatingContainer>
          </TableCell>
        </TableRow>

        <If condition={!!newRating}>
          <TableRow>
            <OptionsContainerMobile>
              <For each='option' of={newRating > 3 ? OptionsGood : OptionsBad}>
                <Option
                  key={option}
                  $selected={selectedOptions?.includes(option)}
                  onClick={() => { onSelectedOptionClicked(option) }}>
                  <Paragraph
                    align='center'
                    cursor='inter'
                    color={selectedOptions?.includes(option) ? theme.colors.whites.pureWhite : theme.colors.greys.mineshaft }
                    variant='p3'>
                    {option}
                  </Paragraph>
                </Option>
              </For>
            </OptionsContainerMobile>
          </TableRow>
          <If condition={selectedOptions?.includes('Other')}>
            <TextAreaContainer>
              <Form onFieldsChange={_handleChange}>
                <TextAreaInput className='rating-modal-input' isCompact={true} name='otherData' value = {prevInput} placeholder={prevInput || 'Tell us more'} rules={[{ required: true }]}/>
              </Form>
            </TextAreaContainer>
          </If>
        </If>
      </DeviceContainer>
    </Container>
  )

}
