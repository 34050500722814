import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Heading, Paragraph, ResponsiveImage, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCardContainer, ProductCardContentContainer, ProductCardImageContainer } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { MarketProductListFragment, UserCartDocument, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { LowStockContainer, MarketMessageModal, ReactivateSubscriptionModal } from '@organisms/index'
import { ProductOrderTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'

const Container = styled.div`
  ${ProductCardContainer}
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const HeadingContainer = styled.div`
  height: 3em;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
`

export enum MarketProductModalEnum {
  SIGNUP = 'SIGNUP',
  UN_PAUSE = 'UN_PAUSE',
  MARKET = 'MARKET',
  NONE = 'NONE',
}

export interface MarketProductCardProps {
  marketProduct: MarketProductListFragment
  className?: string
  loading?: boolean
}

interface MarketProductCardState {
  activeModal: MarketProductModalEnum
  loading: boolean
  buttonTitle: string

}

const DEFAULT_STATE: MarketProductCardState = {
  activeModal: MarketProductModalEnum.NONE,
  loading: false,
  buttonTitle: '',
}

export const _handleWeekendBoxDeliveryText = (): string => {

  const currentDate = new Date()
  const currentDayOfWeek = currentDate.getDay()
  const currentHour = currentDate.getHours()
  const daysUntilFriday = 5 - currentDayOfWeek
  let daysUntilNextNextFriday
  if (currentDayOfWeek === 1 || currentDayOfWeek === 2 || (currentDayOfWeek === 3 && currentHour < 9)) {
    daysUntilNextNextFriday = daysUntilFriday
  } else {
    daysUntilNextNextFriday = daysUntilFriday + 7
  }

  const nextNextFriday = new Date(currentDate)
  nextNextFriday.setDate(currentDate.getDate() + daysUntilNextNextFriday)
  const inputDateString = `${nextNextFriday.toDateString()}`
  const inputDate = new Date(inputDateString)
  const formattedDate = inputDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })
  return `${formattedDate}`
}

export const MarketProductCard = React.memo(({ marketProduct, loading: externalLoading, className }: MarketProductCardProps): JSX.Element => {

  const [state, setState] = useState<MarketProductCardState>(DEFAULT_STATE)
  const config = useConfig()
  const { addToast } = useToasts()
  const { data: userCartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart, { loading: attToCartLoading }] = useAddItemToCartMutation()
  const theme = useTheme()
  const navigate = useNavigate()
  const loading = externalLoading || cartLoading || attToCartLoading || state.loading
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const events = useEvents()
  const isOccasionBox = marketProduct.isOccasionBox
  const isWeekendBox = marketProduct.isWeekendBox
  const hasOrderTypeBoth = marketProduct?.warehouses?.some((warehouse) => warehouse.orderType === ProductOrderTypeEnum.BOTH)
  const hasActiveSubscription = !!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription
  const [removeItemFromCart] = useRemoveItemFromCartMutation()

  const _handleAddProductToBox = async (): Promise<void> => {
    try {
      setState((prevState) => update(prevState, { loading: { $set: true } }))

      await addItemToCart({
        variables: { productId: marketProduct.id, quantity: 1 },
      })

      const logData = {
        itemName: marketProduct?.name,
        itemId: marketProduct?.id,
        price: marketProduct?.price,
        itemBrand: 'UCOOK',
        itemCategory: marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: marketProduct?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Market Product',
        quantity: 1,
        itemImage: marketProduct?.coverImage?.location,
        itemStockCount: marketProduct?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      addToast('Item successfully added to cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setState((prevState) => update(prevState, { loading: { $set: false } }))
    }
  }

  const _closeMarketModal = (): void => {
    setState((prevState) => update(prevState, { activeModal: { $set: MarketProductModalEnum.NONE } }))
  }
  const _handleToggleReactivateSubscriptionModal = (display: boolean): void => {
    const activeModal = display ? MarketProductModalEnum.UN_PAUSE : MarketProductModalEnum.NONE
    setState((prevState) => update(prevState, { activeModal: { $set: activeModal } }))
  }

  const _handleReactivateSubscription = () => {
    _handleAddProductToBox()
    CartPlugin.shared().open()
    _handleToggleReactivateSubscriptionModal(false)
  }
  const _handleOnRemove = async (): Promise<void> => {

    setState((prevState) => update(prevState, { loading: { $set: true } }))

    try {
      if (marketProduct.quantityInCart === 1) {

        await removeItemFromCart({
          variables: {
            productId: marketProduct.id,
          },
        })
        setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId: marketProduct.id,
            quantity: marketProduct.quantityInCart - 1,
          },
        })
      }

      const logData = {
        itemName: marketProduct?.name,
        itemId: marketProduct?.id,
        itemGroupId: marketProduct?.id,
        price: marketProduct?.price,
        itemBrand: 'UCOOK',
        itemCategory: marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: marketProduct?.marketProductCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: marketProduct?.subCategory?.title,
        quantity: 1,
        itemImage: marketProduct?.coverImage?.location,
        itemStockCount: marketProduct?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)
      setState((prevState) => update(prevState, { loading: { $set: false } }))

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (marketProduct.quantityInCart === 0) {
      _handleAddToCart()
    }
  }

  const _handleAddToCart = async (): Promise<void> => {

    if (!hasActiveSubscription && !isOccasionBox && !isWeekendBox && !hasOrderTypeBoth) {
      // Show market modal
      setState((prevState) => update(prevState, { activeModal: { $set: MarketProductModalEnum.MARKET } }))
      return
    }

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    if (isOccasionBox || isWeekendBox || hasOrderTypeBoth) {
      if (noDefaultAddress) {
        ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      } else if (marketProduct.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
        console.log('marketProduct.availability => ', marketProduct.availability)
      } else {
        _handleAddProductToBox()
      }
    } else if (!userCartData?.currentUser?.checkoutStatus?.hasAccount) {
      // Show market modal
      setState((prevState) => update(prevState, { activeModal: { $set: MarketProductModalEnum.MARKET } }))
    } else if (marketProduct.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
      if (userCartData?.currentUser?.checkoutStatus?.hasPausedSubscription) {
        // SHOW Unpause
        _handleToggleReactivateSubscriptionModal(true)
      } else if (!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
        // Show plans
        navigate('/meal-kit/plans')
      }
    } else {
      _handleAddProductToBox()
    }
  }

  const pdpLink = `/market/${marketProduct?.slug}`
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  let notAvailable = false

  const setButtonTitle = () => {
    let buttonTitle = userCartData?.currentUser?.isOnDemand || (isOccasionBox || isWeekendBox || hasOrderTypeBoth) ? 'ADD TO CART' : 'ADD TO ORDER'

    if (marketProduct.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE || !hasActiveSubscription) {
      buttonTitle = 'GET STARTED'
    } else if (marketProduct.availability !== ProductAvailabilityEnum.AVAILABLE) {
      notAvailable = true
      buttonTitle = 'NOT AVAILABLE'
    }
    if (marketProduct.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      notAvailable = true
      buttonTitle = 'OUT OF STOCK'
    }
    setState((prevState) => update(prevState, { buttonTitle: { $set: buttonTitle } }))
  }

  useEffect(() => {
    setButtonTitle()
  }, [userCartData])

  return (
    <Container className={className}>
      <MarketMessageModal
        open={state.activeModal === MarketProductModalEnum.MARKET}
        title='Only available with Meal Kits'
        description={'Purchase market products with your Meal Kit subscription. Sign up to get everything you need to cook dinner, delivered to your door. To get started, check out this week\'s menu or, have a look at our plans page.'}
        primaryActionTitle='SEE THIS WEEKS MENU'
        primaryActionLink='/meal-kit'
        secondaryActionTitle='SEE PLANS & PRICING'
        secondaryActionLink='/meal-kit/plans'
        onClose={_closeMarketModal}
      />
      <ReactivateSubscriptionModal
        open={state.activeModal === MarketProductModalEnum.UN_PAUSE}
        onReactivation={_handleReactivateSubscription}
        onClose={() => { _handleToggleReactivateSubscriptionModal(false) }} />
      <If condition={marketProduct?.upSellText?.length > 0}>
        <FloatingUpSell text={marketProduct.upSellText} />
      </If>
      <If condition={isWeekendBox}>
        <Choose>
          <When condition={marketProduct?.upSellText?.length > 0}>
            <FloatingUpSell text={marketProduct.upSellText} />
          </When>
          <Otherwise>
            <FloatingUpSell text={'Delivered ' + _handleWeekendBoxDeliveryText()} />
          </Otherwise>
        </Choose>
      </If>
      <LinkContainer onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={marketProduct.coverImage} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer className='fluid-grow'>
        <HeadingContainer>
          <Heading variant='h6' className='card-sub-title'>
            {marketProduct.name}
          </Heading>
        </HeadingContainer>

        <Paragraph bold variant='p2' color={theme.colors.whites.silver} className='card-title'>
          {marketProduct?.marketProductCategories?.map((cat) => cat.title).join(', ')}
        </Paragraph>
        <Spacer />
        <LowStockContainer product={marketProduct}>
          <Heading variant='h5' className='price'>
            R{marketProduct.price}
          </Heading>
        </LowStockContainer>
        <Spacer className='fluid-grow' universal='16px' />
        <Button
          className='add-button'
          title={state.buttonTitle}
          color='black'
          fullWidth
          loading={loading || state.loading}
          disabled={loading || notAvailable}
          amount={marketProduct.quantityInCart}
          onClick={_handleOnCenterClick}
          onLeftIconClick={_handleOnRemove}
          onRightIconClick={_handleAddToCart} />
      </ContentContainer>
    </Container>
  )
})

MarketProductCard.displayName = 'MarketProductCard'
