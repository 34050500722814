import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Paragraph, Button, CountdownTimer, Heading, getPageSectionButtonColor } from '@client/components'
import { Spacer, IconEnum, ResponsiveImage } from '@client/components/atoms'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { PageNotificationFragment } from '@hooks/api'

const Container = styled.div<{ $color?: string, $isPopup?: boolean }>`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

${ResponsivePXValue('padding', '16px')}
background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.oranges.coral};

  .close-button {
    ${ZeroSpace}
  }
`

const ContentContainer = styled.div`
  display: flex;
`

const HeaderContainer = styled.div`
 display: flex;
 align-items: center;
 justify-content: space-between;
  align-self: stretch;
`
const ActionMessageContainer = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  align-self: flex-start;
  border-radius: 0px 15px 15px 0px;

  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.greens.fruitSalad};
  ${ResponsivePXValue('padding', '6px 12px')}
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  overflow: hidden;
  border-radius: 8px;
  ${ResponsivePXValue('width', '80px')}
`

export interface PageNotificationPopupProps {
  notification: PageNotificationFragment | null
  dismissLoading?: boolean
  onLinkClicked?: () => void
  onClose?: () => void
}

export function PageNotificationPopup({ notification, dismissLoading = false, onLinkClicked, onClose }: PageNotificationPopupProps): JSX.Element {

  const navigate = useNavigate()

  const ctaBackground = getPageSectionButtonColor(notification?.ctaBackground)
  const textColor = notification?.textColor || theme.colors.whites.pureWhite
  const targetDate = notification?.endDate ? new Date(notification?.endDate) : null

  const _handleOnLinkPressed = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>): void => {
    e.preventDefault()
    if (!notification?.link) return
    navigate(notification?.link)
    onLinkClicked?.()
    onClose?.()
  }

  const displayIcon = notification?.shouldIconDisplay && !!notification?.icon

  return (
    <Container $isPopup={notification?.isPopup} $color={notification?.backgroundColor}>
      <HeaderContainer>
        <Heading variant='h5' color={textColor}>{notification?.text}</Heading>
        <Button
          color='transparent'
          size='small'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={textColor}
          loading={dismissLoading}
          disabled={dismissLoading}
          onClick={() => onClose?.()} />
      </HeaderContainer>
      <If condition={!notification?.link}>
        <Spacer universal='12px'/>
        <ActionMessageContainer $backgroundColor={notification?.ctaBackground}>
          <Paragraph variant='p1' bold color={notification?.ctaText}>{notification?.linkText}</Paragraph>
        </ActionMessageContainer>
      </If>
      <Spacer universal='16px'/>
      <ContentContainer>
        <If condition={displayIcon}>
          <IconContainer>
            <ResponsiveImage image={notification.icon} />
          </IconContainer>
          <Spacer universal='16px' variant='horizontal' />
        </If>
        <TextContainer>
          <Paragraph variant='p2' bold color={textColor}>{notification?.additionalText}</Paragraph>
          <If condition={notification?.shouldCountDownDisplay}>
            <Spacer universal='4px'/>
            <CountdownTimer variant='vertical' targetDate={targetDate} textColor={notification?.textColor} backgroundColor={notification?.countDownBackground} />
          </If>
        </TextContainer>
      </ContentContainer>
      <If condition={!!notification?.link}>
        <Spacer universal='16px' />
        <Button fullWidth href={notification?.link} color={ctaBackground} title={notification?.linkText} onClick={_handleOnLinkPressed} />
      </If>
    </Container>
  )
}
