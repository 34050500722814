import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftPizzaStore')

const CraftPizzaStore = loadable(() => import(/* webpackChunkName: "craft-pizza-store" */'@pages/CraftPizzaStore'), {
  resolveComponent: (components) => components.CraftPizzaStore,
})

export function CraftPizzaStoreAsync(): JSX.Element {

  return <CraftPizzaStore fallback={<div>Loading...</div>} />

}
