import React, { Fragment } from 'react'

import styled from 'styled-components'

import { Image, Paragraph } from '@client/components/atoms'
import { ResponsivePXValue } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { OrderItemFragment } from '@hooks/api'
import { MarketProduct } from '@uctypes/api/globalTypes'

import { RatingRow } from '../controls'
import { TableCell, TableRow } from '../tables'

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  img {
    object-fit: cover;
  }
`

const MobileDescription = styled.div`
  .description{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    margin: 0;
  }
`

const MobileRatingContainer = styled.div`
  .mobileRating{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
  }
`

const AddOnTitle = styled.div`
  ${ResponsivePXValue('width', '186px')}
`

const ServingSize = styled.div`
  ${ResponsivePXValue('width', '186px')}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export interface OrderAddOnItemPastProps {
  addOnItem: OrderItemFragment
  rating: number
  onNavigate: () => void
  onRate: (id: number) => void
}

export function OrderAddOnItemPast({ addOnItem, onNavigate, onRate, rating }: OrderAddOnItemPastProps): JSX.Element {

  const getFrozenServingSize = (addOnItem: OrderItemFragment): string => {
    const name = addOnItem?.productName.split(' - ')
    if (name?.length > 0) {
      let servingSize = name[1]?.replace('one', '1')
      servingSize = servingSize?.replace('two', '2')
      servingSize = servingSize?.replace('four', '4')
      return servingSize
    }
  }

  return (
    <Fragment>
      <DeviceContainer $desktop>
        <TableRow margin='12px 0'>
          <TableCell padding='0 16px 0 0' justify='center'>
            <ImageContainer onClick={onNavigate}>
              <Image
                width={88}
                height={88}
                round={true}
                lazy={false}
                src={addOnItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell grow='1' align='center'>
            <AddOnTitle>
              <Paragraph variant='p2' bold className='order-item-title' onClick={onNavigate}>{addOnItem?.product?.name?.split(' - ')[0]}</Paragraph>
            </AddOnTitle>
          </TableCell>
          <TableCell align='center' grow='1' padding='0 48px 0 0'>
            <ServingSize>
              <Paragraph variant='p3'>
                <Choose>
                  <When condition={addOnItem.product.__typename === 'Wine'}>
                    750ml
                  </When>
                  <When condition={addOnItem.product.__typename === 'MarketProduct'}>
                    {(addOnItem.product as unknown as MarketProduct)?.brand?.name}
                  </When>
                  <Otherwise>
                    {getFrozenServingSize(addOnItem)}
                  </Otherwise>
                </Choose>
              </Paragraph>
            </ServingSize>
          </TableCell>
          <TableCell align='center' grow='1' padding='0 48px 0 0'>
            <Paragraph variant='p3'>
              Qty {addOnItem.quantity}
            </Paragraph>
          </TableCell>
          <TableCell align='center' justify='flex-end'>
            <RatingRow onClick={onRate} rating={rating} />
          </TableCell>
        </TableRow>
      </DeviceContainer>
      <DeviceContainer $mobile $tablet>
        <TableRow margin='12px 0'>
          <TableCell padding='0 8px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image
                round={true}
                lazy={false}
                src={addOnItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column'>
            <Paragraph variant='p2' className='order-item-title' onClick={onNavigate}>{addOnItem?.product?.name?.split(' - ')[0]}</Paragraph>
            <MobileDescription>
              <Paragraph className='description' variant='p3'>
                <Choose>
                  <When condition={addOnItem.product.__typename === 'Wine'}>
                    750ml
                  </When>
                  <When condition={addOnItem.product.__typename === 'MarketProduct'}>
                    {(addOnItem.product as unknown as MarketProduct)?.brand?.name}
                  </When>
                  <Otherwise>
                    {getFrozenServingSize(addOnItem)}
                  </Otherwise>
                </Choose>
              </Paragraph>
            </MobileDescription>
            <MobileDescription>
              <Paragraph className='description' variant='p3'>
                Qty {addOnItem.quantity}
              </Paragraph>
            </MobileDescription >
            <If condition={['FrozenMeal', 'MarketProduct'].includes(addOnItem.product.__typename)}>
              <MobileRatingContainer>
                <RatingRow className='mobileRating' onClick={onRate} rating={rating} />
              </MobileRatingContainer>
            </If>
            <MobileDescription>
              <Paragraph className='description' variant='p2'>
                R{addOnItem?.product?.price * addOnItem.quantity}.00
              </Paragraph>
            </MobileDescription>
          </TableCell >
        </TableRow >
      </DeviceContainer >
    </Fragment >
  )

}
