import React from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { Heading, ResponsivePXValue, Paragraph, Button } from '@client/components'
import { Link } from '@client/components/atoms'
import { DashedTable, DashedTableRow, ModalLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAcceptUserMenuPriceDifferenceMutation, useUserCartQuery } from '@hooks/api'

const ElementWrapper = styled.div<{ $indent?: boolean }>`
  .text-item {
    margin: 0;
    padding: 0;
    ${(props): CSS => {
    if (props.$indent) {
      return ResponsivePXValue('margin-left', '8px')
    }
  }}
  }
`

const Container = styled.div`
  .text {
    margin-top: 0;
    padding: 0;
  }
  .confirm-button {
    ${ResponsivePXValue('margin-top', '16px')}
  }
`

export function SubscriptionDifference(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [acceptUserMenuPriceDifference, { loading: differenceLoading }] = useAcceptUserMenuPriceDifferenceMutation()
  const navigate = useNavigate()

  const _handleConfirm = async (): Promise<void> => {
    await acceptUserMenuPriceDifference({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.id,
      },
    })
    await CheckoutPlugin.shared().checkout(client)
  }

  const _handleBackToMenu = async (): Promise<void> => {
    navigate('/meal-kit')
    CheckoutPlugin.shared().reset()
  }

  const __getNormalRowElement = (text: string, indent = false): JSX.Element => {
    return (
      <ElementWrapper $indent={indent}>
        <Paragraph className='text-item' variant='p1'>{text}</Paragraph>
      </ElementWrapper>
    )
  }

  const __getBoldRowElement = (text: string, indent = false): JSX.Element => {
    return (
      <ElementWrapper $indent={indent}>
        <Paragraph className='text-item' variant='p2'>{text}</Paragraph>
      </ElementWrapper>
    )
  }

  const __getTotalRowElement = (text: string): JSX.Element => {
    return (
      <ElementWrapper>
        <Heading className='text-item' variant='h5'>{text}</Heading>
      </ElementWrapper>
    )
  }

  const deliveryCost = userCartData?.currentUser?.activeCart?.additions?.find((addition) => addition.id === 'DELIVERY')?.value ?? 0
  const discountCost = userCartData?.currentUser?.activeCart?.reductions?.reduce((total, reduction) => {
    if (reduction.id === 'USER_POINTS') {
      return total
    }
    return total + reduction.value
  }, 0) ?? 0
  const difference = userCartData?.currentUser?.activeMenu?.subscriptionDifference?.currentCost - userCartData?.currentUser?.activeMenu?.subscriptionDifference?.subscriptionCost
  const onDemandCose = userCartData?.currentUser?.activeCart?.cartItems?.reduce((total, cartItem) => {
    if (cartItem.isFromSubscription) {
      return total
    }
    return total + cartItem.price
  }, 0)

  const loading = userCartLoading || differenceLoading

  return (
    <Choose>
      <When condition={loading}>
        <ModalLoading />
      </When>
      <Otherwise>
        <Container>
          <Paragraph className='text' align='center'>
            You have selected dishes outside of your default subscription plan for your upcoming order. Following this order, your default plan will be reapplied. Any permanent subscription changes can be applied from your subscription dashboard.
          </Paragraph>
          <DashedTable>
            <DashedTableRow seperated title={__getBoldRowElement('Meal Kit Subscription')}>
              {__getBoldRowElement(`R${userCartData?.currentUser?.activeMenu?.subscriptionDifference?.currentCost.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow title={__getNormalRowElement('Default plan cost', true)}>
              {__getNormalRowElement(`R${userCartData?.currentUser?.activeMenu?.subscriptionDifference?.subscriptionCost.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getNormalRowElement(`${difference > 0 ? 'Extra' : 'Reduced'} meal cost`, true)}>
              {__getNormalRowElement(`${difference > 0 ? '+' : '-'}R${Math.abs(difference).toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getBoldRowElement('Add-ons')}>
              {__getBoldRowElement(`R${onDemandCose.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getBoldRowElement('Delivery')}>
              {__getBoldRowElement(`R${deliveryCost.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getBoldRowElement('Discount')}>
              {__getBoldRowElement(`-R${discountCost.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getBoldRowElement('Wallet')}>
              {__getBoldRowElement(`-R${userCartData?.currentUser?.activeCart?.assignedUserPoints.toFixed(2)}`)}
            </DashedTableRow>
            <DashedTableRow seperated title={__getTotalRowElement('TOTAL')}>
              {__getTotalRowElement(`R${userCartData?.currentUser?.activeCart?.grandTotal.toFixed(2)}`)}
            </DashedTableRow>
          </DashedTable>
          <Button className='buttons confirm-button' fluid title='Confirm' onClick={_handleConfirm} />
          <Link className='buttons' onClick={_handleBackToMenu}>Go back to the menu</Link>
        </Container>
      </Otherwise>
    </Choose>
  )

}
