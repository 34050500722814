import React, { Fragment } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Seperator, Spacer } from '@atoms/index'
import { CarouselCardWidth, CarouselContainerWidth, CarouselFlexContainer, CarouselSlideMargin, ResponsivePXValue } from '@components/Theme'
import { FrozenMealDishListFragment, useGetAllFrozenMealDishesQuery, useGetAppQuery } from '@hooks/api'
import { Carousel, CraftMealCard, SectionLoading } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  display: flex;
  flex-direction: column;
 
  ${CarouselContainerWidth}

  ${ResponsivePXValue('margin', '24px 0')}
  
  .title {
    padding-top: 0;
  }
`
const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .craft-card {
    ${CarouselCardWidth}
  }
`

const CardContainer = styled.div`
  display: flex;
  align-self: center;
 
  ${CarouselCardWidth}

  .craft-card {
     ${CarouselSlideMargin}
  }
`

export interface CraftMealInStoreCarouselProps {
  title?: string
  skeleton?: boolean
}

export function CraftMealInStoreCarousel({ title }: CraftMealInStoreCarouselProps): JSX.Element {

  const { data, loading } = useGetAllFrozenMealDishesQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const theme = useTheme()

  const frozenDishes = data?.dishes
  const dishes = frozenDishes?.list ? [...frozenDishes?.list] : []

  let craftMeal: FrozenMealDishListFragment

  return (
    <Container id='craftMealOnMenuCarousel'>
      <Choose>
        <When condition={!!dishes}>
          <Fragment>
            <If condition={!!title}>
              <Heading variant='h4' align='center' className='title' color={theme.colors.slates.bitter}>{title}</Heading>
              <Spacer universal='8px' />
              <Seperator align='center' />
            </If>
            <Choose>
              <When condition={dishes.length === 1}>
                <CardContainer>
                  <CraftMealCard
                    className='craft-card'
                    key={craftMeal.id}
                    craftMeal={craftMeal} />
                </CardContainer>
              </When>
              <When condition={isDesktop && dishes.length < 4}>
                <CardContainer>
                  <For each='craftMeal' of={dishes}>
                    <CraftMealCard
                      className='craft-card'
                      key={craftMeal.id}
                      craftMeal={craftMeal} />
                  </For>
                </CardContainer>
              </When>
              <Otherwise>
                <CarouselContainer>
                  <Carousel
                    displayNavButtons={isDesktop}>
                    <For each='craftMeal' of={dishes}>
                      <CraftMealCard
                        className='embla-slide craft-card'
                        key={craftMeal.id}
                        craftMeal={craftMeal} />
                    </For>
                  </Carousel>
                </CarouselContainer>
              </Otherwise>
            </Choose>
          </Fragment>
        </When>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <Otherwise></Otherwise>
      </Choose>
    </Container>
  )
}
