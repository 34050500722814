import React, { Fragment } from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { ResponsiveImage, Paragraph } from '@atoms/index'
import { RichText } from '@client/components'
import { FormFactor, ContainerBorderRadius, ResponsiveProperty, ResponsivePXValue, StrongBoxShadow, getObjectFit } from '@components/Theme'
import { PageGridItemFragment } from '@hooks/api'

const Container = styled.div<{ $flexBasis: FormFactor }>`

  display: flex;
  flex-direction: column;
  align-items: center;

  ${ResponsivePXValue('max-width', { desktop: '415px' })}

  ${(props): CSS => {
    return ResponsiveProperty('flex-basis', { ...props.$flexBasis })
  }}
`

const ImageContainer = styled.div<{ $width: string, $height: string, $imageShadow?: boolean }>`

  ${ResponsivePXValue('margin-bottom', '28px')}

  ${(props): CSS => (props.$imageShadow) ? StrongBoxShadow : null}
  ${(props): CSS => (props.$imageShadow) ? ContainerBorderRadius : null}

  ${(props): CSS => {
    if (props.$width || props.$height) {
      return `
        ${ResponsivePXValue('width', props.$width)}
        ${ResponsivePXValue('height', props.$height)}
      `
    }
    return `
      ${ResponsivePXValue('width', '46px')}
      ${ResponsivePXValue('height', '46px')}
    `
  }}
`

const Content = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  
  .title {
    width: 75%;
    ${ResponsivePXValue('margin-bottom', '16px')}
  }
  
`

export interface LinkInterface {
  title: string
  link: string
}
export interface PageGridItemProps {
  pageGridItem: PageGridItemFragment
  className?: string
  flexBasis?: FormFactor
}

export function PageGridItem({ pageGridItem, flexBasis, className }: PageGridItemProps): JSX.Element {

  const theme = useTheme()

  const objectFit = getObjectFit(pageGridItem)

  const description = pageGridItem?.description || ''

  return (
    <Container $flexBasis={flexBasis} className={className}>
      <Choose>
        <When condition={!!pageGridItem}>
          <Fragment>
            <If condition={!!pageGridItem.image && !description}>
              <ImageContainer $width={pageGridItem.imageWidth} $height={pageGridItem.imageHeight} $imageShadow={pageGridItem.imageShadow}>
                <ResponsiveImage image={pageGridItem.image} objectFit={objectFit} />
              </ImageContainer>
            </If>
            <Content>
              <Paragraph align='center' variant='p2' color={pageGridItem.titleColor ?? theme.colors.greys.liteCodGrey} className='title'> {pageGridItem.gridItemTitle} </Paragraph>
              <If condition={!!description}>
                <RichText align='center'>{description}</RichText>
              </If>
            </Content>
          </Fragment>
        </When>
        <Otherwise>
          Page Grid Item not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
