import { useRef } from 'react'

import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { defaultUserSubNav } from '@client/Navigation'
import { MainNavContainerFragment, MainNavItemFragment, NavItemFragment, NavigationFragment, SubNavItemFragment } from '@hooks/api'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { ConfigPlugin } from '@lib/Config'

import { AppPlugin } from './AppPlugin'
import { CartPlugin } from './CartPlugin'

interface ScrollPosition {
  x: number
  y: number
}
interface ScrollPositionArgs {
  prevPos: ScrollPosition
  currPos: ScrollPosition
}

export const NAVIGATION_DEFAULT_STATE: NavigationFragment = {
  id: v4(),
  items: [],
  testing: false,
  openNavItem: null,
  activeNavItem: null,
  activeSubNavItem: null,
  path: null,
  mobileOpenNavItem: null,
  mobileMenuOpen: false,
  mobileSubMenuOpen: false,
  userMobileMenuOpen: false,
  __typename: 'Navigation',
}

const _data = makeVar<NavigationFragment>({ ...NAVIGATION_DEFAULT_STATE })

export class NavigationPlugin implements ConfigPlugin {

  static instance: NavigationPlugin

  static shared(): NavigationPlugin {
    if (!this.instance) {
      this.instance = new NavigationPlugin()
    }
    return this.instance
  }

  async configure(): Promise<void> {

  }

  closeAllMenus(): void {
    _data(update(_data(), {
      mobileMenuOpen: { $set: false },
      mobileSubMenuOpen: { $set: false },
      userMobileMenuOpen: { $set: false },
    }))
  }

  setNavigation(items: NavItemFragment[], testing = false): void {

    _data({
      ...NAVIGATION_DEFAULT_STATE,
      testing,
      items,
    })

  }

  addNavItem(item: NavItemFragment, index?: number): void {

    const data = _data()
    const pushes = !index || index > data.items.length - 1
    const navigation = update(data, {
      items: pushes ? { $push: [item as any] } : { $splice: [[index, 0, item as any]] },
    })
    _data(navigation)

  }

  removeNavItem(id: string): void {

    const data = _data()
    const index = data.items.findIndex((item: NavItemFragment) => item.id === id)
    const navigation = update(data, {
      items: { $splice: [[index, 1]] },
    })
    _data(navigation)

  }

  addSubNavItem(parentId: string, item: SubNavItemFragment, index?: number): void {

    const data = _data()
    const pushes = !index || index > (data.items.find((navItem: NavItemFragment) => navItem.id === parentId) as MainNavContainerFragment).items.length - 1
    const navigation = update(data, {
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.id === parentId) {
            return update(navItem, {
              items: pushes ? { $push: [item as any] } : { $splice: [[index, 0, item as any]] },
            })
          }
          return navItem
        }),
      },
    })
    _data(navigation)

  }

  removeSubNavItem(parentId: string, id: string): void {

    const data = _data()
    const subIndex = (data.items.find((navItem: NavItemFragment) => navItem.id === parentId) as MainNavContainerFragment).items.findIndex((subNavItem) => subNavItem.id === id)
    const navigation = update(data, {
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.id === parentId) {
            return update(navItem, {
              items: { $splice: [[subIndex, 1]] },
            })
          }
          return navItem
        }),
      },
    })
    _data(navigation)

  }

  selectNav(id: string, isHover: boolean): string | null {

    let returnValue: string | null = null
    const data = _data()
    const navigation = update(data, {
      openNavItem: { $set: id },
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.__typename === 'MainNavContainer') {
            return update(navItem, {
              open: { $set: navItem.id === id },
            })
          }
          if (navItem.__typename === 'MainNavMultiItemContainer' && navItem.id === id) {
            return update(navItem, {
              open: { $set: navItem.id === id },
            })
          }
          if (navItem.__typename === 'MainNavItem' && navItem.id === id) {
            returnValue = navItem.href
          }
          return navItem
        }),
      },
    })
    _data(navigation)
    if (returnValue && !isHover) {
      this.setPath(returnValue)
    }
    if (returnValue && data.testing) {
      // console.log(`NAVIGATING TO: ${returnValue}`)
      this.setPath(returnValue)
      returnValue = null
    }
    return returnValue
  }

  selectSubNav(parentId: string, id: string): string | null {

    let returnValue: string | null = null
    const isMobile = AppPlugin.shared().isMobile()
    const data = _data()
    const navigation = update(data, {
      openNavItem: { $set: isMobile ? data.openNavItem : null },
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.__typename === 'MainNavContainer') {
            if (navItem.id === parentId) {
              return update(navItem, {
                open: { $set: isMobile ? navItem.open : false },
                items: {
                  $apply: (subNavItems: SubNavItemFragment[]) => subNavItems.map((subNavItem) => {
                    if (subNavItem.id === id) {
                      returnValue = subNavItem.href
                    }
                    return subNavItem
                  }),
                },
              })
            }
            return update(navItem, {
              open: { $set: isMobile ? navItem.open : false },
            })
          } else if (navItem.__typename === 'MainNavMultiItemContainer' && navItem.id === parentId) {
            if (navItem.id === parentId) {
              returnValue = navItem.href
              return update(navItem, {
                open: { $set: isMobile ? navItem.open : false },
              })
            }
            returnValue = navItem.href
            return update(navItem, {
              open: { $set: isMobile ? navItem.open : false },
            })
          }
          return navItem
        }),
      },
    })
    _data(navigation)
    if (returnValue) {
      this.setPath(returnValue)
    }
    if (returnValue && data.testing) {
      // console.log(`NAVIGATING TO: ${returnValue}`)
      this.setPath(returnValue)
      returnValue = null
    }
    return returnValue

  }

  setPath(path: string): void {

    const data = _data()
    let navId = ''
    let subNavId = ''
    let foundMatch = false
    for (let n = 0; n < data.items.length; n++) {
      if (data.items[n].__typename === 'MainNavItem') {
        const navItem = data.items[n] as MainNavItemFragment
        if (navItem.exactPath && navItem.path === path) {
          navId = data.items[n].id
          foundMatch = true
          break
        }
      } else if (data.items[n].__typename === 'MainNavContainer') {
        const navItem = data.items[n] as MainNavContainerFragment
        for (let s = 0; s < navItem.items.length; s++) {
          if (navItem.items[s].exactPath && navItem.items[s].path === path) {
            navId = data.items[n].id
            subNavId = navItem.items[s].id
            foundMatch = true
            break
          }
        }
      }
      if (foundMatch) {
        break
      }
    }
    if (!foundMatch) {
      for (let n = 0; n < data.items.length; n++) {
        if (data.items[n].__typename === 'MainNavItem') {
          const navItem = data.items[n] as MainNavItemFragment
          const regex = new RegExp(navItem.path)
          if (!navItem.exactPath) {
            if (path.match(regex)) {
              navId = data.items[n].id
              foundMatch = true
              break
            }
          }
        } else if (data.items[n].__typename === 'MainNavContainer') {
          const navItem = data.items[n] as MainNavContainerFragment
          for (let s = 0; s < navItem.items.length; s++) {
            if (!navItem.items[s].exactPath) {
              const regex = new RegExp(navItem.items[s].path)
              if (path.match(regex)) {
                navId = data.items[n].id
                subNavId = navItem.items[s].id
                foundMatch = true
                break
              }
            }
          }
        }
        if (foundMatch) {
          break
        }
      }
    }
    const navigation = update(data, {
      path: { $set: path },
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.__typename === 'MainNavContainer') {
            return update(navItem, {
              active: { $set: navItem.id === navId },
              items: {
                $apply: (subNavItems: SubNavItemFragment[]) => subNavItems.map((subNavItem) => update(subNavItem, {
                  active: { $set: navItem.id === navId && subNavItem.id === subNavId },
                })),
              },
            })
          }
          return update(navItem, {
            active: { $set: navItem.id === navId },
          })
        }),
      },
    })
    _data(navigation)

  }

  isNavUserSubNav = (id: string): boolean => defaultUserSubNav.some(subNav => subNav.id === id)

  closeOpenSubNav(): void {
    const data = _data()
    const navigation = update(data, {
      items: {
        $apply: (navItems: NavItemFragment[]) => navItems.map((navItem) => {
          if (navItem.__typename === 'MainNavContainer') {
            return update(navItem, {
              open: { $set: false },
            })
          }
          if (navItem.__typename === 'MainNavMultiItemContainer') {
            return update(navItem, {
              open: { $set: false },
            })
          }
          return navItem
        }),
      },
    })
    _data(navigation)

  }

  getScrollPosition(): { x: number, y: number } {
    if (typeof window === 'undefined') return { x: 0, y: 0 }

    const position = document.body.getBoundingClientRect()

    return { x: position.left, y: position.top }
  }

  useScrollPosition(effect: ({ prevPos, currPos }: ScrollPositionArgs) => any, dependants: any[], wait: number): void {
    const position = useRef(this.getScrollPosition())

    let throttleTimeout: any = null

    const callBack = () => {
      const currPos = this.getScrollPosition()
      effect({ prevPos: position.current, currPos })
      position.current = currPos
      throttleTimeout = null
    }

    useIsomorphicLayoutEffect(() => {
      const handleScroll = () => {
        if (wait) {
          if (throttleTimeout === null) {
            throttleTimeout = setTimeout(callBack, wait)
          }
        } else {
          callBack()
        }
      }
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
      }
    }, dependants)
  }

  resetMenuNav = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: false },
      mobileSubMenuOpen: { $set: false },
      userMobileMenuOpen: { $set: false },
    }))
  }

  mainMenuIsOpen(): boolean {
    return _data().mobileMenuOpen
  }

  toggleMainMenu = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: !navigation?.mobileMenuOpen },
    }))
  }

  toggleSubMenu = (navItem?: NavItemFragment): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: true },
      mobileSubMenuOpen: { $set: !navigation?.mobileSubMenuOpen },
      mobileOpenNavItem: { $set: navItem },
    }))
  }

  toggleUserMenu = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: true }, // was this always true?
      userMobileMenuOpen: { $set: !navigation?.userMobileMenuOpen },
    }))
  }

  toggleUserMenuApplication = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      userMobileMenuOpen: { $set: !navigation?.userMobileMenuOpen },
      mobileMenuOpen: { $set: false },
      mobileSubMenuOpen: { $set: false },
    }))
    CartPlugin.shared().closeOnNativeApplication()
  }

  toggleMainMenuApplication = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: !navigation?.mobileMenuOpen },
      mobileSubMenuOpen: { $set: false },
      userMobileMenuOpen: { $set: false },
    }))
    CartPlugin.shared().closeOnNativeApplication()
  }

  closeMainMenuMobile = (): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: !navigation?.mobileMenuOpen },
    }))
  }
  toggleSubMenuApplication = (navItem?: NavItemFragment): void => {
    const navigation = _data()
    _data(update(navigation, {
      mobileMenuOpen: { $set: false },
      mobileSubMenuOpen: { $set: !navigation?.mobileSubMenuOpen },
      userMobileMenuOpen: { $set: false },
      mobileOpenNavItem: { $set: navItem },
    }))
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    navigation: {
      read(): NavigationFragment {
        const data = _data()
        return data as NavigationFragment
      },
    },
  })

  possibleTypes = (): { [k: string]: string[] } => ({
    NavItem: ['MainNavItem', 'MainNavContainer', 'MainNavMultiItemContainer'],
  })

  types = (): DocumentNode => gql`
    type SubNavItem {
      id: ID!
      title: String!
      href: String
      active: Boolean!
      path: String!
      exactPath: Boolean!
    }

    interface NavItem {
      id: ID!
      title: String!
      active: Boolean!
    }

    type MainNavItem implements NavItem {
      id: ID!
      title: String!
      active: Boolean!
      href: String
      path: String!
      exactPath: Boolean!
      pillTitle: String
      isExternalNavigation: Boolean

    }

    type MainNavMultiItemContainer implements NavItem {
      id: ID!
      title: String!
      pillTitle: String
      active: Boolean!
      open: Boolean!
      href: String
      path: String!
    }

    type MainNavContainer implements NavItem {
      id: ID!
      title: String!
      pillTitle: String
      active: Boolean!
      open: Boolean!
      items: [SubNavItem!]!
      isExternalNavigation: Boolean

    }

    type Navigation {
      id: ID!
      items: [NavItem]!
      testing: Boolean!
      openNavItem: String
      activeNavItem: String
      activeSubNavItem: String
      path: String
      mobileOpenNavItem: NavItem
      mobileMenuOpen: Boolean!
      mobileSubMenuOpen: Boolean!
      userMobileMenuOpen: Boolean!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      navigation: Navigation!
    }
  `

  queries = (): DocumentNode => gql`
    fragment SubNavItemFragment on SubNavItem {
      id
      title
      href
      active
      path
      exactPath
    }

    fragment MainNavItemFragment on MainNavItem {
      id
      title
      href
      active
      path
      exactPath
      pillTitle
      isExternalNavigation
    }

    fragment MainNavContainerFragment on MainNavContainer {
      id
      title
      pillTitle
      active
      open
      items {
        ...SubNavItemFragment
      }
      isExternalNavigation
    }

    fragment MainNavMultiItemContainerFragment on MainNavMultiItemContainer {
      id
      title
      pillTitle
      active
      open
      href
      path
    }

    fragment NavItemFragment on NavItem {
      id
      title
      active
      ... on MainNavItem {
        ... MainNavItemFragment
      }
      ... on MainNavContainer {
        ... MainNavContainerFragment
      }
      ... on MainNavMultiItemContainer {
        ... MainNavMultiItemContainerFragment
      }
    }

    fragment NavigationFragment on Navigation {
      id
      items {
        ...NavItemFragment
      }
      openNavItem
      activeNavItem
      activeSubNavItem 
      testing
      path
      mobileOpenNavItem {
        ...NavItemFragment
      }
      mobileMenuOpen
      mobileSubMenuOpen
      userMobileMenuOpen
    }

    query GetNavigation {
      navigation @client {
        ... NavigationFragment
      }
    }
  `

}
