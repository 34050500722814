import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { Link, Paragraph, Rule, Button, Spacer } from '@atoms/index'
import { DashedButtonContainer, ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { CreditCardFragment, useUserDetailsQuery, UserDetailsDocument, UserCartDocument, useRemoveCreditCardMutation, useUpdateCreditCardMutation, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { TableCell, TableRow, RadioButtonControl, SectionLoadingOverlay } from '@molecules/index'
import { ConfirmDeleteModal, CreditCardModal } from '@organisms/modals'

const Container = styled.div<{$opacity:string}>`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${(props) => props.$opacity === '1' ? '1' : '0.45'};
`
const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const RadioContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 0 0 16px', tablet: '0 16px 0 16px', desktop: '0 12px 0 24px' })}
`

const MobileContainer = styled.div`
  display: inline-block;
  ${(props): string => props.theme.desktop} {
    display: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  width: 100%;
  ${(props): string => props.theme.desktop} {
    display: inline-block;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ResponsivePXValue('width', '100%')}
`

const AddButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${ResponsivePXValue('width', 'CALC(100% - 48px)')}
  ${ResponsivePXValue('padding', '16px 24px 0px')}
    
  .dashed-button {
    ${DashedButtonContainer()}
  }
`

interface UserCardListState {
  loading: boolean
  creditCards: CreditCardFragment[]
  cardToBeDeleted: CreditCardFragment | null
  addCardModal: boolean
  confirmDeleteModalOpen: boolean
}

export interface UserCardListProps {
  didAddCard?: (creditCardId: string) => void
}

const DEFAULT_STATE: UserCardListState = {
  loading: false,
  creditCards: [],
  cardToBeDeleted: null,
  addCardModal: false,
  confirmDeleteModalOpen: false,
}

export function UserCardList({ didAddCard }: UserCardListProps): JSX.Element {

  const config = useConfig()
  const { data, loading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [state, setState] = useState<UserCardListState>({ ...DEFAULT_STATE })
  const [removeCreditCard] = useRemoveCreditCardMutation()
  const [updateCreditCard] = useUpdateCreditCardMutation()
  const { addToast } = useToasts()
  const theme = useTheme()

  useEffect(() => {
    if (data?.currentUser) {
      const registeredUser = data.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment

      if (registeredUser.creditCards?.length > 0) {
        const sortedCards = [
          ...registeredUser.creditCards.filter((card) => card.isDefault),
          ...registeredUser.creditCards.filter((card) => !card.isDefault),
        ]

        setState((prevState) => update(prevState, {
          creditCards: { $set: sortedCards },
        }))
      }
    }
  }, [data])

  const _handleAddNew = (): void => {
    setState((prevState) => update(prevState, {
      addCardModal: { $set: true },
    }))
  }

  const _handleAddCardModalClose = (): void => {
    setState((prevState) => update(prevState, {
      addCardModal: { $set: false },
    }))
  }

  const _handleDeleteModalClose = (): void => {
    setState((prevState) => update(prevState, {
      confirmDeleteModalOpen: { $set: false },
    }))
  }

  const _handleDeleteCard = (card: CreditCardFragment): void => {
    setState((prevState) => update(prevState, {
      confirmDeleteModalOpen: { $set: true },
      cardToBeDeleted: { $set: card },
    }))
  }

  const _deleteCard = (): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
      confirmDeleteModalOpen: { $set: false },
    }))
    if (state.cardToBeDeleted) {
      const id = state.cardToBeDeleted.id
      try {
        removeCreditCard({
          variables: {
            id,
          },
          refetchQueries: [{ query: UserDetailsDocument }, { query: UserCartDocument }],
          awaitRefetchQueries: true,
        })

        addToast('Card successfully deleted!', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } else {
      addToast('Card not found!', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, {
      loading: { $set: false },
      cardToBeDeleted: { $set: null },
    }))
  }
  const _handleMakeDefault = async (id: string): Promise<void> => {

    setState((prevState) => ({ ...prevState, loading: true }))

    try {
      await updateCreditCard({
        variables: {
          id,
          input: {
            isDefault: true,
          },
        },
        refetchQueries: [{ query: UserDetailsDocument }, { query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      addToast('Default card successfully updated!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => ({ ...prevState, loading: false }))
  }

  const getCardNickName = (card: CreditCardFragment): string => card.isValidated ? card.cardNickname : `Card Not Validated - ${card.cardNickname}`

  let creditCard: CreditCardFragment

  return (
    <PaymentContainer>
      <CreditCardModal open={state.addCardModal} onClose={_handleAddCardModalClose} didAddCard={didAddCard} />
      <ConfirmDeleteModal
        open={state.confirmDeleteModalOpen}
        title='Delete card'
        action='CARD'
        content='You are about to delete your card! Are your sure you want to proceed?'
        onDelete={_deleteCard}
        onClose={_handleDeleteModalClose} />
      <Choose>
        <When condition={state.creditCards.length > 0} >
          <For each='creditCard' of={state.creditCards}>
            <DesktopContainer>
              <Container $opacity={(state.loading || loading) ? '0.45' : '1'}>
                <TableRow>
                  <TableCell align='center' justify='center' noZeroSpace>
                    <Choose>
                      <When condition={creditCard.isDefault}>
                        <RadioContainer>
                          <RadioButtonControl
                            selected={creditCard.isDefault} />
                        </RadioContainer>
                      </When>
                      <Otherwise>
                        <RadioContainer>
                          <RadioButtonControl
                            selected={creditCard.isDefault}
                            visible={creditCard.isValidated}
                            onClick={() => _handleMakeDefault(creditCard.id)} />
                        </RadioContainer>
                      </Otherwise>
                    </Choose>
                  </TableCell>
                  <TableCell direction='column' grow='1' padding='24px'>
                    <Paragraph variant='p2' color={creditCard.isValidated ? theme.colors.greys.mineshaft : theme.colors.reds.cautionRed}>{getCardNickName(creditCard)}</Paragraph>
                    <If condition={!!creditCard.cardBrand && !!creditCard.nameOnCard} >
                      <Paragraph variant='p1'>{creditCard.cardBrand + ', ' + creditCard.nameOnCard}</Paragraph>
                    </If>
                    <Paragraph variant='p1'>{'**** **** **** ' + creditCard.last4Digits}</Paragraph>
                    <If condition={!!creditCard.expiryMonth && !!creditCard.expiryYear} >
                      <Paragraph variant='p3' color={theme.colors.slates.bitter}>{'Expiry: ' + creditCard.expiryMonth + '/' + creditCard.expiryYear}</Paragraph>
                    </If>
                  </TableCell>
                  <If condition={!creditCard.isDefault}>
                    <TableCell padding='0 24px 0 0' align='center' justify='center'>
                      <Link color={theme.colors.slates.ash} onClick={() => _handleDeleteCard(creditCard)}>DELETE</Link>
                    </TableCell>
                  </If>
                </TableRow>
                <Rule color='slate' />
              </Container>
            </DesktopContainer>
            <MobileContainer>
              <Container $opacity={(state.loading || loading) ? '0.45' : '1'}>
                <TableRow>
                  <TableCell noZeroSpace justify='center' align='center' direction='column'>
                    <Choose>
                      <When condition={creditCard.isDefault}>
                        <RadioContainer>
                          <RadioButtonControl
                            selected={creditCard.isDefault} />
                        </RadioContainer>
                      </When>
                      <Otherwise>
                        <RadioContainer>
                          <RadioButtonControl
                            selected={creditCard.isDefault}
                            onClick={() => _handleMakeDefault(creditCard.id)} />
                        </RadioContainer>
                      </Otherwise>
                    </Choose>
                  </TableCell>
                  <TableCell padding='24px' direction='column' justify='center'>
                    <Paragraph variant='p2' color={creditCard.isValidated ? theme.colors.greys.mineshaft : theme.colors.reds.cautionRed}>{getCardNickName(creditCard)}</Paragraph>
                    <If condition={!!creditCard.cardBrand && !!creditCard.nameOnCard} >
                      <Paragraph variant='p1'>{creditCard.cardBrand + ', ' + creditCard.nameOnCard}</Paragraph>
                    </If>
                    <Paragraph variant='p1'>{'**** **** **** ' + creditCard.last4Digits}</Paragraph>
                    <If condition={!!creditCard.expiryMonth && !!creditCard.expiryYear} >
                      <Paragraph variant='p3' color={theme.colors.slates.bitter}>{'Expiry: ' + creditCard.expiryMonth + '/' + creditCard.expiryYear}</Paragraph>
                    </If>
                  </TableCell>
                </TableRow>
                <If condition={!creditCard.isDefault}>
                  <TableRow>
                    <TableCell padding='0 24px'>
                      <Link color={theme.colors.slates.ash} onClick={() => _handleDeleteCard(creditCard)}>DELETE</Link>
                    </TableCell>
                  </TableRow>
                </If>
                <Rule color='slate' />
              </Container>
            </MobileContainer>
          </For>
          <ButtonContainer>
            <Spacer universal='16px' />
            <Link color={theme.colors.oranges.coral} bold onClick={_handleAddNew}>ADD A NEW CARD</Link>
          </ButtonContainer>
        </When>
        <Otherwise>
          <AddButtonContainer>
            <Button className='dashed-button' fullWidth color='transparent' title='ADD A CARD' onClick={_handleAddNew} />
          </AddButtonContainer>
        </Otherwise>
      </Choose>
      <If condition={state.loading || loading} >
        <SectionLoadingOverlay/>
      </If>
    </PaymentContainer>
  )

}
