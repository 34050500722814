import React, { useEffect, useRef } from 'react'

import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'

import { Heading, Paragraph, Spacer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useUserCartQuery } from '@hooks/api'

const DiscountContainer = styled.div<{ $isMiniCard: boolean}>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.whites.pureWhite};
  border: 2px solid ${({ theme }) => theme.colors.oranges.burntSienna};
  ${ResponsivePXValue('padding', '12px')}

  .incentive-text {
    margin-bottom: 0;
  }

  ${(props): CSS => {
    if (props.$isMiniCard) {
      return `
      `
    }
  }}
`

export interface WineDiscountProps {
  isMiniCard?: boolean
  className?: string
}

export const WineDiscount = React.memo(({ isMiniCard = false, className }: WineDiscountProps): JSX.Element => {

  const config = useConfig()
  const { addToast } = useToasts()
  const { data: userCartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const prevNumberOfWinesInCart = useRef(0)

  const cartItems = userCartData?.currentUser?.activeCart?.cartItems || []

  const getTotalWineInCart = () => {
    let totalWine = 0

    for (const cartItem of cartItems) {
      if (cartItem.product.__typename === 'Wine') {
        totalWine += cartItem.quantity
      }
    }

    return totalWine
  }

  useEffect(() => {

    if (!cartLoading) {

      const numberOfWinesInCart = getTotalWineInCart()
      const quantityNeededForDiscount = numberOfWinesInCart >= 3 ? 0 : 3 - numberOfWinesInCart

      if (prevNumberOfWinesInCart.current > numberOfWinesInCart) return

      if (numberOfWinesInCart === 3) {
        addToast('Congratulations, 25% discount applied!', {
          appearance: 'success',
          autoDismiss: true,
        })
      } else if (numberOfWinesInCart > 0 && numberOfWinesInCart < 3) {
        addToast(
          <DiscountContainer className={className} $isMiniCard={isMiniCard}>
            <Heading className='discount' variant='h5' color={theme.colors.oranges.coral}> 25% OFF </Heading>
            <Spacer universal='16px' variant='horizontal' />
            <Paragraph variant='p2' bold className='incentive-text'> when you add any {quantityNeededForDiscount} extra {quantityNeededForDiscount > 1 ? 'wines' : 'wine'!} </Paragraph>
          </DiscountContainer>, {
            appearance: 'info',
            autoDismiss: true,
          })
      }

      prevNumberOfWinesInCart.current = numberOfWinesInCart
    }

  }, [cartItems.length])

  return (<></>)
})

WineDiscount.displayName = 'WineDiscount'
