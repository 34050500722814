import React from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { ExecutionResult } from 'graphql'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Paragraph, Spacer } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { MenuHelper } from '@client/lib/MenuHelper'
import { useEvents } from '@contexts/GTMProvider'
import { SaveUserMenuMutation, useSaveUserMenuMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const ButtonContainer = styled.div`
  width: 100%;

  .save-button {
    border-radius: 0;
    ${ResponsivePXValue('height', '45px')}
  }
`
const FreeDeliveryMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  width: fit-content;
  border-radius: 0px 15px 15px 0px;
  background-color: ${theme.colors.greens.fruitSalad};
  ${ResponsivePXValue('padding', '6px 12px')}

  .delivery-message{
    line-height: 1.2;
  }
`

export function CartFooter(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: cartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const cart = cartData?.currentUser?.activeCart
  const [saveMenu] = useSaveUserMenuMutation()
  const { addToast } = useToasts()
  const hasMealKits = (cart?.cartItems || []).filter(cartItem => cartItem.isFromSubscription).length > 0
  const hasAddOns = (cart?.cartItems || []).filter(cartItem => !cartItem.isFromSubscription).length > 0
  const freeDeliveryThreshold = 450
  const addOnItems = (cart?.cartItems || []).filter(cartItem => !cartItem.isFromSubscription)
  const subTotal = addOnItems.reduce((total, item) => total + item.price, 0)
  const reductions = cart?.reductions?.reduce((total, item) => total + item.value, 0)
  const event = useEvents()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const shouldDisplayFreeDeliveryMessage = (): boolean => {
    return cart?.cartItems?.length > 0 && hasAddOns && !hasMealKits && (subTotal + reductions) < freeDeliveryThreshold
  }

  const freeDeliveryMessage = shouldDisplayFreeDeliveryMessage() ? `You are R${(freeDeliveryThreshold - (subTotal)).toFixed(2)} away from free shipping!` : null

  const checkoutStatus = cartData?.currentUser?.checkoutStatus
  const hasOnDemandItems = cartData?.currentUser?.activeCart?.totalNonSubscriptionItems > 0

  let checkoutDisabled = true

  if (checkoutStatus?.hasActiveSubscription && (checkoutStatus?.hasConfiguredBox || hasOnDemandItems) && !checkoutStatus?.subscriptionIsSaved) {
    checkoutDisabled = false
  } else if ((checkoutStatus?.hasPausedSubscription || !checkoutStatus?.hasActiveSubscription) && (checkoutStatus?.hasConfiguredBox || hasOnDemandItems)) {
    checkoutDisabled = false
  }

  let buttonText = 'Checkout'
  if (checkoutStatus?.hasActiveSubscription && !checkoutDisabled) {
    buttonText = 'Save my cart'
  } else if (checkoutStatus?.hasConfiguredBox && !checkoutDisabled) {
    buttonText = 'Save'
  } else if ((checkoutStatus?.hasActiveSubscription || checkoutStatus?.hasConfiguredBox) && checkoutDisabled) {
    buttonText = 'Cart Saved'
  }

  const shouldDisplayCrossSellingModal = () => {
    if (config.isBrowser()) {
      return !window.sessionStorage.getItem('displayCrossSellingModal')
    }
    return false
  }

  const _displayCrossSellingModal = () => {
    if (config.isBrowser()) {
      window.sessionStorage.setItem('displayCrossSellingModal', 'true')
    }
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAGE_MODAL, 'FROZEN_KIT_CROSS_SELLING')
  }

  const _displaySuccessMessage = () => {
    if (shouldDisplayCrossSellingModal()) {
      _displayCrossSellingModal()
    } else {
      addToast('Your cart has been saved!', {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }

  const _saveUserMenu = async (id: string): Promise<void> => {

    const result: ExecutionResult<SaveUserMenuMutation> = await saveMenu({
      variables: {
        id,
      },
    })
    CheckoutPlugin.shared().setCartErrors(client, [...result.data.userMenuSave.errors])
    const notifications = [...result.data.userMenuSave.notification]
    if (notifications.length) {

      CheckoutPlugin.shared().setCartErrors(client, [...result.data.userMenuSave.errors])

      const notification= notifications[0]
      addToast(notification, {
        appearance: 'success',
        autoDismiss: true,
      })
    }
  }

  const _saveAndLogMenu = () => {

    _saveUserMenu(cartData?.currentUser?.activeMenu?.id)
    const discounts = userCartData?.currentUser?.activeCart?.reductions
    let discountTitles = 'no discount'
    let discountValue = 0

    for (let i = 0; i < discounts.length; i++) {
      discountTitles = discountTitles + discounts[i].title
      discountValue = discountValue + discounts[i].value
    }
    const deliveryFee = userCartData?.currentUser?.activeCart?.additions?.find((addition) => addition.title === 'Delivery Fee')?.value
    const cartItemPrice = userCartData?.currentUser?.activeCart?.grandTotal
    const totalPrice = deliveryFee + cartItemPrice
    event.hasSavedCart(userCartData?.currentUser?.activeCart?.cartItems, totalPrice, discountTitles, discountValue, userDetailsData?.currentUser?.id)

    _displaySuccessMessage()
    CartPlugin.shared().close()

  }

  const _handleSave = async (): Promise<void> => {
    const shouldSave = await MenuHelper.shouldSaveMenu(client)
    if (shouldSave) {
      _saveAndLogMenu()
    } else {
      CheckoutPlugin.shared().checkout(client)
    }
  }

  return (
    <FooterContainer>
      <If condition={!!freeDeliveryMessage}>
        <Spacer universal='12px' />
        <FreeDeliveryMessageContainer>
          <Paragraph className='delivery-message' variant='p2' bold color={theme.colors.whites.pureWhite}>{freeDeliveryMessage}</Paragraph>
        </FreeDeliveryMessageContainer>
        <Spacer universal='12px' />
      </If>
      <ButtonContainer>
        <Button
          loading={false}
          className='save-button'
          title={buttonText.toUpperCase()}
          fluid
          disabled={checkoutDisabled}
          onClick={_handleSave}
        />
      </ButtonContainer>
    </FooterContainer>
  )
}
