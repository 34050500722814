import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator, Spacer } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { MealKitDishDetailsFragment, useGetRelatedDishesQuery } from '@hooks/api'
import { MealKitCard, SectionLoading } from '@molecules/index'
export interface MealKitYouMightAlsoLikeProps {
  dish: MealKitDishDetailsFragment
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${ResponsiveProperty('margin-top', { mobile: '6vw', tablet: '4vw', desktop: '2vw' })}

  .meal-kit-card {
    ${ResponsivePXValue('height', { mobile: '550px', tablet: '550px', desktop: '525px' })}
    ${ResponsiveProperty('margin', { mobile: '0 0 6vw', tablet: '0 0 4vw', desktop: ' 0 0 2vw' })}
  }
  
`

export function MealKitYouMightAlsoLike({ dish }: MealKitYouMightAlsoLikeProps): JSX.Element {

  const ids = dish?.related?.map((item) => item.id)
  const { data, loading } = useGetRelatedDishesQuery({ variables: { ids }, skip: !ids })
  const theme = useTheme()

  const mealKits = data?.related?.list.slice(0, theme.isTablet() ? 4 : 3) || []

  let mealKit: MealKitDishDetailsFragment

  return (
    <Choose>
      <When condition={!(mealKits.length === 0)}>
        <Container id='mealKitYouMightLike'>
          <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>You might also like</Heading>
          <Spacer universal='8px' />
          <Seperator align='center' />
          <CardContainer>
            <For each='mealKit' of={mealKits || []}>
              <MealKitCard
                className='meal-kit-card'
                key={mealKit.id}
                mealKit={mealKit} />
            </For>
          </CardContainer>
        </Container>
      </When>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <Otherwise>

      </Otherwise>
    </Choose>
  )

}
