import React, { useEffect, useState } from 'react'

import { FieldData } from 'rc-field-form/lib/interface'
import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button, Link, Spacer } from '@atoms/index'
import { ModalActionContainer, ResponsiveProperty, ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { UserAddressFragment, UserCartDocument, UserDetailsDocument, useAddLocationMutation, useRemoveUserAddressMutation, useUpdateLocationMutation, useUpdateUserAddressMutation, useUserDetailsQuery } from '@hooks/api'
import { Form, SelectInput, TextInput, TextAreaInput, useForm } from '@molecules/index'
import { ConfirmDeleteModal } from '@organisms/modals'
import { BuildingTypeEnum } from '@uctypes/api/globalTypes'

import { PlaceIdOption, PlaceIdLookup } from '../misc'
import DraggableMap, { Coordinates, MapDragged } from '../misc/DraggableMap'

const Container = styled.div`

 align-self: stretch;

  ${ResponsivePXValue('margin', '0 16px')}

  .submit-button {
    ${ResponsivePXValue('margin', '16px 0')}
  }
`

const StreetContainer = styled.div`
  display: flex;
  align-items: stretch;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

const InputSeperator = styled.div`
  display: flex;
  ${ResponsivePXValue('width', '12px')}
`

const ActionContainer = styled.div<{ $stickyBottom: boolean }>`
  ${ModalActionContainer(true)};
`

const OuterMapContainer = styled.div`
  display: flex;
  justify-self: center;
  ${ResponsivePXValue('padding', '20px 0')}
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', { mobile: '236px', tablet: '277px', desktop: '318px' })}
`

const MapContainer = styled.div`
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', '100%')}
`

interface EditAddressFormData {
  alias: string
  company: string
  buildingTypeEnum: BuildingTypeEnum | null
  buildingName: string | null
  unitNumber: string | null
  instructions: string
  streetName: string | null
  locationName: string | null
  streetNumber: string | null
}

export interface EditAddressFunctionProps {
  placeId: string
  placeCoordinates: Coordinates
  buildingTypeEnum: BuildingTypeEnum
  locationName: string
  streetName: string
  streetNumber: string
  alias?: string
  company?: string
  buildingName?: string
  unitNumber?: string
  instructions?: string
}

export interface EditAddressFormProps {
  userAddress: UserAddressFragment
  onSuccess: () => void
  onError: (message: string) => void
}

interface EditAddressFormState {
  loading: boolean
  buildingTypeEnum: BuildingTypeEnum
  trigger: boolean
  confirmDeleteModalOpen: boolean
  placeCoordinates: Coordinates | null
  types: string[]
  locationId?: string
}

const DEFAULT_STATE: EditAddressFormState = {
  loading: false,
  trigger: false,
  confirmDeleteModalOpen: false,
  buildingTypeEnum: BuildingTypeEnum.HOUSE,
  placeCoordinates: null as Coordinates | null,
  types: [],
  locationId: '',
}

export function EditAddressForm({ userAddress, onSuccess, onError }: EditAddressFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<EditAddressFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [updateUserAddressMutation] = useUpdateUserAddressMutation()
  const [addLocationMutation] = useAddLocationMutation()
  const [updateLocationMutation] = useUpdateLocationMutation()
  const [removeUserAddress] = useRemoveUserAddressMutation()
  const { addToast } = useToasts()
  const [form] = useForm()

  useEffect(() => {
    setBuildingTypeEnum(userAddress?.location?.buildingTypeEnum)
  }, [userAddress])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      placeCoordinates: { $set: userAddress.location.center },
      locationId: { $set: userAddress.location.id },
    }))
  }, [])

  useEffect(() => {

    if (state.trigger) {
      form.setFieldsValue({
        streetNumber: userAddress?.location?.streetNumber ?? '',
        streetName: userAddress?.location?.streetName ?? '',
        locationName: userAddress?.location?.locationName ?? '',
        alias: userAddress?.alias ?? '',
        company: userAddress?.company ?? '',
        buildingTypeEnum: userAddress?.location?.buildingTypeEnum ?? BuildingTypeEnum.HOUSE,
        buildingName: userAddress?.location?.buildingName ?? '',
        unitNumber: userAddress?.location?.unitNumber ?? '',
        instructions: userAddress?.instructions ?? '',
      })
    }

  }, [userAddress, state.trigger])

  const setBuildingTypeEnum = (buildingTypeEnum: BuildingTypeEnum): void => {
    setState((prevState) => update(prevState, {
      buildingTypeEnum: { $set: buildingTypeEnum },
      trigger: { $set: true },
    }))
  }

  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _handleSubmit = async (data: EditAddressFormData): Promise<void> => {

    try {
      setLoading(true)
      const updateLocationResponse = await updateLocationMutation({
        variables: {
          id: state.locationId,
          input: {
            buildingTypeEnum: data.buildingTypeEnum,
            buildingName: data.buildingName,
            unitNumber: data.unitNumber,
            streetName: data.streetName,
            locationName: data.locationName,
            streetNumber: data.streetNumber,
            types: state.types,
          },
        },
      })

      await updateUserAddressMutation({
        variables: {
          id: userAddress?.id,
          input: {
            alias: data.alias,
            instructions: data.instructions,
            company: data.company,
            location: updateLocationResponse?.data?.updateLocation?.id,
            user: userDetailsData?.currentUser?.id,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: SiteHelper.getUserRefetchQueries(),
      })
      setLoading(false)
      onSuccess()
    } catch (e) {
      onError(e.message)
    }
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'buildingTypeEnum') {
          setBuildingTypeEnum(field.value)
        }
      })
    })
  }

  const _handleDeleteModalClose = (): void => {
    setState((prevState) => update(prevState, {
      confirmDeleteModalOpen: { $set: false },
    }))
  }

  const _deleteAddress = (): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
      confirmDeleteModalOpen: { $set: false },
    }))

    try {
      removeUserAddress({
        variables: {
          id: userAddress.id,
        },
        refetchQueries: [{ query: UserDetailsDocument }, { query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      addToast('Address successfully deleted!', {
        appearance: 'success',
        autoDismiss: true,
      })
      onSuccess()
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    setState((prevState) => update(prevState, {
      loading: { $set: false },
    }))
  }

  const _handleMarkerDragged = async (mapDragged: MapDragged): Promise<void> => {
    try {
      setState((prevState) => ({
        ...prevState,
        locationLoading: false,
        placeCoordinates: {
          latitude: mapDragged.coordinates.latitude,
          longitude: mapDragged.coordinates.longitude,
        },
      }))

    } catch (e) {
      setState((prevState) => ({ ...prevState, locationLoading: false }))
      onError(e.message)
    }
  }

  const _handlePlaceSelect = async (placeId: string, option: PlaceIdOption): Promise<void> => {
    if (placeId) {
      const addLocationResponse = await addLocationMutation({
        variables: {
          input: {
            placeId,
            types: option.types,
            locationName: option.title.split(',')[0],
          },
        },
      })

      setState((prevState) => ({
        ...prevState,
        placeId,
        locationId: addLocationResponse.data.addLocation.id,
        types: addLocationResponse.data.addLocation.types as string[],
        buildingTypeEnum: BuildingTypeEnum.HOUSE,
        placeCoordinates: {
          latitude: addLocationResponse.data.addLocation.center.latitude,
          longitude: addLocationResponse.data.addLocation.center.longitude,
        },
      }))
      form.setFieldsValue({
        streetName: addLocationResponse.data.addLocation.streetName,
        streetNumber: addLocationResponse.data.addLocation.streetNumber,
        locationName: addLocationResponse.data.addLocation.locationName,
        alias: '',
        company: '',
        buildingTypeEnum: BuildingTypeEnum.HOUSE,
        buildingName: '',
        unitNumber: '',
        instructions: '',
      })
    }
  }

  const _handleSaveAddress = (): void => {
    if (config.isBrowser()) {
      window.sessionStorage.setItem('hasUserEnteredAddressFromModal', 'false')
    }
    form.submit()
  }

  const displayCompanyName = (state.buildingTypeEnum === BuildingTypeEnum.OFFICE_BUILDING || state.buildingTypeEnum === BuildingTypeEnum.OFFICE_COMPLEX) && state.buildingTypeEnum !== null
  const displayBuildingName = state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null

  const streetAddressTypes = ['street_address', 'premise']
  const isStreetNumberRequired = userAddress?.location?.types?.some(r => streetAddressTypes.includes(r))
  const loading = userDetailsLoading || state.loading

  return (
    <Container>
      <PlaceIdLookup
        onSelect={_handlePlaceSelect} />
      <Form
        form={form}
        disabled={loading}
        onFinish={_handleSubmit}
        onFieldsChange={_handleChange}>
        <ConfirmDeleteModal
          open={state.confirmDeleteModalOpen}
          title='Delete address'
          action='ADDRESS'
          content='You are about to delete your address! Are your sure you want to proceed?'
          onDelete={_deleteAddress}
          onClose={_handleDeleteModalClose} />
        <OuterMapContainer>
          <DraggableMap
            onChange={_handleMarkerDragged}
            center={state.placeCoordinates}
            containerElement={<MapContainer />}
            mapElement={<div style={{ height: '100%', width: '100%' }} />} />
        </OuterMapContainer>
        <StreetContainer id='streetAddressContainer'>
          <If condition={!isStreetNumberRequired && !userAddress?.location?.streetNumber}>
            <TextInput
              name='locationName'
              label='Place'
              placeholder='Place'
              showOptional={false}
              rules={[{ required: false, message: 'Please input a location name' }]} />
            <InputSeperator />
          </If>
          <TextInput
            name='streetNumber'
            label='Street number'
            showOptional={false}
            rules={[{ required: isStreetNumberRequired, message: 'Please input a street number' }]} />
          <InputSeperator />
          <TextInput
            name='streetName'
            label='Street name'
            rules={[{ required: true, message: 'Please input a street name' }]} />
        </StreetContainer>
        <TextInput
          name='alias'
          label='Address Nickname'
          placeholder='My House'
          rules={[{ required: true, message: 'Please input an alias' }]} />
        <SelectInput
          value={state.buildingTypeEnum}
          name='buildingTypeEnum'
          placeholder='Building Type'
          rules={[{ required: true, message: 'Please select a building type' }]}
          apiEnum='BuildingTypeEnum' />
        <If condition={displayCompanyName}>
          <TextInput
            name='company'
            label='Company name'
            placeholder='Enter company name' />
        </If>
        <If condition={displayBuildingName}>
          <TextInput
            name='buildingName'
            placeholder={state.buildingTypeEnum === BuildingTypeEnum.SECURITY_COMPLEX ? 'Complex name' : 'Building name'}
            disabled={!(state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null)}
            rules={[{ required: (state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null), message: 'Please input a building name' }]} />
          <TextInput
            name='unitNumber'
            placeholder='Unit Number / Floor'
            disabled={!(state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null)}
            rules={[{ required: (state.buildingTypeEnum !== BuildingTypeEnum.HOUSE && state.buildingTypeEnum !== null), message: 'Please input a unit number / floor' }]} />
        </If>
        <TextAreaInput
          name='instructions'
          placeholder='Enter your instruction here' />
        <Spacer universal='24px' />
        <ActionContainer $stickyBottom>
          <Button
            loading={loading}
            color='black'
            fullWidth
            title='SAVE ADDRESS'
            onClick={_handleSaveAddress} />
          <Spacer mobile='8px' desktop='16px' />
          <Link variant='l2' decoration='underline' onClick={onSuccess}> Cancel </Link>
        </ActionContainer>
      </Form>
    </Container>
  )
}
