import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Heading, ResponsiveImage, Rule, Paragraph } from '@atoms/index'
import { getLinkColorFromButtonColor, Link, scrollToLink, Spacer } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { CarouselCardWidth, getObjectFit, LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { PageCardFragment } from '@hooks/api'

const Container = styled.div<{ $height?: string, $mobileHeight?: string }>`

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  Overflow: hidden; 

  ${LiteBoxShadow}
  ${CarouselCardWidth}

  ${ResponsivePXValue('min-width', { mobile: 'min(82vw, 288px)', tablet: '288px', desktop: '272px' }, { desktop: true })}

  ${(props): CSS => (props?.$height) ? ResponsivePXValue('height', { mobile: props?.$mobileHeight, tablet: props?.$mobileHeight, desktop: props?.$height }) : null}
  ${(props): CSS => (props?.$height) ? ResponsivePXValue('min-height', { mobile: props?.$mobileHeight, tablet: props?.$mobileHeight, desktop: props?.$height }) : null}

  .description {
    ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px', desktop: '16px 24px' })}
  }
`

const ImageContainer = styled.div`
  width: 100%;

  ${ResponsivePXValue('height', { mobile: '154px', tablet: '230px', desktop: '230px' })}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px', desktop: '16px 24px' })}
`

const IconContainer = styled.div`
  display: flex;

  ${ResponsivePXValue('height', '36px')}
  ${ResponsivePXValue('width', '36px')}
  ${ResponsivePXValue('margin-right', '10px')}
`

export interface PageCardProps {
  pageCard: PageCardFragment
  height?: string
  mobileHeight?: string
  className?: string
}

export function PageCard({ pageCard, height, mobileHeight, className }: PageCardProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const linkUrl = pageCard?.link?.link
  const linkTitle = pageCard?.link?.title
  const linkColor = getLinkColorFromButtonColor('secondary', pageCard?.link?.color)

  const objectFit = getObjectFit(pageCard)

  const _handleLinkClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {

    if (e.defaultPrevented) return

    e.preventDefault()

    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container className={className} $height={height} $mobileHeight={mobileHeight} onClick={_handleLinkClick}>
      <If condition={!pageCard?.image?.isDefault}>
        <ImageContainer>
          <ResponsiveImage image={pageCard.image} objectFit={objectFit} />
        </ImageContainer>
      </If>
      <If condition={!!pageCard?.cardTitle}>
        <TitleContainer>
          <If condition={!pageCard?.icon?.isDefault}>
            <IconContainer>
              <ResponsiveImage image={pageCard.icon} />
            </IconContainer>
          </If>
          <Heading variant='h5' align='center'>{pageCard.cardTitle}</Heading>
        </TitleContainer>
      </If>
      <If condition={!!pageCard.description || !!linkTitle}>
        <Rule />
      </If>
      <If condition={!!pageCard.description}>
        <Paragraph align='center' variant='p1' className='description'>
          {pageCard.description}
        </Paragraph>
      </If>
      <If condition={!!linkTitle}>
        <Link href={linkUrl} color={linkColor} hoverColor={linkColor} variant='l1'>{linkTitle ?? `More About ${pageCard.cardTitle}`}</Link>
        <Spacer universal='24px' />
      </If>
    </Container>
  )
}
