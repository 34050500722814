import React from 'react'

import { Svg } from './Svg'

export interface CreditCardProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function CreditCard({ color, hoverColor, className }: CreditCardProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M28 5.33334H4.00004C2.52728 5.33334 1.33337 6.52725 1.33337 8.00001V24C1.33337 25.4728 2.52728 26.6667 4.00004 26.6667H28C29.4728 26.6667 30.6667 25.4728 30.6667 24V8.00001C30.6667 6.52725 29.4728 5.33334 28 5.33334Z" className='stroke' stroke="#D7D7CD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.33337 13.3333H30.6667" className='stroke' stroke="#D7D7CD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100%" height="100%" className='fill' />
        </clipPath>
      </defs>
    </Svg>
  )

}
