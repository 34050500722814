import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftDessertDetails')

const CraftDessertDetails = loadable(() => import(/* webpackChunkName: "craft-dessert-details" */'@pages/CraftDessertDetails'), {
  resolveComponent: (components) => components.CraftDessertDetails,
})

export function CraftDessertDetailsAsync(): JSX.Element {

  return <CraftDessertDetails fallback={<div>Loading...</div>} />

}
