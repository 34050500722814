import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MarketProductDetails')

const MarketProductDetails = loadable(() => import(/* webpackChunkName: "market-product-details" */'@pages/MarketProductDetails'), {
  resolveComponent: (components) => components.MarketProductDetails,
})

export function MarketProductDetailsAsync(): JSX.Element {

  return <MarketProductDetails fallback={<div>Loading...</div>} />

}
