import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { Heading, ResponsiveImage, Paragraph } from '@atoms/index'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue, StrongBoxShadow, theme } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div<{ $width: string, $height: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .slider-list, .slider-slide {
    height: 100% !important;
  }

  ${ResponsivePXValue('min-height', { mobile: '488px' })}
  ${ResponsivePXValue('max-height', { desktop: '500px' })}
  
  ${(props): CSS => {
    if (props.$width || props.$height) {
      return `
        ${ResponsivePXValue('height', props.$height)}
        ${ResponsivePXValue('margin', '0 0 32px 0')}
      `
    }
    return `
      ${ResponsivePXValue('height', { mobile: '100%', tablet: '225px', desktop: '225px' })}
      ${ResponsivePXValue('margin', '0 0 14px 0')}
    `
  }}

  .subtitle-text {
    ${ResponsivePXValue('padding', '0 0 16px')}
  }

  .description {
    ${ResponsivePXValue('margin', '0 0 16px')}
  }

`

const BannerImageContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '144px', tablet: '225px', desktop: '225px' })}
  background-color: ${theme.colors.whites.pureWhite};
`

const BannerTextContainer = styled.div`
  ${ResponsivePXValue('padding', '0 16px 16px')}
  background-color: ${theme.colors.whites.pureWhite};
`

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  ${ResponsivePXValue('border-radius', '8px')}

  .full-width {
    width: 100%;
  }
`

const ImageContainer = styled.div<{ width: string, height: string, imageShadow?: boolean }>`

  display: flex;

  ${(props): CSS => (props.imageShadow) ? StrongBoxShadow : null}

  ${(props): CSS => {
    if (props.width || props.height) {
      return `
        overflow: hidden;
        ${ResponsivePXValue('min-width', props.width)}
        ${ResponsivePXValue('width', props.width)}
        ${ResponsivePXValue('height', props.height)}
      `
    }
    return `
      cursor: pointer;
      height: 100%;
      width: 100%;
    `
  }}

`

const Link = styled.a`
  width: 100%;
`

export interface PageBannerDashboardProps {
  pageBanner: PageBannerFragment
}

export function PageBannerDashboard({ pageBanner }: PageBannerDashboardProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const link = pageBanner?.link?.link

  const mobileObjectFit = pageBanner?.mobileObjectFit ? pageBanner?.mobileObjectFit : pageBanner.objectFit
  const objectFit = pageBanner.objectFit

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (link) {
      if (link.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = link
      } else {
        navigate(link)
      }
    }
  }

  return (
    <Container $width={pageBanner.imageWidth} $height={pageBanner.imageHeight}>
      <ContentContainer>
        <ImageContainer width={pageBanner.imageWidth} height={pageBanner.imageHeight} imageShadow={pageBanner.imageShadow}>
          <Choose>
            <When condition={!!link}>
              <Link href={link} onClick={(e) => _handleLinkClick(e)}>
                <DeviceContainer $mobile className='full-width'>
                  <BannerImageContainer>
                    <ResponsiveImage
                      key={pageBanner.id}
                      image={pageBanner.mobileContentBannerImage}
                      objectFit={mobileObjectFit} />
                    <If condition={(pageBanner.bannerTitle !== '')}>
                      <Heading variant='h4' align='center' color={theme.colors.slates.bitter} className='header-text'>
                        {pageBanner.bannerTitle}
                      </Heading>
                    </If>
                  </BannerImageContainer>
                </DeviceContainer>
                <DeviceContainer $tablet $desktop className='full-width'>
                  <ResponsiveImage
                    key={pageBanner.id}
                    image={pageBanner.contentBannerImage}
                    objectFit={objectFit} />
                </DeviceContainer>
              </Link>
            </When>
            <Otherwise>
              <DeviceContainer $mobile className='full-width'>
                <BannerImageContainer>
                  <ResponsiveImage
                    key={pageBanner.id}
                    image={pageBanner.mobileContentBannerImage}
                    objectFit={mobileObjectFit} />
                </BannerImageContainer>
                <BannerTextContainer>
                  <If condition={(pageBanner.bannerTitle !== '')}>
                    <Heading variant='h4' align='center' color={theme.colors.slates.bitter} className='header-text'>
                      {pageBanner.bannerTitle}
                    </Heading>
                  </If>
                  <If condition={(pageBanner.subTitle !== '')}>
                    <Heading align='center' color={theme.colors.greys.liteCodGrey} variant='h5' className='subtitle-text'>
                      {pageBanner.subTitle}
                    </Heading>
                  </If>
                  <If condition={(pageBanner.description !== '')}>
                    <Paragraph align='center' color={theme.colors.greys.liteCodGrey} variant='p3' className='description'>
                      {pageBanner.description}
                    </Paragraph>
                  </If>
                </BannerTextContainer>
              </DeviceContainer>
              <DeviceContainer $tablet $desktop className='full-width'>
                <ResponsiveImage
                  key={pageBanner.id}
                  image={pageBanner.contentBannerImage}
                  objectFit={objectFit} />
              </DeviceContainer>
            </Otherwise>
          </Choose>
        </ImageContainer>
      </ContentContainer>
    </Container>
  )
}
