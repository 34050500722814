import React from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { ResponsivePXValue, StrongBoxShadow } from '@components/Theme'

const path = 'M2.953,-0.572C0.626,9.529 1.154,12.597 4.095,14.087C7.037,15.577 9.586,13.528 9.586,13.528C9.821,13.375 10.734,12.707 11.625,11.737C12.341,10.959 12.901,9.907 13.283,9.179C13.7,8.382 14.77,6.201 14.128,3.875C13.504,1.613 12.158,1.247 10.982,1.245C9.378,1.241 8.435,2.772 8.288,4.54C8.197,5.64 8.96,8.246 9.8,9.537C10.166,10.099 10.82,11.019 11.428,11.867C13.053,14.132 14.953,16.733 14.953,16.733'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const InnerContainer = styled.div<{ $noShadow: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props): CSS => props.$noShadow ? null : StrongBoxShadow}

  border-radius: 5%;
  width: 100%;

  ${ResponsivePXValue('max-width', '86px')}
  ${ResponsivePXValue('padding', '30px')}

`

const LogoWrapper = styled.div`
  --delay: 0ms;
  --duration: 1000ms;
  --dasharray: 100;
  --revDasharray: -100;
  --path: path('${path}')

  position: relative;
  width: 100%;

  svg {
    width: 100%;
  }

  .path {
    stroke-dasharray: var(--dasharray);
    stroke-dashoffset: var(--dasharray);
    animation: draw calc(var(--duration) * 2) infinite linear var(--delay);
  }

  @keyframes move {
    0% {
      offset-distance: 0%;
    }
    100% {
      offset-distance: 100%;
    }
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: var(--dasharray);
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: var(--revDasharray);
    }
  }
`

export interface LoaderProps {
  color?: string
  noShadow?: boolean
}

export function Loader({ color, noShadow = false }: LoaderProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <InnerContainer $noShadow={noShadow}>
        <LogoWrapper>
          <svg width='100%' height='100%' viewBox='0 0 16 16' version='1.1'>
            <g transform='matrix(1,0,0,1,0,-0.00583867)'>
              <path className='path' id='path' d={path} fill='none' stroke={color || theme.colors.whites.silver} strokeWidth='2' strokeMiterlimit='10' />
            </g>
          </svg>
        </LogoWrapper>
      </InnerContainer>
    </Container>

  )

}
