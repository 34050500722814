import React from 'react'

import LazyLoad from 'react-lazyload'
import styled from 'styled-components'

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  .lazyload-wrapper {
    width: 100%;
    height: 100%;
  }
`

const ImageElement = styled.img<{ $objectFit: string, $round?: boolean }>`
  border-radius: ${(props) => props.$round ? '8px' : '0'};
  width: 100%;
  height: 100%;
  object-fit: ${(props): string => (props.$objectFit ? props.$objectFit : 'cover')};
  backface-visibility: hidden;
`

export interface ImageProps {
  src?: string
  alt?: string
  objectFit?: string
  lazy?: boolean
  width?: number
  height?: number
  skeleton?: boolean
  round?: boolean
}

export function Image({ alt = 'UCOOK', src, objectFit = 'contain', lazy = false, round = false, width, height }: ImageProps): JSX.Element {

  return (
    <Container>
      <Choose>
        <When condition={lazy}>
          <LazyLoad
            height='100%'
            debounce={false}
            once>
            <ImageElement
              $round={round}
              src={src}
              alt={alt}
              width={width}
              height={height}
              $objectFit={objectFit} />
          </LazyLoad>
        </When>
        <Otherwise>
          <ImageElement
            $round={round}
            src={src}
            alt={alt}
            width={width}
            height={height}
            $objectFit={objectFit} />
        </Otherwise>
      </Choose>
    </Container>
  )
}
