import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import { v4 } from 'uuid'

import { ConfigPlugin } from '@lib/Config'

export enum GlobalModalTypeEnum {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
  CHECK_OUT = 'CHECK_OUT',
  ADD_LOCATION = 'ADD_LOCATION',
  DELIVERY_UNAVAILABLE = 'DELIVERY_UNAVAILABLE',
  DELIVERY_DAY_UNAVAILABLE = 'DELIVERY_DAY_UNAVAILABLE',
  KNOW_YOUR_DISHES = 'KNOW_YOUR_DISHES',
  KNOW_YOUR_CRAFT_MEALS = 'KNOW_YOUR_CRAFT_MEALS',
  PAGE_MODAL = 'PAGE_MODAL',
  PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION',
  PAUSE_SUBSCRIPTION_REASON = 'PAUSE_SUBSCRIPTION_REASON',
}

type ModalsFragment = any

export const MODALS_DEFAULT_STATE: ModalsFragment = {
  id: v4(),
  logIn: false,
  signUp: false,
  checkOut: false,
  addLocation: false,
  deliveryUnavailable: false,
  deliveryDayUnavailable: false,
  knowYourDishes: false,
  knowYourCraftMeals: false,
  pageModal: false,
  pageModalIdentifier: '',
  pageModalHasCookie: false,
  pauseSubscription: false,
  pauseSubscriptionReason: false,
  toggle: false,
  __typename: 'Modals',
}

const _data = makeVar<ModalsFragment>({ ...MODALS_DEFAULT_STATE })

export class ModalsPlugin implements ConfigPlugin {

  static instance: ModalsPlugin

  static shared(): ModalsPlugin {
    if (!this.instance) {
      this.instance = new ModalsPlugin()
    }
    return this.instance
  }

  toggleGlobalModal(show: boolean, type: GlobalModalTypeEnum, identifier?: string, toggle?: boolean, hasCookie?: boolean): void {
    const modals = { ...MODALS_DEFAULT_STATE }
    switch (type) {
      case GlobalModalTypeEnum.LOG_IN:
        modals.logIn = show
        break
      case GlobalModalTypeEnum.SIGN_UP:
        modals.signUp = show
        break
      case GlobalModalTypeEnum.CHECK_OUT:
        modals.checkOut = show
        break
      case GlobalModalTypeEnum.ADD_LOCATION:
        modals.addLocation = show
        break
      case GlobalModalTypeEnum.DELIVERY_UNAVAILABLE:
        modals.deliveryUnavailable = show
        break
      case GlobalModalTypeEnum.DELIVERY_DAY_UNAVAILABLE:
        modals.deliveryDayUnavailable = show
        break
      case GlobalModalTypeEnum.KNOW_YOUR_DISHES:
        modals.knowYourDishes = show
        break
      case GlobalModalTypeEnum.KNOW_YOUR_CRAFT_MEALS:
        modals.knowYourCraftMeals = show
        break
      case GlobalModalTypeEnum.PAGE_MODAL:
        modals.pageModal = show
        if (identifier) {
          modals.pageModalIdentifier = identifier
        }
        if (hasCookie) {
          modals.pageModalHasCookie = hasCookie
        }

        break
      case GlobalModalTypeEnum.PAUSE_SUBSCRIPTION:
        modals.pauseSubscription = show
        if (toggle) {
          modals.toggle = toggle
        }
        break
      case GlobalModalTypeEnum.PAUSE_SUBSCRIPTION_REASON:
        modals.pauseSubscriptionReason = show
        break
      default:
        break
    }
    _data(modals)
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    modals: {
      read(): ModalsFragment {
        const data = _data()
        return data as ModalsFragment
      },
    },
  })

  types = (): DocumentNode => gql`
    type Modals {
      id: ID!
      logIn: Boolean!
      signUp: Boolean!
      checkOut: Boolean!
      addLocation: Boolean!
      deliveryUnavailable: Boolean!
      deliveryDayUnavailable: Boolean!
      knowYourDishes: Boolean!
      knowYourCraftMeals: Boolean!
      pageModal: Boolean!
      pageModalIdentifier: String!
      pageModalHasCookie: Boolean!
      pauseSubscription: Boolean!
      pauseSubscriptionReason: Boolean!
      toggle: Boolean!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      modals: Modals!
    }
  `

  queries = (): DocumentNode => gql`
    fragment ModalsFragment on Modals {
      id
      logIn
      signUp
      checkOut
      addLocation
      deliveryUnavailable
      deliveryDayUnavailable
      knowYourDishes
      knowYourCraftMeals
      pageModal
      pageModalIdentifier
      pageModalHasCookie
      pauseSubscription
      pauseSubscriptionReason
      toggle
    }
    query GetModals {
      modals @client {
        ... ModalsFragment
      }
    }
  `

}
