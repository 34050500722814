import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Form, TextInput, Spacer, Button, Link, Paragraph } from '@client/components'
import { ModalMessageContainer, useForm } from '@client/components/molecules'
import { ModalActionContainer, theme } from '@client/components/Theme'
import { useRequestPasswordResetMutation } from '@hooks/api'

const Container = styled.div`
  width: 100%;
`
const ActionContainer = styled.div`
  ${ModalActionContainer()};
`

export interface RequestPasswordResetFormProps {
  onClose: () => void
  onCancel: () => void
}

interface RequestPasswordResetState {
  error: string
  email: string
}

const DEFAULT_STATE: RequestPasswordResetState = {
  error: '',
  email: '',
}

export function RequestPasswordResetForm({ onClose, onCancel }: RequestPasswordResetFormProps): JSX.Element {

  const [requestPasswordReset, { loading }] = useRequestPasswordResetMutation()
  const [form] = useForm()
  const [state, setState] = useState<RequestPasswordResetState>({ ...DEFAULT_STATE })

  const _handleRequestReset = async (data: { email: string }): Promise<void> => {
    try {
      await requestPasswordReset({
        variables: {
          email: data.email,
        },
      })
      setState((prevState) => update(prevState, {
        email: { $set: data.email },
        error: { $set: '' },
      }))
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
        email: { $set: '' },
      }))
    }
  }

  const _onActionClick = () => {
    if (state.email !== '') {
      onClose()
    } else {
      form.submit()
    }
  }

  const actionText = state.email !== '' ? 'OKAY' : 'SEND INSTRUCTIONS'

  return (
    <Container>
      <Form form={form} onFinish={_handleRequestReset} disabled={loading}>
        <Paragraph variant='p4' align='center'>
          Enter the email associated with your account and we’ll send instructions to reset your password.
        </Paragraph>
        <Spacer universal='24px' />
        <TextInput
          name='email'
          placeholder='Enter your email'
          variant='email'
          rules={[{ required: true, message: 'Please input your email address' }]}
          showLabel={false} />
        <Spacer universal='24px' />
        <If condition={state.email !== ''}>
          <ModalMessageContainer backgroundColor={theme.colors.whites.pureWhite}>
            <Paragraph variant='p1' align='center'>{`We've sent password reset instructions to ${state.email}. Follow the instruction to reset your password`}</Paragraph>
          </ModalMessageContainer>
          <Spacer universal='16px' />
        </If>
        <If condition={state.error !== ''}>
          <ModalMessageContainer>
            <Paragraph variant='p1' align='center' className='error'>{state.error}</Paragraph>
          </ModalMessageContainer>
          <Spacer universal='16px' />
        </If>
        <ActionContainer>
          <Button
            loading={loading}
            disabled={loading}
            color='black'
            fullWidth
            title={actionText}
            onClick={_onActionClick} />
          <Spacer universal='8px' />
          <Link variant='l2' decoration='underline' onClick={onCancel}> CLOSE </Link>
        </ActionContainer>
      </Form>
    </Container>
  )
}
