import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Paragraph, Heading, IconEnum, TextButtonColors, Button, Spacer, Link } from '@atoms/index'
import { ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'
import { PageNotificationFragment } from '@hooks/api'

const Container = styled.div<{ $color?: string }>`
  ${ResponsivePXValue('padding', '16px')}
  background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.oranges.coral};
  max-width: 100%;
  display: flex;

  .link-button {
    font-weight: 400;
    text-decoration: underline;
  }

  .close-button {
    ${ZeroSpace}
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
`

export interface CartNotificationBannerProps {
  notification: PageNotificationFragment | null
  dismissLoading?: boolean
  onLinkClicked?: () => void
  onClose?: () => void
}

export function CartNotificationBanner({ notification, dismissLoading = false, onLinkClicked, onClose }: CartNotificationBannerProps): JSX.Element {

  const navigate = useNavigate()

  const _handleOnLinkPressed = (): void => {
    navigate(notification?.link)
    onLinkClicked?.()
  }

  const buttonColors: TextButtonColors = {
    textColor: notification?.textColor || theme.colors.whites.pureWhite,
    textHoverColor: theme.colors.whites.desertStorm,
  }

  return (
    <Container $color={notification?.backgroundColor}>
      <BodyContainer>
        <Heading variant='h6' color={buttonColors.textColor}>{notification?.text}</Heading>
        <Spacer universal='8px' />
        <Paragraph variant='p3' color={buttonColors.textColor}>{notification?.additionalText}</Paragraph>
        <Spacer universal='8px' />
        <Link
          color={buttonColors.textColor}
          onClick={_handleOnLinkPressed}
          href={notification?.link}
          className='link-button'>
          {notification?.linkText}
        </Link>
      </BodyContainer>
      <Spacer universal='16px' />
      <Button
        color='transparent'
        icon={IconEnum.CLOSE_OUTLINE}
        iconColor={buttonColors.textColor}
        loading={dismissLoading}
        disabled={dismissLoading}
        size='small'
        onClick={() => onClose?.()} />
    </Container>
  )
}
