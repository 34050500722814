import { ApolloClient, FieldFunctionOptions, gql, makeVar, NormalizedCacheObject, Reference, StoreObject, TypePolicies } from '@apollo/client'

import { DocumentNode } from 'graphql'

import { CartItemFragment } from '@hooks/api/index'
import { Config, ConfigPlugin } from '@lib/Config'

import { NavigationPlugin } from './NavigationPlugin'

interface CartProductQuantity { quantity: number, cartItemId: string, type: string }
interface CartProductsQuantity { [k: string]: CartProductQuantity }
interface CartGroupQuantity { quantity: number, cartItemIds: string[], type: string }
interface CartGroupsQuantity { [k: string]: CartGroupQuantity }

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}
export interface CartAddon {
  open: boolean
}

const CART_DEFAULT_STATE = {
  open: false,
}

const _data = makeVar<CartAddon>({ ...CART_DEFAULT_STATE })

export class CartPlugin implements ConfigPlugin {

  static instance: CartPlugin

  static shared(): CartPlugin {
    if (!this.instance) {
      this.instance = new CartPlugin()
    }
    return this.instance
  }

  client!: ApolloClient<NormalizedCacheObject>

  open(closeAll?: boolean): void {
    _data({ open: true })
    if (closeAll) {
      NavigationPlugin.shared().closeAllMenus()
    } else if (NavigationPlugin.shared().mainMenuIsOpen()) {
      NavigationPlugin.shared().toggleMainMenu()
    }
  }

  close(closeAll?: boolean): void {
    _data({ open: false })
    if (closeAll) {
      NavigationPlugin.shared().closeAllMenus()
    } else if (NavigationPlugin.shared().mainMenuIsOpen()) {
      NavigationPlugin.shared().toggleMainMenu()
    }
  }

  closeOnNativeApplication(closeAll?: boolean): void {
    _data({ open: false })
  }

  diffCartQuantities(current: CartProductsQuantity, previous: CartProductsQuantity) {
    const cache = this.client.cache
    const allItems: CartProductsQuantity = { ...previous, ...current }
    Object.keys(allItems).forEach((productId) => {
      const product = {
        id: productId,
        __typename: allItems[productId].type,
      }
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'quantityInCart' })
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'cartItemId' })
    })
  }

  diffCartGroups(current: CartGroupsQuantity, previous: CartGroupsQuantity) {
    const cache = this.client.cache
    const allItems: CartGroupsQuantity = { ...previous, ...current }
    Object.keys(allItems).forEach((productGroupId) => {
      const productGroup = {
        id: productGroupId,
        __typename: allItems[productGroupId].type,
      }
      cache.evict({ id: cache.identify(productGroup as unknown as StoreObject), fieldName: 'quantityInCart' })
      cache.evict({ id: cache.identify(productGroup as unknown as StoreObject), fieldName: 'cartItemIds' })
    })
  }

  async configure(config: Config): Promise<void> {
    const client = await config.getClient()
    this.client = client
  }

  typePolicies = (): TypePolicies => ({
    Cart: {
      fields: {
        open: {
          read: (current: boolean, options: FieldFunctionOptions): boolean => {
            if (isBrowser()) {
              try {
                const previousCartQuantities = JSON.parse(sessionStorage.getItem('CART_PRODUCT_QUANTITIES') || '{}')
                const currentCartQuantities: { [k: string]: { quantity: number, cartItemId: string, type: string } } = {}
                const previousGroupItems = JSON.parse(sessionStorage.getItem('CART_GROUP_QUANTITIES') || '{}')
                const currentGroupItems: { [k: string]: { quantity: number, cartItemIds: string[], type: string } } = {}
                const items = options.readField('allItems') as readonly Reference[] || []
                const cartId = options.readField('id') as string
                for (let i = 0; i < items.length; i++) {
                  const itemRef = items[i]
                  const cartItemId = options.readField('id', itemRef) as string
                  const productRef = options.readField('cartItemProduct', itemRef) as Reference
                  const productId = options.readField('id', productRef) as string
                  const type = options.readField('__typename', productRef) as string
                  const quantity = options.readField('quantity', itemRef) as number
                  currentCartQuantities[productId] = { quantity, cartItemId, type }
                  const groupRef = options.readField('group', productRef) as Reference
                  if (groupRef) {
                    const groupId = options.readField('id', groupRef) as string
                    const groupType = options.readField('__typename', groupRef) as string
                    if (currentGroupItems[groupId]) {
                      currentGroupItems[groupId].cartItemIds.push(cartItemId)
                      currentGroupItems[groupId].quantity += quantity
                    } else {
                      currentGroupItems[groupId] = { quantity, cartItemIds: [cartItemId], type: groupType }
                    }
                  }
                }
                sessionStorage.setItem('CART_PRODUCT_QUANTITIES', JSON.stringify(currentCartQuantities))
                sessionStorage.setItem('PREVIOUS_CART_PRODUCT_QUANTITUES', JSON.stringify(previousCartQuantities))
                sessionStorage.setItem('CART_GROUP_QUANTITIES', JSON.stringify(currentGroupItems))
                sessionStorage.setItem('PREVIOUS_CART_GROUP_QUANTITIES', JSON.stringify(previousGroupItems))
                setTimeout(() => {
                  if (JSON.stringify(currentCartQuantities) !== JSON.stringify(previousCartQuantities)) {
                    this.diffCartQuantities(currentCartQuantities, previousCartQuantities)
                  }
                  if (JSON.stringify(currentGroupItems) !== JSON.stringify(previousGroupItems)) {
                    this.diffCartGroups(currentGroupItems, previousGroupItems)
                  }
                }, 100)
                return _data().open
              } catch (e) {
                console.log(e)
                return false
              }
            }
            return false
          },
        },
        items: {
          merge(_existing: CartItemFragment[], incoming: CartItemFragment[]): CartItemFragment[] {
            return incoming
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend type Cart {
      open: Boolean!
    }
  `

}
