import React, { useState, useEffect } from 'react'

import { format } from 'date-fns'
import update from 'react-addons-update'
import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue, TableRow, TableCell, RadioButtonControl, Rule, SmallLoader } from '@client/components'
import { ContentBlock, SectionLoading } from '@client/components/molecules'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { DeliveryDateFragment, useUserDetailsQuery, useUserCartQuery, useUpdateUserSubscription } from '@hooks/api'
import { CityDeliveryDaysEnum, WeekDayEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  width: 100%;

  .seperator {
    ${ResponsivePXValue('margin', { mobile: '20px 0', tablet: '25px 0', desktop: '30px 0' })}
  }
`

const MobileContainer = styled.div`
  display: inline-block;
  ${(props): string => props.theme.desktop} {
    display: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  width: 100%;
  ${(props): string => props.theme.desktop} {
    display: inline-block;
  }
`

const LoadingContainer = styled.div`
  ${ResponsivePXValue('width', '36px')}
  ${ResponsivePXValue('height', '36px')}
`

const RadioContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 0 0 16px', tablet: '0 16px 0 16px', desktop: '0 12px 0 24px' })}
`

export interface CheckoutDeliveryDaySelectProps {
  onDeliverDaySelect?: (deliveryDay?: CityDeliveryDaysEnum) => void
}

interface CheckoutDeliveryDaySelectState {
  deliveryOptions: DeliveryDateFragment[]
}

const DEFAULT_STATE: CheckoutDeliveryDaySelectState = {
  deliveryOptions: [],
}

export function CheckoutDeliveryDaySelect({ onDeliverDaySelect }: CheckoutDeliveryDaySelectProps): JSX.Element {

  const [state, setState] = useState<CheckoutDeliveryDaySelectState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [updateSubscription, { loading: updateLoading }] = useUpdateUserSubscription()

  const _handleSelectDeliveryDay = async (deliveryDay: CityDeliveryDaysEnum): Promise<void> => {

    if (onDeliverDaySelect) {
      onDeliverDaySelect(deliveryDay)
    }

    await updateSubscription({
      variables: {
        id: userCartData.currentUser.activeMenu.subscription.id,
        input: {
          deliveryOption: deliveryDay as unknown as WeekDayEnum,
        },
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })
  }

  useEffect(() => {
    if (!userCartLoading && !userDetailsLoading) {
      const defaultAddress = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault)
      const deliveryDates = userCartData?.currentUser?.activeMenu?.menu?.deliveryDates
      const deliveryOptions = [] as DeliveryDateFragment[]
      if (defaultAddress && deliveryDates) {
        const deliveryDays = defaultAddress?.location?.city?.deliveryDays
        for (const date of deliveryDates) {
          if (deliveryDays.includes(date.id as CityDeliveryDaysEnum)) {
            deliveryOptions.push(date)
          }
        }
      }
      setState((prevState) => update(prevState, {
        deliveryOptions: { $set: deliveryOptions },
      }))
    }
  }, [userCartData, userDetailsData])

  let selectedChoice = userCartData?.currentUser?.activeMenu?.subscription?.deliveryOption
  if (!userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress) {
    selectedChoice = null
  }

  useEffect(() => {
    if (!userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay) {
      selectedChoice = WeekDayEnum.MONDAY
      _handleSelectDeliveryDay(selectedChoice as unknown as CityDeliveryDaysEnum)
    }
  }, [])

  const loading = userCartLoading || userDetailsLoading
  let deliveryDate: DeliveryDateFragment

  const deliveryOptions = state.deliveryOptions || []

  return (
    <Choose>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <Otherwise>
        <If condition={deliveryOptions?.length > 0}>
          <ContentBlock
            title='Delivery Day'>
            <ContentContainer>
              <For each='deliveryDate' of={deliveryOptions}>
                <div key={deliveryDate.id}>
                  <DesktopContainer>
                    <Container>
                      <TableRow>
                        <TableCell align='center' justify='center' noZeroSpace>
                          <RadioContainer>
                            <Choose>
                              <When condition={updateLoading}>
                                <LoadingContainer>
                                  <SmallLoader color={theme.colors.oranges.coral}></SmallLoader>
                                </LoadingContainer>
                              </When>
                              <Otherwise>
                                <RadioButtonControl
                                  selected={selectedChoice === deliveryDate.id}
                                  onClick={() => _handleSelectDeliveryDay(deliveryDate.id as CityDeliveryDaysEnum)} />
                              </Otherwise>
                            </Choose>
                          </RadioContainer>
                        </TableCell>
                        <TableCell direction='column' grow='1' padding='24px'>
                          <Paragraph variant='p2'>
                            {format(new Date(deliveryDate.date), 'cccc do MMM yyyy')}
                          </Paragraph>
                          <Paragraph variant='p1'>{`${deliveryDate.startTime ?? '09:00'} - ${deliveryDate.endTime ?? '19:00'}`}</Paragraph>
                        </TableCell>
                      </TableRow>
                      <Rule color='slate' />
                    </Container>
                  </DesktopContainer>
                  <MobileContainer>
                    <Container>
                      <TableRow>
                        <TableCell noZeroSpace justify='center' align='center' direction='column'>
                          <RadioContainer>
                            <Choose>
                              <When condition={updateLoading}>
                                <LoadingContainer>
                                  <SmallLoader color={theme.colors.oranges.coral}></SmallLoader>
                                </LoadingContainer>
                              </When>
                              <Otherwise>
                                <RadioButtonControl
                                  selected={selectedChoice === deliveryDate.id}
                                  onClick={() => _handleSelectDeliveryDay(deliveryDate.id as CityDeliveryDaysEnum)} />
                              </Otherwise>
                            </Choose>
                          </RadioContainer>
                        </TableCell>
                        <TableCell padding='24px' direction='column' justify='center'>
                          <Paragraph variant='p2'>
                            {format(new Date(deliveryDate.date), 'cccc do MMM yyyy')}
                          </Paragraph>
                          <Paragraph variant='p1'>{`${deliveryDate.startTime ?? '09:00'} - ${deliveryDate.endTime ?? '19:00'}`}</Paragraph>
                        </TableCell>
                      </TableRow>
                      <Rule color='slate' />
                    </Container>
                  </MobileContainer>
                </div>
              </For>
            </ContentContainer>
          </ContentBlock>
        </If>
      </Otherwise>
    </Choose>
  )
}
