import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type ParagraphVariant = 'p1' | 'p2' | 'p3' | 'p4' | 'p5'
export type ParagraphAlignment = 'left' | 'center' | 'right' | 'justify'
export type ParagraphTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type ParagraphDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const P1 = styled.div<{ $align: string, $decoration: string, $transform: string, $bold: boolean, $color?: string, $cursor?: string}>`

  font-family: 'gordita';

  font-weight: ${(props): string => props.$bold ? '500' : '400'};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  ${(props): string => props.$cursor ? `cursor: ${props.$cursor};` : ''}

  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', '24px')}

`

const P2 = styled.div<{ $align: string, $decoration: string, $transform: string, $bold: boolean, $color?: string, $opacity?: string, $cursor?: string}>`

  font-family: 'gordita';
 
  font-weight: ${(props): string => props.$bold ? '500' : '400'};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  opacity: ${(props): string => props.$opacity};
  ${(props): string => props.$cursor ? `cursor: ${props.$cursor};` : ''}

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', '20px')}

`
const P3 = styled.div<{ $align: string, $decoration: string, $transform: string, $bold: boolean, $color?: string, $cursor?: string}>`

  font-family: 'gordita';
 
  font-weight: ${(props): string => props.$bold ? '500' : '400'};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  ${(props): string => props.$cursor ? `cursor: ${props.$cursor};` : ''}

  ${ResponsivePXValue('font-size', { mobile: '8px', tablet: '8px', desktop: '10px' })}
  ${ResponsivePXValue('line-height', '16px')}

`
const P4 = styled.div<{ $align: string, $decoration: string, $transform: string, $bold: boolean, $color?: string, $cursor?: string}>`

  font-family: 'gordita';

  font-weight: ${(props): string => props.$bold ? '700' : '400'};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  ${(props): string => props.$cursor ? `cursor: ${props.$cursor};` : ''}

  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', '24px')}

`
const P5 = styled.div<{ $align: string, $decoration: string, $transform: string, $bold: boolean, $color?: string, $cursor?: string}>`

  font-family: 'gordita';

  font-weight: ${(props): string => props.$bold ? '700' : '400'};
  color: ${(props): string => props.$color ? props.$color : props.theme.colors.greys.mineshaft};
  text-align: ${(props): string => props.$align};
  text-decoration: ${(props): string => props.$decoration};
  text-transform: ${(props): string => props.$transform};
  ${(props): string => props.$cursor ? `cursor: ${props.$cursor};` : ''}

  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '20px', desktop: '20px' })}

`

export interface ParagraphProps {
  children: string | React.ReactNode
  variant?: ParagraphVariant
  align?: ParagraphAlignment
  transform?: ParagraphTransforms
  decoration?: ParagraphDecorations
  color?: string
  bold?: boolean
  skeleton?: boolean
  className?: string
  opacity?: string
  cursor?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function Paragraph({
  children, variant = 'p1', align = 'left', transform = 'none', decoration = 'none', bold = false,
  color, className, opacity, cursor, onClick,
}: ParagraphProps): JSX.Element {

  const _handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      return onClick(e)
    }
  }

  switch (variant) {
    case 'p1':
      return <P1 onClick={_handleClick} $align={align} $decoration={decoration} $transform={transform} $color={color} $bold={bold} className={className} $cursor={cursor}>{children}</P1>
    case 'p2':
      return <P2 onClick={_handleClick} $align={align} $decoration={decoration} $transform={transform} $color={color} $bold={bold} className={className} $opacity={opacity} $cursor={cursor}>{children}</P2>
    case 'p3':
      return <P3 onClick={_handleClick} $align={align} $decoration={decoration} $transform={transform} $color={color} $bold={bold} className={className} $cursor={cursor}>{children}</P3>
    case 'p4':
      return <P4 onClick={_handleClick} $align={align} $decoration={decoration} $transform={transform} $color={color} $bold={bold} className={className} $cursor={cursor}>{children}</P4>
    case 'p5':
      return <P5 onClick={_handleClick} $align={align} $decoration={decoration} $transform={transform} $color={color} $bold={bold} className={className} $cursor={cursor}>{children}</P5>
  }
}
