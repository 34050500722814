import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/Checkout')

const Checkout = loadable(() => import(/* webpackChunkName: "checkout" */'@pages/Checkout'), {
  resolveComponent: (components) => components.Checkout,
})

export function CheckoutAsync(): JSX.Element {

  return <Checkout fallback={<div>Loading...</div>} />

}
