import React, { Fragment } from 'react'

import { ContentCard, ContentCardHeaderEnum } from '@molecules/index'
import { UserAddressList } from '@organisms/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const seo = {
  name: 'User Dashboard',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Delivery(): JSX.Element {

  const title = 'ADDRESSES'
  const description = 'Tell us where you’d like to receive your UCOOK deliveries. You can enter your home, office or other address and change your details for your next delivery.'

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <ContentCard title={title} description={description} headerType={ContentCardHeaderEnum.STANDARD}>
        <UserAddressList />
      </ContentCard>
    </Fragment>
  )

}
