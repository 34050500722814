import React from 'react'

import styled from 'styled-components'

import { Heading, Icon, IconEnum, Paragraph } from '@atoms/index'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components'

const Container = styled.div<{ $flexDirection: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: ${(props): string => props.$flexDirection};

  ${ResponsivePXValue('max-width', { desktop: '352px' })}

  .title, .description {
    ${ZeroSpace}
  }

`

const IconContainer = styled.div`
  display: flex;
  position: relative;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '48px', desktop: '64px' })}
  ${ResponsivePXValue('min-width', { mobile: '48px', tablet: '48px', desktop: '64px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '64px' })}
  ${ResponsivePXValue('margin-right', { mobile: '0', tablet: '0', desktop: '16px' })}

  svg {
    width: 100%;
    height: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export interface SimpleSectionProps {
  title: string
  description: string
  icon: IconEnum
  flexDirection?: string
  iconColor?: string
  className?: string
}

export function SimpleSection({
  title,
  description,
  icon,
  flexDirection = 'row',
  iconColor = theme.colors.slates.bitter,
  className,
}: SimpleSectionProps): JSX.Element {

  return (
    <Container className={className} $flexDirection={flexDirection}>
      <IconContainer>
        <Icon
          color={iconColor}
          icon={icon} />
      </IconContainer>
      <TextContainer className='text-container'>
        <Heading variant='h6' color={theme.colors.greys.mineshaft} className='title'>{title}</Heading>
        <Paragraph variant='p3' color={theme.colors.greys.mineshaft} className='description'>{description}</Paragraph>
      </TextContainer>
    </Container>
  )

}
