import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { Icon, Image, IconEnum } from '@atoms/images/index'
import { Paragraph } from '@client/components'
import { Step, Steps } from '@client/components/atoms/notifications/Steps'
import { LiteBoxShadow, ResponsivePXValue } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useGetCheckoutQuery } from '@hooks/api'
import { CheckoutStepEnum, CheckoutTypeEnum } from '@uctypes/api/globalTypes'

interface StepItem {
  id: CheckoutStepEnum
  title: string
  active: boolean
  completed: boolean
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'space-between', desktop: 'space-between' })}

  ${LiteBoxShadow}

  ${ResponsivePXValue('height', '64px')}

  .steps {
    ${ResponsivePXValue('width', { tablet: '65%', desktop: '50%' })}
  }
  
`

const LogoContainer = styled.a`
  flex-shrink: 0;
  
  ${ResponsivePXValue('height', 'CALC(100% - 28px)')}
  ${ResponsivePXValue('margin', '0 28px')}
  ${ResponsivePXValue('width', '86px')}
`

const IconContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('padding', '16px')}
`

const TitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    margin: 0;
    padding: 0;
  }
  .sub-title {
    margin: 0;
    padding: 0;
  }
`

export interface CheckoutHeaderProps {
  step: CheckoutStepEnum
  type: CheckoutTypeEnum
}

interface CheckoutHeaderState {
  steps: StepItem[]
  title: string
  subTitle: string
  disabled: boolean
}

const DEFAULT_STATE: CheckoutHeaderState = {
  steps: [],
  title: 'Step 1 of 4',
  subTitle: 'Your Meal Kit Plan',
  disabled: false,
}

export function CheckoutHeader(): JSX.Element {

  const config = useConfig()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const [state, setState] = useState<CheckoutHeaderState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const theme = useTheme()
  const step = CheckoutPlugin.shared().getActiveStep(checkoutData?.checkout)
  const type = CheckoutPlugin.shared().getActiveType(checkoutData?.checkout)

  const _handleLogoClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
  }

  const _handleForceStep = (step: CheckoutStepEnum): void => {
    CheckoutPlugin.shared().forceStep(step)
  }

  const _handleGoBack = (): void => {
    if (state.disabled) {
      return
    }
    if (type === CheckoutTypeEnum.SUBSCRIPTION) {
      switch (step) {
        case CheckoutStepEnum.NOTHING:
        case CheckoutStepEnum.IDENTIFY_USER:
        case CheckoutStepEnum.VERIFY_NUMBER:
        case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
        case CheckoutStepEnum.CART_ERRORS:
        case CheckoutStepEnum.REACTIVATE:
        case CheckoutStepEnum.SAVE_MENU:
        case CheckoutStepEnum.VALIDATION:
          break
        case CheckoutStepEnum.DELIVERY:
        case CheckoutStepEnum.DELIVERY_DAY:
          navigate('/')
          break
        case CheckoutStepEnum.PAYMENT:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.DELIVERY)
          break
        case CheckoutStepEnum.CART_SUMMARY:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.PAYMENT)
          break
        case CheckoutStepEnum.THANK_YOU:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.CART_SUMMARY)
          break
        default:
          break
      }
    } else {
      switch (step) {
        case CheckoutStepEnum.NOTHING:
        case CheckoutStepEnum.IDENTIFY_USER:
        case CheckoutStepEnum.VERIFY_NUMBER:
        case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
        case CheckoutStepEnum.CART_ERRORS:
        case CheckoutStepEnum.REACTIVATE:
        case CheckoutStepEnum.SAVE_MENU:
        case CheckoutStepEnum.VALIDATION:
        case CheckoutStepEnum.DELIVERY_DAY:
          break
        case CheckoutStepEnum.DELIVERY:
          navigate('/')
          break
        case CheckoutStepEnum.PAYMENT:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.DELIVERY)
          break
        case CheckoutStepEnum.CART_SUMMARY:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.PAYMENT)
          break
        case CheckoutStepEnum.THANK_YOU:
          CheckoutPlugin.shared().forceStep(CheckoutStepEnum.CART_SUMMARY)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    let steps: StepItem[] = []
    let title = ''
    let subTitle = ''
    let disabled = false
    if (type === CheckoutTypeEnum.SUBSCRIPTION) {
      switch (step) {
        case CheckoutStepEnum.NOTHING:
        case CheckoutStepEnum.IDENTIFY_USER:
        case CheckoutStepEnum.VERIFY_NUMBER:
        case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
        case CheckoutStepEnum.CART_ERRORS:
        case CheckoutStepEnum.REACTIVATE:
        case CheckoutStepEnum.SAVE_MENU:
        case CheckoutStepEnum.VALIDATION:
        case CheckoutStepEnum.DELIVERY:
        case CheckoutStepEnum.DELIVERY_DAY:
          subTitle = 'Step 1 of 3'
          title = 'Delivery'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress && userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: false,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: false,
            completed: false,
          }]
          break
        case CheckoutStepEnum.PAYMENT:
          subTitle = 'Step 2 of 3'
          title = 'Payment'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress && userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: false,
            completed: false,
          }]
          break
        case CheckoutStepEnum.CART_SUMMARY:
          subTitle = 'Step 3 of 3'
          title = 'Your Order'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress && userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: true,
            completed: false,
          }]
          break
        case CheckoutStepEnum.THANK_YOU:
          subTitle = 'Step 3 of 3'
          title = 'Thank You'
          disabled = true
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress && userCartData?.currentUser?.checkoutStatus?.hasSelectedDeliveryDay,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: true,
            completed: true,
          }]
          break
        default:
          break
      }
    } else {
      switch (step) {
        case CheckoutStepEnum.NOTHING:
        case CheckoutStepEnum.IDENTIFY_USER:
        case CheckoutStepEnum.VERIFY_NUMBER:
        case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
        case CheckoutStepEnum.CART_ERRORS:
        case CheckoutStepEnum.REACTIVATE:
        case CheckoutStepEnum.SAVE_MENU:
        case CheckoutStepEnum.VALIDATION:
        case CheckoutStepEnum.DELIVERY:
        case CheckoutStepEnum.DELIVERY_DAY:
          subTitle = 'Step 1 of 3'
          title = 'Delivery'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: false,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: false,
            completed: false,
          }]
          break
        case CheckoutStepEnum.PAYMENT:
          subTitle = 'Step 2 of 3'
          title = 'Payment'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: false,
            completed: false,
          }]
          break
        case CheckoutStepEnum.CART_SUMMARY:
          subTitle = 'Step 3 of 3'
          title = 'Your Order'
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: true,
            completed: false,
          }]
          break
        case CheckoutStepEnum.THANK_YOU:
          subTitle = 'Step 3 of 3'
          title = 'Your Order'
          disabled = true
          steps = [{
            id: CheckoutStepEnum.DELIVERY,
            title: 'DELIVERY',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddress,
          }, {
            id: CheckoutStepEnum.PAYMENT,
            title: 'PAYMENT',
            active: true,
            completed: userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod,
          }, {
            id: CheckoutStepEnum.CART_SUMMARY,
            title: 'YOUR ORDER',
            active: true,
            completed: true,
          }]
          break
        default:
          break
      }
    }
    setState((prevState) => update(prevState, {
      steps: { $set: steps },
      title: { $set: title },
      subTitle: { $set: subTitle },
      disabled: { $set: disabled },
    }))
  }, [type, step])

  let stepItem: StepItem

  return (
    <>
      <DeviceContainer $mobile>
        <Container>
          <Choose>
            <When condition={step === CheckoutStepEnum.THANK_YOU}>
              <IconContainer>

              </IconContainer>
            </When>
            <Otherwise>
              <IconContainer onClick={_handleGoBack}>
                <Icon
                  icon={IconEnum.CHEVRON_BACK_OUTLINE}
                  color={theme.colors.greys.darkCodGrey} />
              </IconContainer>
            </Otherwise>
          </Choose>
          <TitleContainer>
            <Paragraph className='title' variant='p2'>{state.title}</Paragraph>
            <Paragraph className='sub-title' variant='p3'>{state.subTitle}</Paragraph>
          </TitleContainer>
          <IconContainer>

          </IconContainer>
        </Container>
      </DeviceContainer>
      <DeviceContainer $tablet $desktop>
        <Container>
          <LogoContainer href='/' onClick={_handleLogoClicked}>
            <Image src='/logos/large.svg' lazy={false} />
          </LogoContainer>
          <Steps className='steps' onSelect={_handleForceStep}>
            <For each='stepItem' of={state.steps} index='stepIndex'>
              <Step
                id={stepItem.id}
                disabled={state.disabled}
                key={stepItem.id}
                title={stepItem.title}
                active={stepItem.active}
                completed={stepItem.completed} />
            </For>
          </Steps>
          <LogoContainer />
        </Container>
      </DeviceContainer>
    </>
  )

}
