import React, { useRef } from 'react'

import styled from 'styled-components'

import {
  PageDataActiveWineCampaign,
  PageDataMealKitOnTheMenuCarousel,
  PageDataMealKitPlans,
  PageDataCraftMealInStoreCarousel,
  PageDataMeetWeaningSenseEmail,
  PageDataFeaturedVineyards,
  PageDataAnchoredMealKitMenu,
  PageDataFilteredMealKits,
  PageDataFilteredFrozen,
  PageDataFilteredMarketProduct,
  PageDataFilteredWine,
  PageDataEmbeddedForm,

} from '@client/components'
import { getPageSectionBackground, PageSectionPadding } from '@client/components/Theme'
import { PageDataSectionFragment } from '@hooks/api'
import { PageSectionDataVariationEnum } from '@uctypes/api/globalTypes'

import { PageDataCraftKidsInStoreCarousel } from './PageDataCraftKidsInStoreCarousel'
import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string }>`
 display: flex;
 flex-direction: column;

 ${PageSectionPadding}
     
 background: ${(props): string => props.$background};

`
const PageData = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
`

export interface PageDataSectionProps {
  pageSection: PageDataSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageDataSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageDataSectionProps): JSX.Element {

  const background = getPageSectionBackground(pageSection?.background)

  let pageRef

  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const activeWineCampaign = pageSection.dataSectionVariation === PageSectionDataVariationEnum.ACTIVE_WINE_CAMPAIGN
  const featuredVineyards = pageSection.dataSectionVariation === PageSectionDataVariationEnum.FEATURED_VINEYARDS
  const mealKitPlans = pageSection.dataSectionVariation === PageSectionDataVariationEnum.MEAL_KIT_PLANS
  const mealkitWhatToStockUpOn = pageSection.dataSectionVariation === PageSectionDataVariationEnum.MEAL_KIT_ON_THE_MENU_THIS_WEEK
  const anchoredMealKitMenu = pageSection.dataSectionVariation === PageSectionDataVariationEnum.ANCHORED_MEAL_KIT_MENU_THIS_WEEK
  const craftMealWhatsInStore = pageSection.dataSectionVariation === PageSectionDataVariationEnum.CRAFT_MEAL_STORE_THIS_WEEK
  const craftKidsWhatsInStore = pageSection.dataSectionVariation === PageSectionDataVariationEnum.CRAFT_KIDS_STORE_THIS_WEEK
  const meetWeaningSenseEmailBlock = pageSection.dataSectionVariation === PageSectionDataVariationEnum.MEET_WEANING_SENSE_EMAIL_BLOCK
  const filteredMealKits = pageSection.dataSectionVariation === PageSectionDataVariationEnum.FILTERED_MEAL_KITS
  const filteredFrozenMeals = pageSection.dataSectionVariation === PageSectionDataVariationEnum.FILTERED_FROZEN_MEALS
  const filteredMarketProducts = pageSection.dataSectionVariation === PageSectionDataVariationEnum.FILTERED_MARKET_PRODUCTS
  const filteredWine = pageSection.dataSectionVariation === PageSectionDataVariationEnum.FILTERED_WINES
  const embeddedForm = pageSection.dataSectionVariation === PageSectionDataVariationEnum.EMBEDDED_FORM

  return (
    <Container ref={pageRef} $background={background}>
      <Choose>
        <When condition={!!pageSection}>
          <PageData>
            <Choose>
              <When condition={activeWineCampaign}>
                <PageDataActiveWineCampaign pageSection={pageSection} />
              </When>
              <When condition={featuredVineyards}>
                <PageDataFeaturedVineyards pageSection={pageSection} />
              </When>
              <When condition={mealKitPlans}>
                <PageDataMealKitPlans />
              </When>
              <When condition={anchoredMealKitMenu}>
                <PageDataAnchoredMealKitMenu title={pageSection.title} />
              </When>
              <When condition={mealkitWhatToStockUpOn}>
                <PageDataMealKitOnTheMenuCarousel title={pageSection.title} />
              </When>
              <When condition={craftMealWhatsInStore}>
                <PageDataCraftMealInStoreCarousel title={pageSection.title} />
              </When>
              <When condition={craftKidsWhatsInStore}>
                <PageDataCraftKidsInStoreCarousel title={pageSection.title} />
              </When>
              <When condition={meetWeaningSenseEmailBlock}>
                <PageDataMeetWeaningSenseEmail />
              </When>
              <When condition={filteredMealKits}>
                <PageDataFilteredMealKits pageSection={pageSection} />
              </When>
              <When condition={filteredFrozenMeals}>
                <PageDataFilteredFrozen pageSection={pageSection} />
              </When>
              <When condition={filteredMarketProducts}>
                <PageDataFilteredMarketProduct pageSection={pageSection} />
              </When>
              <When condition={filteredWine}>
                <PageDataFilteredWine pageSection={pageSection} />
              </When>
              <When condition={embeddedForm}>
                <PageDataEmbeddedForm pageSection={pageSection} />
              </When>
            </Choose>
          </PageData>
        </When>
        <Otherwise>
          Page Data Section not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
