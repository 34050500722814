import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@client/components'
import { ErrorBlock, Modal, SectionLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { AddProductAvailabilityEmailDocument, RegisteredUserDetailsFragment, UserDetailsFragment, useGetModalsQuery, useUpdateUserAddressMutation, useUserDetailsQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'
import { GeoStatusEnum } from '@uctypes/api/globalTypes'

import { AddAddressForm } from '../forms'
import { ChangedToInvalidAddressForm } from '../forms/ChangedToInvalidAddressForm'
import { MultipleAddressForm } from '../forms/MultipleAddressForm'
import { NotifyMeOnProductAvailabilityForm } from '../forms/NotifyMeOnProductAvailabilityForm'
import { QuickAddAddressForm } from '../forms/QuickAddAddressForm'
import { SingleAddressForm } from '../forms/SingleAddressForm'

enum AddLocationModalStepEnum {
  QUICK_ADD_LOCATION = 'QUICK_ADD_LOCATION',
  ADD_ADDRESS = 'ADD_ADDRESS',
  MULTIPLE_ADDRESSES = 'MULTIPLE_ADDRESSES',
  SINGLE_ADDRESS = 'SINGLE_ADDRESS',
  DELIVERY_UNAVAILABLE = 'DELIVERY_UNAVAILABLE',
  KEEP_ME_UPDATED = 'KEEP_ME_UPDATED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

const Container = styled.div`
 
`

const WideContainer = styled.div`
  .modal-container {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: 'CALC(100% - 200px)', desktop: '900px' })}
  }
`

interface AddLocationModalState {
  displayStep: AddLocationModalStepEnum
  error: string
  allowCheck: boolean
}

const DEFAULT_STATE: AddLocationModalState = {
  displayStep: AddLocationModalStepEnum.SINGLE_ADDRESS,
  error: '',
  allowCheck: false,
}

export function AddLocationModal(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<AddLocationModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [updateUserAddress] = useUpdateUserAddressMutation()
  const user = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const { addToast } = useToasts()

  useEffect(() => {
    if (data?.modals?.addLocation) {
      setState((prevState) => update(prevState, {
        displayStep: { $set: AddLocationModalStepEnum.SINGLE_ADDRESS },
      }))
    }
  }, [data?.modals?.addLocation])

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.ADD_LOCATION)
  }

  const _handleError = (message: string): void => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.ERROR },
      error: { $set: message },
    }))
  }

  const _handleLogInClicked = () => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.ADD_LOCATION)
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleDeliveryUnavailable = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.DELIVERY_UNAVAILABLE },
    }))
  }

  const _handleOnNotifyClicked = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.KEEP_ME_UPDATED },
    }))
  }

  const _handleTryDifferentAddress = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.SINGLE_ADDRESS },
    }))
  }

  const _handleAddNewAddress = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.ADD_ADDRESS },
    }))
  }

  const _setLoading = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddLocationModalStepEnum.LOADING },
    }))
  }

  const _handleAddressAdded = () => {
    setState((prevState) => update(prevState, {
      allowCheck: { $set: true },
    }))
  }

  const _handleDefaultAddressUpdated = async (id: string): Promise<void> => {
    _setLoading()
    setState((prevState) => update(prevState, {
      allowCheck: { $set: false },
    }))
    try {
      await updateUserAddress({
        variables: {
          id,
          input: {
            isDefault: true,
          },
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      }).then(() => {
        setState((prevState) => update(prevState, {
          allowCheck: { $set: true },
        }))
      })
      addToast('Default address updated successfully!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      setState((prevState) => ({ ...prevState, displayStep: AddLocationModalStepEnum.ERROR, error: e.message }))
    }
  }

  const _handleSubmitEmailNotification = async (email: string) => {

    const defaultAddress = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment).addresses.find((address) => address.isDefault)
    const config = useConfig()
    const client = await config.getClient()

    if (defaultAddress) {
      const input = {
        email,
        city: { id: defaultAddress.location.city.id },
        area: { id: defaultAddress.location.area.id },
      }

      _setLoading()

      try {
        await client.mutate({
          mutation: AddProductAvailabilityEmailDocument,
          variables: { input },
        })
        addToast(`We will notify you at ${email} when we have updates on delivery to your area. `, {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } else {
      addToast('No default address', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _handleClose()
  }

  let title = 'Delivery Address'
  switch (state.displayStep) {
    case AddLocationModalStepEnum.ERROR:
      title = 'Oops'
      break
  }

  useEffect(() => {
    if (state.allowCheck) {
      const defaultAddress = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment)?.addresses?.find((address) => address.isDefault)
      if (defaultAddress?.location?.city?.status === GeoStatusEnum.SUPPORTED) {
        _handleClose()
      } else {
        _handleDeliveryUnavailable()
      }
    }
  }, [userDetailsData?.currentUser?.addresses, state.allowCheck])

  return (
    <Modal
      showCloseButton={state.displayStep !== AddLocationModalStepEnum.LOADING && !userDetailsLoading}
      open={data?.modals?.addLocation}
      title={title}
      allowBackgroundClose={false}
      fullscreen={false}
      onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={userDetailsLoading || state.displayStep === AddLocationModalStepEnum.LOADING}>
            <ModalFormContainer>
              <SectionLoading />
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === AddLocationModalStepEnum.ERROR}>
            <ErrorBlock
              title={state.error}
              onClick={_handleClose} />
          </When>
          <When condition={state.displayStep === AddLocationModalStepEnum.KEEP_ME_UPDATED}>
            <ModalFormContainer>
              <NotifyMeOnProductAvailabilityForm
                onCancel={_handleClose}
                onSubmit={_handleSubmitEmailNotification} />
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === AddLocationModalStepEnum.DELIVERY_UNAVAILABLE}>
            <ModalFormContainer>
              <ChangedToInvalidAddressForm
                onTryDifferentAddress={_handleTryDifferentAddress}
                onNotify={_handleOnNotifyClicked} />
            </ModalFormContainer>
          </When>
          <When condition={ state.displayStep === AddLocationModalStepEnum.ADD_ADDRESS}>
            <WideContainer>
              <ModalFormContainer className='modal-container'>
                <AddAddressForm
                  onSuccess={_handleAddressAdded}
                  onError={_handleError} />
              </ModalFormContainer>
            </WideContainer>
          </When>
          <When condition={user?.__typename !== 'RegisteredUser' || user?.addresses?.length === 0}>
            <ModalFormContainer>
              <QuickAddAddressForm
                onSuccess={_handleClose}
                onError={_handleError}
                onLoginClicked={_handleLogInClicked} />
            </ModalFormContainer>
          </When>
          <When condition={user?.addresses?.length > 1}>
            <ModalFormContainer>
              <MultipleAddressForm
                onUpdateAddress={_handleDefaultAddressUpdated}
                onAddNewAddress={_handleAddNewAddress} />
            </ModalFormContainer>
          </When>
          <When condition={ user?.addresses?.length > 0}>
            <ModalFormContainer>
              <SingleAddressForm
                onAddNewAddress={_handleAddNewAddress} />
            </ModalFormContainer>
          </When>
          <Otherwise>
            <ModalFormContainer>
              <Paragraph variant='p1'>User not found</Paragraph>
            </ModalFormContainer>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )

}
