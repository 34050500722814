import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MarketAisles')

const MarketAisles = loadable(() => import(/* webpackChunkName: "market-aisles" */'@pages/MarketAisles'), {
  resolveComponent: (components) => components.MarketAisles,
})

export function MarketAislesAsync(): JSX.Element {

  return <MarketAisles fallback={<div>Loading...</div>} />

}
