import React, { useRef } from 'react'

import styled, { css, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, PageDetail, Seperator, Spacer } from '@client/components'
import { CarouselFlexContainer, getPageSectionBackground, PageSectionPadding, ResponsivePXValue } from '@client/components/Theme'
import { PageDetailSectionFragment, useGetAppQuery, PageDetailFragment } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { CarouselNavPositionEnum, Carousel } from '../misc/Carousel/Carousel'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string }>`
 display: flex;
 flex: 1;
 flex-direction: column;

 ${PageSectionPadding}

 background: ${(props): string => props.$background};

 ${ResponsivePXValue('padding', '55px')}

  .title {
    display: flex;
    align-self: center;
    ${ResponsivePXValue('max-width', { desktop: '968px' })}
  }
`

const PageDetailStyles = css`
    width: 80vw;
    ${ResponsivePXValue('margin-bottom', '48px')}
`

const PageDetailContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;

  .page-detail {
    ${PageDetailStyles}
  }
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .page-detail {
   ${PageDetailStyles}
  }  
`

export interface PageDetailSectionProps {
  pageSection: PageDetailSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageDetailSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageDetailSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const theme = useTheme()

  const background = getPageSectionBackground(pageSection?.background)

  const pageDetails = pageSection?.pageDetails || []

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const navPosition = isMobile ? CarouselNavPositionEnum.BOTTOM : CarouselNavPositionEnum.CENTER

  let index: number
  let pageDetail: PageDetailFragment

  return (
    <Container ref={pageRef} $background={background}>
      <Choose>
        <When condition={!!pageSection}>
          <If condition={!!pageSection.title}>
            <Heading className='title' variant='h4' align='center' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='8px' />
            <Seperator align='center' />
          </If>
          <If condition={!!pageSection.description}>
            <Heading variant='h2' align='center'>{pageSection.description}</Heading>
          </If>
          <Choose>
            <When condition={pageDetails.length === 1}>
              <PageDetailContainer>
                <PageDetail key={index} pageDetail={pageDetails[0]} className='page-detail' />
              </PageDetailContainer>
            </When>
            <Otherwise>
              <CarouselContainer>
                <Carousel
                  displayNavButtons
                  displayProgress={isMobile}
                  displayPagingInfo={isMobile}
                  navPosition={navPosition}>
                  <For each='pageDetail' of={pageDetails}>
                    <PageDetail
                      className='embla-slide page-detail'
                      pageDetail={pageDetail}
                      key={pageDetail.id}
                    />
                  </For>
                </Carousel>
              </CarouselContainer>
            </Otherwise>
          </Choose>
        </When>
        <Otherwise>
          Page Detail Section not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
