import React from 'react'

import styled from 'styled-components'

import { Rule, Paragraph, Link } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'

export interface InlineInputProps {
  title: string
  text: string[]
  selection?: 'none' | 'radioButton' | 'checkBox'
  selected?: boolean
  button?: {
    title: string
    disabled: boolean
    onClick: () => void
  }
  rule?: boolean
  children?: React.ReactNode
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 24px', desktop: '0 32px' })}
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`
const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('padding', '16px 0')}
`

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  
`

const TextContainer = styled.div`
  div{
    ${ResponsivePXValue('padding', { mobile: '4px 0', tablet: '6px 0', desktop: '8px 0' })}
    margin: 0vw;
  }
`

const ButtonContainer = styled.div`
  ${ResponsivePXValue('padding-top', { mobile: '16px' })}
`

export function InlineInput({ title, text, selection = 'none', button, rule = true, children }: InlineInputProps): JSX.Element {

  let textItem: string
  return (
    <Container>
      <RadioContainer>
        <If condition={selection === 'radioButton'}>
          <div>radio button</div>
        </If>
        <If condition={selection === 'checkBox'}>
          <div>Check box</div>
        </If>
        <ChildrenContainer>
          <Content>
            <TextContainer>
              <Paragraph variant='p2'>{title}</Paragraph>
              <For each='textItem' of={text}>
                <Paragraph key={textItem} variant='p1'>{textItem}</Paragraph>
              </For>
            </TextContainer>
            <If condition={!!button}>
              <ButtonContainer>
                <Link color={theme.colors.slates.ash} onClick={button.onClick}>{button.title}</Link>
              </ButtonContainer>
            </If>
          </Content>
          {children}
        </ChildrenContainer>
      </RadioContainer>
      <If condition={rule}>
        <Rule color='slate' />
      </If>
    </Container>
  )
}
