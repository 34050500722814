import React from 'react'

import styled, { CSS } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { DisabledEffect } from '@components/Theme'

const Container = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props): CSS => props.$disabled ? DisabledEffect : undefined};
`

export interface FilterItemProps {
  id?: string
  title: string
  quantity: number
  selected?: boolean
  disabled?: boolean
  className?: string
}

export function FilterItem(props: FilterItemProps): JSX.Element {

  const {
    title,
    disabled = false,
    className,
  } = props

  return (
    <Container className={className} $disabled={disabled}>
      <Paragraph variant='p2'>
        {title}
      </Paragraph>
    </Container>
  )
}
