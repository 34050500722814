import React, { useEffect } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Seperator, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetRelatedMarketProductsQuery, useGetAppQuery, MarketProductDetailsFragment, MarketProductListFragment, useGetRelatedMarketProductsLazyQuery } from '@hooks/api'
import { MarketProductCard, SectionLoading } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

export interface MarketDishYouMightAlsoLikeProps {
  dish: MarketProductDetailsFragment
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${ResponsivePXValue('margin-top', { mobile: '18px', tablet: '22px', desktop: '28px' })}

  .craft-meal-card {
    ${ResponsivePXValue('margin', { mobile: '0 0 18px', tablet: '0 0 22px', desktop: ' 0 0 28px' })}
  }
`

export function MarketDishYouMightAlsoLike({ dish }: MarketDishYouMightAlsoLikeProps): JSX.Element {

  const [getRelated, { data, loading }] = useGetRelatedMarketProductsLazyQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()
  const relatedFilter = (relatedDish: MarketProductListFragment) => relatedDish.id !== dish.id
  const marketProducts = data?.related?.list.filter(relatedFilter).slice(0, appData.app.deviceType === DeviceTypeEnum.TABLET ? 4 : 3) || []

  useEffect(() => {
    if (dish?.marketProductCategories?.length) {
      getRelated({ variables: { marketProductCategories: dish?.marketProductCategories?.map((category) => category.id) } })
    }
  }, [dish?.marketProductCategories?.length])

  let marketProduct: MarketProductListFragment

  return (
    <Choose>
      <When condition={!(marketProducts.length === 0)}>
        <Container id='marketDishYouMightLike'>
          <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>You might also like</Heading>
          <Spacer universal='8px' />
          <Seperator align='center' />
          <CardContainer>
            <For each='marketProduct' of={marketProducts || []}>
              <MarketProductCard
                key={marketProduct.id}
                loading={loading}
                marketProduct={marketProduct} />
            </For>
          </CardContainer>
        </Container>
      </When>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <Otherwise>
      </Otherwise>
    </Choose>
  )

}
