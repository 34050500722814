import React from 'react'

import { useParams } from 'react-router'

import { WineDetailsFragment, useOneWineWithSlugQuery } from '@hooks/api'
import { DetailPageWrapper, WinePDP } from '@organisms/index'
import { ProductStatusEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

const seo = (wine?: WineDetailsFragment): SearchEngineOptimizationParams => ({
  name: 'UCOOK Wine',
  title: `${wine?.name ?? 'Awesome'} Online | South Africa | UCOOK`,
  meta: [{
    name: 'description',
    content: `${wine?.description?.length > 155 ? wine?.description?.substring(0, 155) ?? 'Amazing' : wine?.description ?? 'Amazing'}`,
  }, {
    name: 'keywords',
    content: `Buy ${wine?.name ?? 'Awesome'},Online ${wine?.name ?? 'Awesome'},Deliver ${wine?.name ?? 'Awesome'}`,
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
})

export function WineDetails(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()
  const { data, loading, error } = useOneWineWithSlugQuery({
    variables: {
      slug,
      status: [ProductStatusEnum.PUBLISHED],
    },
  })
  const wine = data?.wine

  return (
    <DetailPageWrapper
      loading={loading && !wine}
      error={error}
      pdp={<WinePDP dish={wine} loading={loading} />}
      seo={<SearchEngineOptimization seo={seo(wine)} />} />
  )
}
