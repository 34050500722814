import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CMS')

const CMS = loadable(() => import(/* webpackChunkName: "cms" */'@pages/CMS'), {
  resolveComponent: (components) => components.CMS,
})

export function CMSAsync(): JSX.Element {

  return <CMS fallback={<div>Loading...</div>} />

}
