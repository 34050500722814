import React, { Fragment } from 'react'

import styled from 'styled-components'

import { DeviceContainer, Heading, Paragraph } from '@client/components'
import { Icon, IconEnum } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const MainContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('justify-content', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'column', desktop: 'column' })}
  ${ResponsivePXValue('width', 'CALC(100% - 48px)')}
  ${ResponsivePXValue('padding', '0 24px')}

  .text {
    margin: 0px
  }

`

const MobileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('margin', '2px 0')}
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('height', '26px')}

`

const MobileIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('margin', '2px 0')}
  ${ResponsivePXValue('justify-content', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('padding', { mobile: '24px 0 0', tablet: '0', desktop: '0' })}
  ${ResponsivePXValue('width', { mobile: '30%', tablet: '100%', desktop: '100%' })}
  ${ResponsivePXValue('height', '26px')}

`

export interface ReferralScorecardItemProps {
  icon: IconEnum
  title?: string
  subTitle?: string
  className?: string
}

export function ReferralScorecardItem(props: ReferralScorecardItemProps): JSX.Element {
  const { icon, title, subTitle, className } = props

  const iconColor = theme.colors.slates.celeste

  return (
    <Fragment>
      <DeviceContainer $tablet $desktop>
        <MainContainer
          className={className}>
          <IconContainer>
            <Icon
              color={iconColor}
              icon={icon} />
          </IconContainer>
          <Heading className='text' variant='h6' align='center'>{title}</Heading>
          <Paragraph className='text' variant='p1' align='center'>{subTitle}</Paragraph>
        </MainContainer>
      </DeviceContainer>
      <DeviceContainer $mobile>
        <MainContainer
          className={className}>
          <MobileIconContainer>
            <Icon
              color={iconColor}
              icon={icon} />
          </MobileIconContainer>
          <MobileTextContainer>
            <Heading className='text' variant='h6' align='left'>{title}</Heading>
            <Paragraph className='text' variant='p1' align='left'>{subTitle}</Paragraph>
          </MobileTextContainer>
        </MainContainer>
      </DeviceContainer>
    </Fragment>
  )

}
