import React from 'react'

import OneSignal from 'react-onesignal'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Paragraph, Link, Button } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useUserDetailsQuery, useUserLogoutMutation } from '@hooks/api'

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '24px 16px' })}

  .text-link {
    font-weight: 500;
    text-transform: uppercase;
    ${ResponsivePXValue('font-size', '10px')}
  }
`
const UsernameColumn = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', '6px 8px')}
  div{
    padding: 0vw;
    margin: 0vw;
  }
`

const AccountThumbnail = styled.div`
div{
  background-color: ${(props): string => props.theme.colors.oranges.coral};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0vw;
  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('height', '48px')}
  ${ResponsivePXValue('width', '48px')}
  ${ResponsivePXValue('border-radius', '8px')}
}
`

export function Avatar(): JSX.Element {

  const config = useConfig()
  const { data, loading: detailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [logOut, { loading: logoutLoading }] = useUserLogoutMutation()

  const navigate = useNavigate()

  const placeholderUsername = data?.currentUser?.name ?? 'Unknown User'
  const getInitials = (name: string): string => {
    const nameArray = name.split(' ')
    return nameArray[0].charAt(0) + nameArray[nameArray.length - 1].charAt(0)
  }
  const handleLogout = async (): Promise<void> => {
    if (data?.currentUser?.__typename === 'RegisteredUser') {
      await logOut({
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      OneSignal.logout()
      navigate('/')
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
  }

  const title = data?.currentUser?.__typename === 'RegisteredUser' ? 'LOGOUT' : 'LOGIN'

  return (
    <UserInfoContainer>
      <AccountThumbnail>
        <Button title={getInitials(placeholderUsername)} loading={detailsLoading || logoutLoading} />
      </AccountThumbnail>
      <UsernameColumn>
        <Paragraph variant='p2'>{placeholderUsername}</Paragraph>
        <Link variant='l2' className='text-link' color={theme.colors.slates.ash} hoverColor={theme.colors.slates.bitter} onClick={handleLogout}> {title} </Link>
      </UsernameColumn>
    </UserInfoContainer>
  )
}
