import React from 'react'

import { useParams } from 'react-router'

import { MarketProductDetailsFragment, useGetOneMarketProductQuery } from '@hooks/api'
import { DetailPageWrapper, MarketProductPDP } from '@organisms/index'
import { ProductStatusEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

const seo = (dish?: MarketProductDetailsFragment): SearchEngineOptimizationParams => ({
  name: 'UCOOK Market Product',
  title: `${dish?.name ?? 'Awesome'} Recipe & Ingredients | UCOOK`,
  meta: [{
    name: 'description',
    content: `${dish?.description ?? 'Amazing Recipe'}`,
  }, {
    name: 'keywords',
    content: `${dish?.name ?? 'Awesome'} Recipe,${dish?.name ?? 'Awesome'} Ingredients,${dish?.name ?? 'Awesome'} delivery`,
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
})

export function MarketProductDetails(): JSX.Element {

  const { slug, search } = useParams<{ slug: string, search: string }>()
  const { data, loading, error } = useGetOneMarketProductQuery({
    variables: {
      slug,
      status: [ProductStatusEnum.PUBLISHED],
    },
  })

  const product = data?.marketProduct

  return (
    <DetailPageWrapper
      loading={loading && !product}
      error={error}
      pdp={<MarketProductPDP dish={product} slug={slug} search={search} loading={loading} />}
      seo={<SearchEngineOptimization seo={seo(product)} />} />
  )
}
