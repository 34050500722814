import React from 'react'

import { Svg } from './Svg'

export interface FreeRangeProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function FreeRange({ color, hoverColor, className }: FreeRangeProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.5649 5.59333C24.2928 2.96063 27.5409 1.45984 31.3094 1.08085C31.4306 1.07074 31.5519 1.03032 31.6681 1C32.4511 1 33.229 1 34.012 1C34.1332 1.02527 34.2494 1.06569 34.3707 1.0758C41.1347 1.71755 46.7217 6.44226 48.4494 13.0215C48.702 13.9866 48.8232 14.9872 49 15.9675C49 16.781 48.9949 17.5794 48.9949 18.393C48.8333 19.3278 48.7373 20.2778 48.4847 21.1874C46.7672 27.2613 42.8421 31.1724 36.7601 32.845C30.2233 34.649 23.6613 36.3671 17.1145 38.1306C16.8973 38.1862 16.7003 38.3025 16.5437 38.4591C15.205 39.778 13.8714 41.107 12.5529 42.4562C12.4014 42.6128 12.2903 42.8756 12.2802 43.0979C12.2448 43.8458 12.27 44.5987 12.265 45.3466C12.2549 47.1758 11.2193 48.4846 9.43612 48.9242C9.3755 48.9394 9.32498 48.9747 9.26942 49H8.2389C8.16818 48.9697 8.09745 48.9343 8.02168 48.9192C6.49105 48.5301 5.57167 47.5548 5.28373 46.0035C5.21301 45.6144 5.22816 45.2051 5.2029 44.7806C4.94022 44.7806 4.70785 44.7857 4.47548 44.7806C2.91454 44.7452 1.59103 43.7245 1.15155 42.2288C1.10608 42.0822 1.06062 41.9357 1.01515 41.7841C1.0101 41.4506 1.00505 41.1373 1 40.8038C1.20206 40.0559 1.4698 39.3485 2.04062 38.7926C2.72258 38.1306 3.53084 37.7719 4.48053 37.7567C5.29383 37.7416 6.10713 37.7719 6.91539 37.7416C7.1326 37.7314 7.40034 37.6253 7.55694 37.4737C8.89055 36.17 10.204 34.8511 11.5123 33.5222C11.6942 33.3402 11.8205 33.1129 11.8861 32.8653C13.7047 26.1344 15.5031 19.3986 17.3166 12.6678C17.7611 11.0103 18.5087 9.48426 19.4786 8.07443L21.5649 5.59333Z" className='fill' />
    </Svg>
  )

}
