import React, { Fragment, useRef, useState } from 'react'

import update from 'react-addons-update'
import { Outlet } from 'react-router'
import styled, { CSS } from 'styled-components'

import useIsomorphicLayoutEffect from '@client/hooks/UseIsomorphicLayoutEffect'
import { ToastHeader } from '@molecules/index'

import { CheckoutFooter } from '../organisms/checkout/CheckoutFooter'
import { CheckoutHeader } from '../organisms/checkout/CheckoutHeader'
import { ResponsivePXValue } from '../Theme'

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
`

const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 30;
`

const NavigationBar = styled.div``

const FooterContainer = styled.div`
  width: 100%;
  position: absolute;
  ${ResponsivePXValue('bottom', { desktop: '0' })};
`

const Content = styled.div<{ $height: number }>`
  flex: 1;
  width: 100%;
  position: relative;
  max-width: ${(props): number => props.theme.MAX_WIDTH}px;
  ${(props): CSS => {
    return ResponsivePXValue('min-height', `CALC(100vh - ${props.$height}px)`)
  }}
`

interface CheckoutLayoutProps {
  children: JSX.Element | JSX.Element[]
}

interface CheckoutLayoutState {
  headerHeight: number
}

const DEFAULT_STATE = {
  headerHeight: 0,
}

export function CheckoutLayout(): JSX.Element {

  const [state, setState] = useState<CheckoutLayoutState>({ ...DEFAULT_STATE })
  const headerRef: React.RefObject<HTMLDivElement> = useRef()

  useIsomorphicLayoutEffect(() => {
    const headerHeight = headerRef?.current?.clientHeight ?? 0
    setState((prevState) => update(prevState, {
      headerHeight: { $set: headerHeight },
    }))
    // TODO: update on toast header
  }, [])

  return (
    <Fragment>
      <Container>
        <Header ref={headerRef}>
          <ToastHeader />
          <NavigationBar>
            <CheckoutHeader />
          </NavigationBar>
        </Header>
        <Content $height={state.headerHeight}>
          <Outlet />
          <FooterContainer>
            <CheckoutFooter />
          </FooterContainer>
        </Content>
      </Container>
    </Fragment>
  )

}
