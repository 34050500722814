import React from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue, theme, ZeroSpace, MediumBoxShadow } from '@components/Theme'

const ITEMS_PER_ROW = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
}

const GUTTER = {
  mobile: 0,
  tablet: 24,
  desktop: 32,
}

const MOBILE_WIDTH = Math.round((theme.calculationSize.mobile - (theme.pagePadding.mobile * 2) - ((ITEMS_PER_ROW.mobile - 1) * GUTTER.mobile)) / ITEMS_PER_ROW.mobile)
const TABLET_WIDTH = Math.round((theme.calculationSize.tablet - (theme.pagePadding.tablet * 2) - ((ITEMS_PER_ROW.tablet - 1) * GUTTER.tablet)) / ITEMS_PER_ROW.tablet)
const DESKTOP_WIDTH = Math.round((theme.calculationSize.desktop - (theme.pagePadding.desktop * 2) - ((ITEMS_PER_ROW.desktop - 1) * GUTTER.desktop)) / ITEMS_PER_ROW.desktop)

const Container = styled.div<{ $variant: 'compact' | 'normal' }>`

 ${MediumBoxShadow}

  position: relative;
  z-index: 7;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  
  ${ResponsivePXValue('width', { mobile: `${MOBILE_WIDTH}px`, tablet: `${TABLET_WIDTH}px`, desktop: `${DESKTOP_WIDTH}px` })}

  ${(props): CSS => props.$variant === 'compact'
    ? ResponsivePXValue('height', { mobile: `${MOBILE_WIDTH * 1.64}px`, tablet: `${TABLET_WIDTH * 1.64}px`, desktop: `${DESKTOP_WIDTH * 1.33}px` })
    : ResponsivePXValue('height', { mobile: `${MOBILE_WIDTH * 1.75}px`, tablet: `${TABLET_WIDTH * 1.75}px`, desktop: `${DESKTOP_WIDTH * 1.5}px` })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
    ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '16px', desktop: '16px' })}
  }

  .price-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .sub-title {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
    font-weight: 400;
  }

  .description-text {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
    font-weight: 400;
  }

  .card-image {
    width: 100%;
    cursor: pointer;

    ${ResponsivePXValue('height', { mobile: `${MOBILE_WIDTH * 0.75}px`, tablet: `${TABLET_WIDTH * 0.75}px`, desktop: `${DESKTOP_WIDTH * 0.75}px` })}
    ${ResponsivePXValue('max-height', { mobile: '216px', tablet: '236px', desktop: '259px' })}
  }
`

export interface StoreCardContainerProps {
  children: JSX.Element | JSX.Element[]
  variant?: 'compact' | 'normal'
  className?: string
}

export function StoreCardContainer({ children, className, variant = 'normal' }: StoreCardContainerProps): JSX.Element {

  return (
    <Container className={className} $variant={variant}>
      {children}
    </Container>
  )

}
