import React from 'react'

import styled from 'styled-components'

import { ContentSeperator, Heading, Paragraph, Spacer } from '@client/components/atoms'
import { ResponsivePXValue, Ellipsis } from '@client/components/Theme'

import { SimpleTable, SimpleTableRow } from '../tables'

const Container = styled.div<{ $active: boolean }>`
  position: absolute;
  height: 100%;
  max-width: 100%;
  z-index: 2;
  transition: 0.4s ease-in-out;

  top: ${(props): string => props.$active ? '0' : '100%'};
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  .title-header {
    ${Ellipsis()}
  }
  
  .sub-title {
    ${Ellipsis(7)}
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
`

export interface QuickFactCardProps {
  title: string
  description: string
  tableValues: { title: string, value: string }[]
  active?: boolean
}

export function QuickFactCard({ title, description, tableValues, active = false }: QuickFactCardProps): JSX.Element {

  let item: { title: string, value: string }
  let index: number

  return (
    <Container $active={active}>
      <ContentContainer>
        <Heading variant='h5' className='title-header'>{title}</Heading>
        <Spacer variant='horizontal' universal='8px' />
        <Paragraph variant='p2' className='sub-title'>{description}</Paragraph>
        <ContentSeperator margin='24px 0px' />
        <SimpleTable>
          <For each='item' of={tableValues || []} index='index' >
            <SimpleTableRow key={index} title={item.title} description={item.value} />
          </For>
        </SimpleTable>
      </ContentContainer>
    </Container>
  )
}
