import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Form, TextInput, Spacer, Paragraph, Button } from '@client/components'
import { ModalMessageContainer, useForm } from '@client/components/molecules'
import { ZeroSpace } from '@client/components/Theme'
import { UserDetailsDocument, useRequestPhoneVerificationMutation } from '@hooks/api'

const FormContainer = styled.div`
  width: 100%;
  
  .input {
    ${ZeroSpace}
  }
`

export interface EnterNewCellphoneNumberFormProps {
  onNumberChanged: () => void
}

interface EnterNewCellphoneNumberState {
  error: string
}

const DEFAULT_STATE: EnterNewCellphoneNumberState = {
  error: '',
}

export function EnterNewCellphoneNumberForm({ onNumberChanged }: EnterNewCellphoneNumberFormProps): JSX.Element {

  const [requestPhoneVerification, { loading }] = useRequestPhoneVerificationMutation()
  const [form] = useForm()
  const [state, setState] = useState<EnterNewCellphoneNumberState>({ ...DEFAULT_STATE })

  const _handleSubmit = async (data: { number: string }): Promise<void> => {
    try {
      await requestPhoneVerification({
        variables: {
          number: data.number,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      onNumberChanged?.()
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  return (
    <FormContainer>
      <Form form={form} onFinish={_handleSubmit} disabled={loading}>
        <Paragraph variant='p4' align='center'>Enter your mobile number below to verify your account.</Paragraph>
        <Spacer universal='24px' />
        <TextInput
          name='number'
          variant='phone'
          placeholder='Enter your cellphone number'
          rules={[{ required: true, message: 'Please input a number' }]}
          showLabel={false}
          className='input' />
        <Spacer universal='24px' />
        <If condition={state.error !== ''}>
          <ModalMessageContainer>
            <Paragraph variant='p1' align='center' className='error'>
              {state.error}
            </Paragraph>
            <Spacer universal='12px' />
            <Paragraph variant='p1' align='center'>
              Get in touch with Customer Support on 021 447 4424 or email us at support@ucook.co.za
            </Paragraph>
          </ModalMessageContainer>
          <Spacer universal='24px' />
        </If>
        <Button
          loading={loading}
          disabled={loading}
          color='black'
          fullWidth
          title='VERIFY'
          onClick={() => form.submit()} />
      </Form>
    </FormContainer>
  )
}
