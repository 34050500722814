import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { APP_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { Carousel, CarouselFlexContainer, CarouselNavPositionEnum, SelectPlanCard } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { usePlansPageQuery, useGetAppQuery, useUserDetailsQuery, useUserCartQuery, UserSubscriptionFragment, MealKitCategoryFragment, useUpdateUserSubscription, useActivateSubscription, useResetUserMenuMutation, UserCartDocument, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { DeviceTypeEnum, NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

import { SectionLoading } from './SectionLoading'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}
`
interface PageDataMealKitPlansState {
  pageLoading: boolean
  categoryId: string | null
  numberOfMeals: number
  numberOfPortions: NumberOfPortionsEnum
  userCurrentPlan: number | null
}

const DEFAULT_STATE: PageDataMealKitPlansState = {
  pageLoading: false,
  categoryId: null as string | null,
  numberOfMeals: 3,
  numberOfPortions: NumberOfPortionsEnum.SERVES_2,
  userCurrentPlan: null,
}

export function PageDataMealKitPlans(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<PageDataMealKitPlansState>({ ...DEFAULT_STATE })

  const { data, loading } = usePlansPageQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { loading: userCartLoading, data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [updateSubscription] = useUpdateUserSubscription()
  const [activateSubscription] = useActivateSubscription()
  const [resetUserMenu] = useResetUserMenuMutation()
  const plans = data?.categories?.list || []
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)

  const { addToast } = useToasts()

  const currentCategory: string | null = userCartData?.currentUser?.activeMenu?.subscription?.category?.id || null
  const currentNumberOfPortions: NumberOfPortionsEnum = userCartData?.currentUser?.activeMenu?.subscription?.numberOfPortions || NumberOfPortionsEnum.SERVES_2
  const currentNumberOfMeals: number = userCartData?.currentUser?.activeMenu?.subscription?.numberOfMeals || 3

  const __buttonTitleForPlan = (categoryId: string): string => {
    let actionTitle = 'GET STARTED'
    if (userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan && userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
      if (categoryId === currentCategory) {
        if (currentNumberOfPortions === state.numberOfPortions && currentNumberOfMeals === state.numberOfMeals) {
          return 'Currently Selected'
        }
        return 'Update Plan'
      }
      actionTitle = 'Change Plan'
    } else if (userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan && userCartData?.currentUser?.checkoutStatus?.hasPausedSubscription) {
      actionTitle = 'Re Activate Subscription'
    }

    return actionTitle
  }

  const __planOptionAvailable = (categoryId: string): boolean => {
    if (userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan && userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription) {
      if (categoryId === currentCategory && currentNumberOfPortions === state.numberOfPortions && currentNumberOfMeals === state.numberOfMeals) {
        return false
      }
    }
    return true
  }

  const handleChangeSubscription = async (categoryId: string, numberOfMeals: number, numberOfPortions: NumberOfPortionsEnum): Promise<void> => {
    try {
      setState(prevState => ({ ...prevState, pageLoading: true }))
      if (!userCartData?.currentUser?.activeMenu) {
        addToast('Could not find active menu', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: {
            category: categoryId,
            numberOfMeals,
            numberOfPortions,
            updateMenu: true,
          },
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      if (!userCartData?.currentUser?.checkoutStatus?.hasActiveSubscription && userCartData?.currentUser?.checkoutStatus?.hasSetDeliveryAddressDetails && userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod) {
        await activateSubscription({
          variables: {
            id: userCartData?.currentUser?.activeMenu?.subscription?.id,
            reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })

      } else if (!userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox) {
        await resetUserMenu({
          variables: {
            id: userCartData.currentUser.activeMenu.id,
          },
          refetchQueries: [{
            query: UserCartDocument,
          }],
        })
      }

      CheckoutPlugin.shared().checkout(client)
      setState(prevState => ({ ...prevState, pageLoading: false }))
    } catch (e) {
      setState(prevState => ({ ...prevState, pageLoading: false }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleSetNumberOfMeals = (numberOfMeals: number): void => {
    setState((prevState) => update(prevState, {
      numberOfMeals: { $set: numberOfMeals },
    }))
  }

  const _handleSetNumberOfPortions = (numberOfPortions: NumberOfPortionsEnum): void => {
    setState((prevState) => update(prevState, {
      numberOfPortions: { $set: numberOfPortions },
    }))
  }

  let plan: MealKitCategoryFragment

  useEffect(() => {
    if (subscription) {
      const data = {
        categoryId: subscription.category.id,
        numberOfPortions: subscription.numberOfPortions,
        numberOfMeals: subscription.numberOfMeals,
      }
      setState(prevState => ({ ...prevState, ...data }))
    }
  }, [subscription !== undefined])

  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const navPosition = isMobile ? CarouselNavPositionEnum.BOTTOM : CarouselNavPositionEnum.CENTER

  return (
    <Container>
      <Choose>
        <When condition={loading || userDetailsLoading || userCartLoading}>
          <SectionLoading />
        </When>
        <When condition={!data}>
          No Meal Kit plans found
        </When>
        <Otherwise>
          <CarouselContainer>
            <Carousel
              displayNavButtons
              displayPagingInfo={isMobile}
              navPosition={navPosition}>
              <For each='plan' of={plans}>
                <SelectPlanCard
                  key={plan.id}
                  className='embla-slide'
                  loading={state.pageLoading}
                  plan={plan}
                  buttonTitle={__buttonTitleForPlan(plan.id)}
                  buttonEnabled={__planOptionAvailable(plan.id) && !state.pageLoading}
                  numberOfMeals={state.numberOfMeals}
                  numberOfPortions={state.numberOfPortions}
                  onNumberOfMealsChange={_handleSetNumberOfMeals}
                  onNumberOfPortionsChange={_handleSetNumberOfPortions}
                  handleChangeSubscription={handleChangeSubscription} />
              </For>
            </Carousel>
          </CarouselContainer>
        </Otherwise>
      </Choose>
    </Container>
  )
}
