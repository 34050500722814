import React from 'react'

import { Svg } from './Svg'

export interface WasteProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Waste({ color, hoverColor, className }: WasteProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.703 32.0219C30.703 26.6875 34.7571 22.2838 40 21.6421C37.1199 17.9487 32.7375 15.4505 27.7532 15.0341C27.4349 14.4659 27.0867 13.9172 26.7136 13.3882C26.7932 13.3931 26.8728 13.4029 26.9573 13.4029V13.4078C26.9772 13.4078 26.9921 13.4078 27.012 13.4078C27.0618 13.4078 27.1115 13.4078 27.1613 13.4078C28.0765 13.4078 28.9371 13.1629 29.6733 12.7367C30.5687 12.2518 31.3148 11.5072 31.9068 10.5275C32.5335 9.49393 32.8121 8.35748 32.9066 7.16716C32.9713 6.33442 32.9315 5.50658 32.8221 4.68364C32.7176 3.88519 32.5435 3.10144 32.3246 2.32748C32.2749 2.15604 32.2351 1.97969 32.2102 1.80335L32.0858 1L32.051 1.10777C31.7227 2.13154 31.0711 3.02796 30.1757 3.63047C30.1658 3.63537 30.1558 3.64517 30.1459 3.65007C29.7131 3.93908 29.1659 4.01745 28.6635 3.8656C28.1959 3.71864 27.6985 3.64027 27.1812 3.63537C24.4503 3.62557 22.2019 5.82008 22.1969 8.51424C22.1969 8.65139 22.2019 8.78855 22.2168 8.92571C21.2418 8.24972 20.1972 7.65701 19.0879 7.16226C18.4214 6.86346 17.6305 7.15736 17.332 7.81865C17.0286 8.47995 17.327 9.2539 17.9986 9.54781C20.7344 10.7577 23.0127 12.6583 24.6492 15.0439C15.8695 15.8521 9 23.1263 9 31.9828C9 41.378 16.7351 49 26.2808 49C31.8421 49 36.7916 46.4087 39.9503 42.3871C34.7322 41.7307 30.703 37.3417 30.703 32.0219Z" className='fill' />
    </Svg>
  )

}
