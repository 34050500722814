import React from 'react'

import styled from 'styled-components'

import { Form, TextInput, Paragraph, Spacer, Button } from '@client/components'
import { useForm } from '@client/components/molecules'
import { ZeroSpace } from '@client/components/Theme'

const Container = styled.div`
  width: 100%;
  
  .input {
    ${ZeroSpace}
  }

  .instruction-text {
    ${ZeroSpace}
    line-height: 1;
  }
`

export interface EnterNewPasswordFormProps {
  onPasswordSet: (password: string) => void
}

export function EnterNewPasswordForm({ onPasswordSet }: EnterNewPasswordFormProps): JSX.Element {

  const [form] = useForm()

  const _handleRequestReset = (data: { password: string }) => {
    onPasswordSet(data.password as string)
  }

  return (
    <Container>
      <Form form={form} onFinish={_handleRequestReset}>
        <Paragraph align='center' className='instruction-text'>Enter your new password.</Paragraph>
        <Spacer variant='horizontal' universal='24px' />
        <TextInput
          name='password'
          label='Password'
          placeholder='Your new password'
          variant='password'
          rules={[{ required: true, message: 'Please input your new password' }]}
          showLabel={false}
          className='input' />
        <Spacer variant='horizontal' universal='16px' />
        <Button
          title='Reset password'
          onClick={() => form.submit()}
          className='reset-button' />
      </Form>
    </Container>
  )

}
