import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue, Button } from '@client/components'
import { Form, TextInput, useForm } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: '0 25%' })}

  .refer-button {
    ${ResponsivePXValue('margin', '16px 0')}
  }
`

export interface SendReferenceFormProps {
  onSendReference: (email: string, name?: string) => void
}

export function SendReferenceForm({ onSendReference }: SendReferenceFormProps): JSX.Element {

  const [form] = useForm()

  const _handleSendReference = (data: { email: string, name: string }) => {
    onSendReference(data.email as string, data.name as string)
    form.setFieldsValue({ name: '', email: '' })
  }

  return (
    <Container>
      <Form form={form} onFinish={_handleSendReference}>
        <TextInput name='name' label='Name' placeholder='Enter friends name' rules={[{ required: true, message: 'Please enter your friends name' }]} />
        <TextInput name='email' label='Email' placeholder='Enter friends email' rules={[{ required: true, message: 'Please enter your friends email' }]} variant='email' />
        <Button className='refer-button' title='SEND INVITE' onClick={() => form.submit()} />
      </Form>
    </Container>
  )

}
