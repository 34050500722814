import React, { Fragment, useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { SmallLoader } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery, useGetAllFriendReferencesLazyQuery, UserDetailsQuery, GetAllFriendReferencesDocument, useReferFriendMutation, useResendReferralEmailMutation } from '@hooks/api'
import { FriendReference, UserReferralStatusEnum, UserStatusEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { ContentCard, ContentCardHeaderEnum } from '../molecules'
import { ReferralScoreboard } from '../organisms/referral/ReferralScoreboard'
import { SendReference } from '../organisms/referral/SendReference'
import { UserReferrals } from '../organisms/referral/UserReferrals'

const CardSeperator = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '24px', tablet: '28px', desktop: '32px' })}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('height', '32px')}
`

const LoaderContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '80px')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface ReferralsState {
  userId: string
  userDetailsData: UserDetailsQuery | null
  allReferrals: FriendReference[]
}

const DEFAULT_STATE: ReferralsState = {
  userId: '',
  userDetailsData: null,
  allReferrals: [] as FriendReference[],
}

const seo = {
  name: 'User Dashbaord',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Referrals(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<ReferralsState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [getFriendReferences, { data: friendReferencesData, loading: friendReferencesLoading }] = useGetAllFriendReferencesLazyQuery()
  const [referFriend] = useReferFriendMutation()
  const [resendReferralEmail] = useResendReferralEmailMutation()
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const _handleReferFriend = async (email: string, name?: string): Promise<void> => {
    const input = {
      user: state.userId,
      emailAddress: email,
      name,
    }

    try {
      const friendReference = await referFriend({
        variables: {
          input,
        },
        refetchQueries: [{ query: GetAllFriendReferencesDocument, variables: { filters: { referredBy: state.userId } } }],
        awaitRefetchQueries: true,
      })
      if (friendReference && friendReference.data) {
        if (friendReference.data.friendReference.status === UserReferralStatusEnum.EMAILED) {
          addToast(`${email} referred successfully. `, {
            appearance: 'success',
            autoDismiss: true,
          })
        } else if (friendReference.data.friendReference.status === UserReferralStatusEnum.ALREADY_SUBSCRIPTION_USER ||
          friendReference.data.friendReference.status === UserReferralStatusEnum.ALREADY_REFERRED) {
          addToast(`${email} is already a member`, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else if (friendReference.data.friendReference.status === UserReferralStatusEnum.ALREADY_REFERRED_BY_USER) {
          addToast(`You have already referred ${email}`, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      }
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleResendEmail = async (id: string): Promise<void> => {
    try {
      const response = await resendReferralEmail({
        variables: {
          id,
        },
        refetchQueries: [{ query: GetAllFriendReferencesDocument, variables: { filters: { referredBy: state.userId } } }],
        awaitRefetchQueries: true,
      })
      if (response?.data?.response) {
        addToast(`${response?.data?.response}`, {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  useEffect(() => {
    if (userDetailsData?.currentUser) {
      if (userDetailsData.currentUser.status === UserStatusEnum.GUEST) {
        navigate('/')
      }
      setState((prevState) => ({ ...prevState, userId: userDetailsData.currentUser.id, userDetailsData }))
      getFriendReferences({ variables: { filters: { referredBy: userDetailsData.currentUser.id } } })
    }
  }, [userDetailsData])

  useEffect(() => {
    if (friendReferencesData?.friendReferences) {
      const friendReferences = friendReferencesData.friendReferences.list as FriendReference[]

      setState((prevState) => ({ ...prevState, allReferrals: friendReferences }))
    }
  }, [friendReferencesData])

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={userDetailsLoading || friendReferencesLoading}>
          <ContentCard headerType={ContentCardHeaderEnum.NONE}>
            <LoaderContainer>
              <LoaderBox>
                <SmallLoader color={theme.colors.oranges.coral} />
              </LoaderBox>
            </LoaderContainer>
          </ContentCard>
        </When>
        <Otherwise>
          <SendReference
            userDetailsData={state.userDetailsData}
            onSendReferral={_handleReferFriend} />
          <CardSeperator />
          <ReferralScoreboard
            userDetailsData={state.userDetailsData} />
          <CardSeperator />
          <UserReferrals
            userDetailsData={state.userDetailsData}
            allReferrals={state.allReferrals}
            onResendReferral={_handleResendEmail} />
        </Otherwise>
      </Choose>
    </Fragment>
  )

}
