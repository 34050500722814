import React, { useEffect } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { useNavigate } from 'react-router'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { useGetCheckoutQuery } from '@hooks/api'
import { CheckoutStepEnum } from '@uctypes/api/globalTypes'

import { SectionLoading } from '../molecules'
import { CheckoutPayment } from '../organisms/checkout/sections/CheckoutPayment'
import { CheckoutSummary } from '../organisms/checkout/sections/CheckoutSummary'
import { CheckoutThankYou } from '../organisms/checkout/sections/CheckoutThankYou'
import { CheckoutDelivery } from '../organisms/checkout/sections/index'

export function Checkout(): JSX.Element {

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const navigate = useNavigate()

  const step = CheckoutPlugin.shared().getActiveStep(checkoutData?.checkout)
  const type = CheckoutPlugin.shared().getActiveType(checkoutData?.checkout)

  useEffect(() => {
    CheckoutPlugin.shared().checkout(client)
  }, [])

  useEffect(() => {
    if (step === CheckoutStepEnum.NOTHING) {
      navigate('/')
    }
    if (step === CheckoutStepEnum.CONFIGURE_PLAN) {
      navigate('/meal-kit/plans')
    }
  }, [step])

  let $checkoutStep = (
    <SectionLoading />
  )

  switch (step) {
    case CheckoutStepEnum.VALIDATION:
    case CheckoutStepEnum.NOTHING:
    case CheckoutStepEnum.IDENTIFY_USER:
    case CheckoutStepEnum.VERIFY_NUMBER:
    case CheckoutStepEnum.REACTIVATE:
    case CheckoutStepEnum.SAVE_MENU:
      break
    case CheckoutStepEnum.DELIVERY:
    case CheckoutStepEnum.DELIVERY_DAY:
      $checkoutStep = <CheckoutDelivery checkoutType={type} />
      break
    case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
    case CheckoutStepEnum.PAYMENT:
      $checkoutStep = <CheckoutPayment checkoutType={type} />
      break
    case CheckoutStepEnum.CART_ERRORS:
    case CheckoutStepEnum.CART_SUMMARY:
      $checkoutStep = <CheckoutSummary checkoutType={type} />
      break
    case CheckoutStepEnum.THANK_YOU:
      $checkoutStep = <CheckoutThankYou checkoutType={type} />
      break
  }

  return (
    <>
      {$checkoutStep}
    </>
  )

}
