import React, { useRef } from 'react'

import styled, { useTheme, CSS } from 'styled-components'

import { Heading, ResponsiveImage, IconEnum, Link } from '@atoms/index'
import { PageMediaImage, PageMediaImageFloat, MediumBoxShadow, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'
import { PageSectionMediaVariationEnum } from '@uctypes/api/globalTypes'

import { IconText } from './IconText'
import { PageSectionRef } from './PageSectionGroup'

const MobileContainer = styled.div`
  display: inline-block;
  ${(props): string => props.theme.desktop} {
    display: none;
  }
`

const MobileLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props): string => props.theme.colors.whites.desertStorm};

  ${ResponsivePXValue('width', { mobile: '320px', tablet: '768px' })}

`

const MobileRightContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '320px', tablet: '768px' })}
  ${ResponsivePXValue('padding-bottom', { mobile: '48px', tablet: '100px' })}
  ${ResponsivePXValue('padding-top', { mobile: '16px', tablet: '40px' })}

`

const MobileLeftImageContainer = styled.div<{ $shadow: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
 
  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${PageMediaImage}
  ${PageMediaImageFloat}

  ${ResponsivePXValue('margin', { mobile: '0 16px 16px', tablet: '0 40px 40px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '-48px', tablet: '-100px' })}

`

const MobileRightImageContainer = styled.div<{ $shadow: boolean }>`
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${PageMediaImage}

  ${ResponsivePXValue('margin', { mobile: '0 16px 16px', tablet: '0 40px 40px' })}
`

const MobileContentContainer = styled.div`

  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '40px' })}

`

const MobileButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('margin', { mobile: '32px 0 16px', tablet: '80px 0 40px' })}

`

const DesktopContainer = styled.div`
  display: none;
  width: 100%;
  ${(props): string => props.theme.desktop} {
    display: inline-block;
  }
`

const DesktopLeftContainer = styled.div`
  display: flex;
  width: 100%;
`

const DesktopRightContainer = styled.div`
  display: flex;
  width: 100%;

  ${ResponsivePXValue('padding-bottom', { desktop: '40px' })}
`

const DesktopLeftImageContainer = styled.div<{ $shadow: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  flex-shrink: 0;

  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${PageMediaImage}
  ${PageMediaImageFloat}

  ${ResponsivePXValue('margin-left', { desktop: '72px' })}

`

const DesktopRightImageContainer = styled.div<{ $shadow: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  flex-shrink: 0;

  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${PageMediaImage}
 
  ${ResponsivePXValue('margin-right', { desktop: '72px' })}
`

const DesktopContentContainer = styled.div`
  flex-grow: 1;

  ${ResponsivePXValue('padding', { desktop: '0 84px' })}

`

const DesktopButtonsContainer = styled.div`
  display: flex;

  ${ResponsivePXValue('margin', { desktop: '30px 0;' })}

`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('margin-bottom', '14px')}
`

export interface PageMediaListProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaList({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaListProps): JSX.Element {

  const theme = useTheme()

  const contents = pageSection?.contents || []

  const secondaryLink = pageSection?.links?.secondary

  let content: string
  let contentIndex: number

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  return (
    <div ref={pageRef}>
      <MobileContainer>
        <Choose>
          <When condition={pageSection.variation === PageSectionMediaVariationEnum.RIGHT_LIST}>
            <MobileLeftContainer>
              <MobileLeftImageContainer $shadow={pageSection.imageShadow}>
                <ResponsiveImage image={pageSection.image} objectFit={pageSection?.mobileObjectFit} />
              </MobileLeftImageContainer>
              <MobileContentContainer>
                <Heading variant='h4' align='center' color={theme.colors.slates.bitter}>
                  {pageSection.title}
                </Heading>
                <Heading variant='h2' align='center'>
                  {pageSection.subTitle}
                </Heading>
                <ListContainer>
                  <For each='content' of={contents} index='contentIndex'>
                    <IconText icon={IconEnum.CHECKMARK} stacked text={content} textAlign='center' key={contentIndex} />
                  </For>
                </ListContainer>
                <If condition={!!secondaryLink?.link}>
                  <MobileButtonsContainer>
                    <Link color={secondaryLink?.color} href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                  </MobileButtonsContainer>
                </If>
              </MobileContentContainer>
            </MobileLeftContainer>
          </When>
          <Otherwise>
            <MobileRightContainer>
              <MobileRightImageContainer $shadow={pageSection.imageShadow}>
                <ResponsiveImage image={pageSection.image} objectFit={pageSection?.mobileObjectFit} />
              </MobileRightImageContainer>
              <MobileContentContainer>
                <Heading variant='h4' align='center' color={theme.colors.slates.bitter}>
                  {pageSection.title}
                </Heading>
                <Heading variant='h2' align='center'>
                  {pageSection.subTitle}
                </Heading>
                <ListContainer>
                  <For each='content' of={contents} index='contentIndex'>
                    <IconText icon={IconEnum.CHECKMARK} stacked text={content} textAlign='center' key={contentIndex} />
                  </For>
                </ListContainer>
                <If condition={!!secondaryLink?.link}>
                  <MobileButtonsContainer>
                    <Link color={secondaryLink?.color} href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                  </MobileButtonsContainer>
                </If>
              </MobileContentContainer>
            </MobileRightContainer>
          </Otherwise>
        </Choose>
      </MobileContainer>
      <DesktopContainer>
        <Choose>
          <When condition={pageSection.variation === PageSectionMediaVariationEnum.RIGHT_LIST}>
            <DesktopLeftContainer>
              <DesktopLeftImageContainer $shadow={pageSection.imageShadow}>
                <ResponsiveImage image={pageSection.image} objectFit={pageSection?.objectFit} />
              </DesktopLeftImageContainer>
              <DesktopContentContainer>
                <Heading variant='h4' color={theme.colors.slates.bitter}>
                  {pageSection.title}
                </Heading>
                <Heading variant='h2'>
                  {pageSection.subTitle}
                </Heading>
                <ListContainer>
                  <For each='content' of={contents} index='contentIndex'>
                    <IconText icon={IconEnum.CHECKMARK} text={content} key={contentIndex} />
                  </For>
                </ListContainer>
                <If condition={!!secondaryLink?.link}>
                  <DesktopButtonsContainer>
                    <Link color={secondaryLink?.color} href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                  </DesktopButtonsContainer>
                </If>
              </DesktopContentContainer>
            </DesktopLeftContainer>
          </When>
          <Otherwise>
            <DesktopRightContainer>
              <DesktopContentContainer>
                <Heading variant='h4' color={theme.colors.slates.bitter}>
                  {pageSection.title}
                </Heading>
                <Heading variant='h2'>
                  {pageSection.subTitle}
                </Heading>
                <ListContainer>
                  <For each='content' of={contents} index='contentIndex'>
                    <IconText icon={IconEnum.CHECKMARK} text={content} key={contentIndex} />
                  </For>
                </ListContainer>
                <If condition={!!secondaryLink?.link}>
                  <DesktopButtonsContainer>
                    <Link color={secondaryLink?.color} href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
                  </DesktopButtonsContainer>
                </If>
              </DesktopContentContainer>
              <DesktopRightImageContainer $shadow={pageSection.imageShadow}>
                <ResponsiveImage image={pageSection.image} objectFit={pageSection?.objectFit} />
              </DesktopRightImageContainer>
            </DesktopRightContainer>
          </Otherwise>
        </Choose>
      </DesktopContainer>
    </div>
  )

}
