import React, { useEffect, Fragment, useState } from 'react'

import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ResponsivePXValue, Button, Link, TextButtonColors } from '@client/components'
import { Heading, IconEnum, Paragraph } from '@client/components/atoms'
import { ContentSeperator } from '@client/components/atoms/layout/ContentSeparator'
import { ContentCard, ContentCardHeaderEnum } from '@client/components/molecules'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { LoyaltyTierFragment, RegisteredUserDetailsFragment, UserDetailsFragment, UserDetailsQuery } from '@hooks/api'
import { UserStatusEnum } from '@uctypes/api/globalTypes'

import { SendReferenceForm } from '../forms/SendReferenceForm'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  ${ResponsivePXValue('width', 'CALC(100% - 48px)')}
  ${ResponsivePXValue('padding', '0 24px')}

  .text {
    margin: 0px
  }

`

const ShareButtonsContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'center', desktop: 'space-evenly' })}
  ${ResponsivePXValue('padding', '12px 0')}

  .share-button {
    ${ResponsivePXValue('margin', '0 0 16px')}
    ${ResponsivePXValue('padding', '0 30px')}
  }

  .facebook-button {
    background-color: ${(props): string => props.theme.colors.misc.facebookBlue};
    border-color: ${(props): string => props.theme.colors.misc.facebookBlue};
  }

  .whatsapp-button {
    background-color: ${(props): string => props.theme.colors.misc.whatsAppGreen};
    border-color: ${(props): string => props.theme.colors.misc.whatsAppGreen};
  }

  .twitter-button {
    background-color: ${(props): string => props.theme.colors.misc.twitterBlue};
    border-color: ${(props): string => props.theme.colors.misc.twitterBlue};
  }

  .link-button {
    background-color: ${(props): string => props.theme.colors.greys.tundora};
    border-color: ${(props): string => props.theme.colors.greys.tundora};
  }

  .whatsapp-button:hover, .facebook-button:hover, .twitter-button:hover, .link-button:hover {
    background-color: ${(props): string => props.theme.colors.greys.boulder};
    border-color: ${(props): string => props.theme.colors.greys.boulder};
  }

`

const TermsAndConsContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', 'row')}
  ${ResponsivePXValue('justify-content', 'center')}
  ${ResponsivePXValue('align-content', 'flex-start')}
  ${ResponsivePXValue('width', '100%')}

  .text-button {
    line-height: 2;
    font-family: 'gordita';
    font-weight: 400;
    margin: 0px;
    
    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('padding', { mobile: '0px', tablet: '0px 30px', desktop: '0px 30px' })}
  }
  .text-button-sep {
    ${ResponsivePXValue('margin', '12px 0')}
  }

`

interface SendReferenceState {
  referralLink: string
  currentLoyaltyTier: LoyaltyTierFragment
}

const DEFAULT_STATE: SendReferenceState = {
  referralLink: '',
  currentLoyaltyTier: null,
}

export interface SendReferenceProps {
  userDetailsData: UserDetailsQuery
  onSendReferral: (email: string, name?: string) => void
}

export function SendReference({ userDetailsData, onSendReferral }: SendReferenceProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<SendReferenceState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const textButtonColors: TextButtonColors = {
    textColor: theme.colors.whites.silver,
    textHoverColor: theme.colors.greys.liteCodGrey,
  }

  const _handleReferFriend = async (email: string, name?: string): Promise<void> => {
    onSendReferral(email, name)
  }

  const _handleCopyLink = async (): Promise<void> => {
    const el = document.createElement('textarea')
    el.value = state.referralLink
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    addToast('Copy link clicked', {
      appearance: 'success',
      autoDismiss: true,
    })

  }

  const _handleTermsAndConsClick = async (): Promise<void> => {
    navigate('/terms')
  }

  const _handlePolicyClick = async (): Promise<void> => {
    navigate('/referral-policy')
  }

  useEffect(() => {

    if (typeof FB !== 'undefined') {
      FB.init({
        appId: config.getFacebook().appId,
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      })
    }
  }, [])

  useEffect(() => {
    if (userDetailsData?.currentUser) {
      if (userDetailsData.currentUser.status === UserStatusEnum.GUEST) {
        navigate('/')
      } else {
        const registeredUser = userDetailsData.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
        setState((prevState) => ({ ...prevState, referralLink: registeredUser.referralLink, currentLoyaltyTier: registeredUser?.loyaltyTier }))
      }
    }
  }, [userDetailsData])

  const headerText = 'Refer your friends, save on your Meal Kit orders'
  const descriptionText = `Feeling generous? We are. Invite your friends to get cooking with Meal Kits – you’ll get up to R300 off your weekly orders and they’ll get 30% off their first order. When it comes to cooking, you’re already an ${state.currentLoyaltyTier?.name}, which means you’ll get R${state.currentLoyaltyTier?.referrerPoints} off every time a friend orders their first Meal Kit.`
  const shareHeaderText = 'Get sharing, get saving'
  const shareDescriptionText = 'Spread the love by instantly sharing your unique referral code with your mates. Go on – who doesn’t want a good deal on great food?'

  return (
    <Fragment>
      <ContentCard headerType={ContentCardHeaderEnum.IMAGE} image='REFER-A-FRIEND'>
        <MainContainer>
          <Heading variant='h5' align='center'>{headerText}</Heading>
          <Paragraph className='text' variant='p1' align='center'>{descriptionText}</Paragraph>
          <SendReferenceForm onSendReference={_handleReferFriend} />
          <ContentSeperator margin='0' />
          <Heading variant='h5' align='center'>{shareHeaderText}</Heading>
          <Paragraph className='text' variant='p1' align='center'>{shareDescriptionText}</Paragraph>
          <ShareButtonsContainer>
            <Button
              className='share-button facebook-button'
              title='Facebook'
              icon={IconEnum.LOGO_FACEBOOK}
              // blank={true}
              href={`https://www.facebook.com/sharer/sharer.php?u=${state.referralLink}&quote=Get+cooking+with+me+and+save+30%+on+your+first+Meal+Kit+with+my+referral+invitation`} />
            <Button
              className='share-button whatsapp-button'
              title='Whatsapp'
              icon={IconEnum.LOGO_WHATSAPP}
              // blank={true}
              href={`https://wa.me/?text=${state.referralLink}`} />
            <Button
              className='share-button twitter-button'
              title='Twitter'
              icon={IconEnum.LOGO_TWITTER}
              // blank={true}
              href={`https://twitter.com/intent/tweet?text=Get%20cooking%20with%20me%20and%20save%2030%25%20on%20your%20first%20Meal%20Kit%20with%20my%20referral%20invitation:%20${state.referralLink}`} />
            <Button
              className='share-button link-button'
              title='Copy Link'
              icon={IconEnum.LINK_OUTLINE}
              onClick={_handleCopyLink} />
          </ShareButtonsContainer>
          <TermsAndConsContainer>
            <Link
              className='text-button'
              color={theme.colors.whites.silver}
              onClick={_handleTermsAndConsClick}> Terms and conditions </Link>
            <Paragraph
              className='text-button-sep'
              color='grey'>
              {'|'}
            </Paragraph>
            <Link
              className='text-button'
              color={theme.colors.whites.silver}
              onClick={_handlePolicyClick}> Referral Policy </Link>
          </TermsAndConsContainer>
        </MainContainer>
      </ContentCard>
    </Fragment>
  )

}
