import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage, Paragraph } from '@atoms/index'
import { Ellipsis, ResponsivePXValue } from '@components/Theme'
import { MealKitDishListFragment } from '@hooks/api'

const CardsContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

const ImageContainer = styled.div<{ $noRedirect: boolean }>`
  cursor:  ${(props): string => props.$noRedirect ? 'default' : 'pointer'};

  ${ResponsivePXValue('height', { desktop: '182px' })}

`
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props): string => props.theme.colors.whites.pureWhite};

  ${ResponsivePXValue('height', { desktop: '42px' })}
  ${ResponsivePXValue('padding', '12px')}

  .dish-name {
    letter-spacing: 1px;

    ${Ellipsis()}
    
    color: ${(props): string => props.theme.colors.greys.mineshaft};
  }
`

export interface MealKitMenuCardProps {
  mealKit: MealKitDishListFragment
  className?: string
  noRedirect?: boolean
}

export const MealKitMenuCard = React.memo(({ mealKit, className, noRedirect = false }: MealKitMenuCardProps): JSX.Element => {

  const navigate = useNavigate()

  const _handleShowDetails = (): void => {
    if (noRedirect) {
      return
    }
    navigate(`/meal-kit/${mealKit?.slug}`)
  }

  return (
    <CardsContainer className={className} onClick={_handleShowDetails}>
      <ImageContainer className='card-image' $noRedirect={noRedirect}>
        <ResponsiveImage image={mealKit.coverImage} lazy={false} />
      </ImageContainer>
      <TextContainer>
        <Paragraph bold variant='p3' className='dish-name'> {mealKit.name}</Paragraph>
      </TextContainer>
    </CardsContainer>
  )
})

MealKitMenuCard.displayName = 'MealKitMenuCard'
