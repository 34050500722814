import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Icon, IconEnum } from '@atoms/index'
import { SmallLoader } from '@atoms/notifications'
import { DisabledEffect, HoverEffect, ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

export type ButtonColor = 'orange' | 'black' | 'tundora' | 'grey' | 'green' | 'red' | 'white' | 'transparent'
export const buttonColors = { Orange: 'orange', Black: 'black', Tundora: 'tundora', Grey: 'grey', Green: 'green', Red: 'red', Blue: 'blue', White: 'white', Transparent: 'transparent' }
export type ButtonVariant = 'primary' | 'secondary'
export const buttonVariants = { Primary: 'primary', Secondary: 'secondary' }
export type ButtonSize = 'small' | 'large'
export const buttonSizes = { Small: 'small', Large: 'large' }

export interface ButtonColors {
  color?: string
  backgroundColor?: string
  borderColor?: string
  hoverColor?: string
  hoverBackgroundColor?: string
  hoverBorderColor?: string
  disabledColor?: string
  disabledBackgroundColor?: string
  disabledBorderColor?: string
}

const getSize = (size: ButtonSize, fullWidth: boolean, hasTitle: boolean, fluid: boolean): string => {
  if (!hasTitle) {
    if (size === 'large') {
      return `
        ${ResponsivePXValue('height', 'fit-content')}
        ${ResponsivePXValue('border-width', '2px')}
      `
    }
    return `
      ${ResponsivePXValue('height', 'fit-content')}
      ${ResponsivePXValue('border-width', '1px')}
    `
  }
  if (fullWidth) {
    if (size === 'large') {
      return `
        width: 100%;
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '40px' })}
        ${ResponsivePXValue('border-width', '2px')}
      `
    }
    return `
      width: 100%;
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('border-width', '1px')}
    `
  }
  if (size === 'large') {
    return `
      width: ${fluid ? 'auto' : 'fit-content'};
      ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '40px' })}
      ${ResponsivePXValue('border-width', '2px')}
    `
  }

  return `
    width: ${fluid ? 'auto' : 'fit-content'};
    ${ResponsivePXValue('height', '24px')}
    ${ResponsivePXValue('border-width', '1px')}
    `
}

const getIconSize = (size: ButtonSize, hasTitle?: boolean, isMiniCardButton?: boolean, plusMinusButton?: boolean): string => {
  if (hasTitle && isMiniCardButton) {
    return `
      ${ResponsivePXValue('width', '12px')}
      ${ResponsivePXValue('height', '12px')}
    `
  } else if (hasTitle) {
    return `
    ${ResponsivePXValue('width', '12px')}
    ${ResponsivePXValue('height', '12px')}
  `
  }

  if (!hasTitle && isMiniCardButton && !plusMinusButton) {
    return `
    ${ResponsivePXValue('padding', '5px 8px')}
    ${ResponsivePXValue('width', '12px')}
    ${ResponsivePXValue('height', '12px')}
    `
  }

  if (plusMinusButton) {
    return `
    ${ResponsivePXValue('height', { mobile: '12px', tablet: '16px', desktop: '16px' })}
    ${ResponsivePXValue('width', { mobile: '12px', tablet: '16px', desktop: '16px' })}
    font-weight: bold;
    `

  }
  return `
    ${ResponsivePXValue('width', '24px')}
    ${ResponsivePXValue('height', '24px')}
    `
}

const getBorderRadius = (size: ButtonSize, flat: boolean): string => {
  if (flat) {
    return 'border-radius: 0;'
  }
  if (size === 'large') {
    return 'border-radius: 4px;'
  }
  return 'border-radius: 4px;'
}

const getTitleLayout = (isMiniCardButton: boolean): string => {
  const commonStyles = `
    text-align: center;
    vertical-align: sub;
    letter-spacing: 1px;
  `

  if (isMiniCardButton) {
    return `${commonStyles}
    padding-right: 2px;
    `
  }

  return commonStyles
}
const getTextPadding = (size: ButtonSize): string => {
  if (size === 'large') {
    return ResponsivePXValue('padding', { mobile: '0 12px', tablet: '0 12px', desktop: '0 16px' })
  }
  return ResponsivePXValue('padding', '0 8px')
}

const getColors = (colors: ButtonColors, variant: ButtonVariant, disabled: boolean): string => {

  if (variant === buttonVariants.Secondary) {
    return `
      color: ${colors.color};
      background-color: ${colors.backgroundColor};
      border-color: ${colors.borderColor};

      ${disabled ? DisabledEffect : null}

      &:hover {
        color: ${colors.hoverColor};
        background-color: ${colors.hoverBackgroundColor};
        border-color: ${colors.hoverBorderColor};

        ${disabled ? DisabledEffect : null}
      }
    `
  }

  return `
    color: ${colors.color};
    background-color: ${colors.backgroundColor};
    border-color: ${colors.borderColor};

    ${disabled ? DisabledEffect : null}

    &:hover {
      ${HoverEffect}

      ${disabled ? DisabledEffect : null}
    }


  `
}

const getIconColors = (colors: ButtonColors, variant: ButtonVariant): string => {

  if (variant === buttonVariants.Secondary) {
    return `
      .stroke {
        stroke: ${colors.color};
        fill: none;
      }
      .fill {
        fill: ${colors.color};
        stroke: none;
      }
      .fill-stroke {
        fill: ${colors.color};
        stroke: ${colors.color};
      }

      &:hover {
        .stroke {
          stroke: ${colors.hoverColor};
          fill: none;
        }
        .fill {
          fill: ${colors.hoverColor};
          stroke: none;
        }
        .fill-stroke {
          fill: ${colors.hoverColor};
          stroke: ${colors.hoverColor};
        }
      }
    `
  }
  return `
    .stroke {
      stroke: ${colors.color};
      fill: none;
    }
    .fill {
      fill: ${colors.color};
      stroke: none;
    }
    .fill-stroke {
      fill: ${colors.color};
      stroke: ${colors.color};
    }
 `
}

const getFont = (isMiniCardButton: boolean): string => {
  if (isMiniCardButton) {
    return `
    font-family: gordita;
    font-weight: 500;
    ${ResponsivePXValue('font-size', '8px')}
    ${ResponsivePXValue('line-height', '12px')}

  `
  }
  return `
    font-family: gordita;
    font-weight: 900;
    ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '12px' })}
    ${ResponsivePXValue('line-height', { mobile: '14px', tablet: '14px', desktop: '16px' })}
  `
}

const ButtonDivElement = styled.div<{ $colors: ButtonColors, $variant: ButtonVariant, $size: ButtonSize, disabled: boolean, $flat: boolean, $fluid: boolean, $displayBorder: boolean, $fullWidth: boolean, $hasTitle: boolean, $isMiniCardButton: boolean, $fontWeight: number }>`

  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  cursor: pointer;
  box-sizing: border-box;

  ${ResponsivePXValue('border-width', '1px')}

  ${(props): string => getSize(props.$size, props.$fullWidth, props.$hasTitle, props.$fluid)}
  ${(props): string => getFont(props.$isMiniCardButton)}
  ${(props): string => getBorderRadius(props.$size, props.$flat)}
  ${(props): string => getColors(props.$colors, props.$variant, props.disabled)}
  ${(props): string => getIconColors(props.$colors, props.$variant)}

  ${(props): string => props.$displayBorder ? '' : 'border: none;'}
  ${(props): string => props.$fontWeight ? `font-weight: ${props.$fontWeight};` : ''}

`

const ButtonAnchorElement = styled.a<{ $colors: ButtonColors, $variant: ButtonVariant, $size: ButtonSize, disabled: boolean, $flat: boolean, $fluid: boolean, $displayBorder: boolean, $fullWidth: boolean, $hasTitle: boolean, $isMiniCardButton: boolean, $fontWeight: number }>`

  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  text-decoration: none;
  box-sizing: border-box;
  
  ${(props): string => getSize(props.$size, props.$fullWidth, props.$hasTitle, props.$fluid)}
  ${(props): string => getFont(props.$isMiniCardButton)}
  ${(props): string => getBorderRadius(props.$size, props.$flat)}
  ${(props): string => getColors(props.$colors, props.$variant, props.disabled)}
  ${(props): string => getIconColors(props.$colors, props.$variant)}

  ${(props): string => props.$displayBorder ? '' : 'border: none;'}
  ${(props): string => props.$fontWeight ? `font-weight: ${props.$fontWeight};` : ''}
`

const CenterContainer = styled.div<{ $size: ButtonSize, $hasTitle: boolean, $titleOffsetLeft: boolean, $compact: boolean }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props): string => props.$hasTitle ? getTextPadding(props.$size) : '0'}
  ${(props): string => props.$titleOffsetLeft ? (props.$compact ? ResponsivePXValue('margin-left', { mobile: '16px', tablet: '16px', desktop: '16px' }) : ResponsivePXValue('margin-left', { mobile: '23px', tablet: '27px', desktop: '27px' })): null}

`

const TitleContainer = styled.div`
  position: relative;
`

const TitleSpan = styled.span<{ $isLoading: boolean, $isMiniCardButton: boolean }>`
  
  opacity: ${(props): number => props.$isLoading ? 0 : 1};
  
  ${(props): string => getTitleLayout(props.$isMiniCardButton)}




`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

`

const IconContainer = styled.div<{ $size: ButtonSize, $hasTitle: boolean, $isMiniCardButton?: boolean }>`
  ${(props): string => (props.$hasTitle && !props.$isMiniCardButton) ? ResponsivePXValue('margin-left', '10px') : ''}
  ${(props): string => getIconSize(props.$size, props.$hasTitle, props.$isMiniCardButton)}
`

const LeftIconContainer = styled.div<{ $size: ButtonSize, $colors: ButtonColors, disabled: boolean, $isMiniCardButton?: boolean }>`
  flex-shrink: 0;
  ${ResponsivePXValue('margin-left', '11px')}
  ${(props): string => getIconSize(props.$size, false, props.$isMiniCardButton, true)}
`

const RightIconContainer = styled.div<{ $size: ButtonSize, $colors: ButtonColors, disabled: boolean, $isMiniCardButton?: boolean }>`
  flex-shrink: 0;
  ${ResponsivePXValue('margin-right', '11px')}
  ${(props): string => getIconSize(props.$size, false, props.$isMiniCardButton, true)}
`

export function getButtonColors(variant: ButtonVariant, color: ButtonColor, iconColor?: string): ButtonColors {

  if (variant === buttonVariants.Primary) {
    switch (color) {
      case buttonColors.Orange: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.oranges.burntSienna,
          borderColor: theme.colors.oranges.burntSienna,
        }
      }
      case buttonColors.Black: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.greys.mineshaft,
          borderColor: theme.colors.greys.mineshaft,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Tundora: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.greys.tundora,
          borderColor: theme.colors.greys.tundora,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Grey: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.slates.bitter,
          borderColor: theme.colors.slates.bitter,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Green: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.greens.fruitSalad,
          borderColor: theme.colors.greens.fruitSalad,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Red: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.reds.cautionRed,
          borderColor: theme.colors.reds.cautionRed,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Blue: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.blues.danube,
          borderColor: theme.colors.blues.danube,
          disabledColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.White: {
        return {
          color: theme.colors.greys.tundora,
          backgroundColor: theme.colors.whites.pureWhite,
          borderColor: theme.colors.misc.transparent,
        }
      }
      case buttonColors.Transparent: {
        return {
          color: iconColor,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.misc.transparent,
        }
      }
    }
  } else if (variant === buttonVariants.Secondary) {
    switch (color) {
      case buttonColors.Orange: {
        return {
          color: theme.colors.oranges.burntSienna,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.oranges.burntSienna,
          hoverBackgroundColor: theme.colors.oranges.burntSienna,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Black: {
        return {
          color: theme.colors.greys.mineshaft,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.greys.mineshaft,
          hoverBackgroundColor: theme.colors.greys.mineshaft,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Tundora: {
        return {
          color: theme.colors.greys.tundora,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.greys.tundora,
          hoverBackgroundColor: theme.colors.greys.tundora,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Grey: {
        return {
          color: theme.colors.slates.bitter,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.slates.bitter,
          hoverBackgroundColor: theme.colors.slates.bitter,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Green: {
        return {
          color: theme.colors.greens.fruitSalad,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.greens.fruitSalad,
          hoverBackgroundColor: theme.colors.greens.fruitSalad,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Red: {
        return {
          color: theme.colors.reds.cautionRed,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.reds.cautionRed,
          hoverBackgroundColor: theme.colors.reds.cautionRed,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.Blue: {
        return {
          color: theme.colors.blues.danube,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.blues.danube,
          hoverBackgroundColor: theme.colors.blues.danube,
          hoverColor: theme.colors.whites.pureWhite,
        }
      }
      case buttonColors.White: {
        return {
          color: theme.colors.whites.pureWhite,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: theme.colors.whites.pureWhite,
          hoverBackgroundColor: theme.colors.whites.pureWhite,
          hoverColor: theme.colors.greys.tundora,
        }
      }
      case buttonColors.Transparent: {
        return {
          color: iconColor,
          backgroundColor: theme.colors.misc.transparent,
          borderColor: iconColor,
          hoverBackgroundColor: theme.colors.misc.transparent,
          hoverColor: iconColor,
        }
      }
    }
  }

}
export interface ButtonProps {
  title?: string
  icon?: IconEnum
  variant?: ButtonVariant
  size?: ButtonSize
  leftIcon?: IconEnum
  rightIcon?: IconEnum
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void
  onLeftIconClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void
  onRightIconClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void
  href?: string
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  flat?: boolean
  fluid?: boolean
  displayBorder?: boolean
  className?: string
  iconColor?: string
  color?: ButtonColor
  isCallNumber?: boolean
  isMiniCardButton?: boolean
  amount?: number
  fontWeight?: number
  isMealKitMini?: boolean
  amountServingSizeSpecific?: number
  compact?: boolean
}

export function Button(props: ButtonProps): JSX.Element {
  const { variant = 'primary', color = 'orange', size = 'large', isCallNumber = false, loading = false, disabled = false, flat = false, fluid = false, displayBorder = true, icon, title, leftIcon, rightIcon, onClick, onLeftIconClick, onRightIconClick, href, fullWidth = false, className, iconColor, isMiniCardButton, amount, fontWeight, isMealKitMini, amountServingSizeSpecific = null, compact = false } = props
  const navigate = useNavigate()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick && !isCallNumber) {
      return onClick(e)
    } else if (!isCallNumber) {
      e.preventDefault()
      navigate(href)
    }
  }

  const _handleDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick && !(disabled || loading)) {
      return onClick(e)
    }
  }

  let buttonText = title
  let colors = getButtonColors(variant, color, iconColor)
  let leftSmartIcon = leftIcon
  let rightSmartIcon = rightIcon
  let smartIcon = icon
  let iconClass: string
  let titleOffsetLeft = false
  if (amountServingSizeSpecific === 0 && amount> 0) {
    buttonText = compact ? `${amount}` : `${amount} IN CART`
    colors = getButtonColors(variant, 'green', iconColor)
    rightSmartIcon = IconEnum.ADD
    // add left side padding = to icon width
    titleOffsetLeft = true
    if (!isDesktop) {
      smartIcon = IconEnum.CART
      buttonText = `${amount} ${isMealKitMini ? '' : 'IN'}`
      iconClass = 'bold-icon'

    }
  } else if (amount > 0) {
    buttonText = compact ? `${amount}` : `${amount} IN CART`
    rightSmartIcon = IconEnum.ADD
    leftSmartIcon = IconEnum.REMOVE
    colors = getButtonColors(variant, 'green', iconColor)
    if (!isDesktop) {
      smartIcon = IconEnum.CART
      buttonText = `${amount} ${isMealKitMini || compact ? '' : 'IN'}`
      iconClass = 'bold-icon'
    }
  }

  const _handleLeftIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onLeftIconClick && !(disabled || loading)) {
      e.preventDefault()
      e.stopPropagation()
      return onLeftIconClick(e)
    }
  }

  const _handleRightIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onRightIconClick && !(disabled || loading)) {
      e.preventDefault()
      e.stopPropagation()
      return onRightIconClick(e)
    }
  }

  const __renderInner = (): JSX.Element => {
    return (
      <>
        <If condition={!!leftSmartIcon}>
          <LeftIconContainer
            $size={size}
            $colors={colors}
            disabled={disabled || loading}
            className='button-left-icon'
            $isMiniCardButton={isMiniCardButton}
            onClick={_handleLeftIconClick}>
            <Icon
              icon={leftSmartIcon}
              className={iconClass}
              color={iconColor || (disabled ? colors.disabledColor : colors.color)}
              hoverColor={disabled ? colors.disabledColor : colors.hoverColor} />
          </LeftIconContainer>
        </If>
        <CenterContainer className='center-container' $size={size} $hasTitle={!!title} $titleOffsetLeft={titleOffsetLeft} $compact={compact}>
          <TitleContainer className='button-title-container'>
            <TitleSpan className='button-title' $isLoading={loading && !smartIcon} $isMiniCardButton={isMiniCardButton}>
              {buttonText}
            </TitleSpan>
            <Choose>
              <When condition={loading && !smartIcon}>
                <LoadingContainer>
                  <IconContainer $size={size} $hasTitle={false} $isMiniCardButton={isMiniCardButton}>
                    <SmallLoader color={colors.disabledColor} />
                  </IconContainer>
                </LoadingContainer>
              </When>
            </Choose>
          </TitleContainer>
          <If condition={!!smartIcon}>
            <IconContainer className='icon-container' $size={size} $hasTitle={!!title} $isMiniCardButton={isMiniCardButton}>
              <Choose>
                <When condition={loading}>
                  <SmallLoader color={colors.disabledColor} />
                </When>
                <Otherwise>
                  <Icon
                    icon={smartIcon}
                    color={color === buttonColors.Transparent ? iconColor : null}
                  />
                </Otherwise>
              </Choose>
            </IconContainer>
          </If>
        </CenterContainer>
        <If condition={!!rightSmartIcon}>
          <RightIconContainer
            $size={size}
            $colors={colors}
            disabled={disabled || loading}
            className='button-right-icon'
            $isMiniCardButton={isMiniCardButton}
            onClick={_handleRightIconClick}>
            <Icon
              icon={rightSmartIcon}
              className={iconClass}
              color={disabled ? colors.disabledColor : colors.color}
              hoverColor={disabled ? colors.disabledColor : colors.hoverColor} />
          </RightIconContainer>
        </If>
      </>
    )
  }

  return (
    <Choose>
      <When condition={!!href}>
        <ButtonAnchorElement
          href={href}
          $colors={colors}
          $variant={variant}
          $size={size}
          disabled={disabled || loading}
          $flat={flat}
          $fluid={fluid}
          $displayBorder={displayBorder}
          $fullWidth={fullWidth}
          $hasTitle={!!title}
          className={className}
          $isMiniCardButton={isMiniCardButton}
          $fontWeight={fontWeight}
          onClick={_handleLinkClick}>
          {__renderInner()}
        </ButtonAnchorElement>
      </When>
      <Otherwise>
        <ButtonDivElement
          $colors={colors}
          $variant={variant}
          $size={size}
          disabled={disabled || loading}
          $flat={flat}
          $fluid={fluid}
          $displayBorder={displayBorder}
          $fullWidth={fullWidth}
          $hasTitle={!!title}
          className={className}
          $isMiniCardButton={isMiniCardButton}
          $fontWeight={fontWeight}
          onClick={_handleDivClick}>
          {__renderInner()}
        </ButtonDivElement>
      </Otherwise>
    </Choose>
  )

}
