import React, { useEffect, useState } from 'react'

import { format } from 'date-fns'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { IconEnum, Spacer, Heading, Button } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PaddedSectionContainer, ResponsivePXValue, ResponsiveProperty, SidePagePadding, theme } from '@components/Theme'
import {
  MealKitDishDetailsFragment, useUserCartQuery, MealKitAllergenFragment, MealKitListFragment, useGetAppQuery,
  UpSellFrozenMealDishFragment, UpSellMarketProductFragment, UpSellWineFragment, useUserDetailsQuery,
} from '@hooks/api'
import { getUpSellProducts, NumberedListItem, UpSellProductCard } from '@molecules/index'
import { MoreAboutDish, MoreAboutDishProps, MealKitDishDetails, MealKitDishDetailsProps, MealKitYouMightAlsoLike } from '@organisms/index'
import { DeviceTypeEnum, NumberOfPortionsEnum, PortionSizeEnum, ProductIngredient } from '@uctypes/api/globalTypes'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const BackButtonContainer = styled.div`
 display: flex;
 ${SidePagePadding}
 ${ResponsivePXValue('margin-bottom', '24px')}

`
const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('margin', { mobile: '24px 0', tablet: '28px 0', desktop: '36px 0' })}
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
`
const SectionContainer = styled.div<{ $backgroundColor?: string, $customBackground?: string, $padded: boolean }>`
  ${(props): CSS => PaddedSectionContainer(props.$backgroundColor, props.$customBackground, props.$padded)};
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top:  24px solid ${(props): string => props.theme.colors.whites.pureWhite};
  border-left: 28px solid transparent;
  border-right: 28px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
`
interface MealKitDetailsState {
  portionSize: NumberOfPortionsEnum
  ingredientsPortioned: ProductIngredient
}

const DEFAULT_STATE: MealKitDetailsState = {
  portionSize: NumberOfPortionsEnum.SERVES_2,
  ingredientsPortioned: null,
}

const UpSellProductContainer = styled.div`
  display: flex;
  flex: 1;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

export interface MealKitPDPProps {
  dish: MealKitDishDetailsFragment
  slug: string
  loading: boolean
}

export function MealKitPDP({ dish, slug, loading }: MealKitPDPProps): JSX.Element {

  const config = useConfig()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const navigate = useNavigate()
  const events = useEvents()
  const sluggedPortion = dish?.products?.find(product => { return product.slug === slug })?.portionSize as unknown as NumberOfPortionsEnum
  const allergens: string[] = []
  const deliveryDate = format(new Date(userCartData?.currentUser?.activeMenu?.deliveryDate || new Date()), 'dd MMM yyyy')
  const whatYouNeedItems: { [k: string]: NumberedListItem[] } = {}
  const [state, setState] = useState<MealKitDetailsState>(DEFAULT_STATE)
  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: NumberOfPortionsEnum.SERVES_2
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  dish?.products?.forEach((product: MealKitListFragment) => {
    const portionIngredients: NumberedListItem[] = []
    product.fromYourKitchen.forEach((kitchenItem: string) => { portionIngredients.push({ title: kitchenItem }) })
    whatYouNeedItems[state.portionSize] = portionIngredients
  })

  const defaultNeedList: NumberedListItem[] = []

  dish?.fromYourKitchen.forEach((kitchen) => { defaultNeedList.push({ title: kitchen }) })

  const whatWeSend: { [k: string]: NumberedListItem[] } = {}

  dish?.products?.forEach((product: MealKitListFragment) => {
    const portionIngredients: NumberedListItem[] = []
    product.ingredients.forEach((ingredient: MealKitListFragment['ingredients'][0]) => { portionIngredients.push({ title: `${ingredient.displayUnit} ${ingredient.displayTitle}`, description: ingredient.action }) })
    whatWeSend[state.portionSize] = portionIngredients
  })

  const defaultSendList: NumberedListItem[] = []

  dish?.sentIngredients.forEach((ingredient) => { defaultSendList.push({ title: ingredient }) })

  dish?.allergens?.forEach((allergen: MealKitAllergenFragment) => { allergens.push(allergen.title) })

  const _handlePortionSelected = (portionSize: NumberOfPortionsEnum) => {
    const product = dish?.products.find(product => { return product.portionSize === portionSize as unknown as PortionSizeEnum })
    const ingredientsForPortions = product?.ingredients

    setState((prevState) => update(prevState, {
      portionSize: { $set: portionSize },
      ingredientsForPortions: { $set: ingredientsForPortions },
    }))
  }
  const dishDetailsData: MealKitDishDetailsProps = {
    dish,
    allergens,
    deliveryDate,
    sluggedPortion,
  }

  const moreAboutDishData: MoreAboutDishProps = {
    chef: {
      name: dish?.chef?.name,
      about: dish?.chef?.biography,
      image: dish?.chef?.profileImage,
    },
    nutritionalInfo: {
      nutrition: dish?.nutrition,
      nutritionPerServing: dish?.nutritionPerServing,
      allergens: allergens?.join(', '),
    },
    whatWeSend: {
      defaultList: defaultSendList,
      items: whatWeSend[state.portionSize],
    },
    whatYouNeed: {
      defaultList: defaultNeedList,
      items: whatYouNeedItems[state.portionSize],
    },
  }

  const _handleBackClicked = (): void => {
    navigate(-1)
  }

  // Analytics
  useEffect(() => {
    if (!loading) {

      const logData = {
        itemName: dish?.name,
        itemId: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.id,
        itemGroupId: dish?.id,
        price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.mealKitCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Meal Kit',
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        isMealkit: 'yes',
        itemChef: dish?.chef?.name,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasViewedProduct('Meal Kit', snakedData, userCartData?.currentUser?.id)
      events.hasSelectedItem('Meal Kit', snakedData, userCartData?.currentUser?.id)
    }

  }, [loading])

  useEffect(() => {
    const defaultPortionSize = defaultPortions as unknown as PortionSizeEnum || PortionSizeEnum.SERVES_2
    const product = dish?.products.find(product => { return product.portionSize === defaultPortionSize })
    const ingredientsForPortions = product?.ingredients

    setState((prevState) => update(prevState, {
      portionSize: { $set: ingredientsForPortions },
      ingredientsForPortions: { $set: ingredientsForPortions },
    }))

  }, [dish, userDetailsData])

  const upSellProducts = getUpSellProducts(dish)
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  let upSellProduct: UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment

  return (
    <>
      <SectionContainer $padded={false}>
        <BackButtonContainer>
          <Button
            title='BACK'
            variant='secondary'
            displayBorder={false}
            color='grey'
            leftIcon={IconEnum.CHEVRON_BACK_OUTLINE}
            iconColor={theme.colors.oranges.coral}
            loading={loading}
            onClick={_handleBackClicked} />
        </BackButtonContainer>
        <MealKitDishDetails onNumberOfPortionsSelect={_handlePortionSelected} {...dishDetailsData} />
      </SectionContainer>
      <If condition={!!dish && upSellProducts.length > 0}>
        <SectionContainer $customBackground={theme.colors.greens.greenSmoke} $padded>
          <Triangle></Triangle>
          <Heading variant={isMobile ? 'h4' : 'h3'} color={theme.colors.whites.pureWhite} align='center' className='up-sell-title'>ADD EXTRAS:</Heading>
          <Spacer universal='32px' />
          <UpSellProductContainer>
            <For each='upSellProduct' of={upSellProducts.slice(0, 3)}>
              <UpSellProductCard meal={upSellProduct} isCard={false} key={upSellProduct.id} />
              <Spacer variant='horizontal' desktop='16px' />
              <Spacer mobile='8px' />
            </For>
          </UpSellProductContainer>
        </SectionContainer>
      </If>
      <SectionContainer $padded>
        <MoreAboutDish {...moreAboutDishData} />
      </SectionContainer>
      <SectionContainer $padded>
        <MealKitYouMightAlsoLike dish={dish} />
        <GhostButtonContainer >
          <Button variant='secondary' title='BACK TO THE MENU' color='grey' onClick={_handleBackClicked} />
        </GhostButtonContainer>
      </SectionContainer>
    </>
  )
}
