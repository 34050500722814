import React, { } from 'react'

import styled, { useTheme } from 'styled-components'

import { StaticImage, Heading, Seperator, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

interface AsSeen {
  imageId: string
  url: string
}

const asSeenUrls: AsSeen[] = [{
  imageId: 'SAWUBONA',
  url: 'https://saasawubona.com/the-meal-kit-revolution/',
}, {
  imageId: 'IOL',
  url: 'https://www.iol.co.za/lifestyle/food-drink/recreate-david-higgs-dishes-at-home-with-ucook-35785454',
}, {
  imageId: 'A4W',
  url: 'https://www.all4women.co.za/1711071/food-home/food-drink/i-tried-ucook-for-a-week-this-was-my-experience',
}, {
  imageId: 'TIMES_LIVE',
  url: 'https://select.timeslive.co.za/lifestyle/2019-05-15-stuck-for-ideas-for-dinner-if-youve-got-a-phone-no-probs/',
}, {
  imageId: 'SUNDAY_TIMES',
  url: 'https://www.timeslive.co.za/sunday-times/lifestyle/food/2020-03-01-chef-lapo-magnis-ostrich-tagliata-is-made-easy--fun-thanks-to-ucook/',
}]

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};

  ${ResponsivePXValue('padding-top', '44px')}

`

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${ResponsivePXValue('padding', { mobile: '20px 0 28px', tablet: '30px 38px 46px', desktop: '0 140px 44px' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: 'CALC(100% - 76px)', desktop: 'CALC(100% - 280px)' })}

`

const ImageContainer = styled.a`

  align-self: stretch;

  ${ResponsivePXValue('padding', { mobile: '10px 26px 18px', tablet: '14px 44px', desktop: '14px 58px' })}
  ${ResponsivePXValue('width', { mobile: 'CALC(40% - 51px)', tablet: 'CALC(30% - 92px)', desktop: 'CALC(20% - 116px)' })}

`

export function AsSeenOn(): JSX.Element {

  const theme = useTheme()

  let asSeen: AsSeen

  return (
    <Container>
      <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>As seen in</Heading>
      <Spacer universal='8px' />
      <Seperator align='center' />
      <ImagesContainer>
        <For each='asSeen' of={asSeenUrls} index='index'>
          <ImageContainer key={asSeen.imageId} href={asSeen.url} target='_blank'>
            <StaticImage
              objectFit='contain'
              mobileWidth={768}
              tabletWidth={1024}
              desktopWidth={1600}
              staticId={asSeen.imageId} />
          </ImageContainer>
        </For>
      </ImagesContainer>
    </Container>
  )

}
