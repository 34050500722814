import React, { useState, useEffect } from 'react'

// eslint-disable-next-line
import ReactDOM from 'react-dom'
import styled, { CSS, css, useTheme } from 'styled-components'
import ModalComponent from 'styled-react-modal'

import { APP_DEFAULT_STATE } from '@api/local'
import { IconEnum, StaticImage, Button, Heading, Spacer, HeadingAlignment, Rule, Paragraph } from '@atoms/index'
import { usePortal } from '@client/hooks/UsePortal'
import { StrongBoxShadow, ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { ObjectFitEnum, DeviceTypeEnum } from '@uctypes/api/globalTypes'

export type ModalVariant = 'text' | 'multimedia'

export enum ModalPositionEnum {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  CENTER_TOP = 'CENTER_TOP',
  CENTER_CENTER = 'CENTER_CENTER',
  CENTER_BOTTOM = 'CENTER_BOTTOM',
}

const TRANSITION_DURATION = 500

const StyledModal = ModalComponent.styled`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TopLeft = css`
  position: fixed;
  top: 5vw;
  left: 10vw;
`

const TopRight = css`
  position: fixed;
  top: 5vw;
  right: 10vw;
`

const BottomLeft = css`
  position: fixed;
  bottom: 5vw;
  left: 10vw;
`

const BottomRight = css`
  position: fixed;
  bottom: 5vw;
  right: 10vw;
`

const CenterTop = css`
  position: fixed;
  top: 5vw;
`

const CenterCenter = css`
  position: fixed;
`

const CenterBottom = css`
  position: fixed;
  bottom: 5vw;
`

const ModalContainer = styled.div < { $opacity: number, $isMultimediaVariant: boolean, $position?: ModalPositionEnum, $fullScreen?: boolean, $isNativeApplication?: boolean, $modalOverflow?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;

  overflow: hidden;
  opacity: ${(props): number => props.$opacity};
  transform: translateY(${(props): string => props.$opacity ? '0' : '100%'});
  transition: all ${TRANSITION_DURATION}ms ease;

  ${ResponsivePXValue('height', { mobile: '100vh' })}

  ${(props): CSS => props.$fullScreen ? ResponsivePXValue('justify-content', { mobile: 'flex-start', desktop: 'center' }) : ResponsivePXValue('justify-content', { mobile: 'end', desktop: 'center' })};
  ${(props): CSS => props.$fullScreen ? ResponsivePXValue('padding-top', { mobile: '0' }) : ResponsivePXValue('padding-top', { mobile: '16px' })};
  ${(props): CSS => props.$fullScreen ? ResponsivePXValue('overflow', { mobile: 'auto', desktop: 'hidden' }) : undefined};
  
  ${(props): CSS => props.$isMultimediaVariant ? ResponsivePXValue('max-width', { mobile: '100%' }) : undefined};
  ${(props): CSS => props.$isMultimediaVariant ? ResponsivePXValue('position', { mobile: 'absolute' }) : undefined};
  ${(props): CSS => props.$isMultimediaVariant ? ResponsivePXValue('top', { mobile: '0' }) : undefined};

  ${(props): CSS => {
    if (props?.$modalOverflow) {
      return `
        overflow: ${props.$modalOverflow} !important;
      `
    }
  }}
  
  ${(props): CSS => {
    if (props?.$isNativeApplication) {
      return `
        max-height: CALC(100vh - 58px);
        position: fixed;
        ${ResponsivePXValue('bottom', '56px')}
      `
    }
  }}

  ${StrongBoxShadow}

  ${ResponsivePXValue('border-radius', { desktop: '8px' })}
  
  ${(props): CSS => {
    if (props?.$position) {
      switch (props.$position) {
        case ModalPositionEnum.TOP_LEFT:
          return TopLeft
        case ModalPositionEnum.TOP_RIGHT:
          return TopRight
        case ModalPositionEnum.BOTTOM_LEFT:
          return BottomLeft
        case ModalPositionEnum.BOTTOM_RIGHT:
          return BottomRight
        case ModalPositionEnum.CENTER_TOP:
          return CenterTop
        case ModalPositionEnum.CENTER_CENTER:
          return CenterCenter
        case ModalPositionEnum.CENTER_BOTTOM:
          return CenterBottom
        default:
          return CenterCenter
      }
    }
  }}

`

const OuterContainer = styled.div < { $backdrop: boolean, $hasTitle: boolean, $resolutionAdapter: boolean, $isMultimediaVariant: boolean, $fullScreen: boolean, $isNativeApplication: boolean, $modalOverflow?: string }>`
  position: relative;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  overflow: hidden;
  
  ${(props): CSS => props.$isMultimediaVariant ? ResponsivePXValue('max-width', { desktop: 'min-content' }) : ResponsivePXValue('max-width', { desktop: '1152px' })};
  ${(props): CSS => props.$backdrop ? ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' }) : undefined};
  ${(props): CSS => props.$resolutionAdapter ? ResponsivePXValue('max-height', { desktop: 'CALC(90vh - 52px)' }) : ResponsivePXValue('max-height', { desktop: 'CALC(90vh - 72px)' })};
  ${(props): CSS => props.$isNativeApplication ? 'max-height: inherit' : undefined};

  ${(props): CSS => {
    if (props?.$modalOverflow) {
      return `
        overflow: ${props.$modalOverflow} !important;
      `
    }
    if (!props.$fullScreen) {
      return `
        overflow-y: auto;
        ${ResponsivePXValue('margin-top', { mobile: '-16px' })}
      `
    }
    if (props.$resolutionAdapter) {
      return `
        overflow-y: auto;
      `
    }
  }}
`

const ContentContainer = styled.div<{ $contentOverflow: string, $backdrop: boolean, $hasTitle: boolean, $resolutionAdapter: boolean }>`

  ${(props): CSS => props.$backdrop ? ResponsivePXValue('min-width', { desktop: '576px' }) : undefined};
  
  ${(props): CSS => {
    if (props.$resolutionAdapter) {
      return ResponsivePXValue('overflow-y', { mobile: 'scroll' })
    } else if (props.$contentOverflow) {
      return ResponsivePXValue('overflow-y', { mobile: props.$contentOverflow })
    } else {
      return `
        overflow-y: auto;
      `
    }
  }}

  ${ResponsivePXValue('padding', { mobile: '16px 16px 24px 24px', tablet: '16px 16px 24px 20px', desktop: '16px 24px 24px 24px' })}
  ${ResponsivePXValue('margin-right', '4px')}

  .title-rule {
     width: initial;
     ${ResponsivePXValue('margin-left', { mobile: '-16px -16px -16px -24px', tablet: '-16px -16px -16px -20px', desktop: '-24px' })}
     ${ResponsivePXValue('margin-right', { mobile: '-16px -16px -16px -24px', tablet: '-16px -16px -16px -20px', desktop: '-24px' })}
  }

  ::-webkit-scrollbar {
    ${ResponsivePXValue('width', { mobile: '5px', tablet: '5px', desktop: '5px' })}
    ${ResponsivePXValue('height', { mobile: '8px', tablet: '8px', desktop: '8px' })}
    background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props): string => props.theme.colors.whites.silver};
  }
`

const ScrollContainer = styled.div``

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 150px;

  picture {
    display: flex;
  }

  img {
    min-height: 150px;
  }
`
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingContainer = styled.div<{ $showCloseButton: boolean }>`
  display: flex;
  align-items: center;

  justify-content: ${(props): string => props.$showCloseButton ? 'space-between' : 'center'};

   ${(props): CSS => props.$showCloseButton ? ResponsivePXValue('padding', '0 8px') : undefined};

  .close-button {
    color: ${(props): string => props.theme.colors.whites.silver};
  }
`
export interface ModalProps {
  open: boolean
  title?: string
  subTitle?: string
  titleAlign?: HeadingAlignment
  staticId?: string
  variant?: ModalVariant
  position?: ModalPositionEnum
  objectFit?: ObjectFitEnum
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  children: string | JSX.Element | JSX.Element[]
  allowClose?: boolean
  showCloseButton?: boolean
  backdrop?: boolean
  allowScroll?: boolean
  allowBackgroundClose?: boolean
  contentOverflow?: string
  modalOverflow?: string
  className?: string
  resolutionAdapter?: boolean
  lowerCaseTitle?: boolean
  fullscreen?: boolean
}

interface ModalState {
  isOpen: boolean
  opacity: number
  isClosing: boolean
  isOpening: boolean
  closeHovering: boolean
}

const DEFAULT_STATE: ModalState = {
  isOpen: false,
  opacity: 0,
  isClosing: false,
  isOpening: false,
  closeHovering: false,
}

export function Modal({
  open, title, subTitle, titleAlign = 'center', variant = 'text', position, staticId, objectFit, onClose,
  children, showCloseButton = false, allowBackgroundClose = true, backdrop = true,
  allowScroll = false, contentOverflow, modalOverflow, resolutionAdapter = true, fullscreen = true,
}: ModalProps): JSX.Element {

  const [state, setState] = useState<ModalState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()
  const isNativeApplication = appData.app.isNativeApplication
  const isMultimediaVariant = variant === 'multimedia'

  const _handleClose = (): void => {
    if (open && onClose) {
      onClose()
    }
  }

  const _handleBackgroundClose = (): void => {
    if (open && onClose && allowBackgroundClose) {
      onClose()
    }
  }

  const modalAfterOpen = (): void => {
    if (state.isOpening) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, opacity: 1, isOpening: false }))
      })
    }
  }

  const modalBeforeClose = (): Promise<void> => {
    if (state.isClosing) {
      return new Promise<void>((resolve): void => {
        setState((prevState) => ({ ...prevState, opacity: 0, isClosing: false }))
        setTimeout(resolve, TRANSITION_DURATION)
      })
    } else {
      return new Promise<void>((resolve): void => {
        setTimeout(resolve, TRANSITION_DURATION)
      })
    }
  }

  useEffect(() => {
    if (open && !state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: true, isOpening: true }))
    } else if (!open && state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: false, isClosing: true }))
    }
  }, [open])

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA

  const background = backdrop && isDesktop ? theme.colors.misc.modalBackground : theme.colors.misc.overlay

  const modalPosition = backdrop ? 'fixed' : 'initial'
  const width = backdrop ? '100vw' : 'initial'
  const height = backdrop ? '100vh' : 'initial'

  const style = isDesktop
    ? { position: modalPosition, width, height, background, opacity: state.opacity, transition: '0.5s all ease-in-out' }
    : { background }

  if (appData.app.deviceType === DeviceTypeEnum.MOBILE) {
    position = undefined
  }

  const modal = (
    <StyledModal
      isOpen={state.isOpen}
      backgroundProps={{ style }}
      // beforeOpen={modalBeforeOpen}
      afterOpen={modalAfterOpen}
      beforeClose={modalBeforeClose}
      // afterClose={modalAfterClose}
      onBackgroundClick={_handleBackgroundClose}
      allowScroll={allowScroll}
      onEscapeKeydown={_handleClose}>
      <ModalContainer
        $opacity={state.opacity}
        $position={position}
        $fullScreen={fullscreen}
        $isMultimediaVariant={isMultimediaVariant}
        $isNativeApplication={isNativeApplication}
        $modalOverflow={modalOverflow}>
        <OuterContainer
          $modalOverflow={modalOverflow}
          $backdrop={backdrop}
          $hasTitle={!!title}
          $resolutionAdapter={resolutionAdapter}
          $isMultimediaVariant={isMultimediaVariant}
          $fullScreen={fullscreen}
          $isNativeApplication={isNativeApplication}>
          <If condition={isMultimediaVariant}>
            <ImageContainer>
              <StaticImage
                staticId={staticId}
                objectFit={objectFit}
                mobileWidth={768}
                tabletWidth={768}
                desktopWidth={768} />
            </ImageContainer>
          </If>
          <ContentContainer
            $contentOverflow={contentOverflow}
            $backdrop={backdrop}
            $hasTitle={!!title}
            $resolutionAdapter={resolutionAdapter}>
            <If condition={!!title}>
              <HeaderContainer>
                <HeadingContainer $showCloseButton={showCloseButton}>
                  <Heading variant='h2' align={titleAlign}>{title}</Heading>
                  <If condition={showCloseButton}>
                    <Button className='close-button' color='transparent' icon={IconEnum.CLOSE_OUTLINE} onClick={_handleClose} />
                  </If>
                </HeadingContainer>
                <Spacer mobile='12px' desktop='16px' />
                <If condition={!!subTitle}>
                  <Paragraph variant='p1' align={titleAlign}>{subTitle}</Paragraph>
                  <Spacer mobile='12px' desktop='16px' />
                </If>
                <Rule className='title-rule' color={theme.colors.greys.platinum} />
                <Spacer universal='24px' />
              </HeaderContainer>
            </If>
            <ScrollContainer>
              {children}
            </ScrollContainer>
          </ContentContainer>
        </OuterContainer>
      </ModalContainer>
    </StyledModal>
  )

  const target = usePortal('root-modal')
  if (target) {
    return ReactDOM.createPortal(modal, target)
  }

  return modal
}
