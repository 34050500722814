import React, { Fragment, useEffect, useState } from 'react'

import valid from 'card-validator'
import { FieldData } from 'rc-field-form/lib/interface'
import styled from 'styled-components'

import { Button, CreditCardDetails, Link, Paragraph, Spacer } from '@client/components'
import { ModalActionContainer, ResponsivePXValue, ResponsiveProperty, theme } from '@client/components/Theme'
import { Form, SelectInput, TextInput, useForm } from '@molecules/index'

const Container = styled.div`
  width: 100%;

  .title {
    ${ResponsivePXValue('margin', '0')}
  }
`
const ActionContainer = styled.div<{ $stickyBottom: boolean }>`
  ${ModalActionContainer(true)};
`

const DateContainer = styled.div`
  display: flex;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

export interface AddCardFormProps {
  onAddCard: (creditCardDetails: CreditCardDetails) => void
  onCancel: () => void
}

interface AddCardState {
  loading: boolean
  cardType: string
  cvvLabel: string
  monthOptions: { title: string, value: string }[]
  yearOptions: { title: string, value: string }[]
}

const DEFAULT_STATE: AddCardState = {
  loading: false,
  cardType: '',
  cvvLabel: 'CVV',
  monthOptions: [] as { title: string, value: string }[],
  yearOptions: [] as { title: string, value: string }[],
}

export function AddCardForm({ onAddCard, onCancel }: AddCardFormProps): JSX.Element {

  const [form] = useForm()
  const [state, setState] = useState<AddCardState>({ ...DEFAULT_STATE })

  useEffect(() => {
    const monthOptions: { title: string, value: string }[] = []
    const yearOptions: { title: string, value: string }[] = []
    for (let m = 1; m < 13; m++) {
      monthOptions.push({ title: `${m}`.padStart(2, '0'), value: `${m}`.padStart(2, '0') })
    }
    const d =new Date()
    const year = d.getFullYear()
    for (let y = year; y < year + 10; y++) {
      yearOptions.push({ title: `${y}`, value: `${y}` })
    }
    setState((prevState) => ({ ...prevState, monthOptions, yearOptions }))
  }, [])

  const _handleSubmit = (data: CreditCardDetails) => {

    const details: CreditCardDetails = {
      cardNickname: data.cardNickname as string,
      nameOnCard: data.nameOnCard as string,
      cardNumber: data.cardNumber.replace(/\s/g, '') as string,
      cardType: data.cardType as string,
      expiryMonth: data.expiryMonth as string,
      expiryYear: data.expiryYear as string,
      cvv: data.cvv as string,
    }

    onAddCard(
      details,
    )
  }

  const _handleCancel = () => {
    onCancel()
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'cardNumber') {
          let cardType = state.cardType
          const cardNum = field.value.replace(/\s/g, '')
          cardType = valid?.number(cardNum)?.card?.type
          const cvvLabel = cardType === 'american-express' ? 'CID' : 'CVV'
          setState((prevState) => ({
            ...prevState,
            cardType,
            cvvLabel,
          }))
        }
      })
    })
  }

  return (
    <Fragment>
      <Container>
        <Form form={form} onFinish={_handleSubmit} onFieldsChange={_handleChange}>
          <TextInput name='cardNickname' placeholder='Card nickname' rules={[{ required: true, message: 'Please input a nickname' }]} />
          <TextInput name='nameOnCard' placeholder='Enter name' rules={[{ required: true, message: 'Please input a name' }]} />
          <TextInput name='cardNumber' variant='creditCard' placeholder='Card number' rules={[{ required: true, message: 'Please input a card number' }]} />
          <DateContainer>
            <SelectInput name='expiryMonth' placeholder='Select month' options={state.monthOptions} rules={[{ required: true, message: 'Please select a month' }]} />
            <Spacer variant='horizontal' desktop='12px'/>
            <SelectInput name='expiryYear' placeholder='Select year' options={state.yearOptions} rules={[{ required: true, message: 'Please select a year' }]} />
          </DateContainer>
          <TextInput name='cvv' placeholder={state.cvvLabel} rules={[{ required: true, message: 'Please input a CVV' }]} />
          <Spacer mobile='8px' desktop='12px' />
          <Paragraph variant='p3' color={theme.colors.greys.tundora} align='center'>
            Your card will be charged with R1 to verify your details and get you started with your subscription. Don’t forget – it’s flexible, so you can pause at any time, but verifying your card means you won’t have to enter your details again.
          </Paragraph>
          <Spacer universal='16px'/>
          <ActionContainer $stickyBottom>
            <Button color='black' fullWidth title='Save' onClick={() => form.submit()} />
            <Spacer universal='16px' />
            <Link variant='l2' decoration='underline' onClick={_handleCancel}> Cancel </Link>
          </ActionContainer>
        </Form>
      </Container>
    </Fragment>
  )
}
