import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/Suppliers')

const Suppliers = loadable(() => import(/* webpackChunkName: "suppliers" */'@pages/Suppliers'), {
  resolveComponent: (components) => components.Suppliers,
})

export function SuppliersAsync(): JSX.Element {

  return <Suppliers fallback={<div>Loading...</div>} />

}
