import React from 'react'

import styled from 'styled-components'

import { IconEnum, Paragraph, Button } from '@client/components'
import { ResponsivePXValue, theme, ZeroSpace, Ellipsis } from '@client/components/Theme'

import { Spacer } from '../../atoms/layout'

const Container = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('padding', '0px 8px')}
  background-color: ${theme.colors.whites.desertStorm};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ResponsivePXValue('border-radius', '8px')}
  cursor: pointer;
  border-color: ${theme.colors.whites.desertStorm};
  border-style: solid;
  border-width: 2px;
  &:hover {
    border-color: ${theme.colors.slates.ironsideGrey};
    .title-text {
      ${ZeroSpace}
      line-height: 1.2;
      color: ${theme.colors.slates.ironsideGrey};
    }
    .remove-icon-button {
      color: ${theme.colors.slates.ironsideGrey};
    }
  }

  .title-text {
    ${ResponsivePXValue('max-width', { mobile: 'CALC(100vw - 76px)', tablet: '324px', desktop: '324px' })}
    ${ZeroSpace}
    ${Ellipsis()}
    line-height: 1.2;
    color: ${theme.colors.slates.bitter};
  }

  .remove-icon-button {
    color: ${theme.colors.slates.ash};
  }
`

const IconContainer = styled.div`

  .icon-container {
   ${ResponsivePXValue('width', '14px')}
   ${ResponsivePXValue('height', '14px')}
  }
`

export interface TagChipProps {
  title: string
  className?: string
  onRemove?: () => void
}

export function TagChip({ title, className, onRemove }: TagChipProps): JSX.Element {

  const _handleOnRemove = (): void => {
    onRemove?.()
  }

  return (
    <Container className={className} onClick={_handleOnRemove} >
      <Paragraph variant='p3' color={theme.colors.slates.bitter} className='title-text'>{title}</Paragraph>
      <Spacer universal='12px' />
      <If condition={!!onRemove}>
        <IconContainer className='remove-icon-button'>
          <Button
            color='transparent'
            icon={IconEnum.CLOSE_OUTLINE}
            iconColor={theme.colors.slates.ash}
            size='small' />
        </IconContainer>
      </If>
    </Container>
  )
}
