import { ApolloClient, ApolloQueryResult, DocumentNode, FieldPolicy, NormalizedCacheObject, gql, makeVar } from '@apollo/client'
import update from 'react-addons-update'
import { v4 } from 'uuid'
import { CartErrorFragment, CartItemFragment, LocalCheckoutFragment, UserCartDocument, UserCartQuery, UserCheckoutStatusFragment, UserDetailsDocument, UserDetailsQuery } from '@hooks/api'
import { ConfigPlugin } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'
import { CheckoutTypeEnum, CheckoutStepEnum } from '@uctypes/api/globalTypes'

export const CHECKOUT_DEFAULT_STATE: LocalCheckoutFragment = {
  id: v4(),
  open: false,
  type: CheckoutTypeEnum.ON_DEMAND,
  step: CheckoutStepEnum.NOTHING,
  forcedStep: null,
  creditCardId: null,
  userAddressId: null,
  forceSubscription: false,
  cartErrors: [],
  cartItems: [],
  orderId: null,
  dispatchDate: null,
  hasSeenAbout: true,
  shouldShowAbout: false,
  shouldShowModal: true,
  __typename: 'LocalCheckout',
}

const _data = makeVar<LocalCheckoutFragment>({ ...CHECKOUT_DEFAULT_STATE })

export class CheckoutPlugin implements ConfigPlugin {

  static instance: CheckoutPlugin

  static shared(): CheckoutPlugin {
    if (!this.instance) {
      this.instance = new CheckoutPlugin()
    }
    return this.instance
  }

  async checkout(client: ApolloClient<NormalizedCacheObject>, forceSubscriptionCheckout?: boolean): Promise<void> {
    const checkoutState = _data()
    const { type, step, forceSubscription } = await this.getCheckoutStep(client, checkoutState, forceSubscriptionCheckout)
    let checkoutOpen = true
    if (step === CheckoutStepEnum.NOTHING) {
      checkoutOpen = false
    }
    _data(update(checkoutState, {
      open: { $set: checkoutOpen },
      step: { $set: step },
      type: { $set: type },
      forceSubscription: { $set: forceSubscription },
      forcedStep: { $set: null },
    }))
  }

  getActiveStep(state?: LocalCheckoutFragment): CheckoutStepEnum {
    if (!state) {
      state = _data()
    }
    if (state.forcedStep) {
      return state.forcedStep
    }
    return state.step
  }

  getActiveType(state?: LocalCheckoutFragment): CheckoutTypeEnum {
    if (!state) {
      state = _data()
    }
    return state.type
  }

  reset(): void {
    _data({ ...CHECKOUT_DEFAULT_STATE })
  }

  didLogIn(client: ApolloClient<NormalizedCacheObject>): void {
    const checkoutState = _data()
    if (checkoutState.step === CheckoutStepEnum.IDENTIFY_USER) {
      this.checkout(client)
    }
  }

  shouldShowCheckoutAfterLogIn(): boolean {
    const checkoutState = _data()
    if (checkoutState.step === CheckoutStepEnum.IDENTIFY_USER) {
      return true
    }
    return false
  }

  thankYou({ orderId, dispatchDate, cartItems }: { orderId: string, dispatchDate: string, cartItems?: CartItemFragment[] } = { orderId: '', dispatchDate: '', cartItems: [] }): void {
    const checkoutState = _data()
    _data(update(checkoutState, {
      step: { $set: CheckoutStepEnum.THANK_YOU },
      orderId: { $set: orderId },
      dispatchDate: { $set: dispatchDate },
      cartItems: { $set: cartItems },
      open: { $set: true },
    }))
  }

  forceStep(step: CheckoutStepEnum): void {
    const checkoutState = _data()
    _data(update(checkoutState, {
      forcedStep: { $set: step },
    }))
  }

  async setCartErrors(client: ApolloClient<NormalizedCacheObject>, cartErrors: CartErrorFragment[]): Promise<void> {
    const checkoutState = _data()
    const userCart = await this.getUserCart(client)
    const hasCartItems = userCart.activeCart.cartItems.length
    if (hasCartItems) {
      _data(update(checkoutState, {
        cartErrors: { $set: [...cartErrors] },
      }))
    } else {
      _data(update(checkoutState, {
        cartErrors: { $set: [] },
        forceSubscription: { $set: false },
        orderId: { $set: null },
        dispatchDate: { $set: null },
        open: { $set: false },
        creditCardId: { $set: null },
        userAddressId: { $set: null },
        step: { $set: CheckoutStepEnum.NOTHING },
      }))
    }
    const newState = _data()
    const { type, step, forceSubscription } = await this.getCheckoutStep(client, newState, checkoutState.forceSubscription)
    _data(update(newState, {
      step: { $set: step },
      type: { $set: type },
      forceSubscription: { $set: forceSubscription },
    }))
  }

  async setOnDemandCreditCard(client: ApolloClient<NormalizedCacheObject>, creditCardId: string): Promise<void> {
    const checkoutState = _data()
    _data(update(checkoutState, {
      creditCardId: { $set: creditCardId },
    }))
    await this.checkout(client)
  }

  async setOnDemandUserAddress(client: ApolloClient<NormalizedCacheObject>, userAddressId: string): Promise<void> {
    const checkoutState = _data()
    _data(update(checkoutState, {
      userAddressId: { $set: userAddressId },
    }))
    await this.checkout(client)
  }

  private async getUserCart(client: ApolloClient<NormalizedCacheObject>): Promise<UserCartQuery['currentUser']> {
    const { data }: ApolloQueryResult<UserCartQuery> = await client.query({ query: UserCartDocument })
    return data.currentUser
  }

  private async getUserDetails(client: ApolloClient<NormalizedCacheObject>): Promise<UserDetailsQuery['currentUser']> {
    const { data }: ApolloQueryResult<UserDetailsQuery> = await client.query({ query: UserDetailsDocument })
    return data.currentUser
  }

  private async getCheckoutStep(client: ApolloClient<NormalizedCacheObject>, state: LocalCheckoutFragment, forceSubscription = false): Promise<{ type: CheckoutTypeEnum, step: CheckoutStepEnum, forceSubscription?: boolean }> {
    const userCart = await this.getUserCart(client)
    const userDetails = await this.getUserDetails(client)
    // TODO: Remove this function
    const status = SiteHelper.getCheckoutStatus(userCart?.checkoutStatus)
    const type = this.getCheckoutType(status, userCart, userDetails, state, forceSubscription)
    let step = CheckoutStepEnum.NOTHING
    const hasCartItems = userCart.activeCart.cartItems.length
    if (hasCartItems) {
      if (type === CheckoutTypeEnum.ON_DEMAND) {
        step = this.getOnDemandStep(status, userCart, userDetails, state)
      } else {
        step = this.getSubscriptionStep(status, userCart, userDetails, state, forceSubscription)
      }
    }
    if (status.hasSelectedPlan && status.hasConfiguredBox && !status.hasActiveSubscription && !status.hasPausedSubscription) {
      forceSubscription = true
    }
    if (!status.hasConfiguredBox && !status.hasValidSubscription && !status.hasOnDemandProducts && !status.hasAccount) {
      forceSubscription = true
      step = CheckoutStepEnum.IDENTIFY_USER
    }
    return { type, step, forceSubscription }
  }

  private getCheckoutType(status: UserCheckoutStatusFragment, userCart: UserCartQuery['currentUser'], userDetails: UserDetailsQuery['currentUser'], state: LocalCheckoutFragment, forceSubscription = false): CheckoutTypeEnum {
    if (status.hasActiveSubscription || status.hasConfiguredBox || forceSubscription) {
      return CheckoutTypeEnum.SUBSCRIPTION
    }
    return CheckoutTypeEnum.ON_DEMAND
  }

  private getOnDemandStep(status: UserCheckoutStatusFragment, userCart: UserCartQuery['currentUser'], userDetails: UserDetailsQuery['currentUser'], state: LocalCheckoutFragment): CheckoutStepEnum {
    if (state.cartErrors.length) {
      return CheckoutStepEnum.CART_ERRORS
    } else if (!status.hasAccount) {
      return CheckoutStepEnum.IDENTIFY_USER
    } else if (!userDetails.phoneIsVerified) {
      return CheckoutStepEnum.VERIFY_NUMBER
    } else if (!state.userAddressId) {
      return CheckoutStepEnum.DELIVERY
    } else if (!state.creditCardId) {
      return CheckoutStepEnum.PAYMENT
    } else {
      return CheckoutStepEnum.CART_SUMMARY
    }
  }

  private validateDeliveryDay = (currentUser: UserDetailsQuery['currentUser'], currentCart: UserCartQuery['currentUser']): boolean => {
    const defaultAddress = currentUser?.addresses?.find((address) => address.isDefault)
    const deliveryChoice = currentCart?.activeMenu?.subscription?.deliveryOption
    for (const deliveryDay of defaultAddress?.location?.city?.deliveryDays) {
      if ((deliveryDay as string) === (deliveryChoice as string)) {
        return true
      }
    }
    return false
  }

  private getSubscriptionStep(status: UserCheckoutStatusFragment, userCart: UserCartQuery['currentUser'], userDetails: UserDetailsQuery['currentUser'], state: LocalCheckoutFragment, forceSubscription = false): CheckoutStepEnum {
    if (state.cartErrors.length !== 0) {
      return CheckoutStepEnum.CART_ERRORS
    } else if (!status.hasAccount) {
      return CheckoutStepEnum.IDENTIFY_USER
    } else if (state.shouldShowAbout && !state.hasSeenAbout && !forceSubscription) {
      return CheckoutStepEnum.ABOUT
    } else if (!userDetails.phoneIsVerified) {
      return CheckoutStepEnum.VERIFY_NUMBER
    } else {
      if (status.hasActiveSubscription) {
        if (status.hasValidSubscription) {
          if (!status.subscriptionIsSaved) {
            return CheckoutStepEnum.SAVE_MENU
          } else {
            return CheckoutStepEnum.CART_SUMMARY
          }
        } else {
          return CheckoutStepEnum.VALIDATION
        }
      } else {
        if (status.hasConfiguredBox || forceSubscription) {
          if (forceSubscription && !state.hasSeenAbout) {
            return CheckoutStepEnum.ABOUT
          }
          if (status.hasPausedSubscription) {
            return CheckoutStepEnum.REACTIVATE
          } else {
            if (!status.hasSelectedPlan) {
              return CheckoutStepEnum.CONFIGURE_PLAN
            } else {
              if (!status.hasSetDeliveryAddress || !status.hasSetDeliveryAddressDetails || !this.validateDeliveryDay(userDetails, userCart)) {
                return CheckoutStepEnum.DELIVERY
              } else if (!status.hasSelectedDeliveryDay) {
                return CheckoutStepEnum.DELIVERY_DAY
              } else if (!status.hasSetPaymentMethod) {
                return CheckoutStepEnum.PAYMENT
              } else {
                return CheckoutStepEnum.ACTIVATE_SUBSCRIPTION
              }
            }
          }
        }
      }
    }
    return CheckoutStepEnum.NOTHING
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    checkout: {
      read(): LocalCheckoutFragment {
        const data = _data()
        return data as LocalCheckoutFragment
      },
    },
  })

  types = (): DocumentNode => gql`
    enum CheckoutTypeEnum {
      ON_DEMAND
      SUBSCRIPTION
    }

    enum CheckoutStepEnum {
      NOTHING
      ABOUT
      VERIFY_NUMBER
      IDENTIFY_USER
      CONFIGURE_PLAN
      DELIVERY
      PAYMENT
      CART_SUMMARY
      VALIDATION
      REACTIVATE
      THANK_YOU
      SAVE_MENU
      ACTIVATE_SUBSCRIPTION
      CART_ERRORS
      DELIVERY_DAY
    }

    type LocalCheckout {
      id: ID!
      open: Boolean!
      hasSeenAbout: Boolean!
      shouldShowAbout: Boolean!
      shouldShowModal: Boolean!
      type: CheckoutTypeEnum!
      step: CheckoutStepEnum!
      forcedStep: CheckoutStepEnum
      creditCardId: String
      userAddressId: String
      forceSubscription: Boolean
      orderId: String
      dispatchDate: String
      cartErrors: JSONObject
      cartItems: [JSONObject]
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      checkout: LocalCheckout!
    }
  `

  queries = (): DocumentNode => gql`
    fragment LocalCheckoutFragment on LocalCheckout {
      id
      open
      type
      step
      forcedStep
      creditCardId
      userAddressId
      forceSubscription
      cartErrors
      cartItems
      orderId
      dispatchDate
      hasSeenAbout
      shouldShowAbout
      shouldShowModal
    }
    query GetCheckout {
      checkout @client {
        ... LocalCheckoutFragment
      }
    }
  `

}
