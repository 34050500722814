import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ResponsivePXValue } from '@client/components'
import { ErrorBlock, Modal } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery, useUserCartQuery, UserDetailsDocument, UserCartDocument, SubscribeToPaymentChangeDocument, useCheckPaymentStatusLazyQuery, useAddAndValidateCreditCardMutation, useRemoveCreditCardMutation, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { ModalLoading, ModalFormContainer } from '@molecules/index'
import { AddAndValidateCreditCardInput, TransactionStatusEnum } from '@uctypes/api/globalTypes'

import { AddCardForm } from '../forms/AddCardForm'

enum CreditCardStepEnum {
  ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
  REDIRECT = 'REDIRECT',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

const Container = styled.div`
  
`

const IframeContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '400px', tablet: '400px', desktop: '600px' })}
`

export interface CreditCardDetails {
  cardNickname: string
  nameOnCard: string
  cardNumber: string
  cardType?: string
  expiryMonth: string
  expiryYear: string
  cvv: string
}

export interface CreditCardModalProps {
  open: boolean
  onClose: () => void
  didAddCard?: (creditCardId: string) => void
}

interface CreditCardModalState {
  displayStep: CreditCardStepEnum
  error: string
  redirect: string
  paymentId: string
  creditCardId: string
}

const DEFAULT_STATE: CreditCardModalState = {
  displayStep: CreditCardStepEnum.ADD_CREDIT_CARD,
  error: '',
  redirect: '',
  paymentId: '',
  creditCardId: '',
}

export function CreditCardModal({ open, onClose, didAddCard }: CreditCardModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<CreditCardModalState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: detailsLoading, refetch: refetchDetails } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { refetch: refetchCart } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [addAndValidate, { loading: addLoading }] = useAddAndValidateCreditCardMutation()
  const [removeCreditCard, { loading: removeLoading }] = useRemoveCreditCardMutation()
  const [check, { data: statusData, loading: statusLoading, subscribeToMore }] = useCheckPaymentStatusLazyQuery({ variables: { id: state.paymentId } })
  const iFrame: React.RefObject<HTMLIFrameElement> = useRef()
  const toasts = useToasts()

  const user = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment

  const _handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const _handleAddCard = async (creditCardDetails: CreditCardDetails): Promise<void> => {
    const successRedirect = ''
    const failureRedirect = ''
    const creditCardInput: AddAndValidateCreditCardInput = {
      cardNickname: creditCardDetails.cardNickname,
      nameOnCard: creditCardDetails.nameOnCard,
      cardNumber: creditCardDetails.cardNumber,
      cvv: creditCardDetails.cvv,
      expiryMonth: creditCardDetails.expiryMonth,
      expiryYear: creditCardDetails.expiryYear,
      user: user.id,
      successRedirect,
      failureRedirect,
      isDefault: true,
    }
    try {
      const response = await addAndValidate({
        variables: {
          input: creditCardInput,
        },
        refetchQueries: [{ query: UserDetailsDocument }, { query: UserCartDocument }],
      })
      setState((prevState) => update(prevState, {
        displayStep: { $set: CreditCardStepEnum.REDIRECT },
        redirect: { $set: response.data.validatedCard.validationUrl },
        creditCardId: { $set: response.data.validatedCard.id },
      }))
    } catch (e) {
      setState((prevState) => ({ ...prevState, displayStep: CreditCardStepEnum.ERROR, error: e.message }))
    }
  }

  const _handleSuccess = async (): Promise<void> => {
    await Promise.all([
      refetchDetails(),
      refetchCart(),
    ])
    toasts.addToast('Successfully added credit card', {
      appearance: 'success',
      autoDismiss: true,
    })
    didAddCard?.(state.creditCardId)
    onClose?.()
  }

  const _handleFailed = async (): Promise<void> => {
    toasts.addToast('Failed to added credit card', {
      appearance: 'error',
      autoDismiss: true,
    })
    await removeCreditCard({
      variables: { id: state.creditCardId },
      refetchQueries: [{ query: UserDetailsDocument }],
      awaitRefetchQueries: true,
    })
    onClose?.()
  }

  const _handlePending = (): () => void => {
    return subscribeToMore({
      document: SubscribeToPaymentChangeDocument,
      variables: { id: state.paymentId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newStatus = subscriptionData.data.status
        return Object.assign({}, prev, {
          status: newStatus,
        })
      },
    })
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, {
        displayStep: { $set: CreditCardStepEnum.ADD_CREDIT_CARD },
      }))
    }
  }, [open])

  useEffect(() => {
    if (state.paymentId) {
      check({ variables: { id: state.paymentId } })
    }
  }, [state.paymentId])

  useEffect(() => {
    let subscription: () => void | null = null
    if (statusData?.status?.status) {
      if (statusData?.status?.status === TransactionStatusEnum.FAILED) {
        _handleFailed()
      } else if (statusData?.status?.status === TransactionStatusEnum.SUCCESS) {
        _handleSuccess()
      } else {
        subscription = _handlePending()
      }
    }
    return () => subscription?.()
  }, [statusData?.status?.status])

  useEffect(() => {
    const _handler = async (event: MessageEvent): Promise<void> => {
      if (config.isBrowser() && event.origin.startsWith(window.location.origin) && event.data.id) {
        setState((prevState) => update(prevState, {
          paymentId: { $set: event.data.id },
          displayStep: { $set: CreditCardStepEnum.PENDING },
        }))
      }
    }
    if (config.isBrowser()) {
      window.addEventListener('message', _handler)
      return () => window.removeEventListener('message', _handler)
    }
  }, [])

  let title = 'Add a card'
  switch (state.displayStep) {
    case CreditCardStepEnum.ERROR:
      title = 'Oops'
      break
    case CreditCardStepEnum.REDIRECT:
      title = 'Redirect'
      break
  }

  const loading = detailsLoading || addLoading || removeLoading || statusLoading
  let loadingMessage = 'Fetching your information'
  if (addLoading) {
    loadingMessage = 'Attempting to Add Card'
  } else if (removeLoading) {
    loadingMessage = 'Removing failed card'
  } else if (statusLoading) {
    loadingMessage = 'Validating card'
  }

  return (
    <Modal
      className='credit-card-modal'
      allowScroll
      contentOverflow='initial'
      fullscreen={false}
      open={open}
      title={title}
      resolutionAdapter={true}
      allowBackgroundClose={state.displayStep !== CreditCardStepEnum.REDIRECT}
      onClose={_handleClose}>
      <Choose>
        <When condition={loading}>
          <ModalLoading message={loadingMessage} />
        </When>
        <When condition={state.displayStep === CreditCardStepEnum.PENDING}>
          <ModalLoading message='Please wait' />
        </When>
        <When condition={state.displayStep === CreditCardStepEnum.ERROR}>
          <ErrorBlock
            title={state.error}
            onClick={() => setState((prevState) => ({ ...prevState, displayStep: CreditCardStepEnum.ADD_CREDIT_CARD }))}/>
        </When>
        <When condition={state.displayStep === CreditCardStepEnum.ADD_CREDIT_CARD}>
          <Container >
            <ModalFormContainer
              className='add-card'>
              <AddCardForm
                onAddCard={_handleAddCard}
                onCancel={_handleClose} />
            </ModalFormContainer>
          </Container>
        </When>
        <When condition={state.displayStep === CreditCardStepEnum.REDIRECT}>
          <ModalFormContainer >
            <IframeContainer>
              <iframe
                src={state.redirect}
                sandbox='allow-forms allow-scripts allow-same-origin'
                width='100%'
                height='100%'
                ref={iFrame} />
            </IframeContainer>
          </ModalFormContainer>
        </When>
        <Otherwise>
          <Container>
            <ModalFormContainer
              className='add-card'>
              <AddCardForm
                onAddCard={_handleAddCard}
                onCancel={_handleClose} />
            </ModalFormContainer>
          </Container>
        </Otherwise>
      </Choose>
    </Modal>
  )
}
