import React from 'react'

import styled from 'styled-components'

import { SmallLoader, Paragraph, Button } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface ButtonContainerProps {
  $selected: boolean
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const StartButtonContainer = styled.div<ButtonContainerProps>`
  flex: 1; 
  div {
    ${ResponsivePXValue('padding', '0 20px')}
    ${ResponsivePXValue('border-width', '1px 0 1px 1px')}
    ${ResponsivePXValue('height', { mobile: '40px', tablet: '44px', desktop: '48px' })}
    ${ResponsivePXValue('border-radius', { mobile: '8px 0 0 8px', tablet: '8px 0 0 8px', desktop: '8px 0 0 8px' })}
    &:hover {
      background-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.alabaster};
      color: ${(props): string => props.$selected ? props.theme.colors.whites.alabaster : props.theme.colors.oranges.coral};
      border-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.silver};
    };
  }
`

const MiddleButtonContainer = styled.div<ButtonContainerProps>`
  flex: 1; 
  div {
    ${ResponsivePXValue('padding', '0 20px')}
    ${ResponsivePXValue('border-width', '1px 0')}
    ${ResponsivePXValue('height', { mobile: '40px', tablet: '44px', desktop: '48px' })}
    ${ResponsivePXValue('border-radius', '0')}
    &:hover {
      background-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.alabaster};
      color: ${(props): string => props.$selected ? props.theme.colors.whites.alabaster : props.theme.colors.oranges.coral};
      border-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.silver};
    };
  }
`

const EndButtonContainer = styled.div<ButtonContainerProps>`
  flex: 1; 
  div {
    ${ResponsivePXValue('padding', '0 20px')}
    ${ResponsivePXValue('border-width', '1px 1px 1px 0')}
    ${ResponsivePXValue('height', { mobile: '40px', tablet: '44px', desktop: '48px' })}
    ${ResponsivePXValue('border-radius', { mobile: '0 8px 8px 0', tablet: '0 8px 8px 0', desktop: '0 8px 8px 0' })}
    &:hover {
      background-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.alabaster};
      color: ${(props): string => props.$selected ? props.theme.colors.whites.alabaster : props.theme.colors.oranges.coral};
      border-color: ${(props): string => props.$selected ? props.theme.colors.oranges.burntSienna : props.theme.colors.whites.silver};
    };
  }
`

const LoadingContainer = styled.div`

  width: 100%;
  ${ResponsivePXValue('height', { mobile: '40px', tablet: '44px', desktop: '48px' })}
  display: flex;
  align-items: center;
  justify-content: center;

`
export interface ButtonItemProps {
  title: string
  value: string
  active?: boolean
  isFirst?: boolean
  isLast?: boolean
  onSelect?: () => void
}

export function ButtonItem({ title, active = false, isFirst = false, isLast = false, onSelect }: ButtonItemProps): JSX.Element {

  return (
    <Choose>
      <When condition={isFirst}>
        <StartButtonContainer $selected={active}>
          <Choose>
            <When condition={active}>
              <Button title={title} onClick={onSelect}></Button>
            </When>
            <Otherwise>
              <Button variant='secondary' color='grey' title={title} onClick={onSelect} />
            </Otherwise>
          </Choose>
        </StartButtonContainer>
      </When>
      <When condition={isLast}>
        <EndButtonContainer $selected={active}>
          <Choose>
            <When condition={active}>
              <Button title={title} onClick={onSelect}></Button>
            </When>
            <Otherwise>
              <Button variant='secondary' color='grey' title={title} onClick={onSelect} />
            </Otherwise>
          </Choose>
        </EndButtonContainer>
      </When>
      <Otherwise >
        <MiddleButtonContainer $selected={active}>
          <Choose>
            <When condition={active}>
              <Button title={title} onClick={onSelect}></Button>
            </When>
            <Otherwise>
              <Button variant='secondary' color='grey' title={title} onClick={onSelect}/>
            </Otherwise>
          </Choose>
        </MiddleButtonContainer>
      </Otherwise>
    </Choose>
  )

}

export interface ButtonGroupProps {
  title: string
  children?: JSX.Element | JSX.Element[]
  apiEnum?: string
  value: string
  onChange: (item: string) => void
}

export function ButtonGroup({ title, apiEnum, value, onChange, children = [] }: ButtonGroupProps): JSX.Element {

  const { data: enumData, loading: enumLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })

  const _handleClick = (key: string): void => {
    onChange(key)
  }

  let $buttons: JSX.Element[] = []
  if (apiEnum && enumData) {
    $buttons = enumData?.enum?.values?.map((enumOption, index) => {
      return <ButtonItem
        key={enumOption.value}
        title={enumOption.title}
        value={enumOption.value}
        isFirst={index === 0}
        isLast={index === enumData?.enum?.values?.length - 1}
        active={enumOption.value === value}
        onSelect={() => _handleClick(enumOption.value)} />
    })
  } else if (children && Array.isArray(children)) {
    $buttons = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        ...child.props,
        isFirst: index === 0,
        isLast: index === children.length - 1,
        active: child.props.value === value,
        onClick: () => _handleClick(child.props.value),
      })
    })
  } else if (children) {
    $buttons = React.Children.map(children, (child) => {
      return <Button title={child.props.title} onClick={() => _handleClick(child.props.value)} />
    })
  }

  return (
    <Container>
      <Paragraph variant='p2' align='center'>
        {title}
      </Paragraph>
      <Choose>
        <When condition={enumLoading}>
          <LoadingContainer>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoadingContainer>
        </When>
        <When condition={!$buttons.length}>
          <Paragraph variant='p2'>
            no items
          </Paragraph>
        </When>
        <When condition={$buttons.length === 1}>
          <Button title={$buttons[0].props.title} />
        </When>
        <Otherwise>
          <ButtonsContainer>
            {$buttons}
          </ButtonsContainer>
        </Otherwise>
      </Choose>
    </Container>
  )

}
