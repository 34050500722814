import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Heading, Paragraph, ResponsiveImage, Button, Spacer, Link } from '@atoms/index'
import { SimplePagination } from '@client/components/molecules/controls/SimplePagination'
import { DeviceContainer } from '@client/components/utility'
import { CategoryTag, Modal, SectionLoading } from '@components/molecules'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { MealKitDishListFragment, MenuDishWineForWineFragment } from '@hooks/api'
import { DishInfoTable, DishInfoTableData } from '@organisms/stores/DishInfoTable'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '0px', tablet: '8px', desktop: '8px' })};

  .full-width {
   width: 100%;
  }
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('padding', { mobile: '0px', tablet: '8px 24px 8px 24px', desktop: '8px 24px 8px 24px' })};

  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })};
`

const CategoryContainer = styled.div`
  ${ResponsiveProperty('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}

  ${ResponsivePXValue('margin-bottom', { mobile: '10px', tablet: '12px', desktop: '16px' })}
`

const HeaderContainer = styled.div`
  .title {
     ${ZeroSpace}
    
     ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '12px', desktop: '8px' })}
     ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '20px', desktop: '24px' })}
     ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '32px', desktop: '40px' })}
  }
`

const TextContainer = styled.div`
  .description {
    ${ZeroSpace}

    ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '20px', desktop: '24px' })}
  }
`

const DescriptionContainer = styled(TextContainer)`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '8px 24px 8px 24px', tablet: '8px 20px 8px 24px', desktop: '8px 24px 8px 24px' })};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const DishInfoTableContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const ImageContainer = styled.div`
  display: flex;

  img {
    ${ResponsiveProperty('position', { mobile: 'absolute', tablet: 'absolute', desktop: 'relative' })}
    ${ResponsiveProperty('width', { mobile: '100%', tablet: '100vw', desktop: '100%' })}
    ${ResponsiveProperty('margin-left', { tablet: '-8vw' })}
  }

  ${ResponsiveProperty('overflow', { mobile: 'initial', tablet: 'initial', desktop: 'hidden' })};

  ${ResponsivePXValue('border-radius', '16px')};

  ${ResponsivePXValue('height', { mobile: '256px', tablet: '460px', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: 'initial', tablet: '460px', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: 'initial', tablet: '460px', desktop: '576px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '20px', tablet: '24px', desktop: 'initial' })}
`

const PagerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
 
  background-color: ${(props): string => props.theme.colors.whites.alabaster};

  ${ResponsivePXValue('height', '72px')}
`
export interface PairingsModalProps {
  pairedMeals: MenuDishWineForWineFragment[]
  open: boolean
  title: string
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  onShowDetails: (slug: string) => void
  onAddToCart: (product: MealKitDishListFragment) => void
}

interface PairingsModalState {
  currentProduct?: MenuDishWineForWineFragment | null
  currentPage: number
}

const DEFAULT_STATE: PairingsModalState = {
  currentProduct: null,
  currentPage: 1,
}

export function PairingsModal({ pairedMeals, open, title, onClose, onShowDetails, onAddToCart }: PairingsModalProps): JSX.Element {
  const [state, setState] = useState<PairingsModalState>({ ...DEFAULT_STATE, currentProduct: pairedMeals && pairedMeals?.length ? pairedMeals[0] : null })
  const theme = useTheme()

  if (!state.currentProduct && (pairedMeals && pairedMeals?.length)) {
    setState(update(state, { currentProduct: { $set: pairedMeals[0] } }))
  }

  const _handleShowDetails = (): void => {
    onShowDetails(state.currentProduct.dish.slug)
  }

  const _handleAddToCart = (): void => {
    if (state.currentProduct) {
      onAddToCart(state.currentProduct.dish)
    }
  }

  const _handlePageChange = (pageIndex: number): void => {
    setState(update(state, { currentPage: { $set: pageIndex }, currentProduct: { $set: pairedMeals[pageIndex - 1] } }))
  }

  const dishInfoTableData: DishInfoTableData[] = [{
    col1: {
      key: 'By ',
      value: `${state.currentProduct?.dish?.chef?.name}`,
    },
    col2: {
      key: 'Eat within',
      value: `${state.currentProduct?.dish?.cookWithin}`,
    },
  },
  ]

  return (
    <Modal
      open={open}
      title={title}
      onClose={onClose}>
      <Choose>
        <When condition={!!state.currentProduct}>
          <Container>
            <DishContainer>
              <ContentContainer>
                <CategoryContainer>
                  <CategoryTag image={state.currentProduct.dish.mealKitCategories[0]?.icon?.desktop} title={state.currentProduct.dish.mealKitCategories[0]?.title} />
                </CategoryContainer>
                <HeaderContainer>
                  <Heading className='title' variant='h3' color={theme.colors.greys.liteCodGrey}>
                    {state.currentProduct.dish?.name}
                  </Heading>
                </HeaderContainer>
                <DescriptionContainer>
                  <Paragraph className='description' variant='p1' color={theme.colors.greys.darkCodGrey}>
                    {state.currentProduct.dish?.description}
                  </Paragraph>
                </DescriptionContainer>
                <DishInfoTableContainer>
                  <DishInfoTable data={dishInfoTableData} />
                </DishInfoTableContainer>
                <ButtonContainer>
                  <DeviceContainer $mobile $tablet className='full-width'>
                    <Button color='black' fullWidth title='ADD TO CART' onClick={_handleAddToCart} />
                  </DeviceContainer>
                  <DeviceContainer $desktop>
                    <Button color='black' title='ADD TO CART' onClick={_handleAddToCart} />
                  </DeviceContainer>
                  <Spacer mobile='16px' />
                  <Spacer desktop='16px' variant='horizontal' />
                  <Link href={`/meal-kit/${state.currentProduct.dish?.slug}`} onClick={_handleShowDetails}>
                    VIEW FULL DETAILS
                  </Link>
                </ButtonContainer>
              </ContentContainer>
              <ImageContainer className='lite-image'>
                <ResponsiveImage image={state.currentProduct.dish?.coverImage} />
              </ImageContainer>
            </DishContainer>
            <PagerContainer>
              <SimplePagination currentPage={state.currentPage} maxPages={pairedMeals?.length | 0} onPageChange={_handlePageChange} />
            </PagerContainer>
          </Container>
        </When>
        <Otherwise>
          <SectionLoading />
        </Otherwise>
      </Choose>
    </Modal>
  )
}
