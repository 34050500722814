import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Paragraph } from '@atoms/index'
import { ErrorBlock, Modal, SectionLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { AddProductAvailabilityEmailDocument, RegisteredUserDetailsFragment, UserAddressFragment, UserDetailsFragment, useGetModalsQuery, useUserDetailsQuery } from '@hooks/api'
import { ModalFormContainer } from '@molecules/index'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

import { AllItemsUnavailableForm } from '../forms/AllItemsUnavailableForm'
import { NotifyMeOnProductAvailabilityForm } from '../forms/NotifyMeOnProductAvailabilityForm'
import { SomeItemsUnavailableForm } from '../forms/SomeItemsUnavailableForm'

enum DeliveryUnavailableModalStepEnum {
  SOME_UNAVAILABLE = 'SOME_UNAVAILABLE',
  ALL_UNAVAILABLE = 'ALL_UNAVAILABLE',
  KEEP_ME_UPDATED = 'KEEP_ME_UPDATED',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

const Container = styled.div`
  .text-element {
    margin: 0px
  }
`

interface AvailableProduct {
  enum: ProductRangeEnum
  staticId: string
  productName: string
  href: string
}

interface DeliveryUnavailableModalState {
  displayStep: DeliveryUnavailableModalStepEnum
  error: string
  availableRangeList: AvailableProduct[]
  defaultAddress: UserAddressFragment | null
  productRange: string
}

const DEFAULT_STATE: DeliveryUnavailableModalState = {
  displayStep: DeliveryUnavailableModalStepEnum.ALL_UNAVAILABLE,
  error: '',
  availableRangeList: [] as AvailableProduct[],
  defaultAddress: null,
  productRange: '',
}

const showOnPaths: { [k: string]: string } = {
  '/meal-kit': 'meal kits',
  '/meal-kit/plans': 'meal kits',
  '/frozen/craft-meal': 'craft meals',
  '/wine': 'wine',
}

export function DeliveryUnavailableModal(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<DeliveryUnavailableModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const productRangeList: AvailableProduct[] = [
    { enum: ProductRangeEnum.FROZEN_MEAL, staticId: 'product-frozen', productName: 'Craft Meals', href: '/frozen/craft-meal' },
    { enum: ProductRangeEnum.MEAL_KIT_PLAN, staticId: 'product-meal-kit', productName: 'Meal Kits', href: '/meal-kit/plans' },
    { enum: ProductRangeEnum.WINE, staticId: 'product-wine', productName: 'Wine', href: '/wine' },
  ]

  const { addToast } = useToasts()

  useEffect(() => {
    if (data?.modals?.deliveryUnavailable && !userDetailsLoading) {
      const defaultAddress = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment).addresses.find((address) => address.isDefault)
      let displayStep = DeliveryUnavailableModalStepEnum.ALL_UNAVAILABLE
      let error = ''
      let productRange = ''
      const availableRangeList: AvailableProduct[] = []
      if (defaultAddress) {
        const availableProductRanges = defaultAddress.location.city.productRanges
        for (let i = 0; i < productRangeList.length; i++) {
          if (availableProductRanges.includes(productRangeList[i].enum)) {
            displayStep = DeliveryUnavailableModalStepEnum.SOME_UNAVAILABLE
            availableRangeList.push(productRangeList[i])
          }
        }
      } else {
        displayStep = DeliveryUnavailableModalStepEnum.ERROR
        error = 'User does not have a default address'
      }

      if (displayStep === DeliveryUnavailableModalStepEnum.SOME_UNAVAILABLE && config.isBrowser()) {
        const key = Object.keys(showOnPaths).find((path) => window.location.href.includes(path))
        productRange = showOnPaths[key] ?? 'product'
      }

      setState((prevState) => update(prevState, {
        displayStep: { $set: displayStep },
        availableRangeList: { $set: availableRangeList },
        error: { $set: error },
        productRange: { $set: productRange },
        defaultAddress: { $set: defaultAddress },
      }))
    }
  }, [data?.modals?.deliveryUnavailable, userDetailsLoading])

  let title = 'Delivery Unavailable'
  switch (state.displayStep) {
    case DeliveryUnavailableModalStepEnum.ERROR:
      title = 'Oops'
      break
  }

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
  }

  const _handleOnNotifyClicked = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: DeliveryUnavailableModalStepEnum.KEEP_ME_UPDATED },
    }))
  }

  const _handleSubmitEmailNotification = async (email: string) => {
    const client = await config.getClient()

    if (state.defaultAddress) {
      const input = {
        email,
        city: { id: state.defaultAddress.location.city.id },
        area: { id: state.defaultAddress.location.area.id },
      }

      setState((prevState) => update(prevState, {
        displayStep: { $set: DeliveryUnavailableModalStepEnum.LOADING },
      }))

      try {
        await client.mutate({
          mutation: AddProductAvailabilityEmailDocument,
          variables: { input },
        })
        addToast(`We will notify you at ${email} when we have updates on delivery to your area. `, {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } else {
      addToast('No default address', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _handleClose()
  }

  const _handleTryDifferentAddress = () => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
  }

  return (
    <Modal
      showCloseButton={!userDetailsLoading}
      open={data?.modals?.deliveryUnavailable}
      fullscreen={false}
      title={title}
      onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={userDetailsLoading || state.displayStep === DeliveryUnavailableModalStepEnum.LOADING}>
            <ModalFormContainer>
              <SectionLoading />
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === DeliveryUnavailableModalStepEnum.ERROR}>
            <ErrorBlock
              title={state.error}
              onClick={_handleClose} />
          </When>
          <When condition={state.displayStep === DeliveryUnavailableModalStepEnum.ALL_UNAVAILABLE}>
            <ModalFormContainer>
              <AllItemsUnavailableForm onTryDifferentAddress={_handleTryDifferentAddress} onNotify={_handleOnNotifyClicked}></AllItemsUnavailableForm>
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === DeliveryUnavailableModalStepEnum.SOME_UNAVAILABLE}>
            <ModalFormContainer>
              <SomeItemsUnavailableForm productRange={state.productRange} availableRangeList={state.availableRangeList} onProductClicked={_handleClose} onNotify={_handleOnNotifyClicked}></SomeItemsUnavailableForm>
            </ModalFormContainer>
          </When>
          <When condition={state.displayStep === DeliveryUnavailableModalStepEnum.KEEP_ME_UPDATED}>
            <ModalFormContainer>
              <NotifyMeOnProductAvailabilityForm onCancel={_handleClose} onSubmit={_handleSubmitEmailNotification}></NotifyMeOnProductAvailabilityForm>
            </ModalFormContainer>
          </When>
          <Otherwise>
            <ModalFormContainer>
              <Paragraph variant='p1'>User not found</Paragraph>
            </ModalFormContainer>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
