import React, { Fragment } from 'react'

import styled from 'styled-components'

import { Image, Link, Paragraph } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { OrderItemFragment } from '@hooks/api'
import { lunchPremiumId, lunchSaverId } from '@pages/MealKitStore'
import { MealKitCategory, MealKitDish } from '@uctypes/api/globalTypes'

import { RatingRow } from '../controls'
import { TableCell, TableRow } from '../tables'

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  img {
    object-fit: cover;
  }
`

const MobileDescription = styled.div`
  .description{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    margin: 0;
  }
`

const MobileDownloadButton = styled.div`
  .mobile-download{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    color: ${(props): string => props.theme.colors.oranges.coral};
  }
`

const MobileRatingContainer = styled.div`
  .mobile-rating{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
  }
`

export interface OrderSubscriptionItemPastProps {
  cartItem: OrderItemFragment
  rating: number
  onNavigate: () => void
  onDownload: () => void
  onRate: (id: number) => void
}

export function OrderSubscriptionItemPast({ cartItem, rating, onNavigate, onDownload, onRate }: OrderSubscriptionItemPastProps): JSX.Element {

  const getProductCategoryName = (category: MealKitCategory): string => {

    let categoryName = category.title

    if (category.id === lunchPremiumId || category.id === lunchSaverId) {
      categoryName = 'Lunch'
    }
    return categoryName
  }

  const getProductDescription = (product: OrderItemFragment['product']): string => {
    const mealKit = product.__typename === 'MealKit' && product!
    const categoryName = getProductCategoryName((mealKit.group as MealKitDish).mealKitCategories[0])
    return `${categoryName}, Eat within ${mealKit.cookWithin} days`
  }
  const quantity = cartItem.quantity
  const arrayToLoopQuantity = []

  for (let i = 1; i <= quantity; i++) {
    arrayToLoopQuantity.push(i)
  }

  const description = getProductDescription(cartItem?.product)
  let index: number

  return (
    <Fragment>
      <DeviceContainer $desktop>
        <For each='mealKitItem' of={arrayToLoopQuantity} index='index'>
          <TableRow margin='12px 0' key={index}>
            <TableCell padding='0 16px 0 0'>
              <ImageContainer onClick={onNavigate}>
                <Image width={88} height={88} round={true} lazy={false} src={cartItem?.product?.coverImage?.desktop} />
              </ImageContainer>
            </TableCell>
            <TableCell direction='column' grow='1' justify='center' maxWidth='40%'>
              <Paragraph variant='p2' bold className='order-item-title' onClick={onNavigate}>{cartItem?.product?.name}</Paragraph>
              <Paragraph variant='p3' color='grey'>{description}</Paragraph>
            </TableCell>
            <TableCell align='center' grow='1' justify='center' maxWidth='175px'>
              <Link onClick={onDownload} color={theme.colors.oranges.coral} bold>DOWNLOAD RECIPE</Link>
            </TableCell>
            <TableCell align='center' justify='flex-end'>
              <RatingRow rating={rating} onClick={onRate} />
            </TableCell>
          </TableRow>
        </For>
      </DeviceContainer>
      <DeviceContainer $mobile $tablet>
        <For each='mealKitItem' of={arrayToLoopQuantity} index='index'>
          <TableRow margin='12px 0' key={index}>
            <TableCell padding='0 8px 0 0'>
              <ImageContainer onClick={onNavigate}>
                <Image round={true} lazy={false} src={cartItem?.product?.coverImage?.mobile} />
              </ImageContainer>
            </TableCell>
            <TableCell direction='column'>
              <Paragraph variant='p2' bold className='order-item-title' onClick={onNavigate}>{cartItem?.product?.name}</Paragraph>
              <MobileDescription>
                <Paragraph className='description' variant='p3' color='grey'>{description}</Paragraph>
              </MobileDescription>
              <MobileDownloadButton>
                <Link className='mobile-download' href='' bold color={theme.colors.oranges.coral} onClick={onDownload}>DOWNLOAD RECIPE</Link>
              </MobileDownloadButton>
              <MobileRatingContainer>
                <RatingRow className='mobile-rating' onClick={onRate} rating={rating} />
              </MobileRatingContainer>
            </TableCell>
          </TableRow>
        </For>
      </DeviceContainer>
    </Fragment>
  )
}
