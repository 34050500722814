import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/FrozenAisles')

const FrozenAisles = loadable(() => import(/* webpackChunkName: "frozen-aisles" */'@pages/FrozenAisles'), {
  resolveComponent: (components) => components.FrozenAisles,
})

export function FrozenAislesAsync(): JSX.Element {

  return <FrozenAisles fallback={<div>Loading...</div>} />

}
