import React, { ErrorInfo } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button, Heading, Paragraph, Spacer, StaticImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ${ResponsivePXValue('padding', '48px 0')}

  .not-found-header {
    ${ResponsivePXValue('font-size', { mobile: '46px', tablet: '72px', desktop: '96px' })}
  }
`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

 
  ${ResponsivePXValue('margin-bottom', '8px')}

  picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${ResponsivePXValue('width', '360px')}
  ${ResponsivePXValue('max-width', '80vw')}
`

export interface NotFoundStateProps {
  item?: string
  is404?: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

export function NotFoundState({ item = 'PRODUCT', is404 = false, error, errorInfo }: NotFoundStateProps): JSX.Element {

  const navigate = useNavigate()

  const _handleGoToHome = (): void => {
    navigate('/')
  }

  return (
    <Container>
      <Choose>
        <When condition={is404}>
          <Heading variant='h6'>{item} NOT FOUND</Heading>
          <Spacer universal='24px' />
          <Heading variant='h1' className='not-found-header'>404</Heading>
          <Spacer universal='16px' />
          <Paragraph align='center'>The {item.toLocaleLowerCase()}
             you're looking has been removed or is temporarily unavailable
          </Paragraph>
        </When>
        <When condition={!!error}>
          <ImageContainer>
            <StaticImage
              staticId='ucook-error'
              lazy={false}
              objectFit='contain'
              mobileWidth={430}
              tabletWidth={430}
              desktopWidth={768} />
          </ImageContainer>
          {/* <Spacer universal='16px' />
          <Heading variant='h1' className='not-found-header'>500</Heading> */}
          <Spacer universal='24px' />
          <Paragraph align='center'>An unexpected error has occurred. Please try again later</Paragraph>
          <Spacer universal='16px' />
          <Paragraph align='center'>{error.name}</Paragraph>
          <Spacer universal='16px' />
          <Paragraph align='center' bold>{error.message}</Paragraph>
          <Spacer universal='16px' />
          <Paragraph align='center'>{errorInfo?.componentStack?.split('\n')[1]}</Paragraph>
        </When>
        <Otherwise>
          <ImageContainer>
            <StaticImage
              staticId='error-502'
              lazy={false}
              objectFit='contain'
              mobileWidth={430}
              tabletWidth={430}
              desktopWidth={768} />
          </ImageContainer>
        </Otherwise>
      </Choose>
      <Spacer universal='32px' />
      <Button
        title='BACK TO HOME'
        color='black'
        onClick={_handleGoToHome} />
    </Container>
  )
}
