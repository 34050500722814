import React from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { SectionLoading, WineCard } from '@client/components/molecules'
import { Heading, ResponsivePXValue, Seperator, Spacer } from '@components/index'
import { useGetRelatedWinesQuery, useGetAppQuery, WineDetailsFragment, WineListFragment } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  ${ResponsivePXValue('margin-top', { mobile: '18px', tablet: '22px', desktop: '28px' })}

  .wine-card {
    ${ResponsivePXValue('margin', { mobile: '0 0 18px', tablet: '0 0 22px', desktop: ' 0 0 28px' })}
  }
  
`

export interface WineYouMightAlsoLikeProps {
  wine: WineDetailsFragment
}

export function WineYouMightAlsoLike({ wine }: WineYouMightAlsoLikeProps): JSX.Element {

  const { data, loading } = useGetRelatedWinesQuery({ variables: { ids: wine?.related?.map((relate) => relate.id) } })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()

  const relatedFilter = (relatedDish: WineListFragment) => relatedDish?.id !== wine?.id
  const wines = data?.related?.list?.filter(relatedFilter).slice(0, appData.app.deviceType === DeviceTypeEnum.TABLET ? 4 : 3) || []

  let wineItem: WineListFragment

  return (
    <Choose>
      <When condition={!(wines.length === 0)}>
        <Container>
          <Heading align='center' variant='misc' color={theme.colors.slates.bitter} >You might also like</Heading>
          <Spacer universal='8px' />
          <Seperator align='center' />
          <CardContainer>
            <For each='wineItem' of={wines || []}>
              <WineCard
                className='wine-card'
                key={wineItem?.id}
                wine={wineItem} />
            </For>
          </CardContainer>
        </Container>
      </When>
      <When condition={loading}>
        <SectionLoading />
      </When>
      <Otherwise>

      </Otherwise>
    </Choose>
  )
}
