import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Paragraph, Rule, Spacer, theme } from '@client/components'
import { ProductDetailsFragment, ProductListFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum, ProductStockStatusEnum, ProductStockTypeEnum } from '@uctypes/api/globalTypes'

const Wrapper = styled.div<{ $flexDirection: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-direction: ${(props): string => props.$flexDirection};
`
const Container = styled.div<{ $flexDirection: string, $alignItems: string }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  flex-direction: ${(props): string => props.$flexDirection};
  align-items: ${(props): string => props.$alignItems};
`

export interface LowStockContainerProps {
  product: ProductListFragment | ProductDetailsFragment
  children?: JSX.Element | JSX.Element[]
  displayRule?: boolean
  flexDirection?: string
  alignItems?: string
  className?: string
}

export function LowStockContainer({ product, children, displayRule = false, flexDirection = 'row', alignItems = 'center', className }: LowStockContainerProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const hasLowStock = product?.stockStatus === ProductStockStatusEnum.LOW_STOCK && product?.stockType === ProductStockTypeEnum.STOCK
  const lowStockMessage = isMobile ? `Low stock (${product?.stockCount})` : `Low stock, only ${product?.stockCount} remaining!`
  const wrapperFlexDirection = displayRule && isMobile ? 'column' : 'row'
  const isDesktopCard = !isMobile && !displayRule

  return (
    <Wrapper $flexDirection={wrapperFlexDirection}>
      <Container $flexDirection={flexDirection} $alignItems={alignItems} className={className}>
        <If condition={!!children}>
          {children}
        </If>
        <If condition={displayRule && !isMobile} >
          <Spacer universal='12px' />
          <Rule color='slate' />
          <Spacer universal='12px' />
        </If>
        <If condition={hasLowStock}>
          <Paragraph bold variant={isDesktopCard ? 'p3' : 'p2'} color={theme.colors.oranges.coral}> {lowStockMessage}</Paragraph>
        </If>
      </Container>
      <If condition={displayRule && isMobile} >
        <Spacer universal='12px' />
        <Rule color='slate' />
      </If>
    </Wrapper>
  )
}
