import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import update from 'react-addons-update'
import { useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'
import { APP_DEFAULT_STATE } from '@api/local'
import { Button, DeviceContainer, Heading, Link, Paragraph, Spacer } from '@client/components'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace, ModalActionContainer } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetAppQuery, useSubscribeToAcListMutation, useUserDetailsQuery } from '@hooks/api'
import { Form, Modal, ModalPositionEnum, SectionLoading, TextInput, useForm } from '@molecules/index'
import { DeviceTypeEnum, ObjectFitEnum } from '@uctypes/api/globalTypes'
import { useEvents } from '@contexts/GTMProvider'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  ${ResponsivePXValue('width', { tablet: '400px', desktop: '472px' })}
  ${ResponsivePXValue('max-width', { tablet: '400px', desktop: '472px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px 32px', desktop: '32px 40px' })}

  .email-input {
    ${ZeroSpace}
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionContainer = styled.div`
  ${ModalActionContainer()}
`
const InputContainer = styled.div`
  display: flex;
  width: 100%;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

interface NewsLetterModalFormData {
  email: string | null
}

export interface NewsLetterModalProps {
  open?: boolean
  onClose?: () => void
}

interface NewsLetterModalState {
  loading: boolean
  updating: boolean
  open: boolean
}

const DEFAULT_STATE: NewsLetterModalState = {
  loading: false,
  updating: false,
  open: false,
}

export function NewsLetterModal({ open, onClose }: NewsLetterModalProps): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const showNewsletter = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA

  const [state, setState] = useState<NewsLetterModalState>({ ...DEFAULT_STATE })
  const [addLead] = useSubscribeToAcListMutation()
  const [form] = useForm()
  const { addToast } = useToasts()
  const location = useLocation()
  const events = useEvents()
  let timeout: ReturnType<typeof setTimeout>

  const _handleClose = (): void => {

    setState((prevState) => update(prevState, {
      open: { $set: false },
    }))

    Cookies.set('ShowNewsletterModal', '1', { expires: 14 })

    onClose?.()
  }

  const _handleSubmit = async (data: NewsLetterModalFormData): Promise<void> => {

    setState((prevState) => update(prevState, {
      updating: { $set: true },
    }))
    try {
      await addLead({
        variables: {
          input: {
            emailAddress: data.email,
            listId: '152',
            lead: true,
          },
        },
      })

      addToast('You have successfully subscribed to the Newsletter!', {
        appearance: 'success',
        autoDismiss: true,
      })
      events.hasGeneratedLead(data.email, userDetailsData?.currentUser?.phone, 'popup')
      _handleClose()
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, {
      updating: { $set: false },
    }))

    Cookies.set('ShowNewsletterModal', '1')
  }

  useEffect(() => {

    if (showNewsletter) {
      const cookieCheck = Cookies.get('ShowNewsletterModal')

      timeout = setTimeout(() => {
        setState((prevState) => update(prevState, {
          open: { $set: true },
        }))
      }, 15000)

      if (cookieCheck === undefined) {
        if (userDetailsData?.currentUser?.status === 'ACTIVE') {
          setState((prevState) => update(prevState, {
            open: { $set: false },
          }))

          Cookies.set('ShowNewsletterModal', '1', { expires: 14 })

          clearTimeout(timeout)
        }
      } else {
        clearTimeout(timeout)
      }

      return () => clearTimeout(timeout)
    }
  }, [userDetailsData, appData, Cookies, timeout])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      open: { $set: open },
    }))
  }, [open])

  const shouldOpen = !location?.pathname?.includes('/meal-kit/plans')
  const openModal = shouldOpen && state.open

  return (
    <Modal
      allowScroll={true}
      backdrop={false}
      position={ModalPositionEnum.BOTTOM_LEFT}
      allowBackgroundClose={false}
      objectFit={ObjectFitEnum.COVER}
      open={openModal}
      fullscreen={false}>
      <ModalContainer>
        <Choose>
          <When condition={state.loading}>
            <SectionLoading />
          </When>
          <Otherwise>
            <ContentContainer>
              <Form
                form={form}
                onFinish={_handleSubmit}
                loading={state.loading}
                disabled={state.updating}>
                <Heading variant='h6'> Join our newsletter! </Heading>
                <Spacer universal='8px' />
                <Paragraph variant='p2'>
                  Be the first to hear about exclusive discounts and offers on your weekly UCOOK order.
                </Paragraph>
                <Spacer mobile='16px' desktop='24px' />
                <ActionContainer>
                  <InputContainer>
                    <TextInput
                      variant='email'
                      name='email'
                      label='Email address'
                      showLabel={false}
                      placeholder='Enter your email'
                      rules={[{ required: true, message: 'Please input your email address' }]}
                      className='email-input' />
                    <DeviceContainer $mobile $tablet>
                      <Spacer mobile='8px' />
                    </DeviceContainer>
                    <DeviceContainer $desktop>
                      <Spacer desktop='8px' variant='horizontal' />
                    </DeviceContainer>
                    <DeviceContainer $mobile $tablet className='full-width'>
                      <Button
                        title='JOIN'
                        color='black'
                        fullWidth
                        loading={state.updating}
                        disabled={state.updating}
                        onClick={() => form.submit()} />
                    </DeviceContainer>
                    <DeviceContainer $desktop>
                      <Button
                        title='JOIN'
                        color='black'
                        loading={state.updating}
                        disabled={state.updating}
                        onClick={() => form.submit()} />
                    </DeviceContainer>
                  </InputContainer>
                  <Spacer universal='16px' />
                  <Link variant='l2' decoration='underline' onClick={_handleClose}> No thanks </Link>
                </ActionContainer>
              </Form>
            </ContentContainer>
          </Otherwise>
        </Choose>
      </ModalContainer>
    </Modal>
  )
}
