import React, { useRef } from 'react'

import styled, { useTheme, CSS, css } from 'styled-components'

import { Heading, ResponsiveImage, Paragraph } from '@atoms/index'
import { DeviceContainer } from '@client/components'
import { getObjectFit, getPageSectionBackground, LiteBoxShadow, PageSectionPadding, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props): string => props.$background};

  ${PageSectionPadding}
 
  padding-bottom: 0 !important;
`

const ContentContainer = styled.div <{ $hasVideo: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  .text-spacing {
   ${ResponsivePXValue('width', { mobile: '90%', tablet: '85%', desktop: '80%' })}
   ${ResponsivePXValue('margin-bottom', '24px')}
  }

  ${(props): CSS => props.$hasVideo ? ResponsivePXValue('margin-bottom', { mobile: '-20%', desktop: '-15%' }) : ResponsivePXValue('margin-bottom', { mobile: '-60%', desktop: '-15%' })}
`

const VideoDimension = css`
  ${ResponsivePXValue('width', { mobile: '288px', desktop: '774px' })}
  ${ResponsivePXValue('height', { mobile: '162px', desktop: '435px' })}
`

const ImageDimension = css`
  ${ResponsivePXValue('width', { mobile: '90%', desktop: '898px' })}
  ${ResponsivePXValue('height', { mobile: '348px', desktop: '430px' })}
`

const MediaContainer = styled.div<{ $shadow: boolean, $hasContent: boolean, $hasVideo: boolean }>`
  flex-shrink: 0;
  overflow: hidden;

  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};
  transform:  ${(props): string => props.$hasContent ? 'translateY(50%)' : 'initial'};

  ${(props): CSS => props.$shadow ? LiteBoxShadow : undefined}
  ${(props): CSS => props.$hasVideo ? VideoDimension : ImageDimension}
 
  ${ResponsivePXValue('border-radius', '24px')}
`

const VideoContainer = styled.div`
    position:relative;
    padding-bottom:56.25%;
    height:0;
    overflow:hidden;

    iframe, object, embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }

  iframe .ytp-large-play-button {
    top: 45%;
  }
`

export interface PageMediaCardProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaCard({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaCardProps): JSX.Element {

  const theme = useTheme()

  const objectFit = getObjectFit(pageSection)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const background = getPageSectionBackground(pageSection.variationBackground)

  const hasContent = !!pageSection.title || !!pageSection.subTitle || !!pageSection.description
  const hasVideo = !!pageSection.video

  return (
    <Container ref={pageRef} $background={background}>
      <If condition={hasContent}>
        <ContentContainer $hasVideo={hasVideo}>
          <Heading className='text-spacing' variant='h4' color={theme.colors.slates.bitter} align='center'>
            {pageSection.title}
          </Heading>
          <Heading className='text-spacing' variant='h2' align='center'>
            {pageSection.subTitle}
          </Heading>
          <Paragraph className='text-spacing' variant='p1' align='center'>
            {pageSection.description}
          </Paragraph>
        </ContentContainer>
      </If>
      <MediaContainer $shadow={pageSection.imageShadow} $hasContent={hasContent} $hasVideo={hasVideo}>
        <Choose>
          <When condition={hasVideo}>
            <VideoContainer>
              <iframe src={pageSection.video} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </VideoContainer>
          </When>
          <Otherwise>
            <DeviceContainer $mobile $tablet>
              <ResponsiveImage image={pageSection.image} objectFit={objectFit} />
            </DeviceContainer>
            <DeviceContainer $desktop>
              <ResponsiveImage image={pageSection.largeImage} objectFit={objectFit} />
            </DeviceContainer>
          </Otherwise>
        </Choose>
      </MediaContainer>
    </Container>
  )
}
