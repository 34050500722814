import React, { useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { IconEnum, ResponsiveImage, SmallLoader, Paragraph, Spacer, Button } from '@client/components/atoms'
import { convertEnumToNum, StickyHealthyDining, StickyHealthyDiningEnum } from '@client/components/molecules'
import { Ellipsis, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { UserMenuDishFragment, useRemoveDishFromUserMenuMutation } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  max-width: 100%;
  ${ResponsivePXValue('padding', '8px 16px')}

  .text {
    ${Ellipsis()}
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 96px)', tablet: '260px', desktop: '260px' })}
    ${ResponsivePXValue('font-size', '10px')}
    ${ResponsivePXValue('line-height', '16px')}
  }

  .name {
    font-weight: 500;
    ${Ellipsis()}
    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('line-height', '18px')}
  }

  .light {
    font-weight: 400;
  }
`
const LinkContainer = styled.a`
  display: flex;
  flex: 1;
  cursor: pointer;
`
const ImageContainer = styled.div`
  position: relative;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '48px', desktop: '80px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '80px' })}
  ${ResponsivePXValue('border-radius', '8px')}
  overflow: hidden;
  background-color: ${theme.colors.whites.desertStorm};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '12px')}
  ${ResponsivePXValue('height', '12px')}
  z-index: 10;
  .remove-icon-button {
    ${ZeroSpace}
  }
`

const OverlayContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('height', '96px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

const BoldText = styled.span`
  font-weight: 500;
`

export interface MealKitCartItemProps {
  dish: UserMenuDishFragment
}

interface MealKitCartItemState {
  loading: boolean
}

const DEFAULT_STATE: MealKitCartItemState = {
  loading: false,
}

export function MealKitCartItem({ dish }: MealKitCartItemProps): JSX.Element {

  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const [state, setState] = useState<MealKitCartItemState>({ ...DEFAULT_STATE })
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const events = useEvents()
  const portionSize = dish?.portionSize

  const numberSize = 'Serves ' +String(convertEnumToNum(portionSize))
  const _handleOnRemove = async (): Promise<void> => {
    try {
      _toggleLoading(true)
      await removeItemFromCart({
        variables: {
          userMenuDishId: dish.id,
        },
      })

      const product = dish?.dish

      const logData = {
        itemName: product?.name,
        itemId: product?.id,
        itemBrand: 'UCOOK',
        itemCategory: product?.mealKitCategories.map((cat) => cat.id)?.join(', '),
        itemVariant: product?.mealKitCategories.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        itemImage: product?.coverImage?.location,
        itemStockCount: 1,
        itemChef: product?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: convertEnumToNum(portionSize),
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasRemovedFromCart(snakedData)
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _toggleLoading = (state: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: state },
    }))
  }

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    const pdpLink = `/meal-kit/${dish?.dish?.slug}`
    navigate(pdpLink)
    CartPlugin.shared().close()
  }

  return (
    <Container>
      <LinkContainer onClick={_handleLinkClick}>
        <ImageContainer>
          <If condition={dish?.dish?.discoveryHealthyDiningItem}>
            <StickyHealthyDining type={StickyHealthyDiningEnum.SMALL} />
          </If>
          <ResponsiveImage image={dish.dish.coverImage} />
        </ImageContainer>
        <Spacer variant='horizontal' universal='8px' />
        <BodyContainer>
          <Spacer universal='4px' />
          <Paragraph variant='p2' className='text name'>{dish.dish.name}</Paragraph>
          <Spacer universal='4px' />
          <Paragraph variant='p2' className='text light' color={theme.colors.greys.liteCodGrey}>{dish.dish.subTitle}</Paragraph>
          <Spacer universal='4px' />
          <Paragraph variant='p2' className='text light' color={theme.colors.greys.liteCodGrey}>{numberSize}</Paragraph>
          <Spacer universal='4px' />
          <Paragraph variant='p3' className='text'>
            <BoldText> Eat within </BoldText>
            {dish.dish.cookWithin} days
          </Paragraph>
        </BodyContainer>
        <Spacer universal='8px' />
      </LinkContainer>
      <IconContainer>
        <Button
          color='transparent'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={theme.colors.greys.liteCodGrey}
          size='small'
          onClick={_handleOnRemove} />
      </IconContainer>
      <If condition={state.loading}>
        <OverlayContainer>
          <LoaderBox>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoaderBox>
        </OverlayContainer>
      </If>
    </Container>
  )

}
