import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/ReferralPolicy')

const ReferralPolicy = loadable(() => import(/* webpackChunkName: "referral-policy" */'@pages/ReferralPolicy'), {
  resolveComponent: (components) => components.ReferralPolicy,
})

export function ReferralPolicyAsync(): JSX.Element {

  return <ReferralPolicy fallback={<div>Loading...</div>} />

}
