import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Paragraph, Spacer } from '@client/components'
import { Heading, Button } from '@client/components/atoms'
import { ResponsivePXValue, ZeroSpace } from '@client/components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '32px' })}

  .header-text {
    ${ResponsivePXValue('line-height', '24px')}
    ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '16px', desktop: '16px' })}
    ${ZeroSpace}
  }

  .body-text {
    ${ResponsivePXValue('line-height', '26px')}
    ${ZeroSpace}
  }

  .shop-button {
    ${ZeroSpace}
    ${ResponsivePXValue('width', '164px')}
  }
`

export function VitalityHealthyDining(): JSX.Element {

  const navigate = useNavigate()

  const _handleMealKitsClicked = (): void => {
    navigate('/meal-kit')
  }

  return (
    <Container>
      <Heading variant='h5' className='header-text' >{'Your HealthyDinning Benefit is active!'}</Heading>
      <Spacer variant='horizontal' universal='16px' />
      <Paragraph variant='p1' className='body-text' >{'Your Vitality HealthyDining account is active. You can now get rewarded with up to 25% back when you choose healthier convenience or ready-made meals with Vitality HealthyDining.'}</Paragraph>
      <Spacer variant='horizontal' universal='16px' />
      <Button
        color='orange'
        title='Shop meal kits'
        className='shop-button'
        onClick={_handleMealKitsClicked} />
    </Container>
  )
}
