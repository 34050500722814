import React, { useState } from 'react'

import styled from 'styled-components'

import { SmallLoader, Paragraph, Button } from '@client/components'
import { Form, Modal, useForm, RadioButton, RadioButtonOption, RadioButtonProps } from '@client/components/molecules'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { MealKitDishDetailsFragment, UserCartDocument, useUpdateUserSubscription, useUserCartQuery } from '@hooks/api'
import { NumberOfPortionsEnum, PortionSizeEnum } from '@uctypes/api/globalTypes'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
  ${ResponsivePXValue('padding', { mobile: '16px 24px', tablet: '24px 32px', desktop: '32px 40px 40px 40px' })}
  div{
    margin-top: 0;
  }
`
const ModalRadioContainer = styled.div`
  ${ResponsivePXValue('padding-top', '8px')}
  ${ResponsiveProperty('width', { mobile: '100%', tablet: 'auto', desktop: 'auto' })}

  .radio-choice {
    ${ZeroSpace}
  }
`
const ModalButtonContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding-top', { mobile: '24px', tablet: '24px', desktop: '32px' })}
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '382px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`

export enum MealkitModalEnum {
  NONE = 'NONE',
  SERVING_SIZE = 'SERVING_SIZE',
  RECIPE_CARD = 'RECIPE_CARD',
  ADD_TO_CART = 'ADD_TO_CART',
  COOK_WITHIN = 'COOK_WITHIN',
}

export interface MealkitModalProps {
  activeModal: MealkitModalEnum
  onClose: () => void
  onSubmit?: () => void
  dish?: MealKitDishDetailsFragment
}

interface MealkitModalState {
  loading: boolean
  portionSize: PortionSizeEnum
}

const DEFAULT_STATE: MealkitModalState = {
  loading: false,
  portionSize: PortionSizeEnum.SERVES_2,
}

export function MealkitModal({ activeModal, onClose, onSubmit, dish }: MealkitModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<MealkitModalState>({ ...DEFAULT_STATE })
  const [updateSubscription] = useUpdateUserSubscription()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const modalText = {
    servingSize: 'How many people are you cooking for?',
    downloadRecipeCard: 'How many people are you cooking for?',
    cookWithin: 'Please note that these dishes have similar eat within days. For the freshest experience we recommend you swop out some dishes, but if you are happy, confirm your selection below.',
    recipeLimit: 'It looks like you have reached your download limit. Please login or sign up to download more recipes. ',
  }

  const loading = userCartLoading || state.loading

  const options = [{
    title: 'COOKING FOR 1',
    value: NumberOfPortionsEnum.SERVES_1,
  }, {
    title: 'COOKING FOR 2',
    value: NumberOfPortionsEnum.SERVES_2,
  },
  {
    title: 'COOKING FOR 3',
    value: NumberOfPortionsEnum.SERVES_3,
  }, {
    title: 'COOKING FOR 4',
    value: NumberOfPortionsEnum.SERVES_4,
  }]
  let title = ''
  switch (activeModal) {
    case MealkitModalEnum.RECIPE_CARD:
      title = 'Download Recipe'
      break
    case MealkitModalEnum.SERVING_SIZE:
      title = 'Add To Cart'
      break
  }

  const servingSizeRadioOptions = () => {
    return options.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
      }
    }) as unknown as RadioButtonOption[]
  }

  const servingSizeOptionsRadioProps: RadioButtonProps = {
    className: 'radio-container',
    name: 'portionSize',
    outline: false,
    options: [...servingSizeRadioOptions()],
    loading,
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  const _downloadFile = (href: string): void => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = href
    a.download = 'file.pdf'
    a.target = '_blank'
    a.click()
    if (config.isBrowser()) {
      window.URL.revokeObjectURL(a.href)
    }
    document.body.removeChild(a)
  }

  const _handleServingSizeSelectSubmit = async (data: { portionSize: NumberOfPortionsEnum }) => {
    setState(prevState => ({ ...prevState, loading: true }))
    if (activeModal === MealkitModalEnum.SERVING_SIZE) {
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: {
            numberOfPortions: data.portionSize,
            updateMenu: true,
          },
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      onSubmit()
    } else {
      const product = dish?.products.find(product => { return product.portionSize === state.portionSize })
      if (product) {
        _downloadFile(product.recipeCard.location)
      }
    }
    setState(prevState => ({ ...prevState, loading: false }))
  }

  return (
    <Modal onClose={onClose} title={title} open={activeModal !== MealkitModalEnum.NONE} showCloseButton={!loading}>
      <Choose>
        <When condition={loading}>
          <LoadingContainer>
            <LoaderBox>
              <SmallLoader color={theme.colors.oranges.coral} />
            </LoaderBox>
          </LoadingContainer>
        </When>
        <When condition={activeModal === MealkitModalEnum.RECIPE_CARD || activeModal === MealkitModalEnum.SERVING_SIZE}>
          <Form form={form} onFinish={_handleServingSizeSelectSubmit} >
            <ModalContainer>
              <Paragraph variant='p2' align='center'>{activeModal === MealkitModalEnum.RECIPE_CARD ? modalText.downloadRecipeCard : modalText.servingSize}</Paragraph>
              <ModalRadioContainer>
                <RadioButton {...servingSizeOptionsRadioProps} />
              </ModalRadioContainer>
              <ModalButtonContainer>
                <Button
                  fluid
                  title={activeModal === MealkitModalEnum.RECIPE_CARD ? 'Download Recipe' : 'Add To Cart'}
                  onClick={() => form.submit()} />
              </ModalButtonContainer>
            </ModalContainer>
          </Form>
        </When>
      </Choose>
    </Modal>
  )
}
