import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph, StaticImage, Spacer } from '@atoms/index'
import { ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'

const ContentContainer = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${ResponsivePXValue('padding-bottom', '16px')}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '100%', desktop: '100%' })}
  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '16px', desktop: '16px' })}
`

const HeaderContainer = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props): string => props?.$backgroundColor ? props.$backgroundColor : props.theme.colors.whites.alabaster};
  
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '32px' })}

  div{
    ${ZeroSpace}
  }
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '169px', tablet: '204px', desktop: '204px' })}
  width: 100%;
`

export enum ContentCardHeaderEnum {
  IMAGE = 'IMAGE',
  STANDARD = 'STANDARD',
  CENTER = 'CENTER',
  TITLE_ONLY = 'TITLE_ONLY',
  NONE = 'NONE',
}

export interface ContentCardProps {
  headerType: ContentCardHeaderEnum
  title?: string
  description?: string
  image?: string
  className?: string
  headerClassName?: string
  children: JSX.Element | JSX.Element[]
}

export function ContentCard({ title = '', description = '', image, className, headerClassName, headerType, children }: ContentCardProps): JSX.Element {

  return (
    <ContentContainer className={className}>
      <Choose>
        <When condition={headerType === ContentCardHeaderEnum.CENTER}>
          <HeaderContainer className={headerClassName} $backgroundColor={theme.colors.whites.pureWhite}>
            <Heading align='center' variant='h5'>{title}</Heading>
            <Spacer universal='16px' />
            <Paragraph align='center' variant='p1'>{description}</Paragraph>
          </HeaderContainer>
        </When>
        <When condition={headerType === ContentCardHeaderEnum.STANDARD}>
          <HeaderContainer className={headerClassName}>
            <Heading align='left' variant='h5'>{title}</Heading>
            <Spacer universal='16px' />
            <Paragraph variant='p1'>{description}</Paragraph>
          </HeaderContainer>
        </When>
        <When condition={headerType === ContentCardHeaderEnum.TITLE_ONLY}>
          <HeaderContainer className={headerClassName}>
            <Heading align='left' variant='h4'>{title}</Heading>
          </HeaderContainer>
        </When>
        <When condition={headerType === ContentCardHeaderEnum.IMAGE}>
          <ImageContainer>
            <StaticImage
              staticId={image}
              objectFit='contain'
              mobileWidth={288}
              mobileHeight={169}
              tabletHeight={288}
              desktopHeight={204}
              tabletWidth={976}
              desktopWidth={856} />
          </ImageContainer>
        </When>
      </Choose>
      {children}
    </ContentContainer>
  )

}
