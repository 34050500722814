import React from 'react'

import { Pill } from '@atoms/index'

export interface FilterPillProps {
  id?: string
  sectionId: string
  title: string
  backgroundColor?: string
  textColor?: string
  className?: string
  iconClassName?: string
  iconColor?: string
  closable?: boolean
  disabled?: boolean
  onRemoveFilter?: (sectionId: string, selectedValue: string) => void
  onClick?: () => void
  onClose?: () => void
}

export function FilterPill(props: FilterPillProps): JSX.Element {

  const { id, sectionId, onRemoveFilter, title, closable } = props

  const _handleClick = () => {
    if (onRemoveFilter && !closable) {
      onRemoveFilter(sectionId, id || title)
    }
  }

  const _handleClose = () => {
    if (onRemoveFilter && closable) {
      onRemoveFilter(sectionId, id || title)
    }
  }

  return (
    <Pill
      {...props}
      onClick={_handleClick}
      onClose={_handleClose}
    />
  )
}
