import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { FieldData, Paragraph } from '@client/components'
import { Loader, Button, Link } from '@client/components/atoms'
import { Form, Modal, RatingRow, TextAreaInput, useForm, RadioButtonOption, RadioButtonProps, RadioButton } from '@client/components/molecules'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { OrdersQueryDocument, useAddOrderItemRatingMutation, useUpdateOrderItemRatingMutation, useUserDetailsQuery } from '@hooks/api'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '24px 16px 40px', tablet: '32px 40px 40px', desktop: '32px 40px 40px' })}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
`
const TextContainer = styled.div`
  
`

const RatingContainer = styled.div`
  align-self: center;
  ${ResponsivePXValue('padding', { mobile: '24px 16px 40px', tablet: '32px 40px 40px', desktop: '8px 0px 24px' })}
`
const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`
const Option = styled.div<{ $selected: boolean }>`
  border: 1px solid;
  border-color: ${(props): string => props.$selected ? props.theme.colors.greys.liteCodGrey : props.theme.colors.slates.bitter};
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('margin-bottom', '8px')}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('padding', '8px 16px')}
  div{
    ${ZeroSpace}
  }
`
const ButtonsContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding-top', '8px')}
`

const TextAreaContainer = styled.div`
  ${ResponsivePXValue('padding-bottom', '8px')}
`
const RadioContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', '16px')}
`
enum ProductRatingModalStateEnum {
  QUESTIONS = 'QUESTIONS',
  TELL_US_MORE = 'TELL_US_MORE',
  ON_DEMAND = 'ON_DEMAND'
}
export interface ProductRatingModalProps {
  rating: number
  open: boolean
  productType: ProductRangeEnum
  product: any
  orderItemId: string
  orderId: string
  ratingId?: string
  onSuccess: () => void
  onCancel: () => void
}

interface ProductRatingModalState {
  rating: number
  selectedOptions: string[]
  text: string
  title: string
  ratingSubmitted: boolean
  formState: ProductRatingModalStateEnum
  tellMoreData: string
  recommend: boolean
  hasSelectedOther: boolean
}

const DEFAULT_STATE: ProductRatingModalState = {
  rating: 3,
  selectedOptions: [],
  title: '',
  text: '',
  ratingSubmitted: false,
  formState: ProductRatingModalStateEnum.QUESTIONS,
  tellMoreData: '',
  recommend: true,
  hasSelectedOther: false,
}

const positiveOptions = ['Tasted great', 'Good quality ingredients', 'Generous portions', 'Clear instructions', 'Other']
const negativeOptions = ['Taste', 'Ingredient quality', 'Portion size', 'Unclear or incorrect instructions', 'Other']
export function ProductRatingModal({ rating, open, productType, product, orderItemId, ratingId, onSuccess, onCancel }: ProductRatingModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<ProductRatingModalState>({ ...DEFAULT_STATE, rating })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addRating] = useAddOrderItemRatingMutation()
  const [updateRating] = useUpdateOrderItemRatingMutation()
  const [form] = useForm()
  const toasts = useToasts()

  const handleRatingUpdate = (newRating: number) => {
    const title = newRating > 2 ? 'WE’RE GLAD YOU LIKED IT' : 'WE’RE SORRY YOU DIDN’T LIKE IT'
    let text = ''
    if (productType === ProductRangeEnum.MEAL_KIT) {
      text = newRating > 2
        ? `What did you like about ${SiteHelper.toTitleCase(product?.name)}? You can select multiple reasons. `
        : `Help us improve on the quality of our dishes. What didn't you like about ${SiteHelper.toTitleCase(product?.name)}?`
    } else {
      text = `Would you recommend ${SiteHelper.toTitleCase(product?.name)} to a friend?`
    }
    setState((prevState) => update(prevState, {
      text: { $set: text },
      title: { $set: title },
      rating: { $set: newRating },
    }))
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'tellMoreData') {
          setState((prevState) => update(prevState, {
            tellMoreData: { $set: field.value },
          }))
        }
        if (name === 'recommend') {
          setState((prevState) => update(prevState, {
            recommend: { $set: field.value },
          }))
        }
      })
    })
  }

  const handleSubmit = async () => {
    if (state.selectedOptions.filter((selectedOption) => { return (selectedOption === 'Other') }).length > 0 && !state.hasSelectedOther) {
      setState((prevState) => update(prevState, {
        hasSelectedOther: { $set: true },
      }))
      onNextClick()
    } else {
      try {
        const refetchQueries = [{
          query: OrdersQueryDocument,
          variables: {
            filters: {
              users: [userDetailsData?.currentUser.id],
              status: ['COMPLETE', 'PROCESSING'],
            },
            limit: 10,
          },
        }]
        if (ratingId) {
          await updateRating({
            variables: {
              id: ratingId,
              input: {
                feedback: state.tellMoreData,
                selectedOptions: state.selectedOptions,
                value: state.rating,
              },
            },
            refetchQueries,
            awaitRefetchQueries: true,
          })
        } else {
          await addRating({
            variables: {
              input: {
                user: userDetailsData?.currentUser?.id,
                feedback: state.tellMoreData,
                orderItem: orderItemId,
                selectedOptions: state.selectedOptions,
                value: state.rating,
              },
            },
            refetchQueries,
            awaitRefetchQueries: true,
          })
        }

        onSuccess()
      } catch (e) {
        toasts.addToast('An error has ocurred, please try again later', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  const onNextClick = () => {
    const text = state.rating > 2
      ? `We would love to know a little more about what you loved. What did you like about the ${SiteHelper.toTitleCase(product?.name)}? `
      : `We would love to know a little more about what you disliked. Please tell us what was wrong with the ${SiteHelper.toTitleCase(product?.name)}.`
    setState((prevState) => update(prevState, {
      formState: { $set: ProductRatingModalStateEnum.TELL_US_MORE },
      text: { $set: text },
    }))
  }

  const options = [{
    title: 'YES',
    value: true,
  }, {
    title: 'NO',
    value: false,
  }]

  const recommendRadioOptions = () => {
    return options.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
      }
    }) as unknown as RadioButtonOption[]
  }

  const recommendOptionsRadioProps: RadioButtonProps = {
    className: 'radio-container',
    name: 'recommend',
    outline: false,
    options: [...recommendRadioOptions()],
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  useEffect(() => {
    handleRatingUpdate(rating)
  }, [rating])

  useEffect(() => {
    if (product?.productRange === ProductRangeEnum.MEAL_KIT) {
      const title = rating > 2 ? 'WE’RE GLAD YOU LIKED IT' : 'WE’RE SORRY YOU DIDN’T LIKE IT'
      const text = rating > 2
        ? `What did you like about ${SiteHelper.toTitleCase(product?.name)}? You can select multiple reasons. `
        : `Help us improve on the quality of our dishes. What didn't you like about ${SiteHelper.toTitleCase(product?.name)}?`
      setState({ ...state, formState: ProductRatingModalStateEnum.QUESTIONS, title, text })
    } else {
      const text = `Would you recommend ${SiteHelper.toTitleCase(product?.name)} to a friend?`
      setState({ ...state, formState: ProductRatingModalStateEnum.ON_DEMAND, title: 'RATE THIS PRODUCT', text })
    }
  }, [product])

  const handleOptionClicked = (option: string) => {
    if (state.selectedOptions.includes(option)) {
      const selectedOptions = state.selectedOptions.filter((selectedOption) => { return (selectedOption !== option) })
      setState(update(state, { selectedOptions: { $set: selectedOptions } }))
    } else {
      const selectedOptions = [...state.selectedOptions, option]
      setState(update(state, { selectedOptions: { $set: selectedOptions } }))
    }
  }

  let option: string
  let index: number
  const loading = userDetailsLoading
  return (
    <Modal title={state.title} open={open} onClose={onCancel}>
      <Container>
        <Choose>
          <When condition={loading}>
            <Loader noShadow={true}/>
          </When>
          <When condition={state.formState === ProductRatingModalStateEnum.QUESTIONS}>
            <TextContainer>
              <Paragraph align='center' variant='p1'>{state.text}</Paragraph>
            </TextContainer>
            <RatingContainer>
              <RatingRow rating={state.rating} onClick={(rating) => { handleRatingUpdate(rating) }} />
            </RatingContainer>
            <OptionsContainer>
              <For each='option' of={state.rating > 2 ? positiveOptions : negativeOptions} index='index'>
                <Option
                  key={index}
                  $selected={state.selectedOptions.includes(option)}
                  onClick={() => { handleOptionClicked(option) }}>
                  <Paragraph
                    align='center'
                    cursor='pointer'
                    color={state.selectedOptions.includes(option) ? theme.colors.greys.liteCodGrey : theme.colors.slates.bitter}
                    variant={state.selectedOptions.includes(option) ? 'p2' : 'p1'}>
                    {option}
                  </Paragraph>
                </Option>
              </For>
            </OptionsContainer>
            <ButtonsContainer>
              <Button
                onClick={handleSubmit}
                disabled={state.selectedOptions.length === 0}
                title={state.selectedOptions.length === 0 ? 'SELECT AT LEAST ONE ANSWER' : 'SUBMIT FEEDBACK'} />
            </ButtonsContainer>
            <ButtonsContainer>
              <Link
                onClick={onNextClick}> {state.selectedOptions.length === 0 ? 'SKIP' : 'TELL US MORE'}</Link>
            </ButtonsContainer>

          </When>
          <When condition={state.formState === ProductRatingModalStateEnum.TELL_US_MORE}>
            <TextContainer>
              <Paragraph align='center' variant='p1'>{state.text}</Paragraph>
            </TextContainer>
            <TextAreaContainer>
              <Form
                form={form}
                onFieldsChange={_handleChange}>
                <TextAreaInput name='tellMoreData' rules={[{ required: true, message: 'Please enter some feedback' }]} label='Feedback' placeholder='Enter your feedback...' />
              </Form>
            </TextAreaContainer>
            <ButtonsContainer>
              <Button onClick={handleSubmit} title='SUBMIT FEEDBACK' />
            </ButtonsContainer>
            <ButtonsContainer>
              <Link onClick={() => { setState({ ...state, formState: ProductRatingModalStateEnum.QUESTIONS }) }}>BACK</Link>
            </ButtonsContainer>
          </When>
          <Otherwise>
            <RatingContainer>
              <RatingRow rating={state.rating} onClick={(rating) => { handleRatingUpdate(rating) }} />
            </RatingContainer>
            <TextContainer>
              <Paragraph align='center' variant='p1'>{state.text}</Paragraph>
            </TextContainer>
            <RadioContainer>
              <Form
                form={form}
                onFieldsChange={_handleChange}>
                <RadioButton {...recommendOptionsRadioProps} />
              </Form>
            </RadioContainer>
            <TextAreaContainer>
              <Form
                form={form}
                onFieldsChange={_handleChange}>
                <TextAreaInput name='tellMoreData' rules={[{ required: true, message: 'Please enter some feedback' }]} label='Feedback' placeholder='Enter your feedback...' />
              </Form>
            </TextAreaContainer>
            <ButtonsContainer>
              <Button onClick={handleSubmit} title='SUBMIT FEEDBACK' />
            </ButtonsContainer>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )

}
