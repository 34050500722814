import React, { useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator } from '@atoms/index'
import { ResponsivePXValue, Spacer } from '@client/components'
import { MarketProductDetailsFragment } from '@hooks/api'
import { TabPane, Tabs, Accordion, Tab, Form, useForm, TextListProps, TextList, BiographyProps, Biography } from '@molecules/index'
import { ObjectFitEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  .radio-container {
    justify-content: center;
    display: flex;

    ${ResponsivePXValue('padding-top', '24px')}
  }

`
export enum MarketTabEnum {
  ABOUT_THE_BRAND = 'About the brand',
  PRODUCT_DETAILS = 'Product details'
}

export interface MoreAboutMarketDishProps {
  dish: MarketProductDetailsFragment
}
interface TabsState {
  activeTab: string
  servingSize: string
}

const DEFAULT_STATE: TabsState = {
  activeTab: MarketTabEnum.PRODUCT_DETAILS,
  servingSize: '',
}

export function MoreAboutMarketDish({ dish }: MoreAboutMarketDishProps): JSX.Element {

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const [form] = useForm()
  const tags = dish?.tags || []

  const getFormattedText = (text: string): string | string[] => {
    if (text.indexOf('|') === -1) return text
    return text.split('|').map(item => item.trim())
  }

  const productDetails: TextListProps = {
    items: [{
      title: 'Description',
      subTitle: getFormattedText(dish?.description),
    }, {
      title: 'Ingredients',
      subTitle: dish?.packageIngredients?.join(', '),
    }, {
      title: 'Product Claims',
      subTitle: tags.length > 0 ? tags?.map(tag => tag.title)?.join(', ') : 'None',
    }, {
      title: 'Storage instructions',
      subTitle: dish?.storageInstructions,
    }],
  }

  const brandDetails: BiographyProps = {
    image: dish?.brand?.image,
    name: dish?.brand?.name,
    about: dish?.brand?.description,
    objectFit: ObjectFitEnum.CONTAIN,
  }

  return (
    <Form
      form={form}>
      <Container>
        <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>More about this Product</Heading>
        <Spacer universal='8px' />
        <Seperator align='center' />
        <DeviceContainer $mobile>
          <Accordion
            activeTab={state.activeTab}
            backgroundColor={theme.colors.whites.pureWhite}
            onTabChange={(activeTab: MarketTabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
            <Tab tab={MarketTabEnum.PRODUCT_DETAILS} title={MarketTabEnum.PRODUCT_DETAILS}>
              <TextList {...productDetails} />
            </Tab>
            <If condition={!!brandDetails?.name}>
              <Tab tab={MarketTabEnum.ABOUT_THE_BRAND} title={MarketTabEnum.ABOUT_THE_BRAND}>
                <Biography {...brandDetails} />
              </Tab>
            </If>
          </Accordion>
        </DeviceContainer>
        <DeviceContainer $tablet $desktop>
          <Tabs
            activeTab={state.activeTab}
            backgroundColor={theme.colors.whites.pureWhite}
            onTabChange={(activeTab: MarketTabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
            <TabPane tab={MarketTabEnum.PRODUCT_DETAILS} title={MarketTabEnum.PRODUCT_DETAILS}>
              <TextList {...productDetails} />
            </TabPane>
            <If condition={!!brandDetails?.name}>
              <TabPane tab={MarketTabEnum.ABOUT_THE_BRAND} title={MarketTabEnum.ABOUT_THE_BRAND}>
                <Biography {...brandDetails} />
              </TabPane>
            </If>
          </Tabs>
        </DeviceContainer>
      </Container>
    </Form>
  )
}
