import React from 'react'

import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage, Loader, Button } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SupplierFragment, useSupplierQuery } from '@hooks/api'
import { InfoBlock, SupplierCardGroup } from '@molecules/index'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

import { DeviceContainer } from '../utility/DeviceContainer'

const ImageContainer = styled.div`
  left: 0;
  width: 100%;
  top: 0;

  ${ResponsivePXValue('height', { mobile: '320px', tablet: '460px', desktop: '432px' })}
  
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
  ${ResponsivePXValue('padding', '24px 0 36px')}

`

const seo = (supplier?: SupplierFragment, enums?: { title: string, value: string }[]): SearchEngineOptimizationParams => ({
  name: 'UCOOK Supplier',
  title: `${supplier?.name ?? 'Awesome'} ${enums?.find((item) => item.value === supplier?.productOfferings)?.title ?? ''} | UCOOK`,
  meta: [{
    name: 'description',
    content: `${supplier?.shortDescription?.length > 155 ? supplier?.shortDescription?.substring(0, 155) ?? 'Amazing' : supplier?.shortDescription ?? 'Amazing'}`,
  }, {
    name: 'keywords',
    content: `Buy ${supplier?.name ?? 'Awesome'},Online ${supplier?.name ?? 'Awesome'},Deliver ${supplier?.name ?? 'Awesome'}`,
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
})

export function SuppliersStory(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()
  const { data, loading } = useSupplierQuery({ variables: { slug } })
  const navigate = useNavigate()

  const supplier: SupplierFragment = data?.supplier
  const banner = supplier?.bannerImage

  const _handleBackClick = (): void => {
    navigate('/meal-kit/suppliers')
  }

  const _handleShowDetails = (slug: string): void => {
    navigate(`/meal-kit/suppliers/${slug}`)
  }

  return (
    <>
      <SearchEngineOptimization seo={seo(supplier)} />
      <Choose>
        <When condition={loading}>
          <Loader noShadow />
        </When>
        <When condition={!!supplier}>
          <If condition={!!banner}>
            <ImageContainer>
              <DeviceContainer $mobile>
                <ResponsiveImage image={banner} />
              </DeviceContainer>
              <DeviceContainer $desktop $tablet>
                <ResponsiveImage image={banner} />
              </DeviceContainer>
            </ImageContainer>
          </If>
          <InfoBlock title={supplier.name} subTitle={supplier.subtitle} text={supplier.description.concat()} lineBreak={true} />
          <SupplierCardGroup identifier={supplier.slug} onShowDetails={_handleShowDetails} />
          <ButtonContainer>
            <Button title='BACK TO SUPPLIERS' href='/meal-kit/suppliers' onClick={_handleBackClick} />
          </ButtonContainer>
        </When>
      </Choose>
    </>
  )

}
