import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftDessertStore')

const CraftDessertStore = loadable(() => import(/* webpackChunkName: "craft-dessert-store" */'@pages/CraftDessertStore'), {
  resolveComponent: (components) => components.CraftDessertStore,
})

export function CraftDessertStoreAsync(): JSX.Element {

  return <CraftDessertStore fallback={<div>Loading...</div>} />

}
