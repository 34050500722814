import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Badge, Button, ButtonContainer, IconEnum, LoaderContainer, MediumBoxShadow, Paragraph, ResponsivePXValue, SmallLoader, StaticImage, ZeroSpace } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetAppQuery, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'

const Bar = styled.div<{ $standard: boolean }>`
  position: absolute;
  bottom: 0;
  justify-content: space-evenly;
  width: 100%;
  z-index: 10;
  background-color: ${(props): string => props.$standard ? props.theme.colors.whites.pureWhite : props.theme.colors.whites.alabaster};

  ${(props): CSS => {
    if (props.$standard) {
      return MediumBoxShadow
    }
  }}

  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'flex', desktop: 'none' })}
  ${ResponsivePXValue('height', '56px')}
  ${ResponsivePXValue('padding', '8px 0px')}

`

const ImageContainer = styled.a`
  display: inline-block;
  position: relative;
  ${ResponsivePXValue('height', { mobile: '24px' })}
  ${ResponsivePXValue('width', { mobile: '24px' })}
  ${ResponsivePXValue('padding-top', '2px')}
  picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
  }
`
const NavigationButtonContainer = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  .menu-button {
    ${ZeroSpace}
  }
`

interface ApplicationNavigationBarProps {
  showLogo?: boolean
  icon?: IconEnum
  variant?: 'standard' | 'menu'
  onMenuClick?: () => void
  className?: string
}

export function ApplicationNavigationBar({ variant = 'standard' }: ApplicationNavigationBarProps): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const navigate = useNavigate()
  const discountCode = appData?.app?.urlDiscount

  const theme = useTheme()

  const _handleMenuClick = (): void => {
    NavigationPlugin.shared().toggleMainMenuApplication()
  }

  const _handleProfileClick = (): void => {
    if (userDetailsData?.currentUser?.__typename === 'GuestUser' && !userDetailsLoading) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else {
      NavigationPlugin.shared().toggleUserMenuApplication()
    }
  }

  const _handleCartClick = (): void => {
    if (cartData?.currentUser?.activeCart?.open) {
      CartPlugin.shared().close(true)
    } else {
      CartPlugin.shared().open(true)
    }
  }

  const _handleLogoClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    NavigationPlugin.shared().closeAllMenus()
    navigate('/')
  }
  useEffect(() => {
    if (discountCode && discountCode?.length) {
      _handleCartClick()
    }
  }, [appData?.app?.urlDiscount])

  return (
    <Bar $standard={variant === 'standard'}>
      <NavigationButtonContainer>

        <ImageContainer onClick={_handleLogoClicked}>
          <StaticImage
            staticId='Home'
            lazy={false}
            objectFit='contain'
            mobileWidth={768}
            tabletWidth={1024}
            desktopWidth={1600} />
        </ImageContainer>

        <Paragraph
          variant='p2'
          color={theme.colors.slates.bitter}
          align='center'
          bold={false}>Home
        </Paragraph>

      </NavigationButtonContainer>
      <NavigationButtonContainer>
        <Button
          color='transparent'
          icon={IconEnum.MENU}
          iconColor={theme.colors.slates.bitter}
          size='small'
          onClick={_handleMenuClick} />
        <Paragraph
          variant='p2'
          color={theme.colors.slates.bitter}
          align='center'
          bold={false}>Explore
        </Paragraph>
      </NavigationButtonContainer>
      <NavigationButtonContainer>
        <Button
          color='transparent'
          icon={IconEnum.LOGO_WHATSAPP}
          iconColor={theme.colors.slates.bitter}
          size='small'
          href='https://wa.me/27720201555?text=Hello!%20UCOOK%20,%20'
          isCallNumber={true}
        />
        <Paragraph
          variant='p2'
          color={theme.colors.slates.bitter}
          align='center'
          bold={false}>Contact
        </Paragraph>
      </NavigationButtonContainer>
      <NavigationButtonContainer>
        <Button
          color='transparent'
          icon={IconEnum.PERSON_OUTLINE}
          iconColor={theme.colors.slates.bitter}
          size='small'
          onClick={_handleProfileClick} />
        <Paragraph
          variant='p2'
          color={theme.colors.slates.bitter}
          align='center'
          bold={false}>Account
        </Paragraph>
      </NavigationButtonContainer>
      <NavigationButtonContainer>
        <Choose>
          <When condition={cartLoading}>
            <ButtonContainer>
              <LoaderContainer>
                <SmallLoader color={theme.colors.oranges.coral} />
              </LoaderContainer>
            </ButtonContainer>
          </When>
          <Otherwise>
            <ButtonContainer onClick={_handleCartClick}>
              <Badge
                count={cartData?.currentUser?.activeCart?.totalItems || 0}
                offsetY={-10}
                offsetX={20}
                noShadow>
                <Button
                  color='transparent'
                  icon={IconEnum.CART_OUTLINE}
                  iconColor={theme.colors.slates.bitter}
                  size='small' />
                <Paragraph
                  variant='p2'
                  color={theme.colors.slates.bitter}
                  align='center'
                  bold={false}>Cart
                </Paragraph>
              </Badge>
            </ButtonContainer>
          </Otherwise>
        </Choose>
      </NavigationButtonContainer>
    </Bar>
  )

}
