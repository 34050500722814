import React from 'react'

import { ApolloError } from '@apollo/client'

import { Heading, Spacer } from '@atoms/index'
import { NotFoundState, SectionLoading } from '@molecules/index'

export interface DetailPageWrapperProps {
  pdp: JSX.Element
  error: ApolloError
  loading: boolean
  seo?: JSX.Element
}

export function DetailPageWrapper({ pdp, error, loading, seo }: DetailPageWrapperProps): JSX.Element {

  let notFound = false
  let otherError = ''

  if (error) {
    const isArrayOfErrors = Array.isArray(error)
    const has404Error = isArrayOfErrors
      ? error.some((er) => er?.extensions?.code === '404')
      : error.graphQLErrors.some((er) => er?.extensions?.code === '404')

    if (has404Error) {
      notFound = true
    }
    otherError = error?.message
  }

  return (
    <>
      <If condition={!!seo}>
        {seo}
      </If>
      <Choose>
        <When condition={loading}>
          <SectionLoading height='100vh' />
        </When>
        <When condition={notFound}>
          <NotFoundState is404 />
        </When>
        <When condition={!!otherError}>
          <>
            <Spacer universal='32px' variant='horizontal' />
            <Spacer universal='32px' />
            <Heading variant='h1'>{otherError}</Heading>
            <Spacer universal='32px' />
          </>
        </When>
        <Otherwise>
          <If condition={!!pdp}>
            {pdp}
          </If>
        </Otherwise>
      </Choose>
    </>
  )
}
