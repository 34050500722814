import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS, useTheme } from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Button, Heading, Paragraph, getPageSectionButtonColor, Spacer } from '@client/components'
import { FlexDeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PageWidth, ResponsivePXValue, SidePagePadding, StrongBoxShadow } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div<{ $width: string, $height: string }>`
  position: relative;
  display: flex;
  align-items: center;
  max-height: 500px;

  .slider-list, .slider-slide {
    height: 100% !important;
  }

  ${SidePagePadding}

  .device-container {
    height: 100%;
  }

  ${(props): CSS => {
    if (props?.$height) {
      return `
        ${ResponsivePXValue('height', props?.$height)}
        ${ResponsivePXValue('margin', '32px 0')}
      `
    }
    return `
      ${ResponsivePXValue('height', { mobile: '200px', tablet: '92px', desktop: '140px' })}
      ${ResponsivePXValue('margin', { mobile: '16px 0', tablet: '16px 0', desktop: '24px 0' })}
    `
  }}

`

const AbsoluteContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${PageWidth}

  ${ResponsivePXValue('border-radius', { mobile: '4px', tablet: '4px', desktop: '8px' })}
`

const ImageContainer = styled.div<{ $width: string, $height: string, $imageShadow?: boolean }>`
  position: absolute;
  display: flex;

  ${(props): CSS => (props.$imageShadow) ? StrongBoxShadow : null}

  ${(props): CSS => {
    if (props.$width || props.$height) {
      return `
        overflow: hidden;
        ${ResponsivePXValue('min-width', props.$width)}
        ${ResponsivePXValue('width', props.$width)}
        ${ResponsivePXValue('height', props.$height)}
      `
    }
    return `
      cursor: pointer;
      height: 100%;
      width: 100%;
    `
  }}

`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  .title {
    max-width: 85%;
  }
  
  .sub-title {
    max-width: 80%;
  }

  .button {
    max-width: 75%;
  }
`

const Link = styled.a`
  width: 100%;
`

export interface PageBannerImageProps {
  pageBanner: PageBannerFragment
}

export function PageBannerImage({ pageBanner }: PageBannerImageProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()
  const theme = useTheme()

  const link = pageBanner?.link?.link
  const linkTitle = pageBanner?.link?.title
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)

  const mobileObjectFit = pageBanner?.mobileObjectFit ? pageBanner?.mobileObjectFit : pageBanner.objectFit
  const mobileTitleColor = pageBanner?.mobileTitleColor ? pageBanner?.mobileTitleColor : theme.colors.whites.pureWhite
  const mobileSubTitleColor = pageBanner?.mobileSubTitleColor ? pageBanner?.mobileSubTitleColor : theme.colors.whites.pureWhite

  const objectFit = pageBanner.objectFit
  const titleColor = pageBanner?.titleColor ? pageBanner?.titleColor : theme.colors.whites.pureWhite
  const subTitleColor = pageBanner?.subTitleColor ? pageBanner?.subTitleColor : theme.colors.whites.pureWhite

  const _handleLinkClick = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e?.preventDefault()
    if (link) {
      if (link.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = link
      } else {
        navigate(link)
      }
    }
  }

  return (
    <Container $width={pageBanner.imageWidth} $height={pageBanner.imageHeight}>
      <FlexDeviceContainer $mobile className='device-container'>
        <AbsoluteContainer>
          <ImageContainer $width={pageBanner.imageWidth} $height={pageBanner.imageHeight} $imageShadow={pageBanner.imageShadow}>
            <Choose>
              <When condition={!!link && linkTitle.length < 1}>
                <Link href={link} onClick={(e) => _handleLinkClick(e)}>
                  <ResponsiveImage
                    key={pageBanner.id}
                    image={pageBanner.mobileContentBannerImage}
                    objectFit={mobileObjectFit} />
                </Link>
              </When>
              <Otherwise>
                <ResponsiveImage
                  key={pageBanner.id}
                  image={pageBanner.mobileContentBannerImage}
                  objectFit={mobileObjectFit} />
              </Otherwise>
            </Choose>
          </ImageContainer>
          <ContentContainer>
            <If condition={pageBanner?.bannerTitle?.length > 0}>
              <Heading variant='h5' className='title' align='center' color={mobileTitleColor}>{pageBanner?.bannerTitle}</Heading>
              <Spacer universal='12px' />
            </If>
            <If condition={pageBanner?.subTitle?.length > 0}>
              <Paragraph bold variant='p3' className='sub-title' align='center' color={mobileSubTitleColor}>{pageBanner?.subTitle}</Paragraph>
              <Spacer universal='12px' />
            </If>
            <If condition={linkTitle.length > 0}>
              <Button
                className='button'
                variant='secondary'
                color={linkColor}
                title={linkTitle}
                href={link}
                onClick={_handleLinkClick} />
            </If>
          </ContentContainer>
        </AbsoluteContainer>
      </FlexDeviceContainer>
      <FlexDeviceContainer $tablet $desktop className='device-container'>
        <AbsoluteContainer>
          <ImageContainer $width={pageBanner.imageWidth} $height={pageBanner.imageHeight} $imageShadow={pageBanner.imageShadow}>
            <Choose>
              <When condition={!!link && linkTitle.length < 1}>
                <Link href={link} onClick={(e) => _handleLinkClick(e)}>
                  <ResponsiveImage
                    key={pageBanner.id}
                    image={pageBanner.contentBannerImage}
                    objectFit={objectFit} />
                </Link>
              </When>
              <Otherwise>
                <ResponsiveImage
                  key={pageBanner.id}
                  image={pageBanner.contentBannerImage}
                  objectFit={objectFit} />
              </Otherwise>
            </Choose>
          </ImageContainer>
          <ContentContainer>
            <If condition={pageBanner?.bannerTitle?.length > 0}>
              <Heading variant='h5' className='title' align='center' color={titleColor}>{pageBanner?.bannerTitle}</Heading>
              <Spacer universal='12px' />
            </If>
            <If condition={pageBanner?.subTitle?.length > 0}>
              <Paragraph bold variant='p3' className='sub-title' align='center' color={subTitleColor}>{pageBanner?.subTitle}</Paragraph>
              <Spacer universal='12px' />
            </If>
            <If condition={linkTitle.length > 0}>
              <Button
                className='button'
                variant='secondary'
                color={linkColor}
                title={linkTitle}
                href={link}
                onClick={_handleLinkClick} />
            </If>
          </ContentContainer>
        </AbsoluteContainer>
      </FlexDeviceContainer>
    </Container>
  )
}
