import React, { useState, useEffect } from 'react'

import usePlacesAutocomplete from 'use-places-autocomplete'

import { FieldData, Form, SelectInput, useForm } from '@molecules/index'

export interface PlaceIdOption {
  title: string
  value: string
  types: string[]
}
export interface PlaceIdLookupProps {
  onSelect: (placeId: string, option?: PlaceIdOption) => void
}

interface PlaceIdLookupState {
  loadingAddress: boolean
  options: PlaceIdOption[]
}

const DEFAULT_STATE: PlaceIdLookupState = {
  loadingAddress: false,
  options: [] as PlaceIdOption[],
}

export function PlaceIdLookup({ onSelect }: PlaceIdLookupProps): JSX.Element {

  const [form] = useForm()
  const [state, setState] = useState<PlaceIdLookupState>({ ...DEFAULT_STATE })
  const {
    ready,
    _,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'ZA',
      },
      // types: ['address'],
    },
    debounce: 500,
  })

  useEffect((): void => {
    const options = data.map((location) => ({ title: location.description, value: location.place_id, types: location.types })) as PlaceIdOption[]
    setState((prevState) => ({ ...prevState, options }))
  }, [data])

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'address') {
          onSelect(field.value, state.options.find(o => o.value === field.value))
        }
      })
    })
  }

  return (
    <Form form={form} onFieldsChange={_handleChange}>
      <SelectInput
        loading={!ready}
        name='address'
        label='Your address'
        placeholder='Street Address'
        onSearchChange={setValue}
        options={state.options}
        rules={[{ required: true, message: 'Please select an address' }]}
      />
    </Form>
  )

}
