import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftKidsStore')

const CraftKidsStore = loadable(() => import(/* webpackChunkName: "craft-kids-store" */'@pages/CraftKidsStore'), {
  resolveComponent: (components) => components.CraftKidsStore,
})

export function CraftKidsStoreAsync(): JSX.Element {

  return <CraftKidsStore fallback={<div>Loading...</div>} />

}
