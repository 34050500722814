import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftPizzaDetails')

const CraftPizzaDetails = loadable(() => import(/* webpackChunkName: "craft-pizza-details" */'@pages/CraftPizzaDetails'), {
  resolveComponent: (components) => components.CraftPizzaDetails,
})

export function CraftPizzaDetailsAsync(): JSX.Element {

  return <CraftPizzaDetails fallback={<div>Loading...</div>} />

}
