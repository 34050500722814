import React, { Fragment } from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { Heading, ResponsiveImage, Paragraph, Spacer } from '@atoms/index'
import { ContainerBorderRadius, ResponsivePXValue, StrongBoxShadow, getObjectFit } from '@components/Theme'
import { PageDetailFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'gordita';
`

const ImageContainer = styled.div<{ $width: string, $height: string, $imageShadow?: boolean }>`

   overflow: hidden;
 
  ${ResponsivePXValue('margin-bottom', '24px')}
  ${ResponsivePXValue('border-radius', '32px')}

  ${(props): CSS => (props.$imageShadow) ? StrongBoxShadow : null}
  ${(props): CSS => (props.$imageShadow) ? ContainerBorderRadius : null}

  ${(props): CSS => {
    if (props.$width || props.$height) {
      return `
        ${ResponsivePXValue('min-width', props.$width)}
        ${ResponsivePXValue('width', props.$width)}
        ${ResponsivePXValue('height', props.$height)}
      `
    }
    return `
      ${ResponsivePXValue('min-width', '160px')}
      ${ResponsivePXValue('width', '160px')}
      ${ResponsivePXValue('height', '160px')}
    `
  }}
`

const ContentContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('max-width', { desktop: '968px' })}
  
   .description {
     font-weight: 400;
     ${ResponsivePXValue('max-width', { desktop: '80%' })}
  }
  
`

export interface PageDetailProps {
  pageDetail: PageDetailFragment
  className?: string
}

export function PageDetail({ pageDetail, className }: PageDetailProps): JSX.Element {

  const theme = useTheme()

  const objectFit = getObjectFit(pageDetail)

  const title = pageDetail?.title || ''
  const description = pageDetail?.description || ''

  return (
    <Container className={className}>
      <Choose>
        <When condition={!!pageDetail}>
          <Fragment>
            <If condition={!!pageDetail.image}>
              <ImageContainer $width={pageDetail.imageWidth} $height={pageDetail.imageHeight} $imageShadow={pageDetail.imageShadow}>
                <ResponsiveImage image={pageDetail.image} objectFit={objectFit} />
              </ImageContainer>
            </If>
            <If condition={!!title || !!description}>
              <ContentContainer>
                <If condition={!!title}>
                  <Heading
                    className='title'
                    variant='h5'
                    align='center'
                    color={theme.colors.greys.darkCodGrey}>
                    {title}
                  </Heading>
                  <Spacer universal='16px' />
                </If>
                <If condition={!!description}>
                  <Paragraph
                    variant='p1'
                    align='center'
                    className='description'>
                    {description}
                  </Paragraph>
                  <Spacer universal='24px' />
                </If>
              </ContentContainer>
            </If>
          </Fragment>
        </When>
        <Otherwise>
          Page Detail not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
