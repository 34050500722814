import React from 'react'

import styled from 'styled-components'

const ViralSweepContainer = styled.div<{ height: string }>`
  position: relative;
  width: 100%;
  height: ${(props): string => props.height};

  iframe {
    overflow: hidden;
    border: none;
    width: 100%;
    height: 100%;
  }
`

interface ViralSweepProps {
  sweepstakesId: string
  height?: string
}

export function ViralSweep({ sweepstakesId, height = '500px' }: ViralSweepProps): JSX.Element {
  const iFrameUrl = `https://app.viralsweep.com/sweeps/full/${sweepstakesId}`
  return (
    <ViralSweepContainer height={height}>
      <iframe
        src={iFrameUrl}
        allowFullScreen
        title='ViralSweep Sweepstakes' />
    </ViralSweepContainer>
  )
}
