import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { Rule, Paragraph, Link } from '@atoms/index'
import { Spacer } from '@client/components/atoms/layout/Spacer'
import { ResponsivePXValue, lunchCategoryId, lunchPremiumId, lunchSaverId } from '@components/index'
import { FilterItemProps, FilterItem, Form, useForm, FieldData, CheckBox, SelectedFilters } from '@molecules/index'

import { CheckBoxOption } from '../inputs/CheckBox'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;

  .item-container {
    flex-direction: row-reverse;
    justify-content: space-between;

    ${ResponsivePXValue('margin-bottom', '12px')}

    .check-box-control {
      padding-right: 0;
    }
  }

  .action-link{
     color: ${(props): string => props.theme.colors.oranges.burntSienna};
  }

  .action-option {
    .check-box-control {
      display: none;
    }
  }
`

const PaddedContent = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('padding', '8px 16px')}
`

export interface FilterSectionProps {
  id: string
  title: string
  filterKey: string
  items: FilterItemProps[]
  selectedFilters?: SelectedFilters
  onChange?: (sectionName: string, selectedValues: string[]) => void
  reset?: boolean
  displayShowMore?: boolean
  className?: string
}

interface FilterSectionState {
  filterOptions: CheckBoxOption[]
  showMore: boolean
}

const DEFAULT_STATE: FilterSectionState = {
  filterOptions: [],
  showMore: true,
}

export function FilterSection(props: FilterSectionProps): JSX.Element {

  const {
    id,
    title,
    items,
    selectedFilters,
    onChange,
    reset = false,
    displayShowMore = false,
    className,
  } = props

  const [state, setState] = useState<FilterSectionState>({ ...DEFAULT_STATE })
  const [form] = useForm()

  const _handleChange = (changedFields: FieldData[]) => {
    if (onChange) {
      onChange(changedFields[0].name.toString(), changedFields[0].value)
    }
  }

  const _getItemValue = (item: FilterItemProps): string => {
    return item?.id ? item.id : item.title
  }

  const _getLunchItemValue = (): boolean => {
    return selectedFilters?.categories?.includes(lunchSaverId) || selectedFilters?.categories?.includes(lunchPremiumId)
  }

  const _getItemSelectedValue = (item: FilterItemProps): boolean => {
    return item.id === lunchCategoryId ? _getLunchItemValue() : item.selected
  }

  const _actionClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setState((prevState) => ({ ...prevState, showMore: !prevState.showMore }))
  }

  const actionOption: CheckBoxOption = {
    className: 'action-option',
    value: 0,
    selected: false,
    disabled: false,
    title: (
      <Link className='action-link' variant='l2' decoration='underline' onClick={_actionClickHandler}> {state.showMore ? 'Show more' : 'Show less'} </Link>
    ),
  }

  const setFilterOptions = () => {
    const options = items.map((item) => {
      return {
        value: _getItemValue(item),
        selected: _getItemSelectedValue(item),
        disabled: item?.disabled,
        title: (
          <FilterItem {...item} />
        ),
      }
    })

    let checkBoxOptions: CheckBoxOption[] = [...options]

    if (checkBoxOptions.length > 8) {
      if (displayShowMore && state.showMore) {
        checkBoxOptions = checkBoxOptions.slice(0, 8)
        checkBoxOptions.push(actionOption)
      }
    }
    setState((prevState) => ({ ...prevState, filterOptions: checkBoxOptions }))
  }

  useEffect(() => {
    setFilterOptions()
    const selectedValues = items.filter(item => item.selected).map(item => _getItemValue(item))
    form.setFieldsValue({ [id]: selectedValues })
  }, [])

  useEffect(() => {
    if (reset) {
      form.setFieldsValue({ [id]: [] })
    }
  }, [reset])

  useEffect(() => {
    setFilterOptions()
  }, [selectedFilters, state.showMore])

  useEffect(() => {
    const fieldValue = selectedFilters[id] ? selectedFilters : { [id]: [] }
    form.setFieldsValue(fieldValue)
  }, [selectedFilters])

  return (
    <Container className={className}>
      <PaddedContent>
        <Paragraph bold variant='p1'> {title} </Paragraph>
        <Spacer mobile='4px' desktop='8px' />
      </PaddedContent>
      <Rule color='slate' />
      <PaddedContent>
        <Form form={form} onFieldsChange={_handleChange}>
          <CheckBox
            className='check-box'
            name={id}
            options={state.filterOptions}
            showLabel={false}
            showOptional={false}
          />
        </Form>
      </PaddedContent>
    </Container>
  )
}
