import React from 'react'

import styled from 'styled-components'

import { Button, Paragraph, Link, Spacer } from '@client/components/atoms'
import { theme } from '@client/components/Theme'

const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
`

export interface AllItemsUnavailableFormProps {
  onTryDifferentAddress: () => void
  onNotify: () => void
}

export function AllItemsUnavailableForm({ onTryDifferentAddress, onNotify }: AllItemsUnavailableFormProps): JSX.Element {

  const _handleOnNotify = () => {
    onNotify?.()
  }

  const _handleOnTryDifferentAddress = () => {
    onTryDifferentAddress?.()
  }

  return (
    <Container>
      <Paragraph
        variant='p1'
        color={theme.colors.greys.liteCodGrey}
        align='center'>
          We are currently unable to deliver in your selected area.
      </Paragraph>
      <Spacer universal='16px' />
      <Paragraph
        variant='p2'
        color={theme.colors.greys.liteCodGrey}
        align='center'>
        Try another location or enter your address below and we will keep you up to speed with delivery updates.
      </Paragraph>
      <Spacer universal='32px' />
      <Button
        color='black'
        fluid
        title='TRY A DIFFERENT ADDRESS'
        onClick={_handleOnTryDifferentAddress} />
      <Spacer universal='16px' />
      <Link
        color={theme.colors.oranges.coral}
        textAlignment='center'
        onClick={_handleOnNotify}>
          NOTIFY ME ON DELIVERY UPDATES
      </Link>
    </Container>
  )

}
