import React from 'react'

import styled from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { NavigationItem, ResponsivePXValue } from '@client/components'
import { LiteBoxShadow } from '@client/components/Theme'
import { SubNavItemFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${LiteBoxShadow}

  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('padding', '8px 60px')}
`

export interface NavigationListProps {
  items: SubNavItemFragment[]
  onNavigate: (id: string) => void
}

const _handleNavHover = (active: boolean): void => {
  if (!active) {
    NavigationPlugin.shared().closeOpenSubNav()
  }
}

export function NavigationList({ items, onNavigate }: NavigationListProps): JSX.Element {

  let item: SubNavItemFragment
  return (
    <Container
      onMouseLeave={() => _handleNavHover?.(false)}>
      <For each='item' of={items}>
        <NavigationItem
          {...item}
          key={item.id}
          onClick={() => onNavigate(item.id)} />
      </For>
    </Container>
  )

}
