import React from 'react'

import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Paragraph, Button, Link } from '@client/components'
import { Spacer } from '@client/components/atoms'
import { ModalActionContainer, ModalContainerStyle } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useActivateSubscription, useUserCartQuery } from '@hooks/api'
import { Modal } from '@molecules/index'

const Container = styled.div`
  ${ModalContainerStyle}
`
const ActionContainer = styled.div`
  ${ModalActionContainer()}
`

export interface ReactivateSubscriptionModalProps {
  open?: boolean
  onClose: (subscriptionPaused?: boolean) => void
  onReactivation: () => void
}

export function ReactivateSubscriptionModal({ open, onClose, onReactivation }: ReactivateSubscriptionModalProps): JSX.Element {

  const config = useConfig()
  const { addToast } = useToasts()

  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [activateSubscription, { loading: activateLoading }] = useActivateSubscription()

  const _handleReactivate = async (): Promise<void> => {
    await activateSubscription({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
        reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
      },
      refetchQueries: SiteHelper.getUserRefetchQueries(),
      awaitRefetchQueries: true,
    })
    onReactivation()

    addToast('Your subscription is active!', {
      appearance: 'success',
      autoDismiss: true,
    })
  }

  const _handleClose = (): void => {
    onClose()
  }

  const loading = userCartLoading || activateLoading

  return (
    <Modal
      open={open}
      onClose={_handleClose}
      allowBackgroundClose={false}
      fullscreen={false}
      title='Activate your subscription'>
      <Container>
        <DeviceContainer $mobile $tablet>
          <Paragraph variant='p1'>
            Your Meal Kit subscription is currently paused. To add dishes to your next order, activate your subscription by selecting activate below.
          </Paragraph>
        </DeviceContainer>
        <DeviceContainer $desktop>
          <Paragraph variant='p1' align='center'>
            Your Meal Kit subscription is currently paused. To add dishes to your next order, activate your subscription by selecting activate below.
          </Paragraph>
        </DeviceContainer>
        <Spacer universal='24px' />
        <ActionContainer>
          <DeviceContainer $mobile $tablet className='full-width'>
            <Button
              loading={loading}
              color='black'
              fullWidth
              title='ACTIVATE NOW'
              onClick={_handleReactivate} />
          </DeviceContainer>
          <DeviceContainer $desktop>
            <Button
              loading={loading}
              color='black'
              fullWidth
              title='ACTIVATE NOW'
              onClick={_handleReactivate} />
          </DeviceContainer>
          <Spacer mobile='8px' desktop='16px' />
          <Link variant='l2' decoration='underline' onClick={_handleClose}> No thanks </Link>
        </ActionContainer>
      </Container>
    </Modal>
  )
}
