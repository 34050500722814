import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Spacer } from '@client/components/atoms'
import { ModalContainerStyle } from '@client/components/Theme'
import { UpSellFrozenMealDishFragment, UpSellMarketProductFragment, UpSellWineFragment, useGetAppQuery } from '@hooks/api'
import { Modal, UpSellProductCard } from '@molecules/index'

const Container = styled.div<{$isNativeApplication:boolean}>`
  ${ModalContainerStyle}
`

export interface UpSellProductsModalProps {
  open?: boolean
  upSellProducts: (UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment)[]
  onClose?: () => void
}

export function UpSellProductsModal({ open, upSellProducts, onClose }: UpSellProductsModalProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _handleClose = (): void => {
    onClose()
  }

  let upSellProduct: UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment

  return (
    <Modal
      open={open}
      onClose={_handleClose}
      showCloseButton
      fullscreen={false}
      title='ADD EXTRAS'>
      <Container $isNativeApplication={isNativeApplication}>
        <For each='upSellProduct' of={upSellProducts}>
          <UpSellProductCard className='up-sell-product-card' isModal meal={upSellProduct} key={upSellProduct.id} />
          <Spacer mobile='16px' />
        </For>
      </Container>
    </Modal>
  )
}
