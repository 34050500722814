import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button } from '@atoms/index'
import { CookWithinDaysDish, ModalActionContainer, ModalContainerStyle, Paragraph, Spacer } from '@client/components'
import { ErrorBlock, Modal } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, UserMenuDishFragment, useAcceptUserMenuCookWithInDaysMutation, RemoveDishFromUserMenuDocument } from '@hooks/api'
import { ModalLoading } from '@molecules/index'

export enum CookWithinStepEnum {
  COOK_WITHIN = 'COOK_WITHIN',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  ${ModalContainerStyle}
`

const ActionContainer = styled.div`
  ${ModalActionContainer()}
`

interface CookWithinDaysModalState {
  displayStep: CookWithinStepEnum
  open: boolean
  error: string
}

const DEFAULT_STATE: CookWithinDaysModalState = {
  displayStep: CookWithinStepEnum.COOK_WITHIN,
  open: false,
  error: '',
}

export function CookWithinDaysModal(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<CookWithinDaysModalState>({ ...DEFAULT_STATE })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [acceptUserMenuCookWithInDays] = useAcceptUserMenuCookWithInDaysMutation()
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const mealKitItems = userCartData?.currentUser?.activeMenu?.dishes || []
  const hasAcceptedCookWithInDays = userCartData?.currentUser?.checkoutStatus?.hasAcceptedCookWithInDays ?? true

  const message = `Some of your dishes have the same eat within day. Please review and confirm that you're 
                   happy with your selection, freshness is our top priority!`

  const _setCookWithInDays = async (): Promise<void> => {
    await acceptUserMenuCookWithInDays({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.id,
      },
    })
  }

  const _handleAcceptance = (): void => {
    setState(prevState => ({ ...prevState, loading: false, open: false }))
    _setCookWithInDays()

    if (config.isBrowser()) {
      window.sessionStorage.setItem('reviewEatWithinAcceptance', 'true')
    }
  }

  const _handleClose = (): void => {
    setState(prevState => ({ ...prevState, open: false }))
    _setCookWithInDays()
  }

  const removeMenuDish = async (id: string): Promise<void> => {
    try {
      const client = await config.getClient()

      await client.mutate({
        mutation: RemoveDishFromUserMenuDocument,
        variables: {
          userMenuDishId: id,
        },
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true, //
      })
    }
  }

  const _handleMenuDishRemove = async (id: string): Promise<void> => {
    await removeMenuDish(id)
  }

  const _handleShowWeeklyMenu = (): void => {
    setState(prevState => ({ ...prevState, loading: false, open: false }))
    _setCookWithInDays()
    navigate('/meal-kit/') //
  }

  useEffect(() => {
    if (showModal() && !hasAcceptedCookWithInDays) {
      setState(prevState => ({ ...prevState, open: true }))
    }
  }, [mealKitItems.length, hasAcceptedCookWithInDays])

  const showModal = (): boolean => {

    const cookWithinTimes: number[] = []
    let showModal = false

    mealKitItems.filter((mealKitItem) => cookWithinTimes.push(mealKitItem.dish.cookWithin))

    for (let day = 1; day <= 5; day++) {
      const meals = cookWithinTimes.filter((cookWithin) => cookWithin <= day)
      if (meals.length > day) {
        showModal = true
        break
      }
      showModal = false
    }

    if (config.isBrowser() && window.sessionStorage.getItem('reviewEatWithinAcceptance') === 'true') {
      showModal = false
    }

    return showModal
  }

  useEffect(() => {
    if (state.open) {
      setState((prevState) => ({ ...prevState, displayStep: CookWithinStepEnum.COOK_WITHIN }))
    }
  }, [state.open])

  let dishItem: UserMenuDishFragment
  let dishIndex: number

  return (
    <Modal
      open={state.open}
      onClose={_handleClose}
      title='Review eat within days'
      fullscreen={false}>
      <Container>
        <Choose>
          <When condition={state.displayStep === CookWithinStepEnum.LOADING}>
            <ModalLoading message='Checking EAT WITHINDays' />
          </When>
          <When condition={state.displayStep === CookWithinStepEnum.ERROR}>
            <ErrorBlock
              title={state.error}
              onClick={() => setState((prevState) => ({ ...prevState, displayStep: CookWithinStepEnum.COOK_WITHIN }))} />
          </When>
          <Otherwise>
            <ModalContainer>
              <Paragraph variant='p1' align='center'>{message}</Paragraph>
              <Spacer universal='16px'/>
              <For each='dishItem' of={mealKitItems} index='dishIndex'>
                <CookWithinDaysDish
                  key={dishIndex}
                  dish={dishItem}
                  onRemove={_handleMenuDishRemove} />
              </For>
              <Spacer universal='16px' />
              <ActionContainer>
                <Button
                  className='button'
                  title='CONFIRM SELECTION'
                  color='black'
                  fullWidth
                  onClick={_handleAcceptance} />
                <Spacer universal='16px' />
                <Button
                  variant='secondary'
                  className='button'
                  title='GO BACK TO MENU'
                  color='black'
                  fullWidth
                  onClick={_handleShowWeeklyMenu} />
              </ActionContainer>
            </ModalContainer>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
