import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { NumberedList, NumberedListItem } from '@molecules/index'

const Container = styled.div`
  ${ResponsivePXValue('padding', { mobile: '28px 8px', tablet: '30px 8px', desktop: '32px 12px' })}
`
export interface WhatWeSendProps {
  defaultList: NumberedListItem[]
  items: NumberedListItem[]
}

export function WhatWeSend({ defaultList, items }: WhatWeSendProps): JSX.Element {

  return (
    <Container>
      <NumberedList items={items && items.length ? items : defaultList} />
    </Container>
  )

}
