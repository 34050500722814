import React, { useRef } from 'react'

import styled, { useTheme, CSS } from 'styled-components'

import { Heading, ResponsiveImage } from '@atoms/index'
import { Button, Link, RichText, Spacer } from '@client/components'
import { getObjectFit, MediumBoxShadow, PageMediaImage, PageMediaPortraitImage, ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'
import { ImageVariantEnum, PageSectionMediaVariationEnum } from '@uctypes/api/globalTypes'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $isLeftElegant: boolean }>`
  display: flex;
  align-items: center;

  ${(props): CSS => {
    const direction = props.$isLeftElegant ? 'row' : 'row-reverse'
    return ResponsivePXValue('flex-direction', { mobile: 'column', tablet: direction, desktop: direction })
  }}
`

const ImageContainer = styled.div<{ $shadow: boolean, $variant: ImageVariantEnum }>`
  flex-shrink: 0;
  background-color: ${(props): string => props.$shadow ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.transparent};

  ${(props): CSS => props.$shadow ? MediumBoxShadow : undefined}

  ${(props): CSS => {
    switch (props.$variant) {
      case ImageVariantEnum.NORMAL:
        return PageMediaImage
      case ImageVariantEnum.PORTRAIT:
        return PageMediaPortraitImage
      default:
        break
    }
  }}

  ${ResponsivePXValue('margin-bottom', { mobile: '32px' })}
`

const ContentContainer = styled.div`
  flex-grow: 1;

  ${ResponsivePXValue('padding', { desktop: '0 84px 0' })}

  .text-style {
    ${ResponsivePXValue('margin-bottom', '16px')}
    ${ResponsivePXValue('text-align', { mobile: 'center', tablet: 'left', desktop: 'left' })}
  }
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`

  ${ResponsivePXValue('margin-bottom', '16px')}

  .rich-text {
    div {
       ${ResponsiveProperty('text-align', { mobile: 'center', tablet: 'center' })}
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}

  .link{
    color: ${(props): string => props.theme.colors.oranges.coral};
  }
`

export interface PageMediaElegantProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaElegant({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaElegantProps): JSX.Element {

  const theme = useTheme()

  const contents = pageSection?.contents || []
  const hasButton = !!pageSection.links?.primary || !!pageSection.links?.secondary
  const isLeftElegant = pageSection?.variation === PageSectionMediaVariationEnum.LEFT_ELEGANT

  const objectFit = getObjectFit(pageSection)

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  let content: string
  let contentIndex: number

  return (
    <Container ref={pageRef} $isLeftElegant={!!isLeftElegant}>
      <ImageContainer $shadow={pageSection.imageShadow} $variant={pageSection.imageVariant}>
        <ResponsiveImage image={pageSection.image} objectFit={objectFit} />
      </ImageContainer>
      <ContentContainer>
        <Heading className='text-style' variant='h4' color={theme.colors.slates.bitter}>
          {pageSection.title}
        </Heading>
        <Heading className='text-style' variant='h2'>
          {pageSection.subTitle}
        </Heading>
        <ListContainer>
          <For each='content' of={contents} index='contentIndex'>
            <Content key={contentIndex}>
              <RichText className='rich-text'>{content}</RichText>
            </Content>
          </For>
        </ListContainer>
        <If condition={hasButton}>
          <ButtonsContainer>
            <Spacer universal='32px'/>
            <If condition={!!pageSection.links?.primary}>
              <Button title={pageSection.links.primary.title} href={pageSection.links.primary.link} />
              <Spacer desktop='16px' variant='horizontal' />
            </If>
            <If condition={!!pageSection.links?.secondary}>
              <Spacer mobile='16px' />
              <Link className='link' variant='l2' href={pageSection.links.secondary.link}>{pageSection.links.secondary.title}</Link>
            </If>
            <Spacer universal='16px' />
          </ButtonsContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}
