import React from 'react'

import { Svg } from './Svg'

export interface CommunityProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Community({ color, hoverColor, className }: CommunityProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 18.307C1 17.7156 1 17.1241 1 16.5326C1.03033 16.4163 1.07583 16.3 1.08594 16.1787C1.31343 14.01 1.86953 11.9322 2.90083 10.0061C4.35678 7.28629 6.42443 5.18831 9.28073 3.97501C14.5535 1.73548 20.5846 3.45936 23.9464 8.11032C24.2245 8.49453 24.4823 8.89391 24.7553 9.28317C24.8058 9.24273 24.8362 9.22756 24.8513 9.20228C24.9069 9.12645 24.9524 9.04557 25.003 8.96974C26.1051 7.25091 27.5357 5.87584 29.2748 4.80915C33.8044 2.03374 39.8507 2.47356 43.6928 5.97695C47.8382 9.76343 49.3649 14.5559 48.5257 20.0309C47.7674 24.9953 45.2751 29.0851 41.5594 32.4267C36.4585 37.012 31.3273 41.5669 26.2062 46.1319C25.7208 46.5616 25.2355 46.9913 24.7452 47.4261C24.5884 47.2845 24.457 47.1733 24.3306 47.0621C21.9596 44.8984 19.5684 42.7397 17.1924 40.571C13.8356 37.5074 10.4182 34.5096 7.14735 31.36C4.23039 28.5493 2.3599 25.0711 1.46004 21.1077C1.25277 20.1826 1.15166 19.2423 1 18.307Z" className='fill' />
    </Svg>
  )

}
