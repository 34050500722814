import React, { Fragment, useEffect, useRef, useState } from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { FormFactor, Heading, RichText, Paragraph, Link, Button, Spacer } from '@client/components'
import { getPageSectionBackground, PageSectionPadding, ResponsiveProperty, ResponsivePXValue } from '@client/components/Theme'
import { PageTextSectionFragment } from '@hooks/api'

import { PageSectionRef } from './PageSectionGroup'

const Container = styled.div<{ $background: string, $paddingTop: string, $paddingBottom: string, $isLegalDocumentation: boolean }>`
 display: flex;
 flex-direction: column;
 align-items: ${(props): string => props?.$isLegalDocumentation ? 'left' : 'center'};

 ${PageSectionPadding}

 background: ${(props): string => props.$background};

 ${(props): CSS => props?.$paddingTop ? ResponsivePXValue('padding-top', props.$paddingTop) : null}
 ${(props): CSS => props?.$paddingBottom ? ResponsivePXValue('padding-bottom', props.$paddingBottom) : null}
 ${(props): CSS => props?.$isLegalDocumentation ? ResponsivePXValue('padding-bottom', '0') : null}

  .sub-title {

    ${ResponsivePXValue('max-width', { desktop: '970px' })}
    
    ${(props): CSS => {
    if (props.$isLegalDocumentation) {
      return `
          text-align: left;
          width: 100%;
          ${ResponsiveProperty('max-width', { desktop: '968px' })}

        `
    }
  }}
   
  }

`
const ContentContainer = styled.div<{ $flexBasis: FormFactor | null, $textAlign: string, $isLegalDocumentation: boolean }>`
  display: flex;
  flex-direction: column;
  flex-direction: ${(props): string => props?.$flexBasis ? 'row' : 'column'};
  flex-wrap: ${(props): string => props?.$flexBasis ? 'wrap' : 'initial'};

  ${ResponsiveProperty('justify-content', { mobile: 'center', desktop: 'space-evenly' })}
  ${ResponsiveProperty('max-width', { desktop: '968px' })}

  ${(props): CSS => {
    if (props.$isLegalDocumentation) {
      return `
        ${ResponsiveProperty('width', { desktop: '100%' })}
      `
    }
  }}
  .content {
    ${ResponsivePXValue('padding-left', '10px')}
    ${ResponsivePXValue('padding-right', '10px')}
    ${ResponsiveProperty('justify-content', { mobile: 'center' })}
    
    ${(props): CSS => {
    if (props?.$flexBasis) {
      return `
          ${ResponsiveProperty('text-align', { mobile: 'center', desktop: 'initial' })}
          ${ResponsiveProperty('flex-basis', { mobile: 'initial', tablet: props.$flexBasis.tablet, desktop: props.$flexBasis.desktop })}
        `
    }
    if (props.$isLegalDocumentation) {
      return `
        ${ResponsiveProperty('padding-left', { desktop: '0' })}
        width: 100%;
        text-align: left;
        justify-content: left;
      `
    }
  }
}
  
  }

  .rich-text {
    ${ResponsivePXValue('margin-bottom', { mobile: '20px', desktop: '28px' })}
    ${(props): CSS => {
    if (props.$isLegalDocumentation) {
      return `
        ${ResponsivePXValue('margin-bottom', { mobile: '4px', desktop: '4px' })}
      `
    }
  }}
    div {
      ${(props): CSS => {

    if (props.$isLegalDocumentation) {
      return `
        text-align: left;
      `
    } else {
      return ResponsivePXValue('text-align', { mobile: 'center', tablet: props.$textAlign, desktop: props.$textAlign })
    }
  }}
    }
  }

`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ParsedContentContainer = styled.div`
  display: flex;
`

export interface PageTextSectionProps {
  pageSection: PageTextSectionFragment
  pageSectionRef?: boolean
  isLegalDocumentation?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

interface PageTextState {
  showMore: boolean
}

const DEFAULT_STATE: PageTextState = {
  showMore: false,
}

export const calculateFlexBasis = (contentColumns: FormFactor): FormFactor => {
  if (contentColumns?.desktop) {
    return {
      mobile: contentColumns?.mobile ? `${(1 / Number(contentColumns.mobile)) * 100}%` : 'initial',
      tablet: contentColumns?.tablet ? `${(0.95 / Number(contentColumns.tablet)) * 100}%` : 'initial',
      desktop: contentColumns?.desktop ? `${(0.90 / Number(contentColumns.desktop)) * 100}%` : 'initial',
    }
  }
}

export function PageTextSection({ pageSection, pageSectionRef = false, isLegalDocumentation, addPageSectionRef }: PageTextSectionProps): JSX.Element {

  const [state, setState] = useState<PageTextState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  const background = getPageSectionBackground(pageSection?.background)

  const isValidTitle = !!pageSection.title && pageSection.title.trim() !== ''
  const isValidSubTitle = !!pageSection.subTitle && pageSection.subTitle.trim() !== ''
  const contents = pageSection?.contents || []

  const hideContentOverflow = !!pageSection?.hideContentOverflow
  const primaryLink = pageSection?.links?.primary
  const secondaryLink = pageSection?.links?.secondary
  const hasLinks = !!primaryLink || !!secondaryLink
  const textAlgin = isLegalDocumentation ? 'left' : 'center'

  let flexBasis: FormFactor

  const contentColumns = pageSection?.contentColumns as unknown as FormFactor

  if (contents.length > 1 && contentColumns?.desktop) {
    flexBasis = calculateFlexBasis(contentColumns)
  } else {
    flexBasis = null
  }

  let pageRef
  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const actionClickHandler = () => {
    setState((prevState) => ({ ...prevState, showMore: !prevState.showMore }))
  }

  useEffect(() => {
    setState((prevState) => ({ ...prevState, showMore: hideContentOverflow }))
  }, [])

  const actionText = state.showMore ? 'Show More' : 'Show Less'

  let content: string
  let contentIndex: number

  return (
    <Container
      ref={pageRef}
      $background={background}
      $paddingTop={pageSection?.paddingTop}
      $paddingBottom={pageSection?.paddingBottom}
      $isLegalDocumentation={isLegalDocumentation}
      className={isLegalDocumentation ? 'legal-doc' : ''}>
      <Choose>
        <When condition={!!pageSection}>
          <Fragment>
            <If condition={isValidTitle}>
              <Heading variant='h4' color={theme.colors.slates.bitter} align={textAlgin}>
                {pageSection.title}
              </Heading>
              <Spacer universal='24px' />
            </If>
            <If condition={isValidSubTitle}>
              <Heading className='sub-title' variant='h2' align={textAlgin}>
                {pageSection.subTitle}
              </Heading>
              <Spacer universal='24px' />
            </If>
            <If condition={contents.length > 0}>
              <ContentContainer
                $flexBasis={flexBasis}
                $textAlign={pageSection?.textAlign}
                $isLegalDocumentation={isLegalDocumentation}>
                <Choose>
                  <When condition={state.showMore}>
                    <Paragraph variant='p1' align={textAlgin}> {contents[0]} </Paragraph>
                    <Spacer universal='24px' />
                  </When>
                  <Otherwise>
                    <For each='content' of={contents} index='contentIndex'>
                      <ParsedContentContainer key={contentIndex} className='content'>
                        <RichText className='rich-text'>{content}</RichText>
                      </ParsedContentContainer>
                    </For>
                  </Otherwise>
                </Choose>
              </ContentContainer>
              <If condition={hideContentOverflow && contents.length > 1}>
                <Link onClick={actionClickHandler}>{actionText}</Link>
              </If>
            </If>
            <If condition={hasLinks}>
              <LinksContainer>
                <If condition={!!primaryLink}>
                  <Button title={primaryLink.title} href={primaryLink.link} />
                </If>
                <If condition={!!secondaryLink}>
                  <Link href={secondaryLink.link}>{secondaryLink.title}</Link>
                </If>
              </LinksContainer>
            </If>
          </Fragment>
        </When>
        <Otherwise>
          Page Text not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
