import React, { useState } from 'react'

import update from 'react-addons-update'
import OneSignal from 'react-onesignal'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button, Loader, Paragraph } from '@client/components/atoms'
import { Drawer } from '@client/components/molecules'
import { ResponsivePXValue } from '@client/components/Theme'
import { SiteHelper } from '@client/lib/SiteHelper'
import { UserDetailsQuery, useDeleteUserMutation, useUserLogoutMutation } from '@hooks/api'

const Container = styled.div`
  height: 80%;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '8px 8px 26px 8px', tablet: '12px', desktop: '16px' })}

`

const ButtonsContainer = styled.div`
  ${ResponsivePXValue('padding-top', { mobile: '12px', tablet: '12px', desktop: '16px' })}
  width: fit-content;
  display: block;
  justify-content: center;
  margin: 0;
`
const LoaderContainer = styled.div`
  opacity: 0.5;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
`
export interface DeleteAccountModalProps {
  open: boolean
  onClose: () => void
  userDetailsData: UserDetailsQuery
}
interface DeleteState {
  showLoading: boolean
}

const DEFAULT_STATE: DeleteState = {
  showLoading: false,
}

export function DeleteAccountDrawer({ open, onClose, userDetailsData }: DeleteAccountModalProps): JSX.Element {

  const [deleteUser, { loading: removeLoading }] = useDeleteUserMutation()
  const [logOut, { loading: logoutLoading }] = useUserLogoutMutation()
  const [state, setState] = useState<DeleteState>({ ...DEFAULT_STATE })

  const navigate = useNavigate()
  const _handleDeleteAccount = async ():Promise<void> => {
    setState((prevState) => update(prevState, {
      showLoading: { $set: true },
    }))
    await deleteUser({
      variables: {
        id: userDetailsData.currentUser.id,
        input: {
          userId: userDetailsData.currentUser.id,
          email: userDetailsData.currentUser.email,
        },
      },
    },
    )

    await logOut({
      refetchQueries: SiteHelper.getUserRefetchQueries(),
      awaitRefetchQueries: true,
    })

    OneSignal.logout()

    navigate('/')
  }

  const _handlePauseAccountLink = ():void => {

    navigate('/me/subscriptions')
  }
  return (
    <Drawer
      open={open}
      onClose={onClose}
      allowClose={true}>
      <Container>
        <Paragraph variant='p4' bold>Are you sure you want to delete your UCOOK account?</Paragraph>

        <Paragraph >Once you have deleted this account, the information cannot be recovered.</Paragraph>
        <ButtonsContainer>
          <Button title='Yes, delete my account' color='red' onClick={_handleDeleteAccount}></Button>
        </ButtonsContainer>
        <ButtonsContainer>
          <Button title='No, pause my account' color='green' onClick={_handlePauseAccountLink}></Button>
        </ButtonsContainer>
      </Container>
      <If condition={removeLoading || logoutLoading || state.showLoading}>
        <LoaderContainer>
          <Loader noShadow={true}/>
        </LoaderContainer>
      </If>
    </Drawer>

  )

}
