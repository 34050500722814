import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SuppliersQuery, useSuppliersQuery } from '@hooks/api'

import { SectionLoading } from '../content'

import { SupplierCard } from './SupplierCard'

const Container = styled.div`
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 24px)', tablet: 'CALC(100% - 30px)', desktop: 'CALC(100% - 36px)' })}
  ${ResponsivePXValue('padding', { mobile: '24px 12px', tablet: '24px 15px', desktop: '24px 18px' })}
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`

export interface SupplierCardGroupProps {
  identifier: string
  loadingIds?: string[]
  onShowDetails?: (slug: string) => void
}

export function SupplierCardGroup({ identifier, loadingIds = [], onShowDetails }: SupplierCardGroupProps): JSX.Element {

  const { data, loading } = useSuppliersQuery({ variables: { limit: 4 } })
  const theme = useTheme()

  const suppliersList: SuppliersQuery['suppliers']['list'] = data?.suppliers.list.concat()
  let supplier: SuppliersQuery['suppliers']['list'][0]

  let suppliers: SuppliersQuery['suppliers']['list'] = []
  if (suppliersList) {
    suppliers = suppliersList.filter(s => s.slug !== identifier)
  }

  if (suppliers.length > 3) {
    suppliers.pop()
  }

  return (
    <Container>
      <Heading variant='misc' align='center' color={theme.colors.slates.bitter}>Other suppliers</Heading>
      <Spacer universal='8px' />
      <Seperator align='center' />
      <CardContainer>
        <Choose>
          <When condition={!!suppliers}>
            <For each='supplier' of={suppliers}>
              <SupplierCard
                className='supplier-card'
                key={supplier.id}
                supplier={supplier}
                loading={loadingIds.includes(supplier.id)}
                onShowDetails={onShowDetails} />
            </For>
          </When>
          <When condition={loading}>
            <SectionLoading />
          </When>
          <Otherwise>
            Card group not found
          </Otherwise>
        </Choose>
      </CardContainer>
    </Container>
  )

}
