import React, { useRef } from 'react'

import styled, { css, CSS } from 'styled-components'

import { PageMediaAction, PageMediaList } from '@client/components'
import { getPageSectionBackground, PageSectionPadding, ResponsivePXValue, theme } from '@client/components/Theme'
import { PageMediaSectionFragment } from '@hooks/api'
import { PageSectionBackgroundEnum, PageSectionMediaVariationEnum } from '@uctypes/api/globalTypes'

import { PageMediaCard } from './PageMediaCard'
import { PageMediaElegant } from './PageMediaElegant'
import { PageMediaStandard } from './PageMediaStandard'
import { PageSectionRef } from './PageSectionGroup'

const OuterContainer = styled.div<{ $background: string, $isTopFloating: boolean, $setMaxHeight: boolean }>`
  background: ${(props): CSS => props.$background};

  ${(props): CSS => props.$isTopFloating ? ResponsivePXValue('padding-top', { mobile: '60px', tablet: '70px', desktop: '80px' }) : undefined}
  ${(props): CSS => props.$setMaxHeight ? ResponsivePXValue('max-height', { desktop: '450px' }) : undefined}
`

const Variation = css`
  padding: initial;
  ${ResponsivePXValue('padding-bottom', '40px')}
`

const NonVariation = css`
  ${PageSectionPadding}
`
const NoVertical = css`
  ${PageSectionPadding}
  ${ResponsivePXValue('padding-top', '0')}
  ${ResponsivePXValue('padding-bottom', '0', undefined, { desktop: true })} // Applies only to desktop
  ${ResponsivePXValue('padding-right', '0', undefined, { mobile: true })} // Applies only to mobile
  ${ResponsivePXValue('padding-left', '0', undefined, { mobile: true })} // Applies only to mobile
`

const Container = styled.div<{ $background: string, $cardVariation: boolean, $noVerticalPadding: boolean }>`
 display: flex;
 flex-direction: column;
 background: ${(props): CSS => props.$background};
 ${(props): CSS => props.$noVerticalPadding ? NoVertical : (props.$cardVariation ? Variation : NonVariation)}
`

const PageMedia = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
`

const Spacer = styled.div<{ $hasVideo: boolean }>`
  display: flex; 
  
  ${(props): CSS => props.$hasVideo ? ResponsivePXValue('margin-bottom', { mobile: '20%', desktop: '15%' }) : ResponsivePXValue('margin-bottom', { mobile: '60%', desktop: '15%' })}
`

export interface PageMediaSectionProps {
  pageSection: PageMediaSectionFragment
  pageSectionRef?: boolean
  addPageSectionRef?: (pageSectionRef: PageSectionRef) => void
}

export function PageMediaSection({ pageSection, pageSectionRef = false, addPageSectionRef }: PageMediaSectionProps): JSX.Element {

  const background = getPageSectionBackground(pageSection?.background)

  let pageRef

  if (pageSectionRef && addPageSectionRef) {
    pageRef = useRef()
    addPageSectionRef({ ref: pageRef, id: pageSection.id })
  }

  const standardVariation = pageSection.variation === PageSectionMediaVariationEnum.LEFT_STANDARD || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_STANDARD || pageSection.variation === PageSectionMediaVariationEnum.CENTER_STANDARD
  const listVariation = pageSection.variation === PageSectionMediaVariationEnum.LEFT_LIST || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_LIST
  const elegantVariation = pageSection.variation === PageSectionMediaVariationEnum.LEFT_ELEGANT || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_ELEGANT
  const cardVariation = pageSection.variation === PageSectionMediaVariationEnum.CENTER_CARD
  const actionMediaVariation = pageSection.variation === PageSectionMediaVariationEnum.ACTION_MEDIA

  const isTopFloating = pageSection.variation === PageSectionMediaVariationEnum.LEFT_STANDARD || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_STANDARD || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_LIST

  let floatingBackground = background

  if (pageSection.variation === PageSectionMediaVariationEnum.LEFT_STANDARD || pageSection.variation === PageSectionMediaVariationEnum.RIGHT_LIST) {
    floatingBackground = pageSection?.background === PageSectionBackgroundEnum.WHITE ? theme.colors.whites.desertStorm : theme.colors.whites.alabaster
  }

  const setMaxHeight = standardVariation || listVariation

  return (
    <OuterContainer $background={floatingBackground} $isTopFloating={isTopFloating} $setMaxHeight={setMaxHeight}>
      <Container ref={pageRef} $background={background} $cardVariation={cardVariation} $noVerticalPadding={actionMediaVariation}>
        <Choose>
          <When condition={!!pageSection}>
            <PageMedia>
              <Choose>
                <When condition={standardVariation}>
                  <PageMediaStandard pageSection={pageSection as unknown as PageMediaSectionFragment} />
                </When>
                <When condition={listVariation}>
                  <PageMediaList pageSection={pageSection as unknown as PageMediaSectionFragment} />
                </When>
                <When condition={elegantVariation}>
                  <PageMediaElegant pageSection={pageSection as unknown as PageMediaSectionFragment} />
                </When>
                <When condition={actionMediaVariation}>
                  <PageMediaAction pageSection={pageSection as unknown as PageMediaSectionFragment} />
                </When>
                <When condition={cardVariation}>
                  <PageMediaCard pageSection={pageSection as unknown as PageMediaSectionFragment} />
                  <Spacer $hasVideo={!!pageSection?.video} />
                </When>
              </Choose>
            </PageMedia>
          </When>
          <Otherwise>
            Page Media Section not found
          </Otherwise>
        </Choose>
      </Container>
    </OuterContainer>
  )

}
