import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled, { CSS } from 'styled-components'

import { Heading, Paragraph, Spacer } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const Container = styled.div<{ $backgroundColor: string, $variant?: string }>`
  display: flex;
  border-radius: 8px;
  width: fit-content;
  transition: all 300ms ease-in-out;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.$backgroundColor};
  ${ResponsivePXValue('padding', '8px 16px')}
  ${ResponsivePXValue('min-width', '165px')}

  ${(props): CSS => {
    if (props?.$variant === 'vertical') {
      return `
       
      `
    }
  }}

  .seperator {
    display: flex;
    align-self: flex-start;
    line-height: 1;
  }

  .time-value {
    font-family: 'utopia';
    line-height: normal;
    letter-spacing: 1px;
  }
`

const TimeContainer = styled.div<{ $flexDirection: string}>`
  display: flex;
  align-items: center;
  flex-direction: ${(props): string => props.$flexDirection};

  .time-unit{
    line-height: 1;
  }
`

export interface CountdownTimerProps {
  targetDate: Date
  textColor?: string
  backgroundColor?: string
  variant?: 'horizontal' | 'vertical'
  className?: string
}

interface CountdownTimerState {
  days: number
  hours: number
  minutes: number
  seconds?: number
}

const DEFAULT_STATE: CountdownTimerState = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
}

export function CountdownTimer({ targetDate, textColor = theme.colors.yellows.selectiveYellow, backgroundColor = theme.colors.whites.pureWhite, variant = 'horizontal', className }: CountdownTimerProps): JSX.Element {

  const [state, setState] = useState<CountdownTimerState>({ ...DEFAULT_STATE })

  const shouldDisplayDays = state.days > 0
  const shouldDisplayHours = state.hours > 0
  const shouldDisplayMinutes = state.minutes >= 0
  const shouldDisplaySeconds = state.seconds > 0 && state.days <= 0
  const shouldRender = !!targetDate && (shouldDisplayDays || shouldDisplayHours || shouldDisplayMinutes || shouldDisplaySeconds)

  const getValue = (value: number) => {
    if (value < 10) {
      return `0${value}`
    } else {
      return `${value}`
    }
  }

  const getUnit = (value: number, unit: string, returnValue?: boolean) => {
    if (returnValue) return value === 1 ? `${value} ${unit}` : `${value} ${unit}S`
    return value === 1 ? `${unit}` : `${unit}S`
  }

  const setTimeRemaining = () => {
    const now = new Date().getTime()
    const difference = targetDate.getTime() - now

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    setState((prevState) => update(prevState, {
      days: { $set: days },
      hours: { $set: hours },
      minutes: { $set: minutes },
      seconds: { $set: seconds },
    }))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Choose>
      <When condition={shouldRender}>
        <Container
          className={className}
          $backgroundColor={backgroundColor}
          $variant={variant}>
          <Choose>
            <When condition={variant === 'horizontal'}>
              <Paragraph variant='p2' color={textColor} bold>
                {`
                  ${shouldDisplayDays ? getUnit(state.days, 'DAY', true) : ''}
                  ${shouldDisplayDays && shouldDisplayHours ? ' : ' : ''}
                  ${shouldDisplayHours ? getUnit(state.hours, 'HR', true) : ''}
                  ${shouldDisplayHours && shouldDisplayMinutes ? ' : ' : ''}
                  ${shouldDisplayMinutes ? getUnit(state.minutes, 'MIN', true) : ''}
                  ${shouldDisplayMinutes && shouldDisplaySeconds ? ' : ' : ''}
                  ${shouldDisplaySeconds ? getUnit(state.seconds, 'SEC', true) : ''}
                `}
              </Paragraph>
            </When>
            <Otherwise>
              <If condition={shouldDisplayDays}>
                <TimeContainer $flexDirection='column'>
                  <Heading variant='h3' color={textColor} fontWeight={600} className='time-value'> {getValue(state.days)} </Heading>
                  <Paragraph variant='p3' color={textColor} className='time-unit'> {getUnit(state.days, 'DAY')}</Paragraph>
                </TimeContainer>
                <If condition={shouldDisplayHours}>
                  <Spacer universal='16px' variant='horizontal' />
                  <Heading className='seperator' variant='h3' color={textColor} fontWeight={600}> : </Heading>
                  <Spacer universal='16px' variant='horizontal' />
                </If>
              </If>
              <If condition={shouldDisplayHours}>
                <TimeContainer $flexDirection='column'>
                  <Heading variant='h3' color={textColor} fontWeight={600} className='time-value'> {getValue(state.hours)} </Heading>
                  <Paragraph variant='p3' color={textColor} className='time-unit'> {getUnit(state.hours, 'HR')}</Paragraph>
                </TimeContainer>
                <If condition={shouldDisplayMinutes}>
                  <Spacer universal='16px' variant='horizontal' />
                  <Heading className='seperator' variant='h3' color={textColor} fontWeight={600}> : </Heading>
                  <Spacer universal='16px' variant='horizontal' />
                </If>
              </If>
              <If condition={shouldDisplayMinutes}>
                <TimeContainer $flexDirection='column'>
                  <Heading variant='h3' color={textColor} fontWeight={600} className='time-value'> {getValue(state.minutes)} </Heading>
                  <Paragraph variant='p3' color={textColor} className='time-unit'> {getUnit(state.minutes, 'MIN')}</Paragraph>
                </TimeContainer>
                <If condition={shouldDisplaySeconds}>
                  <Spacer universal='16px' variant='horizontal' />
                  <Heading className='seperator' variant='h3' color={textColor} fontWeight={600}> : </Heading>
                  <Spacer universal='16px' variant='horizontal' />
                </If>
              </If>
              <If condition={shouldDisplaySeconds}>
                <TimeContainer $flexDirection='column'>
                  <Heading variant='h3' color={textColor} fontWeight={600} className='time-value'> {getValue(state.seconds)} </Heading>
                  <Paragraph variant='p3' color={textColor} className='time-unit'> {getUnit(state.seconds, 'SEC')}</Paragraph>
                  <Spacer universal='16px' variant='horizontal' />
                </TimeContainer>
              </If>
            </Otherwise>
          </Choose>
        </Container>
      </When>
      <Otherwise>
        <></>
      </Otherwise>
    </Choose>
  )
}
