import React, { useEffect, useState } from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { Heading, Paragraph } from '@atoms/index'
import { calculateFlexBasis, getArrayFromNumber, IconEnum, Button, Link, Spacer } from '@client/components'
import { FormFactor, ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { PageTextCardFragment } from '@hooks/api'

const Container = styled.div<{ $paddingTop: string, $paddingBottom: string }>`
 display: flex;
 flex-direction: column;
 border-radius: 16px;

 background: ${(props): string => props.theme.colors.whites.pureWhite};

 ${ResponsivePXValue('min-width', { mobile: 'min(72vw, 288px)', tablet: '288px', desktop: '346px' })}
 ${ResponsivePXValue('max-width', { mobile: '72vw', tablet: '288px', desktop: '346px' })}
   
 ${ResponsivePXValue('padding', { mobile: '16px 12px', tablet: '16px 12px', desktop: '16px 0' })}

 ${(props): CSS => props?.$paddingTop ? ResponsivePXValue('padding-top', props.$paddingTop) : null};
 ${(props): CSS => props?.$paddingBottom ? ResponsivePXValue('padding-bottom', props.$paddingBottom) : null};

`
const ContentContainer = styled.div<{ $flexBasis: FormFactor | null }>`
  display: flex;
  flex-direction: column;

  flex-direction: ${(props): string => props?.$flexBasis ? 'row' : 'column'};
  flex-wrap: ${(props): string => props?.$flexBasis ? 'wrap' : 'initial'};

  ${ResponsiveProperty('justify-content', { mobile: 'center', desktop: 'initial' })}

  .content{

    ${ResponsivePXValue('padding-left', '10px')}
    ${ResponsivePXValue('padding-right', '10px')}

     ${ResponsiveProperty('justify-content', { mobile: 'center' })}
    
    ${(props): CSS => {
    if (props?.$flexBasis) {
      return `
        ${ResponsiveProperty('text-align', { mobile: 'center', desktop: 'initial' })}
        ${ResponsiveProperty('flex-basis', { mobile: 'initial', tablet: props.$flexBasis.tablet, desktop: props.$flexBasis.desktop })}
      `
    }
  }}
  
  }

`

const RatingContainer = styled.div`

  display: flex;
  justify-content: space-around;

  .sub-title {
    display: inline;
  }
`

const IconContainer = styled.div`

  display: flex;
  justify-content: space-around;
  .star-icon {

    cursor: initial;
    ${ResponsivePXValue('margin', '0px 5px')}
    ${ResponsivePXValue('width', '16px')}
    ${ResponsivePXValue('height', '16px')}
  }

`

export interface PageTextCardProps {
  pageTextCard: PageTextCardFragment
  className?: string
}

interface PageTextCardState {
  showMore: boolean
}

const DEFAULT_STATE: PageTextCardState = {
  showMore: false,
}

export function PageTextCard({ pageTextCard, className }: PageTextCardProps): JSX.Element {

  const [state, setState] = useState<PageTextCardState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  const isValidTitle = !!pageTextCard.title && pageTextCard.title.trim() !== ''
  const isValidSubTitle = !!pageTextCard.subTitle && pageTextCard.subTitle.trim() !== ''
  const contents = pageTextCard?.contents || []

  const hideContentOverflow = !!pageTextCard?.hideContentOverflow

  let flexBasis: FormFactor

  const contentColumns = pageTextCard?.contentColumns as unknown as FormFactor

  if (contents.length > 1 && contentColumns?.desktop) {
    flexBasis = calculateFlexBasis(contentColumns)
  } else {
    flexBasis = null
  }

  const actionClickHandler = () => {
    setState((prevState: PageTextCardState) => ({ ...prevState, showMore: !prevState.showMore }))
  }

  useEffect(() => {
    setState((prevState: PageTextCardState) => ({ ...prevState, showMore: hideContentOverflow }))
  }, [])

  const actionText = state.showMore ? 'Show More' : 'Show Less'
  const ratingArray = getArrayFromNumber(pageTextCard?.rating)

  let content: string
  let contentIndex: number
  let index: number

  return (
    <Container className={className} $paddingTop={pageTextCard?.paddingTop} $paddingBottom={pageTextCard?.paddingBottom}>
      <If condition={isValidTitle}>
        <Heading className='title' variant='h4' color={theme.colors.slates.bitter} align='center'>
          {pageTextCard.title}
        </Heading>
        <Spacer universal='16px' />
      </If>
      <If condition={isValidSubTitle}>
        <Choose>
          <When condition={!!pageTextCard?.rating}>
            <RatingContainer>
              <Heading className='sub-title' variant='h6' align='center'>
                {pageTextCard.subTitle}
              </Heading>
              <Spacer universal='16px' />
              <IconContainer>
                <For index="index" each='rating' of={ratingArray}>
                  <Button
                    className='star-icon'
                    color='transparent'
                    icon={IconEnum.STAR}
                    iconColor={theme.colors.yellows.goldenrod}
                    key={index} />
                </For>
              </IconContainer>
              <Spacer universal='16px' />
            </RatingContainer>
          </When>
          <Otherwise>
            <Heading className='sub-title' variant='h6' align='center'>
              {pageTextCard.subTitle}
            </Heading>
            <Spacer universal='16px' />
          </Otherwise>
        </Choose>
      </If>
      <If condition={contents.length > 0}>
        <Spacer universal='16px' />
        <ContentContainer $flexBasis={flexBasis}>
          <Choose>
            <When condition={state.showMore}>
              <Paragraph variant='p2' align='center'> {contents[0]} </Paragraph>
              <Spacer universal='16px' />
            </When>
            <Otherwise>
              <For each='content' of={contents} index='contentIndex'>
                <Paragraph className='content' variant='p2' align='center' key={contentIndex}> {content} </Paragraph>
                <Spacer universal='16px' />
              </For>
            </Otherwise>
          </Choose>
        </ContentContainer>
        <If condition={hideContentOverflow && contents.length > 1}>
          <Link onClick={actionClickHandler}> {actionText}</Link>
        </If>
      </If>
    </Container>
  )
}
