import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconEnum, IconSizeEnum, Spacer } from '@atoms/index'
import { ResponsivePXValue, ResponsiveProperty } from '@components/Theme'
import { MarketProductCategoryAislesFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { EducationalBanner, NavLink, NavLinks } from '../molecules'
import { MarketAislesCarousel, MarketSearch } from '../organisms'
import { SearchEngineOptimization } from '../utility'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: '1136px' }, { desktop: true })}

  .market-title {
    flex-shrink: 0;
  }
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: baseline;

  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'flex-start' })}
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', { mobile: '8px', desktop: '24px' })}
  cursor: pointer;
`
interface MarketAisleState {
  isLoading: boolean
  displayEducationalBanner: boolean
  hasMyShopItems: boolean
  marketProductCategories: MarketProductCategoryAislesFragment[]
}

const DEFAULT_STATE: MarketAisleState = {
  isLoading: false,
  displayEducationalBanner: false,
  hasMyShopItems: false,
  marketProductCategories: [],
}

export function MarketAisles(): JSX.Element {

  const [state, setState] = useState<MarketAisleState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMiniCard = !isDesktop

  const marketNavLinks: NavLink[] = state.marketProductCategories.map(marketProductCategory => {
    return {
      title: marketProductCategory.title,
      url: `/market/store/${marketProductCategory.slug}`,
      isActive: false,
    }
  })
  let boughtBeforeLink: NavLink[] = []

  if (state.hasMyShopItems) {
    boughtBeforeLink = [{
      title: 'Bought Before',
      url: '/market/store/bought-before',
      isActive: false,
    }]
  }

  const navLinks = [{
    title: 'All',
    url: '/market',
    isActive: true,
  },
  ...boughtBeforeLink,
  ...marketNavLinks,
  ]

  const _handleDataLoaded = (hasMyShopItems: boolean, marketProductCategories: MarketProductCategoryAislesFragment[]) => {
    setState((prevState) => update(prevState, {
      isLoading: { $set: false },
      hasMyShopItems: { $set: hasMyShopItems },
      marketProductCategories: { $set: marketProductCategories },
    }))
  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }

  useEffect(() => {
    if (!Cookies.get('displayEducationalBanner')) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
      Cookies.set('displayEducationalBanner', '0')
    }
  }, [])

  return (
    <Container>
      <SearchEngineOptimization />
      <Spacer mobile='16px' desktop='32px' />
      <TopContainer>
        <RowContainer>
          <HeadingContainer>
            <Heading variant='h1' className='market-title'> Shop Market </Heading>
            <Spacer desktop='8px' variant='horizontal' />
            <IconContainer onClick={_handleBannerToggle}>
              <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
            </IconContainer>
          </HeadingContainer>
          <If condition={isDesktop}>
            <MarketSearch />
          </If>
        </RowContainer>
        <If condition={!isDesktop}>
          <MarketSearch />
          <Spacer mobile='16px' />
        </If>
        <NavLinks outline navLinks={navLinks} />
        <If condition={state.displayEducationalBanner}>
          <Spacer universal='16px' />
          <EducationalBanner
            title='Welcome to UCOOK Market, where delicious convenience awaits!'
            description='Just so you know, our Market goods are only available when you purchase a Meal Kit. But, the good news is that you can add them to your order and voila! Breakfast and lunch are sorted too!'
            onClose={_handleOnClose} />
          <Spacer mobile='16px' />
        </If>
        <Spacer mobile='16px' desktop='32px' />
      </TopContainer>
      <MarketAislesCarousel onDataLoaded={_handleDataLoaded} isMiniCard={isMiniCard} />
    </Container>
  )
}
