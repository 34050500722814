import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { IconEnum, Button } from '@atoms/index'
import { ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`
  display: inline-flex;
`

const IconContainer = styled.div`
  div {
    padding: 0;
    margin: 0;
  }
  .modalStars{
    ${ResponsivePXValue('padding', { mobile: '0', tablet: '0px 3px', desktop: '0px 3px' })}
  }
`

interface RatingRowProps {
  rating?: number
  onClick: (id: number) => void
  className?: string
}
interface HoverRating {
  hoverRating: number
}

const DEFAULT_STATE: HoverRating = {
  hoverRating: 0,
}
export function RatingRow({ onClick, rating, className }: RatingRowProps): JSX.Element {
  const [state, setState] = useState<HoverRating>({ ...DEFAULT_STATE })

  const theme = useTheme()
  const getRatingColor = (index: number) => {
    if (state.hoverRating) {
      if (state.hoverRating > index) {
        return theme.colors.yellows.goldenrod
      } else {
        return theme.colors.whites.desertStorm
      }
    } else {
      if ((!!rating && rating > index)) {
        return theme.colors.yellows.goldenrod
      } else {
        return theme.colors.whites.desertStorm
      }
    }
  }
  const onMouseEnter = (index: number) => {
    setState((prevState) => update(prevState, {
      hoverRating: { $set: index },
    }))
  }

  const onMouseLeave = () => {
    setState((prevState) => update(prevState, {
      hoverRating: { $set: 0 },
    }))
  }
  const onTouchStart = (index: number) => {
    setState((prevState) => update(prevState, {
      hoverRating: { $set: index },
    }))
  }
  let index: number

  return (
    <Container className={className}>
      <For index="index" each='rating' of={[1, 2, 3, 4, 5]}>
        <IconContainer key={index}
          onMouseEnter={() => onMouseEnter(index + 1)}
          onMouseLeave={onMouseLeave}
          onTouchStart={() => onTouchStart(index + 1)}>
          <Button
            className='modalStars'
            color='transparent'
            icon={IconEnum.STAR}
            iconColor={getRatingColor(index)}
            onClick={() => onClick(state.hoverRating)} />
        </IconContainer>
      </For>
    </Container>
  )

}
