import React, { useState, Fragment, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Button, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { useUserDetailsQuery, useGetAppQuery, useUpdateUserMutation, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { InlineInput, InlineInputProps, ContentCard, ContentCardHeaderEnum, TextInput, Form, useForm, SectionLoading } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { DeleteAccountDrawer } from '../molecules/user/DeleteAccountDrawer'

const EditContainer = styled.div`
  display: flex;
`

interface FormData {
  firstName: string
  lastName: string
  email: string
  mobileNumber: string
  password: string
  confirmPassword: string
}

interface AccountState {
  editing: 'NONE' | 'NAME' | 'EMAIL' | 'NUMBER' | 'PASSWORD'
  showDeleteModal: boolean
}

const DEFAULT_STATE: AccountState = {
  editing: 'NONE',
  showDeleteModal: false,
}

const TextInputContainer = styled.div`
  display: flex;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}

  div {
    ${ResponsivePXValue('margin-top', '4px')}
    ${ResponsivePXValue('padding', { mobile: '4px 0', tablet: '6px 0', desktop: '0 24px 0 0' })}
  }
  input {
    ${ResponsivePXValue('width', { tablet: '300px', desktop: '324px' })}
  }
`
const FormButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${ResponsivePXValue('margin', { mobile: '16px 0', tablet: '16px 0', desktop: '16px 16px 0 0' })}
`

const ButtonContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '86px', tablet: '94px', desktop: '104px' })}
  ${ResponsivePXValue('margin', { mobile: '16px 0', tablet: '16px 0', desktop: '16px 16px 0 0' })}
  
  div {
    ${ResponsivePXValue('height', '32px')}
  }
  .save-button {
     ${ResponsivePXValue('margin-left', { mobile: '16px' })}
  }
  
`
const ButtonContainerDelete = styled.div`
  ${ResponsivePXValue('padding-top', '8px')}
  margin: 0 auto;
  display: inline-block;
  align-self: center;
`
const seo = {
  name: 'User Dashbaord',
  title: 'User Dashboard',
  meta: [{
    name: 'robots',
    content: 'noindex,nofollow',
  }],
}

export function Account(): JSX.Element {
  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<AccountState>({ ...DEFAULT_STATE })
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries,
    awaitRefetchQueries: true,
  })

  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const { addToast } = useToasts()
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const headerContent = {
    title: 'ACCOUNT DETAILS',
    description: 'You can update your personal information whenever you’d like.',
  }

  useEffect(() => {
    form.setFieldsValue({ firstName: registeredUser?.firstName })
    form.setFieldsValue({ lastName: registeredUser?.lastName })
    form.setFieldsValue({ email: registeredUser?.email })
    form.setFieldsValue({ mobileNumber: registeredUser?.phone })
  }, [registeredUser])

  const _handleSubmit = (data: FormData) => {

    setState((prevState) => ({ ...prevState, editing: 'NONE' }))

    switch (state.editing) {
      case 'NAME':
        updateUser({
          variables: {
            id: registeredUser.id,
            input: {
              firstName: data.firstName,
              lastName: data.lastName,
            },
          },
        })
        break
      case 'EMAIL':
        updateUser({
          variables: {
            id: registeredUser.id,
            input: { email: data.email },
          },
        })
        break
      case 'NUMBER':
        updateUser({
          variables: {
            id: registeredUser.id,
            input: { phone: data.mobileNumber },
          },
        })
        break
      case 'PASSWORD':
        if (validatePassword()) {
          updateUser({
            variables: {
              id: registeredUser.id,
              input: { password: data.password },
            },
          })
        } else {
          // TODO: Correct copy for error
          addToast('Password Incorrect', {
            appearance: 'error',
            autoDismiss: true,
          })
        }
        break
    }
  }

  const _showDeleteModal = ():void => {
    setState((prevState) => update(prevState, {
      showDeleteModal: { $set: true },
    }))
  }

  const _handleDeleteModalClose = ():void => {
    setState((prevState) => update(prevState, {
      showDeleteModal: { $set: false },
    }))
  }
  const validatePassword = (): boolean => {
    const password = form.getFieldValue('password')
    const confirmPassword = form.getFieldValue('confirmPassword')
    if (password.length < 8) {
      return false
    }
    if (password !== confirmPassword) {
      return false
    }
    return true
  }

  const rows: InlineInputProps[] =
    [{
      title: 'Name',
      text: state.editing === 'NAME' ? [] : [registeredUser?.firstName + ' ' + registeredUser?.lastName],
      button: state.editing === 'NAME'
        ? undefined
        : {
          title: 'EDIT',
          disabled: false,
          onClick: () => setState((prevState) => ({
            ...prevState,
            editing: prevState.editing === 'NAME' ? 'NONE' : 'NAME',
          })),
        },
      children: <Fragment>
        <If condition={state.editing === 'NAME'}>
          <EditContainer>
            <Form form={form} onFinish={_handleSubmit}>
              <TextInputContainer>
                <TextInput name='firstName' rules={[{ required: true, message: 'Please enter a first name' }]} variant='text' label='First Name' placeholder={registeredUser?.firstName} ></TextInput>
                <TextInput name='lastName' rules={[{ required: true, message: 'Please enter a last name' }]} variant='text' label='Last Name' placeholder={registeredUser?.lastName} ></TextInput>
              </TextInputContainer>
              <FormButtonsContainer>
                <Button variant='secondary' color='grey' title='CANCEL' onClick={() => { setState({ ...state, editing: 'NONE' }) }} />
                <Spacer universal='16px' variant='horizontal' />
                <Button title='SAVE' onClick={() => form.submit()} />
              </FormButtonsContainer>
            </Form>
          </EditContainer>
        </If>
      </Fragment>,

    }, {
      title: 'Email',
      text: state.editing === 'EMAIL' ? [] : [registeredUser?.email],
      button: state.editing === 'EMAIL'
        ? undefined
        : {
          title: 'EDIT',
          disabled: false,
          onClick: () => setState((prevState) => ({
            ...prevState,
            editing: state.editing === 'EMAIL' ? 'NONE' : 'EMAIL',
          })),
        },
      children: <Fragment>
        <If condition={state.editing === 'EMAIL'}>
          <EditContainer>
            <Form form={form} onFinish={_handleSubmit}>
              <TextInputContainer>
                <TextInput name='email' rules={[{ required: true, message: 'Please enter an email address' }]} variant='email' label='Email' placeholder={registeredUser?.email}></TextInput>
              </TextInputContainer>
              <FormButtonsContainer>
                <Button variant='secondary' color='grey' title='CANCEL' onClick={() => { setState({ ...state, editing: 'NONE' }) }} />
                <Spacer universal='16px' variant='horizontal' />
                <Button title='SAVE' onClick={() => form.submit()} />
              </FormButtonsContainer>
            </Form>
          </EditContainer>
        </If>
      </Fragment>,
    }, {
      title: 'Mobile Number',
      text: state.editing === 'NUMBER' ? [] : [registeredUser?.phone],
      button: state.editing === 'NUMBER'
        ? undefined
        : {
          title: 'EDIT',
          disabled: false,
          onClick: () => setState((prevState) => ({
            ...prevState,
            editing: state.editing === 'NUMBER' ? 'NONE' : 'NUMBER',
          })),
        },
      children: <Fragment>
        <If condition={state.editing === 'NUMBER'}>
          <EditContainer>
            <Form form={form} onFinish={_handleSubmit}>
              <TextInputContainer>
                <TextInput name='mobileNumber' rules={[{ required: true, message: 'Please enter a phone number' }]} variant='phone' label='Cellphone' placeholder={registeredUser?.phone}></TextInput>
              </TextInputContainer>
              <FormButtonsContainer>
                <Button variant='secondary' color='grey' title='CANCEL' onClick={() => { setState({ ...state, editing: 'NONE' }) }} />
                <Spacer universal='16px' variant='horizontal' />
                <Button title='SAVE' onClick={() => form.submit()} />
              </FormButtonsContainer>
            </Form>
          </EditContainer>
        </If>
      </Fragment>,
    }, {
      title: 'Password',
      text: state.editing === 'PASSWORD' ? [] : ['************'],
      rule: false,
      button: state.editing === 'PASSWORD'
        ? undefined
        : {
          title: 'EDIT',
          disabled: false,
          onClick: () => setState((prevState) => ({
            ...prevState,
            editing: prevState.editing === 'PASSWORD' ? 'NONE' : 'PASSWORD',
          })),
        },
      children: <Fragment>
        <If condition={state.editing === 'PASSWORD'}>
          <EditContainer>
            <Form form={form} onFinish={_handleSubmit}>
              <TextInputContainer>
                <TextInput name='password' rules={[{ required: true, message: 'Please enter a password' }]} variant='password' label='New Password'></TextInput>
                <TextInput name='confirmPassword' rules={[{ required: true, message: 'Please enter a verification' }]} variant='password' label='Confirm New Password'></TextInput>
              </TextInputContainer>
              <FormButtonsContainer>
                <Button variant='secondary' color='grey' title='CANCEL' onClick={() => { setState({ ...state, editing: 'NONE' }) }} />
                <Spacer universal='16px' variant='horizontal' />
                <Button title='SAVE' onClick={() => form.submit()} />
              </FormButtonsContainer>
            </Form>
          </EditContainer>
        </If>
      </Fragment>,
    }]

  let index: number
  let row: InlineInputProps

  return (
    <Fragment>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={userDetailsLoading || updateUserLoading}>
          <ContentCard title={headerContent.title} headerType={ContentCardHeaderEnum.TITLE_ONLY}>
            <SectionLoading />
          </ContentCard>
        </When>
        <Otherwise>
          <ContentCard title={headerContent.title} description={headerContent.description} headerType={ContentCardHeaderEnum.STANDARD}>
            <Fragment>
              <For each='row' of={rows} index='index'>
                <InlineInput key={index} {...row} />
              </For>
            </Fragment>
          </ContentCard>
        </Otherwise>
      </Choose>
      <If condition={isNativeApplication}>
        <ButtonContainerDelete>
          <Button
            className='delete-button'
            title="Delete Account"
            color='red'
            onClick={_showDeleteModal}/>
        </ButtonContainerDelete>
        <DeleteAccountDrawer
          userDetailsData={userDetailsData}
          open={state.showDeleteModal}
          onClose={_handleDeleteModalClose}/>
      </If>
    </Fragment>
  )
}
