import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MealKitDetails')

const MealKitDetails = loadable(() => import(/* webpackChunkName: "meal-kit-details" */'@pages/MealKitDetails'), {
  resolveComponent: (components) => components.MealKitDetails,
})

export function MealKitDetailsAsync(): JSX.Element {

  return <MealKitDetails fallback={<div>Loading...</div>} />

}
