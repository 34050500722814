import React, { useEffect, createRef } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { FlexDeviceContainer, Paragraph, Button, CountdownTimer, PageNotificationPopup } from '@client/components'
import { Spacer, IconEnum, ResponsiveImage, TextButtonColors } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { PageNotificationFragment } from '@hooks/api'

const Container = styled.div<{ $color?: string, $isPopup?: boolean }>`
  background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.oranges.coral};
  max-width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '12px', desktop: '16px' })}

  .close-button {
    ${ZeroSpace}
  }
  .flex-container {
    align-items: center;
  }

  ${(props): CSS => {
    if (props?.$isPopup) {
      return `
        padding: 0 !important;
        border-radius: 8px;
        overflow: hidden;
        ${ResponsiveProperty('position', { mobile: 'fixed', tablet: 'absolute', desktop: 'absolute' })}
        ${ResponsivePXValue('top', { desktop: '80px', tablet: '80px' })}
        ${ResponsivePXValue('right', { desktop: '20px', tablet: '20px', mobile: '0' })}
        ${ResponsivePXValue('bottom', { mobile: '0' })}
        ${ResponsivePXValue('left', { mobile: '0' })}
      `
    }
  }}
`

const FlexSpacer = styled.div`
  flex-grow: 1;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}

  .action-button {
    ${ResponsivePXValue('min-width', '64px')}
    ${ResponsivePXValue('margin', { mobile: '16px 0 0', tablet: '0 16px', desktop: '0 16px' })}
  }
`
const CloseButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`
const NotificationWrapper = styled.div`
  display: block;
`
const IconContainer = styled.div`
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('width', '24px')}
`

export interface PageNotificationBannerProps {
  notification: PageNotificationFragment | null
  dismissLoading?: boolean
  onHeightChange?: (height: number) => void
  onLinkClicked?: () => void
  onClose?: () => void
}

export function PageNotificationBanner({ notification, dismissLoading = false, onLinkClicked, onClose, onHeightChange }: PageNotificationBannerProps): JSX.Element {

  const navigate = useNavigate()
  const mobileRef: React.RefObject<HTMLDivElement> = createRef()

  const targetDate = notification?.endDate ? new Date(notification?.endDate) : null

  useEffect(() => {
    if (onHeightChange && mobileRef) {
      onHeightChange(mobileRef?.current?.clientHeight)
    }
  }, [mobileRef?.current?.clientHeight])

  const _handleOnLinkPressed = (): void => {
    navigate(notification?.link)
    onLinkClicked?.()
  }

  const buttonColors: TextButtonColors = {
    textColor: notification?.textColor || theme.colors.whites.pureWhite,
    textHoverColor: theme.colors.whites.desertStorm,
  }

  return (
    <Container $isPopup={notification?.isPopup} $color={notification?.backgroundColor}>
      <Choose>
        <When condition={!!notification?.isPopup}>
          <PageNotificationPopup
            notification={notification}
            dismissLoading={dismissLoading}
            onLinkClicked={onLinkClicked}
            onClose={onClose}
          />
        </When>
        <Otherwise>
          <FlexDeviceContainer ref={mobileRef} $mobile $tablet>
            <NotificationWrapper>
              <Choose>
                <When condition={!!notification?.link}>
                  <ContentContainer onClick={_handleOnLinkPressed}>
                    <Paragraph variant='p1' align='center' color={buttonColors.textColor}>{notification?.text}</Paragraph>
                    <If condition={notification?.shouldCountDownDisplay}>
                      <Spacer universal='16px' variant='horizontal' />
                      <CountdownTimer targetDate={targetDate} />
                    </If>
                  </ContentContainer>
                </When>
                <Otherwise>
                  <ContentContainer>
                    <Paragraph variant='p1' align='center' color={buttonColors.textColor}>{notification?.text}</Paragraph>
                    <If condition={notification?.shouldCountDownDisplay}>
                      <Spacer universal='16px' variant='horizontal' />
                      <CountdownTimer targetDate={targetDate} />
                    </If>
                  </ContentContainer>
                </Otherwise>
              </Choose>
              <CloseButtonContainer>
                <Button
                  color='transparent'
                  size='small'
                  icon={IconEnum.CLOSE_OUTLINE}
                  iconColor={buttonColors.textColor}
                  loading={dismissLoading}
                  disabled={dismissLoading}
                  onClick={() => onClose?.()} />
              </CloseButtonContainer>
            </NotificationWrapper>
          </FlexDeviceContainer>
          <FlexDeviceContainer className='flex-container' $desktop>
            <FlexSpacer />
            <If condition={!!notification?.icon}>
              <IconContainer>
                <ResponsiveImage image={notification.icon} />
              </IconContainer>
              <Spacer universal='8px' variant='horizontal' />
            </If>
            <ContentContainer>
              <Paragraph variant='p1' className='body-text' color={buttonColors.textColor}>{notification?.text}</Paragraph>
              <If condition={!!notification?.link}>
                <Button
                  title={notification?.linkText}
                  disabled={dismissLoading}
                  onClick={_handleOnLinkPressed}
                  href={notification.link}
                  className='action-button' />
              </If>
              <Spacer universal='16px' variant='horizontal' />
              <If condition={notification?.shouldCountDownDisplay}>
                <CountdownTimer targetDate={targetDate} />
              </If>
            </ContentContainer>
            <FlexSpacer />
            <If condition={notification?.canClose}>
              <Spacer universal='8px' />
              <Button
                color='transparent'
                size='small'
                icon={IconEnum.CLOSE_OUTLINE}
                iconColor={buttonColors.textColor}
                loading={dismissLoading}
                disabled={dismissLoading}
                onClick={() => onClose?.()} />
            </If>
          </FlexDeviceContainer>
        </Otherwise>
      </Choose>
    </Container>
  )
}
// on mobile Must be dismissible ! but x in top center
