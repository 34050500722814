import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Loader, Spacer } from '@client/components/atoms'
import { Aisles, WineCard, WineDiscount } from '@client/components/molecules'
import { AislesBaseContainer, AislesLoadingContainer } from '@client/components/Theme'
import { renderMiniProductGrids } from '@client/components/utility/RenderMiniCardGrids'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { WineAisleFragment, WineListFragment, useGetWineAislesQuery, useUserDetailsQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`
const LoadingContainer = styled.div`
  ${AislesLoadingContainer}
`

export interface WineAisleCarouselProps {
  onDataLoaded?: (wineAisles: WineAisleFragment[]) => void
  isMiniCard?: boolean
  slidesToScroll?: number
  aislesMaxWidth?: string
  urlSuffix?: string
  dataLimit?: number
  fadeThresholdLimit?: number
}

interface WineAisleCarouselState {
  hasLoggedImpression: boolean
}

const DEFAULT_STATE: WineAisleCarouselState = {
  hasLoggedImpression: false,
}

export const WineAislesCarousel = React.memo(({ onDataLoaded, isMiniCard, slidesToScroll = 1, aislesMaxWidth, urlSuffix = '', dataLimit = 20, fadeThresholdLimit }: WineAisleCarouselProps): JSX.Element => {

  const [state, setState] = useState<WineAisleCarouselState>({ ...DEFAULT_STATE })

  const { data, loading: aislesLoading } = useGetWineAislesQuery({ variables: { limit: dataLimit } })
  const events = useEvents()
  const wineAisles = data?.wineAisles?.isles ?? []
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const loading = aislesLoading
  let wineAisle: WineAisleFragment
  let wine: WineListFragment

  const displayFade = (list: unknown[]) => fadeThresholdLimit ? list?.length > fadeThresholdLimit : false

  useEffect(() => {

    if (!state.hasLoggedImpression && !aislesLoading && !!wineAisles.length) {
      for (let i = 0; i < wineAisles.length; i++) {
        const allDisplayedWines = wineAisles[i].products
        events.hasViewedCatalogue(
          allDisplayedWines?.map((item, displayIndex) => {
            const wine = item as WineListFragment

            const logData = {
              itemName: wine?.name,
              itemId: wine?.id,
              itemGroupId: wine?.id,
              price: wine?.price,
              itemBrand: 'UCOOK',
              itemCategory: 'Wines',
              itemVariant: wine?.wineCategory?.title,
              itemListName: 'Wines',
              index: displayIndex,
              itemImage: wine?.coverImage?.location,
            }

            const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
            return snakedData
          }),
          userDetailsData?.currentUser?.id)
      }
      setState((prevState) => update(prevState, {
        hasLoggedImpressions: { $set: true },
      }))
    }

  }, [aislesLoading || wineAisles])

  useEffect(() => {
    if (!!onDataLoaded && !loading) {
      onDataLoaded(wineAisles as WineAisleFragment[])
    }
  }, [loading])

  return (
    <Choose>
      <When condition={loading}>
        <LoadingContainer>
          <Loader noShadow />
        </LoadingContainer>
      </When>
      <Otherwise>
        <AislesContainer>
          <WineDiscount />
          <For each='wineAisle' of={wineAisles}>
            <Aisles
              title={wineAisle.title}
              viewAllUrl={`/wine/store/${wineAisle.asileSlug}${urlSuffix}`}
              className='aisles'
              isMiniCard={isMiniCard}
              slidesToScroll={slidesToScroll}
              aislesMaxWidth={aislesMaxWidth}
              displayFade={displayFade(wineAisle.products)}>
              <Choose>
                <When condition={isMiniCard}>
                  {renderMiniProductGrids(wineAisle.products as WineListFragment[])}
                </When>
                <Otherwise>
                  <For each='wine' of={wineAisle.products || []}>
                    <WineCard
                      className='product-card'
                      key={wine.id}
                      wine={wine}
                      loading={loading} />
                  </For>
                </Otherwise>
              </Choose>
            </Aisles>
            <Spacer universal='16px' />
          </For>
        </AislesContainer>
      </Otherwise>
    </Choose>
  )
})

WineAislesCarousel.displayName = 'WineAislesCarousel'
