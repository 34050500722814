import React from 'react'

import styled from 'styled-components'

import { PageMediaLeftStandard, PageMediaRightStandard, PageMediaCenterStandard } from '@client/components'
import { PageMediaSectionFragment } from '@hooks/api'
import { PageSectionMediaVariationEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex: 1;
`

export interface PageMediaStandardProps {
  pageSection: PageMediaSectionFragment
}

export function PageMediaStandard({ pageSection }: PageMediaStandardProps): JSX.Element {

  return (
    <Container>
      <Choose>
        <When condition={pageSection.variation === PageSectionMediaVariationEnum.LEFT_STANDARD}>
          <PageMediaLeftStandard pageSection={pageSection} />
        </When>
        <When condition={pageSection.variation === PageSectionMediaVariationEnum.RIGHT_STANDARD}>
          <PageMediaRightStandard pageSection={pageSection} />
        </When>
        <When condition={pageSection.variation === PageSectionMediaVariationEnum.CENTER_STANDARD}>
          <PageMediaCenterStandard pageSection={pageSection} />
        </When>
        <Otherwise>
          Page Media Standard not found
        </Otherwise>
      </Choose>
    </Container>
  )

}
