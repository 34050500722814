import React, { Fragment } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Seperator, Spacer } from '@atoms/index'
import { Heading } from '@client/components/atoms/typography/Heading'
import { Carousel } from '@client/components/molecules/misc/Carousel/Carousel'
import { CarouselCardWidth, CarouselContainerWidth, CarouselFlexContainer, CarouselSlideMargin, ResponsivePXValue } from '@components/Theme'
import { useActiveMenuDishesQuery, useGetAppQuery, MealKitDishDetailsFragment, ActiveMenuDishesQuery } from '@hooks/api'
import { MealKitCard, SectionLoading } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  display: flex;
  flex-direction: column;
 
  ${CarouselContainerWidth}

  ${ResponsivePXValue('margin', '24px 0')}
  .title {
    padding-top: 0;
  }
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}

  .meal-kit-card {
    ${CarouselCardWidth}
    ${ResponsivePXValue('margin', { desktop: '4px !important' })}

    .title {
     margin-left: 0;
    }
  }
`

const CardContainer = styled.div`
  display: flex;
  align-self: center;
 
  ${CarouselCardWidth}

  .meal-kit-card {
     ${CarouselSlideMargin}
  }
`
export interface MealKitOnMenuCarouselProps {
  title?: string
  skeleton?: boolean
  test?: boolean
}

export function MealKitOnMenuCarousel({ title, test }: MealKitOnMenuCarouselProps): JSX.Element {

  const variables = {
    test,
  }

  const { data, loading } = useActiveMenuDishesQuery({ variables })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const theme = useTheme()

  const maxDishesToDisplay = 24

  const menuDishes: ActiveMenuDishesQuery['activeMenu']['menuDishes'] = data?.activeMenu?.menuDishes
  const dishes: ActiveMenuDishesQuery['activeMenu']['menuDishes']['list'] = menuDishes?.list?.slice(0, maxDishesToDisplay) ? [...menuDishes?.list?.slice(0, maxDishesToDisplay)] : []

  let mealKit: MealKitDishDetailsFragment

  return (
    <Container id='mealKitOnMenuCarousel'>
      <Choose>
        <When condition={!!dishes}>
          <Fragment>
            <If condition={!!title}>
              <Heading variant='h4' align='center' className='title' color={theme.colors.slates.bitter}>{title}</Heading>
              <Spacer universal='8px' />
              <Seperator align='center' />
            </If>
            <Choose>
              <When condition={dishes.length === 1}>
                <CardContainer>
                  <MealKitCard
                    className='meal-kit-card'
                    key={mealKit.id}
                    mealKit={mealKit} />
                </CardContainer>
              </When>
              <When condition={isDesktop && dishes.length < 4}>
                <CardContainer>
                  <For each='mealKit' of={dishes}>
                    <MealKitCard
                      className='meal-kit-card'
                      key={mealKit.id}
                      mealKit={mealKit} />
                  </For>
                </CardContainer>
              </When>
              <Otherwise>
                <CarouselContainer>
                  <Carousel
                    displayNavButtons={isDesktop}>
                    <For each='mealKit' of={dishes}>
                      <MealKitCard
                        className='embla-slide meal-kit-card'
                        key={mealKit.id}
                        mealKit={mealKit} />
                    </For>
                  </Carousel>
                </CarouselContainer>
              </Otherwise>
            </Choose>
          </Fragment>
        </When>
        <When condition={loading}>
          <SectionLoading />
        </When>
        <Otherwise></Otherwise>
      </Choose>
    </Container>
  )
}
