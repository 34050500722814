import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Paragraph, Heading, scrollToLink, Button, getPageSectionButtonColor, Spacer } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PageWidth, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  ${ResponsivePXValue('max-height', '260x')}
  ${ResponsivePXValue('height', '244px')}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 100%;

  ${PageWidth}

  ${ResponsivePXValue('border-radius', '6px')}

  .text-spacing {
    ${ZeroSpace}
    ${ResponsivePXValue('margin-bottom', '16px')}
  }

  .banner-title {
    font-family: 'utopia';
    font-weight: 600;
    letter-spacing: 1px;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  height: 100%;
`

export interface MobilePageBannerHeroProps {
  pageBanner: PageBannerFragment
}

export function MobilePageBannerHero({ pageBanner }: MobilePageBannerHeroProps): JSX.Element {

  const navigate = useNavigate()
  const theme = useTheme()
  const config = useConfig()

  const linkUrl = pageBanner?.link?.link
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)
  const linkTitle = pageBanner?.link?.title
  const description = pageBanner?.description

  const mobileTitleColor = pageBanner?.mobileTitleColor ? pageBanner?.mobileTitleColor : theme.colors.slates.bitter

  const _handleLinkClick = (): void => {
    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container>
      <ContentContainer>
        <Choose>
          <When condition={!!description}>
            <Heading
              variant='h1'
              align='center'
              color={pageBanner.mobileTitleColor}
              className='banner-title'>
              {pageBanner.bannerTitle}
            </Heading>
            <If condition={!!pageBanner.subTitle}>
              <Spacer universal='16px' />
              <Paragraph
                align='center'
                variant='p1'
                color={pageBanner.mobileDescriptionColor}>
                {description}
              </Paragraph>
            </If>
            <Spacer universal='16px' />
          </When>
          <Otherwise>
            <Heading
              className='banner-title'
              variant='h2'
              align='center'
              color={mobileTitleColor}>
              {pageBanner.bannerTitle}
            </Heading>
            <If condition={!!pageBanner.subTitle}>
              <Spacer universal='16px' />
              <Heading
                variant='h6'
                align='center'
                color={pageBanner.mobileSubTitleColor}>
                {pageBanner.subTitle}
              </Heading>
            </If>
            <Spacer universal='16px' />
          </Otherwise>
        </Choose>
        <If condition={!!linkTitle}>
          <Button
            title={linkTitle}
            href={linkUrl}
            color={linkColor}
            onClick={_handleLinkClick} />
        </If>
      </ContentContainer>
      <ImageContainer>
        <ResponsiveImage
          image={pageBanner.mobileImage}
          objectFit={pageBanner?.mobileObjectFit} />
      </ImageContainer>
    </Container>
  )
}
