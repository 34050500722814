import React, { useEffect } from 'react'

import Cookies from 'js-cookie'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { Button, Heading, Link, Paragraph, ResponsiveImage, Spacer } from '@atoms/index'
import { ResponsivePXValue, ResponsiveProperty, ModalActionContainer, ModalScrollFade } from '@client/components/Theme'
import { DeviceContainer, scrollToLink } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetModalsQuery, useGetOnePageModalLazyQuery } from '@hooks/api'
import { Modal, SectionLoading } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', { desktop: '576px' })}

  .description {
    overflow-y: scroll;
    ${ResponsivePXValue('max-height', { mobile: '250px', desktop: '200px' })}
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  
  ${ResponsivePXValue('min-height', { mobile: '124px', tablet: '124px', desktop: '230px' })}
  ${ResponsivePXValue('max-height', { mobile: '124px', tablet: '124px', desktop: '230px' })}
`

const ActionContainer = styled.div`
  ${ModalActionContainer()}
  position: relative;
  ${ResponsivePXValue('bottom', { desktop: '10px' })}
  ${ResponsivePXValue('padding-top', { desktop: '15px' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  margin: 0 auto;
`

const OverflowContainer = styled.div`
  overflow-y: scroll;
  --mask-height: 10px;
  ${ModalScrollFade}
`

export function PageModal(): JSX.Element {
  const { data: modalsData = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const identifier = modalsData?.modals?.pageModalIdentifier

  const [getPageModalData, { data, loading }] = useGetOnePageModalLazyQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    if (identifier && identifier.trim() !== '') {
      getPageModalData({ variables: { identifier } })
    }
  }, [identifier])

  const location = useLocation()
  const hasCookie = modalsData?.modals?.pageModalHasCookie || false
  const locationLink = data?.pageModal?.locationLink

  const navigate = useNavigate()
  const config = useConfig()

  const _clearCookie = (): void => {
    if (hasCookie) {
      Cookies.set(identifier, 'true', { expires: 365 })
    }
  }

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.PAGE_MODAL, identifier)
    _clearCookie()
  }

  const _handleActionClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    const linkUrl = data?.pageModal?.actionLink
    if (linkUrl && config.isBrowser()) {
      _handleClose()
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else if (linkUrl.includes('#')) {
        scrollToLink(linkUrl)
      } else {
        navigate(linkUrl)
      }
    }
  }

  let shouldOpen = data?.pageModal?.isActive && modalsData?.modals?.pageModal

  if (locationLink) {
    shouldOpen = shouldOpen && locationLink?.includes(location?.pathname)
  }

  return (
    <Modal
      open={shouldOpen}
      onClose={_handleClose}
      allowBackgroundClose={false}
      fullscreen={false}
      resolutionAdapter>
      <Container>
        <Choose>
          <When condition={loading}>
            <SectionLoading />
          </When>
          <When condition={!data}>
            No Data
          </When>
          <Otherwise>
            <>
              <DeviceContainer $mobile $tablet>
                <Heading variant='h2' align='center' color={data?.pageModal?.mobileTitleColor}>
                  {data?.pageModal?.title}
                </Heading>
              </DeviceContainer>
              <DeviceContainer $desktop>
                <Heading variant='h2' align='center' color={data?.pageModal?.titleColor}>
                  {data?.pageModal?.title}
                </Heading>
              </DeviceContainer>
              <Spacer mobile='12px' desktop='16px' />
              <ImageContainer>
                <ResponsiveImage image={data?.pageModal?.image} />
              </ImageContainer>
              <Spacer mobile='12px' desktop='24px' />
              <DeviceContainer $mobile $tablet>
                <Heading variant='h5' align='center' color={data?.pageModal?.mobileSubTitleColor}>
                  {data?.pageModal?.subTitle}
                </Heading>
              </DeviceContainer>
              <DeviceContainer $desktop>
                <Heading variant='h5' align='center' color={data?.pageModal?.subTitleColor}>
                  {data?.pageModal?.subTitle}
                </Heading>
              </DeviceContainer>
              <Spacer universal='8px' />
              <DeviceContainer $mobile $tablet>
                <Paragraph className='description' variant='p1' color={data?.pageModal?.mobileDescriptionColor}>
                  {data?.pageModal?.description}
                </Paragraph>
              </DeviceContainer>
              <OverflowContainer>
                <DeviceContainer $desktop>
                  <Paragraph variant='p1' color={data?.pageModal?.descriptionColor}>
                    {data?.pageModal?.description}
                  </Paragraph>
                </DeviceContainer>
              </OverflowContainer>
              <Spacer universal='24px' />
              <ActionContainer>
                <DeviceContainer $mobile $tablet className='full-width'>
                  <Button
                    loading={loading}
                    color='black'
                    fullWidth
                    title={data?.pageModal?.actionTitle}
                    onClick={_handleActionClick} />
                </DeviceContainer>
                <DeviceContainer $desktop>
                  <Button
                    loading={loading}
                    color='black'
                    title={data?.pageModal?.actionTitle}
                    onClick={_handleActionClick} />
                </DeviceContainer>
                <DeviceContainer $mobile $tablet>
                  <Spacer mobile='8px' />
                </DeviceContainer>
                <DeviceContainer $desktop>
                  <Spacer desktop='16px' variant='horizontal' />
                </DeviceContainer>
                <Link variant='l2' decoration='underline' onClick={_handleClose}> No thanks </Link>
              </ActionContainer>
            </>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )
}
