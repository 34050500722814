import React, { useState } from 'react'

import update from 'react-addons-update'

import { ErrorBlock, Modal, ModalFormContainer, ModalLoading } from '@client/components'
import { useResetPasswordMutation } from '@hooks/api'

import { EnterNewPasswordForm } from '../forms/EnterNewPasswordForm'

export interface PasswordResetModalProps {
  open: boolean
  resetToken: string
  onClose: () => void
}

interface PasswordResetModalState {
  error: string | null
}

const DEFAULT_STATE: PasswordResetModalState = {
  error: null,
}

export function PasswordResetModal({ open, resetToken, onClose }: PasswordResetModalProps): JSX.Element {

  const [reset, { loading: resetLoading }] = useResetPasswordMutation()
  const [state, setState] = useState<PasswordResetModalState>({ ...DEFAULT_STATE })

  const _handleChange = async (password: string): Promise<void> => {
    try {
      await reset({
        variables: {
          token: resetToken,
          password,
        },
      })
      onClose?.()
    } catch (e) {
      setState((prevState) => update(prevState, { error: { $set: e.message } }))
    }
  }

  return (
    <Modal
      showCloseButton={!resetLoading}
      open={open}
      title='Reset Password'
      fullscreen={false}
      onClose={onClose}>
      <Choose>
        <When condition={resetLoading}>
          <ModalLoading message='Resetting Password' />
        </When>
        <When condition={!!state.error}>
          <ErrorBlock title={state.error}/>
        </When>
        <Otherwise>
          <ModalFormContainer>
            <EnterNewPasswordForm onPasswordSet={_handleChange} />
          </ModalFormContainer>
        </Otherwise>
      </Choose>
    </Modal>
  )
}
