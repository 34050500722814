import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Button, DeviceContainer, Heading, Link, Paragraph, ServingSizeEnum, Spacer } from '@client/components'
import { ResponsiveImage, SmallLoader } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { FrozenMealDishListFragment, FrozenMealListFragment } from '@hooks/api'
import { Counter, FieldData, Form, InputWrapper, SelectInput, StickyHealthyDining, StickyHealthyDiningEnum, useForm } from '@molecules/index'
import { FrozenPortionSizeEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100%', desktop: '600px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: '40px' })}
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .lite-text-button {
    ${ZeroSpace}
    font-weight: 400;
  }

  .header-text {
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', '16px')}
    line-height: 1;
  }

  .subtitle-text {
    ${ZeroSpace}
  }

  .price-text {
    ${ZeroSpace}
    line-height: 1;
  }

  .input {
    ${ZeroSpace}
  }
`

const SelectWrapper = styled.div<{ $singleTub: boolean }>`
  ${props => props.$singleTub ? 'display: none;' : ''}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: 'CALC(50% - 8px)' })}
`

const HeaderContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '150px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '150px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '8px' })}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding-top', '4px')}
  ${ResponsivePXValue('padding', { mobile: ' 16px 16px 0', tablet: '40px 40px 0', desktop: '4px 0 0' })}
`

const InputContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: ' 0 16px 0', tablet: '0 40px 0', desktop: '0' })}
`

const CounterContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: 'CALC(50% - 8px)' })}
  ${ResponsivePXValue('padding', { mobile: ' 16px 0 0', tablet: '40px 0 0', desktop: '0' })}
`

const SelectContainer = styled.div`
  font-weight: 700;
  ${ResponsivePXValue('font-size', '12px')}
  ${ResponsivePXValue('width', '100%')}
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '382px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`

export enum AddToCartProductTypeEnum {
  FROZEN = 'FROZEN',
}

export interface AddToCartFormProps {
  product: FrozenMealDishListFragment
  productType: AddToCartProductTypeEnum
  onAddToCart: (servingSize: FrozenPortionSizeEnum, quantity: number, price: number) => void
  onError: () => void
}

interface AddToCartFormState {
  loading: boolean
  productPrice: number | null
  productPriceRange: number[]
  disabled: boolean
  singleTub: boolean
  servingSelectionOptions: { title: string, value: string }[]
}

const DEFAULT_STATE: AddToCartFormState = {
  loading: false,
  productPrice: null,
  productPriceRange: [],
  disabled: true,
  singleTub: false,
  servingSelectionOptions: [],
}

export function AddToCartForm({ product, productType, onAddToCart }: AddToCartFormProps): JSX.Element {

  const [form] = useForm()
  const [state, setState] = useState<AddToCartFormState>({ ...DEFAULT_STATE })
  const [selectedServingSize, setSelectedServingSize] = useState<FrozenPortionSizeEnum | null>(null)
  const [quantity, setQuantity] = useState<number>(1)
  const navigate = useNavigate()

  const _handleSubmit = () => {
    setState(prevState => ({ ...prevState, loading: true }))
    onAddToCart(selectedServingSize, quantity, state.productPrice)
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSize') {
          setSelectedServingSize(field.value)
        }
      })
    })
  }

  const _handleCounterChange = (amount: number): void => {
    setQuantity(amount)
  }

  const _handleClick = (): void => {
    if (productType && productType === AddToCartProductTypeEnum.FROZEN) {
      navigate(`/frozen/craft-meal/${product.slug}`)
    }
  }

  const getServingSizeTitle = (product: FrozenMealListFragment): string => {
    let title = ServingSizeEnum[product.frozenPortionSize] as string

    if (product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      title = title + ' - Out of stock'
    }

    return title
  }

  useEffect(() => {
    if (product && productType === AddToCartProductTypeEnum.FROZEN) {
      const dish = product

      const servingOptions: { title: string, value: string }[] = dish?.products?.map((product) => ({
        title: getServingSizeTitle(product),
        disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
        value: product.id,
      })) || []
      const sortedOptions: { title: string, value: string }[] = servingOptions.sort((a, b) => a.title > b.title ? 1 : -1)

      const prices: number[] = dish?.products?.filter(product => (product.stockStatus !== ProductStockStatusEnum.OUT_OF_STOCK))
        .map((product) => product.price) || []
      const sortedPrices: number[] = prices.sort((a, b) => a - b)

      setState((prevState) => ({ ...prevState, product: dish, productPriceRange: sortedPrices, servingSelectionOptions: sortedOptions }))
    }
  }, [product])

  useEffect(() => {
    if (selectedServingSize) {
      if (productType && productType === AddToCartProductTypeEnum.FROZEN) {
        const price = product?.products?.find(product => product.id === selectedServingSize)?.price || 0
        setState((prevState) => ({ ...prevState, productPrice: price }))
      }
    }
  }, [selectedServingSize])

  const servingSelectionLabel = 'Serving Size'
  const loading = state.loading

  return (
    <Choose>
      <When condition={loading}>
        <LoadingContainer>
          <LoaderBox>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoaderBox>
        </LoadingContainer>
      </When>
      <Otherwise>
        <Form
          form={form}
          onFinish={_handleSubmit}
          onFieldsChange={_handleChange}>
          <Container>
            <HeaderContainer>
              <ImageContainer>
                <If condition={productType === AddToCartProductTypeEnum.FROZEN && product?.discoveryHealthyDiningItem}>
                  <StickyHealthyDining type={StickyHealthyDiningEnum.MEDIUM} />
                </If>
                <ResponsiveImage image={product.coverImage} />
              </ImageContainer>
              <Spacer variant='horizontal' universal='16px' />
              <TitleContainer>
                <Heading variant='h5'>{product.name}</Heading>
                <Spacer universal='16px' />
                <Paragraph variant='p3'>{product.subTitle}</Paragraph>
                <Spacer universal='16px' />
                <Heading variant='h5'>
                  {state.productPrice ? `R${state.productPrice}` : `R${state.productPriceRange[0]} - R${state.productPriceRange[state.productPriceRange.length - 1]}`}
                </Heading>
                <Spacer universal='16px' />
                <Link variant='l2' decoration='underline' onClick={_handleClick}> Full Details </Link>
              </TitleContainer>
            </HeaderContainer>
            <Spacer universal='24px' />
            <InputContainer>
              <SelectWrapper $singleTub={state.singleTub}>
                <SelectContainer>
                  <SelectInput
                    readOnly
                    name='servingSize'
                    placeholder='Select serving size'
                    label={servingSelectionLabel}
                    options={state.servingSelectionOptions}
                    rules={[{ required: true, message: 'Please select a serving size' }]}
                    itemsToDisplay={6}
                    className='input' />
                </SelectContainer>
              </SelectWrapper>
              <Spacer variant='horizontal' universal='16px' />
              <CounterContainer>
                <InputWrapper
                  required={true}
                  label={'Quantity'}
                  className='input'
                  showLabel >
                  <Counter
                    className='counter'
                    minimum={1}
                    amount={quantity}
                    onChange={_handleCounterChange} />
                </InputWrapper>
              </CounterContainer>
              <DeviceContainer $mobile>
                <Spacer universal='24px' />
                <Button
                  color='black'
                  fullWidth
                  title='ADD TO CART'
                  onClick={() => form.submit()} />
              </DeviceContainer>
            </InputContainer>
            <DeviceContainer $tablet $desktop>
              <Spacer universal='24px' />
              <Button
                color='black'
                fullWidth
                title='ADD TO CART'
                onClick={() => form.submit()} />
            </DeviceContainer>
          </Container>
        </Form>
      </Otherwise>
    </Choose>
  )
}
