import React, { ReactNode } from 'react'

import styled, { useTheme, CSS } from 'styled-components'

import { Heading, Rule, Paragraph, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@client/components/Theme'

export interface DashedTableProps {
  children?: JSX.Element[] | JSX.Element
  seperatorVariant?: 'double' | 'dashed' | 'solid' | 'none'
  color?: string
}

const Container = styled.div<{ $variant: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props): string => (props.$variant !== 'none') ? `border-top: ${props.$variant};` : ''}
`

export function DashedTable({ children, seperatorVariant, color = null }: DashedTableProps): JSX.Element {

  const theme = useTheme()

  if (color === null) {
    color = theme.colors.whites.desertStorm
  }

  let variant = ''
  switch (seperatorVariant) {
    case 'double':
      variant = `1px double ${color}`
      break
    case 'dashed':
      variant = `1px dashed ${color}`
      break
    case 'solid':
      variant = `1px solid ${color}`
      break
  }

  return (
    <Container $variant={variant}>
      {children}
    </Container>
  )

}

const RowContainer = styled.div`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LeftText = styled.div<{ $noBottomSpace: boolean }>`
  div {
    padding: 0;
    ${(props): CSS => {
    if (props.$noBottomSpace) {
      return ResponsivePXValue('margin', '10px 0 0 0')
    }
    return ResponsivePXValue('margin', '10px 0 8px 0')
  }}
  }
`

const RightText = styled.div<{ $leftMargin: boolean, $noBottomSpace: boolean }>`
  div {
    padding: 0;
    ${(props): CSS => {
    if (props.$leftMargin && props.$noBottomSpace) {
      return ResponsivePXValue('margin', '10px 0 0px 4px')
    } else if (props.$leftMargin && !props.$noBottomSpace) {
      return ResponsivePXValue('margin', '10px 0 8px 4px')
    } else if (!props.$leftMargin && props.$noBottomSpace) {
      return ResponsivePXValue('margin', '10px 0 0 0')
    }

    return ResponsivePXValue('margin', '10px 0 8px 0')
  }}
  
  }
`
export interface DashedTableRowProps {
  title?: ReactNode
  isTotal?: boolean
  seperated?: boolean
  noBottomSpace?: boolean
  children?: ReactNode
}

export function DashedTableRow({ title, isTotal = false, seperated = false, noBottomSpace = false, children }: DashedTableRowProps): JSX.Element {

  return (
    <RowContainer>
      <Spacer universal='8px' />
      <Row>
        <LeftText $noBottomSpace={noBottomSpace}>
          <Choose>
            <When condition={typeof title === 'string'}>
              <Choose>
                <When condition={isTotal}>
                  <Heading variant='h5'>{title as string}</Heading>
                </When>
                <Otherwise>
                  <Paragraph variant='p2' bold>{title}</Paragraph>
                </Otherwise>
              </Choose>
            </When>
            <Otherwise>
              {title}
            </Otherwise>
          </Choose>
        </LeftText>
        <RightText $leftMargin={!!title} $noBottomSpace={noBottomSpace}>
          {children}
        </RightText>
      </Row>
      <If condition={seperated}>
        <Spacer universal='8px' />
        <Rule color='slate' />
      </If>
      <Spacer universal='8px' />
    </RowContainer>
  )

}
