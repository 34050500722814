import React from 'react'

import styled from 'styled-components'

import { Loader } from '@atoms/index'
import { SidePagePadding, ResponsivePXValue } from '@components/Theme'
import { useSuppliersQuery, SupplierFragment } from '@hooks/api'
import { SupplierCard } from '@molecules/misc/SupplierCard'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  
  ${SidePagePadding}

  ${ResponsivePXValue('margin', '12px 0 0')}
  ${ResponsivePXValue('padding-top', '12px 0')}
  
  .supplier-card {
    ${ResponsivePXValue('margin', { mobile: '24px 0 36px', tablet: '0 24px 36px', desktop: '0 24px 36px' })}
  }
  
`

export interface SupplierGridProps {
  skip?: number | null
  limit?: number | null
  loadingIds?: string[]
  onShowDetails?: (slug: string) => void
}

export function SupplierGrid({ skip = 0, limit = 9, loadingIds = [], onShowDetails }: SupplierGridProps): JSX.Element {

  const variables = {
    skip,
    limit,
  }

  const { data, loading } = useSuppliersQuery({ variables })

  let supplier: SupplierFragment

  return (
    <Choose>
      <When condition={!!data}>
        <Container>
          <For each='supplier' of={data?.suppliers?.list || []}>
            <SupplierCard
              loading={loadingIds.includes(supplier.id)}
              className='supplier-card'
              key={supplier.id}
              supplier={supplier}
              onShowDetails={onShowDetails} />
          </For>
        </Container>
      </When>
      <When condition={loading}>
        <Loader noShadow />
      </When>
      <Otherwise>

      </Otherwise>
    </Choose>
  )

}
