import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Link, Paragraph } from '@client/components/atoms'
import { FieldData, Form, TextAreaInput } from '@client/components/molecules'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const AfterRatingContainer = styled.div`
  flex-direction: column;  
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
`
const FancyTitle = styled.p`
  align-items: center;
  font-family: Utopia;
  ${ResponsivePXValue('font-size', { mobile: '24px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '24px 24px' })}

`
const CloseButtonContainer = styled.div`
  ${ResponsivePXValue('padding-bottom', { mobile: '12px', tablet: '12px', desktop: '16px' })}
  text-decoration: underline;
  text-decoration-color: #CACACA;
`
const TextAreaContainer = styled.div`
  margin:0 auto;
  ${ResponsivePXValue('padding-bottom', '8px')}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '528px' })}
`
const ButtonsContainer = styled.div`
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '164px' })}
  ${ResponsivePXValue('padding-bottom', { mobile: '12px', tablet: '12px', desktop: '12px' })}
`

interface MoreInfoRatingModalState {
  tellMoreData:string
}

const DEFAULT_STATE: MoreInfoRatingModalState = {
  tellMoreData: '',
}
export interface FinalRatingPageProps {
  onSubmit: (moreInfo: string) => void
  onClose: () => void
  oldOrderComments: string
}

export function FinalRatingPage({ onSubmit: _handleSubmit, onClose: _handleClose, oldOrderComments }: FinalRatingPageProps): JSX.Element {

  const [state, setState] = useState<MoreInfoRatingModalState>({ ...DEFAULT_STATE })

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'tellMoreData') {
          setState((prevState) => update(prevState, {
            tellMoreData: { $set: field.value },
          }))
        }
      })
    })
  }
  const moreInfo = state.tellMoreData
  return (

    <AfterRatingContainer>
      <FancyTitle>
        Thanks for your feedback
      </FancyTitle>
      <TextAreaContainer>
        <Paragraph variant='p5' align='center'>We love to hear from you! Your feedback let&apos;s us know what&apos;s working, and what isn&apos;t.</Paragraph>
        <Paragraph variant='p5' align='center'> If you have anything to share, let us know below.</Paragraph>
        <Form onFieldsChange={_handleChange}>
          <TextAreaInput isCompact={true} name='tellMoreData' placeholder={oldOrderComments || 'Tell us more'}/>
        </Form>
      </TextAreaContainer>
      <ButtonsContainer>
        <If condition={state.tellMoreData.length === 0}>
          <ButtonsContainer>
            <Button fullWidth={true} color='grey' title='Done' />
          </ButtonsContainer>
        </If>
        <If condition={state.tellMoreData.length !== 0}>
          <ButtonsContainer>
            <Button fullWidth={true} variant='primary' color='black' title='Done' onClick ={() => _handleSubmit(moreInfo)} />
          </ButtonsContainer>
        </If>
      </ButtonsContainer>
      <CloseButtonContainer>
        <Link
          onClick={_handleClose}
          color={theme.colors.whites.silver}>CLOSE</Link>
      </CloseButtonContainer>
    </AfterRatingContainer>
  )

}
