import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { Button, IconEnum } from '@client/components/atoms'
import { ResponsivePXValue, ZeroSpace } from '@components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  flex: auto;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${ResponsivePXValue('padding-left', { mobile: '16px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('padding-right', { mobile: '16px', tablet: '32px', desktop: '32px' })}

  .range {
    ${ZeroSpace}

    display: flex;
    justify-content: space-between;

    ${ResponsivePXValue('width', '54px')}
  }
`
const Of = styled.div`
  display: flex;
`
const PageNumber = styled.div`
  display: flex;
`

export interface SimplePaginationProps {
  currentPage: number
  maxPages: number
  onPageChange?(pageIndex: number): void
}

export function SimplePagination({ currentPage, maxPages, onPageChange }: SimplePaginationProps): JSX.Element {

  const theme = useTheme()

  const _handleForward = (): void => {
    if (currentPage < maxPages) {
      onPageChange(currentPage + 1)
    }
  }

  const _handleBack = (): void => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <Container>
      <IconContainer>
        <Button
          className='page-arrow'
          color='transparent'
          icon={IconEnum.CHEVRON_BACK_OUTLINE}
          iconColor={theme.colors.greys.mineshaft}
          size='small'
          onClick={_handleBack} />
      </IconContainer>
      <TextContainer>
        <Paragraph variant='p2' className='range' color={theme.colors.greys.liteCodGrey}>
          <PageNumber>{currentPage | 0}</PageNumber>
          <Of> of </Of>
          <PageNumber>{maxPages | 0}</PageNumber>
        </Paragraph>
      </TextContainer>
      <IconContainer>
        <Button
          className='page-arrow'
          color='transparent'
          icon={IconEnum.CHEVRON_FORWARD_OUTLINE}
          iconColor={theme.colors.greys.mineshaft}
          size='small'
          onClick={_handleForward} />
      </IconContainer>
    </Container>
  )
}
