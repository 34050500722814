import React from 'react'

import styled from 'styled-components'

import { Heading, Rule, Paragraph, Button } from '@atoms/index'
import { CarouselCardWidth, ResponsivePXValue } from '@components/Theme'
import { MealKitCategoryFragment } from '@hooks/api'
import { ButtonGroup } from '@molecules/index'
import { NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
 
  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '16px', desktop: '16px' })}
  
  ${CarouselCardWidth}
`
const TextContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 25px', desktop: '0 25px' })}
`

const ButtonsContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '90%', tablet: '261px', desktop: '266px' })}
`
const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '12px 0', desktop: '16px 0' })}
  div {
    ${ResponsivePXValue('margin', '0')}
  }
`
const PriceSpacer = styled.div`
  div{
    ${ResponsivePXValue('padding-left', { mobile: '8px', tablet: '12px', desktop: '16px' })}
  }
`

const InfoContainer = styled.div`
  ${ResponsivePXValue('padding-top', '16px')}
`

const HeaderContainer = styled.div`
  h3{
     ${ResponsivePXValue('padding-bottom', '0px')}
  }
  div{
    ${ResponsivePXValue('padding', { mobile: '8px 24px 0 24px', tablet: '12px 24px 0 24px', desktop: '16px 24px 0 24px' })}
    ${ResponsivePXValue('margin-top', '0')}
  }
`

export interface SelectPlanCardProps {
  loading: boolean
  plan: MealKitCategoryFragment
  buttonTitle: string
  buttonEnabled?: boolean
  numberOfMeals: number
  numberOfPortions: NumberOfPortionsEnum
  onNumberOfMealsChange: (numberOfMeals: number) => void
  onNumberOfPortionsChange: (numberOfPortions: NumberOfPortionsEnum) => void
  handleChangeSubscription: (categoryId: string, numberOfMeals: number, numberOfPortions: NumberOfPortionsEnum) => void
  className?: string
}

export function SelectPlanCard({ loading, plan, buttonTitle, buttonEnabled, numberOfMeals, numberOfPortions, onNumberOfMealsChange, onNumberOfPortionsChange, handleChangeSubscription, className }: SelectPlanCardProps): JSX.Element {

  const _handleNumberOfMealsChange = (newValue: string): void => {
    onNumberOfMealsChange(Number(newValue) as number)
  }

  const _handleNumberOfPortionsChange = (newValue: string): void => {
    onNumberOfPortionsChange(newValue as NumberOfPortionsEnum)
  }

  const __getPricePerMeal = (): string => {
    const price = plan?.pricing?.find((planPrice) => { return planPrice.numberOfMeals === numberOfMeals && planPrice.numberOfPortions === numberOfPortions })
    let pricePerMealString: string
    if (price) {
      if (price.maxPricePerPortion === price.minPricePerPortion) {
        pricePerMealString = `R${price.maxPricePerPortion.toFixed(2)}`
      } else {
        pricePerMealString = `R${price.minPricePerPortion.toFixed(2)} - R${price.maxPricePerPortion.toFixed(2)}`
      }
    } else {
      pricePerMealString = 'R0'
    }
    return pricePerMealString
  }

  const __getPrice = (): string => {
    const price = plan?.pricing?.find((planPrice) => { return planPrice.numberOfMeals === numberOfMeals && planPrice.numberOfPortions === numberOfPortions })
    let priceString: string
    if (price) {
      if (price.maxPrice === price.minPrice) {
        priceString = `R${price.maxPrice.toFixed(2)}`
      } else {
        priceString = `R${price.minPrice.toFixed(2)} - R${price.maxPrice.toFixed(2)}`
      }
    } else {
      priceString = 'R0'
    }
    return priceString
  }

  return (
    <Container className={className}>
      <Heading variant='h5'>{plan?.title}</Heading>
      <TextContainer>
        <Paragraph bold align='center' variant='p1'>{plan?.description}</Paragraph>
      </TextContainer>
      <Rule />
      <ButtonsContainer>
        <ButtonGroup
          title='No. of recipes'
          value={String(numberOfMeals)}
          onChange={_handleNumberOfMealsChange} />
        <ButtonGroup
          title='Cooking for'
          apiEnum='NumberOfPortionsEnum'
          value={numberOfPortions}
          onChange={_handleNumberOfPortionsChange} />
      </ButtonsContainer>
      <InfoContainer>
        <Rule color='slate' />
        <PriceContainer>
          <Paragraph bold variant='p1'>Price per portion </Paragraph>
          <PriceSpacer>
            <Paragraph bold variant='p2'>{`${__getPricePerMeal()}`}</Paragraph>
          </PriceSpacer>
        </PriceContainer>
        <Rule color='slate' />
        <HeaderContainer>
          <Heading variant='h3' align='center'>{`${__getPrice()}`}</Heading>
          <Paragraph bold variant='p3' align='center'>All prices are inclusive of VAT</Paragraph>
        </HeaderContainer>
      </InfoContainer>
      <Button loading={loading} fullWidth disabled={!buttonEnabled} title={buttonTitle} onClick={() => { handleChangeSubscription(plan.id, numberOfMeals, numberOfPortions) }}></Button>
    </Container>
  )
}
