import React, { useState } from 'react'

import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import update from 'react-addons-update'

import { CheckoutPlugin } from '@api/local'
import { ErrorBlock, ModalFormContainer } from '@molecules/index'
import { CellphoneVerificationForm, EnterNewCellphoneNumberForm } from '@organisms/index'

enum VerifyNumberDisplayStepEnum {
  ENTER_NUMBER = 'ENTER_NUMBER',
  VERIFY_NUMBER = 'VERIFY_NUMBER',
  ERROR = 'ERROR',
}

interface VerifyNumberState {
  displayStep: VerifyNumberDisplayStepEnum
  error: string
}

const DEFAULT_STATE: VerifyNumberState = {
  displayStep: VerifyNumberDisplayStepEnum.ENTER_NUMBER,
  error: '',
}

export function VerifyNumber(): JSX.Element {

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<VerifyNumberState>({ ...DEFAULT_STATE })

  const switchStep = (displayStep: VerifyNumberDisplayStepEnum): void => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: displayStep },
    }))
  }

  const _handleSuccess = (): void => {
    CheckoutPlugin.shared().checkout(client)
  }

  return (
    <Choose>
      <When condition={state.displayStep === VerifyNumberDisplayStepEnum.ERROR}>
        <ErrorBlock
          title={state.error}
          actionTitle='BACK TO SIGN UP'
          onClick={() => switchStep(VerifyNumberDisplayStepEnum.ENTER_NUMBER)} />
      </When>
      <When condition={state.displayStep === VerifyNumberDisplayStepEnum.ENTER_NUMBER}>
        <ModalFormContainer>
          <EnterNewCellphoneNumberForm
            onNumberChanged={() => switchStep(VerifyNumberDisplayStepEnum.VERIFY_NUMBER)} />
        </ModalFormContainer>
      </When>
      <When condition={state.displayStep === VerifyNumberDisplayStepEnum.VERIFY_NUMBER}>
        <ModalFormContainer>
          <CellphoneVerificationForm
            onSuccess={_handleSuccess}
            onChangeNumber={() => switchStep(VerifyNumberDisplayStepEnum.ENTER_NUMBER)} />
        </ModalFormContainer>
      </When>
    </Choose>
  )

}
