import React from 'react'

import styled from 'styled-components'

import { Heading, SmallLoader } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '50px')}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '600px' })}

  .modal-loading-title {
    ${ResponsivePXValue('margin', '10px')}
  }
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('height', '32px')}
`

export interface ModalLoadingProps {
  message?: string
}

export function ModalLoading({ message }: ModalLoadingProps): JSX.Element {

  return (
    <LoadingContainer>
      <LoaderBox>
        <SmallLoader color={theme.colors.oranges.coral} />
      </LoaderBox>
      <If condition={!!message}>
        <Heading variant='h4' className='modal-loading-title'>{message}</Heading>
      </If>
    </LoadingContainer>
  )

}
