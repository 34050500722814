import React from 'react'

import styled, { CSS, useTheme } from 'styled-components'

import { DashedTable, DashedTableRow, Heading, ResponsivePXValue, Paragraph, Button } from '@client/components'
import { SummaryBlock } from '@client/components/molecules/stores/SummaryBlock'
import { MealKitCategoryFragment, useGetApiEnumQuery } from '@hooks/api'
import { NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;

  ${ResponsivePXValue('position', { desktop: 'sticky' })}
  ${ResponsivePXValue('top', { desktop: '105px' })}
`

const ElementWrapper = styled.div`
  .text-item {
    margin: 0;
    padding: 0;
  }
`

const ErrorBlock = styled.div`

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(251, 108, 89, 0.1);
  ${(props): CSS => {
    return ResponsivePXValue('border', `1px solid ${props.theme.colors.misc.error}`)
  }}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('padding', '16px 0')}
  ${ResponsivePXValue('margin-top', '16px')}

  .text {
    margin: 0;
    padding: 0;
  }
`

const InfoBlock = styled.div`

  .button {
    ${ResponsivePXValue('margin-top', '16px')}
  }

`

export interface CheckoutPlanSummaryProps {
  numberOfPortions: NumberOfPortionsEnum
  numberOfMeals: number
  category?: MealKitCategoryFragment
  loading?: boolean
  onCheckout?: () => void
}

export function CheckoutPlanSummary({ numberOfPortions, numberOfMeals, category, loading, onCheckout }: CheckoutPlanSummaryProps): JSX.Element {

  const { data: portionsData, loading: portionsLoading } = useGetApiEnumQuery({ variables: { enum: 'NumberOfPortionsEnum' } })
  const theme = useTheme()

  const isLoading = portionsLoading || loading
  const price = category?.pricing?.find((priceItem) => priceItem.numberOfMeals === numberOfMeals && priceItem.numberOfPortions === numberOfPortions).maxPrice
  const titleString = category?.title ?? 'No plan selected'
  const $titleElement = <Paragraph variant='p2'>{titleString}</Paragraph>

  const getTitleForPortionValue = (value: string): string => {
    return portionsData?.enum?.values?.find((enumOption) => enumOption.value === value).title ?? ''
  }

  const getTitleForMealsValue = (value: string): string => {
    return value
  }

  const __getNormalRowElement = (text: string): JSX.Element => {
    return (
      <ElementWrapper>
        <Paragraph className='text-item' variant='p1'>{text}</Paragraph>
      </ElementWrapper>
    )
  }

  const __getTotalRowElement = (text: string): JSX.Element => {
    return (
      <ElementWrapper>
        <Heading className='text-item' variant='h5'>{text}</Heading>
      </ElementWrapper>
    )
  }

  return (
    <Container>
      <SummaryBlock title='Plan Summary' loading={isLoading}>
        <DashedTable>
          <DashedTableRow seperated title={$titleElement} />
          <DashedTableRow title={__getNormalRowElement('No. of recipes')}>
            {__getNormalRowElement(getTitleForMealsValue(String(numberOfMeals)))}
          </DashedTableRow>
          <DashedTableRow seperated title={__getNormalRowElement('Cooking For')}>
            {__getNormalRowElement(getTitleForPortionValue(numberOfPortions))}
          </DashedTableRow>
          <If condition={!!category}>
            <DashedTableRow seperated title={__getTotalRowElement('TOTAL')}>
              {__getTotalRowElement(`R${price.toFixed(2)}`)}
            </DashedTableRow>
          </If>
        </DashedTable>
        <Choose>
          <When condition={!!category}>
            <InfoBlock>
              <Button
                className='button'
                title='CONTINUE'
                onClick={onCheckout} />
              <Paragraph
                className='text'
                align='center'
                variant='p3'
                color={theme.colors.slates.bitter}>
                All prices are inclusive of VAT
              </Paragraph>
            </InfoBlock>
          </When>
          <Otherwise>
            <ErrorBlock>
              <Paragraph
                className='text'
                variant='p3'
                color={theme.colors.misc.error}>
                Select a plan to see pricing
              </Paragraph>
            </ErrorBlock>
          </Otherwise>
        </Choose>
      </SummaryBlock>
    </Container>
  )

}
