import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CraftMealDetails')

const CraftMealDetails = loadable(() => import(/* webpackChunkName: "craft-meal-details" */'@pages/CraftMealDetails'), {
  resolveComponent: (components) => components.CraftMealDetails,
})

export function CraftMealDetailsAsync(): JSX.Element {

  return <CraftMealDetails fallback={<div>Loading...</div>} />

}
