import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { useForm, FieldData, Form, TextInput, Spacer, ModalMessageContainer, Paragraph, Button, Link } from '@client/components'
import { ModalActionContainer, ResponsivePXValue, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { UserDetailsDocument, useRequestPhoneVerificationMutation, useUserDetailsQuery, useVerifyPhoneMutation } from '@hooks/api'

const Container = styled.div`
  width: 100%;
`

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .input {
    ${ZeroSpace}
    ${ResponsivePXValue('width', '40px')}
    ${ResponsivePXValue('max-width', '40px')}
  }
`

const ActionContainer = styled.div`
  ${ModalActionContainer()};
`
export interface CellphoneVerificationFormProps {
  onSuccess: () => void
  onChangeNumber: () => void
}

interface CellphoneVerificationFormState {
  resendAllowed: boolean
  error: string
}

const DEFAULT_STATE: CellphoneVerificationFormState = {
  resendAllowed: true,
  error: '',
}

export function CellphoneVerificationForm({ onSuccess, onChangeNumber }: CellphoneVerificationFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<CellphoneVerificationFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [verifyPhone, { loading: verifyPhoneLoading }] = useVerifyPhoneMutation()
  const [requestPhoneVerification, { loading: requestPhoneVerificationLoading }] = useRequestPhoneVerificationMutation()
  const [form] = useForm()
  const numbers = [] as string[]

  const toggleResendAllowed = (value: boolean): void => {
    setState((prevState) => update(prevState, {
      resendAllowed: { $set: value },
    }))
  }

  const getFormFieldNumber = (name: string): number => {
    switch (name) {
      case 'num1':
        return 1
      case 'num2':
        return 2
      case 'num3':
        return 3
      case 'num4':
        return 4
      case 'num5':
        return 5
    }
  }

  const _handleChange = (changedFields: FieldData[], _allFields: FieldData[]) => {
    let nextField: Element = null
    let i: number

    let aField: Element = null
    if (changedFields[0].value?.length > 1) {
      const value = changedFields[0].value
      const characters: string[] = Array.from(value)
      const fieldNumber = getFormFieldNumber(changedFields[0].name.toString())
      for (let f = fieldNumber; f <= _allFields.length; f++) {
        switch (f) {
          case 1:
            form.setFieldsValue({ num1: characters[f - 1] ?? '' })
            numbers[f] = characters[f - 1]
            break
          case 2:
            form.setFieldsValue({ num2: characters[f - 1] ?? '' })
            numbers[f] = characters[f - 1]
            break
          case 3:
            form.setFieldsValue({ num3: characters[f - 1] ?? '' })
            numbers[f] = characters[f - 1]
            break
          case 4:
            form.setFieldsValue({ num4: characters[f - 1] ?? '' })
            numbers[f] = characters[f - 1]
            break
          case 5:
            form.setFieldsValue({ num5: characters[f - 1] ?? '' })
            numbers[f] = characters[f - 1]
            break
        }
        aField = document.getElementsByName(`num${f}`)[0]
        if (aField !== null) {
          if (f === _allFields.length) {
            _handleSubmit()
          } else {
            (aField as HTMLElement).focus()
          }
        }
      }
    } else {
      for (i = 0; i < _allFields.length; i++) {
        const field = _allFields[i]
        if (!field.value) {
          nextField = document.querySelector(`div.cellphoneVerificationForm input[name='${field.name}']`)
          break
        } else {
          numbers[i] = field.value
        }
      }

      if (nextField !== null) {
        (nextField as HTMLElement).focus()
      } else {
        _handleSubmit()
      }
    }
  }

  const _handleSubmit = async (): Promise<void> => {
    let code = ''
    numbers.forEach((value) => {
      code += value
    })
    try {
      await verifyPhone({
        variables: {
          code,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      onSuccess?.()
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleChangeNumber = () => {
    onChangeNumber?.()
  }

  const _handleResendCode = async (): Promise<void> => {
    try {
      form.resetFields()
      toggleResendAllowed(false)
      await requestPhoneVerification({
        variables: {
          number: userDetailsData?.currentUser?.phone,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      setTimeout(() => {
        toggleResendAllowed(true)
      }, 30000)
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  let value: number
  const loading = userDetailsLoading || verifyPhoneLoading || requestPhoneVerificationLoading

  return (
    <Container className='cellphoneVerificationForm'>
      <Form form={form} onFieldsChange={_handleChange} disabled={loading}>
        <Paragraph variant='p4' align='center'>{` Please enter the 5 digit verification pin sent to ${userDetailsData?.currentUser?.phone} to verify your account.`}</Paragraph>
        <Spacer universal='24px' />
        <InputContainer>
          <Spacer universal='16px' variant='horizontal' />
          <For each='value' of={[1, 2, 3, 4, 5]}>
            <TextInput
              name={`num${value}`}
              label={`Number ${value}`}
              variant='text'
              placeholder='0'
              rules={[{ required: true, message: '!' }]}
              showLabel={false}
              textAlign='center'
              className='input' />
            <Spacer universal='16px' variant='horizontal' />
          </For>
        </InputContainer>
        <Spacer universal='16px' />
        <If condition={state.error !== ''}>
          <ModalMessageContainer>
            <Paragraph variant='p1' align='center' className='error'>
              {state.error}
            </Paragraph>
            <Spacer universal='12px' />
            <Paragraph variant='p1' align='center'>
              Get in touch with Customer Support on 021 447 4424 or email us at support@ucook.co.za
            </Paragraph>
          </ModalMessageContainer>
          <Spacer universal='24px' />
        </If>
        <ActionContainer>
          <Button
            loading={loading}
            disabled={loading || !state.resendAllowed}
            variant='secondary'
            color='black'
            fullWidth
            title='RESEND CODE'
            onClick={_handleResendCode} />
          <Spacer universal='12px' />
          <Link variant='l2' decoration='underline' onClick={_handleChangeNumber}> USE A DIFFERENT NUMBER </Link>
        </ActionContainer>
      </Form>
    </Container>
  )
}
