import React, { useState, useEffect, Fragment } from 'react'

import styled from 'styled-components'

import { ContentSeperator, Heading, IconEnum, SmallLoader, Paragraph, Link } from '@client/components/atoms'
import { ContentCard, ContentCardHeaderEnum } from '@client/components/molecules'
import { ReferralScorecardItem } from '@client/components/molecules/misc/ReferralScorecardItem'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { LoyaltyTierFragment, RegisteredUserDetailsFragment, useFriendReferenceCountLazyQuery, useGetAllFriendReferencesLazyQuery, useGetAllLoyaltyTiersLazyQuery, useGetUserPointsBalanceLazyQuery, useRafDashboardCountLazyQuery, UserDetailsFragment, UserDetailsQuery } from '@hooks/api'
import { LoyaltyTier, UserStatusEnum, UserReferralStatusEnum, FriendReference } from '@uctypes/api/globalTypes'

import { PercentBar } from '../misc/PercentBar'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: flex-start;

  .show-button {
    ${ResponsivePXValue('margin', '0')}
    ${ResponsivePXValue('padding', '0')}
  }

`

const ScorecardContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', '24px 0')}
  ${ResponsivePXValue('border-radius', { mobile: '8px 8px 0px 0px', tablet: '16px 16px 0px 0px', desktop: '16px 16px 0px 0px' })}
  background-color: ${(props): string => props.theme.colors.whites.alabaster};

`

const LoyaltyTierContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', 'CALC(100% - 64px)')}
  ${ResponsivePXValue('padding', '12px 32px')}

  .tier-header {
    ${ResponsivePXValue('padding', { mobile: '12px 0 0', tablet: '24px 0 16px', desktop: '24px 0 16px' })}
  }

  .tier-text {
    ${ResponsivePXValue('margin', { mobile: '6px 0 0', tablet: '24px 0 16px', desktop: '24px 0 16px' })}
  }

  .tier-details {
    ${ResponsivePXValue('margin', '6px 0')}
  }

  .text {
    ${ResponsivePXValue('margin', '12px 0 0')}
    ${ResponsivePXValue('padding', '0')}
  }

`

const LoyaltyTierTitleContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('justify-content', { mobile: 'flex-start', tablet: 'space-between', desktop: 'space-between' })}
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('margin', '0 0 8px')}
`

interface ReferralScoreboardState {
  orderCount: number
  successfulReferralCount: number
  pointsEarned: number
  userPoints: number
  currentTierOrdersNeeded: number
  currentLoyaltyTier: LoyaltyTierFragment | null
  allTiers: LoyaltyTier[]
  isMaxTier: boolean
  showMoreOpen: boolean
}

const DEFAULT_STATE: ReferralScoreboardState = {
  orderCount: 0,
  successfulReferralCount: 0,
  pointsEarned: 0,
  userPoints: 0,
  currentTierOrdersNeeded: 0,
  currentLoyaltyTier: null,
  allTiers: [] as LoyaltyTier[],
  isMaxTier: false,
  showMoreOpen: false,
}

export interface ReferralScoreboardProps {
  userDetailsData: UserDetailsQuery
}

const ordersRemaining = (orders: number, ordersNeeded: number): number => {
  return (ordersNeeded - orders)
}

const nextTierReward = (currentLoyaltyTier: LoyaltyTierFragment, allTiers: LoyaltyTier[]): number => {
  const currentTierIndex = allTiers.findIndex((tier) => tier.id === currentLoyaltyTier.id)
  try {
    const nextTier = allTiers[currentTierIndex + 1]
    return nextTier?.referrerPoints
  } catch {
    return currentLoyaltyTier?.referrerPoints
  }
}

export function ReferralScoreboard({ userDetailsData }: ReferralScoreboardProps): JSX.Element {

  const [state, setState] = useState<ReferralScoreboardState>({ ...DEFAULT_STATE })
  const [getFriendReferences, { data: friendReferencesData, loading: friendReferencesLoading }] = useGetAllFriendReferencesLazyQuery()
  const [rafDashboardCount, { data: countOrders }] = useRafDashboardCountLazyQuery()
  const [getFriendReferencesCount, { data: countFriendReferences }] = useFriendReferenceCountLazyQuery()
  const [getUserPointBalance, { data: balance }] = useGetUserPointsBalanceLazyQuery()
  const [getAllLoyaltyTiers, { data: allLoyaltyTiers }] = useGetAllLoyaltyTiersLazyQuery()

  useEffect(() => {
    if (userDetailsData?.currentUser) {
      if (userDetailsData.currentUser.status !== UserStatusEnum.GUEST) {
        rafDashboardCount({ variables: { userId: userDetailsData.currentUser.id } })
        getFriendReferencesCount({ variables: { filters: { referredBy: userDetailsData.currentUser.id, status: [UserReferralStatusEnum.ORDERED] } } })
        getFriendReferences({ variables: { filters: { referredBy: userDetailsData.currentUser.id, status: [UserReferralStatusEnum.ORDERED] } } })
        getUserPointBalance({ variables: { userId: userDetailsData.currentUser.id } })
        const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
        const ordersNeeded = registeredUser?.loyaltyTier?.orderCount
        const currentTier = registeredUser?.loyaltyTier
        if (currentTier?.nextLoyaltyTier === null) {
          setState((prevState) => ({
            ...prevState,
            currentTierOrdersNeeded: ordersNeeded,
            currentLoyaltyTier: registeredUser.loyaltyTier,
            isMaxTier: true,
          }))
        } else {
          getAllLoyaltyTiers()
          setState((prevState) => ({
            ...prevState,
            currentTierOrdersNeeded: ordersNeeded,
            currentLoyaltyTier: registeredUser.loyaltyTier,
          }))
        }
      }
    }
  }, [userDetailsData])

  useEffect(() => {
    if (allLoyaltyTiers?.loyaltyTiers) {
      const tiers = allLoyaltyTiers?.loyaltyTiers.list as LoyaltyTier[]
      if (state.isMaxTier) {
        const secondLastTier = tiers[(tiers.length - 1)]
        setState((prevState) => ({ ...prevState, currentTierOrdersNeeded: secondLastTier.orderCount }))
      }
      setState((prevState) => ({ ...prevState, allTiers: tiers }))
    }
  }, [allLoyaltyTiers])

  useEffect(() => {
    if (countFriendReferences?.countFriendReferences) {
      setState((prevState) => ({
        ...prevState,
        successfulReferralCount: countFriendReferences?.countFriendReferences,
      }))
    }
  }, [countFriendReferences])

  useEffect(() => {
    if (countOrders?.rafDashboardCount) {
      setState((prevState) => ({ ...prevState, orderCount: countOrders?.rafDashboardCount }))
    }
  }, [countOrders])

  useEffect(() => {
    if (balance?.balance) {
      setState((prevState) => ({ ...prevState, userPoints: balance?.balance }))
    }
  }, [balance])

  useEffect(() => {
    if (friendReferencesData?.friendReferences) {
      const friendReferences = friendReferencesData.friendReferences.list as FriendReference[]
      let totalPoints = 0

      for (const reference of friendReferences) {
        totalPoints = totalPoints + reference.loyaltyTier.referrerPoints
      }

      setState((prevState) => ({
        ...prevState,
        pointsEarned: totalPoints,
      }))
    }
  }, [friendReferencesData])

  const _handleShowClick = async (): Promise<void> => {
    setState((prevState) => ({
      ...prevState,
      showMoreOpen: !state.showMoreOpen,
    }))
  }

  let loyaltyTier: LoyaltyTier

  return (
    <Fragment>
      <ContentCard headerType={ContentCardHeaderEnum.NONE}>
        <Choose>
          <When condition={friendReferencesLoading}>
            <SmallLoader />
          </When>
          <Otherwise>
            <MainContainer>
              <ScorecardContainer>
                <ReferralScorecardItem
                  icon={IconEnum.PACKAGE}
                  title='Meal Kit Orders'
                  subTitle={`${state.orderCount} orders`} />
                <ReferralScorecardItem
                  icon={IconEnum.USER_PLUS}
                  title='Successful referrals '
                  subTitle={`${state.successfulReferralCount} Referrals`} />
                <ReferralScorecardItem
                  icon={IconEnum.CREDIT_CARD}
                  title='Points earned'
                  subTitle={`${state.pointsEarned} Points`} />
                <ReferralScorecardItem
                  icon={IconEnum.DOLLAR_SIGN}
                  title='Points available'
                  subTitle={`${state.userPoints} Points`} />
              </ScorecardContainer>
              <LoyaltyTierContainer>
                <Choose>
                  <When condition={state.isMaxTier}>
                    <LoyaltyTierTitleContainer>
                      <Heading
                        className='tier-header'
                        variant='h5'>
                        {state.currentLoyaltyTier?.name}
                      </Heading>
                      <Paragraph
                        className='tier-text'
                        variant='p1' >
                        {`${state.currentLoyaltyTier?.orderCount}+ Orders`}
                      </Paragraph>
                    </LoyaltyTierTitleContainer>
                    <PercentBar value={state.currentTierOrdersNeeded} total={state.currentTierOrdersNeeded} />
                    <Paragraph
                      variant='p3'>
                      {`Congrats! You’re the top of the food chain – as a Master Chef, you can give your friends 30% off their first Meal Kit order and get R${state.currentLoyaltyTier.referrerPoints} back every time a friend’s first order is processed. Keep referring to maintain your Master Chef status. `}
                    </Paragraph>
                  </When>
                  <Otherwise>
                    <LoyaltyTierTitleContainer>
                      <Heading
                        className='tier-header'
                        variant='h5'>
                        {state.currentLoyaltyTier?.name}
                      </Heading>
                      <Paragraph
                        className='tier-text'
                        variant='p1' >
                        {`${state.orderCount} of ${state.currentLoyaltyTier?.orderCount} orders`}
                      </Paragraph>
                    </LoyaltyTierTitleContainer>
                    <PercentBar value={state.orderCount} total={state.currentTierOrdersNeeded} />
                    <Paragraph
                      className='text'
                      variant='p3'>
                      {`Order ${ordersRemaining(state.orderCount, state.currentTierOrdersNeeded)} more Meal Kits to reach the next tier and earn R${nextTierReward(state.currentLoyaltyTier, state.allTiers)} with every successful new referral`}
                    </Paragraph>
                  </Otherwise>
                </Choose>
                <Choose>
                  <When condition={state.showMoreOpen} >
                    <ContentSeperator margin='12px 0' />
                    <For each='loyaltyTier' of={state.allTiers}>
                      <Paragraph className='tier-details' variant='p2'>{loyaltyTier.name}</Paragraph>
                      <Paragraph className='tier-details' variant='p3'>{loyaltyTier.description}</Paragraph>
                    </For>
                    <Link
                      className='show-button'
                      color={theme.colors.oranges.coral}
                      onClick={_handleShowClick}>Show less</Link>
                  </When>
                  <Otherwise>
                    <Link
                      className='show-button'
                      color={theme.colors.oranges.coral}
                      onClick={_handleShowClick}>VIEW ALL TIERS</Link>
                  </Otherwise>
                </Choose>
              </LoyaltyTierContainer>
            </MainContainer>
          </Otherwise>
        </Choose>
      </ContentCard>
    </Fragment>
  )
}
