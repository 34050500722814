import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph, Link } from '@atoms/index'
import { Button, ResponsivePXValue, Spacer, ZeroSpace, theme } from '@client/components'
import { Modal } from '@client/components/molecules'

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding', { mobile: '0 0 0 6px', tablet: '0 0 0 6px', desktop: '0 0 0 16px' })}

  .header {
    ${ZeroSpace}
     ${ResponsivePXValue('font-size', '16px')}
     ${ResponsivePXValue('line-height', '24px')}
     ${ResponsivePXValue('margin-bottom', '16px')}
  }
  
  .text {
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', '14px')}
    ${ResponsivePXValue('line-height', '24px')}
    ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
  }

  .button {
    align-self: center;
    ${ResponsivePXValue('width', '100%')}
    ${ResponsivePXValue('padding', { mobile: '0 6px', tablet: '0 6px', desktop: '0 6px' })}
  }
`

export interface ReferralModalProps {
  open: boolean
  onClose: () => void
  onSeeMore: () => void
  onGetStarted: () => void
}

export function ReferralModal({ open, onClose, onSeeMore, onGetStarted }: ReferralModalProps): JSX.Element {

  const _handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const _handleSeeMenu = (): void => {
    if (onSeeMore) {
      onSeeMore()
    }
  }

  const _handleGetStarted = (): void => {
    if (onGetStarted) {
      onGetStarted()
    }
  }

  return (
    <Modal
      variant='multimedia'
      staticId='news-letter-cover'
      allowScroll={true}
      fullscreen={false}
      allowBackgroundClose={false}
      showCloseButton
      open={open}
      onClose={_handleClose} >
      <ModalContainer>
        <ContentContainer>
          <Heading variant='h3' className='header' align='center'>
            Your friend is loving UCOOK (and thinks you will too)
          </Heading>
          <Spacer variant='horizontal' universal='16px' />
          <Paragraph variant='p1' className='text' align='center'>
            You’ve got great friends, and one of them has given you 30% off your first Meal Kit order. Pretty neat, right? Get started by taking a look at this week’s menu.
          </Paragraph>
          <Spacer variant='horizontal' universal='24px' />
          <Button
            className='button'
            title='SEE WHAT’S COOKING'
            color='orange'
            onClick={() => _handleSeeMenu()} />
          <Spacer variant='horizontal' universal='16px' />
          <Link
            className='button'
            color={theme.colors.oranges.coral}
            onClick={() => _handleGetStarted()}>GET STARTED</Link>
        </ContentContainer>
      </ModalContainer>
    </Modal>
  )

}
