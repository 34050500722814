import React, { Fragment } from 'react'

import styled from 'styled-components'

import { Heading, Seperator, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

export interface LegalTextBody {
  heading?: string
  paragraphs: string | React.ReactNode[]
}
export interface LegalTextProps {
  heading?: string
  body: LegalTextBody[]
  className?: string
}

const Container = styled.div`
  ${ResponsivePXValue('padding', { mobile: '32px', tablet: '40px 345px', desktop: '48px 345px' })};
  width: 100%;
`

export function LegalText({ heading, body, className }: LegalTextProps): JSX.Element {
  let bodyElement: LegalTextBody
  let bodyIndex: number
  let paragraph: string
  let paragraphIndex: number
  return (
    <Container className={className}>
      <If condition={!!heading}>
        <Heading variant='h3'>
          {heading}
        </Heading>
        <Seperator />
      </If>
      <For each='bodyElement' of={body} index='bodyIndex'>
        <Fragment key={bodyIndex}>
          <If condition={!!bodyElement.heading}>
            <Heading variant='h5'>{bodyElement.heading}</Heading>
          </If>
          <For each='paragraph' of={bodyElement.paragraphs} index='paragraphIndex'>
            <Paragraph key={paragraphIndex}>{paragraph}</Paragraph>
          </For>
        </Fragment>
      </For>
    </Container>
  )

}
