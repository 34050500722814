import React from 'react'

import { useNavigate } from 'react-router'

import { APP_DEFAULT_STATE } from '@api/local'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { ContentSeperator, Heading, IconEnum, MenuNavItemContainer } from '@client/components'
import { Drawer } from '@client/components/molecules'
import { useConfig } from '@contexts/ConfigProvider'
import { useGetNavigationQuery, useGetAppQuery, MainNavContainerFragment, SubNavItemFragment } from '@hooks/api'

import { MobileNavigationBar } from './MobileNavigationBar'

export function MobileSubMenu(): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const _handleSubNavClicked = (currentNavItem: MainNavContainerFragment, subNavItem: SubNavItemFragment): void => {
    const path = NavigationPlugin.shared().selectSubNav(currentNavItem?.id, subNavItem?.id)
    NavigationPlugin.shared().resetMenuNav()
    if (path && config.isBrowser()) {
      navigate(path)
    } else if (subNavItem?.path && subNavItem?.exactPath) {
      navigate(subNavItem?.path)
    } else {
      window.location.href = subNavItem?.path
    }
  }

  const _handleClose = (): void => {
    NavigationPlugin.shared().toggleSubMenu(null)
  }

  let $header = (
    <MobileNavigationBar
      showLogo={false}
      icon={IconEnum.CHEVRON_BACK_OUTLINE}
      variant='menu'
      onIconClick={_handleClose} />
  )
  if (isNativeApplication) {
    $header = (
      <></>
    )
  }

  const currentNavItem = navigationData?.navigation?.mobileOpenNavItem
  let subItem: SubNavItemFragment
  const SubMenuTitle = currentNavItem?.title?.toUpperCase()
  return (
    <Drawer
      open={navigationData?.navigation?.mobileSubMenuOpen && !!currentNavItem}
      header={$header}
      onClose={_handleClose}>
      <MenuNavItemContainer>
        <Heading variant='h6' className='sub-menu-nav-item-header'>{SubMenuTitle}</Heading>
      </MenuNavItemContainer>
      <ContentSeperator margin='0 16px' />
      <For each='subItem' of={(currentNavItem as MainNavContainerFragment)?.items ?? []}>
        <MenuNavItemContainer key={subItem?.id} onClick={() => _handleSubNavClicked(currentNavItem as MainNavContainerFragment, subItem)}>
          <Heading variant='h6' className='sub-menu-nav-item-header'>{subItem.title.toUpperCase()}</Heading>
        </MenuNavItemContainer>
        <ContentSeperator margin='0 16px' />
      </For>
    </Drawer>
  )

}
