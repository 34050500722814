import React from 'react'

import { Svg } from './Svg'

export interface OrganicProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Organic({ color, hoverColor, className }: OrganicProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.0684 29.1094L36.9437 26.1314C34.7861 20.4449 33.4249 15.591 32.8891 9.51755C32.8891 4.81551 29.1338 1 24.4952 1C19.8565 1 16.1012 4.81551 16.1012 9.51755C15.5654 15.591 14.2042 20.4449 12.0514 26.1314L10.9268 29.1094C10.3282 30.7208 10 32.4645 10 34.2865C10 42.4122 16.4922 49 24.5 49C32.5078 49 39 42.4122 39 34.2865C38.9952 32.4645 38.6621 30.7208 38.0684 29.1094ZM24.4952 40.8694C20.4164 40.8694 17.11 37.5143 17.11 33.3755C17.11 29.2367 20.4164 25.8816 24.4952 25.8816C28.5739 25.8816 31.8803 29.2367 31.8803 33.3755C31.8803 37.5143 28.5739 40.8694 24.4952 40.8694Z" className='fill' />
    </Svg>
  )

}
