import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@client/components/atoms'
import { ResponsivePXValue, ZeroSpace } from '@client/components/Theme'

import { DashedTable, DashedTableRow } from '../tables'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('padding-bottom', '16px')}

  t2 {
    ${ZeroSpace}
    line-height: 1;
    font-weight: 400;
  }

  h5 {
    ${ResponsivePXValue('padding', '16px 0')}
    line-height: 1;
  }
`

export interface OrderBreakdownProps {
  subscription: string
  addOn: string
  delivery: string
  discount: string
  userPoints: string
  total: string
}

export function OrderBreakdown({ subscription, addOn, delivery, discount, userPoints, total }: OrderBreakdownProps): JSX.Element {

  return (
    <Container>
      <DashedTable seperatorVariant='none'>
        <DashedTableRow title='Meal-kit Subscription' isTotal={false} seperated={false}>
          <Paragraph variant='p2' bold>{subscription}</Paragraph>
        </DashedTableRow>
        <DashedTableRow title='Add-ons' isTotal={false} seperated={true}>
          <Paragraph variant='p2' bold>{addOn}</Paragraph>
        </DashedTableRow>
        <DashedTableRow title='Delivery Fee' isTotal={false} seperated={false}>
          <Paragraph variant='p2' bold>{delivery}</Paragraph>
        </DashedTableRow>
        <DashedTableRow title='Discount' isTotal={false} seperated={true}>
          <Paragraph variant='p2' bold>{discount}</Paragraph>
        </DashedTableRow>
        <DashedTableRow title='User Points' isTotal={false} seperated={true}>
          <Paragraph variant='p2' bold>{userPoints}</Paragraph>
        </DashedTableRow>
        <DashedTableRow title='Total' isTotal={true} seperated={true}>
          <Heading variant='h5'>{total}</Heading>
        </DashedTableRow>
      </DashedTable>
    </Container>
  )

}
