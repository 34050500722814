import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { ResponsivePXValue, SmallLoader, theme } from '@client/components'
import { Modal } from '@client/components/molecules'
import { SubscriptionCategory } from '@client/components/molecules/stores/SubscriptionCategory'
import { MenuCategoryFragment, useActiveMenuCategoriesLazyQuery, useGetModalsQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('height', '568px')}
  ${ResponsivePXValue('max-height', '568px')}
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: 'CALC(100% - 200px)', desktop: '600px' })}

`
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '382px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`

interface KnowYourDishesModalState {
  categories: MenuCategoryFragment[]
}

const DEFAULT_STATE: KnowYourDishesModalState = {
  categories: [],
}

export function KnowYourDishesModal(): JSX.Element {

  const [state, setState] = useState<KnowYourDishesModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const [getCategories, { data: menuCategories, loading: menuCategoriesLoading }] = useActiveMenuCategoriesLazyQuery()

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.KNOW_YOUR_DISHES)
  }

  useEffect(() => {
    setTimeout(() => {
      getCategories()
    }, 300)
  }, [])

  useEffect(() => {
    if (menuCategories) {
      setState(prevState => ({ ...prevState, categories: menuCategories?.activeMenuCategories?.menuCategories as unknown as MenuCategoryFragment[] }))
    }
  }, [menuCategories])

  let category: MenuCategoryFragment

  return (
    <Modal
      showCloseButton
      open={data?.modals?.knowYourDishes}
      title='KNOW YOUR DISHES'
      onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={menuCategoriesLoading}>
            <LoadingContainer>
              <LoaderBox>
                <SmallLoader color={theme.colors.oranges.coral} />
              </LoaderBox>
            </LoadingContainer>
          </When>
          <Otherwise>
            <>
              <For each='category' of={state.categories || []}>
                <SubscriptionCategory
                  key={category.id}
                  title={category.title}
                  subTitle={category.longDescription}
                  learnMoreUrl='/meal-kit/plans' />
              </For>
            </>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )

}
