import React, { useEffect, createRef } from 'react'

import styled from 'styled-components'

import { FlexDeviceContainer, Paragraph, Button, Link } from '@client/components'
import { IconEnum } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@client/components/Theme'

const Container = styled.div<{ $color?: string }>`
  ${ResponsivePXValue('padding', '16px')}
  background-color: ${(props): string => props.$color ? props.$color : props.theme.colors.oranges.coral};
  max-width: 100%;
  display: block;
  align-items: center;
  justify-content: center;

  .close-button {
    ${ZeroSpace}
  }
`

const ContentContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  align-items: center;
  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}

  .action-button {
    ${ResponsivePXValue('height', '24px')}
    ${ResponsivePXValue('padding', '0 16px')}
    ${ResponsivePXValue('min-width', '64px')}
    text-transform: none;
    ${ResponsivePXValue('margin', { mobile: '16px 0 0', tablet: '0 16px', desktop: '0 16px' })}
  }

  .body-text {
    ${ResponsivePXValue('line-height', '24px')}
    ${ZeroSpace}
  }
`
const CloseButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`
const NotificationWrapper = styled.div`
  display: block;
`

export interface AppNotificationBannerProps {
  dismissLoading?: boolean
  onHeightChange?: (height: number) => void
  onLinkClicked?: () => void
  onClose?: () => void
}

export function AppNotificationBanner({ dismissLoading = false, onClose, onHeightChange }: AppNotificationBannerProps): JSX.Element {

  const mobileRef: React.RefObject<HTMLDivElement> = createRef()

  useEffect(() => {
    if (mobileRef) {
      onHeightChange(mobileRef?.current?.clientHeight)
    }
  }, [mobileRef?.current?.clientHeight])

  return (
    <Container $color={''}>

      <FlexDeviceContainer ref={mobileRef} $mobile={true} $tablet={true} $desktop={false} >
        <NotificationWrapper>

          <ContentContainer>
            <Paragraph variant='p1' className='body-text' align='center' color={'white'}>Available on <Link href='https://play.google.com/store/apps/details?id=io.gonative.android.jaboxo'>Android</Link> and <Link href='https://apps.apple.com/us/app/ucook/id1669025266'>IOS</Link>, your UCOOK experience just got better. Get your hands on app-only discounts and more!</Paragraph>
          </ContentContainer>
          <CloseButtonContainer>
            <Button
              color='transparent'
              size='small'
              icon={IconEnum.CLOSE_OUTLINE}
              iconColor={'white'}
              loading={dismissLoading}
              disabled={dismissLoading}
              onClick={() => onClose?.()} />
          </CloseButtonContainer>
        </NotificationWrapper>

      </FlexDeviceContainer>

    </Container>
  )

}
// on mobile Must be dismissible ! but x in top center
