import Cookies from 'js-cookie'

import { useConfig } from '@client/contexts/ConfigProvider'
import { OrderDirectionEnum, OrderOrderEnum, OrderOriginEnum, OrderStatusEnum, OrderTypeEnum } from '@uctypes/api/globalTypes'

import { OrderItemFragment, OrdersQuery, useOrdersQuery } from './api'

interface ReturnType {
  mealKitRatingModalOpen: boolean
  mealKitRatingOrder: OrdersQuery['orders']['list'][0] | null
}

export const useMealKitRatingsLogic = (userId: string, isRegistered: boolean): ReturnType => {

  const config = useConfig()
  const isRatingsDismissed = Cookies.get('isDismissed')

  const { data: ordersData } = useOrdersQuery({
    skip: (!userId || !!isRatingsDismissed || !isRegistered),
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: {
        users: [userId],
        status: [OrderStatusEnum.COMPLETE, OrderStatusEnum.PROCESSING],
        origin: [OrderOriginEnum.NODE],
        orderTypes: [OrderTypeEnum.SUBSCRIPTION],
      },
      order: {
        field: OrderOrderEnum.DISPATCH_DATE,
        direction: OrderDirectionEnum.DESC,
      },
      skip: 0,
      limit: 5,
    },
  })

  if (ordersData?.orders?.list.length > 0) {
    const orderListLength = ordersData?.orders?.list.length
    let orderNumber = 0
    let valid = false
    while (!valid && orderNumber <= orderListLength) {

      const isMealKit = (orderItem: OrderItemFragment): boolean => {
        return orderItem?.product?.__typename === 'MealKit' && orderItem?.product?.name !== 'Fixed Subscription Fee'
      }
      const deliveryDay = ordersData?.orders?.list[orderNumber]?.dispatchDate.slice(0, 10)
      const dispatchDate = new Date(deliveryDay).getTime()
      const todaysDate = new Date().getTime()
      const dayDiff = (todaysDate - dispatchDate) / (1000 * 60 * 60 * 24)
      const isSub = ordersData?.orders?.list[orderNumber]?.orderType
      const amountPaid = ordersData?.orders?.list[orderNumber]?.amountPaid
      const isRatingsDismissed = Cookies.get('isDismissed')
      const orderItemsMealKits = (ordersData?.orders?.list[orderNumber]?.orderItems?.filter(isMealKit))
      let hasRatedAllMK = true
      for (let i = 0; i < orderItemsMealKits?.length; i++) {
        if (!orderItemsMealKits[i]?.orderItemRating) {
          hasRatedAllMK = false
        }
      }
      if (isSub === 'SUBSCRIPTION' && !isRatingsDismissed && dayDiff > 4 && dayDiff < 28 && amountPaid !== 0 && !hasRatedAllMK && orderItemsMealKits?.length > 0) {
        valid = true

        return {
          mealKitRatingModalOpen: true,
          mealKitRatingOrder: ordersData?.orders?.list[orderNumber],
        }

      } else {
        orderNumber++
      }
    }
    return {
      mealKitRatingModalOpen: false,
      mealKitRatingOrder: null,
    }

  } else {
    return {
      mealKitRatingModalOpen: false,
      mealKitRatingOrder: null,
    }

  }

}
