import React from 'react'

import { useStaticImageQuery } from '@hooks/api'

import { ResponsiveImage } from './ResponsiveImage'

export interface StaticImageProps {
  staticId: string
  mobileWidth: number
  mobileHeight?: number
  tabletWidth: number
  tabletHeight?: number
  desktopWidth: number
  desktopHeight?: number
  objectFit?: string
  lazy?: boolean
  skeleton?: boolean
}

export function StaticImage(props: StaticImageProps): JSX.Element {

  const queryParams = {
    staticId: props.staticId,
    mobileWidth: props.mobileWidth,
    mobileHeight: props.mobileHeight,
    tabletWidth: props.tabletWidth,
    tabletHeight: props.tabletHeight,
    desktopWidth: props.desktopWidth,
    desktopHeight: props.desktopHeight,
  }

  const { data, loading } = useStaticImageQuery({ variables: queryParams })

  return (
    <Choose>
      <When condition={!!data}>
        <ResponsiveImage image={data.staticImage} objectFit={props.objectFit} />
      </When>
      <When condition={loading}>
      </When>
      <Otherwise>
        No Image
      </Otherwise>
    </Choose>
  )

}
