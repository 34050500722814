import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Paragraph, Spacer } from '@client/components/atoms'
import { ContentSeperator } from '@client/components/atoms/layout/ContentSeparator'
import { SectionLoading } from '@client/components/molecules'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { RegisteredUserDetailsFragment, UserAddressFragment, UserDetailsFragment, useUserDetailsQuery } from '@hooks/api'

const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
`

export interface SingleAddressFormProps {
  onAddNewAddress: () => void
}

interface MultipleAddressFormState {
  defaultAddress: UserAddressFragment | null
}

const DEFAULT_STATE: MultipleAddressFormState = {
  defaultAddress: null,
}

export function SingleAddressForm({ onAddNewAddress }: SingleAddressFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<MultipleAddressFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  useEffect(() => {
    if (!userDetailsLoading) {
      const defaultAddress = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment).addresses.find((address) => address.isDefault)
      if (defaultAddress) {
        setState((prevState) => update(prevState, {
          defaultAddress: { $set: defaultAddress },
        }))
      }
    }
  }, [userDetailsLoading])

  const _handleAddNewAddress = () => {
    onAddNewAddress?.()
  }

  return (
    <Container>
      <Choose>
        <When condition={userDetailsLoading}>
          <SectionLoading />
        </When>
        <When condition={userDetailsData?.currentUser?.__typename === 'RegisteredUser'}>
          <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} align='center'>{state.defaultAddress?.alias}</Paragraph>
          <Spacer universal='16px' />
          <Paragraph variant='p1' color={theme.colors.greys.liteCodGrey} align='center'>{state.defaultAddress?.location?.fullAddress}</Paragraph>
          <ContentSeperator />
          <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} align='center'>Want to use an alternative address?</Paragraph>
          <Spacer universal='32px' />
          <Button color='black' title='ADD A NEW ADDRESS' fluid onClick={_handleAddNewAddress} />
        </When>
        <Otherwise>
          <Paragraph variant='p1'> USER NOT REGISTERED</Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )

}
