import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage, Heading, Paragraph, Button } from '@atoms/index'
import { ResponsivePXValue, StrongBoxShadow, ZeroSpace } from '@components/Theme'
import { MealKitCategoryFragment } from '@hooks/api'
import { NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  ${StrongBoxShadow}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('width', { mobile: '256px', tablet: '302px', desktop: '348px' })}
  ${ResponsivePXValue('height', { mobile: '336px', tablet: '354px', desktop: '354px' })}
`

const ImageContainer = styled.a`
  flex-shrink: 0;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '128px', tablet: '144px', desktop: '160px' })}
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${ResponsivePXValue('padding', '16px')}

  .title, .description, .price {
    padding: 0;
    ${ResponsivePXValue('margin', { mobile: '4px 0', tablet: '5px 0', desktop: '6px 0' })}
  }
  .title {
    flex-shrink: 0;
    margin-top: 0;
  }
  .description {
    flex-grow: 1;
  }
  .price {
    flex-shrink: 0;
    color: ${(props): string => props.theme.colors.whites.silver};
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  .add-button {
    border-radius: 0;
  }
`

export interface CategoryCardProps {
  category: MealKitCategoryFragment
  className?: string
  onSelect?: (id: string) => void
  selected?: boolean
  loading?: boolean
  numberOfPortions?: NumberOfPortionsEnum
  numberOfMeals?: number
}

export function CategoryCard({ category, onSelect, selected, loading, className, numberOfPortions = NumberOfPortionsEnum.SERVES_2, numberOfMeals = 3 }: CategoryCardProps): JSX.Element {

  const price = category.pricing.find((priceItem) => priceItem.numberOfMeals === numberOfMeals && priceItem.numberOfPortions === numberOfPortions).maxPrice ?? 500
  const priceInfo = `R${price.toFixed(2)} Per Week`

  return (
    <Container className={className}>
      <ImageContainer>
        <ResponsiveImage image={category?.image} lazy={false} />
      </ImageContainer>
      <ContentContainer>
        <Heading className='title' variant='h5'>{category?.title}</Heading>
        <Paragraph className='description'>{category?.description}</Paragraph>
        <Paragraph className='price' variant='p2' transform='uppercase'>{priceInfo}</Paragraph>
      </ContentContainer>
      <ButtonContainer>
        <Button
          className='add-button'
          disabled={selected}
          loading={loading}
          title={selected ? 'Selected Category' : 'Select Category'}
          fluid
          onClick={() => onSelect?.(category.id)} />
      </ButtonContainer>
    </Container>
  )

}
