import React from 'react'

import styled, { CSS, css, useTheme } from 'styled-components'

import { Icon, IconEnum, ResponsivePXValue } from '@client/components'

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    &:first-child {
        width: auto;
        flex-shrink: 0;
    }
  }
`

const Active = css`
  .dot {
    border-color: ${(props): string => props.theme.colors.oranges.coral};
    background-color: ${(props): string => props.theme.colors.oranges.coral};
  }
  .title {
    color: ${(props): string => props.theme.colors.oranges.coral};
  }
  .dash {
    background-color: ${(props): string => props.theme.colors.oranges.coral};
  }
`

const Inactive = css`
  .dot {
    border-color: ${(props): string => props.theme.colors.whites.silver};
    background-color: ${(props): string => props.theme.colors.misc.transparent};
  }
  .title {
    color: ${(props): string => props.theme.colors.greys.tundora};
  }
  .dash {
    background-color: ${(props): string => props.theme.colors.whites.silver};
  }
`

const Completed = css`
  .dot {
    ${ResponsivePXValue('border-radius', '2px')}
  }
`

const NotCompleted = css`
  .dot {
    ${ResponsivePXValue('border-radius', '10px')}
  }
`

const ItemContainer = styled.div<{ $active: boolean, $completed: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props): CSS => props.$active ? Active : Inactive}
  ${(props): CSS => props.$completed ? Completed : NotCompleted}
`

const Title = styled.div`

  flex-shrink: 0;
  position: relative;
  font-family: gordita;
  font-weight: 500;
  text-transform: uppercase;

  ${ResponsivePXValue('top', '2px')}
  ${ResponsivePXValue('font-size', '11px')}
  ${ResponsivePXValue('margin', '8px')}

`

const Dot = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('width', '18px')}
  ${ResponsivePXValue('height', '18px')}
  border-style: solid;
`

const Dash = styled.div`
  width: 100%;
  flex-grow: 1;
  ${ResponsivePXValue('height', '1px')}
`

export interface StepProps {
  id: string
  active?: boolean
  completed?: boolean
  title: string
  isFirst?: boolean
  className?: string
  disabled?: boolean
  onSelect?: () => void
}

export function Step(props: StepProps): JSX.Element {

  const theme = useTheme()

  const _handleSelect = (): void => {
    if (!props.disabled) {
      props.onSelect()
    }
  }

  const color = props.disabled ? theme.colors.oranges.coral : props.active ? theme.colors.whites.pureWhite : theme.colors.whites.silver

  return (
    <ItemContainer key={props.id} $active={props.active} $completed={props.completed} className={props.className} onClick={_handleSelect}>
      <If condition={!props.isFirst}>
        <Dash className='dash' />
      </If>
      <Dot className='dot'>
        <If condition={props.completed}>
          <Icon color={color} icon={IconEnum.CHECKMARK} />
        </If>
      </Dot>
      <Title className='title'>{props.title}</Title>
    </ItemContainer>
  )
}

export interface StepsProps {
  children: JSX.Element | JSX.Element[]
  className?: string
  onSelect?: (id: string) => void
}

export function Steps({ children, className, onSelect }: StepsProps): JSX.Element {

  const $steps = React.Children.map(children, (child, index) => {
    const props = { ...child.props }
    if (index === 0) {
      props.isFirst = true
    } else {
      props.isFirst = false
    }
    props.onSelect = () => {
      if (props.completed) {
        onSelect?.(props.id)
      }
    }
    return React.cloneElement(child, props)
  })

  return (
    <NavigationContainer className={className}>
      {$steps}
    </NavigationContainer>
  )

}
