import React, { useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button, ResponsivePXValue } from '@client/components'
import { SummaryBlock } from '@client/components/molecules/stores/SummaryBlock'
import { useConfig } from '@client/contexts/ConfigProvider'
import { CartFragment, useRedeemCouponMutation, useRemoveCouponMutation, useUserCartQuery } from '@hooks/api'
import { Form, TextInput, useForm } from '@molecules/index'

import { TagChip } from '../cart'

const Container = styled.div`
  .apply-button {
    ${ResponsivePXValue('margin', '16px 0')}
  }
`

const Chips = styled.div`
  display: flex;
  flex-wrap: wrap;

  .coupon-tag {
    ${ResponsivePXValue('margin-right', '8px')}
    ${ResponsivePXValue('margin-bottom', '8px')}
  }
`

export interface CheckoutPromoCodesProps {
  loading?: boolean
  disabled?: boolean
  empty?: boolean
}
const DEFAULT_STATE: CheckoutPromoCodesProps = {
  empty: true,
}
export function CheckoutPromoCodes({ loading = false, disabled = false }: CheckoutPromoCodesProps): JSX.Element {

  const config = useConfig()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [redeemCoupon, { loading: redeemLoading }] = useRedeemCouponMutation()
  const [removeCoupon, { loading: removeLoading }] = useRemoveCouponMutation()
  const [state, setState] = useState<CheckoutPromoCodesProps>({ ...DEFAULT_STATE })
  const { addToast } = useToasts()
  const [form] = useForm()

  const _handleAddDiscount = async (data: { code: string }) => {
    try {
      await redeemCoupon({
        variables: {
          coupon: data.code as string,
        },
      })
      form.setFieldsValue({ code: '' })
      addToast('Coupon code successfully applied!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleEmpty = () => {
    setState((prevState) => update(prevState, {
      empty: { $set: false },
    }))
  }

  const _handleRemoveDiscount = async (id: string) => {
    try {
      await removeCoupon({
        variables: {
          coupon: id,
        },
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const isLoading = loading || userCartLoading || redeemLoading || removeLoading
  let reduction: CartFragment['reductions'][0]
  return (
    <SummaryBlock title='Promo Code' loading={isLoading}>
      <Container>
        <Form onChange={_handleEmpty} form={form} onFinish={_handleAddDiscount}>
          <TextInput name='code' label='' placeholder='Enter promo code' rules={[{ required: true, message: 'Please enter discount code' }]} />
        </Form>
        <Button
          title='APPLY DISCOUNT'
          fluid
          className='apply-button'
          onClick={() => form.submit()}
          loading={isLoading}
          disabled={disabled || isLoading || !!state.empty} />
        <Chips>
          <For each='reduction' of={userCartData?.currentUser?.activeCart?.reductions}>
            <Choose>
              <When condition={reduction.canBeRemoved}>
                <TagChip
                  key={reduction.id}
                  className='coupon-tag'
                  title={reduction.title}
                  onRemove={() => _handleRemoveDiscount(reduction.id)} />
              </When>
              <Otherwise>
                <TagChip
                  key={reduction.id}
                  className='coupon-tag'
                  title={reduction.title} />
              </Otherwise>
            </Choose>
          </For>
        </Chips>
      </Container>
    </SummaryBlock>
  )

}
