import React, { useState } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Carousel } from '@client/components'
import { CarouselFlexContainer, ResponsivePXValue } from '@client/components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { ResponsiveImage, ResponsiveImageData } from '.'

interface MultipleImageContainerProps {
  images: ProductImageInterface[]
}

export interface ProductImageInterface {
  id: string
  index: number
  isDefault: boolean
  image: ResponsiveImageData
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  overflow: hidden;
`

const SmallMultipleImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('margin-left', { mobile: '0', tablet: '0', desktop: '16px' })}
`

const SmallImageContainer = styled.div`
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '0', tablet: '0', desktop: '80px' })}
  ${ResponsivePXValue('width', { mobile: '0', tablet: '0', desktop: '80px' })}
  cursor: pointer;

`
const BigImageContainer = styled.div`
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '0', tablet: '0', desktop: '464px' })}
  ${ResponsivePXValue('width', { mobile: '0', tablet: '0', desktop: '464px' })}
  overflow: hidden;
`

const SingleImageContainer = styled.div`
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
`

const CarouselContainer = styled.div`
  ${CarouselFlexContainer}
`
export function MultipleImageContainer({ images }: MultipleImageContainerProps): JSX.Element {
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const [currentBigImageIndex, setCurrentBigImageIndex] = useState(0)

  const _handleSmallImageClick = (index: number) => {
    setCurrentBigImageIndex(index)
  }

  const sortedImages = images?.slice().sort((a, b) => a.index - b.index)

  if (images && images.length && isDesktop && sortedImages) {

    if (images.length === 1) {
      return (
        <Container>
          <SingleImageContainer>
            <ResponsiveImage image={sortedImages[currentBigImageIndex]?.image} />
          </SingleImageContainer>

        </Container>
      )
    }

    return (
      <Container>
        <BigImageContainer>
          <ResponsiveImage image={sortedImages[currentBigImageIndex]?.image} />
        </BigImageContainer>
        <SmallMultipleImageContainer>
          {sortedImages.map((image, index) => (
            index !== currentBigImageIndex && (
              <SmallImageContainer
                key={image?.id}
                onClick={() => _handleSmallImageClick(index)}
              >
                <ResponsiveImage image={image?.image} />
              </SmallImageContainer>
            )
          ))}
        </SmallMultipleImageContainer>
      </Container>
    )
  } else if (images && images.length && sortedImages && !isDesktop) {

    let mobileImage: ProductImageInterface
    return (
      <CarouselContainer>
        <>
          <Carousel
            maxWidth='90vw'
            slidesToScroll={1}>
            <For each='mobileImage' of={sortedImages}>
              <SingleImageContainer key={mobileImage?.id}>
                <ResponsiveImage image={mobileImage?.image} />
              </SingleImageContainer>

            </For>
          </Carousel>
        </>

      </CarouselContainer>

    )

  }

  return null // Return something appropriate if no images are available
}
