import React, { Fragment, useEffect, useState } from 'react'

import { FieldData } from 'rc-field-form/es/interface'
import update from 'react-addons-update'
import styled from 'styled-components'

import { Image, Paragraph } from '@client/components/atoms'
import { Form, RadioButton, RadioButtonProps, TextAreaInput, useForm } from '@client/components/molecules'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { OrderItemFragment } from '@hooks/api'

import { RatingRow } from '../controls'
import { TableCell, TableRow } from '../tables'

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '288px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '192px', tablet: '192px', desktop: '88px' })}
  img {
    object-fit: cover;
  }
`

const TextAreaContainer = styled.div`
  display: block;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px;

  .rating-modal-input{
    margin: 0;
  }
`
const MobileImageContainer = styled.div`

  ${ResponsivePXValue('height', '64px')}
`

const MobileContainer = styled.div`
  
`
const ToggleContainer = styled.div`
  display: flex;
  width: 98%;
  height: fit-content;
  align-items: center; 
  margin: 0;
`
const RadioContainer = styled.div`
  display: block;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
  position: relative;
  right: 0px;

  .radioText {
  ${ResponsivePXValue('margin-top', { mobile: '2px', tablet: '2px', desktop: '4px' })}

  }
`
const ParagraphContainer = styled.div`
  ${ResponsivePXValue('margin-top', '8px')}
`
const MobileDescription = styled.div`
  .description{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    margin: 0;
  }
`
const MobileRatingContainer = styled.div`
  .mobileRating{
    ${ResponsivePXValue('padding', { mobile: '4px 0' })}
    justify-content: center;

  }
`
interface RatingModalState {
  otherData: string
  radioChosen: string
  additionalData: string
}

const DEFAULT_STATE: RatingModalState = {
  otherData: '',
  radioChosen: '',
  additionalData: '',
}
export interface LatestAddOnOrderListProps {
  cartItem: OrderItemFragment
  rating: number
  newRating: number
  prevInput?: string
  deliveryDate?: string
  onNavigate: () => void
  onRate: (id: number) => void
  onAdditionalData?:(additionalData: string) => void
  onToggle?:(toggleData: string)=> void
}

export function AddOnOrderList({ cartItem, onNavigate, onRate, deliveryDate, onAdditionalData, newRating, rating, prevInput, onToggle }: LatestAddOnOrderListProps): JSX.Element {

  const [state, setState] = useState<RatingModalState>({ ...DEFAULT_STATE })
  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'otherData') {
          setState((prevState) => update(prevState, {
            otherData: { $set: field.value },
          }))
        }
        if (name === 'servingSizeOptionsRadio') {
          setState((prevState) => update(prevState, {
            radioChosen: { $set: field.value },
          }))
        }
      })
    })
  }
  const [form] = useForm()

  const radios = [
    {
      className: 'size-option',
      value: 'Yes',
      disabled: false,
      element: <Paragraph bold variant='p3'>{'Yes'}</Paragraph>,
    },
    {
      className: 'size-option',
      value: 'No',
      disabled: false,
      element: <Paragraph bold variant='p3'>{'No'}</Paragraph>,
    },
  ]

  const RadioProps: RadioButtonProps = {
    className: 'serving-size',
    outline: false,
    name: 'servingSizeOptionsRadio',
    options: radios,
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  const shortenedProductName = cartItem?.product?.name
  const deliveryDateFormatted = 'Delivered on ' + deliveryDate.split('T')[0]

  useEffect(() => {
    setState((prevState) => update(prevState, {
      additionalData: { $set: 'Would they recommend?: '+state.radioChosen+ ' AdditionalInfo: '+ state.otherData },
    }))
  }, [state.radioChosen, state.otherData])

  if (state.otherData) {
    onAdditionalData(state.otherData)
  }

  if (state.radioChosen) {
    onToggle(state.radioChosen)
  }
  return (
    <Fragment>
      <DeviceContainer $desktop>
        <TableRow justify='space-between' margin='12px 0'>
          <TableCell padding='0 16px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image width={64} height={64} round={true} lazy={false} src={cartItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column' grow='1' justify='center' maxWidth='60%'>
            <Paragraph bold variant='p4' className='order-item-title' onClick={onNavigate}>{shortenedProductName}</Paragraph>
            <Paragraph variant='p2' color={theme.colors.whites.silver}>{deliveryDateFormatted}</Paragraph>
          </TableCell>
          <TableCell align='center' justify='flex-end'>
            <RatingRow rating={newRating || rating} onClick={onRate} />
          </TableCell>
        </TableRow>
        <If condition={!!newRating}>
          <Form className='flex-form' form={form} onFieldsChange={_handleChange}>
            <ToggleContainer>
              <ParagraphContainer>
                <Paragraph variant='p2' className='radioText'>Would you recommend this to a friend?</Paragraph>
              </ParagraphContainer>
              <RadioContainer>
                <RadioButton isMiniCard={true} className='no-margin' {...RadioProps} />
              </RadioContainer>
            </ToggleContainer>

            <TextAreaContainer>
              <TextAreaInput
                className='rating-modal-input'
                isCompact={true}
                name='otherData'
                placeholder={prevInput || 'Tell us more'}
                rules={[{ required: true, message: 'Please input additional info' }]}
              />
            </TextAreaContainer>
          </Form>
        </If>
      </DeviceContainer>

      <DeviceContainer $mobile $tablet>
        <MobileContainer>
          <TableRow>
            <TableCell padding='12px 12px 12px 0' >
              <MobileImageContainer>
                <ImageContainer onClick={onNavigate}>
                  <Image width={64} height={64} round={true} lazy={false} src={cartItem?.product?.coverImage?.desktop} />
                </ImageContainer>
              </MobileImageContainer>

            </TableCell>
            <TableCell direction='column' padding='12px 0 0 0'>
              <Paragraph bold align = 'left' variant='p2' className='order-item-title' onClick={onNavigate}>{shortenedProductName}</Paragraph>
              <MobileDescription>
                <Paragraph align = 'left' className='description' variant='p3' color='grey'>{deliveryDateFormatted}</Paragraph>
              </MobileDescription>
              <MobileRatingContainer>
                <RatingRow className='mobileRating' onClick={onRate} rating={newRating || rating} />
              </MobileRatingContainer>
            </TableCell>
          </TableRow>
          <If condition={!!newRating}>
            <Form className='flex-form' form={form} onFieldsChange={_handleChange}>

              <ToggleContainer>
                <ParagraphContainer>
                  <Paragraph variant='p2' className='radioText' >Would you recommend this to a friend?</Paragraph>
                </ParagraphContainer>
                <RadioContainer>
                  <RadioButton isMiniCard={true} className='no-margin' {...RadioProps} />
                </RadioContainer>
              </ToggleContainer>
              <TextAreaContainer>
                <TextAreaInput
                  className='rating-modal-input'
                  isCompact={true}
                  name='otherData'
                  placeholder={prevInput || 'Tell us more'}
                  rules={[{ required: true, message: 'Please input additional info' }]}
                />
              </TextAreaContainer>
            </Form>
          </If>
        </MobileContainer>

      </DeviceContainer>
    </Fragment>
  )

}
