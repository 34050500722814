import React from 'react'

import {
  ToastProvider as ReactToastProvider,
  ToastProps,
  ToastContainerProps,
  AppearanceTypes,
} from 'react-toast-notifications'
import styled, { keyframes } from 'styled-components'

import { BaseStyle } from '@atoms/index'
import { ResponsivePXValue } from '@client/components'

const CustomToast = styled.div<{ $appearance: AppearanceTypes }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  background: ${(props): string => {
    switch (props.$appearance) {
      case 'success':
        return props.theme.colors.greens.greenSmoke
      case 'error':
        return props.theme.colors.misc.error
      case 'warning':
        return props.theme.colors.yellows.selectiveYellow
      default:
        return props.theme.colors.whites.pureWhite
    }
  }};
  
  width: CALC(100% - 5vw);
  margin: 2.5vw;
  border-radius: 3vw;
  padding: 4.5vw;
  ${(props): string => props.theme.tablet} {
    padding: 3vw;
  }
  ${(props): string => props.theme.desktop} {
    padding: 1.5vw;
    width: CALC(100% - 1vw);
    margin: 0.5vw;
    border-radius: 0.5vw;
  }
  ${(props): string => props.theme.ultra} {
    padding: ${(props): string => props.theme.uw(1.5)};
    width: CALC(100% - ${(props): string => props.theme.uw(1)});
    margin: ${(props): string => props.theme.uw(0.5)};
    border-radius: ${(props): string => props.theme.uw(0.5)};
  }
`

const TextContainer = styled.div<{ $appearance: AppearanceTypes }>`
  flex-grow: 1;
  font-weight: 300;
  text-align: left;
  line-height: 1.5;
  color: ${(props): string => {
    switch (props.$appearance) {
      case 'success':
        return props.theme.colors.whites.pureWhite
      case 'error':
        return props.theme.colors.whites.pureWhite
      case 'warning':
        return props.theme.colors.whites.pureWhite
      default:
        return props.theme.colors.greys.liteCodGrey
    }
  }};
  font-size: 4vw;
  margin-right: 3vw;
  ${(props): string => props.theme.tablet} {
    font-size: 2.4vw;
    margin-right: 2vw;
  }
  ${(props): string => props.theme.desktop} {
    font-size: 1.3vw;
    margin-right: 1vw;
    line-height: 1.5;
  }
  ${(props): string => props.theme.ultra} {
    font-size: ${(props): string => props.theme.uw(1.3)};
    margin-right: ${(props): string => props.theme.uw(1)};
  }
`

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  background: rgba(255,255,255,0.1);
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  display: flex;
  width: 100%;
`
const Progress = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`
const ProgressValue = styled.div`
  animation: ${Progress} 3s linear normal forwards;
  box-shadow: 0 10px 40px -10px ${(props): string => props.theme.colors.whites.pureWhite};
  border-radius: 100px;
  background: ${(props): string => props.theme.colors.whites.pureWhite};
  height: 3px;
  width: 0;
`

const MyCustomToast = ({ children, appearance, showProgressBar = true, onDismiss }: ToastProps): JSX.Element => {
  return (
    <BaseStyle>
      <CustomToast onClick={(): void => onDismiss()} $appearance={appearance}>
        <If condition={showProgressBar}>
          <ProgressBar>
            <ProgressValue></ProgressValue>
          </ProgressBar>
        </If>
        <TextContainer $appearance={appearance}>
          {children}
        </TextContainer>
      </CustomToast >
    </BaseStyle>
  )
}

const TempContainer = styled.div<{ $show: boolean }>`
  display: ${(props): string => (props.$show ? 'inline' : 'none')};
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: fit-content;
  z-index: 998;
  ${(props): string => props.theme.tablet} {
    right: 15vw;
    width: 70vw;
  }
  ${(props): string => props.theme.desktop} {
    top: 4.5vw;
    right: 0vw;
    ${ResponsivePXValue('width', '448px')}
  }
  ${(props): string => props.theme.ultra} {
    top: ${(props): string => props.theme.uw(4.5)};
    ${ResponsivePXValue('width', '448px')}
  }
`

const MyCustomToastContainer = ({ children, ...props }: ToastContainerProps): JSX.Element => {
  return <TempContainer $show={props.hasToasts}>{children}</TempContainer>
}

interface ToastProviderProps {
  children: React.ReactNode
}

export function ToastProvider({ children }: ToastProviderProps): JSX.Element {
  return (
    <ReactToastProvider autoDismissTimeout={3000} components={{ Toast: MyCustomToast, ToastContainer: MyCustomToastContainer }}>
      {children}
    </ReactToastProvider>
  )
}
