import Cookies from 'js-cookie'

export const setDismissedMKRatingsCookie = (): void => {
  const day = new Date().getDay()
  const daysTillFriday = 5 - day

  if (daysTillFriday >= 0) {
    let secondsTillNextFriday = daysTillFriday * 24 * 60 * 60

    if (new Date().getHours() >= 10) {
      if (day === 5) {
        secondsTillNextFriday = 7 * 24 * 60 * 60
      }
      const desiredExpiryHourDiff = (new Date().getHours() - 10) * 60 * 60
      const desiredExpiryMinDiff = new Date().getMinutes() * 60
      const desiredExpirySecDiff = new Date().getSeconds()
      const timeOffset = desiredExpiryHourDiff + desiredExpiryMinDiff + desiredExpirySecDiff
      const secondsForCookie = secondsTillNextFriday - timeOffset

      Cookies.set('isDismissed', 'True', { expires: secondsForCookie / 60 / 60 / 24 })
    } else {
      const desiredExpiryHourDiffBefore = (9 - new Date().getHours()) * 60 * 60
      const desiredExpiryMinDiffBefore = (60 - new Date().getMinutes()) * 60
      const desiredExpirySecDiffBefore = 60 - new Date().getSeconds()
      const timeOffsetBefore = desiredExpiryHourDiffBefore + desiredExpiryMinDiffBefore + desiredExpirySecDiffBefore
      const secondsForCookie = secondsTillNextFriday + timeOffsetBefore

      Cookies.set('isDismissed', 'True', { expires: secondsForCookie / 60 / 60 / 24 })
    }
  } else {
    const secondsTillNextFriday = (7 + daysTillFriday) * 24 * 60 * 60

    if (new Date().getHours() >= 10) {
      const desiredExpiryHourDiff = (new Date().getHours() - 10) * 60 * 60
      const desiredExpiryMinDiff = new Date().getMinutes() * 60
      const desiredExpirySecDiff = new Date().getSeconds()
      const timeOffset = desiredExpiryHourDiff + desiredExpiryMinDiff + desiredExpirySecDiff
      const secondsForCookie = secondsTillNextFriday - timeOffset

      Cookies.set('isDismissed', 'True', { expires: secondsForCookie / 60 / 60 / 24 })
    } else {
      const desiredExpiryHourDiffBefore = (9 - new Date().getHours()) * 60 * 60
      const desiredExpiryMinDiffBefore = (60 - new Date().getMinutes()) * 60
      const desiredExpirySecDiffBefore = 60 - new Date().getSeconds()
      const timeOffsetBefore = desiredExpiryHourDiffBefore + desiredExpiryMinDiffBefore + desiredExpirySecDiffBefore
      const secondsForCookie = secondsTillNextFriday + timeOffsetBefore

      Cookies.set('isDismissed', 'True', { expires: secondsForCookie / 60 / 60 / 24 })
    }
  }
}

export const setDismissedAddOnsRatingsCookie = (): void => {
  Cookies.set('isAddOnsDismissed', 'True', { expires: 30 })
}
