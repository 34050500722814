import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Spacer } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'
import { WineAisleFragment, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { NavLink, NavLinks, EducationalBanner } from '../molecules'
import { WineAislesCarousel } from '../organisms'
import { DeviceContainer } from '../utility'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: '1136px' }, { desktop: true })}

  .wine-title {
    flex-shrink: 0;
    margin-right: 28px;
  }
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeadingContainer = styled.div`
  display: flex;
  flex: 1;

  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'flex-start' })}
`

interface WineAisles {
  isLoading: boolean
  displayEducationalBanner: boolean
  wineAisles: WineAisleFragment[]
}

const DEFAULT_STATE: WineAisles = {
  isLoading: false,
  displayEducationalBanner: false,
  wineAisles: [],
}

export function WineAisles(): JSX.Element {

  const [state, setState] = useState<WineAisles>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMiniCard = !isDesktop

  const wineNavLinks: NavLink[] = state.wineAisles.map((aisle: WineAisleFragment) => {
    return {
      title: aisle.title,
      url: `/wine/store/${aisle.asileSlug}`,
      isActive: false,
    }
  })

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }

  const navLinks = [{
    title: 'All',
    url: '/wine',
    isActive: true,
  },
  ...wineNavLinks,
  ]

  const _handleDataLoaded = (wineAisles: WineAisleFragment[]) => {
    setState((prevState) => update(prevState, {
      isLoading: { $set: false },
      wineAisles: { $set: wineAisles },
    }))
  }

  return (
    <Container>
      <SearchEngineOptimization />
      <TopContainer>
        <Spacer mobile='16px' desktop='32px' />
        <RowContainer>
          <HeadingContainer>
            <Heading variant='h1'>Shop Wine</Heading>
            <Spacer desktop='8px' variant='horizontal' />
          </HeadingContainer>
        </RowContainer>
        <DeviceContainer $desktop>
          <NavLinks outline navLinks={navLinks} />
        </DeviceContainer>
        <Spacer universal='16px' />
        <EducationalBanner
          title='Wine at Cellar Door prices'
          description='Get an extra 25% off when you order three or more bottles with your next Meal Kit order. UCOOK making your life easier, with better prices than anywhere else'
          pillText='Save 25%'
          pillBackground={theme.colors.reds.promo}
          onClose={_handleOnClose}
          showCloseButton={false} />
        <Spacer desktop='24px' mobile='16px' />
      </TopContainer>
      <WineAislesCarousel onDataLoaded={_handleDataLoaded} isMiniCard={isMiniCard}/>
    </Container>
  )
}
