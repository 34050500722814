import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'

import { Heading, Paragraph, ResponsiveImage, Button, Link, Spacer } from '@atoms/index'
import { DeviceContainer } from '@client/components/utility'
import { CategoryTag, Counter, Modal, SectionLoading } from '@components/molecules'
import { MobileFullWidth, ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { MealKitDishDetailsFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column; 
  ${ResponsivePXValue('max-height', { desktop: '584px' })}
`

const WineContainer = styled.div`
  display: flex;
  padding: 8px 24px 8px 24px;

  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}

  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsiveProperty('width', { mobile: '100%', tablet: '100%', desktop: '65%' })}
  ${ResponsivePXValue('padding-right', { mobile: '0', tablet: '20px', desktop: '24px' })}
`

const CategoryContainer = styled.div`
  ${ResponsiveProperty('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}

  ${ResponsivePXValue('margin-bottom', { mobile: '10px', tablet: '12px', desktop: '16px' })}
`

const HeaderContainer = styled.div`
  .header-title {
     ${ZeroSpace}
    
     ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '12px', desktop: '8px' })}
     ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '20px', desktop: '24px' })}
     ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '32px', desktop: '40px' })}
  }
`

const TextContainer = styled.div`
  .content-text-description {
    ${ZeroSpace}

    ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '20px', desktop: '24px' })}
  }
`

const DescriptionContainer = styled(TextContainer)`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
  ${ResponsiveProperty('width', { mobile: '100%' })}

  .full-width {
    width: 100%;
  }
`

const PurchaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const CostContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  ${ResponsivePXValue('margin-top', { mobile: '16px' })}

  .price-header {
    ${ZeroSpace}
  }
`

const QuantityContainer = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
`

const ImageSectionContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '256px', tablet: '256px', desktop: '256px' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '256px', desktop: '35%' })}
  align-items: center;
`

const ImageContainer = styled.div`
  display: flex;
  margin: 0 auto;
  img {
    ${MobileFullWidth}
  }

  ${ResponsiveProperty('overflow', { mobile: 'initial', tablet: 'initial', desktop: 'hidden' })};

  ${ResponsivePXValue('border-radius', '16px')};
  ${ResponsivePXValue('height', { mobile: '256px', tablet: '256px', desktop: '256px' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '256px', desktop: '256px' })}
  ${ResponsivePXValue('min-width', { mobile: '256px', tablet: '256px', desktop: '256px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '20px', tablet: '24px', desktop: 'initial' })}
`
export interface PairedWineModalProps {
  pairedWines: MealKitDishDetailsFragment['activeParings']
  open: boolean
  title: string
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  onShowDetails: (slug: string) => void
  onAddToCart: (product: MealKitDishDetailsFragment['activeParings'][0]['wine'], quantity: number) => void
}

interface PairedWineModalState {
  quantity: number
  currentProduct?: MealKitDishDetailsFragment['activeParings'][0]
  currentPage: number
}

const DEFAULT_STATE: PairedWineModalState = {
  quantity: 1,
  currentPage: 1,
}

export function PairedWineModal({ pairedWines, open, title, onClose, onShowDetails, onAddToCart }: PairedWineModalProps): JSX.Element {

  const [state, setState] = useState<PairedWineModalState>({ ...DEFAULT_STATE, currentProduct: pairedWines && pairedWines.length ? pairedWines[0] : null })
  const theme = useTheme()

  if (!state.currentProduct && (pairedWines && pairedWines.length)) {
    setState(update(state, { currentProduct: { $set: pairedWines[0] } }))
  }

  const _handleCounterChange = (amount: number): void => {
    setState(update(state, { quantity: { $set: amount } }))
  }

  const _handleShowDetails = (): void => {
    onShowDetails(state.currentProduct.wine.slug)
  }

  const _handleAddToCart = (): void => {
    onAddToCart(state.currentProduct.wine, state.quantity)
  }

  return (
    <Modal
      open={open}
      title={title}
      showCloseButton={true}
      onClose={onClose} >
      <Choose>
        <When condition={!!state.currentProduct}>
          <Container>
            <WineContainer>
              <ContentContainer>
                <CategoryContainer>
                  <CategoryTag image={state.currentProduct.wine.wineCategory?.icon?.desktop} title={state.currentProduct.wine.wineCategory?.title} />
                </CategoryContainer>
                <HeaderContainer>
                  <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='header-title'>
                    {state.currentProduct.wine.name}
                  </Heading>
                </HeaderContainer>
                <DescriptionContainer>
                  <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='content-text-description'>
                    {state.currentProduct.wine.description}
                  </Paragraph>
                </DescriptionContainer>
                <PurchaseContainer>
                  <QuantityContainer>
                    <Counter className='wineCounter' minimum={1} amount={state.quantity} onChange={_handleCounterChange} />
                  </QuantityContainer>
                  <CostContainer>
                    <Heading variant='h4' color={theme.colors.greys.liteCodGrey} className='price-header'>
                      {`R${state.currentProduct.wine.price}`}
                    </Heading>
                  </CostContainer>
                </PurchaseContainer>
                <ButtonContainer>
                  <DeviceContainer $mobile $tablet className='full-width'>
                    <Button color='black' fullWidth title='ADD TO CART' onClick={_handleAddToCart} />
                  </DeviceContainer>
                  <DeviceContainer $desktop>
                    <Button color='black' title='ADD TO CART' onClick={_handleAddToCart} />
                  </DeviceContainer>
                  <Spacer mobile='16px' />
                  <Spacer desktop='16px' variant='horizontal' />
                  <Link href={`/wine/${state.currentProduct.wine?.slug}`} onClick={_handleShowDetails}>
                    VIEW FULL DETAILS
                  </Link>
                </ButtonContainer>
              </ContentContainer>
              <ImageSectionContainer>
                <ImageContainer>
                  <ResponsiveImage image={state.currentProduct.wine.coverImage} />
                </ImageContainer>
              </ImageSectionContainer>

            </WineContainer>

          </Container>

        </When>
        <Otherwise>
          <SectionLoading />
        </Otherwise>
      </Choose>
    </Modal>
  )
}
