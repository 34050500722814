import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/WineDetails')

const WineDetails = loadable(() => import(/* webpackChunkName: "wine-details" */'@pages/WineDetails'), {
  resolveComponent: (components) => components.WineDetails,
})

export function WineDetailsAsync(): JSX.Element {

  return <WineDetails fallback={<div>Loading...</div>} />

}
