import React from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { ResponsiveImage } from '@atoms/index'
import { Paragraph, Heading, Button, getPageSectionButtonColor, Spacer } from '@client/components'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ContainerBorderRadius, getObjectFit, MediumBoxShadow, ResponsivePXValue, StrongBoxShadow } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-height: 500px;
  height: 420px;

  ${ResponsivePXValue('padding-bottom', { mobile: '90%', tablet: '45%', desktop: '15%' })}

  .full-width {
    width: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 7;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${MediumBoxShadow}
  ${ContainerBorderRadius}

  ${ResponsivePXValue('padding', { mobile: '32px 10px', tablet: '25px 15px', desktop: '40px' })}
  ${ResponsivePXValue('width', { mobile: '80%', tablet: '65%', desktop: '60%' })}
`

const ImageContainer = styled.div<{ $width: string, $height: string, $imageShadow?: boolean }>`

  display: flex;

  .image-container {
    width: 100%;
  }

  ${(props): CSS => (props.$imageShadow) ? StrongBoxShadow : null}

  ${(props): CSS => {
    if (props.$width || props.$height) {
      return `
        overflow: hidden;
        ${ResponsivePXValue('min-width', props.$width)}
        ${ResponsivePXValue('max-width', props.$width)}
        ${ResponsivePXValue('width', props.$width)}
        ${ResponsivePXValue('min-height', props.$height)}
        ${ResponsivePXValue('max-height', props.$height)}
        ${ResponsivePXValue('height', props.$height)}
      `
    }
    return `
     cursor: pointer;
     width: 100%;

     ${ResponsivePXValue('min-height', { mobile: '400px', tablet: '420px', desktop: '440px' })}
     ${ResponsivePXValue('max-height', { mobile: '400px', tablet: '420px', desktop: '440px' })}
     ${ResponsivePXValue('height', { mobile: '400px', tablet: '420px', desktop: '440px' })}
   
    `
  }}

`
export interface PageBannerContentProps {
  pageBanner: PageBannerFragment
  height?: string
  mobileHeight?: string
}

export function PageBannerContent({ pageBanner }: PageBannerContentProps): JSX.Element {

  const navigate = useNavigate()
  const config = useConfig()

  const linkUrl = pageBanner?.link?.link
  const linkTitle = pageBanner?.link?.title
  const linkColor = getPageSectionButtonColor(pageBanner?.link?.color)
  const description = pageBanner?.description
  const subTitle = pageBanner?.subTitle

  const objectFit = getObjectFit(pageBanner)

  const _handleLinkClick = (): void => {
    if (linkUrl) {
      if (linkUrl.indexOf('http') !== -1 && config.isBrowser()) {
        window.location.href = linkUrl
      } else {
        navigate(linkUrl)
      }
    }
  }

  return (
    <Container>
      <ImageContainer $width={pageBanner.imageWidth} $height={pageBanner.imageHeight} $imageShadow={pageBanner.imageShadow}>
        <DeviceContainer $mobile className='full-width'>
          <ResponsiveImage image={pageBanner.mobileImage} objectFit={objectFit} />
        </DeviceContainer>
        <DeviceContainer $desktop $tablet className='full-width'>
          <ResponsiveImage image={pageBanner.image} objectFit={objectFit} />
        </DeviceContainer>
      </ImageContainer>
      <ContentContainer>
        <Choose>
          <When condition={!!description && !!subTitle}>
            <Heading variant='h4' align='center' color={pageBanner.titleColor}>{pageBanner.bannerTitle}</Heading>
            <Spacer mobile='16px' desktop='24px' />
            <Heading variant='h2' align='center' color={pageBanner.subTitleColor}>{pageBanner.subTitle}</Heading>
            <Spacer mobile='24px' desktop='32px' />
            <Paragraph align='center' variant='p1' color={pageBanner.descriptionColor}>{description} </Paragraph>
            <Spacer universal='24px' />
          </When>
          <When condition={!!description && !subTitle}>
            <Heading variant='h1' align='center' color={pageBanner.titleColor}>{pageBanner.bannerTitle}</Heading>
            <Spacer universal='24px' />
            <Paragraph align='center' variant='p1' bold color={pageBanner.descriptionColor}>{description} </Paragraph>
            <Spacer universal='24px' />
          </When>
          <Otherwise>
            <Heading variant='h4' align='center' color={pageBanner.titleColor}>{pageBanner.bannerTitle}</Heading>
            <Spacer universal='24px' />
            <Heading variant='h1' align='center' color={pageBanner.subTitleColor}>{pageBanner.subTitle}</Heading>
            <Spacer universal='32px' />
          </Otherwise>
        </Choose>
        <If condition={!!linkTitle}>
          <Button title={linkTitle} href={linkUrl} color={linkColor} onClick={_handleLinkClick} />
        </If>
      </ContentContainer>
    </Container>
  )
}
