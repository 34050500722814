export const scrollToSelector = (selector: string, block: ScrollLogicalPosition = 'center', inline: ScrollLogicalPosition = 'nearest'): void => {
  if (typeof document !== 'undefined') {
    document.querySelector(selector.trim())?.scrollIntoView({ behavior: 'smooth', block: block, inline: inline })
  }
}

export const scrollToSelectorTopOfElement = (selector: string, isMobile: boolean): void => {
  if (typeof document !== 'undefined') {
    if (isMobile) {
      const yOffset = -70
      const element = document.querySelector(selector.trim())
      const y = element.getBoundingClientRect().top + scrollY + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    } else {
      const yOffset = -50
      const element = document.querySelector(selector.trim())
      const y = element.getBoundingClientRect().top + scrollY + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    }

  }
}

export const scrollToLink = (url: string): void => {
  const idSelector = `#${url.split('#').pop()}`
  scrollToSelector(idSelector)
}
