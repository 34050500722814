import React from 'react'

import { CraftMealInStoreCarousel } from '@molecules/index'

interface PageDataCraftMealInStoreCarouselProps {
  title: string
}

export function PageDataCraftMealInStoreCarousel({ title }: PageDataCraftMealInStoreCarouselProps): JSX.Element {

  return (
    <>
      <CraftMealInStoreCarousel title={title} />
    </>
  )

}
