import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Button } from '@client/components/atoms'
import { useConfig } from '@client/contexts/ConfigProvider'

const Container = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex: 1;

  .google-button {
    background-color: ${(props): string => props.$disabled ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.googleRed};
    border-color: ${(props): string => props.$disabled ? props.theme.colors.whites.desertStorm : props.theme.colors.misc.googleRed};
  }

  .google-button:hover {
    opacity: 0.6;
  }
`

export interface GoogleSocialButtonProps {
  disabled: boolean
  action?: 'SIGN UP' | 'LOGIN'
  className?: string
  didAuthenticateWithGoogle: (user: gapi.auth2.GoogleUser) => void
}

interface GoogleSocialButtonState {
  disabled: boolean
  loading: boolean
}

const DEFAULT_STATE: GoogleSocialButtonState = {
  disabled: false,
  loading: false,
}

export function GoogleSocialButton({ disabled, action = 'SIGN UP', className, didAuthenticateWithGoogle }: GoogleSocialButtonProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<GoogleSocialButtonState>({ ...DEFAULT_STATE })
  const buttonText = state.disabled ? `GOOGLE ${action} DISABLED` : `${action}  WITH GOOGLE`
  const { addToast } = useToasts()

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (typeof gapi !== 'undefined') {
        gapi.load('client:auth2', _initClient)
      } else {
        setState((prevState) => update(prevState, {
          disabled: { $set: true },
        }))
      }
    }
    return function cleanup() {
      mounted = false
    }
  }, [])

  const _initClient = (): void => {
    try {
      gapi.client.init({
        apiKey: config.getGoogle().apiKey,
        clientId: `${config.getGoogle().clientId}.apps.googleusercontent.com`,
        scope: 'profile',
      })
    } catch (e) {
      _showError()
    }
  }

  const _handleClick = async (): Promise<void> => {
    try {
      const googleUser = await gapi.auth2.getAuthInstance().signIn()
      _setLoading(false)
      didAuthenticateWithGoogle(googleUser)
    } catch (e) {
      _showError()
    }
  }

  const _showError = (): void => {
    addToast('Google was unable to authenticate you.', {
      appearance: 'error',
      autoDismiss: true,
    })
  }

  const _setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const actuallyDisabled = state.disabled || disabled

  return (
    <Container className={className} $disabled={actuallyDisabled}>
      <Button
        title={buttonText}
        disabled={actuallyDisabled || state.loading}
        loading={state.loading}
        onClick={_handleClick}
        className='google-button'
        fullWidth />
    </Container>
  )
}
