import React, { useEffect, useState } from 'react'

import { useApolloClient, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetCheckoutQuery, useUserCartQuery } from '@hooks/api'
import { CheckoutStepEnum, CheckoutTypeEnum } from '@uctypes/api/globalTypes'

import { CheckoutModal } from '../modals'

interface CheckoutControllerState {
  showModal: boolean
  hasCheckedOut: boolean
}

const DEFAULT_STATE: CheckoutControllerState = {
  showModal: false,
  hasCheckedOut: false,
}

export function CheckoutController(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<CheckoutControllerState>({ ...DEFAULT_STATE })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const { data: userCartData } = useUserCartQuery({ skip: !config.isBrowser() })
  const navigate = useNavigate()
  const location = useLocation()

  const step = CheckoutPlugin.shared().getActiveStep(checkoutData?.checkout)
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus

  const _handleCancel = async (): Promise<void> => {
    await CheckoutPlugin.shared().reset()
    setState((prevState) => update(prevState, {
      showModal: { $set: false },
    }))
  }

  const _handleComplete = async (): Promise<void> => {
    await CheckoutPlugin.shared().checkout(client)
    await CheckoutPlugin.shared().thankYou()
  }

  useEffect(() => {
    if (checkoutData?.checkout?.open) {
      setState((prevState) => update(prevState, {
        showModal: { $set: false },
      }))
      switch (step) {
        case CheckoutStepEnum.NOTHING:
          setState((prevState) => update(prevState, {
            showModal: { $set: false },
          }))
          break
        case CheckoutStepEnum.SAVE_MENU:
        case CheckoutStepEnum.CART_ERRORS:
        case CheckoutStepEnum.REACTIVATE:
        case CheckoutStepEnum.VALIDATION:
        case CheckoutStepEnum.VERIFY_NUMBER:
          CartPlugin.shared().close()
          setState((prevState) => update(prevState, {
            showModal: { $set: true },
          }))
          break
        case CheckoutStepEnum.IDENTIFY_USER:
          CartPlugin.shared().close()
          ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
          break
        case CheckoutStepEnum.CONFIGURE_PLAN:
          CartPlugin.shared().close()
          navigate('/meal-kit/plans')
          break
        case CheckoutStepEnum.CART_SUMMARY:
        case CheckoutStepEnum.DELIVERY:
        case CheckoutStepEnum.DELIVERY_DAY:
        case CheckoutStepEnum.PAYMENT:
        case CheckoutStepEnum.ACTIVATE_SUBSCRIPTION:
        case CheckoutStepEnum.THANK_YOU:
          CartPlugin.shared().close()
          if (checkoutData?.checkout?.type === CheckoutTypeEnum.ON_DEMAND || (!checkoutStatus?.hasActiveSubscription && !checkoutStatus?.hasPausedSubscription)) {
            navigate('/checkout')
          } else {
            _handleComplete()
          }
          break
        default:
          break
      }
    } else if (step === CheckoutStepEnum.NOTHING) {
      setState((prevState) => update(prevState, {
        showModal: { $set: false },
      }))
    }
  }, [step])

  useEffect(() => {
    if (checkoutData?.checkout?.open && location.pathname !== '/checkout') {
      CheckoutPlugin.shared().reset()
    }
  }, [location.pathname])

  // useEffect(() => {
  //   if (checkoutStatus?.subscriptionIsSaved) {
  //     addToast('Your cart has been saved!', {
  //       appearance: 'success',
  //       autoDismiss: true,
  //     })
  //   }
  // }, [checkoutStatus?.subscriptionIsSaved])

  return (
    <>
      <CheckoutModal
        open={state.showModal}
        step={step}
        onClose={_handleCancel} />
    </>
  )

}
