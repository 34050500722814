import React, { useEffect, useState } from 'react'

import ReactDOM from 'react-dom'
import styled, { CSS, css, useTheme } from 'styled-components'
import DrawerComponent from 'styled-react-modal'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ApplicationNavigationBar } from '@client/components'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { usePortal } from '@client/hooks/UsePortal'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const TRANSITION_DURATION = 300

const StyledDrawer = DrawerComponent.styled`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ApplicationFooterbarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`
interface DrawerStyleProps {
  $open: boolean
}
const ApplicationStyle = css<DrawerStyleProps>`
  transform: translateY(${(props): string => (props.$open ? '0' : '100%')});
  max-height: 85%;
  position: absolute;
  ${ResponsivePXValue('bottom', { mobile: '64px' })}
`
const NormalStyle = css<DrawerStyleProps>`
  top: 0;
  right: 0;
  transform: translateX(${(props): string => (props.$open ? '0' : '100vW')});
  height: 100%;
`
const DrawerContainer = styled.div<{ $open: boolean, $isNativeApplication?: boolean }>`
  position: fixed;
  ${ResponsivePXValue('width', { mobile: '320px', tablet: '400px', desktop: '400px' })}
  background: ${theme.colors.whites.pureWhite};
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
  display: flex;
  flex-direction: column;
  ${(props): CSS => props.$isNativeApplication ? ApplicationStyle : NormalStyle}

`

const ScrollContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`

const FooterContainer = styled.div`
  width: 100%;
`

const HeaderContainer = styled.div`
  width: 100%;
`
export interface DrawerProps {
  open: boolean
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  allowClose?: boolean
}

interface DrawerState {
  isOpen: boolean
  opacity: boolean
  isClosing: boolean
  isOpening: boolean
}

const DEFAULT_STATE: DrawerState = {
  isOpen: false,
  opacity: false,
  isClosing: false,
  isOpening: false,
}

export function Drawer({ open, onClose, children, header, footer, allowClose = true }: DrawerProps): JSX.Element {

  const [state, setState] = useState<DrawerState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()
  const isNativeApplication = appData.app.isNativeApplication
  const _handleClose = (): void => {
    if (open && allowClose) {
      onClose?.()
      CartPlugin.shared().close()
    }
  }

  const _handleDrawerClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault()
    e.stopPropagation()
  }

  const modalAfterOpen = (): void => {
    if (state.isOpening) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, opacity: true, isOpening: false }))
      })
    }
  }

  const modalBeforeClose = (): Promise<void> => {
    if (state.isClosing) {
      return new Promise<void>((resolve): void => {
        setState((prevState) => ({ ...prevState, opacity: false, isClosing: false }))
        setTimeout(resolve, TRANSITION_DURATION)
      })
    } else {
      return new Promise<void>((resolve): void => {
        setTimeout(resolve, TRANSITION_DURATION)
      })
    }
  }

  useEffect(() => {
    if (open && !state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: true, isOpening: true }))
    } else if (!open && state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: false, isClosing: true }))
    }
  }, [open])

  const style = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA || appData.app.deviceType === DeviceTypeEnum.TABLET || !!isNativeApplication
    ? { opacity: state.opacity, transition: '0.5s all ease-in-out' }
    : { background: theme.colors.misc.transparent }

  const modal = (
    <StyledDrawer
      onBackgroundClick={_handleClose}
      isOpen={state.isOpen}
      backgroundProps={{ style }}
      // beforeOpen={modalBeforeOpen}
      afterOpen={modalAfterOpen}
      beforeClose={modalBeforeClose}
      // afterClose={modalAfterClose}
      allowScroll={false}
      onEscapeKeydown={_handleClose}>
      <DrawerContainer $open={state.opacity} $isNativeApplication={isNativeApplication} onClick={_handleDrawerClick.bind(this)}>
        <If condition={!!header}>
          <HeaderContainer>
            {header}
          </HeaderContainer>
        </If>
        <ScrollContainer>
          {children}
        </ScrollContainer>
        <If condition={!!footer}>
          <FooterContainer>
            {footer}
          </FooterContainer>
        </If>
      </DrawerContainer>
      <If condition={isNativeApplication}>
        <ApplicationFooterbarContainer>
          <ApplicationNavigationBar />
        </ApplicationFooterbarContainer>
      </If>

    </StyledDrawer>
  )

  const target = usePortal('root-modal')
  if (target) {
    return ReactDOM.createPortal(modal, target)
  }
  return modal

}
