import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Paragraph, Link, Spacer } from '@client/components/atoms'
import { ContentSeperator } from '@client/components/atoms/layout/ContentSeparator'
import { Form, SectionLoading, SelectInput, useForm } from '@client/components/molecules'
import { theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { RegisteredUserDetailsFragment, UserAddressFragment, UserDetailsFragment, useUserDetailsQuery } from '@hooks/api'

const Container = styled.div`
  width: 100%;

  .form {
    display: grid;
    flex-direction: column;
  }
`

export interface MultipleAddressFormProps {
  onUpdateAddress: (id: string) => void
  onAddNewAddress: () => void
}

interface MultipleAddressFormState {
  defaultAddress: UserAddressFragment | null
  addressOptions: { title: string, value: string }[]
}

const DEFAULT_STATE: MultipleAddressFormState = {
  defaultAddress: null,
  addressOptions: [] as { title: string, value: string }[],
}

export function MultipleAddressForm({ onUpdateAddress, onAddNewAddress }: MultipleAddressFormProps): JSX.Element {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<MultipleAddressFormState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  useEffect(() => {
    if (!userDetailsLoading) {
      const userAddresses = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment).addresses
      const defaultAddress = userAddresses.find((address) => address.isDefault)
      const addressOptions: { title: string, value: string }[] = []
      for (let index = 0; index < userAddresses.length; index++) {
        const addressFragment = userAddresses[index]
        addressOptions.push({ title: addressFragment.location.fullAddress, value: addressFragment.id })
      }
      if (defaultAddress) {
        setState((prevState) => update(prevState, {
          defaultAddress: { $set: defaultAddress },
          addressOptions: { $set: addressOptions },
        }))
      }
    }
  }, [userDetailsLoading])

  const _handleAddNewAddress = () => {
    onAddNewAddress?.()
  }

  const _handleSubmit = (data: { selectedAddress: string }) => {
    onUpdateAddress?.(data.selectedAddress)
  }

  return (
    <Container>
      <Choose>
        <When condition={userDetailsLoading}>
          <SectionLoading />
        </When>
        <When condition={userDetailsData?.currentUser?.__typename === 'RegisteredUser'}>
          <Form className='form' form={form} onFinish={_handleSubmit}>
            <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} align='center' >{state.defaultAddress?.alias}</Paragraph>
            <Spacer universal='16px' />
            <Paragraph variant='p1' color={theme.colors.greys.liteCodGrey} align='center' >{state.defaultAddress?.location?.fullAddress}</Paragraph>
            <ContentSeperator />
            <Paragraph variant='p2' color={theme.colors.greys.liteCodGrey} align='center' >{'Want to use an alternative address?'}</Paragraph>
            <Spacer universal='16px' />
            <SelectInput
              name='selectedAddress'
              label='Address'
              showLabel={false}
              placeholder='Select an address'
              options={state.addressOptions}
              rules={[{ required: true, message: 'Please select an address' }]} />
            <Spacer universal='24px' />
            <Button color='black' title='UPDATE DEFAULT ADDRESS' fluid onClick={() => form.submit()} />
            <Spacer universal='16px' />
            <Link color={theme.colors.oranges.coral} onClick={_handleAddNewAddress} textAlignment='center'> ADD A NEW ADDRESS </Link>
          </Form>
        </When>
        <Otherwise>
          <Paragraph variant='p1'>User not registered</Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )

}
