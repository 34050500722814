import React from 'react'

import { usePlansPageQuery, MealKitCategoryFragment } from '@hooks/api'
import { MealKitOnMenuThisWeek } from '@molecules/index'

interface PageDataAnchoredMealKitMenuProps {
  title: string
}

export function PageDataAnchoredMealKitMenu({ title }: PageDataAnchoredMealKitMenuProps): JSX.Element {

  const { data } = usePlansPageQuery()

  const categories = data?.categories?.list as MealKitCategoryFragment[] || []

  return <MealKitOnMenuThisWeek categories={categories} title={title} />
}
