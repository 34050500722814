import React from 'react'

import { Svg } from './Svg'

export interface ForkProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Fork({ color, hoverColor, className }: ForkProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.12002 21.29L3.71002 19.88L13.36 10.22L13.16 10C12.9747 9.81597 12.8276 9.59708 12.7272 9.35594C12.6268 9.11481 12.5751 8.8562 12.5751 8.59501C12.5751 8.33382 12.6268 8.0752 12.7272 7.83407C12.8276 7.59294 12.9747 7.37405 13.16 7.19001L17.5 2.82001L18.43 3.74001L15.19 7.00001L16.15 7.94001L19.39 4.69001L20.31 5.61001L17.06 8.85001L18 9.81001L21.26 6.56001L22.18 7.50001L17.81 11.84C17.03 12.62 15.77 12.62 15 11.84L14.78 11.64L5.12002 21.29Z" className='fill' />
    </Svg>
  )

}
