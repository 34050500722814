import React, { ChangeEvent, useState } from 'react'

import parsePhoneNumberFromString from 'libphonenumber-js'
import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule, RuleObject, StoreValue } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, IconEnum, IconSizeEnum, SmallLoader } from '@atoms/index'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ResponsivePXValue, LiteBoxShadow } from '@components/Theme'

import { FormContext, FormContextProperties, FormValue, InputProps, RuleType } from './Form'
import { InputWrapper } from './InputWrapper'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right-icon {
    position: absolute;

    ${ResponsivePXValue('right', '16px')}
  }

 `

const Disabled = css`
  border-color: ${(props): string => props.theme.colors.whites.silver};
  color: ${(props): string => props.theme.colors.whites.silver};
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ::placeholder {
    color: ${(props): string => props.theme.colors.whites.silver};
    opacity: 1;
  }
`

const TextInputElement = styled.input<{ $error: boolean, disabled: boolean, $textAlign: string }>`
  font-family: 'gordita';
  font-weight: 400;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  text-align: ${(props): string => props.$textAlign};
  background-color: ${(props): string => props.$error ? props.theme.colors.whites.roseWhite : props.theme.colors.whites.pureWhite};
  border-color: ${(props): string => props.$error ? props.theme.colors.reds.cautionRed : props.theme.colors.whites.desertStorm};

  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('padding', '0 12px')}
  ${ResponsivePXValue('width', 'CALC(100% - 26px)')}
  ${ResponsivePXValue('height', '40px')}

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props): string => props.$error ? props.theme.colors.reds.cautionRed : props.theme.colors.slates.bitter};
    ${LiteBoxShadow}
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.slates.bitter};
    opacity: 1;
  }

  ${(props): CSS => {
    if (props.disabled) {
      return Disabled
    }
  }}

`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
`

interface Values {
  value?: FormValue
  onChange?: (value: FormValue) => void
}

export interface TextInputProps extends InputProps {
  rules?: Rule[]
  showOptional?: boolean
  placeholder?: string
  variant?: 'text' | 'email' | 'phone' | 'password' | 'integer' | 'float' | 'creditCard'
  textAlign?: 'left' | 'right' | 'center'
  rightIcon?: IconEnum
  className?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface TextInputState {
  passwordVisibility: boolean
}

const DEFAULT_STATE = {
  passwordVisibility: false,
}

export function TextInput(props: TextInputProps): JSX.Element {

  let { placeholder = '', label, rules, showLabel, showOptional, name, variant = 'text', textAlign = 'left', rightIcon, loading, disabled, className, onKeyDown } = props

  const [state, setState] = useState<TextInputState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const type = !state.passwordVisibility && variant === 'password' ? 'password' : 'text'
  const isPassword = variant === 'password'
  const passwordIcon = state.passwordVisibility ? IconEnum.EYE_OFF : IconEnum.EYE
  const hasRightIcon = !!rightIcon || isPassword

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => {
        if (!rules) {
          rules = []
        }
        const hasRule = rules.find((rule: { type: RuleType }) => rule.type === 'method')
        if (!hasRule) {
          rules.push({
            type: 'method',
            validateTrigger: 'onSubmit',
            validator: (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): void => {
              let error: string | undefined
              if (variant === 'email') {
                const isValid = SiteHelper.validateEmail(value)
                if (!isValid) {
                  error = 'Please enter a valid email address'
                }
              } else if (variant === 'phone') {
                const isValid = SiteHelper.validatePhone(value)
                if (!isValid) {
                  error = 'Please enter a valid phone number'
                }
              }
              callback(error)
            },
          })
        }
        const required = !!(
          rules &&
          rules.some(rule => {
            if (rule && typeof rule === 'object' && rule.required) {
              return true
            }
            if (typeof rule === 'function') {
              const ruleEntity = rule(form)
              return ruleEntity && ruleEntity.required
            }
            return false
          })
        )
        const error = meta.errors?.[0]
        const { value, onChange } = control

        const format = (val: string): string | number | undefined => {
          if (val === undefined) {
            return
          }
          if (variant === 'integer') {
            return parseInt(val)
          } else if (variant === 'float') {
            return parseFloat(val)
          } else if (variant === 'email') {
            return val
          } else if (variant === 'phone') {
            let newNumber = null
            if (val.indexOf(' ') !== -1) {
              const values = val.split(' ')
              const prefix = SiteHelper.getCountryCodeForTelPrefix(values.shift())
              const number = values.join(' ')
              try {
                const phoneNumber = parsePhoneNumberFromString(number, prefix)
                newNumber = phoneNumber.formatInternational()
              } catch (err) { }
            } else {
              newNumber = val
              try {
                const phoneNumber = parsePhoneNumberFromString(newNumber, 'ZA')
                newNumber = phoneNumber.formatInternational()
              } catch (err) { }
            }
            return newNumber
          } else if (variant === 'creditCard') {
            if (val.length > 0) {
              const cardNum = val.replace(/\s/g, '')
              let checkedNum = parseInt(cardNum) + ''
              if (checkedNum === 'NaN') {
                return ''
              }
              if (checkedNum.length > 16) {
                checkedNum = checkedNum.substr(0, 16)
              }
              let returnString = ''
              for (let s = 0; s < checkedNum.length; s++) {
                if (s % 4 === 0 && s !== 0) {
                  returnString += ' '
                }
                returnString += checkedNum.charAt(s)
              }
              return returnString
            }
          } else {
            return val
          }
        }

        const _handleChange = (e: ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value
          const parsed = format(newValue)
          onChange(parsed)
        }

        const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
        }

        const _togglePasswordView = () => {
          setState((prevState) => ({ ...prevState, passwordVisibility: !prevState.passwordVisibility }))
        }

        return (
          <InputWrapper
            required={required}
            label={label}
            showLabel={showLabel}
            showOptional={showOptional}
            error={error}
            className={className}>
            <FormContext.Consumer>
              {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
                <Container>
                  <TextInputElement
                    autoComplete='on'
                    disabled={loading || disabled || formLoading || formDisabled}
                    name={name}
                    $error={!!error}
                    type={type}
                    value={value ? value + '' : ''}
                    placeholder={placeholder}
                    $textAlign={textAlign}
                    onChange={_handleChange}
                    onKeyDown={_handleKeyDown} />
                  <If condition={!!error || hasRightIcon}>
                    <Choose>
                      <When condition={isPassword}>
                        <IconContainer onClick={_togglePasswordView}>
                          <Icon
                            containerClassName='right-icon'
                            size={IconSizeEnum.SMALL}
                            icon={passwordIcon}
                            color={theme.colors.whites.desertStorm} />
                        </IconContainer>
                      </When>
                      <When condition={!!error}>
                        <IconContainer>
                          <Icon
                            containerClassName='right-icon'
                            size={IconSizeEnum.SMALL}
                            icon={IconEnum.WARNING_OUTLINE}
                            color={theme.colors.reds.cautionRed} />
                        </IconContainer>
                      </When>
                      <Otherwise>
                        <IconContainer>
                          <Icon
                            containerClassName='right-icon'
                            size={IconSizeEnum.SMALL}
                            icon={rightIcon} />
                        </IconContainer>
                      </Otherwise>
                    </Choose>
                  </If>
                  <If condition={loading || formLoading}>
                    <LoadingContainer>
                      <SmallLoader
                        color={theme.colors.greens.aquaDeep} />
                    </LoadingContainer>
                  </If>
                </Container>
              )}
            </FormContext.Consumer>
          </InputWrapper>
        )
      }}
    </Field>
  )

}
