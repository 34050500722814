import React, { useEffect, useState } from 'react'

import { addSeconds } from 'date-fns'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import update from 'react-addons-update'
import OneSignalReact from 'react-onesignal'
import styled from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE } from '@api/local'
import { Button, Link, Paragraph, Spacer, TitleSeperator } from '@client/components/atoms/index'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useUserDetailsQuery, useGetAppQuery, UserDetailsDocument, useRegisterCommunicationPlatformMutation, useRequestPhoneVerificationMutation, useUserLoginMutation, useUserSocialLoginMutation, RegisteredUserDetailsFragment, UserDetailsFragment } from '@hooks/api'
import { CheckBox, FacebookSocialButton, Form, GoogleIdentitySocialButton, ModalMessageContainer, TextInput, useForm } from '@molecules/index'
import { UserCommunicationPlatformEnum, SocialPlatformEnum, SocialLoginInput } from '@uctypes/api/globalTypes'
import { useEvents } from '@contexts/GTMProvider'

const Container = styled.div<{$isNativeApplication: boolean}>`

  .input {
    ${ZeroSpace}
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .check-input {
    margin: 0;
    flex-grow: initial;
    div {
      font-family: gordita;
      font-weight: 400;
      ${ResponsivePXValue('font-size', { mobile: '8px', desktop: '10px' })}
      ${ResponsivePXValue('line-height', '16px')}
    }
  }
`

const ForgotButton = styled.div`
  cursor: pointer;
  font-family: gordita;
  font-weight: 500;

  color: ${(props): string => props.theme.colors.blues.danube};

  ${ResponsivePXValue('font-size', { mobile: '8px', desktop: '10px' })}
  ${ResponsivePXValue('line-height', '16px')}

  &:hover {
    color: ${(props): string => props.theme.colors.blues.chetwodeBlue};
  }
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SocialButtonContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}
`

export interface LoginFormProps {
  onLoggedIn: (hasCellphoneNumber: boolean, cellphoneVerified: boolean) => void
  onPasswordReset: () => void
  onSignUp: () => void
}

interface LoginFormState {
  error: string
  hasBeenOneSignalInserted: boolean
}

export interface oneSignalAppInfoInterface {

  oneSignalUserId: string
  oneSignalPushToken: string
  oneSignalSubscribed: boolean
  oneSignalRequiresUserPrivacyConsent: boolean
  platform: string
  appId: string
  appVersion: string
  distribution: string
  hardware: string
  installationId: string
  language: string
  model: string
  os: string
  osVersion: string
  timeZone: string

}

const DEFAULT_STATE: LoginFormState = {
  error: '',
  hasBeenOneSignalInserted: false,
}

export function LoginForm({ onLoggedIn, onPasswordReset, onSignUp }: LoginFormProps): JSX.Element {

  const config = useConfig()
  const [login, { loading: loginLoading }] = useUserLoginMutation()
  const [socialLogin, { loading: socialLoginLoading }] = useUserSocialLoginMutation()
  const [requestPhoneVerification] = useRequestPhoneVerificationMutation()
  const [addOneSignalData] = useRegisterCommunicationPlatformMutation()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const [state, setState] = useState<LoginFormState>({ ...DEFAULT_STATE })
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const events = useEvents()
  const source = isNativeApplication ? 'mobile': 'web'

  const _handleOneSignalInsert = async (input: {deviceId: string, platformId: string, platformType: UserCommunicationPlatformEnum}): Promise<void> => {
    try {
      await addOneSignalData({
        variables: input,
      })
      setState((prevState) => update(prevState, {
        hasBeenOneSignalInserted: { $set: true },
      }))
      OneSignalReact.login(registeredUser.id)
      // add to localVaraslogged in so we dont keep doing it
      AppPlugin.shared().setIsOneSignalLoggedIn(true)

    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleSendPhoneVerification = async (): Promise<void> => {
    try {
      await requestPhoneVerification({
        variables: {
          number: userDetailsData?.currentUser?.phone,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      onLoggedIn?.(true, userDetailsData?.currentUser?.phoneIsVerified)
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleLoginWithGoogle = async (token: string): Promise<void> => {
    const userDataFromGoogle :JwtPayload = jwtDecode(token)
    _handleSocialLogIn({
      platform: SocialPlatformEnum.GOOGLE,
      userId: userDataFromGoogle.sub,
      accessToken: token,
      expiry: addSeconds(new Date(), userDataFromGoogle.exp/ 1000),
    })
    events.hasLoggedIn(userDetailsData?.currentUser?.id, SocialPlatformEnum.GOOGLE, source)
  }

  const _handleLoginWithFacebook = async (authResponse: fb.AuthResponse): Promise<void> => {
    _handleSocialLogIn({
      platform: SocialPlatformEnum.FACEBOOK,
      userId: authResponse.userID,
      accessToken: authResponse.accessToken,
      expiry: addSeconds(new Date(), authResponse.expiresIn / 1000),
    })
    events.hasLoggedIn(userDetailsData?.currentUser?.id, SocialPlatformEnum.FACEBOOK, source)

  }

  const _handleSocialLogIn = async (input: SocialLoginInput): Promise<void> => {
    try {
      await socialLogin({
        variables: {
          input,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleLogin = async (data: { email: string, password: string, rememberMe: boolean[] }): Promise<void> => {
    try {
      await login({
        variables: {
          email: data.email,
          password: data.password,
          // rememberMe: data.rememberMe,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      events.hasLoggedIn(userDetailsData?.currentUser?.id, 'UCOOK', source)

    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }

  }

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => { SiteHelper.validateAndSubmitFormOnEnter(event, form) }

  useEffect(() => {
    const currentUser = userDetailsData?.currentUser
    if (currentUser?.__typename === 'RegisteredUser') {
      if (currentUser?.phone && !currentUser.phoneIsVerified) {
        _handleSendPhoneVerification()
      } else {
        onLoggedIn?.(currentUser?.phone, currentUser.phoneIsVerified)
      }

      if (isNativeApplication && gonative) {

        // function found in the app wrapper gonative exclusively

        gonative.onesignal.onesignalInfo().then(function (oneSignalInfo: oneSignalAppInfoInterface) {
          const userCommunicationData = registeredUser?.currentDevice?.userCommunicationPlatformData
          const communicationData = Array(userCommunicationData)[0]
          let isUserCommunicationPlatformRegistered = false
          for (let i = 0; i < Object.keys(communicationData)?.length; i++) {
            if (Object.values(communicationData)[i].platformType === UserCommunicationPlatformEnum.ONE_SIGNAL) {
              isUserCommunicationPlatformRegistered = true
              break
            }
          }

          if (!isUserCommunicationPlatformRegistered) {
            _handleOneSignalInsert({
              deviceId: registeredUser.currentDevice.id,
              platformId: oneSignalInfo.oneSignalUserId,
              platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
            })
          }

        })

        gonative.onesignal.externalUserId.set({
          externalId: registeredUser.id,
        })
      } else {

        const isSubscribed = OneSignalReact.User.PushSubscription.optedIn
        let isUserCommunicationPlatformRegistered = false
        const userCommunicationData = registeredUser?.currentDevice?.userCommunicationPlatformData
        const communicationData = Array(userCommunicationData)[0]

        for (let i = 0; i < Object.keys(communicationData)?.length; i++) {
          if (Object.values(communicationData)[i].platformType === UserCommunicationPlatformEnum.ONE_SIGNAL) {
            isUserCommunicationPlatformRegistered = true
            break
          }
        }
        if (!isUserCommunicationPlatformRegistered && isSubscribed) {
          _handleOneSignalInsert({
            deviceId: registeredUser.currentDevice.id,
            platformId: registeredUser.id,
            platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
          })
        } else {
          OneSignalReact.login(
            userDetailsData?.currentUser?.id,
          )
          AppPlugin.shared().setIsOneSignalLoggedIn(true)

        }
      }

    }
  }, [userDetailsData?.currentUser])

  const loading = loginLoading || socialLoginLoading || userDetailsLoading

  return (
    <Container $isNativeApplication={isNativeApplication}>
      <Form form={form} onFinish={_handleLogin} disabled={loading}>
        <Choose>
          <When condition = {!isNativeApplication} >
            <SocialButtonContainer>
              <GoogleIdentitySocialButton
                disabled={loading}
                message='signin'
                action='LOGIN'
                didAuthenticateWithGoogle={_handleLoginWithGoogle} />
              <DeviceContainer $mobile $tablet>
                <Spacer universal='8px'/>
              </DeviceContainer>
              <DeviceContainer $desktop>
                <Spacer universal='16px' variant='horizontal' />
              </DeviceContainer>
              <FacebookSocialButton
                disabled={loading}
                action='Log in'
                didAuthenticateWithFacebook={_handleLoginWithFacebook} />
            </SocialButtonContainer>
            <Spacer mobile='16px' desktop='24px' />
            <TitleSeperator title='OR LOGIN WITH EMAIL' />
          </When>
          <Otherwise>
            <TitleSeperator title='LOGIN WITH EMAIL' />
          </Otherwise>
        </Choose>

        <Spacer universal='8px' />
        <TextInput
          onKeyDown={_handleKeyDown}
          name='email'
          placeholder='Enter your email'
          rules={[{ required: true, message: 'Please input an email' }]}
          variant='email'
          className='input' />
        <Spacer universal='16px' />
        <TextInput
          onKeyDown={_handleKeyDown}
          name='password'
          placeholder='Enter your password'
          rules={[{ required: true, message: 'Please input a password' }]}
          variant='password'
          className='input' />
        <Spacer universal='16px' />
        <CheckboxContainer>
          <CheckBox
            name='rememberMe'
            label='Remember Me'
            showLabel={false}
            showOptional={false}
            options={[{ title: 'Remember Me', value: true }]}
            className='check-input' />
          <ForgotButton onClick={onPasswordReset}>Forgot your password?</ForgotButton>
        </CheckboxContainer>
        <Spacer universal='24px' />
        <If condition={state.error !== ''}>
          <ModalMessageContainer>
            <Paragraph variant='p1' align='center' className='error'>{state.error}</Paragraph>
          </ModalMessageContainer>
          <Spacer universal='24px' />
        </If>
        <Button
          loading={loading}
          disabled={loading}
          color='black'
          fullWidth
          title='LOGIN'
          onClick={() => form.submit()} />
        <Spacer universal='16px' />
        <FooterContainer>
          <Paragraph variant='p2'>Don&lsquo;t have an account?</Paragraph>
          <Spacer universal='8px' variant='horizontal' />
          <Link variant='l2' decoration='underline' onClick={onSignUp}> Sign Up </Link>
        </FooterContainer>
      </Form>
    </Container>
  )
}
