import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, IconEnum, ResponsiveImage, Paragraph, Spacer, Button } from '@client/components/atoms'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { MenuDishWineForMealKitDishFragment } from '@hooks/api'

import { Counter } from '../controls'

const Container = styled.div<{ $active: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: ${(props): string => props.$active ? '0' : '100%'};
  transition: 0.4s ease-in-out;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  align-self: stretch;
  z-index: 2;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
`

const ImageContainer = styled.a`
  width: auto;
  height: 40%;
  cursor: pointer;
  background-color: ${theme.colors.whites.alabaster};
`

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;

  .maker-text {
    ${ZeroSpace}
    line-height: 24px;
    font-weight: 400;
  }
`

const CounterContainer = styled.div`
  .counter {
    ${ResponsivePXValue('width', '96px')}
  }
`

const IconContainer = styled.div<{ $active: boolean }>`
  display: ${(props): string => props.$active ? 'flex' : 'none'};
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 3;
`

export interface WinePairingCardProps {
  active?: boolean
  pairing: MenuDishWineForMealKitDishFragment
  amount?: number
  onExit?: () => void
  onAmountChange?: (amount: number) => void
}

export function WinePairingCard({ active = false, pairing, amount = 1, onExit, onAmountChange }: WinePairingCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleClick = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/wine/${pairing?.wine?.slug}`)
  }

  const _handleAmountChange = async (amount: number): Promise<void> => {
    onAmountChange?.(amount)
  }

  const _handleExit = () => {
    onExit?.()
  }

  return (
    <Container $active={active}>
      <IconContainer $active={active}>
        <Button
          color='transparent'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={theme.colors.greys.darkCodGrey}
          size='small'
          onClick={_handleExit} />
      </IconContainer>
      <ImageContainer onClick={_handleClick.bind(this)} href={`/wine/${pairing?.wine?.slug}`}>
        <ResponsiveImage image={pairing?.wine?.coverImage} lazy={false} shouldAnimate={false} />
      </ImageContainer>
      <ContentContainer>
        <HeaderRow>
          <HeaderColumn>
            <Heading variant='h5' className='price-header'>{`R${pairing?.wine?.price ?? 250}`}</Heading>
            <Spacer variant='horizontal' universal='16px' />
          </HeaderColumn>
          <CounterContainer>
            <Counter className='counter' minimum={1} amount={amount} onChange={_handleAmountChange} />
          </CounterContainer>
        </HeaderRow>
        <Heading variant='h5' className='title-header'>{pairing?.wine?.name ?? 'Name'}</Heading>
        <Spacer variant='horizontal' universal='16px' />
        <Paragraph variant='p2' className='description-text'>{pairing?.note ?? 'Description'}</Paragraph>
      </ContentContainer>
    </Container>
  )
}
