// eslint-disable-next-line
import React from 'react'
import LazyLoad from 'react-lazyload'
import styled, { useTheme } from 'styled-components'

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  .lazyload-wrapper {
    width: 100%;
    height: 100%;
  }
`

const PictureElement = styled.picture<{ $objectFit: string, $shouldAnimate: boolean }>`
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  object-fit: ${(props): string => (props.$objectFit ? props.$objectFit : 'cover')};
  transition: ${(props): string => (props.$shouldAnimate ? 'opacity 0.3s ease-in' : 'none')};
`

const ImageElement = styled.img<{ $objectFit: string }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props): string => (props.$objectFit ? props.$objectFit : 'cover')};
  backface-visibility: hidden;
`

export interface ResponsiveImageData {
  mobile: string
  desktop: string
  tablet: string
  fallback: string
  alt: string
  skeleton?: boolean
}

export interface ResponsiveImageProps {
  image?: ResponsiveImageData
  objectFit?: string
  lazy?: boolean
  shouldAnimate?: boolean
  className?: string
}

export function ResponsiveImage({ image, objectFit, lazy = false, shouldAnimate = true, className }: ResponsiveImageProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container className={className}>
      <Choose>
        <When condition={lazy}>
          <LazyLoad
            height='100%'
            debounce={false}
            once>
            <PictureElement
              $objectFit={objectFit}
              $shouldAnimate={shouldAnimate}>
              <source
                type='image/webp'
                srcSet={`${image?.mobile}`}
                media={`(max-width: ${theme.mobileImage}px)`} />
              <source
                type='image/webp'
                srcSet={`${image?.tablet}`}
                media={`(max-width: ${theme.tabletImage}px)`} />
              <source
                type='image/webp'
                srcSet={`${image?.desktop}`} />
              <ImageElement
                src={image.fallback}
                alt={image.alt ?? 'UCOOK'}
                $objectFit={objectFit} />
            </PictureElement>
          </LazyLoad>
        </When>
        <Otherwise>
          <PictureElement
            $objectFit={objectFit}
            $shouldAnimate={shouldAnimate}>
            <source
              type='image/webp'
              srcSet={`${image?.mobile}`}
              media={`(max-width: ${theme.mobileImage}px)`} />
            <source
              type='image/webp'
              srcSet={`${image?.tablet}`}
              media={`(max-width: ${theme.tabletImage}px)`} />
            <source
              type='image/webp'
              srcSet={`${image?.desktop}`} />
            <ImageElement
              src={image?.fallback}
              alt={image?.alt ?? 'UCOOK'}
              $objectFit={objectFit} />
          </PictureElement>
        </Otherwise>
      </Choose>
    </Container>
  )
}
