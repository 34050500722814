import React from 'react'

import styled from 'styled-components'

import { Heading, Icon, IconEnum, IconSizeEnum, Paragraph, Pill, Spacer } from '@client/components/atoms'
import { ResponsiveProperty, ResponsivePXValue, theme } from '@components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;

  background-color: ${(props): string => props.theme.colors.slates.celeste};

  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '12px', desktop: '24px' })}
`
const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsiveProperty('align-items', { mobile: 'flex-start', tablet: 'flex-end', desktop: 'flex-end' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export interface EducationalBannerProps {
  title: string
  description: string
  onClose: () => void
  pillText?: string
  pillBackground?: string
  showCloseButton?: boolean
  className?: string
}

export function EducationalBanner({ title, description, onClose, pillText, pillBackground, showCloseButton = true, className }: EducationalBannerProps): JSX.Element {

  const _handleOnClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Container className={className}>
      <Header>
        <HeadingContainer>
          <If condition={!!pillText}>
            <Pill title={pillText} backgroundColor={pillBackground} />
            <Spacer desktop='8px' variant='horizontal' />
            <Spacer mobile='12px' />
          </If>
          <Heading variant='h5'>
            {title}
          </Heading>
        </HeadingContainer>
        <If condition={showCloseButton}>
          <IconContainer onClick={_handleOnClose}>
            <Icon icon={IconEnum.CLOSE_OUTLINE} size={IconSizeEnum.SMALL} color={theme.colors.greys.liteCodGrey} />
          </IconContainer>
        </If>
      </Header>
      <Spacer mobile='8px' desktop='12px' />
      <Paragraph variant='p1'>
        {description}
      </Paragraph>
    </Container>
  )
}
