import React from 'react'

import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, IconEnum } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const CheckBoxDimention = css`
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
  ${ResponsivePXValue('min-height', '14px')}
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`

const ItemContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  flex-shrink: 1;

  ${ResponsivePXValue('padding-right', '10px')}
`
const ItemLabel = styled.div<{ $color: string }>`

  position: relative;
  font-family: gordita;
  font-weight: 500;
  color: ${(props): string => props.$color};

  ${ResponsivePXValue('margin-left', '5px')}
  ${ResponsivePXValue('top', '2px')}
  ${ResponsivePXValue('font-size', '10px')}

`

const BlackColor = (selected: boolean, disabled: boolean): CSS => css`

   ${(props): CSS => {
    if (selected) {
      return `
        margin: 0;
        background-color: ${props.theme.colors.greys.liteCodGrey};
        border-color: ${props.theme.colors.greys.liteCodGrey};
      `
    }
    if (disabled) {
      return `
        border-color: ${props.theme.colors.whites.silver};
      `
    }
  }}
`

const OrangeColor = (selected: boolean, disabled: boolean): CSS => css`

   ${(props): CSS => {
    if (selected) {
      return `
        margin: 0;
        background-color: ${props.theme.colors.oranges.coral};
        border-color: ${props.theme.colors.oranges.coral};
      `
    }
    if (disabled) {
      return `
        border-color: ${props.theme.colors.whites.silver};
      `
    }
  }}
`

const ButtonOuterContainer = styled.div<{ $color: string, $selected: boolean, $disabled: boolean }>`

  border-style: solid;
  border-radius:4px;
  border-width: 1px;
  border-color: ${(props): string => props.theme.colors.whites.silver};

  ${CheckBoxDimention}

  ${ResponsivePXValue('padding', '2px')}
  ${ResponsivePXValue('margin', { mobile: '1px', tablet: '2px', desktop: '2px' })}

  ${(props): CSS => {
    switch (props.$color) {
      case 'orange':
        return OrangeColor(props.$selected, props.$disabled)
      case 'black':
        return BlackColor(props.$selected, props.$disabled)
    }
  }}


`

const ButtonInnerContainer = styled.div`
`

export interface CheckBoxControlProps {
  selected: boolean
  showItemLabel: boolean
  color?: 'black' | 'orange'
  labelColor?: string
  label?: string
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export function CheckBoxControl({ selected = false, disabled = false, showItemLabel = true, label, color = 'black', labelColor, className, onClick }: CheckBoxControlProps): JSX.Element {

  const theme = useTheme()

  const _handleClicked = (): void => {
    if (onClick) {
      onClick()
    }
  }

  const iconColor = disabled ? theme.colors.whites.silver : theme.colors.whites.pureWhite

  return (
    <Container>
      <ItemContainer
        className={className}
        onClick={() => _handleClicked()}>
        <ButtonOuterContainer
          $color={color}
          $selected={selected}
          $disabled={disabled}>
          <ButtonInnerContainer>
            <If condition={selected}>
              <Icon color={iconColor} icon={IconEnum.CHECKMARK} />
            </If>
          </ButtonInnerContainer>
        </ButtonOuterContainer>
        <If condition={showItemLabel}>
          <ItemLabel $color={labelColor}>{label}</ItemLabel>
        </If>
      </ItemContainer>
    </Container >
  )

}
