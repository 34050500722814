import React from 'react'

import { Svg } from './Svg'

export interface FilterProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Filter({ color, hoverColor, className }: FilterProps): JSX.Element {

  return (
    <Svg $strokeColor={color} $strokeHoverColor={hoverColor} $fillColor={color} $fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z" fill="#111111" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4 11.7222C10.4 11.2926 10.7582 10.9444 11.2 10.9444H18.4C18.8419 10.9444 19.2 11.2926 19.2 11.7222C19.2 12.1517 18.8419 12.5 18.4 12.5H11.2C10.7582 12.5 10.4 12.1517 10.4 11.7222Z" fill="white" />
      <path d="M23.36 11.9166C23.36 13.1571 22.3205 14.1933 21 14.1933C19.6795 14.1933 18.64 13.1571 18.64 11.9166C18.64 10.6761 19.6795 9.63994 21 9.63994C22.3205 9.63994 23.36 10.6761 23.36 11.9166Z" stroke="white" strokeWidth="1.28" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.8 20.2777C12.8 19.8482 13.1582 19.5 13.6 19.5L20.8 19.5C21.2419 19.5 21.6 19.8482 21.6 20.2777C21.6 20.7073 21.2419 21.0555 20.8 21.0555L13.6 21.0555C13.1582 21.0555 12.8 20.7073 12.8 20.2777Z" fill="white" />
      <path d="M8.64 20.0833C8.64 18.8428 9.67949 17.8066 11 17.8066C12.3205 17.8066 13.36 18.8428 13.36 20.0833C13.36 21.3238 12.3205 22.3599 11 22.3599C9.67949 22.3599 8.64 21.3238 8.64 20.0833Z" stroke="white" strokeWidth="1.28" />
    </Svg>
  )

}
