import React, { ChangeEvent, createRef, useEffect, useState } from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import ReactDOM from 'react-dom'
import styled, { css, CSS, useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Icon, IconEnum, SmallLoader, Button, IconSizeEnum } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ResponsivePXValue, LiteBoxShadow } from '@components/Theme'
import { useGetApiEnumQuery, useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { FormContext, FormContextProperties, FormValue, InputProps } from './Form'
import { InputWrapper } from './InputWrapper'

const Container = styled.div<{$isMobile: boolean}>`
  position: relative;
  align-self: center;
  .dropDownButton {
    ${ResponsivePXValue('padding', '0')}
  }
  .padded{
    ${ResponsivePXValue('padding', '8px 0')}
  }

  .left-icon{
    position: absolute;
    display: flex;
    ${(props): string => props.$isMobile ? ResponsivePXValue('bottom', '8px'): ResponsivePXValue('bottom', '6px')};
    ${ResponsivePXValue('left', '12px')}
    ${ResponsivePXValue('width', '12px')}
  }

  .right-icon {
    position: absolute;
    display: flex;
    ${(props): string => props.$isMobile ? ResponsivePXValue('bottom', '8px'): ResponsivePXValue('bottom', '0px')};
    ${ResponsivePXValue('left', '12px')}
    ${ResponsivePXValue('width', '12px')}
  }
`

const Disabled = css`
  border-color: ${(props): string => props.theme.colors.whites.silver};
  color: ${(props): string => props.theme.colors.whites.silver};
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ::placeholder {
    color: ${(props): string => props.theme.colors.whites.silver};
    opacity: 1;
  }
`

const SelectInputElement = styled.input<{ $error: boolean, disabled: boolean, $hasIcon: boolean, $isMobile: boolean }>`
  font-family: 'gordita';
  font-weight: 500;
  border-style: solid;
  border-color: ${(props): string => props.$error ? props.theme.colors.misc.error : props.theme.colors.whites.desertStorm};
  ${(props): string => props.$hasIcon ? ResponsivePXValue('padding', '0 4px 0 25px') : ResponsivePXValue('padding', '0 12px')};

  ${(props): string => props.$isMobile ? ResponsivePXValue('height', '26px'): ResponsivePXValue('height', '32px')};
  ${(props): string => props.$isMobile ? ResponsivePXValue('font-size', '10px'): ResponsivePXValue('font-size', '14px')};

  ${ResponsivePXValue('width', 'CALC(100% - 26px)')}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('border-width', '1px')}
  &:focus {
    outline: none;
    box-shadow: none;
    ${LiteBoxShadow}
  }

  ::placeholder {
    color: ${(props): string => props.$hasIcon ? props.theme.colors.greys.liteCodGrey : props.theme.colors.slates.bitter};
    opacity: 1;
  }

  ${(props): CSS => {
    if (props.disabled) {
      return Disabled
    }
    if (props.readOnly) {
      return `
        cursor: pointer;
      `
    }
  }}

`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '8px')}
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
`

const SelectContainer = styled.div<{ $open: boolean, $itemsToDisplay: number, $isMobile: boolean }>`
  position: absolute;
  display: ${(props): string => props.$open ? 'inline-block' : 'none'};
  z-index: 300;
  left: 0;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  overflow-x: hidden;
  overflow-y: scroll;
  ${LiteBoxShadow}

  ${(props): string => props.$isMobile ? ResponsivePXValue('max-height', `${props.$itemsToDisplay * 28}px`): ResponsivePXValue('max-height', `${props.$itemsToDisplay * 40}px`)}
  ${(props): string => props.$isMobile ? ResponsivePXValue('top', '25px'): ResponsivePXValue('top', '50px')}

  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('border-width', '1px')}
`

const SelectOptionElement = styled.div<{ $selected: boolean, $disabled?: boolean, $isMobile: boolean }>`

  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: all;
  z-index: 400;

  ${(props): string => props.$isMobile ? ResponsivePXValue('height', '26px'): ResponsivePXValue('height', '40px')};

  color: ${(props): string => props.$disabled ? props.theme.colors.whites.silver : (props.$selected ? props.theme.colors.oranges.coral : props.theme.colors.greys.tundora)};
  
  background-color: ${(props): string => props.$selected ? props.theme.colors.whites.alabaster : props.theme.colors.whites.pureWhite};
  &:hover {
    background-color: ${(props): string => props.theme.colors.whites.alabaster};
  }

`

const OptionText = styled.div<{$isMobile: boolean}>`
  font-weight: 700;
  font-family: 'gordita';

  ${ResponsivePXValue('margin-left', '16px')}

  ${(props): string => props.$isMobile ? ResponsivePXValue('font-size', '8px'): ResponsivePXValue('font-size', '10px')};

`

const OptionIcon = styled.div<{$isMobile: boolean}>`
  ${(props): string => props.$isMobile ? ResponsivePXValue('width', '15px'): ResponsivePXValue('width', '25px')}
  ${(props): string => props.$isMobile ? ResponsivePXValue('height', '15px'): ResponsivePXValue('height', '25px')}
  ${(props): string => props.$isMobile ? ResponsivePXValue('margin-right', '8px'): ResponsivePXValue('margin-right', '16px')}
  ${(props): string => props.$isMobile ? ResponsivePXValue('padding-bottom', '4px'): ResponsivePXValue('padding-bottom', '8px')}
`

export interface SelectOption {
  title: string
  value: FormValue
  disabled?: boolean
}

interface Values {
  value?: FormValue
  onChange?: (value: FormValue) => void
}
interface SelectInputInnerProps extends SelectInputProps {
  control: Values
  meta: Meta
  form: FormInstance
  itemsToDisplay?: number
  icon?: IconEnum
  iconColor?: string
}

function SelectInputInner(props: SelectInputInnerProps): JSX.Element {

  const config = useConfig()
  const { placeholder = '', label, rules, showLabel = true, name, onSearchChange, options, loading, disabled, apiEnum, meta, control, form, itemsToDisplay = 6, variant = 'standard', readOnly = false, icon, iconColor, className } = props
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<SelectInputState>({ ...DEFAULT_STATE })
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })
  const theme = useTheme()

  let availableOptions: SelectOption[] = options ?? []
  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }
  if (state.search) {
    availableOptions = availableOptions.filter((option) => {
      return option.title.toLowerCase().indexOf(state.search.toLowerCase()) !== -1
    })
  }
  const optionsRef: React.RefObject<HTMLDivElement> = createRef()
  const optionRefs: React.RefObject<HTMLDivElement>[] = availableOptions.map(() => createRef())

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )
  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, search: e.target.value }))
    onSearchChange?.(e.target.value)
  }

  const _handleChange = (value: FormValue, e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    const newValue = value
    onChange(newValue)
    setState((prevState) => ({ ...prevState, open: false, search: '' }))
  }

  const _handleKeyDown = (e: KeyboardEvent): void => {
    if (!state.open) {
      return
    }
    const options = availableOptions
    if (e.key === 'Enter') {
      e.preventDefault()
      // onSelectNext(this.props.dataKey)
      setState((prevState) => ({ ...prevState, open: false, search: '' }))
    } else if (e.key === 'ArrowDown') {
      if (options.length) {
        if (value) {
          let index = options.findIndex((option) => {
            return option.value === value
          })
          if (index < options.length - 1) {
            index++
          } else {
            index = 0
          }
          onChange(options[index].value)
        } else {
          onChange(options[0].value)
        }
      }
    } else if (e.key === 'ArrowUp') {
      if (options.length) {
        if (value) {
          let index = options.findIndex((option) => {
            return option.value === value
          })
          if (index > 0) {
            index--
          } else {
            index = options.length - 1
          }
          onChange(options[index].value)
        } else {
          onChange(options[options.length - 1].value)
        }
      }
    }
  }

  const _handleToggleOpen = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }))
  }

  const _handleBlur = () => {
    setState((prevState) => ({ ...prevState, search: '', open: false }))
  }

  const _handleFocus = () => {
    setState((prevState) => ({ ...prevState, open: true, search: '' }))
  }

  useEffect(() => {
    if (config.isBrowser()) {
      window.addEventListener('keydown', _handleKeyDown)
      return () => window.removeEventListener('keydown', _handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (value && state.open) {
      const index = availableOptions.findIndex((option) => option.value === value)
      const currentScroll = optionsRef.current.scrollTop
      const containerHeight = optionsRef.current.clientHeight
      const itemHeight = optionsRef.current.clientHeight / itemsToDisplay
      const selectedItemTop = itemHeight * index
      if (selectedItemTop >= currentScroll + containerHeight) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop + itemHeight - containerHeight)
      } else if (selectedItemTop < currentScroll) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop)
      }
    }
  }, [value])

  let inputValue = state.search
  if (value) {
    inputValue = availableOptions.find((option) => option.value === value)?.title
  }
  let option: SelectOption
  let optionIndex: number
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const iconSize = isMobile ? IconSizeEnum.SMALL : IconSizeEnum.MEDIUM

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}
      className={className}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container className={className} $isMobile={isMobile}>
            <If condition={!!icon}>
              <Icon
                className='left-icon'
                icon={icon}
                size={iconSize}
                color={iconColor || theme.colors.greys.mineshaft}/>
            </If>
            <SelectInputElement
              className='select-input'
              onBlur={_handleBlur}
              onFocus={_handleFocus}
              autoComplete='off'
              disabled={disabled || loading || apiLoading || formLoading || formDisabled}
              name={name}
              $error={!!error}
              type='text'
              $hasIcon={!!icon}
              readOnly={readOnly}
              value={inputValue}
              placeholder={placeholder}
              $isMobile={isMobile}
              onChange={_handleSearchChange} />
            <SelectContainer ref={optionsRef} $open={state.open} $itemsToDisplay={itemsToDisplay} $isMobile={isMobile} className={`select-option-container ${variant === 'highlighted' ? 'padded' : null}`}>
              <If condition={!!availableOptions.length}>
                <For each='option' of={availableOptions || []} index='optionIndex'>
                  <SelectOptionElement
                    ref={optionRefs[optionIndex]}
                    onMouseDown={(option.disabled ? () => null : (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => _handleChange(option.value, e))}
                    $disabled={option.disabled === true}
                    $selected={option.value === value}
                    $isMobile={isMobile}
                    key={option.value.toString()}>
                    <OptionText $isMobile={isMobile}>
                      {option.title}
                    </OptionText>
                    <If condition={option.value === value}>
                      <OptionIcon $isMobile={isMobile}>
                        <Icon
                          icon={IconEnum.CHECKMARK}
                          size={iconSize}
                          color={theme.colors.oranges.coral} />
                      </OptionIcon>
                    </If>
                  </SelectOptionElement>
                </For>
              </If>
            </SelectContainer>
            <LoadingContainer className='loading-container'>
              <Choose>
                <When condition={loading || apiLoading || formLoading}>
                  <SmallLoader
                    color={theme.colors.whites.silver} />
                </When>
                <When condition={state.open}>
                  <Button
                    color='transparent'
                    className='right-icon'
                    size='small'
                    icon={IconEnum.CHEVRON_UP_OUTLINE}
                    isMiniCardButton={isMobile}
                    iconColor={theme.colors.greys.mineshaft}
                    onClick={_handleToggleOpen} />
                </When>
                <Otherwise>
                  <Button
                    color='transparent'
                    className='right-icon'
                    icon={IconEnum.CHEVRON_DOWN_OUTLINE}
                    isMiniCardButton={isMobile}
                    iconColor={theme.colors.greys.mineshaft}
                    onClick={_handleToggleOpen} />
                </Otherwise>
              </Choose>
            </LoadingContainer>
          </Container>
        )}
      </FormContext.Consumer>

    </InputWrapper>
  )

}

interface SelectInputState {
  search: string
  open: boolean
}

export interface SelectInputProps extends InputProps {
  rules?: Rule[]
  variant?: 'standard' | 'highlighted'
  apiEnum?: string
  options?: SelectOption[]
  placeholder?: string
  readOnly?: boolean
  className?: string
  itemsToDisplay?: number
  icon?: IconEnum
  onSearchChange?: (search: string | null) => void
}

const DEFAULT_STATE = {
  search: '',
  open: false,
}

export function SelectInput(props: SelectInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <SelectInputInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
