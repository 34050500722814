import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import update from 'react-addons-update'
import styled from 'styled-components'
import { Paragraph, ResponsiveImage, ResponsiveImageData, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@client/components/Theme'
import { CheckBox, FieldData, Form, Modal, useForm } from '@molecules/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', { desktop: '576px' })}
`
const ImageContainer = styled.div`
   display: flex;
   overflow: hidden;
   flex: 1;
   cursor: pointer;
   border-radius: 6px;
   
  ${ResponsivePXValue('height', { mobile: '162px', tablet: '162px', desktop: '272px' })}
  ${ResponsivePXValue('max-height', { mobile: '162px', tablet: '162px', desktop: '272px' })}

  img {
    aspect-ratio: 3 / 2;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .check-input {
    margin: 0;
    flex-grow: initial;
    div {
      font-family: gordita;
      font-weight: 400;
      ${ResponsivePXValue('font-size', { mobile: '10px', desktop: '12px' })}
      ${ResponsivePXValue('line-height', '16px')}
    }
  }
`

export interface LunchModalProps {
  open: boolean
  image: ResponsiveImageData
  title?: string
  onClose?: () => void
}

interface LunchModalState {
  open: boolean
}

const DEFAULT_STATE: LunchModalState = {
  open: false,
}

export function LunchModal({ open, image, title = 'Lunch', onClose }: LunchModalProps): JSX.Element {

  const [state, setState] = useState<LunchModalState>({ ...DEFAULT_STATE })
  const [form] = useForm()
  const lunchModalCookieName = 'doNotShowLunchModal'

  useEffect(() => {
    const shouldOpen = Cookies.get(lunchModalCookieName) !== 'true' && open
    setState((prevState) => update(prevState, {
      open: { $set: shouldOpen },
    }))
  }, [open])

  const _handleClose = (): void => {

    setState((prevState) => update(prevState, {
      open: { $set: false },
    }))

    if (onClose) onClose()
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        const doNotShowAgain = name === 'doNotShowAgain' && field.value.length > 0
        if (doNotShowAgain) {
          Cookies.set(lunchModalCookieName, 'true', { expires: 365 })
        }
      })
    })
  }

  return (
    <Modal
      title={title}
      open={state.open}
      onClose={_handleClose}
      showCloseButton
      fullscreen={false}
      resolutionAdapter>
      <Container>
        <Paragraph variant='p1'>
              Lunch is a serves 1 portion only. Ordering lunch won't impact your dinner subscription, where you can continue to select for up to 4 servings per meal
        </Paragraph>
        <Spacer mobile='12px' desktop='16px' />
        <ImageContainer>
          <ResponsiveImage image={image} />
        </ImageContainer>
        <Spacer mobile='12px' desktop='16px' />
        <Form form={form} onFieldsChange={_handleChange}>
          <CheckboxContainer>
            <CheckBox
              name='doNotShowAgain'
              showLabel={false}
              showOptional={false}
              options={[{ title: 'Don’t show this again', value: true }]}
              className='check-input' />
          </CheckboxContainer>
        </Form>
      </Container>
    </Modal>
  )
}
