import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { ErrorBlock } from '@client/components/molecules'
import { UserAddressFragment } from '@hooks/api'
import { ModalLoading } from '@molecules/index'

import { EditAddressForm } from '../forms'
import { AddAddressForm } from '../forms/AddAddressForm'

enum AddressModalStepEnum {
  ADD_ADDRESS = 'ADD_ADDRESS',
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

export interface AddressModalProps {
  userAddress?: UserAddressFragment
  onClose: () => void
}

interface AddressModalState {
  displayStep: AddressModalStepEnum
  error: string
}

const DEFAULT_STATE: AddressModalState = {
  displayStep: AddressModalStepEnum.ADD_ADDRESS,
  error: '',
}

export function AddressModal({ userAddress, onClose }: AddressModalProps): JSX.Element {

  const [state, setState] = useState<AddressModalState>({ ...DEFAULT_STATE })

  const _handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const _handleError = (message: string): void => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddressModalStepEnum.ERROR },
      error: { $set: message },
    }))
  }

  useEffect(() => {
    if (userAddress) {
      setState((prevState) => update(prevState, {
        displayStep: { $set: AddressModalStepEnum.EDIT_ADDRESS },
      }))
    }
  }, [userAddress])

  return (
    <Container>
      <Choose>
        <When condition={state.displayStep === AddressModalStepEnum.LOADING}>
          <ModalLoading message={`Attempting to ${userAddress ? 'update existing' : 'add a new'} address`} />
        </When>
        <When condition={state.displayStep === AddressModalStepEnum.ERROR}>
          <ErrorBlock
            title={state.error}
            actionTitle='Back'
            onClick={() => setState((prevState) => ({ ...prevState, displayStep: AddressModalStepEnum.ADD_ADDRESS }))} />
        </When>
        <When condition={state.displayStep === AddressModalStepEnum.EDIT_ADDRESS && !!userAddress}>
          <EditAddressForm
            userAddress={userAddress}
            onSuccess={_handleClose}
            onError={(message) => _handleError(message)} />
        </When>
        <Otherwise>
          <AddAddressForm
            onSuccess={_handleClose}
            onError={(message) => _handleError(message)} />
        </Otherwise>
      </Choose>
    </Container>
  )
}
