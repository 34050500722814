import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/PrivacyPolicy')

const PrivacyPolicy = loadable(() => import(/* webpackChunkName: "privacy-policy" */'@pages/PrivacyPolicy'), {
  resolveComponent: (components) => components.PrivacyPolicy,
})

export function PrivacyPolicyAsync(): JSX.Element {

  return <PrivacyPolicy fallback={<div>Loading...</div>} />

}
