import React, { useEffect } from 'react'

export function usePortal(id: string): HTMLDivElement | null {

  if (typeof document !== 'undefined') {
    const rootElemRef = React.useRef(document.createElement('div'))

    useEffect(function setupElement() {
      const parentElem = document.querySelector(`#${id}`)
      parentElem.appendChild(rootElemRef.current)
      return function removeElement(): void {
        rootElemRef.current.remove()
      }
    }, [])

    return rootElemRef.current
  }

  return null
}
