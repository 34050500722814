import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Paragraph, IconEnum, Rule, Button, Link, Spacer } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.whites.alabaster};
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', '48px 0')}

`
const KnowMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 0 0 130px', desktop: '0 0 0 130px' })}
  ${ResponsivePXValue('width', { tablet: '648px', desktop: '648px' })}
  div{
    margin: 0vw;
    align-self: flex-start
  }
`

const TextContainer = styled.div`
${ResponsivePXValue('padding-top', '8px')}
`

const ButtonContainer = styled.div`
${ResponsivePXValue('padding', '32px 0')}
`

const QuestionsContainer = styled.div`
${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 130px', desktop: '0 130px' })}
${ResponsivePXValue('width', { tablet: '100%', desktop: '100%' })}
`

const QuestionLinkContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  &:hover {
    div {
      color: ${(props): string => props.theme.colors.oranges.coral};
    }
  }
`

const QuestionColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export interface FaqQuestion {
  question: string
  link: string
  index: number
}
export interface FAQsProps {
  questions: FaqQuestion[]
}

export function FAQs({ questions }: FAQsProps): JSX.Element {

  const theme = useTheme()

  let question: FaqQuestion
  return (
    <Container>
      <KnowMoreContainer>
        <Heading variant='misc' color={theme.colors.slates.bitter} align='left'>
          Want to know more?
        </Heading>
        <TextContainer>
          <Paragraph align='left'>
            We’ve got all the answers! Use our online chat feature if you’d like to speak to us directly.
          </Paragraph>
        </TextContainer>
        <ButtonContainer>
          <Link color={theme.colors.oranges.coral} href='https://support.ucook.co.za/hc/en-us'>SEE ALL FAQ'S</Link>
        </ButtonContainer>
      </KnowMoreContainer>
      <QuestionsContainer>
        <For each='question' of={questions}>
          <QuestionColumn key={question.index}>
            <QuestionLinkContainer href={question.link} target='_blank'>
              <Paragraph align='left' variant='p2'>{question.question}</Paragraph>
              <Button
                color='transparent'
                icon={IconEnum.ARROW_FORWARD}
                iconColor={theme.colors.greys.mineshaft} />
            </QuestionLinkContainer>
            <Rule color='slate' />
            <Spacer universal='16px' />
          </QuestionColumn>
        </For>
      </QuestionsContainer>
    </Container>
  )

}
