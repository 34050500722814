import styled from 'styled-components'

import { ResponsivePXValue } from '@client/components/Theme'

export const FormBlock = styled.div`
  width: 100%;
  max-height: 95vh;
  padding-top: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  ${ResponsivePXValue('width', { mobile: '280px', tablet: '400px', desktop: '500px' })};
`
