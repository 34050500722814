import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router'
import styled, { useTheme, CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Link, Paragraph, Spacer } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
export interface CopyrightType {
  plans?: boolean
}

const DEFAULT_STATE: CopyrightType = {
  plans: false,
}

const Container = styled.div<{ $planPage?: boolean }>`

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  
  ${ResponsivePXValue('justify-content', { mobile: 'center', tablet: 'center', desktop: 'space-around' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column-reverse', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('padding', { mobile: '8px 16px', tablet: '24px 0', desktop: '32px 0' })}

  ${(props): string => props.theme.desktop} {
    width: 100%;
  }

  ${(props): CSS => props.$planPage ? ResponsivePXValue('margin-bottom', { tablet: '80px', desktop: '90px' }) : undefined};

  .footerText {
    ${ResponsivePXValue('font-size', '12px')}
  }

  .link {
    font-weight: 500;
    line-height: 18px;
    color: ${(props): string => props.theme.colors.whites.silver};
    border-right:  1px solid ${(props): string => props.theme.colors.whites.silver};
    ${ResponsivePXValue('font-size', '12px')}
    ${ResponsivePXValue('margin-bottom', '8px')}
    ${ResponsivePXValue('margin-right', '8px')}
    ${ResponsivePXValue('padding-right', '8px')}

    :last-child {
      border-right: none;
    }
  }
`
const LinkContainer = styled.div`

  display: flex;
`

export function Copyright(): JSX.Element {

  const theme = useTheme()

  const location = useLocation()

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const [inMealPan, setInMealPan] = useState<CopyrightType>({ ...DEFAULT_STATE })

  const currentYear = new Date().getUTCFullYear()

  useEffect(() => {
    const pathname = location.pathname
    const mealkitPlanPath = '/meal-kit/plans'

    if (((pathname === mealkitPlanPath) && !isMobile)) {
      setInMealPan((prevState) => ({ ...prevState, plans: true }))
    } else {
      setInMealPan((prevState) => ({ ...prevState, plans: false }))
    }
  }, [location])

  return (
    <Container $planPage={inMealPan.plans}>
      <Paragraph bold className='footerText' variant='p3' align='center' color={theme.colors.whites.silver}>
        {`${currentYear} © UCOOK. All rights reserved by The Supper Society Proprietary Limited | Liquor License: WCP/042073 | GAU/10615`}
      </Paragraph>
      <Spacer mobile='8px' />
      <LinkContainer>
        <Link className='link' variant='l2' href='/terms'> Terms & Conditions</Link>
        <Link className='link' variant='l2' href='/privacy-policy'> Privacy Policy</Link>
        <Link className='link' variant='l2' href='/referral-policy'> Referral Terms</Link>
      </LinkContainer>
      <Spacer mobile='8px' />
    </Container>
  )
}
