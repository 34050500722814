import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, SmallLoader } from '@client/components/atoms'
import { LiteBoxShadow, ResponsivePXValue } from '@client/components/Theme'

const Container = styled.div`

  overflow: hidden;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

  ${LiteBoxShadow}

  ${ResponsivePXValue('border-radius', { mobile: '8px', tablet: '12px', desktop: '16px' })}
  ${ResponsivePXValue('margin-bottom', '24px')}

  .heading {
    margin: 0;
    padding: 0;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props): string => props.theme.colors.whites.alabaster};

  ${ResponsivePXValue('height', { mobile: '72px', tablet: '76px', desktop: '80px' })}
  ${ResponsivePXValue('padding', '0 16px')}
`

const ContentContainer = styled.div`
  ${ResponsivePXValue('width', 'CALC(100% - 32px)')}
  ${ResponsivePXValue('padding', '16px')}
`

const LoaderContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

export interface SummaryBlockProps {
  title: string
  children: JSX.Element | JSX.Element[]
  loading?: boolean
}

export function SummaryBlock({ title, children, loading }: SummaryBlockProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <TitleContainer>
        <Heading variant='h5' className='heading'>
          {title}
        </Heading>
        <If condition={loading}>
          <LoaderContainer>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoaderContainer>
        </If>
      </TitleContainer>
      <ContentContainer>
        {children}
      </ContentContainer>
    </Container>
  )

}
