import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE, UserPlugin } from '@api/local'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PageNotificationFragment, useGetAppQuery, useGetOnePageNotificationGroupQuery } from '@hooks/api'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { PageNotificationBanner } from '../content'

const TransitionContainer = styled.div<{ $show: boolean }>`
  display: ${(props): string => props.$show ? 'flex' : 'none'};
`
interface HideBannerOnScrollProps {
  children: React.ReactNode
  bannerHeight: number
}

interface HideBannerOnScrollState {
  showNotificationBar: boolean
}

const DEFAULT_HIDE_BANNER_STATE: HideBannerOnScrollState = {
  showNotificationBar: true,
}

function HideBannerOnScroll({ bannerHeight, children }: HideBannerOnScrollProps): JSX.Element {
  const [state, setState] = useState<HideBannerOnScrollState>({ ...DEFAULT_HIDE_BANNER_STATE })
  NavigationPlugin.shared().useScrollPosition(
    ({ prevPos, currPos }) => {
      if (bannerHeight) {
        if (!state.showNotificationBar && prevPos.y > 0 - bannerHeight) {
          setState((prevState) => update(prevState, {
            showNotificationBar: { $set: false },
          }))
        } else {
          const shouldShow = currPos.y === 0
          if (shouldShow !== state.showNotificationBar) {
            setState((prevState) => update(prevState, {
              showNotificationBar: { $set: shouldShow },
            }))
          }
        }
      }
    },
    [state.showNotificationBar],
    300,
  )
  return <TransitionContainer $show={state.showNotificationBar}>
    {children}
  </TransitionContainer>
}
export interface ToastHeaderProps {
  identifier?: string
}

interface ToastHeaderState {
  loading: boolean
  currentNotifications: PageNotificationFragment[]
  showNotificationBar: boolean
  bannerHeight: number | null
  scrollHookLoaded: boolean
}

const DEFAULT_STATE: ToastHeaderState = {
  loading: false,
  currentNotifications: [] as PageNotificationFragment[],
  showNotificationBar: true,
  bannerHeight: null,
  scrollHookLoaded: false,
}

export function ToastHeader({ identifier = 'SITE_WIDE' }: ToastHeaderProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<ToastHeaderState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: notificationData, loading: notificationLoading } = useGetOnePageNotificationGroupQuery({ variables: { identifier } })

  useEffect(() => {
    if (!notificationLoading) {
      _setNotifications()
    }
  }, [notificationLoading])

  const _setNotifications = (): void => {
    if (notificationData?.pageNotificationGroup?.currentNotifications?.length > 0) {
      const currentNotifications = notificationData?.pageNotificationGroup?.currentNotifications?.filter((value) => _notificationValid(value.id))
      setState((prevState) => update(prevState, {
        currentNotifications: { $set: currentNotifications },
      }))
    }
  }

  const _handleHeightChange = (bannerHeight: number) => {
    setState((prevState) => update(prevState, {
      bannerHeight: { $set: bannerHeight },
    }))
  }

  const _handleOnDismissNotification = (id: string): void => {
    _setLoading(true)
    // TODO:
    UserPlugin.shared().addDismissedNotification(id)
    _setNotifications()
    _setLoading(false)
  }

  const _notificationValid = (id: string): boolean => {
    // TODO:
    return !UserPlugin.shared().notificationIsDismissed(id)
  }

  const _setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const loading = notificationLoading || state.loading

  return (
    <If condition={!loading && state.currentNotifications?.length > 0}>
      <Choose>
        <When condition={appData.app.deviceType === DeviceTypeEnum.MOBILE && !!state.bannerHeight}>
          <HideBannerOnScroll bannerHeight={state.bannerHeight}>
            <PageNotificationBanner
              onHeightChange={_handleHeightChange}
              dismissLoading={loading}
              onClose={() => _handleOnDismissNotification(state.currentNotifications[0].id)}
              notification={state.currentNotifications[0]} />
          </HideBannerOnScroll>
        </When>
        <Otherwise>
          <PageNotificationBanner
            onHeightChange={_handleHeightChange}
            dismissLoading={loading}
            onClose={() => _handleOnDismissNotification(state.currentNotifications[0].id)}
            notification={state.currentNotifications[0]} />
        </Otherwise>
      </Choose>
    </If>
  )
}
