import React, { useState } from 'react'

import update from 'react-addons-update'
import styled, { CSS, useTheme } from 'styled-components'

import { DashedTable, DashedTableRow, Heading, ResponsivePXValue, Paragraph, Button } from '@client/components'
import { SummaryBlock } from '@client/components/molecules/stores/SummaryBlock'
import { ZeroSpace } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { CartCostItemFragment, useUserCartQuery } from '@hooks/api'
import { CheckoutTypeEnum, NumberOfPortionsEnum } from '@uctypes/api/globalTypes'

import { CartWallet } from '../cart'

const ElementWrapper = styled.div<{ $smallerMargin?: boolean }>`
  
  ${(props): CSS => {
    if (props.$smallerMargin) {
      return ResponsivePXValue('margin-top', '0 !important')
    }
  }}

  .text-item, .link-item {
    margin: 0;
    padding: 0;
  }

  .link-item {
    cursor: pointer;
    &:hover {
      color: ${(props): string => props.theme.colors.oranges.burntSienna};
    }
  }
`

const ErrorBlock = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(251, 108, 89, 0.1);
  ${(props): CSS => {
    return ResponsivePXValue('border', `1px solid ${props.theme.colors.misc.error}`)
  }}
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('width', 'CALC(100% - 32px)')}
  ${ResponsivePXValue('padding', '16px')}
  ${ResponsivePXValue('margin-top', '16px')}

  .text {
    margin: 0;
    padding: 0;
  }
`

const InfoBlock = styled.div`
  .button {
    ${ResponsivePXValue('margin-top', '16px')}
  }
`

const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;

  .button {
    ${ZeroSpace}
    border-radius: 0;
  }
`

const WalletContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding', '16px 0')}
  ${(props): CSS => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.whites.desertStorm}`)}
`

const Link = styled.a`
  font-family: 'gordita';
  display: block;
  ${ResponsivePXValue('margin', '10px 0 8px 0')}
  ${ResponsivePXValue('font-size', '12px')}
  color: ${(props): string => props.theme.colors.blues.danube};
`

export interface CheckoutOrderSummaryProps {
  loading?: boolean
  error?: string
  buttonText?: string
  onCheckout?: () => void
  onWalletChange?: (open: boolean) => void
  showDetail?: boolean
  type: CheckoutTypeEnum
}

interface CheckoutOrderSummaryState {
  walletOpen: boolean
}

const DEFAULT_STATE: CheckoutOrderSummaryState = {
  walletOpen: false,
}

export function CheckoutOrderSummary({ loading, error, buttonText = 'CONTINUE', onCheckout, onWalletChange, showDetail = false, type }: CheckoutOrderSummaryProps): JSX.Element {

  const config = useConfig()
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [state, setState] = useState<CheckoutOrderSummaryState>({ ...DEFAULT_STATE })
  const theme = useTheme()

  const getPortionSize = (portion: NumberOfPortionsEnum) => {
    switch (portion) {
      case NumberOfPortionsEnum.SERVES_1:
        return '1 person '
      case NumberOfPortionsEnum.SERVES_2:
        return '2 people '
      case NumberOfPortionsEnum.SERVES_3:
        return '3 people '
      case NumberOfPortionsEnum.SERVES_4:
        return '4 people '
    }
  }

  const getMealCountText = (mealCount: number) => {
    return `${mealCount} Dishes`
  }

  const _handleWalletChange = (open: boolean): void => {
    setState((prevState) => update(prevState, {
      walletOpen: { $set: open },
    }))
    onWalletChange?.(open)
  }

  const isLoading = loading || userCartLoading
  const subscriptionCost = userCartData?.currentUser?.activeCart?.cartItems?.reduce((total, cartItem) => {
    if (!cartItem.isFromSubscription) {
      return total
    }
    return total + cartItem.price
  }, 0)
  const mealkitPlanPrice = userCartData?.currentUser?.activeCart?.cartItems?.find((item) => { return item.product.__typename === 'MealKitPlan' })
  const costPerPortion = mealkitPlanPrice?.price / userCartData?.currentUser?.activeCart?.totalSubscriptionItems
  const categoryTitle = userCartData?.currentUser?.activeMenu?.subscription?.category?.title
  const categorySelection = getPortionSize(userCartData?.currentUser?.activeMenu?.subscription?.numberOfPortions) + getMealCountText(userCartData?.currentUser?.activeMenu?.subscription?.numberOfMeals)
  const onDemandCose = userCartData?.currentUser?.activeCart?.cartItems?.reduce((total, cartItem) => {
    if (cartItem.isFromSubscription) {
      return total
    }
    return total + cartItem.price
  }, 0)
  const deliveryCost = userCartData?.currentUser?.activeCart?.additions?.find((addition) => addition.title === 'Delivery Fee')?.value ?? 0
  const discountCost = userCartData?.currentUser?.activeCart?.reductions?.reduce((total, reduction) => {
    if (reduction.id === 'USER_POINTS') {
      return total
    }
    return total + reduction.value
  }, 0)
  const showDiscounts = showDetail && userCartData?.currentUser?.activeCart?.reductions?.length > 0

  const __getNormalRowElement = (text: string, smallerMargin = false): JSX.Element => {
    return (
      <ElementWrapper $smallerMargin={smallerMargin}>
        <Paragraph className='text-item' variant='p1'>{text}</Paragraph>
      </ElementWrapper>
    )
  }

  const __getBoldRowElement = (text: string, smallerMargin = false): JSX.Element => {
    return (
      <ElementWrapper $smallerMargin={smallerMargin}>
        <Paragraph className='text-item' variant='p2'>{text}</Paragraph>
      </ElementWrapper>
    )
  }

  const __getTotalRowElement = (text: string, smallerMargin = false): JSX.Element => {
    return (
      <ElementWrapper $smallerMargin={smallerMargin}>
        <Heading className='text-item' variant='h5'>{text}</Heading>
      </ElementWrapper>
    )
  }

  const costOfDelivery = deliveryCost === 0 ? 'Free' : `R${deliveryCost.toFixed(2)}`

  let reduction: CartCostItemFragment
  let reductionIndex: number

  return (
    <SummaryBlock title='Order Summary' loading={isLoading}>
      <DashedTable>
        <If condition={type !== CheckoutTypeEnum.SUBSCRIPTION}>
          <DashedTableRow seperated title={__getBoldRowElement('Your Cart')}>
            {__getBoldRowElement(`R${userCartData?.currentUser?.activeCart?.total.toFixed(2)}`)}
          </DashedTableRow>
        </If>
        <If condition={type === CheckoutTypeEnum.SUBSCRIPTION}>
          <DashedTableRow title={__getNormalRowElement(categoryTitle)}>
            {__getNormalRowElement(categorySelection)}
          </DashedTableRow>
          <DashedTableRow seperated title={<Link href="/meal-kit/plans">Edit plan</Link>}></DashedTableRow>
          <DashedTableRow noBottomSpace title={__getNormalRowElement('Meal kit cost')}>
            {__getNormalRowElement(`R${subscriptionCost.toFixed(2)}`)}
          </DashedTableRow>
          <DashedTableRow title={__getNormalRowElement('Cost per portion')}>
            {__getNormalRowElement(`R${costPerPortion.toFixed(2)}`)}
          </DashedTableRow>
          <DashedTableRow seperated title={__getNormalRowElement('Add-ons', true)}>
            {__getNormalRowElement(`R${onDemandCose.toFixed(2)}`, true)}
          </DashedTableRow>
        </If>
        <DashedTableRow seperated title={__getBoldRowElement('Delivery')}>
          {__getBoldRowElement(costOfDelivery)}
        </DashedTableRow>
        <DashedTableRow seperated={!showDiscounts} title={__getBoldRowElement('Discount')}>
          {__getBoldRowElement(`R${discountCost.toFixed(2)}`)}
        </DashedTableRow>
        <If condition={showDiscounts}>
          <For each='reduction' index='reductionIndex' of={userCartData?.currentUser?.activeCart?.reductions || []}>
            <DashedTableRow
              key={reduction.id}
              seperated={reductionIndex === userCartData?.currentUser?.activeCart?.reductions.length - 1}
              title={__getNormalRowElement(reduction.title, true)}>
              {__getNormalRowElement(`R${reduction.value.toFixed(2)}`, true)}
            </DashedTableRow>
          </For>
        </If>
        <Choose>
          <When condition={showDetail}>
            <WalletContainer>
              <CartWallet checkout onChange={_handleWalletChange} />
            </WalletContainer>
          </When>
          <Otherwise>
            <DashedTableRow seperated={!showDetail} title={__getBoldRowElement('Wallet')}>
              {__getBoldRowElement(`R${userCartData?.currentUser?.activeCart?.assignedUserPoints.toFixed(2)}`)}
            </DashedTableRow>
          </Otherwise>
        </Choose>
        <DashedTableRow seperated title={__getTotalRowElement('TOTAL')}>
          {__getTotalRowElement(`R${userCartData?.currentUser?.activeCart?.grandTotal.toFixed(2)}`)}
        </DashedTableRow>
      </DashedTable>
      <Choose>
        <When condition={!error}>
          <DeviceContainer $tablet $desktop>
            <InfoBlock>
              <Button
                fluid
                className='button'
                title={buttonText}
                disabled={state.walletOpen}
                onClick={onCheckout} />
            </InfoBlock>
          </DeviceContainer>
          <DeviceContainer $mobile>
            <MobileButtonContainer>
              <Button
                fluid
                className='button'
                title={buttonText}
                disabled={state.walletOpen}
                onClick={onCheckout} />
            </MobileButtonContainer>
          </DeviceContainer>
        </When>
        <Otherwise>
          <ErrorBlock>
            <Paragraph
              className='text'
              variant='p3'
              align='center'
              color={theme.colors.misc.error}>
              {error}
            </Paragraph>
          </ErrorBlock>
        </Otherwise>
      </Choose>
    </SummaryBlock>
  )

}
