import React, { Fragment } from 'react'

import { UserPreferencesList } from '../organisms/index'

export function UserPreferences(): JSX.Element {

  return (
    <Fragment>
      <UserPreferencesList />
    </Fragment>
  )

}
