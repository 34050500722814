import React from 'react'

import styled, { CSS } from 'styled-components'

import { Icon, IconEnum, Paragraph, Spacer } from '@client/components'
import { DisabledEffect, ResponsivePXValue, theme, ZeroSpace } from '@components/Theme'

const Container = styled.div<{ $background?: string, $disabled?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  border-radius: 6px;

  background-color: ${(props): string => props.$background ? props.$background : props.theme.colors.whites.pureWhite};

  ${(props): CSS => props.$disabled ? DisabledEffect : undefined};

  ${ResponsivePXValue('padding', '3px 8px')}

  .title {
    ${ZeroSpace}
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
`

export interface PillProps {
  title: string
  backgroundColor?: string
  textColor?: string
  className?: string
  iconClassName?: string
  iconColor?: string
  closable?: boolean
  disabled?: boolean
  isMini?: boolean
  onClick?: () => void
  onClose?: () => void
}

export function Pill({
  title,
  backgroundColor,
  textColor = theme.colors.whites.pureWhite,
  className,
  iconClassName,
  iconColor = theme.colors.whites.pureWhite,
  closable = false,
  disabled = false,
  isMini = false,
  onClick,
  onClose,
}: PillProps): JSX.Element {

  const _handleOnClick = (): void => {
    if (!disabled && onClick) {
      onClick()
    }
  }

  const _handleOnClose = (): void => {
    if (!disabled && onClose) {
      onClose()
    }
  }

  return (
    <Container className={className} $background={backgroundColor} $disabled={disabled} onClick={_handleOnClick}>

      <Choose>
        <When condition={isMini}>
          <Paragraph variant='p3' color={textColor} className='title'>{title}</Paragraph>
        </When>
        <Otherwise>
          <Paragraph variant='p2' color={textColor} className='title' bold>{title}</Paragraph>

        </Otherwise>
      </Choose>
      <If condition={closable}>
        <Spacer universal='12px' variant='horizontal' />
        <IconContainer onClick={_handleOnClose}>
          <Icon className={iconClassName} icon={IconEnum.CLOSE_OUTLINE} color={iconColor} />
        </IconContainer>
      </If>
    </Container>
  )
}
