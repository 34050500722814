import React, { useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Seperator } from '@atoms/index'
import { Paragraph, ResponsivePXValue, Spacer } from '@client/components'
import { TabPane, Tabs, Accordion, Tab, Biography, BiographyProps, TextList, TextListProps, SelectOption, Form, useForm, FieldData, RadioButton, RadioButtonProps, RadioButtonOption } from '@molecules/index'
import { DeviceContainer } from '@utility/DeviceContainer'

import { Ingredients, IngredientsProps } from './Ingredients'

export interface MoreAboutMealProps {
  chef: BiographyProps
  isPizza: boolean
  ingredients: IngredientsProps
  cookingInstructions: TextListProps
  servingSuggestions: TextListProps
  servingSizeOptions: SelectOption[]
  servingSize: string
  servingSizeChange: (servingSize: string) => void
}

enum TabEnum {
  ABOUT_THE_DISH = 'About the dish',
  INGREDIENTS = 'Ingredients',
  COOKING_INSTRUCTIONS = 'Heating instructions',
  SERVING_SUGGESTIONS = 'Serving Suggestions'
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;

  .radio-container {
    justify-content: center;
    display: flex;

    ${ResponsivePXValue('padding-top', '24px')}
  }

`

interface TabsState {
  activeTab: string
  servingSize: string
}

const DEFAULT_STATE: TabsState = {
  activeTab: TabEnum.ABOUT_THE_DISH,
  servingSize: '',
}

export function MoreAboutMeal({ chef, ingredients, cookingInstructions, servingSuggestions, servingSizeOptions, servingSize, servingSizeChange, isPizza }: MoreAboutMealProps): JSX.Element {

  const [state, setState] = useState<TabsState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const [form] = useForm()

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSize') {
          servingSizeChange(field.value)
        }
      })
    })
  }

  const servingSizeRadioOptions = () => {
    return servingSizeOptions.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        disabled: option.disabled,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
      }
    }) as unknown as RadioButtonOption[]
  }

  const servingSizeOptionsRadioProps: RadioButtonProps = {
    className: 'radio-container',
    name: 'servingSize',
    outline: false,
    options: [...servingSizeRadioOptions()],
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  form.setFieldsValue({ servingSize })

  return (
    <Form
      form={form}
      onFieldsChange={_handleChange}>
      <Container>
        <Heading align='center' variant='misc' color={theme.colors.slates.bitter}>More about this meal</Heading>
        <Spacer universal='8px' />
        <Seperator align='center' />
        <DeviceContainer $mobile>
          <Accordion
            activeTab={state.activeTab}
            backgroundColor={theme.colors.whites.pureWhite}
            onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
            <Tab tab={TabEnum.ABOUT_THE_DISH} title={(isPizza) ? 'About this Craft Pizza' : TabEnum.ABOUT_THE_DISH}>
              <Biography {...chef} />
            </Tab>
            <Tab tab={TabEnum.INGREDIENTS} title={TabEnum.INGREDIENTS}>
              <Ingredients {...ingredients} />
            </Tab>
            <Tab tab={TabEnum.COOKING_INSTRUCTIONS} title={TabEnum.COOKING_INSTRUCTIONS}>
              <If condition={!isPizza}>
                <RadioButton {...servingSizeOptionsRadioProps} />
              </If>
              <TextList {...cookingInstructions} />
            </Tab>
            <If condition={servingSuggestions?.items?.length > 0}>
              <Tab tab={TabEnum.SERVING_SUGGESTIONS} title={TabEnum.SERVING_SUGGESTIONS}>
                <TextList {...servingSuggestions} />
              </Tab>
            </If>
          </Accordion>
        </DeviceContainer>
        <DeviceContainer $tablet $desktop>
          <Tabs
            activeTab={state.activeTab}
            backgroundColor={theme.colors.whites.pureWhite}
            onTabChange={(activeTab: TabEnum): void => setState(prevState => ({ ...prevState, activeTab }))}>
            <TabPane tab={TabEnum.ABOUT_THE_DISH} title={(isPizza) ? 'About this Craft Pizza' : TabEnum.ABOUT_THE_DISH}>
              <Biography {...chef} />
            </TabPane>
            <TabPane tab={TabEnum.INGREDIENTS} title={TabEnum.INGREDIENTS}>
              <Ingredients {...ingredients} />
            </TabPane>
            <TabPane tab={TabEnum.COOKING_INSTRUCTIONS} title={TabEnum.COOKING_INSTRUCTIONS}>
              <If condition={!isPizza}>
                <RadioButton {...servingSizeOptionsRadioProps} />
              </If>
              <TextList {...cookingInstructions} />
            </TabPane>
            <If condition={servingSuggestions?.items?.length > 0}>
              <TabPane tab={TabEnum.SERVING_SUGGESTIONS} title={TabEnum.SERVING_SUGGESTIONS}>
                <TextList {...servingSuggestions} />
              </TabPane>
            </If>
          </Tabs>
        </DeviceContainer>
      </Container>
    </Form>
  )

}
