import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Icon, IconEnum, Paragraph, TextAlignment } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $reverse: boolean, $stacked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  flex-direction: ${(props): string => props.$stacked ? ((props.$reverse) ? 'column-reverse' : 'column') : ((props.$reverse) ? 'row-reverse' : 'row')};
  
  ${ResponsivePXValue('margin-bottom', '7px')}
  .standard{
  ${ResponsivePXValue('margin-right', '7px')}
  }
  .reversed{
    ${ResponsivePXValue('margin-left', '7px')}
  }
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '28px')}
  ${ResponsivePXValue('height', '28px')}
`

const HeaderIconContainer = styled.div`
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
`

const TextContainer = styled.div`
  div {
    margin: 0;
    position: relative;
    ${ResponsivePXValue('top', '2px')}
  }
`
const HeaderContainer = styled.div`
  div {
    margin: 0;
    position: relative;
    ${ResponsivePXValue('top', '-2px')}
  }
`
export interface IconTextProps {
  icon: IconEnum
  text: string
  textAlign?: TextAlignment
  color?: string
  reverse?: boolean
  stacked?: boolean
  variant?: 't2' | 'h7'
}

export function IconText({ icon, text, textAlign, color, reverse = false, stacked = false, variant = 't2' }: IconTextProps): JSX.Element {

  const theme = useTheme()

  if (!color) {
    color = theme.colors.oranges.coral
  }

  return (
    <Container $reverse={reverse} $stacked={stacked}>
      <Choose>
        <When condition={variant === 't2'}>
          <IconContainer className={reverse ? 'reversed' : 'standard'}>
            <Icon icon={icon} color={color} />
          </IconContainer>
          <TextContainer>
            <Paragraph variant='p2' align={textAlign}>
              {text}
            </Paragraph>
          </TextContainer>
        </When>
        <When condition={variant === 'h7'}>
          <HeaderIconContainer className={reverse ? 'reversed' : 'standard'}>
            <Icon icon={icon} color={color} />
          </HeaderIconContainer>
          <HeaderContainer>
            <Heading color={color} variant='h6'>{text}</Heading>
          </HeaderContainer>
        </When>
      </Choose>
    </Container>
  )

}
