import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph, ResponsiveImage, ResponsivePXValue, scrollToSelector, Seperator, Spacer, theme } from '@client/components'
import { PageDataSectionFragment, useWineCampaignQuery, VineyardFragment } from '@hooks/api'

const Container = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('margin', '24px 0')}
`

const VineyardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${ResponsivePXValue('max-width', '850px')}
`
const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;

  ${ResponsivePXValue('height', '125px')}
  ${ResponsivePXValue('min-width', '125px')}
  ${ResponsivePXValue('max-width', '125px')}
  ${ResponsivePXValue('margin', '0 16px 16px 0')}

  img {
    aspect-ratio: 3 / 2;
  }
`

export interface PageDataFeaturedVineyardsProps {
  pageSection: PageDataSectionFragment
}

export function PageDataFeaturedVineyards({ pageSection }: PageDataFeaturedVineyardsProps): JSX.Element {

  const { data } = useWineCampaignQuery()

  const vineyards = data?.campaign?.vineyards || []

  const _handleVineyardClick = (selectorId: string): void => {
    scrollToSelector(`#${selectorId}`)
  }

  let vineyard: VineyardFragment

  return (
    <Container>
      <Choose>
        <When condition={vineyards?.length > 0}>
          <If condition={!!pageSection?.title}>
            <Heading align='center' variant='h4' color={theme.colors.slates.bitter}>{pageSection.title}</Heading>
            <Spacer universal='8px' />
            <Seperator align='center' />
          </If>
          <If condition={!!pageSection?.description}>
            <Paragraph align='center' variant='p5' color={theme.colors.slates.bitter}>{pageSection.description}</Paragraph>
            <Spacer universal='8px' />
            <Seperator align='center' />
          </If>
          <VineyardContainer>
            <For each='vineyard' of={vineyards}>
              <ImageContainer onClick={() => { _handleVineyardClick(vineyard.selectorId) }}>
                <ResponsiveImage image={vineyard.logo} lazy={false} />
              </ImageContainer>
            </For>
          </VineyardContainer>
        </When>
        <Otherwise>
          <Paragraph variant='p3' align='center'>
            Active Wine Campaign Vineyards not found
          </Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )
}
