import React, { useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalScrollFade } from '@client/components'
import { Rule, Button } from '@client/components/atoms'
import { AddOnOrderList, DashedTable, Modal, ModalPositionEnum } from '@client/components/molecules'
import { AddonOrderRatingData } from '@client/components/pages/MealKitStore'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { ProductFragment, useUserDetailsQuery, OrderItemFragment, OrdersQueryDocument, useAddOrderItemRatingMutation, useGetAppQuery } from '@hooks/api'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{$isNativeApplication:boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  ${ResponsivePXValue('padding', { mobile: '0px 16px 12px', tablet: '0px 16px 12px', desktop: '0px 16px 12px' })}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '576px' })}
  ${ResponsivePXValue('min-height', { mobile: '450px', tablet: '450px' })}
`
const ScrollContainer = styled.div`
  height: fit-content;
  ${ResponsivePXValue('max-height', { mobile: '100%', tablet: '100%', desktop: '460px' })}
  overflow-y: auto;
  --mask-height: 20px;
  padding-bottom: 20px;
  ${ModalScrollFade}
`
const ButtonsContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '360px', desktop: '164px' })}
  ${ResponsivePXValue('height', { mobile: '30px', tablet: '30px', desktop: '40px' })}
  ${ResponsivePXValue('padding-top', { mobile: '12px', tablet: '12px', desktop: '16px' })}
  justify-content: center;
  margin: 0 auto;
`

export interface AddOnRatingModalProps {
  open: boolean
  ratingOrder: { [k: string]: AddonOrderRatingData }
  ratingPast?: number
  orderItemId?: string
  onClose: () => void
  onObject?: (updateRatingsData: UpdatedRatingsData) => void
}

interface OrderRatingData {
  rating?: number
  ratingComments?: string
  otherInfo?: string[]
}

interface UpdatedRatingsData {
  orderID: string
  rating: number
  addOnId: string
}

interface AddOnListState {
  product?: ProductFragment
  disabled: boolean
  productType: ProductRangeEnum
  ratingId?: string
  otherInfo?: string[]
  selectedOptions: string[]
  orderRatings: { [k: string]: OrderRatingData }
  updatedRatings: { [k: string]: UpdatedRatingsData }
  openModal: boolean
  pillErrorsNumber: number
  otherErrorsNumber: number
  hasLoopedAll?: boolean
  showPillError: boolean
  showOtherError: boolean
  submitting: boolean
}

const DEFAULT_STATE: AddOnListState = {
  disabled: true,
  productType: ProductRangeEnum.MEAL_KIT,
  otherInfo: [],
  selectedOptions: [],
  orderRatings: {},
  updatedRatings: {},
  openModal: false,
  pillErrorsNumber: 0,
  otherErrorsNumber: 0,
  showPillError: false,
  showOtherError: false,
  submitting: false,
}

export function AddOnRatingModal({ open, ratingOrder, onClose }: AddOnRatingModalProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<AddOnListState>({ ...DEFAULT_STATE })
  const navigate = useNavigate()
  const [addRating] = useAddOrderItemRatingMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication

  const mostRecentAddOns: AddonOrderRatingData[] = []
  for (const key in ratingOrder) {
    mostRecentAddOns.push(ratingOrder[key])
  }

  const navigateToPDP = (addOnItem: OrderItemFragment): void => {

    const pdp = addOnItem?.product?.group?.slug
    const rangeSlug = addOnItem?.product?.slug
    const type = addOnItem?.product?.__typename
    const subCat = addOnItem?.product?.productRange

    let suffix
    if (pdp) {
      suffix = pdp
    } else {
      suffix = rangeSlug
    }

    let middle

    if (type !== 'FrozenMeal') {
      middle = subCat
      navigate(`/${middle}/${suffix}`)

    } else {
      middle = 'frozen'
      navigate(`/${middle}`)

    }

  }

  const _handleSubmit = async () => {
    setState((prevState) => update(prevState, {
      hasLoopedAll: { $set: false },
      showPillError: { $set: false },
      showOtherError: { $set: false },
      submitting: { $set: true },
    }))
    const refetchQueries = [{
      query: OrdersQueryDocument,
      variables: {
        filters: {
          users: [userDetailsData?.currentUser.id],
          status: ['COMPLETE', 'PROCESSING'],
        },
        limit: 10,
      },
    }]
    const pillErrors = 0
    const otherErrors = 0

    const ratings = []
    for (const key in state.orderRatings) {
      ratings.push(state.orderRatings[key])
    }

    for (let i = 0; i < ratings?.length; i++) {

      const addOnId = mostRecentAddOns[i]?.orderItemData?.id
      try {
        await addRating({
          variables: {
            input: {
              user: userDetailsData.currentUser.id,
              feedback: state.orderRatings[addOnId]?.ratingComments,
              orderItem: addOnId,
              selectedOptions: state.orderRatings[addOnId]?.otherInfo,
              value: state.orderRatings[addOnId].rating,
            },
          },
          refetchQueries,
          awaitRefetchQueries: true,
        })
      } catch (err) {
      }
    }

    setState((prevState) => update(prevState, {
      hasLoopedAll: { $set: true },
      pillErrorsNumber: { $set: pillErrors },
      otherErrorsNumber: { $set: otherErrors },
      submitting: { $set: false },
    }))
    _handleClose()
  }

  const _handleRatingClick = (rating: number, cartItem: OrderItemFragment, orderId: string) => {

    let ratingId: string | null
    const orderRatingData = state.orderRatings

    orderRatingData[cartItem.id] = {
      rating,
      ratingComments: '',
      otherInfo: [],
    }

    const updateRatingsData = state.updatedRatings

    updateRatingsData[cartItem.id] = {
      orderID: orderId,
      rating,
      addOnId: cartItem.id,
    }
    setState((prevState) => update(prevState, {
      orderRatings: { $set: orderRatingData },
      updatedRatings: { $set: updateRatingsData },
    }))

    setState((prevState) => update(prevState, {
      product: { $set: cartItem.product },
      productType: { $set: cartItem.product.productRange },
      orderId: { $set: orderId },
      ratingId: { $set: ratingId },
      disabled: { $set: false },
    }))
  }
  const _handleClose = () => {
    for (const key in state.orderRatings) {
      delete state.orderRatings[key]
    }
    onClose()
  }
  const _handleAdditionalData = (additionalData: string, cartItem: OrderItemFragment) => {
    const orderRatingData = state.orderRatings
    const ratingItem = orderRatingData[cartItem.id]

    if (ratingItem) {
      orderRatingData[cartItem.id].ratingComments = additionalData
    }
  }
  const _handleToggleData = (toggleData: string, cartItem: OrderItemFragment) => {
    const orderRatingData = state.orderRatings
    const ratingItem = orderRatingData[cartItem.id]

    if (ratingItem) {
      orderRatingData[cartItem.id].otherInfo = [toggleData]
    }
  }
  let addOn: AddonOrderRatingData
  let index: number

  return (
    <Choose>
      <When condition={mostRecentAddOns.length <= 0}>
        <></>
      </When>
      <Otherwise>
        <Modal title='Rate your recent order' open={open} onClose={_handleClose} allowScroll={true} allowBackgroundClose={true} position={ModalPositionEnum.CENTER_BOTTOM} fullscreen={false} showCloseButton>
          <Container $isNativeApplication={isNativeApplication}>
            <ScrollContainer>
              <DashedTable seperatorVariant='none'>
                <If condition={!!mostRecentAddOns}>
                  <For each='addOn' of={mostRecentAddOns} index='index'>
                    <div key={index}>
                      <AddOnOrderList
                        prevInput={state.orderRatings[addOn.orderItemData.id]?.otherInfo[0]}
                        cartItem={addOn.orderItemData}
                        rating={addOn?.orderItemData.orderItemRating?.value}
                        deliveryDate={addOn?.orderItemData?.order?.dispatchDate}
                        newRating={state.orderRatings[addOn.orderItemData.id]?.rating}
                        onNavigate={() => navigateToPDP(addOn.orderItemData)}
                        onRate={(rating) => _handleRatingClick(rating, addOn.orderItemData, addOn?.orderId)}
                        onToggle={(toggleData) => _handleToggleData(toggleData, addOn.orderItemData)}
                        onAdditionalData={(additionalData) => _handleAdditionalData(additionalData, addOn.orderItemData)} />
                      <Rule color='slate' />
                    </div>
                  </For>
                </If>
              </DashedTable>
            </ScrollContainer>
            <If condition={Object.keys(state.orderRatings)?.length === 0}>
              <ButtonsContainer>
                <Button fullWidth={true} color='grey' title='SEND FEEDBACK' />
              </ButtonsContainer>
            </If>
            <If condition={Object.keys(state.orderRatings)?.length !== 0}>
              <ButtonsContainer>
                <Button fullWidth={true} variant='primary' color='black' title='SEND FEEDBACK' onClick={_handleSubmit} />
              </ButtonsContainer>
            </If>
          </Container>
        </Modal>
      </Otherwise>
    </Choose>
  )
}
