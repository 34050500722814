import React from 'react'

import {
  format,
  startOfWeek,
  isBefore,
  setHours,
  setMinutes,
  nextFriday,
  addDays,
  getHours,
  getMinutes,
  isWednesday,
  isFriday,
} from 'date-fns'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE } from '@api/local'
import { Button, Paragraph } from '@atoms/index'
import { Seperator, Link, Spacer } from '@client/components/atoms'
import { _handleWeekendBoxDeliveryText, ContentBlock } from '@client/components/molecules'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useGetCheckoutQuery, CartItemFragment, MarketProductListFragment } from '@hooks/api'
import { CheckoutTypeEnum } from '@uctypes/api/globalTypes'

import { CheckoutContainer } from '../CheckoutContainer'

const Container = styled.div`

  display: grid;

  ${ResponsivePXValue('margin', { mobile: '16px 8px 32px', tablet: '32px 32px 64px', desktop: '64px 128px' })}

  .paragraph {
    ${ResponsivePXValue('margin-bottom', { mobile: '32px', tablet: '32px', desktop: '64px' })}
  }

  .button {
    justify-self: center;
    ${ResponsivePXValue('margin-top', '8px')}
  }
`

const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('gap', { desktop: '8px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '12px', desktop: '12px' })}

  .text {
    margin: 0;
    padding: 0;
    ${ResponsivePXValue('margin-bottom', '4px')}
  }
`
const MarketBoxContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${ResponsivePXValue('gap', { desktop: '8px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '12px', desktop: '12px' })}

  .text {
    margin: 0;
    padding: 0;
    ${ResponsivePXValue('margin-bottom', '4px')}
  }
`

export interface CheckoutThankYouProps {
  checkoutType: CheckoutTypeEnum
}

export function CheckoutThankYou({ checkoutType }: CheckoutThankYouProps): JSX.Element {
  const config = useConfig()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const navigate = useNavigate()
  const isSubscriptionCheckout = checkoutType === CheckoutTypeEnum.SUBSCRIPTION

  const _handleDone = (): void => {
    navigate('/')
  }

  const _handleViewMenu = (): void => {
    navigate('/meal-kit')
  }

  const safeFormat = (date: string | Date, formatStr: string): string => {
    if (!date) return ''
    try {
      return format(new Date(date), formatStr)
    } catch {
      return ''
    }
  }

  const billingDate = safeFormat(userCartData?.currentUser?.activeMenu?.billingDate, 'cccc do MMM yyyy')
  const deliveryDate = safeFormat(userCartData?.currentUser?.activeMenu?.deliveryDate, 'cccc do MMM yyyy')
  const title = isSubscriptionCheckout ? 'Order Saved' : 'Order Placed'
  const occasionBoxes = checkoutData?.checkout?.cartItems?.filter(cartItem => cartItem?.product?.isOccasionBox === true)
  const weekendBoxes = checkoutData?.checkout?.cartItems?.filter(cartItem => cartItem?.product?.isWeekendBox === true)
  const hasOnlyMarketBoxes = (occasionBoxes?.length + weekendBoxes?.length) === checkoutData?.checkout?.cartItems?.length
  let formattedDispatchDate = safeFormat(checkoutData?.checkout?.dispatchDate, 'cccc do MMM yyyy')

  if (!formattedDispatchDate || !(checkoutData?.checkout?.dispatchDate)) {
    formattedDispatchDate = _handleWeekendBoxDeliveryText()
  }
  const orderMessage = hasOnlyMarketBoxes
    ? `Your order number is (${checkoutData?.checkout?.orderId}).`
    : `Your order number is (${checkoutData?.checkout?.orderId}). Your order will be dispatched on the ${formattedDispatchDate}.`

  const isDateBeforeWednesday2AM = (date: Date): boolean => {
    const startOfWeekDate = startOfWeek(date)
    const wednesdayDate = addDays(startOfWeekDate, 3)
    const wednesday2AM = setMinutes(setHours(wednesdayDate, 9), 0)
    return (
      isBefore(date, wednesday2AM) ||
      (isWednesday(date) && getHours(date) === 9 && getMinutes(date) === 0)
    )
  }

  const getWeekendBoxOnDemandDeliveryDate = (inputDate: Date): Date => {
    const isBeforeWednesday2AM = isDateBeforeWednesday2AM(inputDate)
    const finalDeliveryDate = isBeforeWednesday2AM
      ? nextFriday(inputDate)
      : isFriday(inputDate)
        ? nextFriday(inputDate)
        : nextFriday(nextFriday(inputDate))

    return finalDeliveryDate
  }

  const getWeekendBoxDeliveryDate = (inputDate: Date): string => {
    if (!inputDate) return formattedDispatchDate
    const deliveryDate = isSubscriptionCheckout ? nextFriday(inputDate) : getWeekendBoxOnDemandDeliveryDate(inputDate)
    return safeFormat(deliveryDate, 'cccc do MMM yyyy')
  }

  const _handleWeekendBoxDeliveryTextLocally = (marketBox: CartItemFragment): string => {
    if ((marketBox?.product as unknown as MarketProductListFragment)?.deliveryDate) {
      return safeFormat((marketBox?.product as unknown as MarketProductListFragment)?.deliveryDate, 'cccc dd MMM yyyy')
    }
    return getWeekendBoxDeliveryDate(new Date(marketBox?.createdAt))
  }

  let marketBox: CartItemFragment
  let index: number
  return (
    <CheckoutContainer>
      <ContentBlock title={title}>
        <Container>
          <Choose>
            <When condition={isSubscriptionCheckout}>
              <>
                <LineContainer>
                  <Paragraph variant='p1' className='text'>
                    Your order will be billed on:
                  </Paragraph>
                  <Paragraph variant='p2' className='text'>{billingDate}</Paragraph>
                </LineContainer>
                <LineContainer>
                  <Paragraph variant='p1' className='text'>
                    Your box will be delivered on:
                  </Paragraph>
                  <Paragraph variant='p2' className='text'>{deliveryDate}</Paragraph>
                </LineContainer>
                <Spacer universal='8px' />
                <Seperator align='center' />
                <Paragraph variant='p1' className='paragraph' align='center'>
                  We love being as flexible as you are. Feel free to customize your meal kit subscription or change your delivery day up until billing day, after which your order will be locked in.
                </Paragraph>
                <Button title='View Menu' onClick={_handleViewMenu} />
                <Link className='button' onClick={_handleDone}> Home </Link>
              </>
            </When>
            <Otherwise>
              <>
                <LineContainer>
                  <Paragraph variant='p2' className='text'>Thank you for your order</Paragraph>
                </LineContainer>
                <LineContainer>
                  <Paragraph variant='p1' className='text'>
                    {orderMessage}
                  </Paragraph>
                </LineContainer>
                <If condition={occasionBoxes?.length > 0 || weekendBoxes.length > 0}>
                  <MarketBoxContainer className='paragraph'>
                    <If condition={occasionBoxes?.length > 0}>
                      <For each='marketBox' index='index' of={occasionBoxes}>
                        <Paragraph variant='p1' className='text' key={index}>
                          Your Occasion Box ({marketBox?.product?.name}) will be dispatched on the {safeFormat((marketBox?.product as unknown as MarketProductListFragment)?.deliveryDate || checkoutData?.checkout?.dispatchDate, 'cccc dd MMM yyyy')}.
                        </Paragraph>
                      </For>
                      <Spacer universal='12px' />
                    </If>
                    <If condition={weekendBoxes?.length > 0}>
                      <For each='marketBox' index='index' of={weekendBoxes}>
                        <Paragraph variant='p1' className='text' key={index}>
                          Your Weekend Box ({marketBox?.product?.name}) will be dispatched on the {_handleWeekendBoxDeliveryTextLocally(marketBox)}.
                        </Paragraph>
                      </For>
                    </If>
                  </MarketBoxContainer>
                </If>
                <Button title='Okay' onClick={_handleDone} />
              </>
            </Otherwise>
          </Choose>
        </Container>
      </ContentBlock>
    </CheckoutContainer>
  )
}
