import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MealKitPlans')

const MealKitPlans = loadable(() => import(/* webpackChunkName: "meal-kit-plans" */'@pages/MealKitPlans'), {
  resolveComponent: (components) => components.MealKitPlans,
})

export function MealKitPlansAsync(): JSX.Element {

  return <MealKitPlans fallback={<div>Loading...</div>} />

}
