import React from 'react'

import { useParams } from 'react-router-dom'

import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { PageSectionGroup } from '../molecules/content'

/**
 * GA Tracking Elements
 * [ ] Banner Clicks/Navigation?
 *
 * SEO
 * [ ] Link
 * [ ] Description
 * [ ] Tags
 */

const seo = {
  name: 'UCOOK CMS',
  title: 'UCOOK | Meal Kits & Meal Delivery Service South Africa',
  meta: [{
    name: 'description',
    content: 'SA\'s most popular Meal Kit 📦🥕 We deliver the freshest ethically sourced ingredients in the right quantities so you can make easy weeknight meals at home.',
  }, {
    name: 'keywords',
    content: 'Meal Kits,Meal Prep Delivery,Food Box Delivery',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}

export function CMS(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <PageSectionGroup identifier={slug} />
    </>
  )
}
